import { FormControl, FormLabel } from '@chakra-ui/react';
import { CurrencyField } from '../../../Formik/CurrencyField';
import { PercentField } from '../../../Formik/PercentField';
import { SingleSelectField } from '../../../Formik/SingleSelectField';
import { FINANCING_OPTIONS } from '../../constants';

export const FinancialInfoForm = ({
  showLoanAmount,
}: {
  showLoanAmount: boolean;
}) => {
  return (
    <>
      <FormControl>
        <FormLabel>Financing type</FormLabel>
        <SingleSelectField
          isClearable
          name="financingType"
          options={FINANCING_OPTIONS}
        />
      </FormControl>
      {showLoanAmount && (
        <>
          <FormControl>
            <FormLabel>Loan amount</FormLabel>
            <CurrencyField name="loanAmount" />
          </FormControl>
          <FormControl>
            <FormLabel>% Down Payment</FormLabel>
            <PercentField name="percentDownPayment" />
          </FormControl>
        </>
      )}
      <FormControl>
        <FormLabel>Cash Reserves</FormLabel>
        <CurrencyField name="cashReserves" />
      </FormControl>
    </>
  );
};
