import { Box, Button, Flex, Link } from '@chakra-ui/react';
import { useTrackListingDocumentActionMutation } from '@client/graphql/__generated__/main-operations';
import {
  ListingDocumentAction,
  ListingDocumentUploadObject,
  StorefrontSubscriptionReason,
} from '@client/graphql/__generated__/types';
import { Select } from 'chakra-react-select';
import { FC, useCallback, useEffect, useState } from 'react';
import { useStorefrontContext } from '~/apps/consumer/pages/Storefront/StorefrontContext';
import { BasicPdfViewModal } from '../../BasicPdfViewerModal/BasicPdfViewerModal';
import { SignGuestbookOverlay } from '../SignGuestbookOverlay';

interface ListingDocumentsViewerModalProps {
  isOpen: boolean;
  onClose: () => void;
  initialDocumentId: string;
}

export const ListingDocumentsViewerModal: FC<
  ListingDocumentsViewerModalProps
> = ({ isOpen, onClose, initialDocumentId }) => {
  const { listingDocumentUploads, isGatedBehindSigningGuestbook } =
    useStorefrontContext();
  const isDocumentTrackingEnabled = !isGatedBehindSigningGuestbook;

  const [selectedDocument, setSelectedDocument] =
    useState<ListingDocumentUploadObject | null>(null);
  const [trackListingDocumentAction] = useTrackListingDocumentActionMutation();

  useEffect(() => {
    setSelectedDocument(
      listingDocumentUploads.find((doc) => doc.id === initialDocumentId) || null
    );
  }, [initialDocumentId, listingDocumentUploads]);

  const handleDocumentChange = useCallback(
    (option: { value: string }) => {
      const selectedDoc =
        listingDocumentUploads.find((doc) => doc.id === option.value) || null;
      setSelectedDocument(selectedDoc);
    },
    [listingDocumentUploads]
  );

  useEffect(() => {
    if (isDocumentTrackingEnabled && selectedDocument) {
      void trackListingDocumentAction({
        variables: {
          input: {
            listingDocumentUploadId: selectedDocument.id,
            action: ListingDocumentAction.VIEW,
          },
        },
      });
    }
  }, [isDocumentTrackingEnabled, selectedDocument, trackListingDocumentAction]);

  const trackDownload = useCallback(() => {
    if (isDocumentTrackingEnabled && selectedDocument?.file?.url) {
      void trackListingDocumentAction({
        variables: {
          input: {
            listingDocumentUploadId: selectedDocument.id,
            action: ListingDocumentAction.DOWNLOAD,
          },
        },
      });
    }
  }, [isDocumentTrackingEnabled, selectedDocument, trackListingDocumentAction]);

  const initialDocumentUpload = listingDocumentUploads.find(
    (doc) => doc.id === initialDocumentId
  );
  const defaultValue = initialDocumentUpload
    ? docUploadToOption(initialDocumentUpload)
    : undefined;

  return (
    <BasicPdfViewModal
      header={
        <Flex alignItems="center" justifyContent="space-between" mr="8">
          <Box background="whiteAlpha.100" width={['60%', '300px']}>
            <Select
              defaultValue={defaultValue}
              isDisabled={isGatedBehindSigningGuestbook}
              options={listingDocumentUploads.map(docUploadToOption)}
              onChange={handleDocumentChange}
            />
          </Box>
          <Button
            download
            isExternal
            _hover={{ textDecoration: 'none' }}
            as={Link}
            href={selectedDocument?.file?.url}
            isDisabled={
              !selectedDocument?.file?.url || isGatedBehindSigningGuestbook
            }
            onClick={trackDownload}
          >
            Download
          </Button>
        </Flex>
      }
      isOpen={isOpen}
      pdfFiles={[
        {
          url: selectedDocument?.file?.url || '',
          name:
            selectedDocument?.listingDocumentType?.name ||
            selectedDocument?.customDocumentName ||
            '',
        },
      ]}
      signGuestbookOverlay={
        <SignGuestbookOverlay
          reason={StorefrontSubscriptionReason.ACCESS_LISTING_DOCUMENTS}
        />
      }
      onClose={onClose}
    />
  );
};

const docUploadToOption = (doc: ListingDocumentUploadObject) => ({
  label: doc.listingDocumentType?.name || doc.customDocumentName,
  value: doc.id,
});
