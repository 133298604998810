import { FormControl, FormLabel, VStack } from '@chakra-ui/react';
import {
  CreateIndigoContractMutation,
  DocumentForOfferFlowFragment,
  DocumentType,
  IndigoContractsForCurrentUserFragment,
  UsState,
} from '@client/graphql/__generated__/types';
import { FC, memo, MutableRefObject, useRef } from 'react';
import { CenterSpinner } from '~/common/components/CenterSpinner';
import { Select } from '~/services/document/common/Select';
import { DocumentSection } from '../OfferFlow/DocumentSection';
import {
  useIndigoContractForm,
  UseIndigoContractFormState,
} from './hooks/useIndigoContractForm';

export type IndigoContractFormMutationRef = () => Promise<
  CreateIndigoContractMutation['createIndigoContract'] | undefined
>;

export interface IndigoContractFormProps {
  indigoContract?: IndigoContractsForCurrentUserFragment;
  documentType: DocumentType;
  mutationRef: MutableRefObject<IndigoContractFormMutationRef | undefined>;
  requiredFormStates?: (keyof UseIndigoContractFormState)[];
  onIsReady?: (ready: boolean) => void;
}

export const IndigoContractForm: FC<IndigoContractFormProps> = memo(
  function IndigoContractForm(props) {
    const {
      state,
      updateState,
      documents,
      documentSection,
      formFieldsMap,
      sectionsLoading,
      isLoading,
      updateValues,
      values,
      mlsStates,
    } = useIndigoContractForm(props);
    const ref = useRef<HTMLDivElement>(null);
    const options = documents?.map((document) => ({
      label: document.name,
      value: document,
    }));

    if (isLoading) {
      return <CenterSpinner />;
    }

    return (
      <VStack ref={ref} alignItems="stretch">
        {mlsStates.length > 1 && (
          <FormControl id="choose-us-state">
            <FormLabel variant="normal">Choose a state</FormLabel>
            <Select<{ label: UsState; value: UsState }>
              options={mlsStates.map((state) => ({
                label: state,
                value: state,
              }))}
              value={{ label: state.usState, value: state.usState }}
              onChange={(option) =>
                updateState({
                  usState: option?.value,
                  selectedDocument: undefined,
                })
              }
            />
          </FormControl>
        )}

        {options && (
          <FormControl id="choose-contract">
            <FormLabel variant="normal">Choose a contract</FormLabel>
            <Select<{ label: string; value: DocumentForOfferFlowFragment }>
              menuPortalTarget={window.document.body}
              options={options}
              styles={{
                menuPortal: (base) => ({
                  ...base,
                  zIndex: 9999,
                }),
              }}
              value={
                state.selectedDocument
                  ? {
                      label: state.selectedDocument?.name,
                      value: state.selectedDocument,
                    }
                  : undefined
              }
              onChange={(option) =>
                updateState({ selectedDocument: option?.value })
              }
            />
          </FormControl>
        )}
        {!documentSection && sectionsLoading ? (
          <CenterSpinner />
        ) : (
          documentSection && (
            <DocumentSection
              key={documentSection.id}
              buyerSelectProps={{
                selectedParty: state.selectedParty,
                showSelectedPartyInfo: true,
                onSelectParty: (party) => updateState({ selectedParty: party }),
              }}
              documentSection={documentSection}
              fieldValues={values}
              formFieldsMap={formFieldsMap}
              mergedAutofillValues={{}}
              showTitle={false}
              styleRootSectionCard={false}
              onChange={updateValues}
            />
          )
        )}
      </VStack>
    );
  }
);
