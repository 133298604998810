import {
  Button,
  Flex,
  Heading,
  Icon,
  Link,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from '@chakra-ui/react';
import { useGuestbookSignersQuery } from '@client/graphql/__generated__/main-operations';
import { Roles } from '@client/graphql/__generated__/types';
import dayjs from 'dayjs';
import { download, generateCsv, mkConfig } from 'export-to-csv';
import { BookOpenText, Check, DownloadIcon } from 'lucide-react';
import { EmptyState } from '~/apps/consumer/components/EmptyState';
import { UserAvatar } from '~/apps/consumer/components/User/UserAvatar';
import { CenterSpinner } from '~/common/components/CenterSpinner';
import { MailIcon, TelephoneIcon } from '~/common/icons';
import { AddAnnouncementButton } from '../Announcements/AddAnnouncementButton';
import { ShareStorefrontModalTrigger } from '../Dashboard/ShareStorefrontModalTrigger';
import { useListingSetupContext } from '../ListingSetupContext';
import { ListingSetupContentWrapper } from '../ListingSetupWrapperComponents';
import { getSignersCsvData } from './utils';

export const COLUMNS = [
  'Name',
  'Type',
  'Brokerage',
  'SignUp',
  'Contact',
  'Subscribed',
  'Working with agent',
];

export const GuestbookSignersPage = () => {
  const { listing } = useListingSetupContext();
  const listingId = listing?.id;

  const { data, loading } = useGuestbookSignersQuery({
    variables: {
      input: {
        id: listingId || '',
      },
    },
    skip: !listingId,
  });

  if (loading) {
    return (
      <Flex h="100vh" w="100vw">
        <CenterSpinner />
      </Flex>
    );
  }

  const handleExportCsv = () => {
    const csvData = getSignersCsvData(data?.guestbookSigners?.signers || []);
    const csvConfig = mkConfig({
      filename: `${listing?.property.slug}-guestbook-signers`,
      useKeysAsHeaders: true,
    });

    const csv = generateCsv(csvConfig)(csvData);
    download(csvConfig)(csv);
  };

  return (
    <ListingSetupContentWrapper>
      <Flex direction="column" gap={6} w={{ base: '100%', md: '80%' }}>
        <Flex alignItems="center" justifyContent="space-between">
          <Heading as="h5" size="xxs">
            Guestbook CRM
          </Heading>
          <Flex gap={2}>
            <Button
              leftIcon={<Icon as={DownloadIcon} />}
              size="sm"
              variant="outline"
              onClick={handleExportCsv}
            >
              Export CSV
            </Button>
            <AddAnnouncementButton />
          </Flex>
        </Flex>
        <TableContainer
          maxHeight="80vh"
          overflowX="auto"
          overflowY="scroll"
          sx={{
            '::-webkit-scrollbar': {
              display: 'none',
            },
            '-ms-overflow-style': 'none',
            'scrollbar-width': 'none',
          }}
        >
          <Table size="lg" width="100%">
            <Thead bg="indigo.800" position="sticky" top={0} zIndex="docked">
              <Tr>
                {COLUMNS.map((column) => (
                  <Th key={column}>{column}</Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {data?.guestbookSigners?.signers?.length &&
                data?.guestbookSigners?.signers?.map(
                  ({
                    user,
                    isSubscribed,
                    office,
                    signedAt,
                    openHouseId,
                    isWorkingWithAgent,
                  }) => {
                    const signerType: 'Agent' | 'Buyer' = user.roles.includes(
                      Roles.AGENT
                    )
                      ? 'Agent'
                      : 'Buyer';

                    return (
                      <Tr key={user.id}>
                        <Td>
                          <Flex alignItems="center">
                            <UserAvatar mr={3} size="sm" user={user} />
                            <Text fontWeight="bold">{user.fullName}</Text>
                          </Flex>
                        </Td>
                        <Td>
                          <Text>{signerType}</Text>
                        </Td>
                        <Td>
                          {signerType === 'Agent' ? (
                            office?.OfficeName && (
                              <Text>{office.OfficeName}</Text>
                            )
                          ) : (
                            <Text color="whiteAlpha.600">N/A</Text>
                          )}
                        </Td>
                        <Td>
                          <VStack align="flex-start" spacing={0}>
                            <Text>
                              {signedAt
                                ? dayjs(signedAt as Date).format('MMM D, YYYY')
                                : 'N/A'}
                            </Text>
                            <Text color="whiteAlpha.600" fontSize="sm">
                              {openHouseId ? 'Open House' : 'Storefront'}
                            </Text>
                          </VStack>
                        </Td>
                        <Td>
                          <Flex>
                            <Link href={`mailto:${user.email ?? ''}`} mr={2}>
                              <MailIcon
                                color={user.email ? 'white' : 'whiteAlpha.600'}
                                h="14px"
                                w="14px"
                              />
                            </Link>
                            <Link href={`tel:${user.phoneNumber ?? ''}`} mr={2}>
                              <TelephoneIcon
                                color={
                                  user.phoneNumber ? 'white' : 'whiteAlpha.600'
                                }
                                h="14px"
                                w="14px"
                              />
                            </Link>
                          </Flex>
                        </Td>
                        <Td>
                          {isSubscribed && <Check aria-label="Subscribed" />}
                        </Td>

                        <Td>
                          {signerType === 'Buyer' ? (
                            isWorkingWithAgent && (
                              <Check aria-label="Working with agent" />
                            )
                          ) : (
                            <Text color="whiteAlpha.600">N/A</Text>
                          )}
                        </Td>
                      </Tr>
                    );
                  }
                )}
            </Tbody>
          </Table>
        </TableContainer>
        {!data?.guestbookSigners?.signers?.length && listingId && (
          <>
            <EmptyState
              headerText="No Guestbook signers yet"
              icon={BookOpenText}
              subText={
                <Text>
                  <ShareStorefrontModalTrigger as="span" listing={listing}>
                    <Link color="link.skyBlue">Share your storefront</Link>
                  </ShareStorefrontModalTrigger>{' '}
                  to get agents and buyers to sign your Guestbook
                </Text>
              }
            />
          </>
        )}
      </Flex>
    </ListingSetupContentWrapper>
  );
};
