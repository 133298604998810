import {
  CollisionDetection,
  defaultDropAnimationSideEffects,
  DropAnimation,
  MouseSensor,
  rectIntersection,
  SensorDescriptor,
  SensorOptions,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { useCallback, useMemo, useState } from 'react';
import { useIsMobile } from './useIsMobile';

export const GRAB_DELAY = 0;
export const GRAB_DELAY_MOBILE = 200;
export const GRAB_TOLERANCE = 5;

interface UseDndKitDefaultsProps {
  extendSensors?: SensorDescriptor<SensorOptions>[];
}

const emptySensors = [];

export const useDndKitDefaults = <T>({
  extendSensors,
}: UseDndKitDefaultsProps) => {
  const isMobile = useIsMobile();
  const shouldUseGrabDelayMobile =
    isMobile && window.ENVS.environment !== 'e2e';
  const [activeDraggingItemData, setActiveDraggingItemData] =
    useState<T | null>(null);

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        delay: shouldUseGrabDelayMobile ? GRAB_DELAY_MOBILE : GRAB_DELAY,
        tolerance: GRAB_TOLERANCE,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: shouldUseGrabDelayMobile ? GRAB_DELAY_MOBILE : GRAB_DELAY,
        tolerance: GRAB_TOLERANCE,
      },
    }),
    ...(extendSensors || emptySensors)
  );

  const dropAnimationConfig: DropAnimation = useMemo(
    () => ({
      sideEffects: defaultDropAnimationSideEffects({
        styles: {
          active: {
            opacity: '0.4',
          },
        },
      }),
    }),
    []
  );
  // Used for if the DragOverlay is a different size than the draggable item
  // https://github.com/clauderic/dnd-kit/pull/334#issuecomment-1965708784
  const fixCursorSnapOffset: CollisionDetection = useCallback((args) => {
    // Bail out if keyboard activated
    if (!args.pointerCoordinates) {
      return rectIntersection(args);
    }
    const { x, y } = args.pointerCoordinates;
    const { width, height } = args.collisionRect;
    const updated = {
      ...args,
      // The collision rectangle is broken when using snapCenterToCursor. Reset
      // the collision rectangle based on pointer location and overlay size.
      collisionRect: {
        width,
        height,
        bottom: y + height / 2,
        left: x - width / 2,
        right: x + width / 2,
        top: y - height / 2,
      },
    };

    return rectIntersection(updated);
  }, []);

  return {
    sensors,
    dropAnimationConfig,
    activeDraggingItemData,
    setActiveDraggingItemData,
    fixCursorSnapOffset,
  };
};
