import { Flex, Heading, VStack } from '@chakra-ui/react';
import { SidebarItem, SidebarItemType } from './SidebarItem';

export const SIDEBAR_WIDTH = '320px';
export const SIDEBAR_WIDTH_EXPANDED = '320px';
export const SIDEBAR_WIDTH_COLLAPSED = '56px';

export interface SidebarItem {
  icon: JSX.Element;
  label: string;
  to: string;
  rightLabel?: number | string | JSX.Element;
  rightLabelColor?: string;
  isDisabled?: boolean;
  className?: string;
}

interface SidebarSection {
  key: string;
  sectionTitle: React.ReactNode;
  sectionClassName?: string;
  items: SidebarItemType[];
}

export type PageDetailSidebarType = (SidebarSection | SidebarItemType)[];

interface PageDetailLayoutSidebarProps {
  SidebarHeader: () => JSX.Element | null;
  SidebarFooter?: () => JSX.Element | null;
  sidebarItems: PageDetailSidebarType;
  onClickSidebarItem?: () => void;
}

export const PageDetailLayoutSidebar = ({
  SidebarHeader,
  sidebarItems,
  SidebarFooter,
  onClickSidebarItem,
}: PageDetailLayoutSidebarProps) => {
  return (
    <VStack
      alignItems="flex-start"
      bgColor="bg.mainDark"
      borderRight="1px solid"
      borderRightColor="border.divider"
      bottom={0}
      height="100vh"
      left={0}
      minWidth={SIDEBAR_WIDTH}
      overflow="auto"
      overscrollBehavior="contain"
      position="sticky"
      spacing={4}
      top={0}
    >
      <SidebarHeader />
      <VStack alignItems="flex-start" p={2} spacing={0} width="100%">
        {sidebarItems.map((item) => {
          if ('sectionTitle' in item) {
            return (
              <Flex
                key={item.key}
                className={item.sectionClassName}
                direction="column"
                mb={0}
                mt={2}
                width="100%"
              >
                <Heading
                  color="whiteAlpha.700"
                  mb={4}
                  mt={4}
                  pl={3}
                  pr={1}
                  size="smallUppercase"
                >
                  {item.sectionTitle}
                </Heading>
                {item.items.map((subItem) => (
                  <SidebarItem
                    key={subItem.label}
                    item={subItem}
                    onClickSidebarItem={onClickSidebarItem}
                  />
                ))}
              </Flex>
            );
          }

          return (
            <SidebarItem
              key={item.label}
              item={item}
              onClickSidebarItem={onClickSidebarItem}
            />
          );
        })}
      </VStack>
      {SidebarFooter && <SidebarFooter />}
    </VStack>
  );
};
