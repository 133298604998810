import { Box, Flex, Link, Spacer, Text } from '@chakra-ui/react';
import {
  ListingAnnouncementFragment,
  MlsListingAgentObject,
  PublicListingFragment,
  UserObject,
} from '@client/graphql/__generated__/types';
import { openInNewTab } from '~/apps/consumer/utils/browser.utils';
import { getAgentProfilePage } from '~/apps/consumer/utils/routes.utils';
import { MailIcon } from '~/common/icons/MailIcon';
import { TelephoneIcon } from '~/common/icons/TelephoneIcon';
import { UserAvatar } from '../../User/UserAvatar';
import { StorefrontCard } from '../StorefrontCard';

interface ListingAgentCardProps {
  listing: PublicListingFragment;
  listingAnnouncements: ListingAnnouncementFragment[];
  onLinkClick?: React.MouseEventHandler<HTMLAnchorElement>;
}

export const ListingAgentCard = ({
  listing,
  listingAnnouncements,
  onLinkClick,
}: ListingAgentCardProps) => {
  return (
    <StorefrontCard
      linkText="View all announcements"
      title="Meet the listing agent"
      onLinkClick={onLinkClick}
    >
      <Flex direction="column" gap={6}>
        <ListingAgentInformation listing={listing} />
        <ListingAgentMostRecentAnnouncement
          message={
            listingAnnouncements && listingAnnouncements.length > 0
              ? listingAnnouncements[0].message
              : ''
          }
        />
      </Flex>
    </StorefrontCard>
  );
};

interface ListingAgentInformationProps {
  listing: PublicListingFragment;
}

export const ListingAgentInformation = ({
  listing,
}: ListingAgentInformationProps) => {
  const iconSize = {
    width: '14px',
    height: '14px',
  };

  const { fullName, email, phoneNumber } = agentContactInfoFromListing(listing);

  const isPremium = listing?.isPremium;
  const userName = listing.listingAgent?.user?.userName;

  return (
    <Flex
      cursor="pointer"
      direction="row"
      gap={3}
      mx={6}
      onClick={() => {
        isPremium && userName && openInNewTab(getAgentProfilePage(userName));
      }}
    >
      <Flex>
        <UserAvatar user={listing.listingAgent?.user as UserObject} />
      </Flex>
      <Flex direction="column" justifyContent="center">
        <Text fontWeight="500">{fullName}</Text>
        <Flex color="whiteAlpha.600" direction="column">
          <Text>{listing.mlsListing?.office?.name}</Text>
        </Flex>
      </Flex>
      <Spacer />
      <Flex alignItems="center" color="whiteAlpha.700" direction="row" gap={2}>
        <Link
          href={`mailto:${email ?? ''}`}
          onClick={(e) => e.stopPropagation()}
        >
          <MailIcon {...iconSize} />
        </Link>
        <Link
          href={`tel:${phoneNumber ?? ''}`}
          onClick={(e) => e.stopPropagation()}
        >
          <TelephoneIcon {...iconSize} />
        </Link>
      </Flex>
    </Flex>
  );
};

interface ListingAgentMostRecentAnnouncementProps {
  message: string;
}

const ListingAgentMostRecentAnnouncement = ({
  message,
}: ListingAgentMostRecentAnnouncementProps) => {
  return (
    <Box
      backgroundColor="card.bgSubtle"
      borderRadius="sm"
      minHeight="4rem"
      mx={6}
      p={2}
    >
      <Text color="whiteAlpha.600" fontSize="md" lineHeight={1}>
        {message.length === 0
          ? 'No announcements yet'
          : `"${message.substring(0, 80)}${message.length > 80 ? '...' : ''}"`}
      </Text>
    </Box>
  );
};

export function agentContactInfoFromListing(listing?: {
  listingAgent?: {
    user: Partial<Pick<UserObject, 'fullName' | 'email' | 'phoneNumber'>>;
  };
  mlsListing?: {
    agent?: Partial<MlsListingAgentObject>;
  };
}) {
  if (!listing) {
    return {};
  }

  const phoneNumber =
    listing.listingAgent?.user.phoneNumber ??
    listing.mlsListing?.agent?.contact?.cell ??
    listing.mlsListing?.agent?.contact?.office;
  const email =
    listing.listingAgent?.user?.email ??
    listing.mlsListing?.agent?.contact?.email;

  const fullName =
    listing.listingAgent?.user.fullName ??
    (listing.mlsListing?.agent?.firstName &&
      listing.mlsListing?.agent?.lastName)
      ? `${listing.mlsListing?.agent?.firstName as string} ${
          listing.mlsListing?.agent?.lastName as string
        }`
      : '';

  return { fullName, email, phoneNumber };
}
