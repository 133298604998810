import { chakra, Flex } from '@chakra-ui/react';

export const Banner = chakra(Flex, {
  baseStyle: {
    alignItems: 'center',
    bgColor: 'sky.300',
    gap: 4,
    height: 12,
    minHeight: 12,
    justifyContent: 'center',
    width: '100%',
  },
});
