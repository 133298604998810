import { Flex, chakra } from '@chakra-ui/react';
import { MOBILE_HEADER_HEIGHT } from '../../components/Layouts/MobileHeader';

export const ListingSetupContentWrapper = chakra(Flex, {
  baseStyle: {
    justifyContent: 'center',
    px: {
      base: 6,
      md: 4,
      lg: 6,
    },
    py: 8,
    w: '100%',
  },
});

export const ListingSetupContainer = chakra(Flex, {
  baseStyle: {
    p: 0,
    m: 0,
    flexDirection: 'column',
    height: { base: `calc(100dvh - ${MOBILE_HEADER_HEIGHT})`, md: '100vh' },
    width: '100%',
    overflow: { base: 'auto', md: 'auto' },
  },
});
