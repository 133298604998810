import {
  OnDrag,
  OnDragGroup,
  OnRenderEnd,
  OnResize,
  OnResizeGroup,
} from 'react-moveable';
import MoveableManager from 'react-moveable/declaration/MoveableManager';
import { MoveableTargetType } from '../DocumentMapper/types';
import { FormField } from '../FormFields/types';

const ARROW_KEYS = ['ArrowLeft', 'ArrowUp', 'ArrowRight', 'ArrowDown'];

export function onDrag({ target, transform }: OnDrag) {
  target.style.transform = transform;
}

export const onDragGroup = ({ events }: OnDragGroup) => {
  events.forEach((ev) => {
    ev.target.style.transform = ev.transform;
  });
};

export const getPositionSizeOnRender = (
  event: OnRenderEnd,
  formField: FormField,
  width: number,
  height: number
) => {
  const type = event.target.dataset.type as MoveableTargetType;
  const position =
    type === 'label-field' ? formField.labelField || formField : formField;
  const style = event.target.style;
  const [xOffset, yOffset] = Array.from(
    style.transform?.match(/(-?\d+(\.+\d+)?)/g) || ['0', '0']
  );

  const x = parseFloat((position.x + parseFloat(xOffset) / width).toFixed(4));
  const y = parseFloat((position.y + parseFloat(yOffset) / height).toFixed(4));
  const newWidth = parseFloat(
    (parseFloat(style.width.replace('px', '')) / width).toFixed(4)
  );
  const newHeight = parseFloat(
    (parseFloat(style.height.replace('px', '')) / height).toFixed(4)
  );

  if (type === 'label-field') {
    return {
      labelField: {
        x,
        y,
        width: newWidth,
        height: newHeight,
      },
    };
  }

  return {
    x,
    y,
    width: newWidth,
    height: newHeight,
  };
};

export const onResize = ({
  target,
  delta,
  transform,
  width,
  height,
}: OnResize) => {
  target.style.transform = transform;
  delta[0] && (target.style.width = `${width}px`);
  delta[1] && (target.style.height = `${height}px`);
};

export const onMoveKeyDown = (options: {
  event: KeyboardEvent;
  moveable: MoveableManager;
}) => {
  const { event, moveable } = options;

  if (ARROW_KEYS.includes(event.key)) {
    const moveSize = event.shiftKey ? 10 : 1;
    let x = moveable.state.left;
    let y = moveable.state.top;

    const elementType = event.target?.constructor.name;

    // Only preventDefault when target is body
    if (!['HTMLBodyElement', 'HTMLDivElement'].includes(elementType || '')) {
      return;
    }

    event.preventDefault();

    switch (event.key) {
      case 'ArrowDown':
        y = moveable.state.top + moveSize;
        break;
      case 'ArrowUp':
        y = moveable.state.top - moveSize;
        break;
      case 'ArrowLeft':
        x = moveable.state.left - moveSize;
        break;
      case 'ArrowRight':
        x = moveable.state.left + moveSize;
        break;
    }

    moveable.request('draggable', {
      x,
      y,
      isInstant: true,
    });
  }
};

export const onResizeGroup = ({ events }: OnResizeGroup) => {
  events.forEach((event) => {
    onResize(event);
  });
};
