import { Button, ButtonProps, Flex } from '@chakra-ui/react';
import { FieldType } from '@client/graphql/__generated__/types';

import { FIELD_ICONS } from '@document/utils/field-icons';
import { forwardRef, memo } from 'react';
import { enumToLabel } from '../../../utils/enums';

export interface WidgetButtonProps extends ButtonProps {
  fieldType: FieldType;
}

export const WidgetButton = memo(
  forwardRef(({ fieldType, ...props }: WidgetButtonProps, ref) => {
    return (
      <Button
        ref={ref}
        _hover={{
          bgColor: 'gray.50',
          borderColor: 'gray.300',
        }}
        bgColor="white"
        colorScheme="gray"
        cursor="grab"
        flexGrow={1}
        flexShrink={0}
        height={10}
        minWidth="80px"
        pl={4}
        position="relative"
        pr={2}
        size="sm"
        variant="outline"
        width="auto"
        {...props}
      >
        <Flex left={3} position="absolute">
          {FIELD_ICONS[fieldType]}
        </Flex>
        <Flex flexShrink={0} width="25px" />
        {enumToLabel(fieldType)}
      </Button>
    );
  })
);
