import {
  Button,
  Divider,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import {
  DocumentType,
  IndigoContractsForCurrentUserFragment,
  UsState,
} from '@client/graphql/__generated__/types';
import { FC, memo, ReactNode, useRef, useState } from 'react';
import {
  IndigoContractForm,
  IndigoContractFormMutationRef,
} from './IndigoContractForm';

interface SendIndigoContractModalProps {
  title: string;
  titleTag?: ReactNode;
  usState?: UsState;
  documentType: DocumentType;
  isOpen: boolean;
  buttonText: string;
  indigoContract?: IndigoContractsForCurrentUserFragment;
  onClose: () => void;
  onContinue: (indigoContract: { id: string }) => void;
}

export const SendIndigoContractModal: FC<SendIndigoContractModalProps> = memo(
  function SendIndigoContractModal(props) {
    const { buttonText, isOpen, onClose, title, onContinue } = props;
    const [isReady, setIsReady] = useState(false);
    const [saving, setSaving] = useState(false);
    const mutationRef = useRef<IndigoContractFormMutationRef>();

    return (
      <Modal
        closeOnOverlayClick
        blockScrollOnMount={false}
        isOpen={isOpen}
        scrollBehavior="inside"
        size={{ base: 'full', md: 'lg' }}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent maxWidth="600px !important">
          <ModalCloseButton />
          <ModalHeader>
            <HStack>
              <Text>{title}</Text>
              {props.titleTag}
            </HStack>
          </ModalHeader>

          <ModalBody>
            <IndigoContractForm
              documentType={props.documentType}
              indigoContract={props.indigoContract}
              mutationRef={mutationRef}
              requiredFormStates={['selectedDocument', 'selectedParty']}
              onIsReady={setIsReady}
            />
          </ModalBody>

          <Divider />

          <ModalFooter>
            <Button
              isDisabled={!isReady}
              isLoading={saving}
              size="sm"
              width="100%"
              onClick={() => {
                setSaving(true);
                void mutationRef
                  .current?.()
                  .then((indigoContract) => {
                    if (indigoContract) {
                      onContinue(indigoContract);
                    }
                  })
                  .finally(() => {
                    setSaving(false);
                  });
              }}
            >
              {buttonText}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  }
);
