import {
  useDeleteListingDocumentsMutation,
  useUploadAndExtractAgentNegotiationDocumentsMutation,
  useUploadListingDocumentMutation,
} from '@client/graphql/__generated__/main-operations';
import { LISTING_SETUP_SIDEBAR_REFETCH_QUERIES } from '~/apps/consumer/components/Listings/ListingSetup/ListingSetupSidebar/ListingSetupSidebar';

const mutationOptions = {
  refetchQueries: LISTING_SETUP_SIDEBAR_REFETCH_QUERIES,
};

export const useListingDocumentMutations = () => {
  const [uploadListingDocuments, { loading: uploading }] =
    useUploadListingDocumentMutation(mutationOptions);

  const [deleteListingDocuments, { loading: deleting }] =
    useDeleteListingDocumentsMutation(mutationOptions);

  const [
    uploadAndExtractAgentNegotiation,
    { loading: uploadingAgentNegotiation },
  ] = useUploadAndExtractAgentNegotiationDocumentsMutation();

  return {
    uploadListingDocuments,
    uploading,
    deleteListingDocuments,
    deleting,
    uploadAndExtractAgentNegotiation,
    uploadingAgentNegotiation,
  };
};
