/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useCheckPermissionQuery } from '@client/graphql/__generated__/main-operations';
// prettier-ignore
// @ts-ignore
import type { RuleFNs,RuleKeys } from '@services/main/src/modules/permissions/permission.types';

interface UseCheckPermissionOptions {
  skip?: boolean;
}

export const useCheckPermission = <
  TRule extends RuleKeys,
  TArgs extends Parameters<RuleFNs[TRule]>,
>(
  rule: TRule,
  options: UseCheckPermissionOptions,
  ...args: TArgs
) => {
  const { data, loading } = useCheckPermissionQuery({
    variables: {
      input: {
        rule: rule as string,
        args: JSON.stringify(args),
      },
    },
    fetchPolicy: 'cache-first',
    skip: options.skip,
  });

  return {
    loading,
    isAuthorized: data?.checkPermission.isAuthorized,
    message: data?.checkPermission.message,
  };
};
