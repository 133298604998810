import { Button, Flex, Heading, IconButton } from '@chakra-ui/react';
import { ArrowLeftIcon } from 'lucide-react';
import { Link } from 'react-router-dom';
import { HEADER_HEIGHT } from './constants';

export const BuyerOfferAnalysisHeader = () => {
  return (
    <Flex
      alignItems="center"
      backdropFilter="blur(4px)"
      bg="indigo.700"
      borderBottom="1px solid"
      borderBottomColor="whiteAlpha.200"
      height={HEADER_HEIGHT}
      justifyContent="space-between"
      position="sticky"
      px={4}
      py={3}
      top={0}
      width="100%"
      zIndex={1}
    >
      <IconButton
        aria-label="Back"
        as={Link}
        color="white"
        icon={<ArrowLeftIcon />}
        size="sm"
        to="/"
        variant="ghost"
      />
      <Heading color="white" size="xxs">
        Offer Designer
      </Heading>
      <Button bg="blueGradient" size="xs">
        Prepare Buyer Report
      </Button>
    </Flex>
  );
};
