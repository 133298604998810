import { Flex } from '@chakra-ui/react';
import { ComponentProps } from 'react';
import { BuyerFormFields } from '../../BuyerFormFields';
import { FormCard } from './FormCard';

export const BuyerFormCard = (
  props: ComponentProps<typeof BuyerFormFields>
) => {
  return (
    <FormCard label="Basics">
      <Flex direction="column" gap={2}>
        <BuyerFormFields {...props} />
      </Flex>
    </FormCard>
  );
};
