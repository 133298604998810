import { useDocumentVersionListQuery } from '@client/graphql/__generated__/document-operations';
import { DocumentVersionListFieldFragment } from '@client/graphql/__generated__/types';
import { FormField } from '@document/components/FormFields/types';
import { cleanObjectTypeForInput } from '@document/utils/graphql';
import { useCallback, useEffect, useState } from 'react';

export const mapById = (
  documentVersionFields: DocumentVersionListFieldFragment[]
) => {
  return documentVersionFields.reduce(
    (output, field) => {
      const cleanedField = cleanObjectTypeForInput(field);

      output[field.id] = {
        ...cleanedField,
        documentIndex: 0,
        fieldType: cleanedField.fieldType || cleanedField.field?.fieldType,
        mappingKey: cleanedField.mappingKey || cleanedField.field?.mappingKey,
        inputFormat:
          cleanedField.inputFormat || cleanedField.field?.inputFormat,
        label: cleanedField.label || cleanedField.field?.label,
        readOnly:
          typeof cleanedField.readOnly === 'boolean'
            ? cleanedField.readOnly
            : cleanedField.field?.readOnly,
      };

      return output;
    },
    {} as Record<string, FormField>
  );
};

export const useDocumentVersionMapper = (documentVersionId: string) => {
  const [formFieldsById, setFormFieldsById] = useState<
    Record<string, FormField>
  >({});
  const [originalFormFieldsById, setOriginalFormFieldsById] = useState<
    Record<string, FormField>
  >({});
  const [deleteFormFieldIds, setDeleteFormFieldIds] = useState<string[]>([]);
  const { data, loading, refetch } = useDocumentVersionListQuery({
    fetchPolicy: 'network-only',
    variables: {
      fields: true,
      document: true,
      secondaryDocumentVersions: true,
      filters: {
        id: documentVersionId,
      },
    },
  });

  const documentVersion = data?.documentVersionList?.results?.[0];
  const updateFormField = useCallback(
    (formField: FormField | FormField[]) => {
      setFormFieldsById((prev) => {
        if (Array.isArray(formField)) {
          const changed = { ...prev };

          formField.forEach((field) => {
            changed[field.id] = {
              ...changed[field.id],
              ...field,
            };
          });

          return changed;
        } else {
          return {
            ...prev,
            [formField.id]: {
              ...prev[formField.id],
              ...formField,
            },
          };
        }
      });
    },
    [setFormFieldsById]
  );

  const deleteFormField = useCallback(
    (formField: FormField | FormField[]) => {
      if (Array.isArray(formField)) {
        const ids = formField.filter(({ isNew }) => !isNew).map(({ id }) => id);

        setDeleteFormFieldIds(ids);
        setFormFieldsById((prev) => {
          const prevData = { ...prev };
          formField.forEach(({ id }) => {
            delete prevData[id];
          });

          return { ...prevData };
        });
      }
    },
    [setFormFieldsById]
  );

  useEffect(() => {
    const formFieldsById = mapById(
      documentVersion?.documentVersionFields || []
    );

    setFormFieldsById(formFieldsById);
    setOriginalFormFieldsById(formFieldsById);
  }, [documentVersion?.documentVersionFields]);

  return {
    loading,
    documentVersion,
    formFieldsById,
    originalFormFieldsById,
    updateFormField,
    deleteFormField,
    deleteFormFieldIds,
    setDeleteFormFieldIds,
    refetch,
  };
};
