import { Box, Text, useToast } from '@chakra-ui/react';
import { useSendSignatureRequestToBuyersForOfferMutation } from '@client/graphql/__generated__/main-operations';
import { OfferObject } from '@client/graphql/__generated__/types';
import { useState } from 'react';
import { useOfferFlowContext } from '~/apps/consumer/pages/Storefront/OfferFlow/OfferFlowContext';
import { SubmitOfferModal } from '../../SubmitOfferModal/SubmitOfferModal';

export const PrepareOfferForBuyerSignatureModal = () => {
  const {
    latestOffer,
    signatureFormFieldInputs,
    showSendToBuyerForSignatureModal,
    setShowSendToBuyerForSignatureModal,
  } = useOfferFlowContext();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const toast = useToast();

  const [sendSignatureRequest, { loading: sendingSignatureRequest }] =
    useSendSignatureRequestToBuyersForOfferMutation();

  const handleSubmit = async (note: string) => {
    if (!latestOffer) return;
    try {
      const response = await sendSignatureRequest({
        variables: {
          input: {
            offerId: latestOffer?.id,
            message: note,
            signatureFormFields: signatureFormFieldInputs,
          },
        },
      });

      if (!response.errors?.length) {
        toast({
          title: 'Signature request to buyer sent!',
          description: 'Request to sign offer has been sent to your buyer.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        setIsSubmitted(true);
      }
    } catch (error) {
      toast({
        title: 'Error preparing offer',
        description: 'An unexpected error occurred while preparing your offer.',
        status: 'error',
        isClosable: true,
      });
    }
  };
  const bodyHeader = (
    <Box fontSize="lg">
      <Text color="whiteAlpha.600">
        You’re ready for your buyer to sign, that’s great!
      </Text>
      <Text color="white" fontWeight="500">
        Here's what happens next
      </Text>
    </Box>
  );

  const buyersAgentId = latestOffer?.buyersAgent?.user?.id;
  const needsBuyerAgentSignature =
    buyersAgentId &&
    signatureFormFieldInputs.some((input) => input.signerId === buyersAgentId);

  const bodyList = [
    `We’ll email an e-sign link to${
      needsBuyerAgentSignature ? ' you and' : ''
    } your buyer with the entire offer package`,
    `You’ll be notified when they open it, and when they sign`,
    `Once signed, you can submit to the Listing Agent when you’re ready`,
  ];

  return (
    <SubmitOfferModal
      bodyHeader={bodyHeader}
      bodyList={bodyList}
      handleSubmit={handleSubmit}
      isLoading={sendingSignatureRequest}
      isOpen={showSendToBuyerForSignatureModal}
      isSubmitted={isSubmitted}
      modalTitle="Prepare offer for buyer signature"
      notePlaceholder="Add a note"
      offer={latestOffer as OfferObject}
      sentMessage="We’ll notify you once they sign."
      submitButtonText="Send to buyer"
      onClose={() => setShowSendToBuyerForSignatureModal(false)}
    />
  );
};
