import { Button, Flex, Image, Text, VStack } from '@chakra-ui/react';
import { ListingObject } from '@client/graphql/__generated__/types';
import { useActivityPageContext } from '../../contexts/ActivityContext';
import { getPropertySource } from '../../utils/listing.utils';
import { SIDEBAR_WIDTH } from '../PageDetailLayout/PageDetailLayoutSidebar';

export const AlertsPageSidebar = () => {
  const { activeListing, setActiveListing, followedListingsForCurrentUser } =
    useActivityPageContext();

  const handleClick = (listing: ListingObject) => {
    setActiveListing(listing);
  };

  return (
    <VStack
      alignItems="flex-start"
      bgColor="bg.mainDark"
      borderRight="1px solid"
      borderRightColor="border.divider"
      height="100vh"
      left={0}
      minWidth={SIDEBAR_WIDTH}
      overflow="auto"
      overscrollBehavior="contain"
      p={4}
      spacing={4}
      top={0}
    >
      <Text fontWeight="medium" size="sm">
        Properties
      </Text>

      <Flex flexDirection="column" gap={3} overflowY="auto" width="100%">
        {followedListingsForCurrentUser?.map((followedListing) => {
          return (
            <Button
              key={followedListing.id}
              _hover={{
                backgroundColor: 'bg.hover',
              }}
              background={
                followedListing.id === activeListing?.id ? 'bg.hover' : ''
              }
              color="link.secondaryNav"
              display="flex"
              flexDir="row"
              fontSize={{ base: 'sm', md: 'md' }}
              height="auto"
              iconSpacing={2}
              justifyContent="flex-start"
              px={4}
              py={2}
              transition="color 0.3s"
              variant="unstyled"
              width="100%"
              onClick={() => handleClick(followedListing)}
            >
              <Flex alignItems="center">
                <Image
                  borderRadius="4px"
                  height={5}
                  mr={3}
                  src={getPropertySource(
                    followedListing?.mlsListing?.photos[0]
                  )}
                  width={6}
                />
                <Text fontWeight="semiBold" size="sm">
                  {followedListing?.mlsListing?.address?.streetNumberText}{' '}
                  {followedListing?.mlsListing?.address?.streetName}{' '}
                  {followedListing?.mlsListing?.address?.unit ?? ''}
                </Text>
              </Flex>
            </Button>
          );
        })}
      </Flex>
    </VStack>
  );
};
