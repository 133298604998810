import {
  Card,
  CardBody,
  HStack,
  Heading,
  Link,
  Text,
  VStack,
} from '@chakra-ui/react';
import {
  EmbeddedSignatureRequestFragment,
  EmbeddedSignatureRequestSecondaryDocumentFragment,
} from '@client/graphql/__generated__/types';
import { FC, useMemo } from 'react';
import { DocIcon } from '~/common/icons';

interface BuyerAgencyAgreementDocsReviewProps {
  embeddedSignatureRequest: EmbeddedSignatureRequestFragment;
}

const DocCard = ({
  document,
}: {
  document: EmbeddedSignatureRequestSecondaryDocumentFragment;
}) => {
  return (
    <Card maxWidth="90vw" overflowX="hidden" width="100%">
      <CardBody as={HStack}>
        <HStack flexGrow={1}>
          <DocIcon color="purple.500" />
          <Text>{document.name}</Text>
        </HStack>
        <Link isExternal href={document.activeDocumentVersion.file.url} pl={4}>
          View
        </Link>
      </CardBody>
    </Card>
  );
};

export const BuyerAgencyAgreementDocsReview: FC<
  BuyerAgencyAgreementDocsReviewProps
> = ({ embeddedSignatureRequest }) => {
  const { primaryDocument } = embeddedSignatureRequest;
  const secondaryDocuments = useMemo(() => {
    return (
      primaryDocument?.defaultSecondaryDocuments.map(
        (dsd) => dsd.secondaryDocument
      ) || []
    );
  }, [primaryDocument?.defaultSecondaryDocuments]);

  return secondaryDocuments.length > 0 ? (
    <VStack maxWidth="500px" px={4} spacing={2}>
      <Heading as="h2" fontSize="2xl">
        Please review these documents
      </Heading>

      <Text color="whiteAlpha.600" textAlign="center">
        The following documents can help you understand the details of the
        agreement you’re going to sign.
      </Text>

      <VStack mt={4}>
        {secondaryDocuments.map((document) => (
          <DocCard key={document.id} document={document} />
        ))}
      </VStack>
    </VStack>
  ) : null;
};
