import { Box, FormControl, FormLabel, Icon, VStack } from '@chakra-ui/react';
import { MlsListingFiltersInput } from '@client/graphql/__generated__/types';
import { Select } from 'chakra-react-select';
import isNil from 'lodash/isNil';
import { HandshakeIcon } from 'lucide-react';
import { useExploreContext } from '../ExploreContext';
import { FilterDropdownButton } from './FilterDropdownButton';
import { POSSESSION_OPTIONS, YES_NO_OPTIONS } from './constants';
import { getNumActiveFilters } from './helpers';

export const SellerPreferenceFilters = () => {
  const { filters } = useExploreContext();

  const sellerPreferenceFiltersList: (keyof MlsListingFiltersInput)[] = [
    'hasSellerConcessions',
    'sellerOrBuyerPossession',
  ];

  const numActiveFilters = getNumActiveFilters(
    filters,
    sellerPreferenceFiltersList
  );

  return (
    <FilterDropdownButton
      activeFilterCount={numActiveFilters}
      filterGroupName="Seller preferences"
      icon={<Icon as={HandshakeIcon} />}
    >
      <VStack>
        <SellerPreferenceFilterContent />
      </VStack>
    </FilterDropdownButton>
  );
};

export const SellerPreferenceFilterContent = () => {
  const { filters, updateFilters } = useExploreContext();

  return (
    <>
      <FormControl>
        <FormLabel>Seller Concessions</FormLabel>
        <Box w="full">
          <Select
            isClearable
            useBasicStyles
            defaultValue={
              !isNil(filters?.hasSellerConcessions)
                ? YES_NO_OPTIONS.find(
                    (option) => option.value === filters.hasSellerConcessions
                  )
                : undefined
            }
            options={YES_NO_OPTIONS}
            placeholder="Any"
            onChange={(options) => {
              updateFilters({
                hasSellerConcessions: options?.value,
              });
            }}
          />
        </Box>
      </FormControl>
      <FormControl>
        <FormLabel>Seller / Buyer Possession</FormLabel>
        <Box w="full">
          <Select
            isClearable
            useBasicStyles
            defaultValue={
              filters?.sellerOrBuyerPossession
                ? POSSESSION_OPTIONS.find(
                    (option) => option.value === filters.sellerOrBuyerPossession
                  )
                : undefined
            }
            options={POSSESSION_OPTIONS}
            placeholder="Any"
            onChange={(options) => {
              updateFilters({
                sellerOrBuyerPossession: options?.value,
              });
            }}
          />
        </Box>
      </FormControl>
    </>
  );
};
