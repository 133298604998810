import {
  Flex,
  Icon,
  NumberInput,
  NumberInputField,
  Text,
} from '@chakra-ui/react';
import {
  ConfidenceLevel,
  KeyTermType,
  KeyTermUnit,
  MarketDataSourceType,
} from '@client/graphql/__generated__/types';
import isNil from 'lodash/isNil';
import { CircleAlertIcon } from 'lucide-react';
import { useRef, useState } from 'react';
import { CurrencyInput } from '~/common/components/Inputs/CurrencyInput';
import { SignalStrength } from '~/common/icons/SignalIcon';
import { COLLAPSED_ROW_HEIGHT, EXPANDED_ROW_HEIGHT } from './constants';
import { KeyTermsRangeChart } from './KeyTermsRangeChart';
import { SourceTags } from './SourceTags';
import { formatRangeValue } from './utils';

interface KeyTermsRowProps {
  isExpanded: boolean;
  setExpandedRow: (row: KeyTermType | null) => void;
  label: string;
  range?: [number, number];
  row: KeyTermType;
  unit?: KeyTermUnit;
  source?: MarketDataSourceType;
  confidence?: ConfidenceLevel;
  distanceMiles?: number;
  timePeriodMonths?: number;
  errorMessage?: string;
}

export const KeyTermsRow = ({
  isExpanded,
  setExpandedRow,
  label,
  range,
  row,
  unit,
  source,
  confidence,
  distanceMiles,
  timePeriodMonths,
  errorMessage,
}: KeyTermsRowProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [value, setValue] = useState<number | null>(null);

  const isSingleValue = range && range[0] === range[1];

  const rangeLabel =
    range && unit
      ? isSingleValue
        ? formatRangeValue(range[0], unit)
        : `${formatRangeValue(range[0], unit)} - ${formatRangeValue(
            range[1],
            unit
          )}`
      : undefined;
  const sourceLabel = source?.toLowerCase()?.split('_')?.join(' ');

  const dotColor = !range
    ? undefined
    : (value || 0) < range[0] || (value || 0) > range[1]
      ? 'red.500'
      : 'green.500';

  return (
    <Flex
      ref={containerRef}
      borderBottom="1px solid"
      borderBottomColor="whiteAlpha.200"
      direction="column"
      justifyContent="center"
      px={4}
      tabIndex={0}
      onBlur={() => {
        setExpandedRow(null);
      }}
      onClick={() => {
        if (inputRef.current !== document.activeElement) {
          inputRef.current?.focus();
        }
      }}
      onFocus={() => {
        if (!isSingleValue && range) {
          setExpandedRow(row);
        }
      }}
      onMouseDown={() => {
        if (inputRef.current !== document.activeElement && !isExpanded) {
          inputRef.current?.focus();
        }
      }}
    >
      <Flex
        alignItems="center"
        gap={6}
        height={isExpanded ? EXPANDED_ROW_HEIGHT : COLLAPSED_ROW_HEIGHT}
        py={4}
        transition="height 0.3s ease-in-out"
      >
        <Flex flexBasis={0} flexGrow={1} height="100%">
          <Flex direction="column" height="100%" justifyContent="space-between">
            <Text fontWeight="medium" textTransform="capitalize">
              {label}
            </Text>
          </Flex>
        </Flex>
        <Flex flexBasis={0} flexGrow={4} height="100%">
          <Flex
            direction="column"
            height="100%"
            justifyContent="space-between"
            width="100%"
          >
            <Flex height="100%">
              <Flex flexBasis={0} flexGrow={2} height="100%">
                {range && (
                  <Flex direction="column">
                    <Text fontSize="24px" fontWeight="medium">
                      {rangeLabel}
                    </Text>
                    <SourceTags
                      confidence={confidence as unknown as SignalStrength}
                      distanceMiles={distanceMiles}
                      isExpanded={isExpanded}
                      sourceLabel={sourceLabel}
                      timePeriodMonths={timePeriodMonths}
                    />
                  </Flex>
                )}
                {errorMessage && (
                  <Flex alignItems="center" gap={1}>
                    <Icon as={CircleAlertIcon} color="red.500" />
                    <Text>{errorMessage}</Text>
                  </Flex>
                )}
              </Flex>
              <Flex
                flexBasis={0}
                flexGrow={2}
                justifyContent="center"
                position="relative"
              >
                {unit === KeyTermUnit.USD && (
                  <CurrencyInput
                    ref={inputRef}
                    fontSize="24px"
                    fontWeight="medium"
                    placeholder="$0"
                    value={value ?? undefined}
                    onValueChange={(value) => {
                      const newParsedValue = value
                        ? Number.parseFloat(value)
                        : null;
                      setValue(newParsedValue);
                    }}
                  />
                )}
                {unit === KeyTermUnit.DAYS && (
                  <NumberInput
                    size="lg"
                    width="100%"
                    onChange={(_, value) => {
                      setValue(value);
                    }}
                  >
                    <NumberInputField
                      ref={inputRef}
                      backgroundColor="input.bg"
                      fontSize="24px"
                      fontWeight="medium"
                      placeholder="0 days"
                      value={value ?? undefined}
                    />
                  </NumberInput>
                )}
                {!isSingleValue && range && unit && !isNil(value) && (
                  <Flex
                    backgroundColor={dotColor}
                    borderRadius="full"
                    boxSize={2}
                    position="absolute"
                    right={3}
                    top={5}
                  />
                )}
              </Flex>
            </Flex>
            {isExpanded && !isSingleValue && range && unit && (
              <KeyTermsRangeChart
                dotColor={dotColor}
                range={range}
                unit={unit}
                value={value}
              />
            )}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
