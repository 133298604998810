import { Box, VStack } from '@chakra-ui/react';
import { FC, memo } from 'react';
import { Outlet } from 'react-router';
import { ContractPreview } from '~/apps/consumer/components/OfferFlow/ContractPreview/ContractPreview';
import { useIsMobile } from '~/common/hooks/useIsMobile';
import { CONTINUE_BUTTON_HEIGHT } from './OfferFlowContainer';
import {
  OfferFlowContextProvider,
  OfferFlowContextProviderProps,
  useOfferFlowContext,
} from './OfferFlowContext';

interface OfferFlowContentOutletProps {
  maxWidth?: string;
}

const OfferFlowContentOutlet: FC<OfferFlowContentOutletProps> = memo(
  function OfferFlowContentOutlet({ maxWidth = '100%' }) {
    const isMobile = useIsMobile();
    const viewportHeight = window.visualViewport?.height || window.innerHeight;
    const { showContractPreview } = useOfferFlowContext();

    return isMobile ? (
      <VStack
        bgColor="bg.mainDark"
        bottom={0}
        left={0}
        overscrollBehavior="contain"
        position="fixed"
        right={0}
        top={0}
      >
        <VStack height="100%" width="100%">
          <Outlet context={{ maxWidth, viewportHeight }} />
          {isMobile && (
            <Box
              display={showContractPreview ? undefined : 'none'}
              mb={CONTINUE_BUTTON_HEIGHT}
              width="100%"
            >
              <ContractPreview noPortal />
            </Box>
          )}
        </VStack>
      </VStack>
    ) : (
      <Outlet context={{ maxWidth, viewportHeight }} />
    );
  }
);

interface OfferFlowOutletProps
  extends Omit<OfferFlowContextProviderProps, 'children'> {
  maxWidth?: string;
}

export const OfferFlowOutlet: FC<OfferFlowOutletProps> = memo(
  function OfferFlowOutlet({
    headerTitle,
    showStartOverButton,
    listing,
    maxWidth = '100%',
    waitToLoadQueries = false,
  }) {
    return (
      <OfferFlowContextProvider
        headerTitle={headerTitle}
        listing={listing}
        showStartOverButton={showStartOverButton}
        waitToLoadQueries={waitToLoadQueries}
      >
        <OfferFlowContentOutlet maxWidth={maxWidth} />
      </OfferFlowContextProvider>
    );
  }
);
