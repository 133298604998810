import { debounce } from 'lodash';
import {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useIsMobile } from '~/common/hooks/useIsMobile';
import { VisiblePage } from '../types';

export const useIntersectionObserver = (options: {
  scrollableRef?: MutableRefObject<HTMLDivElement>;
  onVisiblePageChange?: (visiblePage: VisiblePage) => void;
}) => {
  const { scrollableRef, onVisiblePageChange } = options;
  const valuesRef = useRef({ visiblePageNumber: 0, visibleDocumentIndex: 0 });
  const [observer, setObserver] = useState<IntersectionObserver>();
  const [visiblePageNumber, setVisiblePageNumber] = useState<number>();
  const [visiblePages, setVisiblePages] = useState<number[]>([1, 2, 3]);
  const [visibleDocumentIndex, setVisibleDocumentIndex] = useState<number>(0);
  const isMobile = useIsMobile();

  const onIntersect = useCallback(
    (visibleEntry: IntersectionObserverEntry) => {
      if (visibleEntry && visibleEntry.intersectionRatio > 0) {
        const target = visibleEntry.target as HTMLElement;
        const dataset = target.dataset;
        const pageNumber = parseInt(dataset.pageNumber || '0');
        const documentIndex = parseInt(dataset.documentIndex || '0');
        const name = dataset.name;
        const visiblePages = [
          pageNumber - 2,
          pageNumber - 1,
          pageNumber,
          pageNumber + 1,
          pageNumber + 2,
        ];

        if (
          valuesRef.current.visibleDocumentIndex !== documentIndex ||
          valuesRef.current.visiblePageNumber !== pageNumber
        ) {
          setVisiblePageNumber(pageNumber);
          setVisiblePages(visiblePages);
          setVisibleDocumentIndex(documentIndex);
        }

        valuesRef.current.visibleDocumentIndex = documentIndex;
        valuesRef.current.visiblePageNumber = pageNumber;

        if (onVisiblePageChange) {
          onVisiblePageChange({ documentIndex, pageNumber, name });
        }
      }
    },
    [onVisiblePageChange]
  );

  useEffect(() => {
    const observerOptions = {
      root: scrollableRef?.current,
      threshold: isMobile ? undefined : [0.4, 0.6, 0.7, 0.9, 1],
    };

    const observerCallback = debounce<IntersectionObserverCallback>(
      (entries) => {
        const visibleEntry = Array.from(entries).reduce((prev, next) =>
          prev && prev.intersectionRatio > next.intersectionRatio ? prev : next
        );

        onIntersect(visibleEntry);
      },
      isMobile ? 50 : 150
    );

    const newObserver = new IntersectionObserver(
      observerCallback,
      observerOptions
    );

    setObserver(newObserver);

    return () => newObserver.disconnect();
  }, [isMobile, onIntersect, scrollableRef]);

  return {
    visibleDocumentIndex,
    visiblePages,
    visiblePageNumber,
    observer,
  };
};
