import {
  GuestbookSignerFragment,
  Roles,
} from '@client/graphql/__generated__/types';
import { parsePhoneNumber } from 'awesome-phonenumber';
import dayjs from 'dayjs';

export const getSignersCsvData = (signers: GuestbookSignerFragment[]) => {
  const data = signers.map((signer) => {
    const type = signer.user.roles.includes(Roles.AGENT) ? 'Agent' : 'Buyer';

    const phoneNumber =
      parsePhoneNumber(signer.user.phoneNumber)?.number?.national ||
      signer.user.phoneNumber;

    return {
      name: signer.user.fullName,
      type,
      brokerage: type === 'Agent' ? signer.office?.OfficeName : 'N/A',
      sign_up_date: dayjs(signer.signedAt as Date).format('MMM D, YYYY'),
      sign_up_source: signer.openHouseId ? 'Open House' : 'Storefront',
      phone_number: phoneNumber,
      email: signer.user.email,
      subscribed: signer.isSubscribed ? 'Yes' : 'No',
      working_with_agent:
        type === 'Agent' ? 'N/A' : signer.isWorkingWithAgent ? 'Yes' : 'No',
    };
  });

  return data;
};
