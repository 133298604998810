import {
  Box,
  Card,
  CardBody,
  Flex,
  Grid,
  GridItem,
  Heading,
  Icon,
  Link,
  Tag,
  Text,
  VStack,
} from '@chakra-ui/react';
import {
  usePublicListingDocumentUploadsQuery,
  usePublicListingQuery,
} from '@client/graphql/__generated__/main-operations';
import {
  ListingDocumentUploadObject,
  PublicListingObject,
  UserObject,
} from '@client/graphql/__generated__/types';
import { FileTextIcon, LinkIcon } from 'lucide-react';
import { QRCodeCanvas } from 'qrcode.react';
import { useParams } from 'react-router';
import { filterAndGroupListingDocs } from '~/apps/consumer/components/Storefront/Documents/helpers';
import { agentContactInfoFromListing } from '~/apps/consumer/components/Storefront/ListingAgent/ListingAgentCard';
import { UserAvatar } from '~/apps/consumer/components/User/UserAvatar';
import { getPropertySource } from '~/apps/consumer/utils/listing.utils';
import { getStorefrontUrlWithAnalytics } from '~/apps/consumer/utils/storefront.utils';
import { IndigoLogo } from '~/common/icons/IndigoLogo';
import { formatPhoneNumber } from '~/common/utils/formatter';

export const ListingPackageInstructions = () => {
  const { propertySlug } = useParams<string>();

  const { data } = usePublicListingQuery({
    variables: {
      input: { propertySlug },
    },
    skip: !propertySlug,
  });

  const listing = data?.publicListing;

  const isPremium = listing?.isPremium;

  const { data: existingDocData } = usePublicListingDocumentUploadsQuery({
    variables: {
      input: {
        listingId: listing?.id || '',
      },
    },
    skip: !listing || !isPremium,
  });

  const { disclosures, additionalDocuments } = filterAndGroupListingDocs(
    (existingDocData?.publicListingDocumentUploads as ListingDocumentUploadObject[]) ||
      []
  );

  const sortedDocs = [...disclosures, ...additionalDocuments];

  const hasDocs = !!existingDocData?.publicListingDocumentUploads?.length;

  const { fullName, email, phoneNumber } = agentContactInfoFromListing(
    listing as PublicListingObject
  );

  const mlsId = listing?.mlsListing?.mlsId;
  const imageUrl = getPropertySource(listing?.mlsListing?.photos?.[0]);

  const storefrontUrl = propertySlug
    ? getStorefrontUrlWithAnalytics(propertySlug, {
        utm_source: 'mls',
        utm_content: 'storefront-pdf',
      })
    : '';

  return (
    <VStack alignItems="center" margin={16}>
      <VStack width="600px">
        <Flex justifyContent="space-between" mb={10} width="100%">
          <IndigoLogo />
          <Tag>MLS #{mlsId}</Tag>
        </Flex>
        <Flex
          alignItems="flex-start"
          flexDirection="column"
          flexGrow={0}
          gap={4}
        >
          <Text fontSize="24px">
            View and download disclosures, view seller preferences and make
            secure, validated offers at:
          </Text>
          <Card border="none">
            <CardBody>
              <Flex alignItems="center" gap={2}>
                <Icon as={LinkIcon} />
                <Link isExternal href={storefrontUrl}>
                  {storefrontUrl}
                </Link>
              </Flex>
            </CardBody>
          </Card>
          <Flex direction="column" position="relative">
            <Box
              background={`linear-gradient(180deg, rgba(0, 0, 0, 0.00) 44.25%, rgba(0, 0, 0, 0.81) 100%), url(${imageUrl}) lightgray 50% / cover no-repeat`}
              borderRadius={2}
              height="350px"
              width="600px"
            />
            <Flex
              bottom={5}
              flexDirection="column"
              left={5}
              position="absolute"
            >
              <Heading
                as="h4"
                fontWeight="semibold"
                p={0}
                size="xxs"
                zIndex={1}
              >
                {listing?.mlsListing?.address?.streetNumberText}{' '}
                {listing?.mlsListing?.address?.streetName}{' '}
                {listing?.mlsListing?.address?.unit
                  ? `${listing?.mlsListing?.address?.unit} `
                  : ''}
              </Heading>
              <Heading
                as="h4"
                color="whiteAlpha.700"
                fontWeight="400"
                p={0}
                size="xxs"
                zIndex={1}
              >
                {listing?.mlsListing?.address?.city}
                {', '}
                {listing?.mlsListing?.address?.state}{' '}
                {listing?.mlsListing?.address?.postalCode}
              </Heading>
            </Flex>
          </Flex>
          {hasDocs && (
            <Flex direction="column" width="100%">
              <Heading
                as="h4"
                color="whiteAlpha.700"
                fontStyle="italic"
                size="headline"
              >
                Contents
              </Heading>
              <Grid rowGap={2} templateColumns="repeat(2, minmax(0, 1fr))">
                {sortedDocs.slice(0, 4).map((doc) => (
                  <GridItem key={doc.id}>
                    <Flex alignItems="center" direction="row" gap={2}>
                      <Icon as={FileTextIcon} color="purple.500" />
                      <Text color="whiteAlpha.700">
                        {doc?.listingDocumentType?.name ||
                          doc?.customDocumentName}
                      </Text>
                    </Flex>
                  </GridItem>
                ))}
              </Grid>
              {sortedDocs.length > 4 && (
                <Text color="whiteAlpha.700" mt={2}>
                  and {sortedDocs.length - 4} more...
                </Text>
              )}
            </Flex>
          )}
          <Flex gap={4} width="100%">
            <Card width="100%">
              <CardBody>
                <Flex direction="row" gap={3}>
                  <Flex>
                    <UserAvatar
                      size="lg"
                      user={listing?.listingAgent?.user as UserObject}
                    />
                  </Flex>
                  <Flex direction="column" justifyContent="center">
                    <Text fontWeight="500">{fullName}</Text>
                    <Flex color="whiteAlpha.600" direction="column">
                      <Text>{listing?.mlsListing?.office?.name}</Text>
                    </Flex>
                    <Flex>
                      <Text color="whiteAlpha.600">
                        {formatPhoneNumber(phoneNumber)}{' '}
                        {phoneNumber && email ? '•' : ''} {email}
                      </Text>
                    </Flex>
                  </Flex>
                </Flex>
              </CardBody>
            </Card>
            <QRCodeCanvas size={112} value={storefrontUrl} />
          </Flex>
        </Flex>
      </VStack>
    </VStack>
  );
};
