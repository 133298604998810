import { Flex } from '@chakra-ui/react';
import { useEffect, useRef } from 'react';
import { MapboxGeocoder } from '~/apps/consumer/components/MapboxGeocoder/MapboxGeocoder';
import { useExploreContext } from '~/apps/consumer/components/MapSearch/ExploreContext';
import { PropertyFilters } from '~/apps/consumer/components/MapSearch/Filters/PropertyFilters';
import { useBuyerOfferAnalysisContext } from '../../../BuyerOfferAnalysisContext';
import { getIntitialFilterValues } from '../../utils';

export const EditCompsMapFilters = ({
  geocoderInputRef,
}: {
  geocoderInputRef?: React.RefObject<HTMLInputElement>;
}) => {
  const { listing } = useBuyerOfferAnalysisContext();
  const { mapRef, handleRetrieve, updateFilters } = useExploreContext();
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (listing) {
      const initialFilterValues = getIntitialFilterValues(listing);
      updateFilters(initialFilterValues);
    }
  }, [listing, updateFilters]);

  return (
    <Flex
      alignItems="center"
      background="black"
      borderBottom="1px solid"
      borderBottomColor="whiteAlpha.100"
      borderRadius="8px"
      gap={4}
      justifyContent="space-between"
      left="10px"
      position="absolute"
      pr={3}
      top="10px"
      zIndex="3"
    >
      <Flex grow={1} maxWidth="350px" minWidth="350px">
        <MapboxGeocoder
          borderRadius="none"
          height="56px"
          inputRef={geocoderInputRef}
          mapRef={mapRef}
          onRetrieve={handleRetrieve}
        />
      </Flex>
      <Flex ref={containerRef} gap={2}>
        <PropertyFilters containerRef={containerRef} />
      </Flex>
    </Flex>
  );
};
