import { Icon, IconProps } from '@chakra-ui/react';
import { memo } from 'react';

export const XTwitterIcon = memo((props: IconProps) => {
  return (
    <Icon
      fill="none"
      viewBox="0 0 21 20"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <svg fill="none" viewBox="0 0 26 24" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_4451_180)">
          <path
            d="M20.1889 0.69043H24.0222L15.6474 10.2718L25.5 23.3095H17.7853L11.7432 15.402L4.82954 23.3095H0.993604L9.95137 13.0613L0.5 0.69043H8.41011L13.8718 7.91798L20.1889 0.69043ZM18.8434 21.0127H20.9677L7.25601 2.86666H4.97671L18.8434 21.0127Z"
            fill="#1F1F3B"
          />
        </g>
        <defs>
          <clipPath id="clip0_4451_180">
            <rect
              fill="white"
              height="22.619"
              transform="translate(0.5 0.69043)"
              width="25"
            />
          </clipPath>
        </defs>
      </svg>
    </Icon>
  );
});
