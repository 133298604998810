import { Image, VStack } from '@chakra-ui/react';
import {
  TourModalPageSubtitle,
  TourModalPageTitle,
} from '../../../Tour/styled';
import DashboardOnboarding3 from './assets/dashboard-onboarding-3.png';

export const Page3 = () => {
  const title = 'AI-powered contract writing';
  const subtitle =
    'Auto-filled contracts, from agency to offer docs, keeps you compliant with industry changes';

  return (
    <VStack spacing={4}>
      <TourModalPageTitle fontSize="40px" fontWeight="medium">
        {title}
      </TourModalPageTitle>
      <TourModalPageSubtitle fontSize="xl" maxWidth="385px" mb={4}>
        {subtitle}
      </TourModalPageSubtitle>
      <Image maxWidth="385px" src={DashboardOnboarding3} width="100%" />
    </VStack>
  );
};
