import { Button, Flex, useDisclosure } from '@chakra-ui/react';
import {
  ListingDocumentTypeEnum,
  ListingDocumentTypeFragment,
  PublicListingDocumentTypeFragment,
} from '@client/graphql/__generated__/types';
import { useFormikContext } from 'formik';
import uniqueId from 'lodash/uniqueId';
import { FormGroupCard } from '~/apps/consumer/components/FormGroup/FormGroupCard';
import { DropzoneField } from '~/apps/consumer/components/Formik/DropzoneField';
import { DocumentFormValueType } from '~/apps/consumer/pages/ListingSetup/Documents/types';
import { DismissableInfo } from '~/common/components/DismissableInfo/DismissableInfo';
import { AddCustomDocumentModal } from './AddCustomDocumentModal';

export interface DocumentUploadGroupProps {
  title: string;
  isSubmitting: boolean;
  listingDocumentTypes: (
    | PublicListingDocumentTypeFragment
    | ListingDocumentTypeFragment
  )[];
  warningText?: string;
  isDisclosures: boolean;
  disableCustomDocs?: boolean;
}

export const DocumentUploadGroup = ({
  title,
  isSubmitting,
  listingDocumentTypes,
  warningText,
  isDisclosures,
  disableCustomDocs,
}: DocumentUploadGroupProps) => {
  const { isOpen: isWarningOpen, onClose: onWarningClose } = useDisclosure({
    defaultIsOpen: !!warningText,
  });
  const {
    isOpen: isAddCustomDocModalOpen,
    onOpen: onAddCustomDocModalOpen,
    onClose: onAddCustomDocModalClose,
  } = useDisclosure();

  const { values, setFieldValue } = useFormikContext<DocumentFormValueType>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const customDocs = Object.entries(values).filter(([_, value]) =>
    isDisclosures
      ? value.customDocumentType === ListingDocumentTypeEnum.DISCLOSURE &&
        value.file
      : value.customName &&
        value.customDocumentType === ListingDocumentTypeEnum.OTHER &&
        value.file
  );

  const onAddCustomDoc = async (data: { name: string; file: File }) => {
    const key = uniqueId(data.name);
    await setFieldValue(key, {
      customName: data.name,
      file: data.file,
      customDocumentType: isDisclosures
        ? ListingDocumentTypeEnum.DISCLOSURE
        : ListingDocumentTypeEnum.OTHER,
    });
  };

  return (
    <FormGroupCard hideButton title={title}>
      <Flex flexDirection="column" gap={5} pb={6} px={6}>
        {isWarningOpen && (
          <DismissableInfo
            children={warningText || ''}
            onClose={onWarningClose}
          />
        )}
        {listingDocumentTypes?.map((docType) => (
          <DropzoneField
            key={docType.id}
            disabled={isSubmitting}
            label={docType.name}
            name={`${docType.id}.file`}
          />
        ))}
        {!disableCustomDocs &&
          customDocs?.map((doc, index) => (
            <DropzoneField
              key={index}
              disabled={isSubmitting}
              label={doc[1]?.customName as string}
              name={`${doc[0]}.file`}
            />
          ))}
        {!disableCustomDocs && (
          <Button variant="outline" onClick={onAddCustomDocModalOpen}>
            Add additional {isDisclosures ? 'disclosures' : 'documents'}
          </Button>
        )}
        <AddCustomDocumentModal
          isDisclosures={isDisclosures}
          isOpen={isAddCustomDocModalOpen}
          onClose={onAddCustomDocModalClose}
          onSubmit={onAddCustomDoc}
        />
      </Flex>
    </FormGroupCard>
  );
};
