import { FormFieldContainer } from '@document/components/FormFields/FormFieldContainer';
import { FormField, ValuesType } from '@document/components/FormFields/types';
import { isEqual } from 'lodash';
import { FC, memo, useCallback, useMemo } from 'react';
import { CheckboxFormField } from './CheckboxFormField';

interface RadioFormFieldProps {
  formField: FormField;
  value?: boolean;
  tabIndex?: number;
  isDisabled?: boolean;
  onCheckedChange: (checkedFormField: FormField | null) => void;
}

interface RadioFormFieldGroupProps {
  formFields: FormField[];
  values: ValuesType;
  pageWidth: number;
  pageHeight: number;
  isDisabled?: boolean;
  tabIndices?: Record<string, number>;
  onChange: (formFields: Record<string, boolean>) => void;
}

export const RadioFormField: FC<RadioFormFieldProps> = memo(
  function RadioFormField({
    formField,
    value,
    tabIndex,
    onCheckedChange,
    isDisabled,
  }) {
    const onChange = useCallback(
      (checked: boolean) => {
        onCheckedChange(checked ? formField : null);
      },
      [onCheckedChange, formField]
    );

    return (
      <CheckboxFormField
        checked={!!value}
        data-testid="radio-form-field"
        isDisabled={isDisabled}
        tabIndex={tabIndex}
        title={formField.label}
        onChange={onChange}
      />
    );
  }
);

export const RadioFormFieldGroup: FC<RadioFormFieldGroupProps> = memo(
  function RadioFieldGroup({
    formFields,
    values,
    pageHeight,
    pageWidth,
    tabIndices,
    onChange,
    isDisabled,
  }) {
    const fieldValues = useMemo(() => {
      return formFields.reduce(
        (output, formField) => {
          output[formField.mappingKey] = values[
            formField.mappingKey
          ] as boolean;

          return output;
        },
        {} as Record<string, boolean>
      );
    }, [formFields, values]);

    const onCheckedChange = useCallback(
      (checkedField: FormField | null) => {
        const currentValues = formFields.reduce(
          (output, formField) => {
            output[formField.mappingKey] =
              formField.mappingKey === checkedField?.mappingKey;

            return output;
          },
          {} as Record<string, boolean>
        );

        if (!isEqual(fieldValues, currentValues)) {
          onChange(currentValues);
        }
      },
      [formFields, onChange, fieldValues]
    );

    return (
      <>
        {formFields.map((formField) => (
          <FormFieldContainer
            key={formField.id}
            formField={formField}
            pageHeight={pageHeight}
            pageWidth={pageWidth}
          >
            <RadioFormField
              formField={formField}
              isDisabled={isDisabled}
              tabIndex={tabIndices?.[formField.id]}
              value={!!values[formField.mappingKey]}
              onCheckedChange={onCheckedChange}
            />
          </FormFieldContainer>
        ))}
      </>
    );
  },
  (prevProps, nextProps) => {
    return JSON.stringify(prevProps) === JSON.stringify(nextProps);
  }
);
