import {
  Card,
  CardBody,
  HStack,
  Icon,
  IconButton,
  Text,
} from '@chakra-ui/react';
import isNil from 'lodash/isNil';
import { FileTextIcon, Trash2Icon } from 'lucide-react';
import { FC, memo } from 'react';
import { useOfferFlowContext } from '../../pages/Storefront/OfferFlow/OfferFlowContext';

interface DocumentRowProps {
  name: string;
  previewIndex?: number;
  isLoading?: boolean;
  onDelete?: () => void;
}

export const DocumentRow: FC<DocumentRowProps> = memo(function DocumentRow({
  name,
  previewIndex,
  isLoading,
  onDelete,
}) {
  const { updateContractPreviewState } = useOfferFlowContext();
  const isClickable = !isNil(previewIndex);

  return (
    <Card
      cursor={isClickable ? 'pointer' : 'default'}
      onClick={
        isClickable
          ? () => {
              updateContractPreviewState('show', previewIndex);
            }
          : undefined
      }
    >
      <CardBody py={3}>
        <HStack justifyContent="space-between">
          <HStack minWidth="0px">
            <Icon as={FileTextIcon} boxSize="20px" color="purple.500" />
            <Text overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
              {name}
            </Text>
          </HStack>
          {onDelete && (
            <IconButton
              aria-label="Delete document"
              color="whiteAlpha.700"
              icon={<Icon as={Trash2Icon} boxSize="16px" />}
              isLoading={isLoading}
              size="sm"
              variant="ghost"
              onClick={(event) => {
                event.stopPropagation();
                onDelete();
              }}
            />
          )}
        </HStack>
      </CardBody>
    </Card>
  );
});
