import { Box, Card, CardBody, Text } from '@chakra-ui/react';
import { StorefrontSubscriptionReason } from '@client/graphql/__generated__/types';
import { Bell } from 'lucide-react';
import { useStorefrontContext } from '~/apps/consumer/pages/Storefront/StorefrontContext';
import { ActivitiesList } from '../../Activity/ActivitiesList';
import { EmptyState } from '../../EmptyState';
import { SignGuestbookOverlay } from '../SignGuestbookOverlay';

export const ActivitiesTab = () => {
  const { listingActivities } = useStorefrontContext();

  return (
    <Box>
      {listingActivities?.length ? (
        <Card backdropFilter="none" p={3}>
          <CardBody>
            <SignGuestbookOverlay
              blurPx={3}
              borderRadius="md"
              reason={StorefrontSubscriptionReason.ACCESS_LISTING_ACTIVITY}
            />
            <ActivitiesList activities={listingActivities} />
          </CardBody>
        </Card>
      ) : (
        <EmptyState
          headerText="No new activities on this listing yet"
          icon={Bell}
          subText={
            <Text>
              Subscribe to{' '}
              <Text as="span" color="link.skyBlue">
                get alerts
              </Text>{' '}
              and stay up to date
            </Text>
          }
        />
      )}
    </Box>
  );
};
