import { Card, CardBody, CardHeader, Flex, Heading } from '@chakra-ui/react';
import { ListingDocumentUploadObject } from '@client/graphql/__generated__/types';
import { DismissableInfo } from '~/common/components/DismissableInfo/DismissableInfo';
import { ListingDocumentRow } from './ListingDocumentRow';

interface DocumentsDetailsCardProps {
  documentUploads: ListingDocumentUploadObject[];
  title: string;
  warningText: string;
  onViewDocument: (documentId: string) => void;
}

export const DocumentsDetailsCard = ({
  documentUploads,
  title,
  warningText,
  onViewDocument,
}: DocumentsDetailsCardProps) => {
  return (
    <Card>
      <CardHeader>
        <Heading size="heading">{title}</Heading>
      </CardHeader>
      <CardBody>
        <Flex direction="column" gap={6}>
          {warningText && <DismissableInfo children={warningText} />}
          <Flex direction="column">
            {documentUploads.map((doc, index) => {
              const isLastItem = index === documentUploads.length - 1;
              const isFirstItem = index === 0;

              return (
                <ListingDocumentRow
                  key={doc.id}
                  doc={doc}
                  isFirstItem={isFirstItem}
                  isLastItem={isLastItem}
                  onViewDocument={onViewDocument}
                />
              );
            })}
          </Flex>
        </Flex>
      </CardBody>
    </Card>
  );
};
