import { Flex } from '@chakra-ui/react';
import { FinancialInfoForm } from './FinancialInfoForm';
import { FormCard } from './FormCard';

export const FinancialInfoFormCard = ({
  showLoanAmount = true,
}: {
  showLoanAmount: boolean;
}) => {
  return (
    <FormCard label="Financial Info">
      <Flex direction="column" gap={2}>
        <FinancialInfoForm showLoanAmount={showLoanAmount} />
      </Flex>
    </FormCard>
  );
};
