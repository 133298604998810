import { Text } from '@chakra-ui/react';

export const UnstyledFormLabel = ({
  children,
}: {
  children: React.ReactNode;
}) => (
  <Text fontSize="14px" fontWeight={500} mb={1}>
    {children}
  </Text>
);
