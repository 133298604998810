/* eslint-disable @typescript-eslint/no-explicit-any */
import { ContractValidationFragment } from '@client/graphql/__generated__/types';
import { useCallback, useMemo } from 'react';

interface UseOfferValidationsOptions {
  validations: ContractValidationFragment[];
}

/**
 * Hook for handling offer validations.
 * @param options.offer - The offer object.
 * @returns An object containing the offer validations and a function to get the errors for a specific mapping key.
 */
export const useOfferValidations = ({
  validations,
}: UseOfferValidationsOptions) => {
  const validationsMap = useMemo(() => {
    if (!validations) {
      return {};
    }

    return validations.reduce(
      (output, validation) => {
        return {
          ...output,
          [validation.field.mappingKey]: validation,
        };
      },
      {} as Record<string, ContractValidationFragment>
    );
  }, [validations]);

  const getValidation = useCallback(
    (mappingKey: string) => {
      return validationsMap?.[mappingKey];
    },
    [validationsMap]
  );

  return {
    validations: validations || [],
    getValidation,
  };
};
