import {
  MlsListingFiltersInput,
  MlsListingStatus,
} from '@client/graphql/__generated__/types';
import {
  formatBooleanFilterValue,
  formatCurrencyFilterValue,
  formatDaysFilterValue,
  formatSelectFilterValue,
  formatSqFtFilterValue,
} from './helpers';

export type MappedFilterKeys = keyof Omit<
  MlsListingFiltersInput,
  'bbox' | 'premiumOnly' | 'closedDateRange'
>;

export const filterKeyToLabelMap: Record<MappedFilterKeys, string> = {
  priceRange: 'Price range',
  bedRange: 'Bed',
  fullBathRange: 'Full bath',
  halfBathRange: 'Half bath',
  sqftRange: 'Sqft',
  loanTypes: 'Loan type',
  statuses: 'Status',
  hasSellerConcessions: 'Has seller concessions',
  sellerOrBuyerPossession: 'Possession',
  daysOnMarket: 'Days on market',
  hasOffers: 'Has offers',
  numSubscribers: 'Number of subscribers',
};

export const filterKeyToFormatterMap: Record<
  Exclude<
    MappedFilterKeys,
    | 'numSubscribers'
    | 'bedRange'
    | 'fullBathRange'
    | 'halfBathRange'
    | 'closedDateRange'
  >,
  (value: number | string | boolean) => string | undefined
> = {
  priceRange: formatCurrencyFilterValue,
  sqftRange: formatSqFtFilterValue,
  loanTypes: (value: string) =>
    formatSelectFilterValue(value, LOAN_TYPE_OPTIONS),
  statuses: (value: string) =>
    formatSelectFilterValue(value, MLS_STATUS_OPTIONS),
  hasSellerConcessions: formatBooleanFilterValue,
  hasOffers: formatBooleanFilterValue,
  sellerOrBuyerPossession: (value: string) =>
    formatSelectFilterValue(value, POSSESSION_OPTIONS),
  daysOnMarket: (value: number) =>
    formatDaysFilterValue(value, DAYS_ON_MARKET_RANGE_OPTIONS),
};

export const PRICE_RANGE_OPTIONS = [
  {
    label: '$100,000',
    value: 100000,
  },
  {
    label: '$150,000',
    value: 150000,
  },
  {
    label: '$200,000',
    value: 200000,
  },
  {
    label: '$250,000',
    value: 250000,
  },
  {
    label: '$300,000',
    value: 300000,
  },
  {
    label: '$350,000',
    value: 350000,
  },
  {
    label: '$400,000',
    value: 400000,
  },
  {
    label: '$450,000',
    value: 450000,
  },
  {
    label: '$500,000',
    value: 500000,
  },
  {
    label: '$550,000',
    value: 550000,
  },
  {
    label: '$600,000',
    value: 600000,
  },
  {
    label: '$650,000',
    value: 650000,
  },
  {
    label: '$700,000',
    value: 700000,
  },
  {
    label: '$750,000',
    value: 750000,
  },
  {
    label: '$800,000',
    value: 800000,
  },
  {
    label: '$850,000',
    value: 850000,
  },
  {
    label: '$900,000',
    value: 900000,
  },
  {
    label: '$950,000',
    value: 950000,
  },
  {
    label: '$1,000,000',
    value: 1000000,
  },
  {
    label: '$1,250,000',
    value: 1250000,
  },
  {
    label: '$1,500,000',
    value: 1300000,
  },
  {
    label: '$1,750,000',
    value: 1750000,
  },
  {
    label: '$2,000,000',
    value: 2000000,
  },
  {
    label: '$2,250,000',
    value: 2250000,
  },
  {
    label: '$2,500,000',
    value: 2500000,
  },
  {
    label: '$2,750,000',
    value: 2750000,
  },
  {
    label: '$3,000,000',
    value: 3000000,
  },
];

export const BEDROOM_RANGE_OPTIONS = [
  {
    label: 'Studio',
    value: 0,
  },
  {
    label: '1 bed',
    value: 1,
  },
  {
    label: '2 beds',
    value: 2,
  },
  {
    label: '3 beds',
    value: 3,
  },
  {
    label: '4 beds',
    value: 4,
  },
  {
    label: '5 beds',
    value: 5,
  },
];

export const BATHROOM_RANGE_OPTIONS = [
  {
    label: '1 bath',
    value: 1,
  },
  {
    label: '2 baths',
    value: 2,
  },
  {
    label: '3 baths',
    value: 3,
  },
  {
    label: '4 baths',
    value: 4,
  },
  {
    label: '5 baths',
    value: 5,
  },
];

export const SQFT_RANGE_OPTIONS = [
  {
    label: '500 sqft',
    value: 500,
  },
  {
    label: '750 sqft',
    value: 750,
  },
  {
    label: '1000 sqft',
    value: 1000,
  },
  {
    label: '1250 sqft',
    value: 1250,
  },
  {
    label: '1500 sqft',
    value: 1500,
  },
  {
    label: '1750 sqft',
    value: 1750,
  },
  {
    label: '2000 sqft',
    value: 2000,
  },
  {
    label: '2250 sqft',
    value: 2250,
  },
  {
    label: '2500 sqft',
    value: 2500,
  },
  {
    label: '2750 sqft',
    value: 2750,
  },
  {
    label: '3000 sqft',
    value: 3000,
  },
  {
    label: '3500 sqft',
    value: 3500,
  },
  {
    label: '4000 sqft',
    value: 4000,
  },
  {
    label: '5000 sqft',
    value: 5000,
  },
  {
    label: '7500 sqft',
    value: 7500,
  },
];

export const LOAN_TYPE_OPTIONS = [
  {
    label: 'FHA',
    value: 'fha',
  },
  {
    label: 'VA Loan',
    value: 'va',
  },
  {
    label: 'Conventional',
    value: 'conventional',
  },
  {
    label: 'USDA Loan',
    value: 'usda',
  },
];

export const MLS_STATUS_OPTIONS = [
  {
    label: 'Active',
    value: MlsListingStatus.Active,
  },
  {
    label: 'Pending',
    value: MlsListingStatus.Pending,
  },
  {
    label: 'Coming Soon',
    value: MlsListingStatus.ComingSoon,
  },
  {
    label: 'Active Under Contract',
    value: MlsListingStatus.ActiveUnderContract,
  },
  {
    label: 'Closed',
    value: MlsListingStatus.Closed,
  },
];

export const YES_NO_OPTIONS = [
  {
    label: 'Yes',
    value: true,
  },
  {
    label: 'No',
    value: false,
  },
];

export const POSSESSION_OPTIONS = [
  {
    label: 'Seller',
    value: 'seller',
  },
  {
    label: 'Buyer',
    value: 'buyer',
  },
  {
    label: 'None',
    value: 'none',
  },
];

export const DAYS_ON_MARKET_RANGE_OPTIONS = [
  {
    label: '1 day',
    value: 1,
  },
  {
    label: '7 days',
    value: 7,
  },
  {
    label: '14 days',
    value: 14,
  },
  {
    label: '30 days',
    value: 30,
  },
  {
    label: '60 days',
    value: 60,
  },
  {
    label: '90 days',
    value: 90,
  },
  {
    label: '6 months',
    value: 183,
  },
  {
    label: '1 year',
    value: 366,
  },
];
