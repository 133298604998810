import { Icon, IconProps } from '@chakra-ui/react';

export const TwoPeopleIcon = (props: IconProps) => {
  return (
    <Icon
      fill="none"
      height="16px"
      viewBox="0 0 16 16"
      width="16px"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.6666 14V12.6667C10.6666 11.9594 10.3857 11.2811 9.8856 10.781C9.3855 10.281 8.70722 10 7.99998 10H3.99998C3.29274 10 2.61446 10.281 2.11436 10.781C1.61426 11.2811 1.33331 11.9594 1.33331 12.6667V14M14.6666 14V12.6667C14.6662 12.0758 14.4695 11.5018 14.1076 11.0349C13.7456 10.5679 13.2387 10.2344 12.6666 10.0867M10.6666 2.08667C11.2403 2.23353 11.7487 2.56713 12.1117 3.03487C12.4748 3.50261 12.6719 4.07789 12.6719 4.67C12.6719 5.26211 12.4748 5.83739 12.1117 6.30513C11.7487 6.77287 11.2403 7.10647 10.6666 7.25333M8.66665 4.66667C8.66665 6.13943 7.47274 7.33333 5.99998 7.33333C4.52722 7.33333 3.33331 6.13943 3.33331 4.66667C3.33331 3.19391 4.52722 2 5.99998 2C7.47274 2 8.66665 3.19391 8.66665 4.66667Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </Icon>
  );
};
