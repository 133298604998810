import { useContractSignatureFieldsQuery } from '@client/graphql/__generated__/document-operations';
import { ContractSignatureFieldsArgs } from '@client/graphql/__generated__/types';
import { useMemo } from 'react';
import { useDocumentVersionFormFields } from '~/services/document/hooks/useDocumentVersionFormFields';

export const useDocumentSignatureMapperQueries = (
  signatureArgs: ContractSignatureFieldsArgs
) => {
  const { data } = useContractSignatureFieldsQuery({
    variables: {
      args: signatureArgs,
    },
  });

  const signatureDocumentVersionFields = useDocumentVersionFormFields(
    data?.contractSignatureFields?.documentVersionFields || []
  );
  const signatureFormFields = useMemo(() => {
    return data?.contractSignatureFields?.signatureFormFields || [];
  }, [data?.contractSignatureFields?.signatureFormFields]);

  return {
    signatureDocumentVersionFields,
    signatureFormFields,
  };
};
