import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  IconButton,
} from '@chakra-ui/react';
import isNil from 'lodash/isNil';
import { Trash2 } from 'lucide-react';
import { useMemo } from 'react';
import { formatPercent } from '~/common/utils/formatter';
import { formatToCurrency } from '~/services/document/utils/number';
import { TextField } from '../../Formik/TextField';
import { SimpleList, SimpleListRow } from '../../Lists/SimpleList';
import { FinancialInfoForm } from '../AddBuyerForm/BuyerFormCards/FinancialInfoForm';
import { BuyerFormFields } from '../BuyerFormFields';
import { BuyerFormValues } from '../types';

interface SelectedPartyInfoCardProps {
  values: BuyerFormValues;
  showFinancingInfo?: boolean;
  isEditing: boolean;
  onConfirm: () => void;
  setIsEditing: (isEditing: boolean) => void;
  showCoBuyer: boolean;
  onRemoveCoBuyer: () => Promise<void>;
  showLoanAmount: boolean;
}

export const SelectedPartyInfoCard = ({
  values,
  showFinancingInfo = true,
  isEditing,
  onConfirm,
  setIsEditing,
  showCoBuyer,
  showLoanAmount,
  onRemoveCoBuyer,
}: SelectedPartyInfoCardProps) => {
  const isEntity = values.isEntity;

  const formFields = (
    <Flex direction="column" gap={3}>
      <BuyerFormFields isEntity={isEntity} />
      {showFinancingInfo && (
        <>
          <Divider borderColor="whiteAlpha.400" orientation="horizontal" />
          <FinancialInfoForm showLoanAmount={showLoanAmount} />
        </>
      )}
      {showCoBuyer && (
        <>
          <Divider borderColor="whiteAlpha.400" orientation="horizontal" />
          <FormControl isRequired justifyContent="space-between">
            <Flex alignItems="center" justifyContent="space-between">
              <FormLabel>Co-buyer name</FormLabel>
              <IconButton
                aria-label="Remove co-buyer"
                color="whiteAlpha.400"
                icon={<Icon as={Trash2} boxSize={4} />}
                mb={2}
                size="xs"
                variant="ghost"
                onClick={() => void onRemoveCoBuyer()}
              />
            </Flex>
            <TextField name="coBuyerName" />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Co-buyer email</FormLabel>
            <TextField name="coBuyerEmail" />
          </FormControl>
        </>
      )}
    </Flex>
  );

  const infoList = useMemo(
    () => (
      <SimpleList>
        <SimpleListRow label="Buyer name">
          <Box>{values?.buyerName}</Box>
        </SimpleListRow>
        <SimpleListRow label="Email">
          <Box>{values?.buyerEmail}</Box>
        </SimpleListRow>
        {isEntity && (
          <>
            <SimpleListRow label="Entity buyer">
              <Box>{values?.entityName}</Box>
            </SimpleListRow>
            <SimpleListRow label="Buyer title">
              <Box>{values?.entityTitle}</Box>
            </SimpleListRow>
          </>
        )}
        <SimpleListRow label="Financing Type">
          <Box>{values?.financingType}</Box>
        </SimpleListRow>
        {showLoanAmount && (
          <SimpleListRow label="Loan amount">
            <Box>{formatToCurrency(values?.loanAmount, 0)}</Box>
          </SimpleListRow>
        )}
        <SimpleListRow label="Percent down payment">
          {!isNil(values?.percentDownPayment) && (
            <Box>{formatPercent(values?.percentDownPayment, 3)}</Box>
          )}
        </SimpleListRow>
        <SimpleListRow label="Cash reserves">
          <Box>{formatToCurrency(values?.cashReserves, 0)}</Box>
        </SimpleListRow>

        {showCoBuyer && (
          <>
            <SimpleListRow label="Co-buyer name">
              <Box>{values?.coBuyerName}</Box>
            </SimpleListRow>
            <SimpleListRow label="Co-buyer email">
              <Box>{values?.coBuyerEmail}</Box>
            </SimpleListRow>
          </>
        )}
      </SimpleList>
    ),
    [isEntity, values, showCoBuyer, showLoanAmount]
  );

  return (
    <Card>
      <CardHeader>
        <Flex alignItems="center" justifyContent="space-between">
          Selected buyer info
          <Button
            height={7}
            isDisabled={
              (isEntity && (!values.entityName || !values.entityTitle)) ||
              ((!values.buyerName || !values.buyerEmail) && isEditing)
            }
            size="xs"
            onClick={() => {
              if (isEditing && onConfirm) {
                onConfirm();
              }
              void setIsEditing?.(!isEditing);
            }}
          >
            {isEditing ? 'Confirm' : 'Edit'}
          </Button>
        </Flex>
      </CardHeader>
      <CardBody gap={2}>{isEditing ? formFields : infoList}</CardBody>
    </Card>
  );
};
