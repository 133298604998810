import {
  Button,
  Flex,
  FlexProps,
  HStack,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import { AccessRole } from '@client/graphql/__generated__/types';
import { FC } from 'react';
import { AccessControlList } from '~/common/components/AccessControls/AccessControlList';
import { CenterSpinner } from '~/common/components/CenterSpinner';
import { SearchInput } from '~/common/components/Inputs/SearchInput';
import { useAppContext } from '~/services/main/contexts/AppContext';
import { Modal } from '../Modal/Modal';
import { AccessControlInviteForm } from './AccessControlInviteForm';
import { useAccessControls } from './useAccessControls';

interface AccessControlsProps {
  listingId?: string;
  partyId?: string;
  organizationId?: string;
  title?: string | React.ReactNode;
  containerProps?: FlexProps;
  inviteAccessRoles?: AccessRole[];
  inviteDefaultAccessRole?: AccessRole;
  onSelfRemoved?: () => void;
}

export const AccessControls: FC<AccessControlsProps> = ({
  listingId,
  organizationId,
  partyId,
  containerProps,
  title = 'Admins',
  inviteAccessRoles = [AccessRole.CO_OWNER, AccessRole.ADMIN],
  inviteDefaultAccessRole = AccessRole.ADMIN,
  onSelfRemoved,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { user } = useAppContext();
  const {
    acessControls,
    loading,
    refetchInvites,
    updateFilters,
    isAdmin,
    deleteAccessControlOrInvitation,
  } = useAccessControls({ listingId, organizationId, partyId, onSelfRemoved });

  return (
    <Flex
      direction="column"
      gap={6}
      width={{ base: '100%', md: '80%' }}
      {...containerProps}
    >
      <Flex alignItems="center" justifyContent="space-between">
        {title}

        <HStack>
          <SearchInput
            onChange={(e) => updateFilters({ search: e.target.value })}
          />
          {isAdmin && (
            <Button px={6} size="sm" onClick={onOpen}>
              Invite
            </Button>
          )}
        </HStack>
      </Flex>

      <VStack alignItems="stretch">
        {loading && !acessControls.length ? (
          <CenterSpinner />
        ) : (
          <AccessControlList
            accessControls={acessControls || []}
            currentUserId={user?.id as string}
            isAdmin={isAdmin || false}
            onDelete={deleteAccessControlOrInvitation}
          />
        )}
      </VStack>

      <Modal isOpen={isOpen} title="Invite User" onClose={onClose}>
        <AccessControlInviteForm
          inviteAccessRoles={inviteAccessRoles}
          inviteDefaultAccessRole={inviteDefaultAccessRole}
          listingId={listingId}
          organizationId={organizationId}
          partyId={partyId}
          onSuccess={() => {
            onClose();
            void refetchInvites();
          }}
        />
      </Modal>
    </Flex>
  );
};
