import { Text } from '@chakra-ui/react';
import {
  ActivityFragment,
  ActivityType,
} from '@client/graphql/__generated__/types';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { ActivityItem } from './ActivityItem';

dayjs.extend(relativeTime);

export const DocumentUpdateActivity = ({
  listingActivity,
}: {
  listingActivity: ActivityFragment;
}) => {
  const metadata = listingActivity.metadata as {
    [ActivityType.DOCUMENT_UPDATE]: {
      listingDocumentTypeName?: string;
      customDocumentName?: string;
    };
  };

  const documentName =
    metadata[ActivityType.DOCUMENT_UPDATE].listingDocumentTypeName ||
    metadata[ActivityType.DOCUMENT_UPDATE].customDocumentName;

  return (
    <ActivityItem activity={listingActivity}>
      <Text as="span" fontWeight="bold" sx={{ textWrap: 'wrap' }}>
        {listingActivity?.user?.fullName}{' '}
      </Text>
      <Text as="span" color="gray.500">
        Updated the document:{' '}
      </Text>
      <Text as="span" color="white" fontWeight="500">
        {documentName}{' '}
      </Text>
    </ActivityItem>
  );
};
