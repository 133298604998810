import {
  Card,
  CardBody,
  CardHeader,
  Flex,
  Stack,
  Text,
} from '@chakra-ui/react';
import {
  ContractValidationFragment,
  ListingFragment,
  PublicListingFragment,
} from '@client/graphql/__generated__/types';
import { useRegisteredRoles } from '~/apps/consumer/hooks/useRegisteredRoles';
import { OfferContractContextProvider } from '~/apps/consumer/pages/Storefront/OfferFlow/OfferContractContext';
import { useOfferFlowContext } from '~/apps/consumer/pages/Storefront/OfferFlow/OfferFlowContext';
import { useUpdateInputValuesDebounced } from '~/apps/consumer/pages/Storefront/OfferFlow/hooks/useUpdateInputValuesDebounced';
import { CenterSpinner } from '~/common/components/CenterSpinner';
import { useCheckPermission } from '~/common/hooks/useCheckPermission';
import { AgentCard } from '../../Agent/AgentCard';
import { OfferValidations } from '../../OfferFlow/OfferValidations';
import { StartOfferUploadCard } from '../../Storefront/UploadOffer/Upload/StartOfferUploadCard';
import { StartOfferUploadListingDetails } from '../../Storefront/UploadOffer/Upload/StartOfferUploadListingDetails';
import { ContractBuyerInfoDetails } from './ContractBuyerInfoDetails';

export interface OfferDetailsProps {
  listing: ListingFragment | PublicListingFragment;
  offerId: string;
}

const OfferDetailsInner = ({ listing, offerId }: OfferDetailsProps) => {
  // TODO: Migrate away from using offerflow context if possible.
  const {
    latestOffer,
    latestOfferLoading,
    setShowVerifyUserModal,
    offerFlowSections,
    offerFlowSectionsDataLoading,
    formFieldsMap,
    offerKeyFields,
    offerKeyFieldsLoading,
  } = useOfferFlowContext();

  const { isRegisteredAgent } = useRegisteredRoles();

  const { inputValues, onChange } = useUpdateInputValuesDebounced();
  const { isAuthorized: isAuthorizedToModifyOffer } = useCheckPermission(
    'offers.canModify',
    {},
    [offerId]
  );

  if (!latestOffer || latestOfferLoading || offerKeyFieldsLoading) {
    return <CenterSpinner />;
  }
  const contract = latestOffer.contract;

  const canUpdateKeyTerms = isAuthorizedToModifyOffer;

  const buyersAgentName = offerKeyFields?.buyersAgentName as string | undefined;

  const officeName = offerKeyFields?.buyersAgentOfficeName as
    | string
    | undefined;

  return (
    <Flex direction="column" py={4} width="100%">
      <Stack gap={6} width="100%">
        <Stack gap={4}>
          <StartOfferUploadListingDetails listing={listing} />
          {buyersAgentName && (
            <AgentCard
              agentName={buyersAgentName}
              isVerified={isRegisteredAgent}
              officeName={officeName}
              user={latestOffer.buyersAgent?.user}
              onVerifyUser={() => setShowVerifyUserModal(true)}
            />
          )}
          {offerFlowSectionsDataLoading && <CenterSpinner />}
          {offerFlowSections.KEY_TERMS && !offerFlowSectionsDataLoading && (
            <StartOfferUploadCard
              documentSection={offerFlowSections?.KEY_TERMS}
              editable={canUpdateKeyTerms}
              fieldValues={inputValues ?? {}}
              formFieldsMap={formFieldsMap}
              title="Key Terms"
              validations={
                (contract?.validations as ContractValidationFragment[]) ?? []
              }
              onChange={onChange}
            />
          )}
          {latestOffer.noteToListingAgent && (
            <Card>
              <CardHeader>Note to Listing Agent</CardHeader>
              <CardBody>
                <Text color="whiteAlpha.600" whiteSpace="pre-line">
                  {latestOffer.noteToListingAgent}
                </Text>
              </CardBody>
            </Card>
          )}
          <ContractBuyerInfoDetails />
          {contract?.validations?.length && (
            <Card>
              <CardHeader>Validation</CardHeader>
              <CardBody>
                <OfferValidations validations={contract?.validations} />
              </CardBody>
            </Card>
          )}
        </Stack>
      </Stack>
    </Flex>
  );
};

export const OfferDetails = ({ listing, offerId }: OfferDetailsProps) => {
  return (
    <OfferContractContextProvider offerId={offerId}>
      <OfferDetailsInner listing={listing} offerId={offerId} />
    </OfferContractContextProvider>
  );
};
