import { Button } from '@chakra-ui/react';
import { useState } from 'react';

export const DownloadOfferDocumentsButton = ({
  contractId,
  buyersAgentName,
  propertySlug,
}: {
  contractId: string | undefined;
  buyersAgentName: string | undefined;
  propertySlug: string | undefined;
}) => {
  const downloadFilePrefix =
    buyersAgentName && propertySlug
      ? `${encodeURIComponent(buyersAgentName)}-${encodeURIComponent(
          propertySlug
        )}`
      : 'offer-documents';

  const [loading, setLoading] = useState<boolean>(false);

  const handleDownload = () => {
    if (contractId) {
      // Download can take a few ms to start given on size of docs
      // We show a quick loader to let the user know something is happening
      setLoading(true);

      const downloadUrl = `/documents/api/contracts/download/${contractId}?fileprefix=${downloadFilePrefix}`;
      window.location.href = downloadUrl;

      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  };

  return (
    <Button
      isDisabled={!contractId}
      isLoading={loading}
      w="100%"
      onClick={handleDownload}
    >
      Download all documents
    </Button>
  );
};
