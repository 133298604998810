import { TourProps } from '@reactour/tour';

export const customStyles: TourProps['styles'] = {
  popover: (base) => ({
    ...base,
    backgroundColor: 'var(--chakra-colors-purple-500)',
    borderRadius: 'var(--chakra-radii-md)', // chakra popover borderRadius
  }),
  badge: (base) => ({
    ...base,
    backgroundColor: 'var(--chakra-colors-purple-500)',
  }),
  button: (base) => ({
    ...base,
    backgroundColor: 'var(--chakra-colors-purple-500)',
  }),
  close: (base) => ({
    ...base,
    marginTop: '-8px',
  }),
  dot: (base, state) => ({
    ...base,
    backgroundColor: state?.current
      ? 'var(--chakra-colors-whiteAlpha-900)'
      : 'transparent',
  }),
  arrow: (base, state) => ({
    ...base,
    color: state?.disabled
      ? 'var(--chakra-colors-whiteAlpha-300)'
      : 'var(--chakra-colors-whiteAlpha-900)',
  }),
};
