import { Flex, Heading } from '@chakra-ui/react';
import { OrganizationListingsProvider } from './OrganizationListingsContext';
import { OrganizationListingsStatuses } from './OrganizationListingsStatuses/OrganizationListingsStatuses';
import { OrganizationListingsTable } from './OrganizationListingsTable';

export const OrganizationListingsContent = () => {
  return (
    <Flex overflowX="auto" width="100%">
      <Flex direction="column" gap={4} minWidth="1000px" width="100%">
        <Heading size="xxs">Listings</Heading>
        <OrganizationListingsStatuses />
        <OrganizationListingsTable />
      </Flex>
    </Flex>
  );
};

export const OrganizationListings = () => {
  return (
    <OrganizationListingsProvider>
      <OrganizationListingsContent />
    </OrganizationListingsProvider>
  );
};
