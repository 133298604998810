import { Avatar, AvatarProps, forwardRef } from '@chakra-ui/react';
import { UserObject } from '@client/graphql/__generated__/types';
import { FC } from 'react';

interface UserAvatarProps extends AvatarProps {
  user?: Partial<Pick<UserObject, 'fullName' | 'profilePicture'>>;
  src?: string;
}

export const UserAvatar: FC<UserAvatarProps> = forwardRef(function UserAvatar(
  { user, src, ...props },
  ref
) {
  return (
    <Avatar
      ref={ref}
      _light={{
        background: 'gray.100',
        borderColor: 'blackAlpha.300',
        color: 'black',
      }}
      background="whiteAlpha.100"
      borderColor="whiteAlpha.300"
      borderStyle="solid"
      borderWidth="2px"
      name={user?.fullName}
      src={src || user?.profilePicture?.url}
      textColor="white"
      {...props}
    />
  );
});
