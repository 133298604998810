import { useEffect, useRef, useState } from 'react';

export const useMainDashboardLeftMargin = () => {
  const flexRef = useRef<HTMLDivElement>(null);
  const [leftMargin, setLeftMargin] = useState<number | null>(null);

  useEffect(() => {
    const calculateLeftMargin = () => {
      if (flexRef.current) {
        const rect = flexRef.current.getBoundingClientRect();
        const leftMargin = rect.left;
        setLeftMargin(leftMargin);
      }
    };

    calculateLeftMargin();
    window.addEventListener('resize', calculateLeftMargin);

    return () => {
      window.removeEventListener('resize', calculateLeftMargin);
    };
  }, []);

  return { leftMargin, flexRef };
};
