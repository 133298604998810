import {
  Button,
  ButtonProps,
  Card,
  CardBody,
  CardProps,
  HStack,
  Icon,
  Text,
  VStack,
} from '@chakra-ui/react';
import { Roles } from '@client/graphql/__generated__/types';
import { ArrowRight } from 'lucide-react';
import { FC } from 'react';

interface ChooseUserTypeProps {
  onSelect: (userType: Roles) => void;
}

export const ChooseUserType: FC<ChooseUserTypeProps> = ({ onSelect }) => {
  return (
    <VStack alignItems="flex-start" spacing={3} width="100%">
      <Text fontSize="md" mb={4}>
        <strong>Which best describes you?</strong>
      </Text>

      <UserTypeButton data-testid="agent" onClick={() => onSelect(Roles.AGENT)}>
        <Text>I’m an agent or assistant</Text>
        <Text color="text.light">You’ll need an MLS ID to continue</Text>
      </UserTypeButton>

      <UserTypeButton
        data-testid="consumer"
        onClick={() => onSelect(Roles.CONSUMER)}
      >
        <Text>I’m a homebuyer or seller</Text>
      </UserTypeButton>
    </VStack>
  );
};

const UserTypeButton: FC<CardProps & ButtonProps> = ({
  children,
  ...props
}) => {
  return (
    <Card
      _hover={{
        bgColor: 'whiteAlpha.50',
      }}
      as={Button}
      bgColor="transparent"
      height="auto"
      px={0}
      py={4}
      variant="outline"
      width="100%"
      {...props}
    >
      <CardBody
        as={HStack}
        justifyContent="space-between"
        py={0}
        spacing={1}
        width="100%"
      >
        <VStack alignItems="flex-start" flexGrow={1}>
          {children}
        </VStack>
        <Icon as={ArrowRight} color="primary.600" />
      </CardBody>
    </Card>
  );
};
