import { Checkbox, CheckboxProps } from '@chakra-ui/react';
import { useField } from 'formik';

export interface CheckboxFieldProps extends CheckboxProps {
  name: string;
  label: string;
}

export const CheckboxField = ({ name, label }: CheckboxFieldProps) => {
  const [field] = useField({ name, type: 'checkbox' });

  return (
    <Checkbox {...field} isChecked={field.value === true}>
      {label}
    </Checkbox>
  );
};
