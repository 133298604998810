import { Flex, FlexProps, Text } from '@chakra-ui/react';
import React from 'react';

export const NeedHelp: React.FC<FlexProps> = (props) => {
  return (
    <Flex
      _hover={{
        cursor: 'pointer',
      }}
      backgroundColor="whiteAlpha.50"
      border="1px solid"
      borderColor="whiteAlpha.100"
      direction="column"
      fontWeight="medium"
      p={3}
      rounded="4px"
      width="100%"
      {...props}
    >
      <Text color="white">Need help or have feedback?</Text>
      <Text
        color="whiteAlpha.700"
        onClick={() => window.open('mailto:help@useindigo.com', '_blank')}
      >
        Email us at help@useindigo.com
      </Text>
    </Flex>
  );
};
