import { Flex, Text, keyframes } from '@chakra-ui/react';
import {
  ActivityFragment,
  PublicUserObject,
} from '@client/graphql/__generated__/types';
import dayjs from 'dayjs';
import { motion } from 'framer-motion';
import { UserAvatar } from '../User/UserAvatar';
import { UserContactInfoPopover } from './UserContactInfoCard';
import { DATE_MOBILE_OFFSET } from './activity.constants';
const pulse = keyframes`
  0% { box-shadow: 0 0 0 0 rgba(66, 153, 225, 0.4); }
  70% { box-shadow: 0 0 0 10px rgba(66, 153, 225, 0); }
  100% { box-shadow: 0 0 0 0 rgba(66, 153, 225, 0); }
`;

export function StorefrontActivity({
  listingActivity,
  verb,
  object,
}: {
  listingActivity: ActivityFragment;
  verb: string;
  object: string;
}) {
  const user = listingActivity.user as PublicUserObject;

  return (
    <Flex key={listingActivity.id} flexDirection="column" mb={4}>
      <Flex
        direction={{ base: 'column', md: 'row' }}
        justifyContent="space-between"
        mb={4}
        width="100%"
      >
        <Flex alignItems="center">
          <UserContactInfoPopover
            trigger={
              <motion.div whileHover={{ scale: 1.1 }}>
                <UserAvatar
                  animation={`${pulse} 2s 3`}
                  mr={3}
                  size="sm"
                  user={user}
                />
              </motion.div>
            }
            user={user}
          />
          <Flex alignItems="center">
            <Text color="gray.500">
              <UserContactInfoPopover
                trigger={
                  <Text
                    _hover={{ color: 'blue.300' }}
                    as="span"
                    color="white"
                    cursor="pointer"
                    fontWeight="medium"
                  >
                    {user?.fullName}
                  </Text>
                }
                user={user}
              />{' '}
              {verb}{' '}
              <Text as="span" color="white" fontWeight="medium">
                {object}
              </Text>
            </Text>
          </Flex>
        </Flex>

        <Flex alignItems="center" ml={{ base: DATE_MOBILE_OFFSET, md: 0 }}>
          <Text color="gray.500" mr={2}>
            {dayjs(listingActivity.createdAt as Date).fromNow()}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
}
