import {
  Flex,
  Input,
  InputGroup,
  InputProps,
  InputRightAddon,
} from '@chakra-ui/react';
import { InputFormatType } from '@client/graphql/__generated__/types';
import { ChakraStylesConfig } from 'chakra-react-select';
import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { Select } from '~/services/document/common/Select';
import { NumberInputField } from '../NumberInputField/NumberInputField';

interface UnitOption {
  label: string;
  value: string;
}

export interface InputWithUnitSelectProps extends InputProps {
  name: string;
  selectedUnit: string;
  unitOptions: UnitOption[];
  unitMinWidth?: string;
  variant?: 'default' | 'stacked';
  isNumber?: boolean;
}

export const InputWithUnitSelect = ({
  id,
  name,
  selectedUnit,
  unitOptions,
  unitMinWidth,
  variant = 'default',
  isNumber = false,
  ...rest
}: InputWithUnitSelectProps) => {
  const { values, setFieldValue } = useFormikContext();
  const [fieldName, setFieldName] = useState<string>(`${name}_${selectedUnit}`);
  const [selectedValue, setSelectedValue] = useState<UnitOption>();
  const initialSelectedValue = unitOptions?.find(
    (option) => option.value === selectedUnit
  );

  useEffect(() => {
    setFieldName(`${name}_${selectedUnit}`);
  }, [name, selectedUnit]);

  const styleProps: {
    chakraStyles?: ChakraStylesConfig | undefined;
  } =
    variant === 'default'
      ? {
          chakraStyles: {
            container: (provided) => ({
              ...provided,
              minWidth: unitMinWidth || '55px',
              borderRightRadius: 'sm',
              borderLeft: 'none',
            }),
            control: (provided) => ({
              ...provided,
              alignItems: 'center',
              borderLeft: 'none',
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              height: 12,
              _focusVisible: { border: 'none', boxShadow: 'none' },
              fontSize: 'sm',
            }),
            dropdownIndicator: (provided) => ({
              ...provided,
              bg: 'transparent',
              pl: 0,
              pr: 1,
              cursor: 'pointer',
            }),
            indicatorSeparator: (provided) => ({
              ...provided,
              display: 'none',
            }),
            valueContainer: (provided) => ({
              ...provided,
              pl: 2,
              pr: 1,
            }),
            menu: (provided) => ({
              ...provided,
              border: '1px solid',
              borderColor: '#ddd',
              borderRadius: 'sm',
              boxShadow: 'md',
              zIndex: 10,
            }),
          },
        }
      : {};

  const select = (
    <Select
      id={`${name}_select`}
      isClearable={false}
      isMulti={false}
      isSearchable={false}
      options={unitOptions}
      value={selectedValue || initialSelectedValue}
      onChange={(option: UnitOption) => {
        const currentValue = (values as { [key: string]: string })[fieldName];
        const newFieldName = `${name}_${option?.value}`;
        if (currentValue) {
          void setFieldValue(fieldName, null);
          void setFieldValue(newFieldName, currentValue);
        }
        setSelectedValue(option);
        setFieldName(`${name}_${option?.value}`);
      }}
      {...styleProps}
    />
  );

  return (
    <Flex direction="column" gap={1}>
      {variant === 'stacked' && select}
      <InputGroup isolation="auto">
        {isNumber ? (
          <NumberInputField
            id={id}
            inputFormat={{
              type: InputFormatType.NUMBER,
              options: {
                precision:
                  (selectedValue || initialSelectedValue)?.value === 'percent'
                    ? 2
                    : 0,
              },
            }}
            value={(values as { [key: string]: string })[fieldName]}
            onChange={(value) => {
              if (value !== (values as { [key: string]: string })[fieldName]) {
                void setFieldValue(fieldName, value);
              }
            }}
          />
        ) : (
          <Input
            id={id}
            {...rest}
            value={(values as { [key: string]: string })[fieldName]}
            onChange={(e) => void setFieldValue(fieldName, e.target.value)}
          />
        )}
        {variant === 'default' && (
          <InputRightAddon
            alignItems="center"
            backgroundColor="whiteAlpha.100"
            borderLeft="none"
            px={0}
          >
            {select}
          </InputRightAddon>
        )}
      </InputGroup>
    </Flex>
  );
};
