import { Flex, Icon } from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from 'lucide-react';
import { useState } from 'react';
import { HEADER_HEIGHT, LEFT_SIDEBAR_WIDTH } from '../constants';
import { BuyerInfo } from './BuyerInfo';
import { ListingInfo } from './ListingInfo';

export const BuyerOfferAnalysisLeftSidebar = () => {
  const [isExpanded, setIsExpanded] = useState(true);

  const expandIcon = (
    <Icon
      aria-label={isExpanded ? 'Collapse Left Sidebar' : 'Expand Left Sidebar'}
      as={isExpanded ? ChevronLeftIcon : ChevronRightIcon}
      boxSize={6}
      color="whiteAlpha.600"
      cursor="pointer"
      onClick={() => setIsExpanded(!isExpanded)}
    />
  );

  return (
    <Flex
      bg="indigo.700"
      borderRight="1px solid"
      borderRightColor="whiteAlpha.200"
      direction="column"
      height={`calc(100vh - ${HEADER_HEIGHT})`}
      minWidth={isExpanded ? LEFT_SIDEBAR_WIDTH : 0}
      position="sticky"
      top={HEADER_HEIGHT}
      transition="0.3s width ease-in-out, 0.3s min-width ease-in-out"
      width={isExpanded ? LEFT_SIDEBAR_WIDTH : 0}
    >
      <Flex
        bg="indigo.700"
        borderBottom="1px solid"
        borderBottomColor="whiteAlpha.200"
        borderRight="1px solid"
        borderRightColor="whiteAlpha.200"
        borderRightRadius="8px"
        borderTop="1px solid"
        borderTopColor="whiteAlpha.200"
        flexGrow={0}
        justifyContent="flex-end"
        left={isExpanded ? `calc(${LEFT_SIDEBAR_WIDTH} - 1px)` : 0}
        position="absolute"
        px={1}
        py={1}
        top={0}
        transition="0.3s left ease-in-out"
        visibility={isExpanded ? 'hidden' : 'visible'}
        width="30px"
      >
        {expandIcon}
      </Flex>
      {isExpanded && (
        <>
          <Flex justifyContent="flex-end" pt={1} px={1} width="100%">
            {expandIcon}
          </Flex>
          <BuyerInfo />
          <ListingInfo />
        </>
      )}
    </Flex>
  );
};
