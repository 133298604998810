import {
  MlsListingAddressObject,
  MlsListingFragment,
  MlsListingStatus,
} from '@client/graphql/__generated__/types';
import dayjs from 'dayjs';
import { getMediaUrl } from '~/common/url';
import {
  DEFAULT_DATETIME_FORMAT,
  DEFAULT_DATE_FORMAT,
} from '~/common/utils/formatter';
import { StyledFormInputType } from '../components/StyledFormInput/StyledFormInput';
import {
  PREFERENCE_VALUE_FIELD_MAP,
  getPreferenceMetaBySlug,
} from '../pages/ListingSetup/Preferences/constants';

interface MlsListingForSqFtValue {
  listPrice?: number;
  property?: {
    area?: number;
  };
}

export const DEFAULT_PROPERTY_IMAGE_URL = getMediaUrl(
  'medias/default-house.svg'
);

export const getPropertySource = (src?: string) =>
  src || DEFAULT_PROPERTY_IMAGE_URL;

export function getPerSqFtValue(listing?: MlsListingForSqFtValue) {
  const listPrice = listing?.listPrice;
  const area = listing?.property?.area;

  return area && listPrice ? listPrice / area : undefined;
}

/**
 * Get the date or datetime string for a specific preference in the default date or datetime format
 */
export function getPreferenceDateString(
  listing: {
    listingPreferences: {
      preference: { slug: string };
      dateTimeValue?: string;
    }[];
  },
  prefSlug: string,
  format: string = DEFAULT_DATETIME_FORMAT
) {
  const prefVal = listing.listingPreferences.find(
    (prefVal) => prefVal.preference.slug === prefSlug
  );
  const datetimeVal = prefVal?.dateTimeValue;
  const prefDate: Date | undefined = datetimeVal
    ? new Date(datetimeVal)
    : undefined;

  return prefDate ? getFormattedDate(prefDate, format) : undefined;
}

export function getFormattedDateForPreferenceValue(
  prefValue: string,
  prefSlug: string
): string | undefined {
  const preferenceMeta = getPreferenceMetaBySlug(prefSlug);
  if (preferenceMeta?.dataType !== PREFERENCE_VALUE_FIELD_MAP.DATE) {
    return undefined;
  }
  const format =
    preferenceMeta.inputType === StyledFormInputType.DATETIME
      ? DEFAULT_DATETIME_FORMAT
      : DEFAULT_DATE_FORMAT;

  return getFormattedDate(new Date(prefValue), format);
}

// Format date to e.g. "03/12/2024"
export function getFormattedDate(date?: Date, format = DEFAULT_DATE_FORMAT) {
  return date ? dayjs(date).format(format) : undefined;
}

export function getFormattedStatusText(statusText?: string): string {
  if (!statusText) return '';

  return statusText.replace(/-/g, ' - ').replace(/\s+/g, ' ').trim();
}

export function getStreetAddressFromMlsListing(
  mlsListingAddress?: MlsListingAddressObject
) {
  if (!mlsListingAddress) {
    return '';
  }

  return `${
    mlsListingAddress?.streetNumberText
      ? `${mlsListingAddress?.streetNumberText} `
      : ''
  }${mlsListingAddress?.streetName}${
    mlsListingAddress?.unit ? ` ${mlsListingAddress?.unit}` : ''
  }`;
}

// Gets listPrice or closePrice based on the status of the listing
export function getListingPrice(mlsListing?: MlsListingFragment) {
  if (mlsListing?.mls?.status === MlsListingStatus.Closed) {
    return mlsListing?.sales?.closePrice || mlsListing?.listPrice || 0;
  }

  return mlsListing?.listPrice || 0;
}
