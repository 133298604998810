import { InputProps } from '@chakra-ui/react';
import { InputFormatObject } from '@client/graphql/__generated__/types';
import { FC, memo } from 'react';
import { InputFormField } from './InputFormField';
import { baseInputStyle } from './defaults';
import { useNumberField } from './hooks/useNumberField';

interface NumberFormFieldProps extends Omit<InputProps, 'onChange' | 'value'> {
  inputFormat?: Omit<InputFormatObject, '__typename'>;
  value?: string | number;
  onChange?: (value: string | number) => void;
}

export const NumberFormField: FC<NumberFormFieldProps> = memo(
  function NumberFormField({ onChange, inputFormat, value, ...props }) {
    const { formatValue, setIsFocus, onChangeNumber } = useNumberField({
      onChange,
      inputFormat,
      value,
    });

    return (
      <InputFormField
        {...baseInputStyle}
        {...props}
        value={value || value === 0 ? formatValue : ''}
        onBlur={() => setIsFocus(false)}
        onChangeValue={onChangeNumber}
        onFocus={() => setIsFocus(true)}
      />
    );
  }
);
