import {
  ContractDocumentObject,
  DocumentType,
  DocumentVersionObject,
  ListingDocumentTypeEnum,
  OfferObject,
  PublicListingDocumentTypeFragment,
} from '@client/graphql/__generated__/types';
import { ValuesType } from '~/services/document/components/FormFields/types';
import { BuyerInfoInput } from '../pages/Storefront/OfferFlow/hooks/useOfferFlow';

export const getIsUploadedOfferExtractionFailed = (offer: OfferObject) => {
  // Can add other checks to see if failed in the future
  return (
    !offer?.contract?.contractDocuments?.some(
      (document) =>
        document.documentVersion?.document?.documentType ===
        DocumentType.PROPERTY_PURCHASE_CONTRACT
    ) && !offer?.submittedAt
  );
};

export const getIsBuyerInformationIncomplete = (
  buyerInfo?: BuyerInfoInput,
  requireEmail = true
) => {
  const primaryBuyerName = buyerInfo?.buyerName;
  const primaryBuyerEmail = buyerInfo?.buyerEmail;
  const coBuyerName = buyerInfo?.coBuyerName;
  const coBuyerEmail = buyerInfo?.coBuyerEmail;

  if (!requireEmail) {
    return !primaryBuyerName;
  }

  return (
    !primaryBuyerEmail ||
    !primaryBuyerName ||
    ((!!coBuyerEmail || !!coBuyerName) && (!coBuyerEmail || !coBuyerName))
  );
};

/**
 * Formats the offer attachment name to be <buyer_name>_offer_on_<address>.pdf
 * So LA can have a quick way to identify the offer
 * @param offer
 * @returns
 */
export const formatOfferAttachmentName = (offer: OfferObject) => {
  const maxNameLength = 16;

  const buyerName = offer.primaryBuyer?.fullName;
  const address = offer.listing?.property?.slug;
  const truncatedBuyerName = buyerName
    ?.slice(0, Math.min(buyerName.length, maxNameLength))
    // replace spaces or hyphens with underscores
    .replace(/[\s-]/g, '_');
  const truncatedAddress = address
    ?.slice(0, Math.min(address.length, maxNameLength))
    .replace(/[\s-]/g, '_');

  if (truncatedBuyerName && truncatedAddress) {
    return `${truncatedBuyerName}_offer_on_${truncatedAddress}.pdf`;
  }

  return 'Signed Contract Package.pdf';
};

export const getDisclosureContractDocs = (
  contractDocuments: ContractDocumentObject[],
  disclosureDocumentTypes?: PublicListingDocumentTypeFragment[]
) => {
  if (!contractDocuments?.length) {
    return [];
  }

  return contractDocuments?.filter(
    (cd) =>
      disclosureDocumentTypes?.some(
        (dis) =>
          dis.formName === cd.documentVersion?.document.form &&
          dis.type === ListingDocumentTypeEnum.DISCLOSURE
      )
  );
};

// Custom named disclosures
export const getOtherDisclosureContractDocs = (
  contractDocuments: ContractDocumentObject[]
) => {
  if (!contractDocuments?.length) {
    return [];
  }

  return contractDocuments?.filter(
    (cd) => !!cd.externalDocumentType && !!cd.externalDocumentName
  );
};

export const getAgentNegotiationContractDocs = (
  contractDocuments: ContractDocumentObject[],
  disclosureDocumentTypes?: PublicListingDocumentTypeFragment[]
) => {
  if (!contractDocuments?.length) {
    return [];
  }

  return contractDocuments?.filter(
    (cd) =>
      disclosureDocumentTypes?.some(
        (dis) =>
          dis.formName === cd.documentVersion?.document.form &&
          dis.type === ListingDocumentTypeEnum.AGENT_NEGOTIATION
      )
  );
};

// filters on any secondary versions that are included in make offer (uploaded or fillable)
// or enabled from secondary document version rules
export const filterActiveSecondaryVersions = (
  secondaryVersions: DocumentVersionObject[],
  enabledSecondaryDocumentVersionsFromRules: string[],
  fieldValues: ValuesType
) => {
  const result = secondaryVersions?.filter((doc) => {
    //TODO: remove mappingKey logic?
    //NOTE: keep mappingKey logic for now to prevent any regressions
    const mappingKey = doc.mappingKeys?.[0];

    return (
      !!(mappingKey && fieldValues[mappingKey]) ||
      enabledSecondaryDocumentVersionsFromRules.includes(doc.id)
    );
  });

  return result;
};
