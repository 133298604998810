import { Box, Flex, Tag, Text } from '@chakra-ui/react';
import { formatToCurrency } from '~/services/document/utils/number';

interface IListingDetailMapMarkerProps {
  price: number;
  address: string;
  submittedOffersCount: number;
  status: string;
  bg?: string;
  primaryColor?: string;
  secondaryColor?: string;
  tagColorScheme?: string;
}

export const ListingDetailMapMarker = ({
  bg = 'purpleGradient',
  primaryColor = 'white',
  secondaryColor = 'whiteAlpha.700',
  price,
  address,
  submittedOffersCount,
  tagColorScheme = 'gray',
}: IListingDetailMapMarkerProps) => {
  const formattedPrice = formatToCurrency(price, 0, 'compact');

  return (
    <Box
      bg={bg}
      borderRadius="6px"
      boxShadow="0px 1px 4px 0px rgba(0, 0, 0, 0.33)"
      px={2}
      py={1.5}
      zIndex={10}
    >
      <Flex alignItems="center" gap={2}>
        <Flex
          alignItems="left"
          direction="column"
          gap={0.5}
          justifyContent="center"
        >
          <Text color={secondaryColor} fontWeight="semibold">
            {address}
          </Text>
          <Flex alignItems="center" gap={2}>
            <Text color={primaryColor} fontWeight="semibold">
              {formattedPrice}
            </Text>
            <Tag
              alignItems="center"
              bgColor={submittedOffersCount ? primaryColor : undefined}
              color={submittedOffersCount ? 'purple.500' : secondaryColor}
              colorScheme={submittedOffersCount ? undefined : tagColorScheme}
              height={4}
              justifyContent="center"
              size="sm"
              variant={submittedOffersCount ? 'solid' : 'outline'}
            >
              {submittedOffersCount
                ? `${submittedOffersCount} offers`
                : 'No offers'}
            </Tag>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};
