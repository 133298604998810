import {
  DocumentSectionModuleType,
  DocumentVersionFieldObject,
  PublicDocumentVersionFieldObject,
  VariableSource,
} from '@client/graphql/__generated__/types';
import {
  convertFormulaOutput,
  evaluateFormula,
} from '@services/document/src/utils/formula';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { getFieldValuesForDocumentSection } from '~/services/document/utils/field';
import { DocumentSectionWithChildren } from '../../DocumentSections/types';
import { ValuesType } from '../../FormFields/types';
import { FIELD_TO_VARIABLE_DATA_TYPE } from '../../FormulaEditor/autocomplete/field';

interface UseDocumentVersionValuesOptions {
  documentVersionFields?: Partial<
    DocumentVersionFieldObject | PublicDocumentVersionFieldObject
  >[];
  values?: ValuesType;
  offerFlowSections?: Record<
    DocumentSectionModuleType,
    DocumentSectionWithChildren | undefined
  >;
}

export const useDocumentVersionValues = (
  options: UseDocumentVersionValuesOptions
) => {
  const [values, setValues] = useState({});
  // Get fields with autofill formula that is readOnly
  const fieldsWithAutofill = useMemo(() => {
    return options.documentVersionFields?.filter(({ autofill, field }) => {
      return autofill?.formula || field?.autofill?.formula;
    });
  }, [options.documentVersionFields]);

  // Evaluate readonly prefill formula
  const prefilledValues = useCallback(
    (updatedValues: ValuesType) => {
      return fieldsWithAutofill?.reduce(
        (autofillValues, { field, autofill }) => {
          const targetAutofill = autofill || field?.autofill;
          const variableSources = targetAutofill?.variableSources || [];

          if (
            targetAutofill &&
            targetAutofill.formula &&
            variableSources.length === 1 &&
            variableSources[0] === VariableSource.FIELD &&
            field
          ) {
            const { output } = evaluateFormula(
              targetAutofill.formula,
              targetAutofill.formulaVariableFields,
              updatedValues
            );

            const dataType = FIELD_TO_VARIABLE_DATA_TYPE[field.fieldType];

            if (output) {
              autofillValues[field.mappingKey] = convertFormulaOutput(
                dataType,
                output
              );
            }
          }

          return autofillValues;
        },
        {} as ValuesType
      );
    },
    [fieldsWithAutofill]
  );

  const updateValues = useCallback(
    (newValues: ValuesType, autofillValues?: ValuesType) => {
      setValues((prev) => {
        const updatedValues = { ...autofillValues, ...prev, ...newValues };

        return {
          ...updatedValues,
          ...prefilledValues(updatedValues),
        };
      });
    },
    [prefilledValues]
  );

  useEffect(() => {
    if (options.values) {
      // Make sure to overwrite any preapproval field values
      const preapprovalFieldValues = getFieldValuesForDocumentSection(
        options.offerFlowSections?.BUYER,
        options.values
      );
      // need prev value so unsaved input values arent overwritten by reloading the offer
      setValues((prev) => {
        return { ...options.values, ...prev, ...preapprovalFieldValues };
      });
    }
  }, [options.values, options.offerFlowSections]);

  return {
    values,
    updateValues,
  };
};
