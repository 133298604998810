import { ApolloQueryResult } from '@apollo/client';
import {
  Flex,
  HStack,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import { useUploadOfferAsListingAgentMutation } from '@client/graphql/__generated__/main-operations';
import {
  ListingAndOffersQuery,
  OfferSource,
} from '@client/graphql/__generated__/types';
import { CheckIcon } from 'lucide-react';
import React from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useIsMobile } from '~/common/hooks/useIsMobile';
import { offerDetailsPath } from '../../utils/routes.utils';
import { OfferPackagesUploadZone } from './OfferPackagesUploadZone';

interface UploadOfferModalProps {
  listingId: string;
  isOpen: boolean;
  onClose: () => void;
  setIsProcessingUploadedOffer: React.Dispatch<React.SetStateAction<boolean>>;
  refetchAllData: () => Promise<ApolloQueryResult<ListingAndOffersQuery>>;
}

export const ListingUploadOfferModal: React.FC<UploadOfferModalProps> = ({
  listingId,
  isOpen,
  onClose,
  setIsProcessingUploadedOffer,
  refetchAllData,
}) => {
  const [uploadOfferAsListingAgent] = useUploadOfferAsListingAgentMutation({
    refetchQueries: ['OffersForListing'],
  });
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useIsMobile();
  const toast = useToast();

  const handleUploadFiles = (acceptedFiles: File[]) => {
    setIsProcessingUploadedOffer(true);

    void uploadOfferAsListingAgent({
      variables: {
        input: {
          files: acceptedFiles,
          listingId: listingId,
          source: OfferSource.LA_UPLOAD,
        },
      },
    })
      .then(async ({ data }) => {
        await refetchAllData();
        const offerId = data?.uploadOfferAsListingAgent?.offer?.id;
        if (!offerId) {
          return;
        }

        toast({
          title: 'Offer uploaded! Here it is',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });

        const offerPath = offerDetailsPath(listingId, offerId, location.search);
        navigate(offerPath);
      })
      .finally(() => {
        setIsProcessingUploadedOffer(false);
        onClose();
      });
  };

  return (
    <Modal isOpen={isOpen} size={{ md: '4xl' }} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        {isMobile && <ModalCloseButton />}
        <ModalBody p={8}>
          <Flex
            direction={{ base: 'column', md: 'row' }}
            gap={4}
            minHeight={{ base: 'auto', md: '450px' }}
          >
            <Stack
              flex={1}
              justifyContent="center"
              width={{ base: '100%', md: '50%' }}
            >
              <Heading>Upload an offer for your listing</Heading>
              <HStack>
                <CheckIcon color="green" /> <Text>Classify your docs</Text>
              </HStack>
              <HStack>
                <CheckIcon color="green" />{' '}
                <Text>Extract key offer fields for easy comparison</Text>
              </HStack>
              <HStack>
                <CheckIcon color="green" />{' '}
                <Text>
                  Validate offer inputs with tax records and listing data
                </Text>
              </HStack>
            </Stack>
            <OfferPackagesUploadZone handleUpload={handleUploadFiles} />
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
