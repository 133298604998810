import { useField } from 'formik';
import React from 'react';
import {
  CurrencyInput,
  CurrencyInputProps,
} from '~/common/components/Inputs/CurrencyInput';

interface CurrencyFieldProps extends CurrencyInputProps {
  name: string;
}

export const CurrencyField = ({ name, ...rest }: CurrencyFieldProps) => {
  const [field] = useField(name);

  return (
    <CurrencyInput
      allowDecimals={false}
      autoComplete="off"
      decimalScale={0}
      decimalsLimit={0}
      name={field.name}
      prefix="$"
      size="sm"
      value={field.value as number}
      onBlur={(event) => {
        field.onBlur(name)(event);
      }}
      onValueChange={(valueStr) => {
        const parsedValue = valueStr ? parseFloat(valueStr) : undefined;
        field.onChange(name)({
          target: { value: parsedValue },
        } as unknown as React.ChangeEvent<HTMLInputElement>);
      }}
      {...rest}
    />
  );
};
