import { Input, InputProps } from '@chakra-ui/react';
import { ChangeEvent, FC, memo, useCallback } from 'react';
import { baseInputStyle } from './defaults';

interface InputFormFieldProps extends InputProps {
  onChangeValue?: (value: string) => void;
}

export const InputFormField: FC<InputFormFieldProps> = memo(
  function InputFormField({ onChangeValue, ...props }) {
    const onChangeCallback = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        onChangeValue && onChangeValue(e.currentTarget.value);
      },
      [onChangeValue]
    );

    return <Input {...baseInputStyle} {...props} onChange={onChangeCallback} />;
  }
);
