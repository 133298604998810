import { Image, ImageProps } from '@chakra-ui/react';
import { DEFAULT_PROPERTY_IMAGE_URL } from '~/apps/consumer/utils/listing.utils';

type ListingImageInCardProps = {
  photo: string;
  addressFull?: string;
} & ImageProps;

export const ListingImageInCard = ({
  photo,
  addressFull,
  ...imageProps
}: ListingImageInCardProps) => (
  <Image
    alt={`${addressFull || 'Home'} listing photo`}
    borderTopRadius={9}
    fallbackSrc={DEFAULT_PROPERTY_IMAGE_URL}
    h="180px"
    minW="100%"
    objectFit="cover"
    objectPosition="center"
    src={photo}
    userSelect="none"
    w="100%"
    {...imageProps}
  />
);
