import { Center, Heading } from '@chakra-ui/react';
import { useUpdateStorefrontSetupMutation } from '@client/graphql/__generated__/main-operations';
import { StorefrontSetupStep } from '@client/graphql/__generated__/types';
import { useNavigate } from 'react-router';
import { getStorefrontSetupWizardRoute } from '~/apps/consumer/utils/routes.utils';
import { CenterSpinner } from '~/common/components/CenterSpinner';
import {
  ComparablesProvider,
  useComparablesContext,
} from '../Comparables/ComparablesContext';
import { ComparablesBody } from '../Comparables/ComparablesPage';
import { useStorefrontSetupWizardOutletContext } from './StorefrontSetupWizardContext';
import { StorefrontSetupWizardPageLayout } from './StorefrontSetupWizardPageLayout';
import { shouldUpdateContinueStep } from './utils';

const ComparablesPageContent = () => {
  const { listing } = useStorefrontSetupWizardOutletContext();
  const navigate = useNavigate();
  const [updateListingSetup] = useUpdateStorefrontSetupMutation();

  const { loading, onPublish, isDirty } = useComparablesContext();

  return (
    <StorefrontSetupWizardPageLayout
      onNext={() => {
        if (isDirty) {
          onPublish?.();
        }
        if (
          shouldUpdateContinueStep({
            currentStep: listing?.storefrontSetup?.continueStep,
            updatingStep: StorefrontSetupStep.DOCUMENTS,
          })
        ) {
          void updateListingSetup({
            variables: {
              input: {
                listingId: listing?.id,
                continueStep: StorefrontSetupStep.DOCUMENTS,
              },
            },
          });
        }

        navigate(
          getStorefrontSetupWizardRoute(
            listing?.id,
            StorefrontSetupStep.DOCUMENTS
          )
        );
      }}
      onPrevious={() => {
        navigate(
          getStorefrontSetupWizardRoute(
            listing?.id,
            StorefrontSetupStep.SELLER_PREFERENCES
          )
        );
      }}
    >
      {loading ? (
        <CenterSpinner />
      ) : (
        <Center
          flexDirection="column"
          gap={8}
          mt={{ base: 4, lg: 16 }}
          pb={4}
          px={4}
          width={{ base: '100%', lg: '80%' }}
        >
          <Heading textAlign="center">
            Help buyers understand your list price
          </Heading>
          <ComparablesBody isHeaderSticky={false} />
        </Center>
      )}
    </StorefrontSetupWizardPageLayout>
  );
};

export const ComparablesPage = () => {
  const { listing } = useStorefrontSetupWizardOutletContext();

  return (
    <ComparablesProvider listing={listing} showToast={false}>
      <ComparablesPageContent />
    </ComparablesProvider>
  );
};
