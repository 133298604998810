import { Roles } from '@client/graphql/__generated__/types';
import { Navigate, Outlet } from 'react-router-dom';
import { SwitchUser } from '~/common/components/SwitchUser/SwitchUser';
import { useAppContext } from '../../contexts/AppContext';

export const Authenticated = () => {
  const { isAuthenticated, user } = useAppContext();

  if (!isAuthenticated) {
    return (
      <Navigate
        replace
        to={{
          pathname: '/auth/login',
          search: `?next=${window.location.pathname}`,
        }}
      />
    );
  }

  return (
    <>
      <Outlet />
      {window.ENVS.environment !== 'production' &&
        (user?.roles.includes(Roles.ADMIN) || !!window.ENVS.switchedUser) && (
          <SwitchUser />
        )}
    </>
  );
};
