import { Card, CardBody, CardHeader, Divider, HStack } from '@chakra-ui/react';
import { FC, memo, useMemo } from 'react';

interface DocumentSectionContainerProps {
  styleRootSectionCard?: boolean;
  showTitle?: boolean;
  isSubSection?: boolean;
  title: string;
  children: React.ReactNode;
}

export const DocumentSectionContainer: FC<DocumentSectionContainerProps> = memo(
  function DocumentSectionContainer({
    styleRootSectionCard = true,
    showTitle = true,
    isSubSection,
    title,
    children,
  }) {
    const padding = useMemo(() => {
      if (isSubSection && !styleRootSectionCard) return 0;
      if (isSubSection) return 3;
      if (styleRootSectionCard) return 4;

      return 0;
    }, [isSubSection, styleRootSectionCard]);

    return (
      <Card
        backdropFilter="none"
        flexGrow={1}
        variant={styleRootSectionCard ? 'outline' : 'unstyled'}
        width="100%"
        zIndex={2}
      >
        {showTitle && (
          <>
            <CardHeader
              fontWeight={isSubSection ? 'medium' : 'normal'}
              letterSpacing={isSubSection ? 'normal' : '1px'}
              pb={!styleRootSectionCard || isSubSection ? 4 : 0}
              pt={isSubSection ? 3 : 4}
              px={padding}
              textTransform={isSubSection ? 'capitalize' : 'uppercase'}
            >
              {title}
            </CardHeader>

            {isSubSection && <Divider />}
          </>
        )}

        <CardBody
          alignItems="stretch"
          as={HStack}
          columnGap={4}
          flexGrow={1}
          flexWrap="wrap"
          p={padding}
          rowGap={4}
          width="100%"
        >
          {children}
        </CardBody>
      </Card>
    );
  }
);
