import {
  Box,
  Card,
  Flex,
  Heading,
  Tag,
  chakra,
  useRadio,
} from '@chakra-ui/react';
import { IconTag } from '~/common/components/IconTag/IconTag';
import { DotIcon } from '~/common/icons';

interface OfferCountCardProps {
  exampleTitle: string;
  isRecommended?: boolean;
  title: string;
  description: string;
  bestAndFinalDateStr?: string;
}

export const OfferCountCardRadio = ({
  exampleTitle,
  isRecommended = false,
  title,
  description,
  bestAndFinalDateStr = 'E.g. 03.12.24',
  ...radioProps
}: OfferCountCardProps) => {
  const { state, getInputProps, getRadioProps, htmlProps } =
    useRadio(radioProps);

  return (
    <chakra.label
      {...htmlProps}
      cursor="pointer"
      flex="1"
      minWidth={{ base: '240px', md: '0' }}
    >
      <input {...getInputProps()} hidden />
      <Card
        {...getRadioProps()}
        bgColor="indigo.700" // if not, will be gray.50 on some phones
        border={state.isChecked ? '1px solid' : '1px solid transparent'}
        borderColor={state.isChecked ? 'violet.500' : 'transparent'}
        h="100%"
        position="relative"
      >
        <Flex direction="column" gap={2} padding="10px">
          <Card
            align="center"
            bgColor="whiteAlpha.25"
            borderRadius="sm"
            gap={2}
            h="200px"
            justifyContent="center"
            p={3}
          >
            <IconTag icon={DotIcon} iconSize="6px" label="Accepting offers" />
            <Heading size="sm" textAlign="center">
              {exampleTitle}
            </Heading>
            <Box fontSize="sm" opacity="0.6" textAlign="center">
              Best and final due {bestAndFinalDateStr}
            </Box>
          </Card>
          <Flex direction="column" gap={2} p={2}>
            {isRecommended && (
              <Box>
                <Tag variant="gradient">Recommended</Tag>
              </Box>
            )}
            <Heading as="h4" color="white" fontSize="md" fontWeight={500}>
              {title}
            </Heading>
            <Box color="whiteAlpha.600" fontSize="md">
              {description}
            </Box>
          </Flex>
        </Flex>
      </Card>
    </chakra.label>
  );
};
