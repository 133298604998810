import { MlsListingStatus } from '@client/graphql/__generated__/types';

export const DEFAULT_STATUS_ITEMS = [
  {
    status: [MlsListingStatus.Active],
    name: 'Active',
    bgColor: 'purpleGradient',
    primaryColor: 'white',
    secondaryColor: 'whiteAlpha.700',
    tagColorScheme: 'gray',
  },
  {
    status: [MlsListingStatus.ComingSoon],
    name: 'Coming Soon',
    bgColor: 'tan.300',
    primaryColor: 'indigo.800',
    secondaryColor: 'indigo.700',
    tagColorScheme: 'black',
  },
  {
    status: [MlsListingStatus.ActiveUnderContract, MlsListingStatus.Pending],
    name: 'Pending',
    bgColor: 'gray.500',
    primaryColor: 'white',
    secondaryColor: 'whiteAlpha.700',
    tagColorScheme: 'gray',
  },
  {
    status: [MlsListingStatus.Closed],
    name: 'Closed (This Month)',
    bgColor: 'gray.900',
    primaryColor: 'white',
    secondaryColor: 'whiteAlpha.700',
    tagColorScheme: 'gray',
  },
];
