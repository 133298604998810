import { Flex } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { ProcessingAnimation } from '~/apps/consumer/components/Transitions/ProcessingAnimation';
import { CenterSpinner } from '~/common/components/CenterSpinner';
import { useStorefrontContext } from '../StorefrontContext';
import { useOfferFlowContext } from './OfferFlowContext';

const defaultLoadingSteps = [
  { text: 'Gathering property tax records' },
  { text: 'Getting listing information' },
  { text: 'Pre-filling offer documents...' },
];

export const OfferProcessingAnimation = () => {
  const [animationComplete, setAnimationComplete] = useState(false);

  const { queriesLoading } = useOfferFlowContext();
  const { shouldContinueOffer } = useStorefrontContext();

  const navigate = useNavigate();

  useEffect(() => {
    if (
      (shouldContinueOffer && !queriesLoading) ||
      (animationComplete && !queriesLoading)
    ) {
      navigate('contract', {
        replace: true,
      });
    }
  }, [animationComplete, navigate, queriesLoading, shouldContinueOffer]);

  return (
    <Flex
      direction="column"
      flexGrow={1}
      justifyContent="center"
      p={4}
      width="100%"
    >
      {shouldContinueOffer ? (
        <CenterSpinner />
      ) : (
        <ProcessingAnimation
          animationDuration={{
            short: 100,
            long: 1500,
          }}
          steps={defaultLoadingSteps}
          onFinish={() => setAnimationComplete(true)}
        />
      )}
    </Flex>
  );
};
