import { Button, Icon, Link } from '@chakra-ui/react';
import { ArrowUpRight } from 'lucide-react';

export const BrokeragePageButton = ({
  label = 'Brokerage listing page',
  url,
}: {
  label?: string;
  url: string;
}) => {
  return (
    <Button
      isExternal
      _hover={{
        bg: 'whiteAlpha.200',
      }}
      as={Link}
      bg="whiteAlpha.50"
      color="white"
      colorScheme="whiteAlpha"
      href={`http://${url}`}
      rightIcon={<Icon as={ArrowUpRight} />}
      size="sm"
    >
      {label}
    </Button>
  );
};
