import { ChevronRightIcon } from '@chakra-ui/icons';
import { Box, Button, Flex, IconButton, Image, Text } from '@chakra-ui/react';
import { MlsListingAddressObject } from '@client/graphql/__generated__/types';
import { X } from 'lucide-react';
import { PropsWithChildren } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import {
  StepNavLink,
  StepStatus,
} from '~/apps/consumer/components/Navigation/StepNavLink';
import {
  DEFAULT_PROPERTY_IMAGE_URL,
  getStreetAddressFromMlsListing,
} from '~/apps/consumer/utils/listing.utils';
import { getStorefrontSetupWizardRoute } from '~/apps/consumer/utils/routes.utils';
import { PageDotIndicator } from '~/common/components/PageDotIndicator/PageDotIndicator';
import { useIsMobile } from '~/common/hooks/useIsMobile';
import { useStorefrontSetupWizardOutletContext } from './StorefrontSetupWizardContext';
import { ROUTE_TO_STEP_MAP, STEP_ORDER, STEP_TO_TITLE_MAP } from './utils';

interface StorefrontSetupWizardPageLayoutProps extends PropsWithChildren {
  onNext?: () => void | Promise<void>;
  onPrevious?: () => void | Promise<void>;
  nextButtonText?: string;
  isLoading?: boolean;
}

export const StorefrontSetupWizardPageLayout = ({
  children,
  isLoading,
  onNext,
  onPrevious,
  nextButtonText = 'Publish & Continue',
}: StorefrontSetupWizardPageLayoutProps) => {
  const { listing } = useStorefrontSetupWizardOutletContext();
  const navigate = useNavigate();
  const location = useLocation();

  // because STEP_ORDER has long content to display, we want to make mobile layout gets triggered for tablet
  const isMobile = useIsMobile(true);

  const currentStep =
    ROUTE_TO_STEP_MAP[
      location.pathname.split('/').pop() as keyof typeof ROUTE_TO_STEP_MAP
    ];

  const currentStepIndex = STEP_ORDER.indexOf(currentStep);

  const currentStepTitle = STEP_TO_TITLE_MAP[currentStep];

  return (
    <Flex alignItems="center" direction="column" minHeight="100vh" width="100%">
      <Flex
        alignItems="center"
        backgroundColor="bg.main"
        borderBottom="1px solid"
        borderBottomColor="whiteAlpha.100"
        boxShadow="0px 0px 24px 0px var(--chakra-colors-blackAlpha-400)"
        position="sticky"
        py={5}
        top={0}
        width="100%"
        zIndex="10"
      >
        <Flex
          alignItems="center"
          gap={2}
          pl={5}
          width={{ base: '100%', lg: 'auto' }}
        >
          <IconButton
            aria-label="Close"
            as={NavLink}
            color="white"
            icon={<X />}
            mr={{ base: 0, lg: '6' }}
            to={`/listings/${listing?.id}/dashboard`}
            variant="link"
            zIndex={11}
          />
          {!isMobile && (
            <Image
              fallbackSrc={DEFAULT_PROPERTY_IMAGE_URL}
              height="36px"
              src={listing?.mlsListing?.photos?.[0]}
              width="48px"
            />
          )}
          <Text fontWeight="500" mx={{ base: 'auto', lg: 0 }}>
            {getStreetAddressFromMlsListing(
              listing?.mlsListing?.address as MlsListingAddressObject
            )}
          </Text>
          {isMobile && <Box visibility="hidden" width="40px" />}
        </Flex>
        {!isMobile && (
          <>
            <Flex
              alignItems="center"
              gap={2}
              justifyContent="center"
              position="absolute"
              width="full"
            >
              {STEP_ORDER.map((step, index) => {
                const link = (
                  <StepNavLink
                    fontSize="md"
                    status={StepStatus.INCOMPLETE}
                    to={getStorefrontSetupWizardRoute(listing?.id, step)}
                  >
                    {STEP_TO_TITLE_MAP[step]}
                  </StepNavLink>
                );

                return (
                  <>
                    {link}
                    {index < STEP_ORDER.length - 1 && (
                      <ChevronRightIcon
                        color="whiteAlpha.500"
                        height="20px"
                        width="20px"
                      />
                    )}
                  </>
                );
              })}
            </Flex>
            <Flex alignItems="center" gap={2} ml="auto" pr={5} zIndex={11}>
              <Button
                isLoading={isLoading}
                size="sm"
                variant="outline"
                onClick={() => {
                  void onPrevious?.();
                }}
              >
                Previous
              </Button>
              <Button
                isLoading={isLoading}
                size="sm"
                onClick={() => {
                  void onNext?.();
                }}
              >
                {nextButtonText}
              </Button>
            </Flex>
          </>
        )}
      </Flex>
      {isMobile && currentStepTitle && (
        <Flex alignItems="center">
          <Text
            color="whiteAlpha.700"
            fontSize="14px"
            fontWeight={500}
            mt={8}
            textTransform="uppercase"
          >
            {currentStepTitle}
          </Text>
        </Flex>
      )}
      {children}
      {isMobile && (
        <Flex
          alignItems="center"
          backgroundColor="bg.main"
          borderTop="1px solid"
          borderTopColor="whiteAlpha.100"
          bottom={0}
          boxShadow="0px 0px 24px 0px var(--chakra-colors-blackAlpha-400)"
          position="sticky"
          py={5}
          width="100%"
          zIndex="10"
        >
          <Flex alignItems="center" gap={2} py={5} width="100%">
            <Button
              isLoading={isLoading}
              left={5}
              position="absolute"
              size="sm"
              variant="outline"
              zIndex="11"
              onClick={() => {
                void onPrevious?.();
              }}
            >
              Previous
            </Button>
            <Flex
              alignItems="center"
              justifyContent="center"
              position="absolute"
              width="full"
            >
              <PageDotIndicator
                currentStepIndex={currentStepIndex}
                totalSteps={STEP_ORDER.length}
                onClick={(index) => {
                  navigate(
                    getStorefrontSetupWizardRoute(
                      listing?.id,
                      STEP_ORDER[index]
                    )
                  );
                }}
              />
            </Flex>
            <Button
              isLoading={isLoading}
              position="absolute"
              right={5}
              size="sm"
              zIndex="11"
              onClick={() => void onNext?.()}
            >
              {nextButtonText}
            </Button>
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};
