import {
  Button,
  Flex,
  Icon,
  Input,
  InputProps,
  Spacer,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useField } from 'formik';
import { Trash2Icon } from 'lucide-react';
import { useState } from 'react';

export interface ListInputProps {
  name: string;
  placeholder?: InputProps['placeholder'];
}

export const ListInput = ({ name, placeholder }: ListInputProps) => {
  const [field, meta, helper] = useField(name); // eslint-disable-line @typescript-eslint/no-unused-vars
  const values = (field.value || []) as string[];
  const [input, setInput] = useState('');

  return (
    <Stack spacing={2}>
      <Flex>
        <Input
          borderRightRadius="0px"
          height={10}
          placeholder={placeholder}
          value={input}
          onChange={(e) => setInput(e.target.value)}
        />
        <Button
          borderLeftRadius="0px"
          height={10}
          size="sm"
          onClick={() => {
            void helper.setValue([...values, input]);
            setInput('');
          }}
        >
          Add Item
        </Button>
      </Flex>
      {values.map((val) => (
        <Flex
          key={val}
          alignItems="center"
          borderBottom="1px solid"
          borderColor="whiteAlpha.200"
          height={12}
        >
          <Text>{val}</Text>
          <Spacer />
          <Icon
            aria-label="delete value"
            as={Trash2Icon}
            color="whiteAlpha.700"
            cursor="pointer"
            height={4}
            onClick={() => {
              void helper.setValue(values.filter((v) => v !== val));
            }}
          />
        </Flex>
      ))}
    </Stack>
  );
};
