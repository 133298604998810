import { Divider, Heading, Icon, VStack } from '@chakra-ui/react';
import { useAllListingActivitiesForCurrentUserQuery } from '@client/graphql/__generated__/main-operations';
import { Activity } from 'lucide-react';
import { FC } from 'react';
import { ActivitiesList } from '../../components/Activity/ActivitiesList';

export const ActivitySection: FC = () => {
  const { data } = useAllListingActivitiesForCurrentUserQuery({
    variables: { limit: 10 },
  });

  const activities = data?.allListingActivitiesForCurrentUser?.results;

  return (
    <>
      <VStack alignItems="start" pb={4} pt={6} width="full">
        <Heading as="h2" fontSize="xl" mb={2} size="sm">
          <Icon
            as={Activity}
            color="purple.500"
            mb={1}
            mr={2}
            verticalAlign="middle"
          />
          Storefront Activity
        </Heading>
        <Divider />
      </VStack>

      <VStack alignItems="stretch" spacing={0} width="100%">
        <ActivitiesList activities={activities} />
      </VStack>
    </>
  );
};
