import { ApolloQueryResult } from '@apollo/client';
import {
  ListingAndOffersQuery,
  ListingFragment,
} from '@client/graphql/__generated__/types';
import { createContext, useContext } from 'react';
import { useOutletContext } from 'react-router';
import { useDocumentSectionsByModuleType } from '~/services/document/components/DocumentSections/hooks/useDocumentSectionsByModuleType';
import { OfferWithContract } from './ListingWithOffersProvider';

export type ListingOffersOutletContextType = {
  listing: ListingFragment;
  listingLoading: boolean;
  offersWithContracts: OfferWithContract[];
  offerCardSections?: ReturnType<typeof useDocumentSectionsByModuleType>;
  showListingSetupTour: boolean;
  setShowListingSetupTour: (show: boolean) => void;
};

export type RefetchAllListingData = () => Promise<
  ApolloQueryResult<ListingAndOffersQuery>
>;

export type ListingAgentSetupContextType = ListingOffersOutletContextType & {
  refetchAllData: RefetchAllListingData;
  isTrackableListingPackageEnabled: boolean;
};

export const ListingSetupContext = createContext<ListingAgentSetupContextType>({
  offersWithContracts: [],
  listing: {} as ListingFragment,
  listingLoading: true,
  refetchAllData: (() => {}) as RefetchAllListingData,
  showListingSetupTour: false,
  setShowListingSetupTour: () => {},
  isTrackableListingPackageEnabled: false,
});

export const useListingOffersOutletContext = () => {
  return useOutletContext<ListingAgentSetupContextType>();
};

export const useListingSetupContext = () => {
  return useContext(ListingSetupContext);
};
