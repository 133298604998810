import { Icon, IconProps } from '@chakra-ui/react';

export const SwapIcon = (props: IconProps) => {
  return (
    <Icon
      fill="none"
      height="15"
      viewBox="0 0 14 15"
      width="14"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        clipRule="evenodd"
        d="M7.47105 4.8562L10.5352 1.79204L9.61817 0.875L5.63696 4.8562L9.61817 8.83741L10.5352 7.92037L7.47105 4.8562Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M6.5542 4.20703H14V5.50392H6.5542V4.20703Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M6.4799 10.1454L3.41443 7.08131L4.33126 6.16406L8.3144 10.1454L4.33126 14.1267L3.41443 13.2094L6.4799 10.1454Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M7.39715 10.793H0V9.49609H7.39715V10.793Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </Icon>
  );
};
