import { Button, HStack, Text, useToast } from '@chakra-ui/react';
import { PublicDocumentVersionObject } from '@client/graphql/__generated__/types';
import { ValuesType } from '~/services/document/components/FormFields/types';

interface AddDocumentRowProps {
  documentVersion: PublicDocumentVersionObject;
  onChange: (fieldValues: ValuesType) => void;
}

export const AddDocumentRow = ({
  documentVersion,
  onChange,
}: AddDocumentRowProps) => {
  const mappingKey = documentVersion.mappingKeys?.[0];
  const toast = useToast();

  return (
    <HStack justifyContent="space-between">
      <Text>{documentVersion.document.name}</Text>
      <Button
        colorScheme="skyButton"
        size="xs"
        onClick={() => {
          if (mappingKey) {
            onChange({
              [mappingKey]: true,
            });
            toast({
              title: 'Document added successfully',
              status: 'success',
              duration: 3000,
            });
          }
        }}
      >
        Add
      </Button>
    </HStack>
  );
};
