import {
  ActivityFragment,
  ActivityType,
} from '@client/graphql/__generated__/types';
import * as Sentry from '@sentry/react';
import { match } from 'ts-pattern';
import { StorefrontActivity } from './StorefrontActivity';

export const ListingDocumentActionActivity = ({
  listingActivity,
}: {
  listingActivity: ActivityFragment;
}) => {
  const verb = match(listingActivity.type)
    .with(ActivityType.LISTING_DOCUMENT_DOWNLOADED, () => 'downloaded')
    .with(ActivityType.LISTING_DOCUMENT_VIEWED, () => 'viewed')
    .otherwise(() => {
      Sentry.captureMessage(`Unknown activity type: ${listingActivity.type}`, {
        extra: listingActivity,
        level: 'warning',
      });

      return 'viewed';
    });
  const metadata = listingActivity.metadata as {
    [key in ActivityType]: {
      documentName: string;
    };
  };

  const documentName = metadata[listingActivity.type]?.documentName;

  return (
    <StorefrontActivity
      listingActivity={listingActivity}
      object={documentName}
      verb={`${verb} document`}
    />
  );
};
