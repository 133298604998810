import { Button, Flex, Link, Spacer, Text } from '@chakra-ui/react';
import { ListingDocumentUploadObject } from '@client/graphql/__generated__/types';
import { DocIcon } from '~/common/icons';

export const ListingDocumentRow = ({
  isLastItem,
  isFirstItem,
  doc,
  onViewDocument,
}: {
  isLastItem: boolean;
  isFirstItem: boolean;
  doc: ListingDocumentUploadObject;
  onViewDocument: (documentId: string) => void;
}) => {
  return (
    <Flex
      alignItems="center"
      borderBottom={!isLastItem ? '1px solid' : 'none'}
      borderColor="border.divider"
      gap={4}
      pb={!isLastItem ? 5 : 0}
      pt={!isFirstItem ? 5 : 0}
    >
      <DocIcon boxSize={9} />
      <Text>{doc?.listingDocumentType?.name || doc?.customDocumentName}</Text>
      <Spacer />
      <>
        <Button
          as={Link}
          fontWeight="500"
          isDisabled={!doc.file}
          variant="link"
          onClick={doc.file ? () => onViewDocument(doc.id) : undefined}
        >
          View
        </Button>
      </>
    </Flex>
  );
};
