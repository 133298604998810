import { Roles } from '@client/graphql/__generated__/types';
import { Props } from 'chakra-react-select';
import { PropsWithChildren, useEffect, useState } from 'react';
import { useAppContext } from '~/services/main/contexts/AppContext';

export interface RoleFilterProps extends PropsWithChildren<Props> {
  roles: Roles[];
}

export const RoleFilter = ({ roles, children }: RoleFilterProps) => {
  const [isAuthorized, setIsAuthorized] = useState(false);
  const { user } = useAppContext();

  useEffect(() => {
    const currentUserRoles = user?.roles || [];
    if (currentUserRoles.some((curRole) => roles.includes(curRole))) {
      setIsAuthorized(true);
    }
  }, [user, roles]);

  if (!isAuthorized) {
    return null;
  }

  return <>{children}</>;
};
