import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  NumberInput,
  NumberInputField,
} from '@chakra-ui/react';
import debounce from 'lodash/debounce';
import { useEffect, useMemo, useState } from 'react';

interface RangeInputProps {
  label: string;
  onChangeMin: (value?: number) => void;
  onChangeMax: (value?: number) => void;
  initialMinValue?: number;
  initialMaxValue?: number;
}

export const RangeInput = ({
  label,
  onChangeMin,
  onChangeMax,
  initialMinValue,
  initialMaxValue,
}: RangeInputProps) => {
  const [minValue, setMinValue] = useState<number | undefined>(undefined);
  const [maxValue, setMaxValue] = useState<number | undefined>(undefined);

  useEffect(() => {
    setMinValue(initialMinValue);
  }, [initialMinValue]);

  useEffect(() => {
    setMaxValue(initialMaxValue);
  }, [initialMaxValue]);

  const debounceOnChangeMin = useMemo(
    () => debounce(onChangeMin, 500),
    [onChangeMin]
  );
  const debounceOnChangeMax = useMemo(
    () => debounce(onChangeMax, 500),
    [onChangeMax]
  );

  return (
    <FormControl>
      <FormLabel>{label}</FormLabel>
      <Flex alignItems="center" gap={2} width="100%">
        <Box width="200px">
          <NumberInput
            defaultValue={initialMinValue}
            max={maxValue}
            onChange={(_, value) => {
              const parsedValue = isNaN(value) ? undefined : value;
              setMinValue(parsedValue);
              debounceOnChangeMin(parsedValue);
            }}
          >
            <NumberInputField
              background="input.bg"
              placeholder="No min"
              value={minValue}
            />
          </NumberInput>
        </Box>
        -
        <Box width="200px">
          <NumberInput
            defaultValue={initialMaxValue}
            min={minValue}
            onChange={(_, value) => {
              const parsedValue = isNaN(value) ? undefined : value;
              setMaxValue(parsedValue);
              debounceOnChangeMax(parsedValue);
            }}
          >
            <NumberInputField
              background="input.bg"
              placeholder="No max"
              value={maxValue}
            />
          </NumberInput>
        </Box>
      </Flex>
    </FormControl>
  );
};
