import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Tag,
  Text,
  VStack,
} from '@chakra-ui/react';
import { FC, useCallback, useEffect, useState } from 'react';
import { Select } from '~/services/document/common/Select';
import { useAppContext } from '~/services/main/contexts/AppContext';
import { AdditionalUsersResponse, UserResponse } from './types';

export const SwitchUserForm: FC = () => {
  const { user } = useAppContext();
  const [activeUser, setActiveUser] = useState<UserResponse>();
  const [additionalUsers, setAdditionalUsers] =
    useState<AdditionalUsersResponse>();

  const fetchUsers = useCallback(async () => {
    const response = await fetch('/auth/additional-users');
    const data = (await response.json()) as AdditionalUsersResponse;

    setAdditionalUsers(data);
  }, []);

  useEffect(() => {
    void fetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user) {
      setActiveUser({
        id: user?.id,
        firstName: user?.firstName,
        lastName: user?.lastName,
        roles: user?.roles,
      } as UserResponse);
    }
  }, [user]);

  return additionalUsers ? (
    <VStack
      as="form"
      onSubmit={(e) => {
        e.preventDefault();
        const userId =
          activeUser?.id !== additionalUsers.user.id ? activeUser?.id : null;

        void fetch('/auth/switch-user', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ userId }),
        }).then(() => {
          window.location.reload();
        });
      }}
    >
      <FormControl>
        <FormLabel>User</FormLabel>
        <Select
          chakraStyles={{
            option: (base, props) => {
              return {
                ...base,
                bg: () => {
                  if (!props.isSelected && props.isFocused) {
                    return 'whiteAlpha.100';
                  }

                  return props.isSelected ? 'rgba(0,0,0,0.3)' : 'transparent';
                },
              };
            },
          }}
          formatOptionLabel={(option) => {
            return (
              <HStack justifyContent="space-between">
                <Text flexGrow={1}>
                  {option.firstName} {option.lastName}
                </Text>
                <HStack
                  flexShrink={1}
                  flexWrap="wrap"
                  justifyContent="flex-end"
                  maxWidth="50%"
                >
                  {option.roles.map((role) => (
                    <Tag key={role} size="sm">
                      {role}
                    </Tag>
                  ))}
                </HStack>
              </HStack>
            );
          }}
          getOptionValue={(option) => option.id}
          isSearchable={false}
          menuPortalTarget={document.body}
          options={[additionalUsers.user].concat(
            additionalUsers.additionalUsers
          )}
          selectedOptionStyle="check"
          styles={{
            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          }}
          value={activeUser}
          onChange={(option) => setActiveUser(option as UserResponse)}
        />
      </FormControl>

      <Button type="submit">Switch user</Button>
    </VStack>
  ) : null;
};
