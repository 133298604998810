import { Flex, HStack, Image, VStack } from '@chakra-ui/react';
import { MakeOfferModal } from '../components/MakeOffer/MakeOfferModal';
import HeaderImage from '../images/header.png';
import LeftImage from '../images/left.png';
import Property1 from '../images/property1.jpg';
import Property2 from '../images/property2.jpg';
import Property3 from '../images/property3.jpg';
import Property4 from '../images/property4.jpg';
import Property5 from '../images/property5.jpg';
import RightImage from '../images/right.png';

export const PropertyView = () => {
  return (
    <VStack>
      <Flex bgColor="white" justifyContent="center" width="100%">
        <Image height="55px" src={HeaderImage} />
      </Flex>

      <HStack overflow="hidden" width="100%">
        <HStack justifyContent="center">
          <Flex height="500px" width="830px">
            <Image
              height="100%"
              objectFit="cover"
              src={Property1}
              width="100%"
            />
          </Flex>

          <VStack flexShrink={0} height="500px">
            <HStack flexShrink={0} height="246px" width="500px">
              <Image
                height="100%"
                objectFit="cover"
                src={Property2}
                width="100%"
              />
              <Image
                height="100%"
                objectFit="cover"
                src={Property3}
                width="100%"
              />
              <Image
                height="100%"
                objectFit="cover"
                src={Property2}
                width="100%"
              />
              <Image
                height="100%"
                objectFit="cover"
                src={Property3}
                width="100%"
              />
              <Image
                height="100%"
                objectFit="cover"
                src={Property2}
                width="100%"
              />
              <Image
                height="100%"
                objectFit="cover"
                src={Property3}
                width="100%"
              />
            </HStack>
            <HStack flexShrink={0} height="246px" width="500px">
              <Image
                height="100%"
                objectFit="cover"
                src={Property4}
                width="100%"
              />
              <Image
                height="100%"
                objectFit="cover"
                src={Property5}
                width="100%"
              />
              <Image
                height="100%"
                objectFit="cover"
                src={Property4}
                width="100%"
              />
              <Image
                height="100%"
                objectFit="cover"
                src={Property5}
                width="100%"
              />
              <Image
                height="100%"
                objectFit="cover"
                src={Property4}
                width="100%"
              />
              <Image
                height="100%"
                objectFit="cover"
                src={Property5}
                width="100%"
              />
            </HStack>
          </VStack>
        </HStack>
      </HStack>

      <HStack alignItems="flex-start" position="relative" top="-50px">
        <Image src={LeftImage} width="815px" />

        <Flex position="relative">
          <Flex left={0} position="absolute" pt={4} px={4} right={0} top={0}>
            <MakeOfferModal />
          </Flex>
          <Image src={RightImage} width="350px" />
        </Flex>
      </HStack>
    </VStack>
  );
};
