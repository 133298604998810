import { useState } from 'react';

export const useCopyPaste = () => {
  const [copied, setCopied] = useState(false);

  const copyToClipboard = (text: string, onCopied?: () => void) => {
    // Use a fallback method for copying text
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard
        .writeText(text)
        .catch(() => {
          fallbackCopyTextToClipboard(text);
        })
        .finally(() => {
          setCopied(true);
          onCopied?.();
        });
    } else {
      fallbackCopyTextToClipboard(text);
      setCopied(true);
      onCopied?.();
    }

    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  // Fallback function for copying text, used if navigator.clipboard is not available
  const fallbackCopyTextToClipboard = (text: string) => {
    const textArea = document.createElement('textarea');
    textArea.value = text;

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      document.execCommand('copy');
    } catch (err) {}

    document.body.removeChild(textArea);
  };

  return { copied, copyToClipboard };
};
