import { Flex, Text, useDisclosure } from '@chakra-ui/react';
import {
  OffersForListingFragment,
  UserObject,
} from '@client/graphql/__generated__/types';
import { BaseComparableField } from '../../Comparables/BaseComparableField';
import { UserAvatar } from '../../User/UserAvatar';
import { OfferNoteTooltip } from '../OfferNoteTooltip';
import { OFFER_COMPARE_WIDTH } from './OfferCompareColumn';

export interface OfferCompareBuyersAgentFieldProps {
  offer: OffersForListingFragment;
  buyersAgentName?: string;
  officeName?: string;
}

export const OfferCompareBuyersAgentField = ({
  offer,
  buyersAgentName,
  officeName,
}: OfferCompareBuyersAgentFieldProps) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const buyersAgentUser = offer.buyersAgent?.user;

  return (
    <BaseComparableField
      border="none"
      borderBottom="1px solid"
      borderBottomColor="border.divider"
      px={4}
      py={3}
    >
      <Flex
        alignItems="center"
        gap={3}
        justifyContent="space-between"
        width={OFFER_COMPARE_WIDTH}
      >
        <Flex alignItems="center" direction="row" gap={2}>
          <UserAvatar
            user={
              buyersAgentUser
                ? (buyersAgentUser as UserObject)
                : ({ fullName: buyersAgentName } as UserObject)
            }
          />
          <Flex flexDirection="column">
            <Text
              color="white"
              fontSize="sm"
              fontWeight="500"
              sx={{ textWrap: 'wrap' }}
            >
              {buyersAgentName}
            </Text>
            {officeName && (
              <Text color="whiteAlpha.600" fontSize="sm">
                {officeName}
              </Text>
            )}
          </Flex>
        </Flex>
        {offer.noteToListingAgent && (
          <OfferNoteTooltip
            isOpen={isOpen}
            note={offer.noteToListingAgent}
            onClose={onClose}
            onOpen={onOpen}
          />
        )}
      </Flex>
    </BaseComparableField>
  );
};
