import { Button } from '@chakra-ui/react';
import { useOfferFlowContext } from '~/apps/consumer/pages/Storefront/OfferFlow/OfferFlowContext';

export const DocPreviewButton = () => {
  const { showContractPreview, updateContractPreviewState, queriesLoading } =
    useOfferFlowContext();

  return (
    <Button
      _hover={{ bg: 'bg.mainDark' }}
      backdropBlur="blur(10px)"
      bgColor="bg.mainDarkOpacity"
      borderBottomColor="bg.mainDark"
      borderBottomLeftRadius={0}
      borderBottomRightRadius={0}
      borderTopColor="border.divider"
      borderWidth={1}
      color="text.primary"
      isLoading={queriesLoading}
      position="relative"
      right="20px"
      size="xs"
      top="2px"
      zIndex={999}
      onClick={() => updateContractPreviewState('toggle')}
    >
      {showContractPreview ? 'Hide Preview' : 'Preview Offer'}
    </Button>
  );
};
