import {
  ContractObject,
  DocumentVersionObject,
} from '@client/graphql/__generated__/types';
import { useMemo } from 'react';

export function useContractDocumentsDocumentVersions(
  contract?: ContractObject
): DocumentVersionObject[] {
  const contractDocumentsDocumentVersions = useMemo(() => {
    return (
      (contract?.contractDocuments
        .map((doc) => doc.documentVersion)
        .filter(Boolean) as DocumentVersionObject[]) || []
    );
  }, [contract]);

  return contractDocumentsDocumentVersions;
}
