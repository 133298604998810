import { Flex, StyleProps, Text } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { BaseComparableField } from '../../../Comparables/BaseComparableField';
import { COLUMN_WIDTH, COMPARABLE_FIELD_HEIGHT } from './constants';

export interface ComparableFieldProps {
  title?: string;
  text?: string | ReactNode;
  subText?: string;
  showTitle?: boolean;
  showDifference?: boolean;
  differenceDisplay?: string;
  differenceStyle?: StyleProps;
  height?: string;
  rightComponent?: ReactNode;
}

export const ComparableField = ({
  title,
  text,
  subText,
  showTitle,
  showDifference,
  differenceDisplay,
  differenceStyle,
  height = COMPARABLE_FIELD_HEIGHT,
  rightComponent,
}: ComparableFieldProps) => {
  return (
    <BaseComparableField height={height}>
      <Flex
        direction="column"
        flexBasis={COMPARABLE_FIELD_HEIGHT}
        flexGrow={0}
        flexShrink={0}
        justifyContent="center"
        px={4}
        py={2}
        width={COLUMN_WIDTH}
      >
        {showTitle && (
          <Text
            fontWeight="bold"
            minWidth="0"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
          >
            {title}
          </Text>
        )}
        <Text
          minWidth="0"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
        >
          {text}
        </Text>
        {subText && (
          <Text
            color="whiteAlpha.600"
            minWidth="0"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
          >
            {subText}
          </Text>
        )}
      </Flex>
      {showDifference && (
        <Text
          bgColor="pill.bg"
          borderRadius="5px"
          fontWeight="600"
          p="1px 4px"
          position="absolute"
          right="10px"
          size="sm"
          {...differenceStyle}
        >
          {differenceDisplay}
        </Text>
      )}
      {rightComponent && rightComponent}
    </BaseComparableField>
  );
};
