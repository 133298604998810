import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  NumberInput,
  NumberInputField,
  Text,
  VStack,
} from '@chakra-ui/react';
import {
  useGetSalesComparablesLazyQuery,
  useListingforMlsListingLazyQuery,
} from '@client/graphql/__generated__/main-operations';
import {
  ListingFragment,
  MlsListingFragment,
  MlsListingObject,
} from '@client/graphql/__generated__/types';
import { distance, point } from '@turf/turf';
import { Select } from 'chakra-react-select';
import dayjs from 'dayjs';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { formatToCurrency } from '~/services/document/utils/number';
import { CompsMap } from '../../components/BuyerOfferAnalysis/Comps/CompsMap';
import { ComparablesContainer } from '../../components/Comparables/styledComponents';
import { CONTAINER_PADDINGS } from '../../components/Layouts/Container';
import { ComparableColumn } from '../../components/Listings/Comparables/ComparableColumns/ComparableColumn';
import { MLSListingRow } from '../../components/Listings/MLSListingRow';
import { FindPropertyPopoverList } from '../../components/MakeOffer/FindPropertyPopoverList';
import { getListingPrice } from '../../utils/listing.utils';

export const CompsTestPage = () => {
  const isEnabled = useFeatureFlagEnabled('comps-test');
  const navigate = useNavigate();
  const [listingForMlsListing, { loading: loadingListing }] =
    useListingforMlsListingLazyQuery();
  const [selectedMlsListing, setSelectedMlsListing] = useState<
    MlsListingObject | undefined
  >(undefined);
  const [selectedListing, setSelectedListing] = useState<
    ListingFragment | undefined
  >(undefined);
  const [comparables, setComparables] = useState<
    MlsListingFragment[] | undefined
  >();
  const [options, setOptions] = useState<{
    min_sim_score?: number;
    num_comps?: number;
    distance?: number;
    min_date?: string;
    status?: string;
  }>({});

  const [getSalesComparables, { loading: loadingSalesComparables }] =
    useGetSalesComparablesLazyQuery();

  const getSelectedListingId = async (mlsListingObject: MlsListingObject) => {
    const { data } = await listingForMlsListing({
      variables: {
        input: {
          id: mlsListingObject.id,
        },
      },
    });

    if (data?.syncListingFromMLS) {
      return data.syncListingFromMLS.id;
    }
  };

  const onSubmit = async () => {
    if (!selectedMlsListing) {
      return;
    }
    const listingId = await getSelectedListingId(selectedMlsListing);

    if (!listingId) {
      //TODO: SHOW ERROR TOAST
      return;
    }

    const { data } = await getSalesComparables({
      variables: {
        input: {
          listingId,
          ...options,
        },
      },
    });

    setSelectedListing(data?.getSalesComparables?.listing as ListingFragment);
    setComparables(
      data?.getSalesComparables?.comparables as MlsListingObject[]
    );
  };

  const statusOptions = [
    { label: 'Active', value: 'ACTIVE' },
    { label: 'Off Market', value: 'OFF_MARKET' },
    { label: 'Both', value: 'BOTH' },
  ];

  if (!isEnabled) {
    navigate('/');

    return null;
  }

  const numberOfComps = comparables?.length || 0;

  const minCompPrice = comparables?.reduce((min, comparable) => {
    return Math.min(min, getListingPrice(comparable));
  }, Infinity);
  const maxCompPrice = comparables?.reduce((max, comparable) => {
    return Math.max(max, getListingPrice(comparable));
  }, -Infinity);

  const maxDistance = comparables?.reduce((max, comparable) => {
    const distanceInMiles =
      selectedListing?.mlsListing?.geo?.location && comparable.geo?.location
        ? distance(
            point([
              selectedListing?.mlsListing?.geo?.location?.lon,
              selectedListing?.mlsListing?.geo?.location?.lat,
            ]),
            point([
              comparable.geo?.location?.lon,
              comparable.geo?.location?.lat,
            ]),
            { units: 'miles' }
          )
        : 0;

    return Math.max(max, distanceInMiles);
  }, 0);

  const maxDaysOnMarket = comparables?.reduce((max, comparable) => {
    return Math.max(max, comparable.mls?.daysOnMarket || 0);
  }, 0);

  return (
    <Container centerContent maxW="container.xl" {...CONTAINER_PADDINGS}>
      <VStack gap={4} width="100%">
        <Flex width="100%">
          <Heading>Comps Test Page</Heading>
        </Flex>
        <VStack flexGrow={1} spacing={4} width="500px">
          <FormControl>
            <FormLabel>Select a Property</FormLabel>
            <FindPropertyPopoverList
              closeMenuOnSelect
              size="sm"
              onSelect={(mlsListing) => {
                setSelectedMlsListing(mlsListing);
              }}
            />
          </FormControl>
          {selectedMlsListing && (
            <MLSListingRow
              key={selectedMlsListing.id}
              listing={selectedMlsListing}
              onRemove={() => {
                setSelectedMlsListing(undefined);
              }}
            />
          )}
          <HStack gap={4} width="100%">
            <FormControl flexBasis={0} flexGrow={1}>
              <FormLabel>Min Sim Score (default 93)</FormLabel>
              <NumberInput
                size="lg"
                value={options.min_sim_score}
                onChange={(valueAsString, valueAsNumber) =>
                  setOptions({
                    ...options,
                    min_sim_score: valueAsNumber || undefined,
                  })
                }
              >
                <NumberInputField />
              </NumberInput>
            </FormControl>
            <FormControl flexBasis={0} flexGrow={1}>
              <FormLabel>Max Num Comps (default 5)</FormLabel>
              <NumberInput
                size="lg"
                value={options.num_comps}
                onChange={(valueAsString, valueAsNumber) =>
                  setOptions({
                    ...options,
                    num_comps: valueAsNumber || undefined,
                  })
                }
              >
                <NumberInputField />
              </NumberInput>
            </FormControl>
          </HStack>
          <HStack gap={4} width="100%">
            <FormControl flexBasis={0} flexGrow={1}>
              <FormLabel>Max Distance (miles) (default 5)</FormLabel>
              <NumberInput
                size="lg"
                value={options.distance}
                onChange={(valueAsString, valueAsNumber) =>
                  setOptions({
                    ...options,
                    distance: valueAsNumber || undefined,
                  })
                }
              >
                <NumberInputField />
              </NumberInput>
            </FormControl>
            <FormControl flexBasis={0} flexGrow={1}>
              <FormLabel>
                Min Date (default{' '}
                {dayjs().subtract(6, 'month').format('YYYY-MM-DD')})
              </FormLabel>
              <Input
                placeholder="YYYY-MM-DD"
                value={options.min_date}
                onChange={(e) =>
                  setOptions({ ...options, min_date: e.target.value })
                }
              />
            </FormControl>
          </HStack>
          <FormControl>
            <FormLabel>Status</FormLabel>
            <Select<{ label: string; value: string }>
              isMulti={false}
              options={statusOptions}
              placeholder="Both"
              value={statusOptions.find(
                (option) => option.value === options.status
              )}
              onChange={(option) =>
                setOptions({ ...options, status: option?.value })
              }
            />
          </FormControl>
          <Button
            isDisabled={!selectedMlsListing}
            isLoading={loadingListing || loadingSalesComparables}
            width="100%"
            onClick={() => void onSubmit()}
          >
            Submit
          </Button>
        </VStack>
        {selectedListing && comparables && (
          <VStack gap={4} width="100%">
            <HStack gap={4} height="400px" width="100%">
              <CompsMap
                comparableSet={{
                  id: '123',
                  listingComparables: comparables?.map((comparable, index) => ({
                    id: comparable.id,
                    order: index + 1,
                    mlsListing: comparable,
                  })),
                  listing: selectedListing,
                  agent: { id: '123' },
                }}
                mainListing={selectedListing}
              />
              <Card flexBasis={0} flexGrow={1} height="100%">
                <CardHeader pb={6}>
                  <Heading size="smallUppercase">
                    Comparative market analysis
                  </Heading>
                </CardHeader>
                <CardBody>
                  <VStack gap={4} width="100%">
                    <HStack justifyContent="space-between" width="100%">
                      <Text fontWeight="medium">Number of Properties</Text>
                      <Text>{numberOfComps}</Text>
                    </HStack>
                    <Divider />
                    <HStack justifyContent="space-between" width="100%">
                      <Text fontWeight="medium">Comp Range</Text>
                      <Text>
                        {formatToCurrency(minCompPrice, 0, 'compact')} -{' '}
                        {formatToCurrency(maxCompPrice, 0, 'compact')}
                      </Text>
                    </HStack>
                    <Divider />
                    <HStack justifyContent="space-between" width="100%">
                      <Text fontWeight="medium">
                        Distance from subject property
                      </Text>
                      <Text>{maxDistance?.toFixed(1)} miles</Text>
                    </HStack>
                    <Divider />
                    <HStack justifyContent="space-between" width="100%">
                      <Text fontWeight="medium">Oldest comp</Text>
                      <Text>{maxDaysOnMarket} days on market</Text>
                    </HStack>
                    <Divider />
                  </VStack>
                </CardBody>
              </Card>
            </HStack>
            <ComparablesContainer width="100%">
              <ComparableColumn
                isMainListing
                mainMlsListing={selectedListing?.mlsListing as MlsListingObject}
                mlsListing={selectedListing?.mlsListing as MlsListingObject}
              />
              {comparables?.map((comparable) => (
                <ComparableColumn
                  key={comparable.id}
                  mainMlsListing={
                    selectedListing?.mlsListing as MlsListingObject
                  }
                  mlsListing={comparable as MlsListingObject}
                />
              ))}
            </ComparablesContainer>
          </VStack>
        )}
      </VStack>
    </Container>
  );
};
