import { Box, chakra, Flex } from '@chakra-ui/react';

interface PageDotIndicatorProps {
  currentStepIndex: number;
  totalSteps: number;
  onClick: (stepIndex: number) => void;
}

export const PageDotIndicator = ({
  currentStepIndex,
  totalSteps,
  onClick,
}: PageDotIndicatorProps) => {
  return (
    <Flex gap={3}>
      {Array.from({ length: totalSteps }, (_, index) =>
        index === currentStepIndex ? (
          <ActiveDot key={index} />
        ) : (
          <StepDot key={index} onClick={() => onClick(index)} />
        )
      )}
    </Flex>
  );
};

export const StepDot = chakra(Box, {
  baseStyle: {
    width: 2,
    height: 2,
    borderRadius: 'full',
    border: '1px solid',
    borderColor: 'whiteAlpha.400',
    cursor: 'pointer',
  },
});

export const ActiveDot = chakra(StepDot, {
  baseStyle: {
    width: 2,
    height: 2,
    borderRadius: 'full',
    border: '1px solid',
    borderColor: 'whiteAlpha.400',
    background: 'gray.300',
  },
});
