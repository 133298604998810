import { Button, FormControl, FormLabel, VStack } from '@chakra-ui/react';
import { PartyFragment } from '@client/graphql/__generated__/types';
import { FC, memo, useEffect, useState } from 'react';
import { CenterSpinner } from '~/common/components/CenterSpinner';
import {
  ADD_BUYER_FORM_ID,
  AddBuyerForm,
} from '../../Buyer/AddBuyerForm/AddBuyerForm';
import { useAddBuyerForm } from '../../Buyer/AddBuyerForm/hooks/useAddBuyerForm';
import { PartySelector } from '../../Buyer/PartySelector/PartySelector';
import { SelectedPartyInfoForm } from '../../Buyer/SelectedPartyInfo/SelectedPartyInfoForm';
import { DocumentSectionProps } from '../DocumentSection';

export interface DocumentSectionBuyerSelectProps {
  selectedParty?: PartyFragment;
  showSelectedPartyInfo?: boolean;
  onCreateParty?: () => void;
  onSelectParty: (party?: PartyFragment) => void;
}

export const DocumentSectionBuyerSelect: FC<DocumentSectionProps> = memo(
  function DocumentSectionBuyerSelect({ buyerSelectProps, documentSection }) {
    const [isLoading, setIsLoading] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [showAddBuyerForm, setShowAddBuyerForm] = useState(false);
    const { buyerParties, loading, createParty, updateParty } = useAddBuyerForm(
      {
        selectedParty: buyerSelectProps?.selectedParty,
        onSelectParty: buyerSelectProps?.onSelectParty,
      }
    );

    useEffect(() => {
      if (buyerSelectProps?.selectedParty) {
        const secondaryConsumerEmails =
          buyerSelectProps.selectedParty.secondaryConsumers.map(
            (consumer) => consumer.email
          ) || [];
        if (
          !buyerSelectProps.selectedParty.primaryConsumer.email ||
          !secondaryConsumerEmails.every((email) => !!email)
        ) {
          setIsEditing(true);
        } else {
          setIsEditing(false);
        }
      }
    }, [buyerSelectProps?.selectedParty]);

    if (!buyerParties && loading) {
      return <CenterSpinner />;
    }

    return buyerSelectProps ? (
      <FormControl id={documentSection.id} width="100%">
        <FormLabel variant="normal">{documentSection.title}</FormLabel>
        <VStack alignItems="stretch" flexGrow={1} spacing={4}>
          <PartySelector
            parties={buyerParties}
            setShowAddBuyerForm={setShowAddBuyerForm}
            showAddBuyerForm={showAddBuyerForm}
            {...buyerSelectProps}
          />
          {showAddBuyerForm ? (
            <AddBuyerForm
              onSubmit={async (values) => {
                setIsLoading(true);
                await createParty(values);
                setIsLoading(false);
                setShowAddBuyerForm(false);
                buyerSelectProps.onCreateParty?.();
              }}
            />
          ) : undefined}
          {buyerSelectProps.selectedParty && isEditing ? (
            <SelectedPartyInfoForm
              handleSubmit={updateParty}
              isEditing={isEditing}
              party={buyerSelectProps.selectedParty}
              setIsEditing={setIsEditing}
              showFinancingInfo={false}
            />
          ) : undefined}

          {showAddBuyerForm && (
            <Button
              form={ADD_BUYER_FORM_ID}
              isLoading={isLoading}
              type="submit"
            >
              Add Buyer
            </Button>
          )}
        </VStack>
      </FormControl>
    ) : null;
  }
);
