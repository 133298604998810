import { SlideFade } from '@chakra-ui/react';
import { FC } from 'react';
import { Navigate } from 'react-router';
import { ChooseContract } from '~/apps/consumer/components/OfferFlow/ChooseContract';
import { useOfferFlowContext } from './OfferFlowContext';

export const ChooseContractPage: FC = () => {
  const { documents, activeDocument, setActiveDocumentId } =
    useOfferFlowContext();

  if (documents?.length === 1) {
    return <Navigate replace to="../key-terms" />;
  }

  return (
    <SlideFade in>
      <ChooseContract
        activeDocumentId={activeDocument?.id}
        documents={documents}
        onChange={(document) => setActiveDocumentId(document.id)}
      />
    </SlideFade>
  );
};
