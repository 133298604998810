import {
  Card,
  CardBody,
  CardHeader,
  chakra,
  Collapse,
  Flex,
  forwardRef,
  IconButton,
} from '@chakra-ui/react';
import { ChevronDown, ChevronUp } from 'lucide-react';

interface CollapsibleCardProps {
  isExpanded: boolean;
  onCollapse: () => void;
  onExpand: () => void;
  header: React.ReactNode;
  body: React.ReactNode;
}

// forwardRef to allow popover or tooltip
// https://v2.chakra-ui.com/docs/components/popover#popover-anchor
export const CollapsibleCard = forwardRef<CollapsibleCardProps, 'div'>(
  ({ isExpanded, onCollapse, onExpand, header, body }, ref) => {
    return (
      <Card ref={ref}>
        <CardHeader
          cursor="pointer"
          p={5}
          pb={5}
          onClick={() => (isExpanded ? onCollapse() : onExpand())}
        >
          <Flex alignItems="center" justifyContent="space-between">
            {header}
            <IconButton
              aria-label="expand"
              height={6}
              icon={isExpanded ? <ChevronUp /> : <ChevronDown />}
              size="xs"
              variant="unstyled"
            />
          </Flex>
        </CardHeader>
        <StyledCollapse in={isExpanded}>
          <CardBody pb={3} pt={0} px={3}>
            {body}
          </CardBody>
        </StyledCollapse>
      </Card>
    );
  }
);

const StyledCollapse = chakra(Collapse, {
  baseStyle: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'flex-start',
    borderRadius: '12px',
    borderBottom: '1px solid',
    borderBottomColor: 'card.border',
    verticalAlign: 'top',
  },
});
