import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Flex,
  Text,
} from '@chakra-ui/react';
import { ContractValidationFragment } from '@client/graphql/__generated__/types';
import { useState } from 'react';
import { useOfferFlowContext } from '~/apps/consumer/pages/Storefront/OfferFlow/OfferFlowContext';
import { GradientBorder } from '~/common/components/GradientBorder/GradientBorder';
import { DocumentSectionWithChildren } from '~/services/document/components/DocumentSections/types';
import { FieldValueDisplay } from '~/services/document/components/Fields/FieldValueDisplay';
import {
  FormField,
  ValuesType,
} from '~/services/document/components/FormFields/types';
import { useOfferValidations } from '~/services/document/hooks/useOfferValidations';
import { FormFieldControl } from '../../../InputField/FormFieldControl';
import { SimpleListRow } from '../../../Lists/SimpleList';

interface StartOfferUploadCardProps {
  documentSection?: DocumentSectionWithChildren;
  fieldValues: ValuesType;
  validations: ContractValidationFragment[];
  title?: string;
  editable?: boolean;
  onChange?: (value: ValuesType) => void;
  formFieldsMap?: Record<string, FormField>;
}

export const StartOfferUploadCard = ({
  editable,
  documentSection,
  fieldValues,
  validations,
  title,
  formFieldsMap,
  onChange,
}: StartOfferUploadCardProps) => {
  const { mergedAutofillValues, save, refetchLatestOffer } =
    useOfferFlowContext();
  const { getValidation } = useOfferValidations({
    validations,
  });
  const [isSaving, setIsSaving] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const onSave = async () => {
    setIsSaving(true);
    await save(undefined, true);
    await refetchLatestOffer();
    setIsEditing(false);
    setIsSaving(false);
  };

  return (
    <Card width="100%">
      <CardHeader>
        <Flex justifyContent="space-between">
          {title ?? ''}
          {editable &&
            (isEditing ? (
              <Button
                isLoading={isSaving}
                size="xs"
                variant="card-header"
                onClick={() => void onSave()}
              >
                Save
              </Button>
            ) : (
              <Button
                size="xs"
                variant="card-header"
                onClick={() => setIsEditing(true)}
              >
                Edit
              </Button>
            ))}
        </Flex>
      </CardHeader>
      <CardBody>
        {isEditing && (
          <GradientBorder mb={4}>
            <Text>
              NOTE: Please only change these fields if the data was extracted
              incorrectly from the offer contract. Editing this field does NOT
              update the contract itself.
            </Text>
          </GradientBorder>
        )}
        {documentSection?.documentSectionFields?.map((definition) => {
          const formField = formFieldsMap?.[definition.field.mappingKey];

          return isEditing ? (
            <FormFieldControl
              key={definition.field.label}
              autofillValue={mergedAutofillValues[definition.field.mappingKey]}
              formField={formField as FormField}
              label={definition.field.label}
              validation={getValidation(definition.field.mappingKey)}
              value={fieldValues[definition.field.mappingKey] as string}
              onChange={onChange}
            />
          ) : (
            <SimpleListRow
              key={definition.field.id}
              label={definition.field.label}
            >
              <FieldValueDisplay
                compareValue={mergedAutofillValues[definition.field.mappingKey]}
                field={definition.field}
                validation={getValidation(definition.field.mappingKey)}
                value={fieldValues[definition.field.mappingKey]}
              />
            </SimpleListRow>
          );
        })}
      </CardBody>
    </Card>
  );
};
