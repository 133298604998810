import {
  Box,
  Flex,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useIsMobile } from '~/common/hooks/useIsMobile';
import { DocumentsThumbnailsWidgetWithContext } from '~/services/document/components/DocumentMapperWidgets/DocumentsThumbnailsWidget/DocumentsThumbnailsWidget';
import {
  PDFScrollable,
  PDFScrollableProps,
} from '~/services/document/components/PDFScrollable/PDFScrollable';
import {
  DocumentsThumbnails,
  VisiblePage,
} from '~/services/document/components/PDFScrollable/types';

export interface BasicPdfViewModalProps {
  isOpen: boolean;
  onClose: () => void;
  header?: React.ReactNode;
  pdfFiles: PDFScrollableProps['pdfFiles'];
  showThumbnails?: boolean;
  signGuestbookOverlay?: React.ReactNode;
}

export const BasicPdfViewModal = ({
  isOpen,
  onClose,
  header,
  pdfFiles,
  showThumbnails = true,
  signGuestbookOverlay,
}: BasicPdfViewModalProps) => {
  const [thumbnails, setThumbnails] = useState<DocumentsThumbnails>({});
  const [visiblePage, setVisiblePage] = useState<VisiblePage | null>(null);
  const isMobile = useIsMobile();

  return (
    <Modal
      isOpen={isOpen}
      scrollBehavior="inside"
      size="full"
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent
        background="indigo.700"
        border="1px"
        borderColor="whiteAlpha.200"
        borderRadius="md"
        maxWidth={{ base: '100%', md: '80%' }}
      >
        <ModalHeader>{header}</ModalHeader>
        <ModalCloseButton />
        <ModalBody height="80vh" py={4}>
          <Flex direction="column" gap={4}>
            <Box>
              {/* Inspiration from OfferFlow/PreviewTabContent */}
              <HStack alignItems="stretch" width="100%">
                {!isMobile && (
                  <Box
                    maxHeight="calc(100vh - 100px)"
                    width={{ base: '120px', '2xl': '200px' }}
                  >
                    {showThumbnails && visiblePage && (
                      <DocumentsThumbnailsWidgetWithContext
                        documentsThumbnails={thumbnails}
                        visiblePage={visiblePage}
                      />
                    )}
                  </Box>
                )}
                <Box
                  bgColor="gray.400"
                  height={isMobile ? '80vh' : 'calc(100vh - 100px)'}
                  overflow="hidden"
                  position="relative"
                  width="100%"
                >
                  {signGuestbookOverlay}
                  <PDFScrollable
                    defaultZoom={isMobile ? 1.5 : 1}
                    maxWidth={1200}
                    pdfFiles={pdfFiles}
                    onDocumentsThumbnailsChange={setThumbnails}
                    onVisiblePageChange={setVisiblePage}
                  />
                </Box>
              </HStack>
            </Box>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
