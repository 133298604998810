/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  Checkbox,
  Input,
  Portal,
  Radio,
  useColorMode,
} from '@chakra-ui/react';
import {
  FieldType,
  InputFormatType,
} from '@client/graphql/__generated__/types';
import { FormField, ValuesType } from '@document/components/FormFields/types';
import { SelectInstance } from 'chakra-react-select';
import dayjs from 'dayjs';
import { debounce } from 'lodash';
import {
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import ReactDatePicker from 'react-datepicker';
import { CurrencyInput } from '~/common/components/Inputs/CurrencyInput';
import { useIsMobile } from '~/common/hooks/useIsMobile';
import { Select } from '~/services/document/common/Select';

export interface InputFieldProps {
  formField: FormField;
  value?: string | boolean | number | string[];
  placeholder?: string;
  autoFocus?: boolean;
  color?: string;
  transition?: string;
  optionsAutofillValue?: any;
  onChange?: (value: ValuesType) => void;
  onFocus?: (mappingKey?: string) => void;
  onBlur?: (mappingKey?: string) => void;
}

export const InputField: FC<InputFieldProps> = memo(function InputField({
  formField,
  value,
  color,
  transition,
  placeholder,
  autoFocus,
  optionsAutofillValue,
  onChange,
  onFocus,
  onBlur,
}) {
  const { colorMode } = useColorMode();
  const selectRef =
    useRef<SelectInstance<{ label: string; value: Record<string, any> }>>(null);
  const [inputValue, setInputValue] = useState<
    string | number | boolean | string[] | undefined
  >(value || '');
  const isMobile = useIsMobile();

  const onChangeDebounce = useMemo(
    () => (onChange ? debounce(onChange, 300) : null),
    [onChange]
  );
  const onChangeHandler = useCallback(
    (value: string | boolean | number | undefined) => {
      setInputValue(value);
      onChangeDebounce && onChangeDebounce({ [formField.mappingKey]: value });
    },
    [onChangeDebounce, formField.mappingKey]
  );
  const onFocusHandler = useCallback(
    (mappingKey?: string, target?: Element) => {
      if (isMobile) {
        window.scrollTo(0, 0);
        window.requestAnimationFrame(() => {
          target?.scrollIntoView({ block: 'center' });
        });
      }

      onFocus && onFocus(mappingKey);
    },
    [isMobile, onFocus]
  );

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  switch (formField.fieldType) {
    case FieldType.CHECKBOX:
      return (
        <Checkbox
          isChecked={Boolean(inputValue)}
          onChange={(e) => onChangeHandler(e.currentTarget.checked)}
        />
      );

    case FieldType.RADIO:
      return (
        <Radio
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          inputProps={{ 'data-mappingkey': formField.mappingKey }}
          isChecked={Boolean(inputValue)}
          name={formField.field?.group}
          onChange={() => {
            const radios = document.querySelectorAll<HTMLInputElement>(
              `input[name="${formField.field?.group || ''}"]`
            );
            const radioChangedValues = Array.from(radios).reduce(
              (out, radio) => {
                out[radio.dataset.mappingkey as string] =
                  radio.dataset.mappingkey === formField.mappingKey;

                return out;
              },
              {} as ValuesType
            );

            onChange && onChange(radioChangedValues);
          }}
        />
      );

    case FieldType.NUMBER:
      return (
        <CurrencyInput
          allowDecimals={(formField.inputFormat?.options.precision || 0) > 0}
          autoComplete="off"
          autoFocus={autoFocus}
          color={color}
          decimalScale={formField.inputFormat?.options.precision || 0}
          decimalsLimit={formField.inputFormat?.options.precision || 0}
          placeholder={placeholder}
          prefix={
            formField.inputFormat?.type === InputFormatType.CURRENCY ? '$' : ''
          }
          size="sm"
          transition={transition}
          value={inputValue as number}
          onBlur={() => onBlur && onBlur(formField.mappingKey)}
          onFocus={(e) =>
            onFocusHandler && onFocusHandler(formField.mappingKey, e.target)
          }
          onValueChange={(valueStr) => {
            onChangeHandler(valueStr ? parseFloat(valueStr) : undefined);
          }}
        />
      );

    case FieldType.DATE:
      return isMobile ? (
        <Input
          autoComplete="off"
          autoFocus={autoFocus}
          color={color}
          placeholder={placeholder}
          size="sm"
          transition={transition}
          type="date"
          value={inputValue ? String(inputValue) : ''}
          onBlur={() => onBlur && onBlur(formField.mappingKey)}
          onChange={(e) =>
            onChangeHandler && onChangeHandler(e.currentTarget.value)
          }
          onFocus={(e) =>
            onFocusHandler && onFocusHandler(formField.mappingKey, e.target)
          }
        />
      ) : (
        <ReactDatePicker
          customInput={
            <Input
              autoComplete="off"
              autoFocus={autoFocus}
              color={color}
              placeholder={placeholder}
              size="sm"
              transition={transition}
            />
          }
          dateFormat="MM/dd/yyyy"
          name={formField.mappingKey}
          popperClassName={colorMode === 'dark' ? 'dark' : undefined}
          popperContainer={({ children }) => (
            <Portal>
              <Box position="relative" zIndex="var(--chakra-zIndices-popover)">
                {children}
              </Box>
            </Portal>
          )}
          popperModifiers={[
            {
              name: 'offset',
              options: {
                offset: [0, 20],
              },
            },
          ]}
          popperPlacement="top"
          selected={
            inputValue && dayjs(inputValue as string).isValid()
              ? dayjs(inputValue as string).toDate()
              : undefined
          }
          value={inputValue ? (inputValue as string) : ''}
          onBlur={() => onBlur && onBlur(formField.mappingKey)}
          onChange={(date) => {
            if (date) {
              const dateVal = dayjs(date);
              onChangeHandler(dateVal.format('MM/DD/YYYY'));
            } else {
              onChangeHandler('');
            }
          }}
          onFocus={(e) =>
            onFocusHandler && onFocusHandler(formField.mappingKey, e.target)
          }
        />
      );

    case FieldType.SELECT:
      if (!optionsAutofillValue) break;

      return (
        <Box width="100%">
          <Select<{ label: string; value: any }>
            isClearable
            isOptionSelected={(option, selectedOptions) => {
              return !!selectedOptions.find(
                (selectedOption) => selectedOption.label === option.label
              );
            }}
            menuPortalTarget={document.body}
            options={optionsAutofillValue as any[]}
            selectRef={selectRef}
            size="sm"
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            value={
              inputValue
                ? {
                    label: inputValue as string,
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
                    value: optionsAutofillValue.find(
                      (option: { label: string; value: any }) => {
                        return option.label === inputValue;
                      }
                      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                    )?.value,
                  }
                : undefined
            }
            onChange={(option) => {
              if (option?.value) {
                setInputValue(option?.label);
                onChangeDebounce?.(option?.value);
              } else {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                const options = optionsAutofillValue;
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                const optionKeys = Object.keys(options?.[0]?.value || {});
                const allUnchecked = optionKeys.reduce(
                  (out, key) => {
                    out[key] = false;

                    return out;
                  },
                  {} as Record<string, boolean>
                );

                setInputValue(undefined);
                onChangeDebounce?.(allUnchecked);
              }
            }}
            onFocus={(e) => {
              const selectValue = selectRef.current?.state.selectValue?.[0];
              const mappingKey = Object.keys(selectValue?.value || {}).find(
                (key) => {
                  return selectValue?.value?.[key];
                }
              );

              window.requestAnimationFrame(
                () => onFocusHandler && onFocusHandler(mappingKey, e.target)
              );
            }}
          />
        </Box>
      );
  }

  return (
    <Input
      autoComplete="off"
      autoFocus={autoFocus}
      color={color}
      placeholder={placeholder}
      size="sm"
      transition={transition}
      value={inputValue ? String(inputValue) : ''}
      onBlur={() => onBlur && onBlur(formField.mappingKey)}
      onChange={(e) =>
        onChangeHandler && onChangeHandler(e.currentTarget.value)
      }
      onFocus={(e) =>
        onFocusHandler && onFocusHandler(formField.mappingKey, e.target)
      }
    />
  );
});
