import { Flex, Heading } from '@chakra-ui/react';
import { CurrentUserFragment } from '@client/graphql/__generated__/types';
import { FC, RefObject } from 'react';
import { PublicProfileFeatureButton } from '../Account/PublicProfileFeatureButton';

interface MainDashboardHeaderProps {
  user: CurrentUserFragment | undefined;
  hasViewedTour: boolean;
  flexRef: RefObject<HTMLDivElement>;
}

export const MainDashboardHeader: FC<MainDashboardHeaderProps> = ({
  user,
  hasViewedTour,
  flexRef,
}) => {
  return (
    <Flex
      ref={flexRef}
      alignItems={{ base: 'start', sm: 'center' }}
      direction={{ base: 'column', sm: 'row' }}
      gap={{ base: 4, sm: 0 }}
      mb={6}
      width="100%"
    >
      <Heading as="h1" flexGrow={1} size="sm">
        {hasViewedTour ? 'Welcome back, ' : 'Welcome to Indigo, '}
        {user?.firstName}
      </Heading>

      <PublicProfileFeatureButton />
    </Flex>
  );
};
