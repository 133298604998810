import {
  Box,
  Divider,
  Flex,
  RangeSlider,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  RangeSliderTrack,
  Text,
} from '@chakra-ui/react';
import { KeyTermUnit } from '@client/graphql/__generated__/types';
import isNil from 'lodash/isNil';
import { formatRangeValue } from './utils';

interface KeyTermsRangeChartProps {
  value?: number | null;
  range: [number, number];
  unit: KeyTermUnit;
  dotColor?: string;
}

export const KeyTermsRangeChart = ({
  value,
  range,
  unit,
  dotColor,
}: KeyTermsRangeChartProps) => {
  const roundRangeLabels = (num: number): number => {
    const magnitude = Math.floor(Math.log10(Math.abs(num)));
    const roundingFactor =
      // Binds the range labels to the nearest 5 or 10
      (unit === KeyTermUnit.DAYS ? 10 : 5) * Math.pow(10, magnitude - 2);

    return Math.round(num / roundingFactor) * roundingFactor;
  };

  const difference = roundRangeLabels((range[1] - range[0]) / 3);

  const rangeLabels = [
    formatRangeValue(roundRangeLabels(range[0] - difference), unit),
    formatRangeValue(roundRangeLabels(range[0]), unit),
    formatRangeValue(roundRangeLabels(range[0] + difference), unit),
    formatRangeValue(roundRangeLabels(range[0] + difference * 2), unit),
    formatRangeValue(roundRangeLabels(range[1]), unit),
    formatRangeValue(roundRangeLabels(range[1] + difference), unit),
  ];

  const calculateDotPosition = (value: number): string => {
    const minValue = range[0] - difference; // First range label
    const maxValue = range[1] + difference; // Last range label
    const totalRange = maxValue - minValue;

    // Calculate percentage (0-100)
    const position = ((value - minValue) / totalRange) * 100;

    // Clamp the value between 0-100 to ensure dot stays within bounds
    const clampedPosition = Math.max(0, Math.min(100, position));

    // Account for padding on range slider
    const addOrSubtract = clampedPosition > 50 ? '-4px' : '4px';
    const leftValue = `calc(${clampedPosition}% + ${addOrSubtract})`;

    return leftValue;
  };

  const dotPosition = !isNil(value) ? calculateDotPosition(value) : 0;

  const valueGreaterThanRange = !isNil(value) && value > range[1] + difference;
  const valueLessThanRange = !isNil(value) && value < range[0] - difference;
  const valueInRange = !valueGreaterThanRange && !valueLessThanRange;

  const dotLabelMargin =
    value && value > range[1] + difference / 2 ? -70 : null;

  return (
    <Flex alignItems="center" gap={1} height="150px" width="100%">
      {valueLessThanRange && (
        <OutOfRangeSection
          dotColor={dotColor}
          isGreaterThanRange={valueGreaterThanRange}
          unit={unit}
          value={value}
        />
      )}
      <Flex
        direction="column"
        height="100%"
        justifyContent="center"
        width="100%"
      >
        <Flex alignItems="center">
          <Flex alignItems="center" position="relative" px={2} width="100%">
            {!isNil(value) && valueInRange && (
              <>
                <Box
                  _after={{
                    position: 'absolute',
                    content: '""',
                    borderLeft: '12px solid transparent',
                    borderRight: `${
                      dotLabelMargin ? '0px' : '8px'
                    } solid transparent`,
                    borderTop: '8px solid',
                    borderTopColor: dotColor,
                    left: dotLabelMargin ? '85%' : '50%',
                    transform: 'translate(-50%, 0)',
                    transition: 'left 0.3s ease-in-out',
                    zIndex: 2,
                  }}
                  background={dotColor}
                  borderRadius="3px"
                  bottom="28px"
                  left={
                    (dotLabelMargin || 0) < 0
                      ? `calc(${dotPosition} + ${dotLabelMargin}px)`
                      : dotPosition
                  }
                  position="absolute"
                  px={1}
                  transform={
                    (dotLabelMargin || 0) < 0 ? undefined : 'translateX(-50%)'
                  }
                  transition="left 0.3s ease-in-out"
                  zIndex={2}
                >
                  <Text color="indigo.900" fontWeight="medium">
                    Your Offer
                  </Text>
                </Box>
                <Box
                  background={dotColor}
                  borderRadius="full"
                  boxSize="16px"
                  left={dotPosition}
                  position="absolute"
                  transform="translateX(-50%)"
                  transition="left 0.3s ease-in-out"
                  zIndex={2}
                />
              </>
            )}
            <RangeSlider
              aria-label={['min', 'max']}
              defaultValue={[20, 80]}
              isDisabled={true}
            >
              <RangeSliderTrack>
                <RangeSliderFilledTrack />
              </RangeSliderTrack>
              <RangeSliderThumb index={0} />
              <RangeSliderThumb index={1} />
            </RangeSlider>
          </Flex>
        </Flex>
        <Flex justifyContent="space-between">
          {rangeLabels.map((label) => (
            <Text key={label}>{label}</Text>
          ))}
        </Flex>
      </Flex>
      {valueGreaterThanRange && (
        <OutOfRangeSection
          dotColor={dotColor}
          isGreaterThanRange={valueGreaterThanRange}
          unit={unit}
          value={value}
        />
      )}
    </Flex>
  );
};

const OutOfRangeSection = ({
  value,
  unit,
  dotColor = 'gray.500',
  isGreaterThanRange,
}: {
  value: number;
  unit: KeyTermUnit;
  dotColor?: string;
  isGreaterThanRange: boolean;
}) => {
  const formattedValue = formatRangeValue(value, unit);

  return (
    <Flex direction="column" height="100%" justifyContent="center" width="30%">
      <Flex gap={1} position="relative" width="100%">
        {isGreaterThanRange && (
          <Divider
            borderColor="whiteAlpha.500"
            height="80%"
            orientation="vertical"
            variant="dashed"
          />
        )}
        <Box
          _after={{
            content: '""',
            borderLeft: '8px solid transparent',
            borderRight: '8px solid transparent',
            borderTop: '8px solid',
            borderTopColor: dotColor,
            left: '50%',
            position: 'absolute',
            transform: 'translate(-50%, 0)',
            transition: 'left 0.3s ease-in-out',
            zIndex: 2,
          }}
          background={dotColor}
          borderRadius="3px"
          bottom="28px"
          left="50%"
          position="absolute"
          px={1}
          transform="translateX(-50%)"
          transition="left 0.3s ease-in-out"
          zIndex={1}
        >
          <Text color="indigo.900" fontWeight="medium">
            Your Offer
          </Text>
        </Box>
        <RangeSlider
          defaultValue={[50]}
          id="left-slider"
          isDisabled={true}
          mx={2}
        >
          <RangeSliderTrack />
          <RangeSliderThumb
            _disabled={{
              background: dotColor,
            }}
            index={0}
          />
        </RangeSlider>

        {!isGreaterThanRange && (
          <Divider
            borderColor="whiteAlpha.500"
            orientation="vertical"
            variant="dashed"
          />
        )}
      </Flex>
      <Flex justifyContent="center">
        <Text>{formattedValue}</Text>
      </Flex>
    </Flex>
  );
};
