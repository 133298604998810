import { Heading, Icon, Text, VStack } from '@chakra-ui/react';
import { LucideIcon } from 'lucide-react';
import { FC, useState } from 'react';
import { SvgGradient } from '~/common/components/Svg/SvgGradient';

type CardEmptyStateProps = {
  title: string;
  description: string;
  icon: LucideIcon;
};

export const CardEmptyState: FC<CardEmptyStateProps> = (props) => {
  const [generatedId, setGeneratedId] = useState('');

  return (
    <VStack alignItems="center" height="100%" justifyContent="center" p={4}>
      <SvgGradient gradientThemeKey="purple" onIdGenerated={setGeneratedId} />
      <Icon
        as={props.icon}
        height="44px"
        stroke={`url(#${generatedId})`}
        strokeWidth="1px"
        width="44px"
      />
      <Heading as="h2" fontSize="20px" size="md" textAlign="center">
        {props.title}
      </Heading>
      <Text color="whiteAlpha.600" textAlign="center">
        {props.description}
      </Text>
    </VStack>
  );
};
