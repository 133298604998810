import { Flex, Text } from '@chakra-ui/react';
import { NewText } from '~/common/components/NewText/NewText';
import { SecondaryNavButton } from '../Navigation/SecondaryNavButton';

export const SIDEBAR_WIDTH = '320px';
export const SIDEBAR_WIDTH_EXPANDED = '320px';
export const SIDEBAR_WIDTH_COLLAPSED = '56px';

export interface SidebarItemType {
  icon: JSX.Element;
  label: string;
  to: string;
  rightLabel?: number | string | JSX.Element;
  rightLabelColor?: string;
  isDisabled?: boolean;
  className?: string;
  highlight?: boolean;
  isNew?: boolean;
}

export const SidebarItem = ({
  item,
  onClickSidebarItem,
}: {
  item: SidebarItemType;
  onClickSidebarItem?: () => void;
}) => {
  const itemComponent = (
    <Flex gap={1} grow={1} justifyContent="space-between">
      <Flex alignItems="center" gap={1}>
        <Text fontWeight={500} opacity={item.isDisabled ? 0.4 : 1}>
          {item.label}
        </Text>
        {item.isNew && <NewText />}
      </Flex>
      {!!item.rightLabel && (
        <Flex
          alignItems="center"
          bgColor={item.rightLabelColor}
          borderRadius="50%"
          justifyContent="center"
          minW={6}
        >
          {item.rightLabel}
        </Flex>
      )}
    </Flex>
  );

  return (
    <Flex
      className={item.className}
      color={item.highlight ? 'black' : undefined}
      mb={0}
      width="100%"
    >
      {item.isDisabled ? (
        <Flex px={4} py={2} w="full">
          <Flex mr={2} opacity={0.4}>
            {item.icon}
          </Flex>
          {itemComponent}
        </Flex>
      ) : (
        <SecondaryNavButton
          _hover={
            item.highlight ? { bgColor: 'sky.500', color: 'black' } : undefined
          }
          bgColor={item.highlight ? 'sky.300' : undefined}
          className={item.className}
          color={item.highlight ? 'black' : undefined}
          isDisabled={item.isDisabled}
          leftIcon={item.icon}
          to={item.to}
          onClick={onClickSidebarItem}
        >
          {itemComponent}
        </SecondaryNavButton>
      )}
    </Flex>
  );
};
