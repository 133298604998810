import { Button, ButtonProps, useDisclosure } from '@chakra-ui/react';
import { Roles } from '@client/graphql/__generated__/types';
import { ShareWithSellerModal } from '~/apps/consumer/components/Listings/ShareWithSellerModal';
import { RoleFilter } from '~/apps/consumer/components/User/RoleFilter';
import { useListingSetupContext } from '../ListingSetupContext';

const defaultButtonContent = <>Share with seller</>;

type ShareWithSellerButtonProps = {
  children?: React.ReactNode;
} & ButtonProps;

export const ShareWithSellerButton = ({
  children = defaultButtonContent,
  ...buttonProps
}: ShareWithSellerButtonProps) => {
  const { listing } = useListingSetupContext();
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <RoleFilter roles={[Roles.AGENT]}>
      <Button fontWeight="semibold" size="sm" onClick={onOpen} {...buttonProps}>
        {children}
      </Button>
      <ShareWithSellerModal
        isOpen={isOpen}
        listingId={listing.id}
        onClose={onClose}
      />
    </RoleFilter>
  );
};
