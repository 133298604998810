import { Card, Flex, Text, VStack } from '@chakra-ui/react';
import {
  FindMlsAgentFragment,
  LicenseType,
  PublicUserWithLicensesFragment,
} from '@client/graphql/__generated__/types';
import { isMobile } from 'react-device-detect';
import { UserAvatar } from '~/apps/consumer/components/User/UserAvatar';

type Props = {
  user: PublicUserWithLicensesFragment | undefined;
  mlsAgentData: FindMlsAgentFragment | undefined;
  handleReset: () => void;
};

export const ExistingUserCard = ({
  user,
  mlsAgentData,
  handleReset,
}: Props) => {
  const existingUserInDatabase = !!user;
  const getCardData = () => {
    if (!user && !mlsAgentData) {
      return null;
    }

    return {
      firstName: user?.firstName ?? mlsAgentData?.mlsAgent?.MemberFirstName,
      fullName: user?.fullName ?? mlsAgentData?.mlsAgent?.MemberFullName,
      email: user?.email ?? mlsAgentData?.mlsAgent?.MemberEmail,
      licenseNumber:
        user?.licenses?.find(
          (license) => license.licenseType === LicenseType.MLS
        )?.licenseNumber ?? mlsAgentData?.mlsAgent?.MemberMlsId,
      profilePicture: user?.profilePicture,
    };
  };

  const cardData = getCardData();

  if (!cardData) {
    return null;
  }

  const { firstName, fullName, email, licenseNumber, profilePicture } =
    cardData;

  return (
    <>
      <VStack alignItems="flex-start" gap={0}>
        <Text fontWeight={500}>Welcome, {firstName}!</Text>
        {!existingUserInDatabase && (
          <Text
            as="a"
            color="whiteAlpha.600"
            cursor="pointer"
            textDecoration="underline"
            onClick={() => {
              handleReset();
            }}
          >
            Not you?
          </Text>
        )}
      </VStack>
      <Card p={4} w="100%">
        <Flex alignItems="center" gap={4}>
          <UserAvatar
            user={{
              fullName,
              profilePicture,
            }}
          />
          <Flex
            alignItems={isMobile ? 'flex-start' : 'center'}
            flex={1}
            flexDirection={isMobile ? 'column' : 'row'}
            gap={isMobile ? 2 : 4}
          >
            <VStack alignItems="flex-start" flex={1} fontSize="14px" gap={0}>
              <Text fontWeight={500}>{fullName}</Text>
              {email && <Text color="whiteAlpha.600">{email}</Text>}
              {mlsAgentData?.office?.OfficeName && (
                <Text color="whiteAlpha.600">
                  {mlsAgentData?.office?.OfficeName}
                </Text>
              )}
              {mlsAgentData?.mls?.name && (
                <Text color="whiteAlpha.600">{mlsAgentData?.mls?.name}</Text>
              )}
            </VStack>
            {licenseNumber && (
              <Text
                bg="whiteAlpha.200"
                borderRadius={4}
                color="whiteAlpha.500"
                fontSize="14px"
                height="fit-content"
                px={1}
                width="fit-content"
              >
                License #: {licenseNumber}
              </Text>
            )}
          </Flex>
        </Flex>
      </Card>
    </>
  );
};
