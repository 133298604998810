import { Flex, Heading, useToast } from '@chakra-ui/react';
import {
  usePreferencesQuery,
  useUpdateListingPreferenceMutation,
} from '@client/graphql/__generated__/main-operations';
import {
  PreferenceObject,
  PreferenceValueType,
  UsState,
} from '@client/graphql/__generated__/types';
import { useEffect, useMemo, useState } from 'react';
import { Form } from '~/apps/consumer/components/Formik/Form';
import { useSellerPreferenceGroups } from '~/apps/consumer/hooks/useSellerPreferenceGroups';
import { useListingSetupContext } from '~/apps/consumer/pages/ListingSetup/ListingSetupContext';
import { PublishBanner } from '~/common/components/Banner/PublishBanner';
import { CenterSpinner } from '~/common/components/CenterSpinner';
import {
  ListingSetupContainer,
  ListingSetupContentWrapper,
} from '../ListingSetupWrapperComponents';
import { OfferTransparencyPrefGroup } from './OfferTransparencyPrefGroup';
import {
  BEST_AND_FINAL_DATE,
  BEST_AND_FINAL_DATE_FIELD_PROPS,
  PREFERENCE_VALUE_FIELD_MAP,
  TRANSPARENCY_PREFERENCE_GROUPS,
} from './constants';
import {
  OFFER_COUNT_DISPLAY,
  OFFER_TRANSPARENCY_FIELD,
} from './offer-transparency.constants';
import { OFFER_TRANSPARENCY } from './offer-transparency.types';

const FORM_ID = 'offer-transparency-form';

export const OfferTransparencyPage = () => {
  const { listing } = useListingSetupContext();
  const toast = useToast();

  const { data: preferencesData, loading: loadingPreferences } =
    usePreferencesQuery({
      variables: {
        input: {
          usState: listing?.mlsListing?.address?.state as UsState,
        },
      },
    });

  const preferences = preferencesData?.preferences as PreferenceObject[];

  const offerCountDisplayValue = listing?.listingPreferences?.find(
    (pref) => pref.preference.slug === OFFER_COUNT_DISPLAY
  )?.stringValue;

  const [transparencyPreferenceGroup] = useSellerPreferenceGroups(
    listing,
    preferences,
    TRANSPARENCY_PREFERENCE_GROUPS
  );

  const { defaultValues } = transparencyPreferenceGroup;

  const bestAndFinalDateDefaultValue = useMemo(() => {
    return defaultValues[
      BEST_AND_FINAL_DATE_FIELD_PROPS.fieldSlug as keyof typeof defaultValues
    ];
  }, [defaultValues]);

  const offerTransparencyDefaultValue =
    offerCountDisplayValue || OFFER_TRANSPARENCY.MULTIPLE;

  const [offerTransparency, setOfferTransparency] = useState<string>(
    offerTransparencyDefaultValue
  );
  const [bestAndFinalDate, setBestAndFinalDate] = useState<Date>(
    bestAndFinalDateDefaultValue
  );

  const isDirty =
    offerTransparency !== offerTransparencyDefaultValue ||
    bestAndFinalDate !== bestAndFinalDateDefaultValue;

  useEffect(() => {
    setBestAndFinalDate(bestAndFinalDateDefaultValue);
  }, [bestAndFinalDateDefaultValue]);

  const [updateListingPreference, { loading }] =
    useUpdateListingPreferenceMutation({
      onCompleted() {
        toast({
          title: 'Updated your Offer Transparency preferences',
          status: 'success',
          duration: 2000,
        });
      },
      onError() {
        toast({
          title: 'Failed to update your Offer Transparency preferences',
          status: 'error',
          duration: 2000,
        });
      },
    });

  const handleSubmit = async () => {
    const offerCountDisplayPref = preferences.find(
      (pref) => pref.slug === OFFER_COUNT_DISPLAY
    );

    const dataTypeOfferCount =
      PREFERENCE_VALUE_FIELD_MAP[PreferenceValueType.STRING];
    const dataTypeDate = PREFERENCE_VALUE_FIELD_MAP[PreferenceValueType.DATE];
    const matchingPreference = preferences?.find(
      (preference) => preference.slug === BEST_AND_FINAL_DATE
    );

    await updateListingPreference({
      variables: {
        input: {
          listingId: listing?.id,
          preferenceValues: [
            {
              preference: { id: offerCountDisplayPref?.id as string },
              [dataTypeOfferCount]: offerTransparency,
            },
            {
              preference: { id: matchingPreference?.id as string },
              [dataTypeDate]: bestAndFinalDate,
            },
          ],
        },
      },
    });
  };

  if (loadingPreferences) {
    return <CenterSpinner />;
  }

  return (
    <Form
      formId={FORM_ID}
      initialValues={{
        [BEST_AND_FINAL_DATE_FIELD_PROPS.fieldSlug]:
          bestAndFinalDateDefaultValue,
        [OFFER_TRANSPARENCY_FIELD]: offerTransparencyDefaultValue,
      }}
      onSubmit={handleSubmit}
    >
      {() => (
        <ListingSetupContainer>
          <PublishBanner
            formId={FORM_ID}
            isDirty={isDirty}
            isLoading={loading || loadingPreferences}
          />
          <ListingSetupContentWrapper>
            <Flex
              direction="column"
              gap={4}
              width={{ base: '100%', md: '80%' }}
            >
              <Heading pb={2} size="xxs">
                Offer Transparency
              </Heading>

              <OfferTransparencyPrefGroup
                bestAndFinalDate={bestAndFinalDate}
                listing={listing}
                offerTransparencyDefaultValue={offerTransparencyDefaultValue}
                setBestAndFinalDate={setBestAndFinalDate}
                setOfferTransparency={setOfferTransparency}
              />
            </Flex>
          </ListingSetupContentWrapper>
        </ListingSetupContainer>
      )}
    </Form>
  );
};
