import { FinancingType } from '@client/graphql/__generated__/types';

export const FINANCING_OPTIONS = [
  { label: 'Conventional Loan', value: FinancingType.CONVENTIONAL },
  { label: 'FHA Loan', value: FinancingType.FHA },
  { label: 'VA Loan', value: FinancingType.VA },
  { label: 'USDA Loan', value: FinancingType.USDA },
  { label: 'Other', value: FinancingType.OTHER },
  { label: 'Cash', value: FinancingType.CASH },
];
