import { Button, Card, CardBody, HStack, Text, VStack } from '@chakra-ui/react';
import { UserObject } from '@client/graphql/__generated__/types';
import { FC, memo } from 'react';
import { UserAvatar } from '../User/UserAvatar';
import { VerifiedBadge } from '../User/VerifiedBadge';

interface AgentCardProps {
  isVerified: boolean;
  user?: UserObject;
  agentName?: string;
  officeName?: string;
  onVerifyUser?: () => void;
}

export const AgentCard: FC<AgentCardProps> = memo(function AgentCard({
  isVerified,
  user,
  agentName,
  officeName,
  onVerifyUser,
}) {
  return isVerified ? (
    <Card>
      <CardBody px={4} py={3}>
        <HStack>
          <UserAvatar size="sm" user={user || { fullName: agentName }} />
          <VStack
            alignItems="stretch"
            flexGrow={1}
            lineHeight="1.2"
            spacing={0}
          >
            <Text fontSize="sm">
              <strong>{agentName || user?.fullName}</strong>
            </Text>
            {officeName && <Text fontSize="sm">{officeName}</Text>}
          </VStack>

          {user && <VerifiedBadge size="sm" />}
        </HStack>
      </CardBody>
    </Card>
  ) : (
    <Button size="sm" variant="outline" onClick={onVerifyUser}>
      Verify your phone number
    </Button>
  );
});
