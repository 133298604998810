export const XIcon = ({ width = '18px', height = '18px' }) => (
  <svg
    fill="none"
    height={width}
    viewBox="0 0 18 18"
    width={height}
    xmlns="http://www.w3.org/2000/svg"
  >
    <line
      stroke="black"
      strokeWidth="4"
      x1="1.41421"
      x2="16.4142"
      y1="1.58579"
      y2="16.5858"
    />
    <line
      stroke="black"
      strokeWidth="4"
      x1="16.4142"
      x2="1.41421"
      y1="1.41421"
      y2="16.4142"
    />
  </svg>
);
