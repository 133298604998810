export function joinPaths(...paths: string[]): string {
  return paths
    .map((part, index) => {
      if (index === 0) {
        // Remove a trailing slash from the first part (if exists), unless it's the only part
        return part.endsWith('/') && paths.length > 1
          ? part.slice(0, -1)
          : part;
      } else {
        // Remove a leading slash from the subsequent parts (if exists)
        return part.startsWith('/') ? part.slice(1) : part;
      }
    })
    .join('/');
}
