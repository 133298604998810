import { Icon, IconProps } from '@chakra-ui/react';

export enum SignalStrength {
  HIGH = 'HIGH',
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
}

interface SignalIconProps extends IconProps {
  strength: SignalStrength;
}

export const SignalIcon = ({
  strength,
  color = 'purple.500',
  ...rest
}: SignalIconProps) => {
  const showMediumBar = strength !== SignalStrength.LOW;
  const showHighBar = strength === SignalStrength.HIGH;

  return (
    <Icon
      color={color}
      fill="none"
      height="12px"
      name="signal"
      viewBox="0 0 12 12"
      width="12px"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <svg
        fill="none"
        height="12"
        viewBox="0 0 12 12"
        width="12"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          fill="currentColor"
          height="5"
          rx="0.75"
          width="1.5"
          x="2"
          y="6"
        />
        <rect
          fill="currentColor"
          height="8"
          opacity={showMediumBar ? '1' : '0.2'}
          rx="0.75"
          width="1.5"
          x="5.5"
          y="3"
        />
        <rect
          fill="currentColor"
          height="10"
          opacity={showHighBar ? '1' : '0.2'}
          rx="0.75"
          width="1.5"
          x="9"
          y="1"
        />
      </svg>
    </Icon>
  );
};
