import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Divider,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Link,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react';
import {
  useDocumentSectionListForOfferFlowQuery,
  useDocumentVersionAutofillValuesQuery,
} from '@client/graphql/__generated__/document-operations';
import {
  DocumentSectionCategory,
  IndigoContractsForCurrentUserFragment,
  SignatureRequestEventType,
} from '@client/graphql/__generated__/types';
import { CheckIcon, XIcon } from 'lucide-react';
import { FC, memo } from 'react';
import { IconTag } from '~/common/components/IconTag/IconTag';
import { useDocumentSectionsByModuleType } from '~/services/document/components/DocumentSections/hooks/useDocumentSectionsByModuleType';
import { DocumentSection } from '~/services/document/components/DocumentSections/types';
import { FieldValueDisplay } from '~/services/document/components/Fields/FieldValueDisplay';
import { getFormattedDate } from '../../utils/listing.utils';

interface IndigoContractDetailCardProps {
  indigoContract: IndigoContractsForCurrentUserFragment;
}

export const IndigoContractDetailCard: FC<IndigoContractDetailCardProps> = memo(
  function IndigoContractDetailCard({ indigoContract }) {
    const signatureRequest = indigoContract.contract?.signatureRequests?.[0];
    const contract = indigoContract.contract;
    const contractDocuments = indigoContract?.contract?.contractDocuments;
    const primaryContractDocument = contractDocuments?.find(
      (doc) => doc.order === 0
    );
    const signedDocumentUrl = signatureRequest?.allSignedDocument?.url;
    const { data: autofillValuesData } = useDocumentVersionAutofillValuesQuery({
      variables: {
        documentVersionIds: [
          primaryContractDocument?.documentVersion?.id as string,
        ],
        fieldValues: contract?.fieldValues || {},
        variableValues: {},
      },
      skip: !primaryContractDocument?.documentVersion?.id,
    });

    const { data } = useDocumentSectionListForOfferFlowQuery({
      variables: {
        filters: {
          documentSectionCategory: DocumentSectionCategory.OFFER_CARD,
          documentVersionId: primaryContractDocument?.documentVersion?.id,
        },
      },
      skip: !primaryContractDocument?.documentVersion?.id,
      fetchPolicy: 'cache-first',
    });

    const sectionsByModuleType = useDocumentSectionsByModuleType({
      documentSections:
        (data?.publicDocumentSectionList?.results as DocumentSection[]) || [],
    });

    const fieldValues = {
      ...contract?.fieldValues,
      ...autofillValuesData?.documentVersionAutofillValues
        ?.readOnlyAutofillValues,
    };

    const allSigned = signatureRequest?.events?.find(
      (event) =>
        event.eventType ===
        SignatureRequestEventType.SIGNATURE_REQUEST_ALL_SIGNED
    );

    return (
      <Card alignSelf="stretch" width={{ base: '100%', md: '400px' }}>
        <CardHeader letterSpacing="normal" px={4} py={3} textTransform="none">
          <Heading fontSize="lg" mb={2} size="xs">
            {getFormattedDate(indigoContract.createdAt as Date, 'MMMM D')}{' '}
            Agreement
          </Heading>

          <IconTag
            bgColor={!allSigned ? 'whiteAlpha.100' : undefined}
            borderRadius={4}
            color="white"
            display="inline-flex"
            fontSize="10px"
            height={3}
            icon={allSigned ? CheckIcon : XIcon}
            iconColor={!allSigned ? 'gray.400' : undefined}
            label={allSigned ? 'Signed' : 'Not Signed'}
            letterSpacing={1}
            px={2}
            textTransform="uppercase"
            variant={allSigned ? 'gradient' : undefined}
          />
        </CardHeader>

        <Divider />

        <CardBody as={VStack}>
          {sectionsByModuleType.KEY_TERMS?.documentSectionFields
            ?.filter((section) =>
              fieldValues?.[section.field.mappingKey]
                ? !!String(fieldValues?.[section.field.mappingKey])?.trim()
                : false
            )
            .map((section) => (
              <FormControl key={section.id}>
                <FormLabel
                  color="gray.400"
                  fontWeight="normal"
                  mb={0}
                  variant="normal"
                >
                  {section.field.label}
                </FormLabel>

                {section.field.mappingKey.includes('location') ? (
                  (fieldValues?.[section.field.mappingKey] as string)
                    ?.split(', ')
                    .map((location) => <Text key={location}>{location}</Text>)
                ) : (
                  <FieldValueDisplay
                    field={section.field}
                    showCheck={false}
                    value={fieldValues?.[section.field.mappingKey]}
                  />
                )}
              </FormControl>
            ))}
        </CardBody>

        <Divider />

        <CardFooter as={HStack}>
          <Button
            isExternal
            as={Link}
            href={`/documents/contracts/${
              contract?.id as string
            }/pdf?partyId=${indigoContract?.party?.id}&onlyFieldValues=true`}
            size="xs"
            textDecoration="none !important"
          >
            Preview
          </Button>
          <Tooltip
            label={
              !signedDocumentUrl
                ? 'Download available after all parties have signed'
                : undefined
            }
          >
            <Button
              as={Link}
              download={!!signedDocumentUrl}
              href={signedDocumentUrl ? signedDocumentUrl : undefined}
              isDisabled={!signedDocumentUrl}
              size="xs"
              textDecoration="none !important"
              variant="outline"
            >
              Download
            </Button>
          </Tooltip>
        </CardFooter>
      </Card>
    );
  }
);
