import { ListingObject } from '@client/graphql/__generated__/types';
import { createContext, useContext } from 'react';

interface ActivityPageContextValues {
  followedListingsForCurrentUser: ListingObject[] | undefined;
  activeListing: ListingObject | undefined;
  setActiveListing: (listing: ListingObject) => void;
}

export const ActivityPageContext = createContext<ActivityPageContextValues>(
  {} as ActivityPageContextValues
);

export const useActivityPageContext = () => {
  return useContext(ActivityPageContext);
};
