import { isEqual } from 'lodash';
import { SetStateAction, useCallback } from 'react';
import { FormField } from '../../../FormFields/types';
import { PositionAndSize } from '../../../Moveable/Moveable';
import { PageMetadata } from '../../../PDFScrollable/types';
import { DocumentMapperContextValue } from '../../context';
import { MoveableTarget, MoveableTargetType } from '../../types';

interface UseMoveableEventsOptions {
  page: PageMetadata;
  moveableTargets?: MoveableTarget[];
  setMoveableTargets: (value: SetStateAction<MoveableTarget[]>) => void;
  formFieldsById: DocumentMapperContextValue['formFieldsById'];
  updateMoveableTargets: DocumentMapperContextValue['setMoveableTargets'];
  setMoveableTargetsPage: DocumentMapperContextValue['setMoveableTargetsPage'];
  setSelectedFormFieldId: DocumentMapperContextValue['setSelectedFormFieldId'];
  onFormFieldChange: DocumentMapperContextValue['onFormFieldChange'];
}

export const useMoveableEvents = (options: UseMoveableEventsOptions) => {
  const {
    formFieldsById,
    moveableTargets,
    page,
    setMoveableTargets,
    updateMoveableTargets,
    setMoveableTargetsPage,
    setSelectedFormFieldId,
    onFormFieldChange,
  } = options;

  const onMoveableChange = useCallback(
    (positionAndSizes: PositionAndSize[]) => {
      const updated: FormField[] = [];

      positionAndSizes.forEach((positionAndSize) => {
        const formField = formFieldsById[positionAndSize.id];

        if (formField) {
          const existing = updated.find(({ id }) => id === formField.id);

          if (existing) {
            Object.assign(existing, positionAndSize);
          } else {
            updated.push({
              ...formField,
              ...positionAndSize,
            });
          }
        }
      });

      if (
        !isEqual(
          updated,
          updated.map(({ id }) => formFieldsById[id])
        )
      ) {
        setSelectedFormFieldId(undefined);
        onFormFieldChange(updated);
      }
    },
    [formFieldsById, onFormFieldChange, setSelectedFormFieldId]
  );

  const onMoveableSelect = useCallback(
    (targets: (HTMLElement | SVGElement)[], isDragSelect: boolean) => {
      const selectedFormFieldIds = targets.map(
        (target) => target.dataset.formfieldid
      );
      const deselectedMoveableTargets =
        moveableTargets?.filter((formField) => {
          return !selectedFormFieldIds.includes(formField.formField?.id);
        }) || [];

      if (deselectedMoveableTargets.length > 0) {
        deselectedMoveableTargets?.forEach(({ formField }) => {
          const targets = document.querySelectorAll<HTMLDivElement>(
            `div[data-formfieldid="${formField?.id}"]`
          );

          if (targets.length > 0) {
            targets.forEach((target) => {
              target.style.transform = '';
            });
          }
        });
      }

      if (targets.length === 0) {
        setMoveableTargets([]);
        setSelectedFormFieldId(undefined);

        return;
      }

      const selectedMoveableTargets: MoveableTarget[] = targets
        .map((target: HTMLDivElement) => {
          const formFieldId = target.dataset.formfieldid;
          const formField = formFieldsById[formFieldId || ''];

          return {
            page,
            formField,
            type: target.dataset.type as MoveableTargetType,
          };
        })
        .filter((target) => target.formField);

      setMoveableTargetsPage((prev) => {
        if (
          prev?.documentIndex !== page.documentIndex ||
          prev.pageNumber !== page.pageNumber
        ) {
          return {
            pageNumber: page.pageNumber,
            documentIndex: page.documentIndex,
          };
        }

        return prev;
      });
      setMoveableTargets(selectedMoveableTargets);

      if (selectedMoveableTargets.length === 1 && !isDragSelect) {
        setSelectedFormFieldId(selectedMoveableTargets[0].formField?.id);
      } else {
        setSelectedFormFieldId(undefined);
      }
    },
    [
      formFieldsById,
      moveableTargets,
      page,
      setMoveableTargets,
      setMoveableTargetsPage,
      setSelectedFormFieldId,
    ]
  );

  const onMoveableSelectEnd = useCallback(
    (targets: (HTMLElement | SVGElement)[]) => {
      updateMoveableTargets(
        targets
          .map((target) => {
            const formFieldId = target.dataset.formfieldid;
            const formField = formFieldsById[formFieldId || ''];

            return {
              page,
              formField,
              type: target.dataset.type as MoveableTargetType,
            };
          })
          .filter((target) => target.formField)
      );
    },
    [formFieldsById, page, updateMoveableTargets]
  );

  return {
    onMoveableChange,
    onMoveableSelect,
    onMoveableSelectEnd,
  };
};
