import { Button, Flex, Heading } from '@chakra-ui/react';
import { FormikProps } from 'formik';
import { PropsWithChildren } from 'react';

interface IFormGroupCardProps extends PropsWithChildren {
  title: string;
  isSaving?: boolean;
  submitButtonText?: string;
  formProps?: FormikProps<{
    [key: string]: unknown;
  }>;
  hideButton?: boolean;
}

export const FormGroupCard = ({
  title,
  children,
  isSaving,
  submitButtonText,
  formProps,
  hideButton = false,
}: IFormGroupCardProps) => {
  return (
    <Flex borderRadius="10px" direction="column">
      <Flex
        backgroundColor="card.bg"
        border="1px solid"
        borderColor="border.box"
        borderRadius="md"
        direction="column"
        gap={4}
        justifyContent="center"
      >
        <Flex
          alignItems="center"
          borderRadius="md"
          justifyContent="space-between"
          p={6}
        >
          <Heading size="smallUppercase">{title}</Heading>
          {!hideButton && (
            <Button
              height={8}
              isDisabled={!formProps?.dirty || isSaving}
              isLoading={isSaving}
              size="sm"
              type="submit"
            >
              {submitButtonText}
            </Button>
          )}
        </Flex>
        {children}
      </Flex>
    </Flex>
  );
};
