import { Flex } from '@chakra-ui/react';
import { ToggleButton } from './ToggleButton';

export interface ToggleButtonsProps {
  activeButton: string;
  setActiveButton: (value: string) => void;
  buttons: {
    label: string;
    value: string;
  }[];
  activeButtonColor?: string;
  activeButtonTextColor?: string;
  size?: 'sm' | 'md' | 'lg';
}

const containerHeightToSize = {
  sm: 10,
  md: 12,
  lg: 14,
};

export const ToggleButtons = ({
  activeButton,
  setActiveButton,
  buttons,
  size = 'md',
  activeButtonColor = 'white',
  activeButtonTextColor = 'black',
}: ToggleButtonsProps) => {
  return (
    <Flex
      alignItems="center"
      border="1px solid"
      borderColor="whiteAlpha.200"
      borderRadius="6px"
      flexBasis="100%"
      height={containerHeightToSize[size]}
      mb={4}
      p={2}
    >
      <ToggleButton
        activeColor={activeButtonColor}
        activeTextColor={activeButtonTextColor}
        isActive={activeButton === buttons[0].value}
        size={size}
        onClick={() => setActiveButton(buttons[0].value)}
      >
        {buttons[0].label}
      </ToggleButton>
      <ToggleButton
        activeColor={activeButtonColor}
        activeTextColor={activeButtonTextColor}
        isActive={activeButton === buttons[1].value}
        size={size}
        onClick={() => setActiveButton(buttons[1].value)}
      >
        {buttons[1].label}
      </ToggleButton>
    </Flex>
  );
};
