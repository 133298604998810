import {
  Button,
  Card,
  CardBody,
  Flex,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { TourType } from '@client/graphql/__generated__/types';
import { BarChartBig, Bell, WandSparkles } from 'lucide-react';
import { useCallback, useEffect } from 'react';
import { useCustomTourContext } from '~/apps/consumer/contexts/CustomTourContext/CustomTourContext';
import { useSignGuestbookState } from '~/apps/consumer/pages/OpenHouseGuestbook/hooks/useSignGuestbookState';
import { useStorefrontContext } from '~/apps/consumer/pages/Storefront/StorefrontContext';
import { TourModalTitle } from '../../Tour/TourModalTitle';
import { TourSubtitle } from '../../Tour/styled';
import { tourSteps } from './constants';

export const StorefrontTourModal = () => {
  const { showStartTourModal, setShowStartTourModal } = useStorefrontContext();

  const { setIsOpen, setSteps, updateHasViewedTour, setCurrentStep } =
    useCustomTourContext();

  // we don't show the tour modal if the user is redirected from link to sign the guestbook
  const { isSignOpenHouseGuestbook } = useSignGuestbookState();
  const onClose = useCallback(() => {
    void updateHasViewedTour([TourType.BA_STOREFRONT]);
    setShowStartTourModal(false);
  }, [updateHasViewedTour, setShowStartTourModal]);

  useEffect(() => {
    if (isSignOpenHouseGuestbook) {
      onClose();
    }
  }, [isSignOpenHouseGuestbook, onClose]);

  return (
    <Modal
      isOpen={showStartTourModal && !isSignOpenHouseGuestbook}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody pt={6}>
          <Flex alignItems="center" direction="column" gap={4}>
            <TourModalTitle />
            <TourSubtitle>
              The secure home checkout platform where agents, buyers and sellers
              collaborate on all home offers
            </TourSubtitle>

            <Card>
              <CardBody>
                <Flex direction="column" gap={4}>
                  <Flex alignItems="center" gap={3}>
                    <Icon as={Bell} color="sky.300" />
                    <Text>
                      Stay notified on offer activity and announcements
                    </Text>
                  </Flex>
                  <Flex alignItems="center" gap={3}>
                    <Icon as={BarChartBig} color="sky.300" />
                    <Text>Craft offers with built-in intelligence</Text>
                  </Flex>
                  <Flex alignItems="center" gap={3}>
                    <Icon as={WandSparkles} color="sky.300" />
                    <Text>
                      Automatically generate offer forms validated against
                      listing data and seller preferences in real-time
                    </Text>
                  </Flex>
                </Flex>
              </CardBody>
            </Card>
            <Button
              background="blueGradient"
              borderRadius="32px"
              color="black"
              onClick={() => {
                setSteps && setSteps(tourSteps);
                setCurrentStep(0);
                setIsOpen(true);
                onClose();
              }}
            >
              Get Started
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
