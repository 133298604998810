import { Box, Button, Flex, Text } from '@chakra-ui/react';
import {
  PublicListingDocument,
  usePublicListingQuery,
} from '@client/graphql/__generated__/main-operations';
import {
  ArrowRight,
  BookOpenText,
  CheckCircle,
  CircleDollarSign,
  DoorOpen,
  FileText,
  Gauge,
  Handshake,
  Home,
  Users,
} from 'lucide-react';
import { NavLink } from 'react-router-dom';
import { useListingActivityContext } from '~/apps/consumer/contexts/ListingActivityContext';
import { useRegisteredRoles } from '~/apps/consumer/hooks/useRegisteredRoles';
import { AddAnnouncementButton } from '~/apps/consumer/pages/ListingSetup/Announcements/AddAnnouncementButton';
import { useListingSetupContext } from '~/apps/consumer/pages/ListingSetup/ListingSetupContext';
import { getStorefrontSetupWizardRoute } from '~/apps/consumer/utils/routes.utils';
import { useCheckPermission } from '~/common/hooks/useCheckPermission';
import { useProdFeatureFlagEnabled } from '~/common/hooks/useProdFeatureFlagEnabled';
import { PreferenceIcon } from '~/common/icons/PreferenceIcon';
import { ListingActivityProvider } from '../../../Activity/ListingActivityProvider';
import {
  PageDetailLayoutSidebar,
  PageDetailSidebarType,
} from '../../../PageDetailLayout/PageDetailLayoutSidebar';
import { StorefrontSetupTourClassNameStep1 } from '../ListingSetupTourModal/constants';
import { ListingSetupSidebarHeader } from './ListingSetupSidebarHeader';

export const LISTING_SETUP_SIDEBAR_REFETCH_QUERIES = [PublicListingDocument];

interface ListingAgentSetupSidebarProps {
  onClickSidebarItem?: () => void;
}

const ListingSetupSidebarComponent = ({
  onClickSidebarItem,
}: ListingAgentSetupSidebarProps) => {
  const { isRegisteredConsumer } = useRegisteredRoles();

  const { listing, isTrackableListingPackageEnabled } =
    useListingSetupContext();
  const { guestbookSignersCount } = useListingActivityContext();
  const listingsAdminEnabled = useProdFeatureFlagEnabled('listings-admin');
  const openHousesEnabled = useProdFeatureFlagEnabled('open-houses');
  const { isAuthorized: isOwner } = useCheckPermission('listings.isOwner', {}, [
    listing.id,
  ]);
  const { isAuthorized: isAdmin } = useCheckPermission('listings.isAdmin', {}, [
    listing.id,
  ]);

  const iconSize = {
    width: '16px',
    height: '16px',
  };

  const isPremium = listing?.isPremium;

  // NOTE: We start the storefront setup wizard at the first step as long as the user clicks the "Step-by-Step Setup" button
  const storefrontSetupRoute = getStorefrontSetupWizardRoute(listing.id);

  const walkthruButton = (
    <Button
      as={NavLink}
      bgGradient="linear(to-tr, cyan.gradient.start, cyan.gradient.end)"
      borderRadius="xxs"
      display="flex"
      gap={1}
      height="24px"
      px={2}
      size="sm"
      textTransform="none"
      to={storefrontSetupRoute}
    >
      Walkthru
      <ArrowRight height="12px" width="12px" />
    </Button>
  );

  const registeredAgentSidebar: PageDetailSidebarType = [
    ...(isTrackableListingPackageEnabled && isPremium
      ? [
          {
            icon: <Gauge {...iconSize} />,
            label: 'Dashboard',
            to: 'dashboard',
          },
        ]
      : []),
    {
      icon: <CircleDollarSign {...iconSize} />,
      label: 'Offers',
      to: 'offers',
      rightLabel: listing?.submittedOffersCount,
      rightLabelColor: 'purple.500',
    },
    ...(isPremium
      ? [
          {
            icon: <BookOpenText {...iconSize} />,
            label: 'Guestbook CRM',
            to: 'guestbook',
            rightLabel:
              guestbookSignersCount > 0
                ? guestbookSignersCount.toString()
                : undefined,
            rightLabelColor: 'purple.500',
          },
          ...(openHousesEnabled
            ? [
                {
                  icon: <DoorOpen {...iconSize} />,
                  label: 'Open Houses',
                  to: 'open-houses',
                  isNew: true,
                },
              ]
            : []),
        ]
      : []),
    ...(isOwner && listingsAdminEnabled
      ? [
          {
            icon: <Users {...iconSize} />,
            label: 'Admins',
            to: 'admins',
          },
        ]
      : []),
    ...(isPremium
      ? [
          {
            key: 'customize-storefront',
            sectionTitle: (
              <Flex alignItems="center">
                <Text flexGrow={1}>Customize Storefront</Text>
                {!!listing?.setupDetails?.configuredCount ? (
                  <SetupProgress listingId={listing.id} />
                ) : (
                  walkthruButton
                )}
              </Flex>
            ),
            sectionClassName: StorefrontSetupTourClassNameStep1,
            items: [
              {
                icon: <PreferenceIcon {...iconSize} />,
                label: 'Seller Preferences',
                to: 'preferences',
              },
              {
                icon: <Home {...iconSize} />,
                label: 'Comparables',
                to: 'comparables',
              },
              {
                icon: <FileText {...iconSize} />,
                label: 'Documents',
                to: 'documents',
              },
              {
                icon: <Handshake {...iconSize} />,
                label: 'Offer Transparency',
                to: 'offer-transparency',
              },
            ],
          },
        ]
      : []),
  ];

  const consumerSidebar: PageDetailSidebarType = [
    {
      icon: <CircleDollarSign {...iconSize} />,
      label: 'Offers',
      to: 'offers',
      rightLabel: listing?.submittedOffersCount,
      rightLabelColor: 'purple.500',
    },
  ];

  const sidebarItems =
    isRegisteredConsumer && !isAdmin ? consumerSidebar : registeredAgentSidebar;

  return (
    <PageDetailLayoutSidebar
      SidebarFooter={() => (
        <Box pt={2} px={3} width="full">
          <AddAnnouncementButton borderRadius="xs" width="full" />
        </Box>
      )}
      SidebarHeader={ListingSetupSidebarHeader}
      sidebarItems={sidebarItems}
      onClickSidebarItem={onClickSidebarItem}
    />
  );
};

// NOTE: separate component out to avoid re-rendering whole sidebar
const SetupProgress = ({ listingId }: { listingId: string }) => {
  // PublicListing query would be faster to refetch and re-use cache
  const { data } = usePublicListingQuery({
    variables: {
      input: {
        listingId,
      },
    },
  });
  const listing = data?.publicListing;

  const setupDetails = listing?.setupDetails;

  if (!setupDetails) {
    return null;
  }

  if (setupDetails.configuredCount === setupDetails.progressTotal) {
    return (
      <Flex alignItems="center" color="whiteAlpha.600" gap={1}>
        <CheckCircle height="14px" width="14px" />
        <Text fontSize="xs" fontWeight="medium">
          Completed
        </Text>
      </Flex>
    );
  }

  return (
    <Flex alignItems="baseline" gap={1}>
      <Text color="white" fontSize="md" fontWeight="medium">
        {setupDetails.configuredCount}/{setupDetails.progressTotal}
      </Text>
      <Text color="whiteAlpha.600">completed</Text>
    </Flex>
  );
};

export const ListingSetupSidebar = (props: ListingAgentSetupSidebarProps) => (
  <ListingActivityProvider>
    <ListingSetupSidebarComponent {...props} />
  </ListingActivityProvider>
);
