import { Box, Button, Flex, Icon, VStack } from '@chakra-ui/react';
import { Grid2X2Icon, MapIcon } from 'lucide-react';
import { useRef, useState } from 'react';
import { useIsMobile } from '~/common/hooks/useIsMobile';
import { MOBILE_HEADER_HEIGHT } from '../Layouts/MobileHeader';
import { SimpleHeader } from '../Layouts/SimpleHeader';
import { MapboxGeocoder } from '../MapboxGeocoder/MapboxGeocoder';
import { ExploreProvider, useExploreContext } from './ExploreContext';
import { ExploreMap } from './ExploreMap/ExploreMap';
import { DemandFilters } from './Filters/DemandFilters';
import { MobileFilterDrawer } from './Filters/MobileFilterDrawer';
import { PremiumFilter } from './Filters/PremiumFilter';
import { PropertyFilters } from './Filters/PropertyFilters';
import { SellerPreferenceFilters } from './Filters/SellerPreferenceFilters';
import { ListingGrid } from './ListingGrid/ListingGrid';

export const MapSearchContent = ({
  showUnauthenticatedHeader = false,
}: {
  showUnauthenticatedHeader?: boolean;
}) => {
  const {
    listings,
    totalCount,
    mapRef,
    filters,
    updateFilters,
    handleRetrieve,
    utmParams,
  } = useExploreContext();

  return (
    <>
      <VStack flexGrow={1} height="100%" spacing={0} width="100%">
        <Flex
          alignItems="center"
          gap={3}
          justifyContent="flex-start"
          width="100%"
        >
          <Flex grow={1} maxWidth="350px" minWidth="200px">
            <MapboxGeocoder
              borderRadius="none"
              height="56px"
              mapRef={mapRef}
              onRetrieve={handleRetrieve}
            />
          </Flex>
          <PropertyFilters />
          <SellerPreferenceFilters />
          <DemandFilters />
        </Flex>
        <ExploreMap mapRef={mapRef} />
      </VStack>
      <VStack
        maxHeight="100%"
        maxWidth={{ base: '300px', xl: '580px' }}
        minHeight="100%"
        minWidth={{ base: 'unset', xl: '580px' }}
        overflowY="auto"
        px={0}
        spacing={0}
      >
        {showUnauthenticatedHeader && <SimpleHeader />}
        <Flex alignItems="center" minHeight="56px" px={3} py={0} width="100%">
          <PremiumFilter
            value={filters?.premiumOnly || false}
            onChange={(e) => {
              updateFilters({ premiumOnly: e.target.checked });
            }}
          />
        </Flex>
        <ListingGrid
          listings={listings}
          totalCount={totalCount}
          utmParams={utmParams}
        />
      </VStack>
    </>
  );
};

enum MobileMapView {
  MAP = 'map',
  LIST = 'list',
}
export const MobileMapSearchContent = () => {
  const [mobileView, setMobileView] = useState<MobileMapView>(
    MobileMapView.MAP
  );
  const [showMobileFilters, setShowMobileFilters] = useState(false);
  const drawerBtnRef = useRef<HTMLButtonElement>(null);

  const {
    listings,
    totalCount,
    mapRef,
    filters,
    updateFilters,
    handleRetrieve,
    clearFilters,
  } = useExploreContext();

  return (
    <>
      <VStack flexGrow={1} height="100%" spacing={0} width="100%">
        <VStack
          backdropFilter="blur(2px)"
          background="linear-gradient(180deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.00) 100%)"
          position="absolute"
          pt={{ base: 0, md: 4 }}
          px={{ base: 3, md: 4 }}
          top={MOBILE_HEADER_HEIGHT}
          width="100%"
          zIndex="5"
        >
          <Box width="100%">
            <MapboxGeocoder mapRef={mapRef} onRetrieve={handleRetrieve} />
          </Box>
          <Flex
            alignItems="center"
            gap={2}
            justifyContent="space-between"
            width="100%"
          >
            <Button
              background="bg.mainDark"
              color="white"
              size="sm"
              onClick={() => setShowMobileFilters(true)}
            >
              Filters
            </Button>
            <PremiumFilter
              backgroundColor="bg.mainDark"
              value={filters?.premiumOnly || false}
              onChange={(e) => {
                updateFilters({ premiumOnly: e.target.checked });
              }}
            />
            <Button
              background="bg.mainDark"
              color="white"
              leftIcon={
                mobileView === MobileMapView.LIST ? (
                  <Icon as={MapIcon} />
                ) : (
                  <Icon as={Grid2X2Icon} />
                )
              }
              size="sm"
              onClick={() =>
                setMobileView((prev) =>
                  prev === MobileMapView.MAP
                    ? MobileMapView.LIST
                    : MobileMapView.MAP
                )
              }
            >
              {mobileView === MobileMapView.LIST ? 'Map' : 'List'}
            </Button>
          </Flex>
        </VStack>
        <ExploreMap
          mapRef={mapRef}
          visibility={mobileView === MobileMapView.MAP ? 'visible' : 'hidden'}
        />
        {mobileView === MobileMapView.LIST && (
          <ListingGrid listings={listings} totalCount={totalCount} />
        )}
      </VStack>
      <MobileFilterDrawer
        btnRef={drawerBtnRef}
        clearAll={() => {
          clearFilters();
          setShowMobileFilters(false);
        }}
        isOpen={showMobileFilters}
        onClose={() => setShowMobileFilters(false)}
      />
    </>
  );
};

export const MapSearch = ({
  showUnauthenticatedHeader = false,
}: {
  showUnauthenticatedHeader?: boolean;
}) => {
  const isMobile = useIsMobile(true);

  const page = (
    <ExploreProvider>
      {isMobile ? (
        <MobileMapSearchContent />
      ) : (
        <MapSearchContent
          showUnauthenticatedHeader={showUnauthenticatedHeader}
        />
      )}
    </ExploreProvider>
  );

  return page;
};
