import { HStack, Tag, Text } from '@chakra-ui/react';
import { useOffersForCurentUserQuery } from '@client/graphql/__generated__/main-operations';
import { SignatureIcon } from 'lucide-react';
import { FC } from 'react';
import { CenterSpinner } from '~/common/components/CenterSpinner';
import { useInfiniteQuery } from '~/services/document/hooks/useInfiniteQuery';
import { enumToLabel } from '~/services/document/utils/enums';
import { CardEmptyState } from '../../components/CardEmptyState';
import { DashboardCard } from '../../components/Dashboard/DashboardCard';
import { DashboardItem } from '../../components/Dashboard/DashboardItem';

interface OffersWidgetProps {
  onNewOffer: () => void;
}

export const OffersWidget: FC<OffersWidgetProps> = ({ onNewOffer }) => {
  const [offersRef, { data, loading }] = useInfiniteQuery(
    useOffersForCurentUserQuery,
    {
      variables: {
        perPage: 6,
        orderBy: '-updatedAt',
        filters: { forBuyer: true },
      },
    }
  );
  const offersResult = data?.offersForCurrentUser?.results;

  return (
    <DashboardCard
      emptyStateElement={
        <CardEmptyState
          description="Auto-filled with property info and seller preferences, writing an offer with Indigo is a breeze"
          icon={SignatureIcon}
          title="Write an offer on any home"
        />
      }
      isLoading={loading && !offersResult}
      noData={offersResult?.length === 0}
      title="Buyer Offers"
      onHeaderButtonClick={onNewOffer}
    >
      {offersResult?.map((offer) => (
        <DashboardItem
          key={offer.id}
          listing={offer.listing}
          routeTo={`/offers/${offer.id}?listingId=${offer.listing?.id}&partyId=${offer.party?.id}&offerStatus=${offer.status}`}
          user={offer.buyersAgent?.user}
        >
          <HStack flexWrap="wrap" spacing={1}>
            <Text>{offer.primaryBuyer?.fullName}</Text>
            <Tag size="sm">{enumToLabel(offer.status)}</Tag>
          </HStack>
          <Text color="whiteAlpha.500">
            {offer.listing?.mlsListing?.address.full}
          </Text>
        </DashboardItem>
      ))}

      {loading && <CenterSpinner />}

      <div ref={offersRef} style={{ position: 'relative', bottom: 100 }} />
    </DashboardCard>
  );
};
