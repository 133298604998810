import { Button, Tag } from '@chakra-ui/react';
import {
  ListingFragment,
  PublicListingFragment,
} from '@client/graphql/__generated__/types';

import { MlsListingFragment } from '@client/graphql/__generated__/types';
import dayjs from 'dayjs';
import { BaseListingCard } from '~/apps/consumer/components/Listings/ListingCard/BaseListingCard';
import { getFormattedStatusText } from '~/apps/consumer/utils/listing.utils';

export const EditCompsListingCard = ({
  listing,
  mlsListing,
  isAdded,
  loading,
  onClick,
}: {
  listing?: ListingFragment;
  mlsListing?: MlsListingFragment;
  isAdded?: boolean;
  loading?: boolean;
  onClick?: () => void;
}) => {
  const button = (
    <Button
      _hover={{
        textDecoration: 'none',
        bgColor: 'primaryButton.300', // Somehow setting textDecoration to none removes the hover bg color, so manually setting it
      }}
      borderRadius="sm"
      fontSize="sm"
      isLoading={loading}
      mt={2}
      size="xs"
      variant="solid"
      onClick={onClick}
    >
      {isAdded ? 'Remove Comp' : 'Add Comp'}
    </Button>
  );

  const topTags = mlsListing?.mls?.statusText ? (
    <Tag lineHeight={1} variant="dark">
      {`${getFormattedStatusText(mlsListing?.mls?.statusText)}${
        mlsListing?.sales?.closeDate
          ? ` - ${dayjs(mlsListing?.sales?.closeDate).format('MM/DD/YYYY')}`
          : ''
      }`}
    </Tag>
  ) : undefined;

  return (
    <BaseListingCard
      button={button}
      listing={{ mlsListing, ...(listing || {}) } as PublicListingFragment}
      topTags={topTags}
    />
  );
};
