import { Box, Flex, Text } from '@chakra-ui/react';
import { usePartyAndBuyerInfoQuery } from '@client/graphql/__generated__/main-operations';
import { useParams } from 'react-router';
import { UserAvatar } from '../../components/User/UserAvatar';
import { getPartyEmail, getPartyPhoneNumber } from '../../utils/party.utils';

export const BuyerPartyPageSidebarHeader = () => {
  const { buyerPartyId } = useParams<string>();

  const { data, loading } = usePartyAndBuyerInfoQuery({
    variables: {
      input: { id: buyerPartyId as string },
    },
    skip: !buyerPartyId,
  });

  if (loading || !data?.partyAndBuyerInfo) {
    return null;
  }

  const party = data?.partyAndBuyerInfo;

  const phoneNumber = getPartyPhoneNumber(party);
  const email = getPartyEmail(party);

  const consumers = [
    party.primaryConsumer,
    ...(party.secondaryConsumers || []),
  ];

  return (
    <Flex
      alignItems="center"
      borderBottom="1px solid"
      borderBottomColor="border.divider"
      p={5}
      width="100%"
    >
      <Flex mr={3}>
        {consumers?.map((consumer, i) => (
          <UserAvatar
            key={consumer.id}
            size="sm"
            transform={`translateX(${i > 0 ? '-6px' : ''})`}
            user={consumer}
          />
        ))}
      </Flex>
      <Flex alignItems="flex-start" direction="column" ml={2}>
        <Text fontWeight="medium" p={0} size="headline" zIndex={1}>
          <Box>
            <Text fontWeight="bold">
              {consumers?.map((consumer, index) => (
                <span key={consumer.id}>
                  {index > 0 ? ', ' : ''}
                  {consumer.fullName}
                </span>
              ))}
            </Text>
          </Box>
          <Text color="gray.400">
            <Box>{phoneNumber}</Box>
            <Box>{email}</Box>
          </Text>
        </Text>
      </Flex>
    </Flex>
  );
};
