import { Tag, TagCloseButton, TagLabel, TagProps } from '@chakra-ui/react';
import { MinMaxFilterInput } from '@client/graphql/__generated__/types';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import { match } from 'ts-pattern';
import { useExploreContext } from '../ExploreContext';
import {
  filterKeyToFormatterMap,
  filterKeyToLabelMap,
  MappedFilterKeys,
} from './constants';

interface FilterTagProps extends TagProps {
  filterKey: MappedFilterKeys;
}

export const FilterTag = ({ filterKey, ...rest }: FilterTagProps) => {
  const { filters, updateFilters } = useExploreContext();

  const filterValue = filters[filterKey];

  const tagLabel = filterKeyToLabelMap[filterKey];
  if (
    isNil(filterValue) ||
    (typeof filterValue === 'object' && isEmpty(filterValue))
  ) {
    return null;
  }

  const valueFormatter = filterKeyToFormatterMap[filterKey] as (
    value: number | string | boolean
  ) => string | undefined;

  const filterValueLabel = match(typeof filterValue)
    .with('string', () =>
      valueFormatter
        ? valueFormatter(filterValue as string)
        : (filterValue as string)
    )
    .with('object', () => {
      if (Array.isArray(filterValue)) {
        if (valueFormatter) {
          return filterValue.length
            ? filterValue
                .map((value: string) => valueFormatter(value))
                .join(', ')
            : undefined;
        }

        return filterValue.length ? filterValue.join(', ') : undefined;
      } else {
        const filterMinValue = (filterValue as MinMaxFilterInput)?.min;
        const filterMaxValue = (filterValue as MinMaxFilterInput)?.max;

        const formattedMinValue = valueFormatter
          ? valueFormatter(filterMinValue as number)
          : filterMinValue;
        const formattedMaxValue = valueFormatter
          ? valueFormatter(filterMaxValue as number)
          : filterMaxValue;

        if (isNil(formattedMinValue) && isNil(formattedMaxValue)) {
          return null;
        } else if (isNil(formattedMaxValue)) {
          return `${formattedMinValue}+`;
        } else if (isNil(formattedMinValue)) {
          return `${formattedMaxValue} max`;
        } else if (formattedMinValue === formattedMaxValue) {
          return `${formattedMinValue}`;
        }

        return `${formattedMinValue} - ${formattedMaxValue}`;
      }
    })
    .with('boolean', () =>
      valueFormatter
        ? valueFormatter(filterValue as boolean)
        : (filterValue as boolean)
    )
    .otherwise(() => null);

  if (isNil(filterValueLabel)) {
    return null;
  }

  return (
    <Tag {...rest} maxWidth="200px">
      <TagLabel>
        {tagLabel}: {filterValueLabel}
      </TagLabel>
      <TagCloseButton
        onClick={() => updateFilters({ [filterKey]: undefined })}
      />
    </Tag>
  );
};
