import { useSignUpConsumerMutation } from '@client/graphql/__generated__/main-operations';
import { UserFragment } from '@client/graphql/__generated__/types';
import { FC } from 'react';
import { SignUpForm } from './SignUpForm';

interface ConsumerSignUpProps {
  signupFormInstructions?: string;
  user?: UserFragment;
  onSignup: (user: UserFragment) => Promise<void> | void;
}

export const ConsumerSignUp: FC<ConsumerSignUpProps> = ({
  onSignup,
  user,
  signupFormInstructions,
}) => {
  const [signUpConsumer, { loading }] = useSignUpConsumerMutation();

  return (
    <SignUpForm
      loading={loading}
      signupFormInstructions={signupFormInstructions}
      user={user}
      withMls={false}
      onSubmit={async (input) => {
        const { data } = await signUpConsumer({
          variables: {
            input: {
              email: input.email,
              firstName: input.firstName,
              lastName: input.lastName,
              profilePicture: input.profilePicture,
            },
          },
        });

        if (data?.signUpConsumer?.user) {
          await onSignup(data.signUpConsumer.user);
        }
      }}
    />
  );
};
