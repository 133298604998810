import { useCallbackRef } from '@chakra-ui/react';
import { useState } from 'react';

export const DEFAULT_BLUR_STYLES = {
  content: '""',
  background: 'linear-gradient(0deg, #111527 0%, rgba(17, 21, 39, 0.00) 100%)',
  width: '100%',
  bottom: 0,
  left: 0,
  height: '50px',
  position: 'absolute',
};

export const useShowBlurOnScrollable = () => {
  const [showBlur, setShowBlur] = useState(false);

  const getShouldShowBlur = (element: Element) => {
    const isScrolledToBottom =
      element.scrollHeight - element.clientHeight <= element.scrollTop;

    const isOverflowing =
      (element?.scrollHeight || 0) > (element?.clientHeight || 0);

    return !isScrolledToBottom && isOverflowing;
  };

  const handleBlurOnScroll = (event: React.UIEvent<HTMLDivElement>) => {
    setShowBlur(getShouldShowBlur(event.currentTarget));
  };

  const setInitialBlurRef = useCallbackRef(
    (ref: Element | null) => {
      if (ref) {
        setShowBlur(getShouldShowBlur(ref));
      }
    },
    [getShouldShowBlur, setShowBlur]
  );

  return { showBlur, handleBlurOnScroll, setInitialBlurRef };
};
