import { createContext, useMemo } from 'react';
import {
  DocumentServiceContextValue,
  DocumentServiceProviderProps,
} from './context.type';
import { createApolloClient } from './utils/apollo-client';

export const DocumentServiceContext =
  createContext<DocumentServiceContextValue>({});

export const DocumentServiceProvider: React.FC<
  DocumentServiceProviderProps
> = ({ children, apolloClientAuth, apolloClientOptions }) => {
  const apolloClient = useMemo(() => {
    return createApolloClient({
      clientOptions: apolloClientOptions,
      authorization: apolloClientAuth,
    });
  }, [apolloClientAuth, apolloClientOptions]);

  return (
    <DocumentServiceContext.Provider
      value={{
        apolloClient,
      }}
    >
      {apolloClient ? children : null}
    </DocumentServiceContext.Provider>
  );
};
