import { Button, Flex, Icon, Spacer, Text } from '@chakra-ui/react';
import { File as FileIcon } from 'lucide-react';

type File = {
  name: string;
  url: string;
};

type Props = {
  files: File[];
  onRemove?: (index: number) => void;
};

export const FilesBox = ({ files, onRemove }: Props) => {
  return (
    <>
      {files.map((file, index) => {
        const fileUrl = file.url;

        return (
          <Flex
            key={`${file.name}-${index}`}
            alignItems="center"
            borderBottom={index === files.length - 1 ? '0' : '1px'}
            borderColor="whiteAlpha.300"
            justifyContent="space-between"
            mb={index === files.length - 1 ? 0 : 3}
            pb={index === files.length - 1 ? 0 : 3}
            width="100%"
          >
            <Icon as={FileIcon} color="whiteAlpha.500" />
            <Text
              minWidth="0"
              ml={2}
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
              width={{ base: '100%', md: '240px' }}
            >
              {file.name}
            </Text>
            <Spacer />
            <Button
              mr={2}
              variant="link"
              onClick={() => window.open(fileUrl, '_blank')}
            >
              View
            </Button>
            {onRemove && (
              <Button
                variant="link"
                onClick={() => {
                  onRemove(index);
                }}
              >
                Remove
              </Button>
            )}
          </Flex>
        );
      })}
    </>
  );
};
