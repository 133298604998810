import { Tag } from '@chakra-ui/react';

export const BetaTag = ({ as }: { as?: 'sup' | 'span' }) => {
  return (
    <Tag
      as={as}
      bg="whiteAlpha.100"
      borderRadius="40px"
      color="purple.300"
      fontSize="11px"
      fontWeight={500}
      letterSpacing="0.55px"
      lineHeight="normal"
      px="7px"
      py="4px"
      size="sm"
      textTransform="uppercase"
    >
      Beta
    </Tag>
  );
};
