import { Flex, FlexProps } from '@chakra-ui/react';
import { FC, ReactNode } from 'react';
import { useIsMobile } from '~/common/hooks/useIsMobile';
import { SIDEBAR_WIDTH_COLLAPSED } from '../../components/PageDetailLayout/SidebarItem';

interface OverflowContainerProps extends FlexProps {
  children: ReactNode;
  leftMargin: number | null;
}

export const OverflowContainer: FC<OverflowContainerProps> = ({
  children,
  leftMargin,
  ...rest
}) => {
  const isMobile = useIsMobile();

  return (
    <Flex
      css={{
        '&::-webkit-scrollbar': {
          display: 'none',
        },
        '-ms-overflow-style': 'none',
        'scrollbar-width': 'none',
      }}
      direction="row"
      gap={4}
      overflowX="scroll"
      position="relative"
      // +1px to make it better visually aligned with other components
      pr={
        isMobile
          ? `calc(${leftMargin ?? 0}px + 1px)`
          : `calc(${leftMargin ?? 0}px - ${SIDEBAR_WIDTH_COLLAPSED} + 1px)`
      }
      w={
        isMobile
          ? `calc(100% + ${leftMargin ?? 0}px)`
          : `calc(100vw - ${leftMargin ?? 0}px)`
      }
      {...rest}
    >
      {children}
    </Flex>
  );
};
