import { useDroppable } from '@dnd-kit/core';
import { AbsoluteBox } from '@document/common/AbsoluteBox';
import { FC, MouseEventHandler, memo } from 'react';

interface DropContainerProps {
  id?: string;
  documentIndex: number;
  pageNumber: number;
  onClick: MouseEventHandler<HTMLDivElement>;
}

export const DropContainer: FC<DropContainerProps> = memo(
  function DropContainer({ id, documentIndex, pageNumber, onClick }) {
    const { isOver, setNodeRef } = useDroppable({
      id: `${pageNumber}-${documentIndex}`,
      data: {
        pageNumber,
        documentIndex,
      },
    });

    const dataTestId = id ? `droppable-page-${id}` : 'droppable-page';

    return (
      <AbsoluteBox
        ref={setNodeRef}
        cursor={isOver ? 'copy' : 'default'}
        data-testid={dataTestId}
        zIndex={0}
        onClick={onClick}
      />
    );
  }
);
