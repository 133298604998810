import { CheckCircleIcon, WarningTwoIcon } from '@chakra-ui/icons';
import { HStack, Link, LinkProps, Text } from '@chakra-ui/react';
import { FC, memo, useMemo } from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';

export enum StepStatus {
  ERROR = 'error',
  COMPLETE = 'complete',
  INCOMPLETE = 'incomplete',
}

interface StepNavLinkProps extends LinkProps {
  status: StepStatus;
  disabled?: boolean;
  fontSize?: 'xs' | 'sm' | 'md' | 'lg';
}

export const StepNavLink: FC<StepNavLinkProps & NavLinkProps> = memo(
  function StepNavLink({
    disabled = false,
    status,
    children,
    fontSize = 'xs',
    ...rest
  }) {
    const StatusIcon = useMemo(() => {
      switch (status) {
        case StepStatus.COMPLETE:
          return <CheckCircleIcon color="green.500" />;
        case StepStatus.ERROR:
          return <WarningTwoIcon color="red.500" />;
        case StepStatus.INCOMPLETE:
          return null;
      }
    }, [status]);

    const sharedStyles = {
      _light: {
        color: 'gray.500',
      },
      color: 'whiteAlpha.500',
      fontSize,
    };

    return (
      <HStack spacing={1}>
        {StatusIcon}
        {disabled ? (
          <Text cursor="default" {...sharedStyles}>
            {children}
          </Text>
        ) : (
          <Link
            _activeLink={{
              color: 'white',
              fontWeight: '600',
            }}
            as={NavLink}
            {...sharedStyles}
            {...rest}
          >
            {children}
          </Link>
        )}
      </HStack>
    );
  }
);
