/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, BoxProps } from '@chakra-ui/react';
import { FC, ReactNode, memo } from 'react';

export interface FormFieldContainer extends BoxProps {
  formField: {
    width: number;
    height: number;
    x: number;
    y: number;
  };
  pageHeight: number;
  pageWidth: number;
  children?: ReactNode;
  boxRef?: (element: HTMLDivElement | null) => void;
}

export const FormFieldContainer: FC<FormFieldContainer> = memo(
  function FormFieldContainer({
    formField,
    pageHeight,
    pageWidth,
    children,
    boxRef,
    ...props
  }) {
    return (
      <Box
        ref={boxRef}
        display="block"
        height="auto"
        position="absolute"
        style={{
          height: `${formField.height * pageHeight}px`,
          left: `${formField.x * pageWidth}px`,
          top: `${formField.y * pageHeight}px`,
          width: `${formField.width * pageWidth}px`,
        }}
        {...props}
      >
        {children}
      </Box>
    );
  }
);
