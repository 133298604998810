import { Box } from '@chakra-ui/react';
import {
  FieldType,
  PublicDocumentSectionFieldObject,
} from '@client/graphql/__generated__/types';
import { FC, memo, useCallback, useMemo } from 'react';
import { useIsMobile } from '~/common/hooks/useIsMobile';
import { Select } from '~/services/document/common/Select';
import { DocumentSectionField } from '~/services/document/components/DocumentSections/types';
import { ValuesType } from '~/services/document/components/FormFields/types';
import { useOfferValidations } from '~/services/document/hooks/useOfferValidations';
import { FormFieldControl } from '../../InputField/FormFieldControl';
import {
  DocumentSection,
  DocumentSectionChildren,
  DocumentSectionProps,
} from '../DocumentSection';
import { DocumentSectionContainer } from './DocumentSectionContainer';

export const getRadioFieldOption = (
  radioFields: DocumentSectionChildren[],
  fieldValues: ValuesType
) => {
  const radioField = radioFields.find(
    (radioField: DocumentSectionField) =>
      fieldValues[radioField.field.mappingKey]
  ) as DocumentSectionField;

  return radioField
    ? {
        label: radioField.field.label,
        value: radioField.field.mappingKey,
      }
    : null;
};

export const DocumentSectionDefault: FC<DocumentSectionProps> = memo(
  function DocumentSectionDefault(props) {
    const {
      documentSection,
      fieldValues,
      mergedAutofillValues,
      optionsAutofillValues,
      formFieldsMap,
      isSubSection,
      setActiveMappingKey,
      prefillSellerPrefs,
      validations,
      index,
      onChange,
      styleRootSectionCard = true,
      showTitle = true,
    } = props;
    const isMobile = useIsMobile();
    const { getValidation } = useOfferValidations({
      validations: validations || [],
    });
    const fieldsOrSections = useMemo(() => {
      let all: DocumentSectionChildren[] = [];
      all = all
        .concat(documentSection.children || [])
        .concat(documentSection.documentSectionFields || []);

      return all.sort((a, b) => a.order - b.order);
    }, [documentSection.children, documentSection.documentSectionFields]);

    const radioFields = fieldsOrSections.filter(
      (field) =>
        field.__typename === 'PublicDocumentSectionFieldObject' &&
        field.field.fieldType === FieldType.RADIO
    );

    const nonRadioFields = fieldsOrSections.filter(
      (field) =>
        field.__typename === 'PublicDocumentSectionObject' ||
        (field.__typename === 'PublicDocumentSectionFieldObject' &&
          field.field.fieldType !== FieldType.RADIO)
    );

    // Callbacks
    const onBlur = useCallback(() => {
      setActiveMappingKey?.();
    }, [setActiveMappingKey]);
    const onFocus = useCallback(
      (mappingKey?: string) => {
        setActiveMappingKey?.(mappingKey);
      },
      [setActiveMappingKey]
    );

    return (
      <DocumentSectionContainer
        key={documentSection.id}
        isSubSection={isSubSection}
        showTitle={showTitle}
        styleRootSectionCard={styleRootSectionCard}
        title={documentSection.title}
      >
        {radioFields?.length > 0 && (
          <Box width="100%">
            <Select
              isClearable
              menuPortalTarget={document.body}
              options={radioFields.map(
                (radioField: PublicDocumentSectionFieldObject) => ({
                  label: radioField.field.label,
                  value: radioField.field.mappingKey,
                })
              )}
              size="sm"
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              value={getRadioFieldOption(radioFields, fieldValues)}
              onChange={(option) => {
                const allMappingKeys = radioFields.reduce(
                  (out, field: PublicDocumentSectionFieldObject) => {
                    out[field.field.mappingKey] =
                      option?.value === field.field.mappingKey;

                    return out;
                  },
                  {} as ValuesType
                );
                onChange(allMappingKeys);
              }}
            />
          </Box>
        )}
        {nonRadioFields?.map((fieldOrSection, idx) => {
          if (fieldOrSection.__typename === 'PublicDocumentSectionObject') {
            return (
              <DocumentSection
                {...props}
                key={fieldOrSection.id}
                isSubSection
                documentSection={fieldOrSection}
                fieldValues={fieldValues}
                formFieldsMap={formFieldsMap}
                index={idx + 1}
                mergedAutofillValues={mergedAutofillValues}
                optionsAutofillValues={optionsAutofillValues}
                setActiveMappingKey={setActiveMappingKey}
                styleRootSectionCard={styleRootSectionCard}
                onChange={onChange}
              />
            );
          }
          if (
            fieldOrSection.__typename === 'PublicDocumentSectionFieldObject'
          ) {
            return (
              formFieldsMap[fieldOrSection.field.mappingKey] && (
                <FormFieldControl
                  key={fieldOrSection.id}
                  autofillValue={
                    mergedAutofillValues[fieldOrSection.field.mappingKey]
                  }
                  formField={formFieldsMap[fieldOrSection.field.mappingKey]}
                  idx={(index || 0) + idx}
                  label={fieldOrSection.field.label}
                  optionsAutofillValue={
                    optionsAutofillValues?.[fieldOrSection.field.mappingKey]
                  }
                  prefillSellerPrefs={prefillSellerPrefs}
                  shouldAutoFocus={index === 0 && idx === 0 && !isMobile}
                  validation={getValidation(fieldOrSection.field.mappingKey)}
                  value={fieldValues[fieldOrSection.field.mappingKey] as string}
                  onBlur={onBlur}
                  onChange={onChange}
                  onFocus={onFocus}
                />
              )
            );
          }
        })}
      </DocumentSectionContainer>
    );
  }
);
