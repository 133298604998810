import { Box, Flex, FormControl, FormLabel } from '@chakra-ui/react';
import { CreatableSelect } from 'chakra-react-select';
import isNil from 'lodash/isNil';
import { useEffect, useMemo, useState } from 'react';

interface RangeCreatableSelectInputProps {
  id?: string;
  label: string;
  options: { label: string; value: number }[];
  onChangeMin: (value?: number) => void;
  onChangeMax: (value?: number) => void;
  initialMinValue?: number;
  initialMaxValue?: number;
}

export const RangeCreatableSelectInput = ({
  id,
  label,
  options,
  onChangeMin,
  onChangeMax,
  initialMinValue,
  initialMaxValue,
}: RangeCreatableSelectInputProps) => {
  const [minValue, setMinValue] = useState<number | undefined>(undefined);
  const [maxValue, setMaxValue] = useState<number | undefined>(undefined);

  useEffect(() => {
    setMinValue(initialMinValue);
  }, [initialMinValue]);

  useEffect(() => {
    setMaxValue(initialMaxValue);
  }, [initialMaxValue]);

  const minOptions = useMemo(() => {
    return options.map((option) => {
      return {
        label: option.label,
        value: option.value,
        disabled: maxValue ? option.value > maxValue : false,
      };
    });
  }, [options, maxValue]);

  const maxOptions = useMemo(() => {
    return options.map((option) => {
      return {
        label: option.label,
        value: option.value,
        disabled: minValue ? option.value < minValue : false,
      };
    });
  }, [options, minValue]);

  return (
    <FormControl id={id}>
      <FormLabel>{label}</FormLabel>
      <Flex alignItems="center" gap={2} width="100%">
        <Box minWidth="100px" width="50%">
          <CreatableSelect
            isClearable
            useBasicStyles
            defaultValue={minOptions.find(
              (option) => option.value === initialMinValue
            )}
            id={`range-creatable-select-min-${id || label}`}
            isOptionDisabled={(option) => {
              return maxValue ? option.value > maxValue : false;
            }}
            isValidNewOption={(inputValue) => {
              const inputNumber = Number(inputValue);
              const isNumeric = Number.isFinite(inputNumber);

              if (!isNumeric) {
                return false;
              }

              const matchingOption = options.some(
                (option) => option.value === inputNumber
              );

              return matchingOption
                ? false
                : inputNumber > 0 && (!maxValue || inputNumber < maxValue);
            }}
            options={minOptions}
            placeholder="No min"
            size="sm"
            onChange={(option) => {
              const value = isNil(option?.value)
                ? undefined
                : Number(option?.value);
              onChangeMin(value);
              setMinValue(value);
            }}
          />
        </Box>
        -
        <Box minWidth="100px" width="50%">
          <CreatableSelect
            isClearable
            useBasicStyles
            defaultValue={maxOptions.find(
              (option) => option.value === initialMaxValue
            )}
            id={`range-creatable-select-max-${id || label}`}
            isOptionDisabled={(option) => {
              return minValue ? option.value < minValue : false;
            }}
            isValidNewOption={(inputValue) => {
              const inputNumber = Number(inputValue);
              const isNumeric = Number.isFinite(inputNumber);

              if (!isNumeric) {
                return false;
              }

              const matchingOption = options.some(
                (option) => option.value === inputNumber
              );

              return matchingOption ? false : inputNumber > (minValue || 0);
            }}
            options={maxOptions}
            placeholder="No max"
            size="sm"
            onChange={(option) => {
              const value = isNil(option?.value)
                ? undefined
                : Number(option?.value);
              onChangeMax(value);
              setMaxValue(value);
            }}
          />
        </Box>
      </Flex>
    </FormControl>
  );
};
