import { UsState } from '@client/graphql/__generated__/types';
import { useFeatureFlagPayload } from 'posthog-js/react';

export enum FeatureKey {
  MAKE_OFFER = 'make-offer',
  OPEN_HOUSES = 'open-houses',
  SIGN_UP = 'sign-up',
}

interface UseIsFeatureEnabledForStateOrMlsProps {
  featureFlagKey: FeatureKey;
  mlsOSN?: string;
  state?: UsState;
}

/**
 * Example payload from PostHog feature flag:
 * {
 *   state: [UsState.CA, UsState.TX], // Array of US states where feature is enabled
 *   mls: ['CanopyMLS', 'TestMLS']    // Array of MLS originating system names where feature is enabled
 * }
 */

interface StateAndMlsPayload {
  state?: UsState[];
  mls?: string[];
}

export const useGetStateAndMLSFeatureFlagPayload = (
  featureFlagKey: FeatureKey | undefined
) => {
  return useFeatureFlagPayload(featureFlagKey ?? '') as
    | StateAndMlsPayload
    | undefined;
};

export const useIsFeatureEnabledForStateOrMls = ({
  featureFlagKey,
  mlsOSN,
  state,
}: UseIsFeatureEnabledForStateOrMlsProps) => {
  const payload = useGetStateAndMLSFeatureFlagPayload(featureFlagKey);

  if (!mlsOSN && !state) {
    return false;
  }

  if (window.ENVS.environment === 'e2e') {
    return true;
  }

  const isFeatureEnabled = state
    ? payload?.state?.includes(state)
    : mlsOSN
      ? payload?.mls?.includes(mlsOSN)
      : false;

  return !!isFeatureEnabled;
};
