import {
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
} from '@chakra-ui/react';
import {
  OpenHouseCardFragment,
  OpenHouseCrudOptions,
} from '@client/graphql/__generated__/types';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { Field, Form, Formik, FormikProps } from 'formik';
import { useListingActivityContext } from '~/apps/consumer/contexts/ListingActivityContext';

dayjs.extend(isSameOrBefore);
const OPEN_HOUSE_DATE_FORMAT = 'YYYY-MM-DD';
const DEFAULT_START_TIME = '10:00';
const DEFAULT_END_TIME = '12:00';

const defaultDate = dayjs().add(1, 'day').format(OPEN_HOUSE_DATE_FORMAT);
export const DEFAULT_CREATE_OPEN_HOUSE_OPTIONS = {
  date: defaultDate,
  startTime: DEFAULT_START_TIME,
  endTime: DEFAULT_END_TIME,
  options: {
    notifyGuestbookSigners: true,
  },
};

export interface OpenHouseFormData {
  date: string;
  startTime: string;
  endTime: string;
  options?: OpenHouseCrudOptions;
}

interface ListingOpenHouseFormModalProps {
  isOpen: boolean;
  onClose: () => void;
  editingOpenHouse: OpenHouseCardFragment | null;
  onCreateOpenHouse: (formData: OpenHouseFormData) => Promise<void>;
  onUpdateOpenHouse: (formData: OpenHouseFormData) => Promise<void>;
}

export function ListingOpenHouseFormModal({
  isOpen,
  onClose,
  editingOpenHouse,
  onCreateOpenHouse,
  onUpdateOpenHouse,
}: ListingOpenHouseFormModalProps) {
  const { followerCount } = useListingActivityContext();

  const initialValues: OpenHouseFormData = editingOpenHouse
    ? {
        date: dayjs(editingOpenHouse.date).format(OPEN_HOUSE_DATE_FORMAT),
        startTime: editingOpenHouse.startTime,
        endTime: editingOpenHouse.endTime,
        options: {
          notifyGuestbookSigners: true,
        },
      }
    : DEFAULT_CREATE_OPEN_HOUSE_OPTIONS;

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <Formik
        initialValues={initialValues}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            if (editingOpenHouse) {
              await onUpdateOpenHouse(values);
            } else {
              await onCreateOpenHouse(values);
            }
            onClose();
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {(props: FormikProps<OpenHouseFormData>) => (
          <Form>
            <ModalContent>
              <ModalHeader>
                {editingOpenHouse ? 'Edit Open House' : 'Create New Open House'}
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <VStack spacing={4}>
                  <OpenHouseFormControls props={props} />
                </VStack>
              </ModalBody>
              <OpenHouseFormFooter
                editingOpenHouse={!!editingOpenHouse}
                followerCount={followerCount}
                formikProps={props}
                onClose={onClose}
              />
            </ModalContent>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

export const OpenHouseFormControls = ({
  props,
}: {
  props: FormikProps<OpenHouseFormData>;
}) => {
  const { values } = props;
  const now = dayjs();

  return (
    <>
      <FormControl isRequired>
        <FormLabel>Date</FormLabel>
        <Input
          as={Field}
          max={now.add(6, 'month').format(OPEN_HOUSE_DATE_FORMAT)}
          min={now.format(OPEN_HOUSE_DATE_FORMAT)}
          name="date"
          type="date"
        />
      </FormControl>
      <FormControl isRequired mt={4}>
        <FormLabel>Start Time</FormLabel>
        <Input required as={Field} name="startTime" type="time" />
      </FormControl>
      <FormControl isRequired mt={4}>
        <FormLabel>End Time</FormLabel>
        <Input
          as={Field}
          min={values.startTime}
          name="endTime"
          type="time"
          value={values.endTime}
        />
      </FormControl>
    </>
  );
};

interface OpenHouseFormFooterProps {
  followerCount: number;
  onClose: () => void;
  editingOpenHouse: boolean;
  formikProps: FormikProps<OpenHouseFormData>;
}

export const OpenHouseFormFooter: React.FC<OpenHouseFormFooterProps> = ({
  followerCount,
  onClose,
  editingOpenHouse,
  formikProps,
}) => {
  return (
    <ModalFooter>
      {followerCount > 0 && (
        <Checkbox
          color="whiteAlpha.600"
          defaultChecked={true}
          flex={1}
          isChecked={formikProps.values.options?.notifyGuestbookSigners ?? true}
          name="notifyGuestbookSigners"
          onChange={(e) => {
            void formikProps.setFieldValue(
              'options.notifyGuestbookSigners',
              e.target.checked
            );
          }}
        >
          {`Notify (${followerCount}) Guestbook ${
            followerCount === 1 ? 'member' : 'members'
          }`}
        </Checkbox>
      )}
      <Button mr={3} variant="ghost" onClick={onClose}>
        Cancel
      </Button>
      <Button
        colorScheme="blue"
        isLoading={formikProps.isSubmitting}
        type="submit"
      >
        {editingOpenHouse ? 'Update' : 'Create'}
      </Button>
    </ModalFooter>
  );
};
