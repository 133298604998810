import { GeocodingFeature } from '@mapbox/search-js-core';
import { LngLatBounds } from 'mapbox-gl';
import { MlsListingWithListing } from './ExploreContext';

export const convertBoundsToBbox = (bounds?: LngLatBounds | null) => {
  if (!bounds) return null;

  return {
    topLeft: {
      lon: bounds.getWest(),
      lat: bounds.getNorth(),
    },
    bottomRight: {
      lon: bounds.getEast(),
      lat: bounds.getSouth(),
    },
  };
};

export type SearchResult = GeocodingFeature & {
  properties: {
    context: {
      address: { address_number: string };
      region: { region_code: string };
    };
  };
};

/***
 * Matching Strategy:
 * - Street number, state, postal code must match exactly
 * - Street name must match all characters except for the case that one has a street suffix and the other does not.
 *   Then all other words in the street name must match exactly.
 * - City does not need to match, but will be used to determine the best matching listing if there are multiple matches.
 */
export const getMatchingListingForSearchResult = (
  mlsListings: MlsListingWithListing[],
  searchResult: SearchResult | null
) => {
  const matchingListings = mlsListings
    .map((mlsListing) => {
      const streetNumberMatch =
        mlsListing.mlsListing.address.streetNumberText ===
        searchResult?.properties?.context?.address?.address_number;
      const streetNameMatch = getStreetNameMatch(
        searchResult?.properties?.context?.street?.name,
        mlsListing.mlsListing.address.streetName
      );
      const stateMatch =
        mlsListing.mlsListing.address.state ===
        searchResult?.properties?.context?.region?.region_code;
      const cityMatch =
        mlsListing.mlsListing.address.city ===
        searchResult?.properties?.context?.place?.name;
      const postalCodeMatch =
        mlsListing.mlsListing.address.postalCode ===
        searchResult?.properties?.context?.postcode?.name;

      if (
        !streetNumberMatch ||
        !streetNameMatch ||
        !stateMatch ||
        !postalCodeMatch
      )
        return null;

      return {
        mlsListing,
        cityMatch,
      };
    })
    .filter((listing) => listing !== null);

  if (matchingListings.length === 1) return matchingListings[0]?.mlsListing;
  if (matchingListings.length > 1) {
    return getBestMatchingListing(matchingListings);
  }

  return null;
};

export const getStreetNameMatch = (
  resultStreetName?: string,
  mlsListingStreetName?: string
) => {
  if (!resultStreetName && !mlsListingStreetName) return true;
  if (!resultStreetName || !mlsListingStreetName) return false;

  const resultStreetNameWords = resultStreetName.toLowerCase().split(' ');
  const mlsListingStreetNameWords = mlsListingStreetName
    .toLowerCase()
    .split(' ');

  if (
    Math.abs(resultStreetNameWords.length - mlsListingStreetNameWords.length) >
    1
  ) {
    return false;
  }

  const numMustMatchWords = Math.min(
    resultStreetNameWords.length,
    mlsListingStreetNameWords.length
  );

  for (let step = 0; step < numMustMatchWords; step++) {
    if (resultStreetNameWords[step] !== mlsListingStreetNameWords[step]) {
      return false;
    }
  }

  return true;
};

export const getBestMatchingListing = (
  matchingListings: {
    mlsListing: MlsListingWithListing;
    cityMatch: boolean;
  }[]
) => {
  const bestMatchingListing = matchingListings.reduce((best, current) => {
    if (best.cityMatch && !current.cityMatch) return best;
    if (!best.cityMatch && current.cityMatch) return current;

    return best;
  }, matchingListings[0]);

  return bestMatchingListing.mlsListing;
};
