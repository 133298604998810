import {
  Box,
  Button,
  Center,
  Heading,
  HStack,
  Spinner,
  VStack,
} from '@chakra-ui/react';
import { useContractByIdQuery } from '@client/graphql/__generated__/document-operations';
import { FieldType } from '@client/graphql/__generated__/types';
import {
  DocumentFormFieldsContext,
  DocumentFormFieldsProvider,
} from '@document/components/DocumentFormFields/context';
import { PageFormFields } from '@document/components/DocumentFormFields/PageFormFields';
import { FormField, ValuesType } from '@document/components/FormFields/types';
import { PDFScrollable } from '@document/components/PDFScrollable/PDFScrollable';
import {
  DocumentsThumbnails,
  PageMetadata,
  VisiblePage,
} from '@document/components/PDFScrollable/types';
import { memo, useContext, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { DocumentsThumbnailsWidgetWithContext } from '~/services/document/components/DocumentMapperWidgets/DocumentsThumbnailsWidget/DocumentsThumbnailsWidget';
import { OfferFields } from './OfferFields';

const PDFFormFieldsList = memo(function PDFFormFieldsList({
  page,
}: {
  page: PageMetadata;
}) {
  const {
    formFields,
    isRepositionedVisible,
    repositionedFormFields,
    values,
    updateValues,
  } = useContext(DocumentFormFieldsContext);

  return page.isVisible ? (
    <>
      {isRepositionedVisible ? (
        <PageFormFields
          containerProps={{
            bgColor: 'white',
          }}
          formFields={
            repositionedFormFields[page.pageNumber - 1]?.map((field) => ({
              ...field,
              documentIndex: 0,
            })) || []
          }
          page={page}
          updateValues={updateValues}
          values={values}
        />
      ) : (
        <PageFormFields
          formFields={formFields[page.documentIndex] || []}
          page={page}
          updateValues={updateValues}
          values={values}
        />
      )}
    </>
  ) : null;
});

export const OfferFlow = () => {
  const { contractId } = useParams();
  const [visiblePage, setVisiblePage] = useState<VisiblePage | null>(null);
  const [thumbs, setThumbnails] = useState<DocumentsThumbnails>({});
  const [scrolalbleRef, setScrollableRef] = useState<HTMLDivElement | null>(
    null
  );
  const { data } = useContractByIdQuery({
    variables: {
      id: contractId as string,
      documentML: true,
      documentVersionFields: true,
      extractedValues: true,
    },
  });

  const contract = useMemo(() => {
    return data?.contractById;
  }, [data?.contractById]);

  const propertyAddress =
    contract?.fieldValues?.['property[street_address_full]'];

  const contractDocuments = useMemo(() => {
    return (
      contract?.contractDocuments && Array.from(contract?.contractDocuments)
    );
  }, [contract?.contractDocuments]);

  const contractDocumentFormFields = useMemo(() => {
    return contractDocuments
      ?.sort((a, b) => a.order - b.order)
      .map((contractDocument, index) => {
        return contractDocument.documentVersion?.documentVersionFields
          ?.map((dvf) => {
            return {
              documentIndex: index,
              ...dvf,
            } as FormField;
          })
          .filter(
            (field) =>
              field.fieldType !== FieldType.INITIAL &&
              field.fieldType !== FieldType.SIGNATURE &&
              field.fieldType !== FieldType.SIGNED_DATE
          );
      })
      .filter((fields) => !!fields?.length) as unknown as FormField[][];
  }, [contractDocuments]);

  const extractedValues = contract?.extractedValues?.[0];

  if (!contract) {
    return (
      <Center>
        <Spinner />
      </Center>
    );
  }

  return (
    <DocumentFormFieldsProvider
      formFields={contractDocumentFormFields}
      repositionedFormFields={
        extractedValues?.repositionedDocumentVersionFields as unknown as FormField[][]
      }
      values={(contract?.fieldValues as unknown as ValuesType) || {}}
    >
      <VStack spacing={0} width="100%">
        <HStack bg="white" px={6} py={2} width="100%">
          <Heading flexGrow={1} fontSize="lg" size="sm">
            Offer: {propertyAddress}
          </Heading>

          <DocumentFormFieldsContext.Consumer>
            {({ isRepositionedVisible, setIsRepositionVisible }) => (
              <Button
                size="sm"
                variant="outline"
                onClick={() => setIsRepositionVisible((val) => !val)}
              >
                {isRepositionedVisible ? 'Show Original' : 'Show Re-positioned'}
              </Button>
            )}
          </DocumentFormFieldsContext.Consumer>

          <Button colorScheme="teal" size="sm">
            Close
          </Button>
        </HStack>
        <HStack alignItems="stretch" width="100%">
          <Box maxHeight="calc(100vh - 75px)" width="300px">
            {visiblePage && (
              <DocumentsThumbnailsWidgetWithContext
                documentsThumbnails={thumbs}
                visiblePage={visiblePage}
              />
            )}
          </Box>
          <Box
            bgColor="gray.400"
            height="calc(100vh - 65px)"
            overflow="hidden"
            width="100%"
          >
            <PDFScrollable
              maxWidth={1200}
              pageChildren={PDFFormFieldsList}
              pdfFiles={
                contractDocuments?.map(({ file, documentVersion }) => ({
                  url: file.url,
                  name: documentVersion?.document.name || file.fileName,
                })) || []
              }
              onDocumentsThumbnailsChange={(thumbnails) => {
                setThumbnails(thumbnails);
              }}
              onVisiblePageChange={setVisiblePage}
            />
          </Box>

          <Box
            ref={setScrollableRef}
            flexShrink={0}
            height="calc(100vh - 75px)"
            overflow="auto"
            width="500px"
          >
            {scrolalbleRef && (
              <OfferFields
                formFieldsPerDocument={contractDocumentFormFields}
                scrollableRef={{ current: scrolalbleRef }}
              />
            )}
          </Box>
        </HStack>
      </VStack>
    </DocumentFormFieldsProvider>
  );
};
