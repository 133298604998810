export enum VariableDataType {
  TEXT = 'TEXT',
  NUMBER = 'NUMBER',
  BOOLEAN = 'BOOLEAN',
  LIST = 'LIST',
  DATE = 'DATE',
  CHILDREN = 'CHILDREN',
}

export enum VariableSource {
  FIELD = 'FIELD',
  INDIGO_OFFER = 'INDIGO_OFFER',
  SELF = 'SELF',
}
