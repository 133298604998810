import { Tag, TagProps } from '@chakra-ui/react';
import { FC, memo, useMemo } from 'react';

interface ValidationTagProps extends TagProps {
  isError?: boolean;
  isSuccess?: boolean;
  size?: string;
  children: React.ReactNode;
}

export const ValidationTag: FC<ValidationTagProps> = memo(
  function ValidationTag({ isError, isSuccess, size, children, ...props }) {
    const colors = useMemo(() => {
      if (isSuccess) {
        return {
          color: 'green.400',
        };
      }

      if (isError) {
        return {
          color: 'red.400',
        };
      }
    }, [isError, isSuccess]);

    return (
      <Tag variant="validation" {...colors} {...props} size={size}>
        {children}
      </Tag>
    );
  }
);
