import { Button, ButtonProps, useDisclosure } from '@chakra-ui/react';
import { Roles } from '@client/graphql/__generated__/types';
import { Paperclip } from 'lucide-react';
import { Dispatch } from 'react';
import { ListingUploadOfferModal } from '~/apps/consumer/components/Offers/ListingUploadOfferModal';
import { OfferProcessingModal } from '~/apps/consumer/components/Offers/OfferProcessingModal';
import { RoleFilter } from '~/apps/consumer/components/User/RoleFilter';
import { useListingSetupContext } from '../ListingSetupContext';

const defaultButtonContent = (
  <>
    <Paperclip size="16px" />
    Upload
  </>
);

type UploadOfferByListingAgentButtonProps = {
  children?: React.ReactNode;
  isProcessingUploadedOffer: boolean;
  setIsProcessingUploadedOffer: Dispatch<React.SetStateAction<boolean>>;
} & ButtonProps;

export const UploadOfferByListingAgentButton = ({
  children = defaultButtonContent,
  isProcessingUploadedOffer,
  setIsProcessingUploadedOffer,
  ...buttonProps
}: UploadOfferByListingAgentButtonProps) => {
  const { listing, refetchAllData } = useListingSetupContext();
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <RoleFilter roles={[Roles.AGENT]}>
      <Button
        display="flex"
        fontWeight="semibold"
        gap={1}
        iconSpacing={1}
        size="sm"
        variant="outline"
        onClick={onOpen}
        {...buttonProps}
      >
        {children}
      </Button>
      <ListingUploadOfferModal
        isOpen={isOpen}
        listingId={listing.id}
        refetchAllData={refetchAllData}
        setIsProcessingUploadedOffer={setIsProcessingUploadedOffer}
        onClose={onClose}
      />
      <OfferProcessingModal
        isProcessingUploadedOffer={isProcessingUploadedOffer}
      />
    </RoleFilter>
  );
};
