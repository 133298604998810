import { Icon, IconProps } from '@chakra-ui/react';
import { memo } from 'react';

export const BathIcon = memo((props: IconProps) => {
  return (
    <Icon
      fill="none"
      height="20"
      viewBox="0 0 21 20"
      width="21"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_1194_4565)">
        <path
          d="M19.0529 9.15892H2.89382V2.68164C2.89382 2.09073 3.37109 1.59073 3.98473 1.59073C4.59837 1.59073 5.07564 2.068 5.07564 2.68164H5.98473C5.98473 1.59073 5.09837 0.681641 3.98473 0.681641C2.87109 0.681641 1.98473 1.568 1.98473 2.68164V9.20437C1.34837 9.36346 0.871094 9.95437 0.871094 10.6362C0.871094 11.318 1.34837 11.9089 1.98473 12.068C2.05291 14.318 3.37109 16.2498 5.28019 17.1816L3.712 18.5907L4.32564 19.2726L6.23473 17.568C6.75746 17.7271 7.30291 17.7953 7.89382 17.7953H13.5302C14.0984 17.7953 14.6665 17.7044 15.1893 17.568L17.0984 19.2726L17.712 18.5907L16.1438 17.1816C18.0529 16.2498 19.3711 14.2953 19.4393 12.068C20.0756 11.9089 20.5529 11.318 20.5529 10.6362C20.5302 9.81801 19.8711 9.15892 19.0529 9.15892ZM13.5302 16.8862H7.89382C5.212 16.8862 3.03018 14.7726 2.89382 12.1135H18.5075C18.3938 14.7726 16.212 16.8862 13.5302 16.8862ZM19.0529 11.2044H2.34837C2.03018 11.2044 1.78018 10.9544 1.78018 10.6362C1.78018 10.318 2.03018 10.068 2.34837 10.068H19.0529C19.3711 10.068 19.6211 10.318 19.6211 10.6362C19.6211 10.9544 19.3711 11.2044 19.0529 11.2044Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_1194_4565">
          <rect
            fill="white"
            height="18.6364"
            transform="translate(0.711914 0.681641)"
            width="20"
          />
        </clipPath>
      </defs>
    </Icon>
  );
});
