import { useDisclosure } from '@chakra-ui/react';
import {
  IndigoContractObject,
  MlsListingObject,
} from '@client/graphql/__generated__/types';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useFindPropertyEvents } from '../../Buyers/hooks/useFindPropertyEvents';

export const useMainDashboardModals = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { onSelect } = useFindPropertyEvents();

  const findPropertyModal = useDisclosure();

  const openHouseOverviewModal = useDisclosure();

  const agencyAgreementModal = useDisclosure({
    defaultIsOpen: searchParams.get('state') === 'new-agency-agreement',
  });

  const dashboardTourModal = useDisclosure();

  const addBuyerOfferAnalysisModal = useDisclosure();

  const handleFindPropertySelect = async (listing: MlsListingObject) => {
    await onSelect(listing);
    findPropertyModal.onOpen();
  };

  const handleAgencyAgreementClose = () => {
    agencyAgreementModal.onClose();
    setSearchParams((sp) => {
      sp.delete('state');

      return sp;
    });
  };

  const handleAgencyAgreementContinue = (
    indigoContract: IndigoContractObject
  ) => {
    agencyAgreementModal.onClose();
    setSearchParams((sp) => {
      sp.delete('state');

      return sp;
    });
    navigate(`/dashboard/agency-agreement/${indigoContract.id}`, {
      state: {
        isPrepareIndigoContract: true,
      },
    });
  };

  return {
    agencyAgreementModal: {
      ...agencyAgreementModal,
      onClose: handleAgencyAgreementClose,
      onContinue: handleAgencyAgreementContinue,
    },
    addBuyerOfferAnalysisModal: {
      ...addBuyerOfferAnalysisModal,
    },
    dashboardTourModal,
    findPropertyModal: {
      ...findPropertyModal,
      handleSelect: handleFindPropertySelect,
    },
    openHouseOverviewModal,
  };
};
