import { Select, StyleProps, Textarea } from '@chakra-ui/react';
import { FieldType } from '@client/graphql/__generated__/types';
import { Property } from 'csstype';
import { debounce, noop } from 'lodash';
import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { CheckboxFormField } from './CheckboxFormField';
import { DateFormField } from './DateFormField';
import { InputFormField } from './InputFormField';
import { NumberFormField } from './NumberFormField';
import { baseInputStyle } from './defaults';
import { FormField as FormFieldType, ValuesType } from './types';

export interface FormFieldProps extends StyleProps {
  formField: FormFieldType;
  isDisabled?: boolean;
  sizeRatio?: number;
  readOnly?: boolean;
  value?: string | number | boolean | string[];
  tabIndex?: number;
  onChange?: (values: ValuesType) => void;
}

const DEBOUNCE_TIME = process.env.NODE_ENV === 'test' ? 0 : 300;

export const FormField: FC<FormFieldProps> = memo(function FormField({
  formField,
  sizeRatio,
  bgColor,
  value,
  readOnly,
  isDisabled,
  tabIndex,
  onChange,
}) {
  const [fieldValue, setFieldValue] = useState<
    string | number | boolean | string[] | undefined
  >('');
  const style = useMemo(() => {
    const fontSize =
      parseInt(formField.fontSize?.replace('px', '') || '10') *
      (sizeRatio || 1);
    let backgroundColor = bgColor && `${bgColor as string}80`;

    if (readOnly) {
      backgroundColor = 'transparent';
    }

    return {
      fontFamily: formField.fontFamily,
      fontSize: `${Math.round(fontSize)}px`,
      textAlign: formField.align as unknown as Property.TextAlign,
      backgroundColor,
    };
  }, [
    formField.fontSize,
    formField.fontFamily,
    formField.align,
    sizeRatio,
    readOnly,
    bgColor,
  ]);

  const onChangeDebounce = useMemo(
    () => debounce(onChange || noop, DEBOUNCE_TIME),
    [onChange]
  );
  const onChangeCallback = useCallback(
    (value: string | number | boolean | undefined) => {
      setFieldValue(value);

      onChangeDebounce({
        [formField.mappingKey]: value,
      });
    },
    [formField.mappingKey, onChangeDebounce]
  );

  useEffect(() => {
    setFieldValue((prev) => {
      if (prev !== value) {
        return value;
      }

      return prev;
    });
  }, [value]);

  switch (formField.fieldType) {
    case FieldType.NUMBER:
      return (
        <NumberFormField
          data-testid="number-form-field"
          id={formField.id}
          inputFormat={formField.inputFormat}
          isDisabled={isDisabled}
          style={style}
          tabIndex={tabIndex}
          value={fieldValue as string}
          onChange={onChangeCallback}
        />
      );
    case FieldType.DATE:
      return (
        <DateFormField
          data-testid="date-form-field"
          dateFormat={
            formField.inputFormat?.options.formatString ||
            formField.field?.inputFormat?.options.formatString
          }
          id={formField.id}
          style={style}
          tabIndex={tabIndex}
          value={fieldValue as string}
          onChange={(date) => {
            onChangeCallback(date?.toString());
          }}
        />
      );
    case FieldType.SELECT:
      return (
        <Select
          {...baseInputStyle}
          data-testid="select-form-field"
          id={formField.id}
          isDisabled={isDisabled}
          rootProps={{ height: '100%' }}
          style={style}
          tabIndex={tabIndex}
        />
      );
    case FieldType.CHECKBOX:
      return (
        <CheckboxFormField
          checked={!!fieldValue}
          data-testid="checkbox-form-field"
          id={formField.id}
          style={style}
          tabIndex={tabIndex}
          onChange={onChangeCallback}
        />
      );
    case FieldType.TEXTAREA:
      return (
        <Textarea
          {...baseInputStyle}
          data-testid="textarea-form-field"
          id={formField.id}
          isDisabled={isDisabled}
          minHeight="auto"
          py={1}
          style={style}
          tabIndex={tabIndex}
          value={fieldValue as string}
          onChange={(e) => onChangeCallback(e.currentTarget.value)}
        />
      );
    default:
      return (
        <InputFormField
          data-testid="input-form-field"
          id={formField.id}
          isDisabled={isDisabled}
          style={style}
          tabIndex={tabIndex}
          value={fieldValue as string}
          onChangeValue={onChangeCallback}
        />
      );
  }
});
