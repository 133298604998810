import { Image, VStack } from '@chakra-ui/react';
import {
  TourModalPageSubtitle,
  TourModalPageTitle,
} from '../../../Tour/styled';
import DashboardOnboarding1 from './assets/dashboard-onboarding-1.png';

export const Page1 = () => {
  const title = 'Modern Listing Storefronts';
  const subtitle =
    'Streamline all of your communications, paperwork and contracts';

  return (
    <VStack spacing={4}>
      <TourModalPageTitle fontSize="40px" fontWeight="medium">
        {title}
      </TourModalPageTitle>
      <TourModalPageSubtitle fontSize="xl" maxWidth="385px" mb={4}>
        {subtitle}
      </TourModalPageSubtitle>
      <Image maxWidth="385px" src={DashboardOnboarding1} width="100%" />
    </VStack>
  );
};
