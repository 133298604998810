import { Flex } from '@chakra-ui/react';
import { usePartyAndBuyerInfoQuery } from '@client/graphql/__generated__/main-operations';
import { useCallback } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { useIsMobile } from '~/common/hooks/useIsMobile';
import { MobileNavDrawer } from '../../components/Listings/ListingSetup/MobileNavDrawer';
import { PageDetailLayout } from '../../components/PageDetailLayout/PageDetailLayout';
import {
  SIDEBAR_WIDTH,
  SIDEBAR_WIDTH_COLLAPSED,
} from '../../components/PageDetailLayout/PageDetailLayoutSidebar';
import { BuyerPartyPageSidebar } from './BuyerPartyPageSidebar';

export const BuyerPartyPageLayout = () => {
  const { buyerPartyId } = useParams<string>();
  const { data, refetch: refetchPartyAndBuyerInfo } = usePartyAndBuyerInfoQuery(
    {
      variables: {
        input: { id: buyerPartyId as string },
      },
      skip: !buyerPartyId,
    }
  );

  const isMobile = useIsMobile();

  const navigate = useNavigate();

  const handleClickBack = useCallback(() => {
    navigate('/buyers');
  }, [navigate]);

  return (
    <PageDetailLayout>
      {!isMobile && <BuyerPartyPageSidebar />}
      {isMobile && (
        <MobileNavDrawer
          Sidebar={BuyerPartyPageSidebar}
          backButtonText="Buyers"
          onClickBack={handleClickBack}
        />
      )}
      <Flex
        boxSizing="border-box"
        overflowX="hidden"
        width={{
          base: '100%',
          md: `calc(100vw - (${SIDEBAR_WIDTH} + ${SIDEBAR_WIDTH_COLLAPSED}))`,
        }}
      >
        <Outlet
          context={{
            partyAndBuyerInfo: data?.partyAndBuyerInfo,
            refetchPartyAndBuyerInfo,
          }}
        />
      </Flex>
    </PageDetailLayout>
  );
};
