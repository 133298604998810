import { Flex, Progress } from '@chakra-ui/react';
import { DEFAULT_POSITION } from '../MapSearch/constants';

import {
  ListingFragment,
  MlsListingStatus,
} from '@client/graphql/__generated__/types';
import debounce from 'lodash/debounce';
import { MapEvent } from 'mapbox-gl';
import { useCallback, useMemo } from 'react';
import {
  Map,
  NavigationControl,
  Popup,
  ScaleControl,
  ViewStateChangeEvent,
} from 'react-map-gl';
import { ListingCard } from '../Listings/ListingCard/ListingCard';
import { ListingStatusLegend } from '../Map/Legends/ListingStatusLegend';
import { convertBoundsToBbox } from '../MapSearch/helpers';
import {
  OrganizationMapProvider,
  useOrganizationMapContext,
} from './OrganizationMapContext';
import { OrganizationMapMarker } from './OrganizationMapMarker/OrganizationMapMarker';

export const OrganizationMapContent = () => {
  const {
    mapRef,
    listings,
    setSelectedListing,
    selectedListing,
    loading,
    updateFilters,
    filters,
  } = useOrganizationMapContext();

  const updateStatusFilter = useCallback(
    (statuses: MlsListingStatus[]) => {
      updateFilters({ status: statuses });
    },
    [updateFilters]
  );

  const fetchListings = useCallback(
    (event: MapEvent) => {
      const bounds = event.target.getBounds();
      const bbox = convertBoundsToBbox(bounds);

      if (bbox) {
        updateFilters({
          bbox,
        });
      }
    },
    [updateFilters]
  );

  const handleMove = useMemo(
    () =>
      debounce((event: ViewStateChangeEvent) => {
        fetchListings(event);
      }, 300),
    [fetchListings]
  );

  const markers = useMemo(() => {
    return listings.map((listing) => {
      if (!listing.property?.geo?.lat || !listing.property?.geo?.lng) {
        return null;
      }

      return (
        <OrganizationMapMarker
          key={`marker-${listing.id}`}
          lat={listing.property.geo.lat}
          listing={listing}
          lng={listing.property.geo.lng}
          zoom={mapRef.current?.getZoom() || DEFAULT_POSITION.zoom}
          onClick={(event) => {
            event.originalEvent.stopPropagation();
            setSelectedListing(listing);
          }}
        />
      );
    });
  }, [listings, setSelectedListing, mapRef]);

  return (
    <Flex
      direction={{ base: 'column-reverse', xl: 'column' }}
      height="calc(100vh * 0.65)"
      width="100%"
    >
      <Progress
        isIndeterminate
        height="2px"
        size="xs"
        visibility={loading ? 'visible' : 'hidden'}
        width="100%"
      />
      <Map
        ref={mapRef}
        initialViewState={{
          longitude: DEFAULT_POSITION.lng,
          latitude: DEFAULT_POSITION.lat,
          zoom: DEFAULT_POSITION.zoom,
        }}
        mapStyle="mapbox://styles/mapbox/navigation-guidance-night-v4"
        mapboxAccessToken={window.ENVS.mapboxApiKey}
        minZoom={3}
        style={{ borderRadius: '8px' }}
        testMode={window.ENVS.environment === 'e2e'}
        onLoad={fetchListings}
        onMove={handleMove}
      >
        <NavigationControl position="top-left" />
        <ScaleControl />
        {markers}
        {selectedListing && selectedListing.property?.geo && (
          <Popup
            focusAfterOpen={false}
            latitude={selectedListing.property.geo.lat}
            longitude={selectedListing.property.geo.lng}
            offset={15}
            style={{
              maxWidth: '260px',
              minWidth: '260px',
              zIndex: 2,
              borderRadius: '12px',
            }}
            onClose={() => setSelectedListing(null)}
          >
            <ListingCard
              shouldOpenInNewTab
              listing={selectedListing as ListingFragment}
            />
          </Popup>
        )}
        <ListingStatusLegend
          selectedStatuses={filters.status}
          onClick={updateStatusFilter}
        />
      </Map>
    </Flex>
  );
};

export const OrganizationMap = () => {
  return (
    <OrganizationMapProvider>
      <OrganizationMapContent />
    </OrganizationMapProvider>
  );
};
