import { Flex, FlexProps } from '@chakra-ui/react';

export interface GradientBorderProps extends FlexProps {
  gradient?: string;
}

export const GradientBorder = ({
  children,
  gradient = 'purpleGradient',
  ...props
}: GradientBorderProps) => {
  return (
    <Flex
      border="1px solid transparent"
      borderRadius="8px"
      direction="column"
      gap={5}
      p={4}
      position="relative"
      sx={{
        '&::before': {
          border: '1px solid transparent',
          borderRadius: '8px',
          content: '""',
          position: 'absolute',
          inset: 0,
          background: gradient,
          '-webkit-mask':
            'linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0)',
          mask: 'linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0)',
          '-webkit-mask-composite': 'xor',
          maskComposite: 'exclude',
        },
      }}
      {...props}
    >
      {children}
    </Flex>
  );
};
