export function setupE2ECoverage() {
  if (window.ENVS.environment !== 'e2e') {
    return;
  }

  window.__saveCoverage__ = async () => {
    await fetch('http://localhost:8888/save-coverage', {
      method: 'POST',
      body: JSON.stringify(window.__coverage__),
    });
  };
}
