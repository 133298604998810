import { Flex, Input, InputProps, useColorMode } from '@chakra-ui/react';
import dayjs from 'dayjs';
import DatePicker from 'react-datepicker';
import { DEFAULT_DATE_FORMAT } from '~/common/utils/formatter';

interface DateRangeInputProps {
  startDate?: Date | null;
  endDate?: Date | null;
  onStartChange: (date: Date | null) => void;
  onEndChange: (date: Date | null) => void;
  inputProps?: InputProps;
  placeholder?: string;
}

export const DateRangeInput = ({
  startDate,
  endDate,
  onStartChange,
  onEndChange,
  inputProps,
  placeholder = 'Select a date',
}: DateRangeInputProps) => {
  const { colorMode } = useColorMode();

  const selectedStartDate = (startDate && dayjs(startDate).toDate()) || null;
  const selectedEndDate = (endDate && dayjs(endDate).toDate()) || null;

  const startValue = startDate
    ? dayjs(startDate).format(DEFAULT_DATE_FORMAT)
    : undefined;
  const endValue = endDate
    ? dayjs(endDate).format(DEFAULT_DATE_FORMAT)
    : undefined;

  return (
    <Flex alignItems="center" gap={2}>
      <DatePicker
        customInput={<Input {...inputProps} />}
        placeholderText={placeholder}
        popperClassName={colorMode === 'dark' ? 'dark' : undefined}
        selected={selectedStartDate}
        value={startValue}
        withPortal={false}
        onChange={onStartChange}
      />
      -
      <DatePicker
        customInput={<Input {...inputProps} />}
        placeholderText={placeholder}
        popperClassName={colorMode === 'dark' ? 'dark' : undefined}
        selected={selectedEndDate}
        value={endValue}
        withPortal={false}
        onChange={onEndChange}
      />
    </Flex>
  );
};
