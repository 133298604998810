import {
  Card,
  CardBody,
  CardHeader,
  Flex,
  Icon,
  Spacer,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { FieldFragment } from '@client/graphql/__generated__/types';
import { formatDistanceToNow } from 'date-fns';
import { Check, TriangleAlert } from 'lucide-react';
import { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useFormatFieldValue } from '~/services/document/components/Fields/hooks/useFormatFieldValue';
import { ListingOffersOutletContextType } from '../../pages/ListingSetup/ListingSetupContext';
import { OfferWithContract } from '../../pages/ListingSetup/ListingWithOffersProvider';
import { UserAvatar } from '../User/UserAvatar';
import { OfferCardKeyTermFields } from './OfferCardKeyTermFields';
import { OfferNoteTooltip } from './OfferNoteTooltip';

export interface OfferBaseProps {
  offerWithContract: OfferWithContract;
  offerCardSections: ListingOffersOutletContextType['offerCardSections'];
}

export type OfferCardProps = OfferBaseProps;

export const OfferCard = ({
  offerWithContract,
  offerCardSections,
}: OfferCardProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isOpen, onClose, onOpen } = useDisclosure();

  const formattedSubmittedAt = useMemo(() => {
    return formatDistanceToNow(
      // TODO: Consider adding Date scalar to GQL types
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      new Date(offerWithContract.offer.submittedAt),
      { addSuffix: true }
    ).toUpperCase();
  }, [offerWithContract.offer.submittedAt]);

  const buyersAgentUser = offerWithContract?.offer?.buyersAgent?.user;

  return (
    <Card
      _hover={{
        cursor: 'pointer',
      }}
      size="lg"
      width="100%"
      onClick={() => {
        navigate({
          pathname: offerWithContract.offer.id,
          search: location.search,
        });
      }}
    >
      <CardHeader
        borderBottom="1px solid"
        borderBottomColor="border.divider"
        p={4}
        pb={6}
      >
        <Flex
          alignItems={{ base: 'flex-start', md: 'center' }}
          direction={{ base: 'column', md: 'row' }}
          gap={2}
          justifyContent="space-between"
        >
          <Flex alignItems="center" gap={3}>
            <UserAvatar
              user={
                buyersAgentUser
                  ? buyersAgentUser
                  : {
                      fullName: offerWithContract.buyersAgentName,
                    }
              }
            />
            <Stack spacing={0}>
              <Text fontWeight="500">{offerWithContract.buyersAgentName}</Text>
              <Text color="whiteAlpha.600" fontSize="xs">
                {offerWithContract.officeName}
              </Text>
            </Stack>
          </Flex>
          <Spacer />
          <Flex alignItems="center" gap={2}>
            {offerWithContract.offer.noteToListingAgent && (
              <OfferNoteTooltip
                isOpen={isOpen}
                note={offerWithContract.offer.noteToListingAgent}
                onClose={onClose}
                onOpen={onOpen}
              />
            )}
            {offerWithContract.offer.submittedAt && (
              <Text
                color="whiteAlpha.600"
                fontSize="xs"
                fontWeight="500"
                textTransform="uppercase"
              >
                {formattedSubmittedAt} {/* Use the memoized value here */}
              </Text>
            )}
          </Flex>
        </Flex>
      </CardHeader>
      <CardBody p={{ base: 4, md: 7 }}>
        <Flex flexDirection="column" gap={2}>
          {offerCardSections && (
            <OfferCardKeyTermFields
              cardSections={offerCardSections}
              fieldValues={offerWithContract.offer.contract?.fieldValues}
            />
          )}
          <Flex alignItems="center">
            <Text
              color="whiteAlpha.600"
              fontSize="12px"
              fontWeight="500"
              letterSpacing={1}
              textTransform="uppercase"
            >
              Validation
            </Text>
            <Spacer />
            {/* <Text fontSize="sm" fontWeight="semibold">

            </Text> */}
            <Flex alignItems="center" gap={2}>
              {!!offerWithContract.offer.contract?.validations?.length ? (
                <>
                  <Icon as={TriangleAlert} boxSize="16px" color="red.500" />
                  <Text fontSize="sm" fontWeight="500">
                    {offerWithContract.offer.contract?.validations?.length}{' '}
                    issue
                    {offerWithContract.offer.contract?.validations?.length > 1
                      ? 's'
                      : ''}
                  </Text>
                </>
              ) : (
                <>
                  <Icon as={Check} boxSize="16px" color="green.500" />
                  <Text fontSize="sm">No issues</Text>
                </>
              )}
            </Flex>
          </Flex>
        </Flex>
      </CardBody>
    </Card>
  );
};

export interface OfferFieldProps {
  field: FieldFragment;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
}

export const OfferField = ({ field, value }: OfferFieldProps) => {
  const formattedValue = useFormatFieldValue({
    field,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    value,
  });

  return (
    <Flex alignItems="center" data-testid="offer-field">
      <Text
        color="whiteAlpha.600"
        fontSize="12px"
        fontWeight="500"
        letterSpacing={1}
        textTransform="uppercase"
      >
        {field.label}
      </Text>
      <Spacer />
      <Text fontSize="sm" fontWeight="semibold">
        {formattedValue || 'N/A'}
      </Text>
    </Flex>
  );
};
