import {
  useHasSignedListingGuestbookQuery,
  useListingActivitiesQuery,
  usePublicListingAnnouncementsQuery,
  usePublicListingComparableSetQuery,
  usePublicListingDocumentUploadsQuery,
  usePublicListingQuery,
  usePublicPreferencesQuery,
} from '@client/graphql/__generated__/main-operations';
import { UsState } from '@client/graphql/__generated__/types';
import { useMemo } from 'react';
import {
  fakeComparables,
  fakeListingActivities,
  fakeListingDocumentUploads,
} from '~/apps/consumer/utils/storefront.fakes';
import { useProdFeatureFlagEnabled } from '~/common/hooks/useProdFeatureFlagEnabled';
import { useAppContext } from '~/services/main/contexts/AppContext';

export const useStorefrontQueries = (propertySlug: string) => {
  const { isAuthenticated, user: currentUser } = useAppContext();
  const isTrackableListingPackageEnabled = useProdFeatureFlagEnabled(
    'trackable-listing-package'
  );

  const { data, loading } = usePublicListingQuery({
    variables: {
      input: { propertySlug },
    },
    skip: !propertySlug,
  });
  const listing = data?.publicListing;
  const { data: preferencesData } = usePublicPreferencesQuery({
    variables: {
      input: {
        usState: listing?.property?.state || UsState.GLOBAL,
      },
    },
  });

  const isPremiumListing = listing?.isPremium;

  // TODO: ALL admins of the listing should be able to access the storefront without signing the Guestbook, not just agent and coagent.
  const isCurrentUserStorefrontOwner = Boolean(
    (listing?.listingAgent?.user?.id &&
      listing.listingAgent.user.id === currentUser?.id) ||
      (listing?.coListingAgent?.user?.id &&
        listing.coListingAgent.user.id === currentUser?.id)
  );

  const { data: useHasSignedListingGuestbookData } =
    useHasSignedListingGuestbookQuery({
      variables: {
        input: {
          id: listing?.id as string,
        },
      },
      skip:
        !isPremiumListing ||
        !listing?.id ||
        !isAuthenticated ||
        isCurrentUserStorefrontOwner,
    });

  const hasSignedListingGuestbook =
    useHasSignedListingGuestbookData?.hasSignedListingGuestbook?.hasSigned ??
    false;

  const isGatedBehindSigningGuestbook = isTrackableListingPackageEnabled
    ? !(
        (isAuthenticated && hasSignedListingGuestbook) ||
        isCurrentUserStorefrontOwner
      )
    : false;

  const { data: listingComparablesData, loading: listingComparablesLoading } =
    usePublicListingComparableSetQuery({
      variables: {
        input: {
          listingId: listing?.id || '',
        },
      },
      skip: !listing || !isPremiumListing,
    });

  const { data: listingActivitiesQuery } = useListingActivitiesQuery({
    variables: {
      input: {
        id: listing?.id || '',
      },
    },
    skip: !listing?.id || !isPremiumListing || isGatedBehindSigningGuestbook,
  });

  const { data: existingDocData } = usePublicListingDocumentUploadsQuery({
    variables: {
      input: {
        listingId: listing?.id || '',
      },
    },
    skip: !listing || !isPremiumListing,
  });

  const perhapsGatedDocUploads = useMemo(() => {
    return isGatedBehindSigningGuestbook
      ? fakeListingDocumentUploads(
          existingDocData?.publicListingDocumentUploads || []
        )
      : existingDocData?.publicListingDocumentUploads;
  }, [isGatedBehindSigningGuestbook, existingDocData]);

  const sortedDocs = useMemo(() => {
    return Array.from(perhapsGatedDocUploads || []).sort((a, b) => {
      if (a?.listingDocumentType?.usState === UsState.GLOBAL) {
        return -1;
      }
      if (b?.listingDocumentType?.usState === UsState.GLOBAL) {
        return 1;
      }

      return 0;
    });
  }, [perhapsGatedDocUploads]);

  const { data: listingAnnouncements } = usePublicListingAnnouncementsQuery({
    variables: {
      input: {
        listingId: listing?.id || '',
      },
    },
    skip: !listing || !isPremiumListing,
  });

  const perhapsGatedListingActivities = useMemo(() => {
    return isGatedBehindSigningGuestbook
      ? fakeListingActivities()
      : listingActivitiesQuery?.listingActivities;
  }, [isGatedBehindSigningGuestbook, listingActivitiesQuery]);

  const listingComparableSet =
    listingComparablesData?.publicListingComparableSet;

  const numComps = listingComparableSet?.listingComparables?.length;
  const perhapsGatedListingComparableSet = useMemo(() => {
    return isGatedBehindSigningGuestbook
      ? {
          ...listingComparableSet,
          listingComparables: numComps ? fakeComparables(numComps) : [],
        }
      : listingComparableSet;
  }, [isGatedBehindSigningGuestbook, listingComparableSet, numComps]);

  return {
    listing,
    listingComparableSet: perhapsGatedListingComparableSet,
    listingDocumentUploads: sortedDocs,
    loading,
    listingAnnouncements: listingAnnouncements?.publicListingAnnouncements,
    preferences: preferencesData?.publicPreferences,
    listingActivities: perhapsGatedListingActivities,
    isGatedBehindSigningGuestbook,
    storefrontOverviewContext: {
      listingComparableSet,
      preferenceValues: listing?.listingPreferences,
      isLoading: loading || listingComparablesLoading,
    },
  };
};
