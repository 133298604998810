import { Divider, Flex, Heading, Text } from '@chakra-ui/react';
import {
  MlsListingPropertyObject,
  PublicListingFragment,
  PublicOpenHouseFragment,
  StorefrontSubscriptionReason,
} from '@client/graphql/__generated__/types';
import { useCallback, useState } from 'react';
import { useProdFeatureFlagEnabled } from '~/common/hooks/useProdFeatureFlagEnabled';
import { numberFormatter } from '~/common/utils/formatter';
import { useAppContext } from '~/services/main/contexts/AppContext';
import { ListingActivityProvider } from '../../components/Activity/ListingActivityProvider';
import { PremiumTag } from '../../components/PremiumTag/PremiumTag';
import { OpenHouseTag } from '../../components/Storefront/OpenHouseTag';
import { VerifyUserModal } from '../../components/VerifyUser/VerifyUserModal';
import { useListingActivityContext } from '../../contexts/ListingActivityContext';
import { getDaysOnMarketText } from '../../utils/storefront.utils';
import { GetAlerts } from './GetAlerts';
import { useStorefrontContext } from './StorefrontContext';

interface StorefrontSummaryComponentProps {
  isFollowingListing?: boolean;
  listing: PublicListingFragment;
  handleClickUnsubscribe?: () => Promise<void>;
  handleClickSubscribe?: () => Promise<void>;
  showUserVerifyModal?: () => void;
  isPreview?: boolean;
  isPremium: boolean;
}

const StorefrontSummaryComponent = ({
  isFollowingListing = false,
  listing,
  handleClickUnsubscribe,
  handleClickSubscribe,
  showUserVerifyModal,
  isPreview,
  isPremium,
}: StorefrontSummaryComponentProps) => {
  const mlsPropertyInfo = listing?.mlsListing
    ?.property as MlsListingPropertyObject;

  const openHousesEnabled = useProdFeatureFlagEnabled('open-houses');
  const nextOpenHouse: PublicOpenHouseFragment | undefined =
    listing?.currentAndUpcomingOpenHouses?.[0];

  return (
    <Flex
      alignItems={{ base: 'flex-start', lg: 'center' }}
      direction={{ base: 'column', lg: 'row' }}
      justifyContent="space-between"
      width="100%"
    >
      <Flex direction="column">
        <Flex align="start" direction="column" gap={4}>
          <Flex direction="row" gap={2}>
            {isPremium && <PremiumTag />}
            {openHousesEnabled && nextOpenHouse && (
              <OpenHouseTag openHouse={nextOpenHouse} />
            )}
          </Flex>
          <Heading as="h4" fontWeight="semibold" p={0} size="xxs" zIndex={1}>
            {listing.mlsListing?.address?.streetNumberText}{' '}
            {listing.mlsListing?.address?.streetName}{' '}
            {listing.mlsListing?.address?.unit
              ? `${listing.mlsListing?.address?.unit} `
              : ''}
            <Text as="span" fontWeight="400" p={0} size="xxs" zIndex={1}>
              {listing.mlsListing?.address?.city}
              {', '}
              {listing.mlsListing?.address?.state}{' '}
              {listing.mlsListing?.address?.postalCode}
            </Text>
          </Heading>
        </Flex>
        <Flex gap={8} mt={0}>
          <Text color="whiteAlpha.700" fontSize="md">
            {mlsPropertyInfo?.bedrooms} beds &bull;&nbsp;
            {mlsPropertyInfo?.bathrooms} baths &bull;&nbsp;
            {numberFormatter(mlsPropertyInfo?.area, {
              maxPrecision: 0,
            })}{' '}
            sqft &bull;&nbsp;
            {getDaysOnMarketText(listing?.mlsListing?.mls?.daysOnMarket)}
          </Text>
        </Flex>
      </Flex>
      <Flex alignItems="center">
        <Flex flexDirection="column">
          <Heading as="h2" size="sm">
            {numberFormatter(listing?.mlsListing?.listPrice, {
              maxPrecision: 0,
              type: 'currency',
            })}
          </Heading>
          {isPremium && (listing.followerCount || 0) > 0 && (
            <Heading
              color="whiteAlpha.700"
              size="smallUppercase"
              whiteSpace="nowrap"
            >
              {listing.followerCount} subscribers
            </Heading>
          )}
        </Flex>
        {isPremium && (
          <>
            <Divider h={20} ml={4} mr={4} orientation="vertical" />
            <GetAlerts
              handleClickSubscribe={handleClickSubscribe}
              handleClickUnsubscribe={handleClickUnsubscribe}
              isFollowingListing={isFollowingListing}
              isPreview={isPreview}
              listingId={listing.id}
              showUserVerifyModal={showUserVerifyModal}
            />
          </>
        )}
      </Flex>
    </Flex>
  );
};

const NonAuthenticatedStorefrontSummary = ({
  listing,
  isPremium,
}: {
  listing: PublicListingFragment;
  isPremium: boolean;
}) => {
  const [showVerifyUserModal, setShowVerifyUserModal] = useState(false);

  const showUserVerifyModal = useCallback(() => {
    setShowVerifyUserModal(true);
  }, []);

  // TODO: if user is done authenticatating in VerifyUserModal
  // we should automatically trigger the "Get alerts/follow" action
  // they originally clicked on

  return (
    <>
      <StorefrontSummaryComponent
        isPremium={isPremium}
        listing={listing}
        showUserVerifyModal={showUserVerifyModal}
      />
      <VerifyUserModal
        setShowVerifyUserModal={setShowVerifyUserModal}
        showVerifyUserModal={showVerifyUserModal}
      />
    </>
  );
};

const AuthenticatedStorefrontSummary = ({
  listing,
  isPremium,
  isPreview,
}: {
  listing: PublicListingFragment;
  isPremium: boolean;
  isPreview?: boolean;
}) => {
  const { followListing, unfollowListing, isFollowingListing } =
    useListingActivityContext();

  const handleClickSubscribe = async () => {
    await followListing(StorefrontSubscriptionReason.GET_ALERTS);
  };

  const handleClickUnsubscribe = async () => {
    await unfollowListing();
  };

  return (
    <StorefrontSummaryComponent
      handleClickSubscribe={handleClickSubscribe}
      handleClickUnsubscribe={handleClickUnsubscribe}
      isFollowingListing={isFollowingListing}
      isPremium={isPremium}
      isPreview={isPreview}
      listing={listing}
    />
  );
};

export const StorefrontSummary = ({
  isPreview,
  isPremium,
}: {
  isPreview?: boolean;
  isPremium: boolean;
}) => {
  const { isAuthenticated } = useAppContext();
  const { listing } = useStorefrontContext();

  if (isAuthenticated) {
    return (
      <ListingActivityProvider>
        <AuthenticatedStorefrontSummary
          isPremium={isPremium}
          isPreview={isPreview}
          listing={listing}
        />
      </ListingActivityProvider>
    );
  }

  return (
    <NonAuthenticatedStorefrontSummary
      isPremium={isPremium}
      listing={listing}
    />
  );
};
