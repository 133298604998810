import { Button, Divider, Flex, Link, Stack, Text } from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router';
import { NavLink } from 'react-router-dom';
import { NewText } from '~/common/components/NewText/NewText';
import { useIsMobile } from '~/common/hooks/useIsMobile';
import { IndigoLogo } from '~/common/icons/IndigoLogo';
import { HOMEPAGE_URL } from '~/common/url';
import { useAppContext } from '~/services/main/contexts/AppContext';

type Props = {
  showSignUpLogin?: boolean;
};

export const SimpleHeader = ({ showSignUpLogin = true }: Props) => {
  const isMobile = useIsMobile();
  const { isAuthenticated } = useAppContext();
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Flex
      alignItems="center"
      height="40px"
      justifyContent={isMobile && !showSignUpLogin ? 'center' : 'space-between'}
      my={{ base: 3, lg: 5 }}
      px={{ base: 0, lg: 8 }}
      width="100%"
    >
      <NavLink to={isAuthenticated ? '/' : HOMEPAGE_URL}>
        <IndigoLogo />
      </NavLink>
      {!isAuthenticated && showSignUpLogin && (
        <Stack alignItems="center" direction="row" spacing={4}>
          {location.pathname !== '/map' && (
            <>
              <Link
                _hover={{ textDecoration: 'none', color: 'whiteAlpha.700' }}
                color="white"
                textAlign={{ base: 'center', lg: 'left' }}
                onClick={() => navigate('/map')}
              >
                <Text display={{ base: 'none', md: 'inline' }}>
                  Explore listings
                </Text>
                <Text display={{ base: 'inline', md: 'none' }}>Explore</Text>
                <NewText />
              </Link>
              <Divider
                borderColor="whiteAlpha.300"
                height={8}
                opacity={1}
                orientation="vertical"
              />
            </>
          )}
          <Link
            _hover={{ textDecoration: 'none', color: 'whiteAlpha.700' }}
            color="white"
            onClick={() =>
              navigate({
                pathname: '/auth/login',
                search: `?next=${location.pathname}`,
              })
            }
          >
            Login
          </Link>
          <Button
            variant="rounded"
            onClick={() =>
              navigate({
                pathname: '/auth/login',
                search: `?next=${location.pathname}`,
              })
            }
          >
            Sign Up
          </Button>
        </Stack>
      )}
    </Flex>
  );
};
