import { Input, InputProps } from '@chakra-ui/react';
import { forwardRef, useEffect, useState } from 'react';
import CurrencyInputField, {
  CurrencyInputProps as CurrencyInputFieldProps,
} from 'react-currency-input-field';

export type CurrencyInputProps = Omit<InputProps, 'value' | 'onChange'> &
  Omit<CurrencyInputFieldProps, 'size'>;

export const CurrencyInput = forwardRef<HTMLInputElement, CurrencyInputProps>(
  ({ size, onValueChange, onBlur, onFocus, value, ...props }, ref) => {
    const [localValue, setLocalValue] =
      useState<CurrencyInputFieldProps['value']>(value);
    const [isFocused, setIsFocused] = useState(false);

    useEffect(() => {
      if (!isFocused) {
        setLocalValue(value);
      }
    }, [isFocused, value, setLocalValue]);

    return (
      <CurrencyInputField
        ref={ref}
        customInput={Input}
        prefix="$"
        size={size as unknown as number}
        value={localValue}
        onBlur={(e) => {
          setIsFocused(false);
          onBlur && onBlur(e);
        }}
        onFocus={(e) => {
          setIsFocused(true);
          onFocus && onFocus(e);
        }}
        onValueChange={(val) => {
          setLocalValue(val);
          onValueChange && onValueChange(val);
        }}
        {...props}
      />
    );
  }
);
