import { makeUniqueId } from '@apollo/client/utilities';
import { useToast } from '@chakra-ui/react';
import { MutableRefObject, useCallback, useEffect, useState } from 'react';
import { FormField } from '../../FormFields/types';
import { DocumentMapperContextValue } from '../context';

enum ShortcutKeyCodes {
  KeyX = 'KeyX',
  KeyC = 'KeyC',
  KeyV = 'KeyV',
  KeyS = 'KeyS',
  KeyZ = 'KeyZ',
  Backspace = 'Backspace',
}
const shortcutKeyCodes = Object.keys(ShortcutKeyCodes) as Array<
  keyof typeof ShortcutKeyCodes
>;

interface UseKeyboardShortcutsOptions
  extends Pick<
    DocumentMapperContextValue,
    | 'visiblePage'
    | 'formFieldsById'
    | 'pageMousePositionRef'
    | 'setSelectedFormFieldId'
    | 'setMoveableTargets'
    | 'moveableTargets'
    | 'onFormFieldDelete'
    | 'onFormFieldChange'
  > {
  history: MutableRefObject<(FormField | FormField[])[]>;
  onSave: () => void;
}

export const useKeyboardShortcuts = (options: UseKeyboardShortcutsOptions) => {
  const {
    history,
    visiblePage,
    moveableTargets,
    setMoveableTargets,
    setSelectedFormFieldId,
    formFieldsById,
    pageMousePositionRef,
    onFormFieldChange,
    onFormFieldDelete,
    onSave,
  } = options;
  const toast = useToast();
  const [copiedFormFields, setCopiedFormFields] = useState<FormField[]>();

  const onCut = useCallback(() => {
    if (moveableTargets?.length) {
      setCopiedFormFields(
        moveableTargets?.map(({ formField }) => ({
          ...formFieldsById[formField.id],
        }))
      );

      toast({
        status: 'info',
        isClosable: true,
        duration: 2000,
        title: `${moveableTargets?.length} fields cut ready for paste`,
      });
    }
  }, [formFieldsById, moveableTargets, toast]);

  const onCopy = useCallback(() => {
    const filtered = moveableTargets.filter(
      (target) => target.type === 'form-field'
    );

    const copiedFormFields = filtered.map(({ formField }) => {
      return {
        ...formFieldsById[formField.id],
        id: makeUniqueId('copied-field'),
        isNew: true,
      };
    });

    if (copiedFormFields?.length) {
      setCopiedFormFields(copiedFormFields);

      void navigator.clipboard.writeText(JSON.stringify(copiedFormFields));

      toast({
        status: 'info',
        isClosable: true,
        duration: 2000,
        title: `Copied ${copiedFormFields?.length} fields`,
      });
    }
  }, [formFieldsById, moveableTargets, toast]);

  const onPaste = useCallback(async () => {
    let pastedFormFields = copiedFormFields;

    try {
      const text = await navigator.clipboard.readText();
      pastedFormFields = JSON.parse(text) as FormField[];
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Error pasting form fields', e);
      pastedFormFields = copiedFormFields;
    }

    if (pastedFormFields?.length) {
      pastedFormFields.reduce(
        (prev, formField) => {
          let newX =
            prev?.newX ||
            pageMousePositionRef.current.x /
              pageMousePositionRef.current.width ||
            formField.x;
          let newY =
            prev?.newY ||
            pageMousePositionRef.current.y /
              pageMousePositionRef.current.height ||
            formField.y;

          if (prev) {
            const diffBetweenPrevX = prev.formField.x - formField.x;
            const diffBetweenPrevY = prev.formField.y - formField.y;

            newX = newX - diffBetweenPrevX;
            newY = newY - diffBetweenPrevY;
          }

          const diffX = formField.x - newX;
          const diffY = formField.y - newY;

          onFormFieldChange({
            ...formField,
            x: newX,
            y: newY,
            labelField: formField.labelField
              ? {
                  ...formField.labelField,
                  x: formField.labelField?.x - diffX,
                  y: formField.labelField?.y - diffY,
                }
              : undefined,
            pageNumber:
              pageMousePositionRef.current.pageNumber ||
              visiblePage?.pageNumber ||
              formField.pageNumber,
            documentIndex:
              visiblePage?.documentIndex || formField.documentIndex,
          });

          return {
            formField,
            newX,
            newY,
          };
        },
        null as { formField: FormField; newX: number; newY: number } | null
      );
    }
  }, [
    copiedFormFields,
    onFormFieldChange,
    pageMousePositionRef,
    visiblePage?.documentIndex,
    visiblePage?.pageNumber,
  ]);

  const onDelete = useCallback(() => {
    if (moveableTargets?.length) {
      onFormFieldDelete(moveableTargets?.map(({ formField }) => formField));
      setMoveableTargets(undefined);
      setSelectedFormFieldId(undefined);
    }
  }, [
    moveableTargets,
    onFormFieldDelete,
    setMoveableTargets,
    setSelectedFormFieldId,
  ]);

  const onUndo = useCallback(() => {
    setMoveableTargets(undefined);
    setSelectedFormFieldId(undefined);

    const prevFormField = history.current.pop();
    if (prevFormField) {
      onFormFieldChange(prevFormField);
    }
  }, [history, onFormFieldChange, setMoveableTargets, setSelectedFormFieldId]);

  useEffect(() => {
    const keyDown = (event: KeyboardEvent) => {
      const code = event.code;
      const metaKey = event.metaKey;
      const elementType = event.target?.constructor.name;

      if (
        ['HTMLTextAreaElement', 'HTMLInputElement', 'HTMLElement'].includes(
          elementType || ''
        ) ||
        !metaKey ||
        !shortcutKeyCodes.includes(code as ShortcutKeyCodes)
      ) {
        return;
      }

      if (code === ShortcutKeyCodes.KeyS) {
        event.stopPropagation();
        event.preventDefault();
      }

      switch (code) {
        case ShortcutKeyCodes.KeyX:
          return onCut();
        case ShortcutKeyCodes.KeyS:
          return onSave();
        case ShortcutKeyCodes.KeyC:
          return onCopy();
        case ShortcutKeyCodes.KeyV:
          return onPaste();
        case ShortcutKeyCodes.Backspace:
          return onDelete();
        case ShortcutKeyCodes.KeyZ:
          return onUndo();
      }
    };

    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    window.addEventListener('keydown', keyDown);

    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    return () => window.removeEventListener('keydown', keyDown);
  }, [onCopy, onCut, onDelete, onPaste, onSave, onUndo, options]);
};
