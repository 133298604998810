import {
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightElement,
} from '@chakra-ui/react';
import { ComponentProps, useEffect, useState } from 'react';

type ExtraProps = {
  rightElement?: React.ReactNode;
};

export const sanitizePhoneNumber = (phoneNumber: string) => {
  return phoneNumber.replace(/\D/g, '').slice(0, 10);
};

export const formatPhoneNumber = (phoneNumber: string) => {
  return phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
};

export const PhoneNumberInputGroup = ({
  onChange,
  value,
  rightElement,
  ...props
}: ComponentProps<typeof Input> & ExtraProps) => {
  const [phoneNumber, setPhoneNumber] = useState<string>();

  useEffect(() => {
    if (value) {
      const sanitizedNumber = sanitizePhoneNumber(value.toString());
      setPhoneNumber(formatPhoneNumber(sanitizedNumber));
    }
  }, [value]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const sanitizedNumber = sanitizePhoneNumber(e.currentTarget.value);
    const formattedNumber = formatPhoneNumber(sanitizedNumber);

    setPhoneNumber(formattedNumber);

    // Create a new event-like object with the sanitized value
    const sanitizedEvent: React.ChangeEvent<HTMLInputElement> = {
      ...e,
      target: { ...e.target, value: sanitizedNumber },
      currentTarget: { ...e.currentTarget, value: sanitizedNumber },
    };

    onChange?.(sanitizedEvent);
  };

  return (
    <InputGroup>
      <InputLeftAddon children="+1" />
      <Input
        isInvalid={
          // we don't want to show the invalid state if the phone number is undefined
          phoneNumber !== undefined &&
          !/^\(\d{3}\)\s\d{3}-\d{4}$/.test(phoneNumber || '')
        }
        minLength={10}
        placeholder="Phone Number"
        type="tel"
        value={phoneNumber}
        onChange={handleInputChange}
        {...props}
      />
      {rightElement && (
        <InputRightElement height="100%">{rightElement}</InputRightElement>
      )}
    </InputGroup>
  );
};
