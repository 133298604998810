import { FieldType } from '@client/graphql/__generated__/types';
import { useMemo } from 'react';
import { FormField } from '../../FormFields/types';

export interface UseFormFieldsOptions {
  formFields: FormField[];
  documentIndex: number;
  pageNumber: number;
}

export const useFormFields = ({
  formFields,
  documentIndex,
  pageNumber,
}: UseFormFieldsOptions) => {
  const tabIndices = useMemo(() => {
    return formFields?.reduce(
      (output, formField, index) => {
        output[formField.id] = index + 1;

        return output;
      },
      {} as Record<string, number>
    );
  }, [formFields]);

  const filtered = useMemo(() => {
    return formFields?.filter((formField) => {
      return (
        formField.pageNumber === pageNumber &&
        formField.documentIndex === documentIndex
      );
    });
  }, [formFields, documentIndex, pageNumber]);

  const radioFormFields = useMemo(() => {
    return filtered
      ?.filter((formField) => {
        return formField.fieldType === FieldType.RADIO;
      })
      .reduce(
        (output, formField) => {
          if (formField.field?.group) {
            const group = output[formField.field?.group];

            if (group) {
              output[formField.field?.group].push(formField);
            } else {
              output[formField.field?.group] = [formField];
            }

            return output;
          }
        },
        {} as Record<string, FormField[]>
      );
  }, [filtered]);

  const pageFormFields = useMemo(() => {
    return filtered?.filter((formField) => {
      return formField.fieldType !== FieldType.RADIO;
    });
  }, [filtered]);

  return {
    radioFormFields,
    pageFormFields,
    tabIndices,
  };
};
