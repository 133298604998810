import {
  Button,
  HStack,
  LightMode,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { DocumentSignatureMapper } from '~/services/document/components/DocumentSignatureMapper';
import { Signer } from '~/services/document/components/DocumentSignatureMapper/DocumentSignatureMapper';
import { useOfferFlowContext } from './OfferFlowContext';

export const PrepareForBuyerSignature = () => {
  const {
    latestOffer,
    refetchLatestOffer,
    signatureFormFields,
    setSignatureFormFields,
    setShowSendToBuyerForSignatureModal,
  } = useOfferFlowContext();
  const navigate = useNavigate();
  const offerId = latestOffer?.id;
  const contractId = latestOffer?.contract?.id;

  useEffect(() => {
    void refetchLatestOffer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const signers = useMemo(() => {
    if (!latestOffer) return [];

    return [latestOffer.buyersAgent?.user, latestOffer.primaryBuyer]
      .concat(latestOffer.secondaryBuyers || [])
      .filter(Boolean) as Signer[];
  }, [latestOffer]);

  const contractSignatureArgs = useMemo(() => {
    if (!contractId || !offerId) return null;

    return {
      contractId,
      offerId,
      buyerSignatures: true,
      buyersAgentSignatures: true,
    };
  }, [contractId, offerId]);

  const saveDisabled = useMemo(() => {
    return signatureFormFields.some((field) => !field.signerId);
  }, [signatureFormFields]);

  return contractSignatureArgs ? (
    <>
      <LightMode>
        <Modal
          blockScrollOnMount
          isCentered
          autoFocus={false}
          isOpen={true}
          size="6xl"
          onClose={() => navigate('../')}
        >
          <ModalOverlay />
          <ModalContent
            height={{ base: '85vh', md: '95vh' }}
            maxHeight={{ base: '85vh', md: '95vh' }}
            maxWidth={{ base: '95vw', md: '90vw', xl: '1200px' }}
            width={{ base: '95vw', md: '90vw', xl: '80vw' }}
          >
            <ModalHeader color="black">
              <HStack>
                <Text flexGrow={1} fontSize={{ base: 'sm', md: 'lg' }}>
                  Prepare for buyer signature
                </Text>
                <HStack>
                  <Button
                    size={{ base: 'xs', md: 'md' }}
                    variant="outline"
                    onClick={() => navigate('../')}
                  >
                    Cancel
                  </Button>
                  <Button
                    isDisabled={saveDisabled}
                    size={{ base: 'xs', md: 'md' }}
                    onClick={() => setShowSendToBuyerForSignatureModal(true)}
                  >
                    Next
                  </Button>
                </HStack>
              </HStack>
            </ModalHeader>
            <ModalBody
              borderRadius="sm"
              height="100%"
              overflow="hidden"
              p={0}
              position="relative"
              width="100%"
            >
              <DocumentSignatureMapper
                contractSignatureFieldArgs={contractSignatureArgs}
                pdfFiles={[
                  {
                    url: `/documents/contracts/${contractSignatureArgs.contractId}/pdf?offerId=${contractSignatureArgs.offerId}`,
                  },
                ]}
                signers={signers}
                onSignatureFormFieldsChange={setSignatureFormFields}
              />
            </ModalBody>
          </ModalContent>
        </Modal>
      </LightMode>
    </>
  ) : null;
};
