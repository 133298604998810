import {
  Button,
  HStack,
  LightMode,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { useIndigoContractsForCurrentUserQuery } from '@client/graphql/__generated__/main-operations';
import { DocumentType } from '@client/graphql/__generated__/types';
import { FC, useCallback, useRef, useState } from 'react';
import {
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
} from 'react-router';
import { PrepareIndigoContract } from '../../components/IndigoContract/PrepareIndigoContract';
import { SendIndigoContractModal } from '../../components/IndigoContract/SendIndigoContractModal';

export const AgencyAgreementContinuePage: FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { state } = useLocation();
  const { refetch } = useOutletContext<{ refetch: () => Promise<void> }>();
  const { indigoContractId } = useParams();
  const mutationRef = useRef<() => Promise<void>>();
  const [saving, setSaving] = useState(false);
  const [ready, setReady] = useState(false);
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const isPrepareIndigoContract = state?.isPrepareIndigoContract as boolean;
  const prepareIndigoContractModalDisclosure = useDisclosure({
    defaultIsOpen: isPrepareIndigoContract,
  });

  const { data } = useIndigoContractsForCurrentUserQuery({
    variables: {
      filters: {
        id: indigoContractId,
      },
      includeContractDocuments: true,
      includeFieldValues: true,
    },
    skip: !indigoContractId,
  });

  const indigoContract = data?.indigoContractsForCurrentUser?.results?.[0];

  const onClose = useCallback(() => {
    void refetch?.().finally(() => navigate('/dashboard'));
  }, [navigate, refetch]);

  return indigoContractId && indigoContract ? (
    <>
      <SendIndigoContractModal
        buttonText="Continue"
        documentType={DocumentType.AGENCY_AGREEMENT}
        indigoContract={indigoContract}
        isOpen={true}
        title="Continue Agreement"
        onClose={onClose}
        onContinue={() => {
          prepareIndigoContractModalDisclosure.onOpen();
        }}
      />
      <LightMode>
        <Modal
          isCentered
          autoFocus={false}
          blockScrollOnMount={true}
          isOpen={prepareIndigoContractModalDisclosure.isOpen}
          scrollBehavior="inside"
          size={{ base: 'full', md: '6xl' }}
          onClose={prepareIndigoContractModalDisclosure.onClose}
        >
          <ModalOverlay />
          <ModalContent
            maxWidth="1200px !important"
            minHeight={{ base: 'auto', md: '90vh' }}
            minWidth="auto"
            overflow="hidden"
            width="100%"
          >
            <ModalHeader color="black">
              <HStack justifyContent="space-between" width="100%">
                <Text>Prepare Buyer Agreement</Text>

                <HStack>
                  <Button
                    colorScheme="gray"
                    size="sm"
                    onClick={prepareIndigoContractModalDisclosure.onClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    disabled={!ready}
                    isLoading={saving}
                    size="sm"
                    onClick={() => {
                      setSaving(true);
                      void mutationRef.current?.().finally(() => {
                        setSaving(false);
                      });
                    }}
                  >
                    Sign and send to buyer
                  </Button>
                </HStack>
              </HStack>
            </ModalHeader>
            <ModalBody
              height={{
                base: 'calc(100dvh - 75px) !important',
                md: '100% !important',
              }}
              p={0}
            >
              <PrepareIndigoContract
                indigoContractId={indigoContractId}
                mutationRef={mutationRef}
                onClose={onClose}
                onReady={() => {
                  if (ready) return;
                  setReady(true);
                }}
              />
            </ModalBody>
          </ModalContent>
        </Modal>
      </LightMode>
    </>
  ) : null;
};
