import { useSearchMlsListingsWithFiltersLazyQuery } from '@client/graphql/__generated__/main-operations';
import {
  ListingForSearchMlsListingFragment,
  MlsListingFiltersInput,
  MlsListingFragment,
  MlsListingStatus,
} from '@client/graphql/__generated__/types';
import { GeocodingFeature } from '@mapbox/search-js-core';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { MapRef } from 'react-map-gl';
import { DEFAULT_POSITION, ZOOM_LEVEL_MARKER_CHANGE } from './constants';
import { getMatchingListingForSearchResult, SearchResult } from './helpers';

export interface MlsListingWithListing {
  mlsListing: MlsListingFragment;
  listing?: ListingForSearchMlsListingFragment;
}

interface ExploreContextType {
  listings: MlsListingWithListing[];
  loading: boolean;
  filters: MlsListingFiltersInput;
  updateFilters: (filters: Partial<MlsListingFiltersInput>) => void;
  clearFilters: () => void;
  selectedListing: MlsListingWithListing | null;
  setSelectedListing: (listing: MlsListingWithListing | null) => void;
  totalCount: number;
  setTotalCount: (totalCount: number) => void;
  searchResult: GeocodingFeature | null;
  setSearchResult: (searchResult: GeocodingFeature | null) => void;
  shouldOpenPopup: boolean;
  setShouldOpenPopup: (shouldOpenPopup: boolean) => void;
  mapRef: React.RefObject<MapRef>;
  handleRetrieve: (result: GeocodingFeature) => void;
  utmParams?: { [key: string]: string };
  hasFilters: boolean;
  showClosedStatus: boolean;
}

export const ExploreContext = createContext<ExploreContextType>(
  {} as ExploreContextType
);

export const ExploreProvider = ({
  children,
  utmParams,
  showClosedStatus = false,
}: {
  children: React.ReactNode;
  utmParams?: { [key: string]: string };
  showClosedStatus?: boolean;
}) => {
  const mapRef = useRef<MapRef>(null);

  // Used for if we need to wait for data to load before opening the popup
  const [shouldOpenPopup, setShouldOpenPopup] = useState(false);
  const [searchResult, setSearchResult] = useState<GeocodingFeature | null>(
    null
  );
  const [totalCount, setTotalCount] = useState(0);
  const [filters, setFilters] = useState<MlsListingFiltersInput>({});

  const [listings, setListings] = useState<MlsListingWithListing[]>([]);
  const [selectedListing, setSelectedListing] =
    useState<MlsListingWithListing | null>(null);

  const [searchMlsListingsWithFilters, { loading }] =
    useSearchMlsListingsWithFiltersLazyQuery();

  const updateFilters = useCallback(
    (filters: Partial<MlsListingFiltersInput>) => {
      setFilters((prev) => ({
        ...prev,
        ...filters,
      }));
    },
    [setFilters]
  );

  const clearFilters = () => {
    setFilters((prev) => ({
      bbox: prev.bbox,
    }));
  };

  const hasFilters = Object.entries(filters).some(([key, value]) => {
    if (key === 'premiumOnly' || key === 'bbox') {
      return false;
    }

    if (typeof value === 'object') {
      if (Array.isArray(value)) {
        return value.length > 0;
      }

      return (
        !isEmpty(value) && Object.values(value).some((value) => !isNil(value))
      );
    }

    return !isNil(value);
  });

  useEffect(() => {
    void (async () => {
      const { data } = await searchMlsListingsWithFilters({
        variables: {
          input: {
            filters: {
              ...filters,
              ...(showClosedStatus && !filters?.statuses?.length
                ? {
                    statuses: [
                      MlsListingStatus.Active,
                      MlsListingStatus.ActiveUnderContract,
                      MlsListingStatus.Pending,
                      MlsListingStatus.ComingSoon,
                      MlsListingStatus.Closed,
                    ],
                  }
                : {}),
            },
            limit:
              (mapRef.current?.getZoom() || DEFAULT_POSITION.zoom) >
              ZOOM_LEVEL_MARKER_CHANGE
                ? 60
                : 200,
          },
        },
      });

      const mlsListings = data?.searchMlsListingsWithFilters?.mlsListings || [];
      setListings(mlsListings);
      setTotalCount(data?.searchMlsListingsWithFilters?.totalCount || 0);

      if (
        shouldOpenPopup &&
        searchResult?.properties?.feature_type === 'address'
      ) {
        const matchingListing = getMatchingListingForSearchResult(
          mlsListings,
          searchResult as SearchResult
        );
        if (matchingListing) {
          setSelectedListing(matchingListing);
        } else {
          setSelectedListing(null);
        }
        // Resets the popup listing if the property is no longer in the filtered results
      } else if (selectedListing) {
        if (
          !mlsListings.some(
            (mlsListing) =>
              mlsListing.mlsListing.id === selectedListing?.mlsListing?.id
          )
        ) {
          setSelectedListing(null);
        }
      }
      setShouldOpenPopup(false);
    })();
    // Don't need to run this when search Result changes. Only when the filters change.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, searchMlsListingsWithFilters]);

  useEffect(() => {
    mapRef?.current?.getMap()?.resize();
  }, [mapRef]);

  const handleRetrieve = (result: GeocodingFeature) => {
    const matchingListing = getMatchingListingForSearchResult(
      listings,
      result as SearchResult
    );
    if (matchingListing) {
      setSelectedListing(matchingListing);
    } else {
      setShouldOpenPopup(true);
    }
    setSearchResult(result);
  };

  return (
    <ExploreContext.Provider
      value={{
        handleRetrieve,
        selectedListing,
        setSelectedListing,
        listings,
        loading,
        filters,
        updateFilters,
        clearFilters,
        totalCount,
        setTotalCount,
        searchResult,
        setSearchResult,
        shouldOpenPopup,
        setShouldOpenPopup,
        mapRef,
        utmParams,
        hasFilters,
        showClosedStatus,
      }}
    >
      {children}
    </ExploreContext.Provider>
  );
};

export const useExploreContext = () => {
  return useContext(ExploreContext);
};
