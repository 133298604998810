import { Card, Image, Text, VStack } from '@chakra-ui/react';
import { DEFAULT_PROPERTY_IMAGE_URL } from '~/apps/consumer/utils/listing.utils';
import { useIsMobile } from '~/common/hooks/useIsMobile';
import { useOpenHouseSignContext } from './OpenHouseSignContext';

type ImageProps = React.ComponentProps<typeof Image>;

const mobileImageProps: ImageProps = {
  borderTopLeftRadius: 4,
  borderBottomLeftRadius: 4,
  h: '70px',
  minW: '70px',
};

const desktopImageProps: ImageProps = {
  borderTopRadius: 9,
  h: '180px',
  minW: '100%',
};

export const OpenHouseDetailCard: React.FC = () => {
  const isMobile = useIsMobile();

  const { openHouseData } = useOpenHouseSignContext();

  const { mlsListing } = openHouseData?.openHouse.listing ?? {};
  const { photos, address } = mlsListing ?? {};
  const { full: addressFull, city, state, postalCode } = address ?? {};

  const coverPhoto = photos?.[0] || DEFAULT_PROPERTY_IMAGE_URL;
  const openHouseImage = (
    <Image
      alt={`${addressFull || 'Home'} listing photo`}
      fallbackSrc={coverPhoto}
      objectFit="cover"
      objectPosition="center"
      src={coverPhoto}
      userSelect="none"
      {...(isMobile ? mobileImageProps : desktopImageProps)}
    />
  );

  const addressLayout = (
    <VStack
      alignItems="flex-start"
      gap={0}
      justifyContent="flex-start"
      p={4}
      pt={3}
      spacing={0}
    >
      <Text fontSize="16px" fontWeight={500} lineHeight="24px">
        {addressFull}
        <Text color="whiteAlpha.600" fontWeight={400}>
          {city}, {state} {postalCode}
        </Text>
      </Text>
    </VStack>
  );

  return (
    <Card
      borderRadius={isMobile ? '4px' : undefined}
      direction={isMobile ? 'row' : 'column'}
      flexShrink={0}
      h={isMobile ? '70px' : undefined}
      minH="auto"
      overflow="hidden"
      userSelect="none"
      w={isMobile ? '100%' : '260px'}
    >
      {openHouseImage}
      {addressLayout}
    </Card>
  );
};
