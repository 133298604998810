import { Navigate, Outlet } from 'react-router';
import { useAppContext } from '~/services/main/contexts/AppContext';
import { Container } from '../../components/Layouts/Container';

export const OrganizationPageLayout = () => {
  const { selectedOrganization, loadingOrganizations } = useAppContext();

  if (!selectedOrganization && !loadingOrganizations) {
    return <Navigate replace to="/" />;
  }

  return (
    <Container display="flex" justifyContent="center" overflow="auto">
      <Outlet />
    </Container>
  );
};
