import {
  Button,
  Card,
  CardBody,
  Checkbox,
  Flex,
  Grid,
  Heading,
  Text,
  VStack,
  chakra,
} from '@chakra-ui/react';
import { useSignUpAgentV2Mutation } from '@client/graphql/__generated__/main-operations';
import {
  FindMlsAgentFragment,
  UserObject,
} from '@client/graphql/__generated__/types';
import { useState } from 'react';
import { UserAvatar } from '~/apps/consumer/components/User/UserAvatar';
import {
  FeatureKey,
  useGetStateAndMLSFeatureFlagPayload,
} from '~/apps/consumer/hooks/useIsFeatureEnabledForStateOrMls';

// Theme configuration
const THEME = {
  colors: {
    primary: 'blue.500',
    secondary: 'gray.200',
    text: {
      subtle: 'chakra-subtle-text',
    },
  },
} as const;

// Types
interface AutofilledAgentProps {
  mlsAgentsInfo: FindMlsAgentFragment[] | undefined;
  setShowAgentSignup: React.Dispatch<React.SetStateAction<boolean>>;
  onConfirm: (user: UserObject) => Promise<void> | void;
}

interface AgentCardProps {
  agent: FindMlsAgentFragment;
  isSelected: boolean;
  onToggle: (mlsId: string) => void;
}

// Styled components
const AutofillText = chakra(Text, {
  baseStyle: {
    color: THEME.colors.text.subtle,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
});

// Sub-components
const AgentCard = ({ agent, isSelected, onToggle }: AgentCardProps) => {
  const mlsId = agent.mlsAgent?.MemberMlsId ?? '';
  const profilePictureURL = agent.mlsAgent?.Media?.[0]?.MediaURL;

  return (
    <Card
      key={mlsId}
      borderColor={isSelected ? THEME.colors.primary : undefined}
      borderWidth={2}
      cursor="pointer"
      overflow="hidden"
      position="relative"
      onClick={() => onToggle(mlsId)}
    >
      <Checkbox
        isChecked={isSelected}
        opacity={isSelected ? 1 : 0}
        position="absolute"
        right={0}
        sx={{
          '& > span:first-of-type': {
            borderTopRightRadius: 0,
            borderBottomLeftRadius: 'xs',
            borderTopLeftRadius: 0,
            borderBottomRightRadius: 0,
            bg: isSelected ? THEME.colors.primary : THEME.colors.secondary,
            borderColor: isSelected
              ? THEME.colors.primary
              : THEME.colors.secondary,
          },
          '& > span[data-checked]': {
            color: 'white',
          },
        }}
        top={0}
        zIndex={1}
      />
      <CardBody>
        <Flex
          alignItems="center"
          flexDir={{ base: 'column', md: 'row' }}
          justifyContent="space-between"
          width="100%"
        >
          <Flex
            alignItems="center"
            flexDir={{ base: 'column', md: 'row' }}
            overflow="hidden"
          >
            {profilePictureURL && (
              <UserAvatar mr={{ md: 3 }} src={profilePictureURL} />
            )}
            <Flex
              direction="column"
              overflow="hidden"
              textAlign={{ base: 'center', md: 'start' }}
            >
              <Heading size="heading" whiteSpace="nowrap">
                {agent.mlsAgent?.MemberFullName}
              </Heading>
              <AutofillText>{agent.office?.OfficeName}</AutofillText>
              <AutofillText>{agent.mls?.name}</AutofillText>
            </Flex>
          </Flex>

          <Flex
            alignItems={{ md: 'flex-end' }}
            direction="column"
            overflow="hidden"
            textAlign={{ base: 'center' }}
          >
            <AutofillText>
              Agent MLS ID: {agent.mlsAgent?.MemberMlsId}
            </AutofillText>
            <AutofillText>
              {agent.mlsAgent?.MemberMobilePhone ||
                agent.mlsAgent?.MemberDirectPhone}
            </AutofillText>
            <AutofillText>{agent.mlsAgent?.MemberEmail}</AutofillText>
          </Flex>
        </Flex>
      </CardBody>
    </Card>
  );
};

// Main component
export const AutofilledAgent = ({
  mlsAgentsInfo: rawMlsAgentsInfo,
  setShowAgentSignup,
  onConfirm,
}: AutofilledAgentProps) => {
  const [signUpAgent, { loading }] = useSignUpAgentV2Mutation();
  const { mls: supportedMLS } =
    useGetStateAndMLSFeatureFlagPayload(FeatureKey.SIGN_UP) ?? {};

  const mlsAgentsInfo = rawMlsAgentsInfo?.filter(
    (agent) =>
      agent.mls?.originatingSystemName &&
      (!supportedMLS || supportedMLS.includes(agent.mls?.originatingSystemName))
  );

  const [selectedAgents, setSelectedAgents] = useState<Set<string>>(
    new Set(
      mlsAgentsInfo?.map((agent) => agent.mlsAgent?.MemberMlsId ?? '') ?? []
    )
  );

  const handleToggleAgent = (mlsId: string) => {
    setSelectedAgents((prev) => {
      const next = new Set(prev);
      next.has(mlsId) ? next.delete(mlsId) : next.add(mlsId);

      return next;
    });
  };

  const handleContinue = async () => {
    const selectedAgentInfos = mlsAgentsInfo?.filter((agent) =>
      selectedAgents.has(agent.mlsAgent?.MemberMlsId ?? '')
    );

    if (!selectedAgentInfos?.length) return;

    const firstAgent = selectedAgentInfos[0];
    const profilePictureURL = firstAgent.mlsAgent?.Media?.[0]?.MediaURL;

    const result = await signUpAgent({
      variables: {
        input: {
          firstName: firstAgent.mlsAgent?.MemberFirstName as string,
          lastName: firstAgent.mlsAgent?.MemberLastName as string,
          email: firstAgent.mlsAgent?.MemberEmail as string,
          licenses: selectedAgentInfos.map((agent) => ({
            licenseNumber: `${agent.mlsAgent?.MemberMlsId as string}`,
            mlsId: agent.mls?.id as string,
            states: [],
          })),
          ...(profilePictureURL ? { profilePictureURL } : {}),
        },
      },
    });

    await onConfirm(result.data?.signUpAgentV2?.user as UserObject);
  };

  return (
    <VStack
      alignItems={{ base: 'center', md: 'flex-start' }}
      mt={{ base: 3 }}
      spacing={4}
      width="100%"
    >
      <Flex alignItems={{ md: 'flex-start' }} direction="column">
        <Heading size="heading">Select Your MLS Profiles</Heading>
        <Button
          color={THEME.colors.text.subtle}
          variant="link"
          onClick={() => setShowAgentSignup(true)}
        >
          Not you?
        </Button>
      </Flex>

      <Grid
        gap={4}
        templateColumns={{ base: '1fr', md: 'repeat(1, 1fr)' }}
        width="100%"
      >
        {mlsAgentsInfo
          ?.filter((agentInfo) => agentInfo.mlsAgent?.MemberMlsId)
          .map((agentInfo) => (
            <AgentCard
              key={agentInfo.mlsAgent?.MemberMlsId}
              agent={agentInfo}
              isSelected={selectedAgents.has(
                agentInfo.mlsAgent?.MemberMlsId ?? ''
              )}
              onToggle={handleToggleAgent}
            />
          ))}
      </Grid>

      <Button
        isDisabled={selectedAgents.size === 0}
        isLoading={loading}
        type="button"
        width="100%"
        onClick={() => void handleContinue()}
      >
        Continue with Selected Profile{selectedAgents.size !== 1 ? 's' : ''}
      </Button>
    </VStack>
  );
};
