import { Flex, StyleProps } from '@chakra-ui/react';
import { COMPARABLE_FIELD_HEIGHT } from '../Listings/Comparables/ComparableColumns/constants';

export interface ComparableBaseProps extends StyleProps {
  children?: React.ReactNode;
}

export const BaseComparableField = ({
  children,
  height = COMPARABLE_FIELD_HEIGHT,
  ...styleProps
}: ComparableBaseProps) => {
  return (
    <Flex
      alignItems="center"
      border="1px solid"
      borderColor="border.divider"
      height={height}
      justifyContent="space-between"
      overflow="auto"
      {...styleProps}
    >
      {children}
    </Flex>
  );
};
