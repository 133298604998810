import { Card, Text, VStack } from '@chakra-ui/react';
import { ComponentProps } from 'react';

type ActionCardProps = {
  icon: React.ReactNode;
  header: React.ReactNode;
  description: React.ReactNode;
} & ComponentProps<typeof Card>;

export const ActionCard: React.FC<ActionCardProps> = ({
  icon,
  header,
  description,
  ...rest
}) => (
  <Card
    _hover={{
      cursor: 'pointer',
      bg: 'whiteAlpha.100',
    }}
    alignItems="center"
    border="1px solid"
    borderRadius="sm"
    color="cyan.500"
    display="flex"
    flex="1"
    flexDirection="column"
    gap={4}
    h="140px"
    justifyContent="center"
    minW="240px"
    p={4}
    {...rest}
  >
    {icon}
    <VStack gap={0}>
      <Text color="white" fontWeight="medium" textAlign="center">
        {header}
      </Text>
      <Text color="whiteAlpha.700" textAlign="center">
        {description}
      </Text>
    </VStack>
  </Card>
);
