import { Icon, IconProps } from '@chakra-ui/react';

export const SignatureIcon = (props: IconProps) => (
  <Icon
    color="primary.500"
    fill="none"
    height="15"
    viewBox="0 0 17 15"
    width="17"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M13.9395 3.60162C13.8643 3.60162 13.6973 3.63392 13.4418 3.88939L13.4333 3.89796L6.7242 10.4228L6.22862 11.9095L7.72751 11.4099L14.441 4.88068C14.6929 4.62741 14.7248 4.46174 14.7248 4.387C14.7248 4.31187 14.6925 4.14487 14.4371 3.88939C14.1816 3.63394 14.0146 3.60162 13.9395 3.60162ZM15.6702 2.65622C15.2015 2.18746 14.6164 1.85767 13.9395 1.85767C13.2647 1.85767 12.6813 2.18533 12.2133 2.65164L5.20399 9.46858L3.47119 14.667L8.65743 12.9382L15.6616 6.12628L15.6702 6.11777C16.139 5.64903 16.4688 5.06395 16.4688 4.387C16.4688 3.71006 16.1389 3.12496 15.6702 2.65622C15.6702 2.65621 15.6702 2.65623 15.6702 2.65622Z"
      fill="currentColor"
      fillRule="evenodd"
    />
    <path
      d="M14.0653 6.30122L11.8369 4.07284L12.6258 3.28399L14.042 2.84277L15.7132 4.09623L14.8542 5.51237L14.0653 6.30122Z"
      fill="currentColor"
    />
    <path
      clipRule="evenodd"
      d="M3.36984 4.33145C3.12843 4.61257 3.07542 4.81902 3.07542 4.93195C3.07542 5.54216 3.26658 5.94017 3.51763 6.37952C3.54224 6.42258 3.56922 6.4684 3.59778 6.51691C3.83773 6.92444 4.18961 7.5221 4.18961 8.27456C4.18961 8.91368 3.94011 9.3886 3.58921 9.74315C3.34327 9.99163 3.02215 10.1962 2.79897 10.3384C2.75586 10.3659 2.71641 10.391 2.68185 10.4137C2.42116 10.5847 2.26067 10.7148 2.15155 10.8705C2.05661 11.006 1.96123 11.2184 1.96123 11.6171C1.96123 12.3724 2.55143 12.9735 3.31764 12.9735C3.65171 12.9735 3.93775 12.8483 4.15706 12.7021C4.26502 12.6301 4.34838 12.5584 4.4023 12.5074C4.42893 12.4821 4.44741 12.4628 4.45713 12.4524L4.46479 12.444C4.46469 12.4441 4.46422 12.4446 4.46337 12.4456L4.46131 12.448L4.45994 12.4497L4.45917 12.4506L4.45876 12.4511C4.45855 12.4513 4.45834 12.4516 5.1282 13.0098C5.79806 13.568 5.79784 13.5683 5.79762 13.5686L5.79717 13.5691L5.79621 13.5703L5.79407 13.5728L5.78897 13.5788L5.77542 13.5944C5.76493 13.6064 5.75145 13.6214 5.73507 13.6391C5.70236 13.6743 5.65773 13.7203 5.60167 13.7734C5.4902 13.879 5.32983 14.0162 5.12442 14.1532C4.717 14.4248 4.09776 14.7174 3.31764 14.7174C1.57692 14.7174 0.217285 13.3242 0.217285 11.6171C0.217285 10.9015 0.400459 10.3305 0.723342 9.86972C1.03204 9.4292 1.42865 9.15013 1.72505 8.95563C1.80156 8.90542 1.86881 8.86202 1.92849 8.8235C2.13685 8.68903 2.25302 8.61406 2.3497 8.51636C2.41662 8.44875 2.44566 8.40142 2.44566 8.27456C2.44566 8.0174 2.34176 7.83586 2.05566 7.33601C2.03889 7.30671 2.0215 7.27632 2.00346 7.24476C1.69742 6.70919 1.33148 5.99302 1.33148 4.93195C1.33148 4.27888 1.62665 3.68451 2.04679 3.19527C2.46918 2.70341 3.056 2.26967 3.75473 1.9106C5.15439 1.19133 7.10694 0.717407 9.44568 0.717407V2.46136C7.32767 2.46136 5.6591 2.89271 4.55183 3.46172C3.9971 3.7468 3.609 4.05295 3.36984 4.33145Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </Icon>
);
