import { Box, FormControl, FormLabel, Icon, VStack } from '@chakra-ui/react';
import {
  MlsListingFiltersInput,
  MlsListingStatus,
} from '@client/graphql/__generated__/types';
import { Select } from 'chakra-react-select';
import { HouseIcon } from 'lucide-react';
import { DateRangeInput } from '../../DateRangeInput/DateRangeInput';
import { RangeCreatableSelectInput } from '../../RangeCreatableSelectInput/RangeCreatableSelectInput';
import { useExploreContext } from '../ExploreContext';
import { FilterDropdownButton } from './FilterDropdownButton';
import {
  BATHROOM_RANGE_OPTIONS,
  BEDROOM_RANGE_OPTIONS,
  LOAN_TYPE_OPTIONS,
  MLS_STATUS_OPTIONS,
  PRICE_RANGE_OPTIONS,
  SQFT_RANGE_OPTIONS,
} from './constants';
import { getNumActiveFilters } from './helpers';

export const PropertyFilters = ({
  containerRef,
}: {
  containerRef?: React.RefObject<HTMLElement>;
}) => {
  const { filters, showClosedStatus } = useExploreContext();

  const propertyFiltersList: (keyof MlsListingFiltersInput)[] = [
    'priceRange',
    'bedRange',
    'fullBathRange',
    'halfBathRange',
    'sqftRange',
    'loanTypes',
    'statuses',
    'closedDateRange',
  ];

  const numActiveFilters = getNumActiveFilters(filters, propertyFiltersList);

  return (
    <FilterDropdownButton
      activeFilterCount={numActiveFilters}
      // NOTE: need to prevent close on blur due to weird interaction with date picker
      closeOnBlur={!showClosedStatus}
      containerRef={containerRef}
      filterGroupName="Property filters"
      icon={<Icon as={HouseIcon} />}
    >
      <VStack>
        <PropertyFilterContent />
      </VStack>
    </FilterDropdownButton>
  );
};

export const PropertyFilterContent = () => {
  const { filters, updateFilters, showClosedStatus } = useExploreContext();

  return (
    <>
      <RangeCreatableSelectInput
        id="price-range"
        initialMaxValue={filters?.priceRange?.max}
        initialMinValue={filters?.priceRange?.min}
        label="Price Range"
        options={PRICE_RANGE_OPTIONS}
        onChangeMax={(value) => {
          updateFilters({
            priceRange: {
              max: value,
              min: filters?.priceRange?.min,
            },
          });
        }}
        onChangeMin={(value) => {
          updateFilters({
            priceRange: {
              max: filters?.priceRange?.max,
              min: value,
            },
          });
        }}
      />
      <RangeCreatableSelectInput
        id="bedrooms"
        initialMaxValue={filters?.bedRange?.max}
        initialMinValue={filters?.bedRange?.min}
        label="Bedrooms"
        options={BEDROOM_RANGE_OPTIONS}
        onChangeMax={(value) => {
          updateFilters({
            bedRange: {
              max: value,
              min: filters?.bedRange?.min,
            },
          });
        }}
        onChangeMin={(value) => {
          updateFilters({
            bedRange: {
              max: filters?.bedRange?.max,
              min: value,
            },
          });
        }}
      />
      <RangeCreatableSelectInput
        id="full-bathrooms"
        initialMaxValue={filters?.fullBathRange?.max}
        initialMinValue={filters?.fullBathRange?.min}
        label="Full Bathrooms"
        options={BATHROOM_RANGE_OPTIONS}
        onChangeMax={(value) => {
          updateFilters({
            fullBathRange: {
              max: value,
              min: filters?.fullBathRange?.min,
            },
          });
        }}
        onChangeMin={(value) => {
          updateFilters({
            fullBathRange: {
              max: filters?.fullBathRange?.max,
              min: value,
            },
          });
        }}
      />
      <RangeCreatableSelectInput
        id="half-bathrooms"
        initialMaxValue={filters?.halfBathRange?.max}
        initialMinValue={filters?.halfBathRange?.min}
        label="Half Bathrooms"
        options={BATHROOM_RANGE_OPTIONS}
        onChangeMax={(value) => {
          updateFilters({
            halfBathRange: {
              max: value,
              min: filters?.halfBathRange?.min,
            },
          });
        }}
        onChangeMin={(value) => {
          updateFilters({
            halfBathRange: {
              max: filters?.halfBathRange?.max,
              min: value,
            },
          });
        }}
      />
      <RangeCreatableSelectInput
        id="square-footage"
        initialMaxValue={filters?.sqftRange?.max}
        initialMinValue={filters?.sqftRange?.min}
        label="Square Footage"
        options={SQFT_RANGE_OPTIONS}
        onChangeMax={(value) => {
          updateFilters({
            sqftRange: {
              max: value,
              min: filters?.sqftRange?.min,
            },
          });
        }}
        onChangeMin={(value) => {
          updateFilters({
            sqftRange: {
              max: filters?.sqftRange?.max,
              min: value,
            },
          });
        }}
      />
      <FormControl>
        <FormLabel>Loan Type</FormLabel>
        <Box w="full">
          <Select
            isClearable
            isMulti
            useBasicStyles
            defaultValue={
              filters?.loanTypes?.map((loanType) =>
                LOAN_TYPE_OPTIONS.find((option) => option.value === loanType)
              ) as {
                label: string;
                value: string;
              }[]
            }
            id="loan-type"
            options={LOAN_TYPE_OPTIONS}
            placeholder="Any"
            onChange={(options) => {
              const values = options?.map((option) => option.value);

              updateFilters({
                loanTypes: values,
              });
            }}
          />
        </Box>
      </FormControl>

      <FormControl>
        <FormLabel>Status</FormLabel>
        <Box w="full">
          <Select
            isClearable
            isMulti
            useBasicStyles
            defaultValue={
              filters?.statuses?.map((status) =>
                MLS_STATUS_OPTIONS.find((option) => option.value === status)
              ) as {
                label: string;
                value: MlsListingStatus;
              }[]
            }
            id="status"
            options={
              showClosedStatus
                ? MLS_STATUS_OPTIONS
                : MLS_STATUS_OPTIONS.filter(
                    (option) => option.value !== MlsListingStatus.Closed
                  )
            }
            placeholder="Any"
            onChange={(options) => {
              const values = options?.map((option) => option.value);

              updateFilters({
                statuses: values,
              });
            }}
          />
        </Box>
      </FormControl>
      <FormControl>
        <FormLabel>Closed Date</FormLabel>
        <Box w="full">
          <DateRangeInput
            endDate={filters?.closedDateRange?.endDate as Date | undefined}
            startDate={filters?.closedDateRange?.startDate as Date | undefined}
            onEndChange={(value) => {
              updateFilters({
                closedDateRange: {
                  endDate: value,
                  startDate: filters?.closedDateRange?.startDate as
                    | Date
                    | undefined,
                },
              });
            }}
            onStartChange={(value) => {
              updateFilters({
                closedDateRange: {
                  endDate: filters?.closedDateRange?.endDate as
                    | Date
                    | undefined,
                  startDate: value,
                },
              });
            }}
          />
        </Box>
      </FormControl>
    </>
  );
};
