import { useToast } from '@chakra-ui/react';
import { useAcceptAccessControlInviteMutation } from '@client/graphql/__generated__/main-operations';
import { FC, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useAppContext } from '~/services/main/contexts/AppContext';

export const InvitationAcceptPage: FC = () => {
  const { user } = useAppContext();
  const { code } = useParams<{ code: string }>();
  const navigate = useNavigate();
  const toast = useToast();
  const [acceptInvite] = useAcceptAccessControlInviteMutation();

  useEffect(() => {
    const acceptInvitation = async () => {
      const roles = user?.roles;
      if (roles && roles.length > 0) {
        const { data } = await acceptInvite({
          variables: { input: { code: code as string } },
        });

        if (
          data?.acceptAccessControlInvite?.targetEntityName === 'Listing' &&
          data?.acceptAccessControlInvite?.targetId
        ) {
          navigate(`/listings/${data.acceptAccessControlInvite.targetId}`);
          toast({
            title: 'Invitation as listing admin accepted',
            status: 'success',
          });

          return;
        }

        navigate('/');
      }
    };

    void acceptInvitation();
  }, [acceptInvite, code, navigate, toast, user?.roles]);

  return null;
};
