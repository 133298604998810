import {
  Card,
  CardBody,
  CardHeader,
  Heading,
  Link,
  SlideFade,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useMemo } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';
import { IndigoLogo } from '~/common/icons/IndigoLogo';
import { useAppContext } from '../../../contexts/AppContext';
import { LoginForm, LoginFormSteps } from './LoginForm';

export const Login = () => {
  const [searchParams] = useSearchParams();
  const { appState, user } = useAppContext();
  const nextUrl = searchParams.get('next');
  const step = searchParams.get('step');
  const title = useMemo(() => {
    switch (step) {
      case LoginFormSteps.SignUp:
        return 'Sign Up';
      case LoginFormSteps.MLSAgent:
        return '';
      default:
        return "Let's get started";
    }
  }, [step]);

  if (user && user.roles.length > 0) {
    return <Navigate to={searchParams.get('next') || '/'} />;
  }

  return (
    <VStack
      alignItems="center"
      bgColor={appState.bgColor}
      justifyContent="center"
      minHeight="100vh"
      p={4}
      width="100hv"
    >
      <SlideFade in offsetY="60px">
        <Card minWidth="340px">
          <CardHeader>
            <VStack alignItems="center" spacing={4}>
              <IndigoLogo width="100px" />
              <Heading
                as="h2"
                fontSize="3xl"
                letterSpacing="0px"
                size="sm"
                textTransform="none"
              >
                {title}
              </Heading>
            </VStack>
          </CardHeader>
          <CardBody minWidth="340px">
            <LoginForm
              onLogin={() => {
                window.location.assign(nextUrl || '/');
              }}
            />
          </CardBody>
        </Card>
        <VStack alignItems="center" fontSize="lg" mt="4">
          <Text align="center">
            Now available in Charlotte, NC & SC
            <br />
            Want us in your area?{' '}
            <Link color="link.skyBlue" href="mailto:help@useindigo.com">
              Tell us!
            </Link>
          </Text>
        </VStack>
      </SlideFade>
    </VStack>
  );
};
