import { Icon, IconProps, chakra, keyframes } from '@chakra-ui/react';
import { IndigoLogoSmall } from '~/common/icons/IndigoLogoSmall';

export const LogoSpinner = (props: IconProps) => {
  return <SpinningIcon as={IndigoLogoSmall} {...props} />;
};

const spinStop = keyframes({
  '0%': { transform: 'rotate(0deg)' },
  '5%, 25%': { transform: 'rotate(90deg)' },
  '30%, 50%': { transform: 'rotate(180deg)' },
  '55%, 75%': { transform: 'rotate(270deg)' },
  '80%, 100%': { transform: 'rotate(360deg)' },
});

const SpinningIcon = chakra(Icon, {
  baseStyle: {
    animation: `${spinStop} 7s linear infinite`,
  },
});
