import {
  Button,
  Flex,
  SlideFade,
  Text,
  Tooltip,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import React, { FC, useState } from 'react';
import { useIsMobile } from '~/common/hooks/useIsMobile';
import { useOfferFlowContext } from './OfferFlowContext';

export const CONTINUE_BUTTON_HEIGHT = '80px';
interface OfferFlowContainerProps {
  children: React.ReactNode;
  onContinueRoutePath?: string;
  continueButtonText?: string;
  showContinueButton?: boolean;
  isDisabled?: boolean;
  onSaveOverride?: () => void | Promise<void>;
  showCancelButton?: boolean;
  cancelButtonText?: string;
  onCancel?: () => void | Promise<void>;
  customContinueButton?: React.ReactNode;
  toolTipLabel?: string;
  showTooltip?: boolean;
}

export const OfferFlowContainer: FC<OfferFlowContainerProps> = ({
  children,
  onContinueRoutePath,
  continueButtonText = 'Continue',
  showContinueButton = true,
  customContinueButton,
  onSaveOverride,
  isDisabled,
  showCancelButton,
  cancelButtonText = 'Cancel',
  toolTipLabel,
  showTooltip = false,
  onCancel,
}) => {
  const { save, showContractPreview } = useOfferFlowContext();
  const [isLoading, setIsLoading] = useState(false);
  const isMobile = useIsMobile();
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <VStack alignItems="stretch" flexGrow={1} height="100%" spacing="0">
      <Flex
        flexGrow={1}
        pb={
          isMobile && !showContractPreview ? CONTINUE_BUTTON_HEIGHT : undefined
        }
        px={5}
        width="100%"
      >
        <SlideFade in style={{ width: '100%' }}>
          {children}
        </SlideFade>
      </Flex>
      {(showContinueButton || showCancelButton) && (
        <VStack
          alignItems="stretch"
          bgColor="bg.mainDarkOpacity"
          bottom={0}
          flexShrink={1}
          justifyContent="flex-end"
          left={isMobile ? 0 : undefined}
          position={isMobile ? 'fixed' : 'sticky'}
          py={4}
          right={isMobile ? 0 : undefined}
          spacing={4}
          zIndex={5}
        >
          <VStack
            alignItems="stretch"
            backdropFilter="blur(4px)"
            bgColor="bg.mainDarkOpacity"
            px={5}
          >
            {showContinueButton &&
              (customContinueButton ? (
                customContinueButton
              ) : (
                <Tooltip
                  hasArrow
                  isDisabled={!toolTipLabel || !showTooltip}
                  isOpen={isOpen}
                  label={<Text>{toolTipLabel}</Text>}
                  placement="top"
                  onClose={onClose}
                  onOpen={onOpen}
                >
                  <Button
                    isDisabled={isDisabled}
                    isLoading={isLoading}
                    // eslint-disable-next-line @typescript-eslint/no-misused-promises
                    onClick={async () => {
                      setIsLoading(true);

                      try {
                        if (onSaveOverride) {
                          await onSaveOverride();
                        } else {
                          await save(onContinueRoutePath);
                        }
                      } finally {
                        setIsLoading(false);
                      }
                    }}
                    onMouseEnter={onOpen}
                    onMouseLeave={onClose}
                    onTouchStart={showTooltip ? onOpen : undefined}
                  >
                    {continueButtonText}
                  </Button>
                </Tooltip>
              ))}
            {showCancelButton && (
              <Button
                mx={5}
                variant="outline"
                onClick={() => {
                  if (onCancel) {
                    void onCancel();
                  }
                }}
              >
                {cancelButtonText}
              </Button>
            )}
          </VStack>
        </VStack>
      )}
    </VStack>
  );
};
