import { Icon, IconProps } from '@chakra-ui/react';

export const RadioIcon = (props: IconProps) => (
  <Icon
    color="primary.500"
    fill="none"
    height="16px"
    viewBox="0 0 16 16"
    width="16px"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      height="14"
      rx="7"
      stroke="currentColor"
      strokeWidth="2"
      width="14"
      x="1"
      y="1"
    />
    <path
      d="M4.88013 8.62645L6.25213 10.4024C6.30417 10.4733 6.37403 10.5318 6.45588 10.5731C6.53774 10.6144 6.62923 10.6373 6.72273 10.6399C6.81624 10.6424 6.90907 10.6246 6.99348 10.5878C7.07789 10.551 7.15146 10.4964 7.20805 10.4284L11.6001 5.36011"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </Icon>
);
