import {
  Box,
  Divider,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Text,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import { useFindMlsAgentQuery } from '@client/graphql/__generated__/main-operations';
import { PublicUserObject } from '@client/graphql/__generated__/types';
import { ReactNode } from 'react';
import { CenterSpinner } from '~/common/components/CenterSpinner';
import { formatPhoneNumber } from '~/common/utils/formatter';
import { useDevice } from '~/services/document/hooks/useDevice';
import { UserAvatar } from '../User/UserAvatar';

export const UserContactInfoCard = ({ user }: { user?: PublicUserObject }) => {
  const { data, loading } = useFindMlsAgentQuery({
    variables: {
      input: {
        phoneNumber: user?.phoneNumber,
      },
    },
    skip: !user?.phoneNumber,
  });

  if (loading) {
    return <CenterSpinner />;
  }

  const officeName = data?.findMLSAgent?.office?.OfficeName;

  return (
    <Box
      backgroundColor="indigo.700"
      borderColor="whiteAlpha.100"
      borderRadius="sm"
      boxShadow="md"
      p={4}
      textAlign="center"
    >
      <UserAvatar mb={4} size="xl" user={user} />
      {user && (
        <VStack spacing={1}>
          <Text color="white" fontSize="lg" fontWeight="medium">
            {user.fullName}
          </Text>
          {officeName && <Text color="whiteAlpha.600">{officeName}</Text>}
          <Divider borderColor="whiteAlpha.100" my="2" />
          {user.email && (
            <Text color="whiteAlpha.600">
              <a href={`mailto:${user.email}`}>{user.email}</a>
            </Text>
          )}
          <Text color="whiteAlpha.600">
            <a href={`tel:${user.phoneNumber}`}>
              {formatPhoneNumber(user.phoneNumber)}
            </a>
          </Text>
        </VStack>
      )}
    </Box>
  );
};
export function UserContactInfoPopover({
  user,
  trigger,
}: {
  user: PublicUserObject;
  trigger: ReactNode;
}) {
  const { onOpen, onClose, isOpen } = useDisclosure();
  const { isTouch } = useDevice();

  return (
    <Popover
      isOpen={isOpen}
      placement="bottom-start"
      trigger={isTouch ? 'click' : 'hover'}
      onClose={onClose}
      onOpen={onOpen}
    >
      <PopoverTrigger>{trigger}</PopoverTrigger>
      <Portal>
        <PopoverContent
          minHeight="150px"
          minWidth="150px"
          overflow="hidden"
          width="fit-content"
        >
          <UserContactInfoCard user={user} />
        </PopoverContent>
      </Portal>
    </Popover>
  );
}
