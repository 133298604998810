import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  Input,
  Text,
  useToast,
} from '@chakra-ui/react';
import { useAddOrUpdateDisclosureContractDocumentsMutation } from '@client/graphql/__generated__/document-operations';
import {
  DocumentVersionObject,
  ExternalDocumentType,
} from '@client/graphql/__generated__/types';
import { Select } from 'chakra-react-select';
import { PaperclipIcon } from 'lucide-react';
import { useMemo, useState } from 'react';
import { useOfferFlowContext } from '~/apps/consumer/pages/Storefront/OfferFlow/OfferFlowContext';
import { UploadDropzone } from '~/common/components/Upload/UploadDropzone';

export const UploadDocsTab = () => {
  const {
    agentNegotiationDocumentTypes,
    disclosureDocumentTypes,
    latestOffer,
    updateFieldValues,
    activeDocumentVersion,
    fieldValues,
  } = useOfferFlowContext();

  const toast = useToast();

  const [selectedDocType, setSelectedDocType] = useState<string>('');
  const [otherDocumentName, setOtherDocumentName] = useState<string>('');
  const [uploadedFile, setUploadedFile] = useState<File | undefined>();
  const [addOrUpdateDisclosure, { loading }] =
    useAddOrUpdateDisclosureContractDocumentsMutation();

  const agentNegotiationDocumentVersions = useMemo(() => {
    return (
      activeDocumentVersion?.secondaryVersions?.filter(
        (docVersion) =>
          agentNegotiationDocumentTypes?.some(
            (docType) => docType.formName === docVersion.document.form
          ) && !docVersion.mappingKeys?.some((key) => fieldValues[key])
      ) || []
    );
  }, [
    activeDocumentVersion?.secondaryVersions,
    agentNegotiationDocumentTypes,
    fieldValues,
  ]);

  // disclosure document versions that need to be uploaded and have not been uploaded
  const disclosureDocumentVersions = useMemo(
    () =>
      activeDocumentVersion?.secondaryVersions?.filter(
        (docVersion) =>
          disclosureDocumentTypes?.some(
            (docType) => docType.formName === docVersion.document.form
          ) && !docVersion.mappingKeys?.some((key) => fieldValues[key])
      ) || [],
    [
      activeDocumentVersion?.secondaryVersions,
      disclosureDocumentTypes,
      fieldValues,
    ]
  );

  const combinedDocVersions = useMemo(
    () => [...agentNegotiationDocumentVersions, ...disclosureDocumentVersions],
    [agentNegotiationDocumentVersions, disclosureDocumentVersions]
  );

  const docTypeOptions = useMemo(() => {
    const otherOption = [
      {
        label: 'Other',
        value: 'other',
      },
    ];

    return (
      combinedDocVersions
        ?.map((docVersion) => ({
          label: docVersion.document.name,
          value: docVersion.document.form,
        }))
        ?.concat(otherOption) || otherOption
    );
  }, [combinedDocVersions]);

  const isOtherDocument = selectedDocType === 'other';

  const onUpload = async (file?: File) => {
    const docVersion = [
      ...agentNegotiationDocumentVersions,
      ...disclosureDocumentVersions,
    ].find((docVersion) => docVersion.document.form === selectedDocType);

    const order = (
      activeDocumentVersion?.secondaryVersions as DocumentVersionObject[]
    )?.findIndex((secondary) => docVersion?.id === secondary.id);

    const mappingKey = docVersion?.mappingKeys[0];

    await addOrUpdateDisclosure({
      variables: {
        input: {
          contractId: latestOffer?.contract?.id as string,
          contractDocuments: [
            {
              documentVersionId: docVersion?.id || undefined,
              file,
              order: order && order !== -1 ? order : 100,
              mappingKeys: mappingKey ? [mappingKey] : undefined,
              externalContractDocumentName: isOtherDocument
                ? otherDocumentName
                : undefined,
              externalContractDocumentType: isOtherDocument
                ? ExternalDocumentType.UNKNOWN
                : undefined,
            },
          ],
          shouldDetectFormFields: true,
        },
      },
      onCompleted: () => {
        toast({
          title: 'Document uploaded successfully',
          status: 'success',
          duration: 3000,
        });
        setSelectedDocType('');
        setOtherDocumentName('');
        setUploadedFile(undefined);
      },
    });

    if (mappingKey) {
      updateFieldValues({
        [mappingKey]: true,
      });
    }
  };

  return (
    <Flex flexDirection="column" gap={3} width="100%">
      <Box width="100%">
        <Select
          isClearable
          menuPortalTarget={document.body}
          options={docTypeOptions}
          placeholder="Select  document type"
          styles={{
            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
            container: (base) => ({ ...base, width: '100%' }),
          }}
          value={
            docTypeOptions.find((option) => option.value === selectedDocType) ||
            null
          }
          onChange={(option) => setSelectedDocType(option?.value || '')}
        />
      </Box>

      {isOtherDocument && (
        <FormControl>
          <FormLabel>Document Name</FormLabel>
          <Input
            placeholder="e.g. Inspection Report"
            onChange={(e) => setOtherDocumentName(e.target.value)}
          />
        </FormControl>
      )}
      {!!selectedDocType && (
        <>
          <UploadDropzone
            allowMultiple={false}
            borderColor="whiteAlpha.300"
            dropzoneContent={
              <Flex
                alignItems="center"
                gap={1}
                justifyContent="space-between"
                p={3}
                width="100%"
              >
                <Text
                  color={uploadedFile ? 'white' : 'whiteAlpha.600'}
                  noOfLines={1}
                >
                  {uploadedFile ? `${uploadedFile.name}` : 'Attach file'}
                </Text>
                <Icon as={PaperclipIcon} color="whiteAlpha.600" />
              </Flex>
            }
            onDrop={(files) => setUploadedFile(files[0])}
          />
          <Button
            isDisabled={
              !uploadedFile || (isOtherDocument && !otherDocumentName)
            }
            isLoading={loading}
            onClick={() => void onUpload(uploadedFile)}
          >
            Upload
          </Button>
        </>
      )}
    </Flex>
  );
};
