import { RuleType } from '@client/graphql/__generated__/types';
import { FormField, ValuesType } from '@document/components/FormFields/types';
import { evaluateFormula } from '@services/document/src/utils/formula';
import type EventEmitter from 'eventemitter3';
import { FC, memo, useCallback, useContext, useMemo } from 'react';
import { DocumentFormFieldsContext } from '~/services/document/components/DocumentFormFields/context';
import { OfferField } from './OfferField';

interface OfferFieldsPerPageProps {
  formFields: FormField[];
  documentIndex: number;
  observer?: IntersectionObserver;
  emitter: EventEmitter;
}

interface OfferFieldsProps {
  formFields: FormField[];
  observer?: IntersectionObserver;
  values: ValuesType;
  emitter: EventEmitter;
  updateValues: (values: ValuesType) => void;
  evaluateErrorRules: (formField: FormField) => (string | undefined)[];
}

const OfferFields: FC<OfferFieldsProps> = memo(function OfferFields(props) {
  const {
    observer,
    formFields,
    emitter,
    values,
    updateValues,
    evaluateErrorRules,
  } = props;

  return (
    <>
      {formFields?.map((formField, index) => {
        return values[formField.mappingKey] ? (
          <OfferField
            key={formField.id}
            emitter={emitter}
            evaluateErrorRules={
              formField.rules || formField.field?.defaultRules
                ? evaluateErrorRules
                : undefined
            }
            formField={formField}
            index={index}
            observer={observer}
            updateValues={updateValues}
            value={values[formField.mappingKey]}
          />
        ) : null;
      })}
    </>
  );
});

export const OfferFieldsPerPage: FC<OfferFieldsPerPageProps> = memo(
  function OfferFieldsPerPage(props) {
    const { formFields, emitter, observer } = props;
    const { values, updateValues } = useContext(DocumentFormFieldsContext);

    const formFieldsByPage = useMemo(() => {
      return formFields.reduce(
        (out, formField) => {
          const pageFormFields = out[formField.pageNumber];
          if (pageFormFields) {
            pageFormFields.push(formField);
          } else {
            out[formField.pageNumber] = [formField];
          }

          return out;
        },
        {} as Record<number, FormField[]>
      );
    }, [formFields]);
    const evaluateErrorRules = useCallback(
      (formField: FormField) => {
        const errorRules = formField?.field?.defaultRules?.filter(
          ({ ruleType }) => ruleType === RuleType.ERROR
        );

        return (
          errorRules
            ?.map(({ message, autofill }) => {
              if (autofill?.formula && autofill.formulaVariableFields) {
                const { output } = evaluateFormula(
                  autofill.formula,
                  autofill.formulaVariableFields,
                  values
                );

                return output && output === 'true' ? message : '';
              }
            })
            .filter((error) => !!error) || []
        );
      },
      [values]
    );
    const allFormFields = Object.keys(formFieldsByPage)
      .sort((a, b) => parseInt(a) - parseInt(b))
      .reduce((out, pageNumber) => {
        return out.concat(formFieldsByPage[Number(pageNumber)]);
      }, [] as FormField[]);

    return (
      <>
        <OfferFields
          emitter={emitter}
          evaluateErrorRules={evaluateErrorRules}
          formFields={allFormFields}
          observer={observer}
          updateValues={updateValues}
          values={values}
        />
      </>
    );
  }
);
