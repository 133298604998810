import { chakra } from '@chakra-ui/react';
import {
  Formik,
  FormikHelpers,
  FormikProps,
  Form as UnstyledFormikForm,
} from 'formik';
import React from 'react';

interface IFormProps<T extends { [key: string]: unknown }> {
  formId?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSubmit: (values: T, formikHelpers: FormikHelpers<T>) => void | Promise<any>;
  children: (props: FormikProps<T>) => React.ReactNode;
  initialValues?: T;
  enableReinitialize?: boolean;
}

export const Form = <T extends { [key: string]: unknown }>({
  formId,
  children,
  onSubmit,
  initialValues,
  enableReinitialize,
}: IFormProps<T>) => {
  return (
    <Formik<T>
      enableReinitialize={enableReinitialize}
      initialValues={initialValues ?? ({} as T)}
      onSubmit={onSubmit}
    >
      {(props) => {
        return (
          <FormikForm id={formId} width="100%">
            {children(props)}
          </FormikForm>
        );
      }}
    </Formik>
  );
};

const FormikForm = chakra(UnstyledFormikForm);
