import { CheckIcon } from '@chakra-ui/icons';
import {
  Flex,
  HStack,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import { useUploadOfferForBuyersAgentMutation } from '@client/graphql/__generated__/main-operations';
import {
  OfferSource,
  PublicListingFragment,
} from '@client/graphql/__generated__/types';
import { useNavigate } from 'react-router';
import { useRegisteredRoles } from '~/apps/consumer/hooks/useRegisteredRoles';
import { getStorefrontRoute } from '~/apps/consumer/utils/storefront.utils';
import { useIsMobile } from '~/common/hooks/useIsMobile';
import { OfferPackagesUploadZone } from '../../../Offers/OfferPackagesUploadZone';

export interface StartOfferUploadModalProps {
  listing: PublicListingFragment;
  isOpen: boolean;
  onClose: () => void;
  onProcessing: () => void;
  onErrorProcessing: () => void;
  initialFiles?: File[];
}

export const StartOfferUploadModal = ({
  listing,
  isOpen,
  onClose,
  onProcessing,
  onErrorProcessing,
  initialFiles,
}: StartOfferUploadModalProps) => {
  const [uploadOffer] = useUploadOfferForBuyersAgentMutation();
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const { isRegisteredAgent } = useRegisteredRoles();
  const toast = useToast();

  const handleUpload = (acceptedFiles: File[]) => {
    if (!acceptedFiles.length) {
      toast({
        title: 'Please upload at least one file',
        status: 'error',
      });

      return;
    }

    onProcessing();
    uploadOffer({
      variables: {
        input: {
          files: acceptedFiles,
          listingId: listing.id,
          source: OfferSource.BA_UPLOAD,
        },
        isAgentVerified: isRegisteredAgent,
      },
    })
      .then(({ data }) => {
        const offerId = isRegisteredAgent
          ? data?.uploadOfferForBuyersAgent?.offer?.id
          : data?.uploadOfferForUnauthenticatedBuyersAgent?.offer?.id;
        if (!offerId) {
          return;
        }
        const offerDetailsPath = `${getStorefrontRoute(
          listing
        )}/offer/uploaded`;
        navigate(offerDetailsPath);
      })
      .catch(() => {
        onErrorProcessing();
      });
  };

  return (
    <Modal isOpen={isOpen} size="4xl" onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        {isMobile && <ModalCloseButton />}
        <ModalBody p={8}>
          <Flex
            direction={{ base: 'column', md: 'row' }}
            gap={4}
            minHeight={{ base: 'auto', md: '450px' }}
          >
            <Stack
              flex={1}
              justifyContent="center"
              width={{ base: '100%', md: '50%' }}
            >
              <Heading>Upload a signed offer</Heading>
              <HStack>
                <CheckIcon color="green" /> <Text>Classify your docs</Text>
              </HStack>
              <HStack>
                <CheckIcon color="green" />{' '}
                <Text>Validate accuracy with tax records and listing data</Text>
              </HStack>
              <HStack>
                <CheckIcon color="green" />{' '}
                <Text> Built in comparison to seller preferences</Text>
              </HStack>
            </Stack>
            <OfferPackagesUploadZone
              handleUpload={handleUpload}
              initialFiles={initialFiles}
            />
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
