import { GeocodingFeature } from '@mapbox/search-js-core';
import { useGeocodingCore, useSearchSession } from '@mapbox/search-js-react';
import { useCallback, useState } from 'react';

export const useMapboxGeocoder = () => {
  const [suggestions, setSuggestions] = useState<GeocodingFeature[]>([]);
  const [selectedSuggestion, setSelectedSuggestion] =
    useState<GeocodingFeature | null>(null);
  const geocoder = useGeocodingCore({
    country: 'US',
    proximity: 'ip',
    accessToken: window.ENVS.mapboxApiKey,
  });

  const session = useSearchSession(geocoder);

  const searchSuggestions = useCallback(
    async (query: string) => {
      const result = await session.suggest(query);
      setSuggestions(result.features);

      return result;
    },
    [session]
  );

  const onSuggestionSelect = async (suggestion: GeocodingFeature) => {
    const result = await session.retrieve(suggestion);
    setSelectedSuggestion(result);

    return result;
  };

  const onSuggestionsClear = () => {
    setSuggestions([]);
    session.clear();
  };

  return {
    selectedSuggestion,
    setSelectedSuggestion,
    suggestions,
    setSuggestions,
    searchSuggestions,
    onSuggestionSelect,
    onSuggestionsClear,
  };
};
