import {
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
} from '@chakra-ui/react';
import { useIsMobile } from '~/common/hooks/useIsMobile';
import { useMobileHeaderContext } from '../../contexts/MobileHeaderContext';
import { NavItems } from '../Navigation/NavItems';
import { NeedHelp } from './NeedHelp';

export const DefaultMobileNavDrawer = () => {
  const { isDrawerOpen, onDrawerClose } = useMobileHeaderContext();
  const isMobile = useIsMobile();

  return (
    <Drawer
      blockScrollOnMount={!isMobile}
      isOpen={isDrawerOpen}
      placement="left"
      onClose={onDrawerClose}
    >
      <DrawerOverlay />
      <DrawerContent overflowY={{ base: 'auto', md: 'initial' }}>
        <DrawerHeader
          alignItems="center"
          borderBottom="1px solid"
          borderBottomColor="border.divider"
          mb={4}
          py={8}
        >
          <DrawerCloseButton mt={{ base: 2 }} />
        </DrawerHeader>
        <NavItems isExpanded isMobile onClick={() => onDrawerClose()} />
        <DrawerFooter bottom={0} p={3} position="sticky">
          <NeedHelp />
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};
