import {
  Button,
  Card,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Link,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
import { UserFragment } from '@client/graphql/__generated__/types';
import { Field, Form, Formik, FormikProps } from 'formik';
import { useState } from 'react';
import { ProfilePicture } from '~/apps/consumer/components/User/ProfilePicture';
import { FeatureKey } from '~/apps/consumer/hooks/useIsFeatureEnabledForStateOrMls';
import { MLSSelect } from '../AgentSignUpFlow/MLSSelect';

interface FormInputs {
  firstName: string;
  lastName: string;
  email: string;
  mlsAccounts: Array<{
    licenseNumber: string;
    mlsId: string;
  }>;
  profilePicture: string;
}

interface SignUpFormProps {
  withMls?: boolean;
  loading: boolean;
  user?: UserFragment;
  signupFormInstructions?: string;
  onSubmit: (
    user: Omit<FormInputs, 'profilePicture'> & { profilePicture?: File }
  ) => Promise<void> | void;
}

export const SignUpForm = ({
  withMls = true,
  loading,
  user,
  signupFormInstructions,
  onSubmit,
}: SignUpFormProps) => {
  const [newPictureFile, setNewPictureFile] = useState<File>();

  return (
    <Formik<FormInputs>
      initialValues={{
        firstName: user?.firstName || '',
        lastName: user?.lastName || '',
        mlsAccounts: [{ licenseNumber: '', mlsId: '' }],
        profilePicture: '',
        email: user?.email || '',
      }}
      onSubmit={async (values) => {
        await onSubmit({
          ...values,
          profilePicture: newPictureFile,
        });
      }}
    >
      {(props: FormikProps<FormInputs>) => {
        const addMlsAccount = () => {
          void props.setFieldValue('mlsAccounts', [
            ...props.values.mlsAccounts,
            { licenseNumber: '', mlsId: '' },
          ]);
        };

        return (
          <VStack as={Form} spacing={4} width="100%">
            {signupFormInstructions && (
              <Flex width="100%">
                <Heading size="heading">{signupFormInstructions}</Heading>
              </Flex>
            )}
            <ProfilePicture
              onProfilePictureChange={(picture, pictureUrl) => {
                void props.setFieldValue('profilePicture', pictureUrl);
                setNewPictureFile(picture);
              }}
            />

            <FormControl isRequired>
              <FormLabel>First Name</FormLabel>
              <Input
                as={Field}
                id="firstName"
                name="firstName"
                placeholder="First Name"
              />
            </FormControl>

            <FormControl isRequired>
              <FormLabel>Last Name</FormLabel>
              <Input
                as={Field}
                id="lastName"
                name="lastName"
                placeholder="Last Name"
              />
            </FormControl>

            <FormControl isRequired>
              <FormLabel>Email</FormLabel>
              <Input
                as={Field}
                id="email"
                isDisabled={!!user?.email}
                name="email"
                placeholder="Email"
              />
            </FormControl>

            {withMls && (
              <>
                {props.values.mlsAccounts.map((mlsAccount, index) => (
                  <Card key={index} p={4} w="full">
                    <FormControl isRequired>
                      <FormLabel>Agent MLS ID</FormLabel>
                      <Input
                        as={Field}
                        id={`mlsAccounts.${index}.licenseNumber`}
                        name={`mlsAccounts.${index}.licenseNumber`}
                        placeholder="Agent MLS ID you use to log into your MLS account."
                      />
                    </FormControl>

                    <FormControl mt={4}>
                      <FormLabel>MLS</FormLabel>
                      <Stack spacing={3}>
                        <MLSSelect
                          featureKeyFilter={FeatureKey.SIGN_UP}
                          id={`mlsAccounts.${index}.mlsId`}
                          name={`mlsAccounts.${index}.mlsId`}
                          selectedMlsId={mlsAccount.mlsId}
                          onChange={(value) =>
                            void props.setFieldValue(
                              `mlsAccounts.${index}.mlsId`,
                              value?.value
                            )
                          }
                        />
                      </Stack>
                    </FormControl>
                    {index > 0 && (
                      <Button
                        colorScheme="red"
                        mt={4}
                        size="sm"
                        variant="ghost"
                        onClick={() => {
                          const newAccounts = [...props.values.mlsAccounts];
                          newAccounts.splice(index, 1);
                          void props.setFieldValue('mlsAccounts', newAccounts);
                        }}
                      >
                        Remove
                      </Button>
                    )}
                  </Card>
                ))}
                <Button variant="outline" width="100%" onClick={addMlsAccount}>
                  + Add Another MLS Account
                </Button>
              </>
            )}

            <FormControl>
              <Checkbox isRequired sx={{ alignItems: 'start' }}>
                <Text fontSize="xs">
                  I agree to receive texts for important updates e.g. new offers
                  on my listing. Message/data rates apply. Opt-out by replying
                  STOP.
                </Text>
              </Checkbox>
              <br />
              <Checkbox isRequired mt={2} sx={{ alignItems: 'center' }}>
                <Text fontSize="xs">
                  I agree to the{' '}
                  <Link
                    isExternal
                    color="skyblue"
                    href="https://www.useindigo.com/terms-of-use"
                  >
                    Terms of Use
                  </Link>{' '}
                  and{' '}
                  <Link
                    isExternal
                    color="skyblue"
                    href="https://www.useindigo.com/privacy-policy"
                  >
                    Privacy Policy.
                  </Link>{' '}
                </Text>
              </Checkbox>
            </FormControl>

            <Button isLoading={loading} type="submit" width="100%">
              Continue
            </Button>
          </VStack>
        );
      }}
    </Formik>
  );
};
