import {
  FormControl,
  FormLabel,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';
import {
  ContractValidationFragment,
  FieldType,
} from '@client/graphql/__generated__/types';
import { FC, memo } from 'react';
import { PercentageTag } from '~/common/components/PercentageTag';
import { useFormatFieldValue } from '~/services/document/components/Fields/hooks/useFormatFieldValue';
import {
  FormField,
  Values,
} from '~/services/document/components/FormFields/types';
import { PrefillSellerPrefs } from '../../pages/Storefront/OfferFlow/KeyTermsPage';
import { OfferValidationDisplay } from '../OfferFlow/OfferValidations';
import { InputField, InputFieldProps } from './InputField';

interface FormFieldControlProps extends InputFieldProps {
  formField: FormField;
  value: string | number | boolean | string[] | undefined;
  prefillSellerPrefs?: PrefillSellerPrefs;
  idx?: number;
  shouldAutoFocus?: boolean;
  isRequired?: boolean;
  label: string;
  autofillValue?: Values;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  optionsAutofillValue?: any;
  validation: ContractValidationFragment;
}

export const FormFieldControl: FC<FormFieldControlProps> = memo(
  function FormFieldControl({
    formField,
    value,
    prefillSellerPrefs,
    shouldAutoFocus = true,
    isRequired,
    label,
    autofillValue,
    optionsAutofillValue,
    validation,
    onChange,
    onFocus,
    onBlur,
    idx,
  }) {
    const formattedValue = useFormatFieldValue({
      field: formField,
      value: value,
    });
    const formattedPlaceholder = useFormatFieldValue({
      field: formField,
      value: autofillValue,
    });

    return (
      <FormControl isDisabled={formField.readOnly} isRequired={isRequired}>
        <FormLabel variant="normal">{label}</FormLabel>
        <InputGroup
          _before={{
            content: `"${formattedValue as string}"`,
            position: 'absolute',
            left: '17px',
            top: '1px',
            right: 0,
            bottom: 0,
            display: 'flex',
            alignItems: 'center',
            transition: prefillSellerPrefs?.loading ? 'none' : 'color 0.5s',
            color: prefillSellerPrefs?.done ? 'primary.500' : 'transparent',
          }}
          position="relative"
        >
          <InputField
            autoFocus={shouldAutoFocus && idx === 0}
            color={prefillSellerPrefs?.done ? 'transparent' : 'inherit'}
            formField={formField}
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            optionsAutofillValue={optionsAutofillValue}
            placeholder={
              formattedPlaceholder
                ? `Seller prefers ${formattedPlaceholder as string}`
                : undefined
            }
            transition={prefillSellerPrefs?.done ? 'none' : undefined}
            value={value}
            onBlur={onBlur}
            onChange={onChange}
            onFocus={onFocus}
          />

          {((value && autofillValue) || validation) && (
            <InputRightElement height="100%" mr={4} width="auto">
              {validation && (
                <OfferValidationDisplay
                  showInputValue={false}
                  validation={validation}
                />
              )}
              {formField.field?.fieldType === FieldType.NUMBER && (
                <PercentageTag
                  compareValue={autofillValue as number}
                  value={value as number}
                />
              )}
            </InputRightElement>
          )}
        </InputGroup>
      </FormControl>
    );
  }
);
