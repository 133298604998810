import {
  Button,
  Center,
  Flex,
  Icon,
  Image,
  Link,
  Text,
  VStack,
} from '@chakra-ui/react';
import { ListingObject, Roles } from '@client/graphql/__generated__/types';
import { ArrowUpRightIcon, ForwardIcon } from 'lucide-react';
import { ShareStorefrontModalTrigger } from '~/apps/consumer/pages/ListingSetup/Dashboard/ShareStorefrontModalTrigger';
import { useListingSetupContext } from '~/apps/consumer/pages/ListingSetup/ListingSetupContext';
import {
  DEFAULT_PROPERTY_IMAGE_URL,
  getPropertySource,
} from '~/apps/consumer/utils/listing.utils';
import { getStorefrontRoute } from '~/apps/consumer/utils/storefront.utils';
import { IndigoLogoSmall } from '~/common/icons';
import { RoleFilter } from '../../../User/RoleFilter';
import { StorefrontSetupTourClassNameStep2 } from '../ListingSetupTourModal/constants';

export const ListingSetupSidebarHeader = () => {
  const { listing } = useListingSetupContext();

  const isPremium = listing?.isPremium;

  const premiumBadge = (
    <Center
      bg="purpleGradient"
      color="black"
      display="flex"
      gap={1}
      h="30px"
      w="full"
    >
      <IndigoLogoSmall color="black" height="12px" width="12px" />
      <Text fontSize="sm" fontWeight="semibold">
        Premium
      </Text>
    </Center>
  );

  return (
    <VStack gap={4} w="full">
      {isPremium && premiumBadge}
      <Flex
        alignItems="center"
        borderBottom="1px solid"
        borderBottomColor="border.divider"
        p={5}
        pt={isPremium ? 0 : 5}
        width="100%"
      >
        <Image
          borderRadius="4px"
          fallbackSrc={DEFAULT_PROPERTY_IMAGE_URL}
          height="40px"
          src={getPropertySource(listing.mlsListing?.photos[0])}
          width="50px"
        />
        <Flex alignItems="flex-start" direction="column" ml={2}>
          <Text fontWeight="medium" p={0} size="headline" zIndex={1}>
            {listing.mlsListing?.address?.streetNumberText}{' '}
            {listing.mlsListing?.address?.streetName}{' '}
            {listing.mlsListing?.address?.unit ?? ''}
          </Text>
          <Flex gap={3}>
            <RoleFilter roles={[Roles.AGENT]}>
              <ShareStorefrontModalTrigger listing={listing}>
                <Button
                  className={StorefrontSetupTourClassNameStep2}
                  variant="link"
                >
                  <Flex alignItems="center" gap={1}>
                    <Icon as={ForwardIcon} height="16px" width="16px" /> Share
                  </Flex>
                </Button>
              </ShareStorefrontModalTrigger>
            </RoleFilter>
            <Button
              isExternal
              as={Link}
              color="link.skyBlue"
              href={getStorefrontRoute(listing as ListingObject)}
              variant="link"
            >
              <Flex alignItems="center" gap={1}>
                <Icon as={ArrowUpRightIcon} height="16px" width="16px" />
                View
              </Flex>
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </VStack>
  );
};
