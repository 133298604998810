import { SearchIcon } from '@chakra-ui/icons';
import {
  Input,
  InputGroup,
  InputLeftElement,
  InputProps,
} from '@chakra-ui/react';
import debounce from 'lodash/debounce';
import { FC, memo, useMemo } from 'react';

export const SearchInput: FC<InputProps> = memo(function SearchInput({
  onChange,
  onInput,
  ...props
}) {
  const onChangeDebounce = useMemo(() => {
    if (onChange) {
      return debounce(onChange, 500);
    }
  }, [onChange]);

  const onInputDebounce = useMemo(() => {
    if (onInput) {
      return debounce(onInput, 500);
    }
  }, [onInput]);

  return (
    <InputGroup {...props}>
      <InputLeftElement pointerEvents="none">
        <SearchIcon color="gray.500" />
      </InputLeftElement>
      <Input
        pl={9}
        placeholder="Search"
        size="sm"
        type="search"
        onChange={onChangeDebounce}
        onInput={onInputDebounce}
        {...props}
      />
    </InputGroup>
  );
});
