import { Container, Flex, VStack } from '@chakra-ui/react';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { useNavigate } from 'react-router-dom';
import { BuyerOfferAnalysisProvider } from '../../components/BuyerOfferAnalysis/BuyerOfferAnalysisContext';
import { BuyerOfferAnalysisHeader } from '../../components/BuyerOfferAnalysis/BuyerOfferAnalysisHeader';
import { BuyerOfferAnalysisTopTags } from '../../components/BuyerOfferAnalysis/BuyerOfferAnalysisTopTags';
import { CompsCard } from '../../components/BuyerOfferAnalysis/Comps/CompsCard';
import { EditCompsModal } from '../../components/BuyerOfferAnalysis/Comps/EditCompsModal/EditCompsModal';
import { ContingenciesTable } from '../../components/BuyerOfferAnalysis/KeyTermsAndContingencies/ContingenciesTable';
import { KeyTermsTable } from '../../components/BuyerOfferAnalysis/KeyTermsAndContingencies/KeyTermsTable';
import { BuyerOfferAnalysisLeftSidebar } from '../../components/BuyerOfferAnalysis/LeftSidebar/BuyerOfferAnalysisLeftSidebar';
import { CONTAINER_PADDINGS } from '../../components/Layouts/Container';

export const BuyerOfferAnalysisPage = () => {
  const isBuyerOfferAnalysisEnabled = useFeatureFlagEnabled('offer-coach');
  const navigate = useNavigate();

  if (!isBuyerOfferAnalysisEnabled) {
    navigate('/');
  }

  return (
    <BuyerOfferAnalysisProvider>
      <BuyerOfferAnalysisHeader />
      <Flex height="100%" justifyContent="space-between">
        <BuyerOfferAnalysisLeftSidebar />
        <Container
          centerContent
          maxW="container.xl"
          minW="container.md"
          {...CONTAINER_PADDINGS}
        >
          <VStack gap={4} width="100%">
            <BuyerOfferAnalysisTopTags />
            <CompsCard />
            <KeyTermsTable />
            <ContingenciesTable />
          </VStack>
          <EditCompsModal />
        </Container>
        {/* TODO: Add back in once we add in offer score and chat on offer analysis page */}
        {/* <BuyerOfferAnalysisRightSidebar /> */}
      </Flex>
    </BuyerOfferAnalysisProvider>
  );
};
