import { DeepPartial } from '@chakra-ui/react';
import {
  RuleType,
  RuleValidationObject,
} from '@client/graphql/__generated__/types';
import { useMemo } from 'react';

export const useValidationsColor = (
  validationResults?: DeepPartial<RuleValidationObject>[],
  defaultColor = 'inherit'
) => {
  return useMemo(() => {
    const ruleTypes = validationResults?.map(
      (result) => result?.rule?.ruleType
    );

    if (ruleTypes?.includes(RuleType.ERROR)) {
      return 'validation.error';
    } else if (ruleTypes?.includes(RuleType.WARNING)) {
      return 'validation.warning';
    }

    return defaultColor;
  }, [defaultColor, validationResults]);
};
