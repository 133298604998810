import { StyleProps } from '@chakra-ui/react';
import { MlsListingFragment } from '@client/graphql/__generated__/types';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import {
  NumberFormatterOptions,
  numberFormatter,
} from '~/common/utils/formatter';

export const getDefaultDifferenceFormatter =
  ({ maxPrecision, type, unitSuffix }: NumberFormatterOptions) =>
  (difference?: number) => {
    if (isNil(difference)) {
      return undefined;
    }
    if (difference > 0) {
      return `+${
        numberFormatter(difference, {
          maxPrecision,
          type,
          unitSuffix,
        }) as string
      }`;
    } else if (difference < 0) {
      return `${
        numberFormatter(difference, {
          maxPrecision,
          type,
          unitSuffix,
        }) as string
      }`;
    } else {
      return '0';
    }
  };

export const POSITIVE_STYLE: StyleProps = {
  color: 'green.300',
};

export const NEGATIVE_STYLE: StyleProps = {
  color: 'red.400',
};

export const NEUTRAL_STYLE: StyleProps = {
  color: 'gray.400',
};

export const getDefaultDifferenceStyler = (difference?: number) => {
  if (isNil(difference)) {
    return undefined;
  }
  if (difference > 0) {
    return POSITIVE_STYLE;
  } else if (difference < 0) {
    return NEGATIVE_STYLE;
  } else {
    return NEUTRAL_STYLE;
  }
};

interface GetDefaultDifferenceComputerOptions {
  type: 'percent' | 'number';
  field: string;
}

export const getDefaultDifferenceComputer =
  ({ type, field }: GetDefaultDifferenceComputerOptions) =>
  (
    compMlsListing?: MlsListingFragment,
    mainMlsListing?: MlsListingFragment
  ) => {
    const compValue = get(compMlsListing, field) as number | undefined;
    const mainValue = get(mainMlsListing, field) as number | undefined;

    if (isNil(compValue) || isNil(mainValue)) {
      return undefined;
    }

    if (type === 'percent') {
      return (compValue - mainValue) / mainValue;
    } else {
      return compValue - mainValue;
    }
  };
