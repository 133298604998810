import {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  VStack,
} from '@chakra-ui/react';
import { FC, memo, useCallback, useRef } from 'react';
import { isAndroid } from 'react-device-detect';
import { ValuesType } from '~/services/document/components/FormFields/types';
import { DocumentSection, DocumentSectionProps } from '../DocumentSection';
import { DocumentSectionContainer } from './DocumentSectionContainer';

export const DocumentSectionTab: FC<DocumentSectionProps> = memo(
  function DocumentSectionTab(props) {
    const {
      documentSection,
      showTitle,
      isSubSection,
      styleRootSectionCard,
      tabVariant = 'outline',
      onChange,
    } = props;
    const tabPanelsRef = useRef<HTMLDivElement | null>(null);
    const onTabChange = useCallback(() => {
      const fields = documentSection.children?.flatMap(
        (child) => child.documentSectionFields
      );
      const fieldsMappingKeys = fields?.reduce((prev, next) => {
        if (next?.field.mappingKey) {
          prev[next?.field.mappingKey] = '';
        }

        return prev;
      }, {} as ValuesType);

      onChange({
        ...fieldsMappingKeys,
      });

      window.requestAnimationFrame(() => {
        tabPanelsRef.current?.scrollIntoView({
          behavior: isAndroid ? 'auto' : 'smooth',
          block: 'start',
        });
      });
    }, [documentSection.children, onChange]);

    return (
      <DocumentSectionContainer
        isSubSection={isSubSection}
        showTitle={showTitle}
        styleRootSectionCard={styleRootSectionCard}
        title={documentSection.title}
      >
        <VStack ref={tabPanelsRef} alignItems="stretch" flexGrow={1}>
          <Tabs isFitted variant={tabVariant} onChange={onTabChange}>
            <TabList>
              {documentSection.children?.map((child) => (
                <Tab key={child.id}>{child.title}</Tab>
              ))}
            </TabList>

            <TabPanels ref={tabPanelsRef}>
              {documentSection.children?.map((child) => (
                <TabPanel key={child.id}>
                  {!!child.documentSectionFields?.length && (
                    <DocumentSection
                      {...props}
                      documentSection={child}
                      isSubSection={false}
                      showTitle={false}
                      styleRootSectionCard={false}
                    />
                  )}
                </TabPanel>
              ))}
            </TabPanels>
          </Tabs>
        </VStack>
      </DocumentSectionContainer>
    );
  }
);
