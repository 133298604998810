import {
  Box,
  Button,
  Card,
  Center,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightElement,
  Link,
  Stack,
  Text,
  Textarea,
  VStack,
  useBreakpointValue,
  useToast,
} from '@chakra-ui/react';
import {
  useGetUserNotificationSettingsQuery,
  useUpdateUserMutation,
  useUpdateUserNotificationSettingsMutation,
} from '@client/graphql/__generated__/main-operations';
import { LicenseType, Roles } from '@client/graphql/__generated__/types';
import { Field, Form, Formik, FormikProps } from 'formik';
import { ArrowUpRight, Check, Copy } from 'lucide-react';
import { FC, useState } from 'react';
import { CenterSpinner } from '~/common/components/CenterSpinner';
import { MLSSelect } from '~/services/main/components/AgentSignUpFlow/MLSSelect';
import { useAppContext } from '~/services/main/contexts/AppContext';
import { CopyPasteButton } from '../../components/CopyPasteButton';
import { Container } from '../../components/Layouts/Container';
import { SIDEBAR_WIDTH_COLLAPSED } from '../../components/PageDetailLayout/PageDetailLayoutSidebar';
import { ProfilePicture } from '../../components/User/ProfilePicture';
import { RoleFilter } from '../../components/User/RoleFilter';
import { FeatureKey } from '../../hooks/useIsFeatureEnabledForStateOrMls';
import { getAgentProfilePage } from '../../utils/routes.utils';

export interface AccountPageInputs {
  firstName: string;
  lastName: string;
  userName: string;
  email: string;
  mlsAccounts: {
    licenseNumber: string;
    mlsId: string;
  }[];
  profilePictureUrl: string | null;
  profileBio: string | null;
  notifications: string[];
}

export const AccountPage: FC = () => {
  const { user, refetchUser } = useAppContext();

  const [updateUser, { loading: isUpdatingUser }] = useUpdateUserMutation();

  const [
    updateNotificationSettings,
    { loading: updateNotificationSettingsLoading },
  ] = useUpdateUserNotificationSettingsMutation();

  const {
    data: notificationSettingsData,
    loading: notificationSettingsDataLoading,
    refetch: refetchUserNotificationSettings,
  } = useGetUserNotificationSettingsQuery();

  const [newPictureFile, setNewPictureFile] = useState<File>();

  const toast = useToast();

  const userMlsAccounts =
    user?.licenses
      ?.filter((license) => license.licenseType === LicenseType.MLS)
      .map((license) => ({
        licenseNumber: license.licenseNumber,
        mlsId: license.mls?.id || '',
      }))
      .sort((a, b) => a.licenseNumber.localeCompare(b.licenseNumber)) || [];

  const onSubmit = async (values: AccountPageInputs) => {
    const { notifications } = values;

    // sets input to undefined if the value has not changed
    const input = {
      firstName:
        values.firstName !== user?.firstName ? values.firstName : undefined,
      lastName:
        values.lastName !== user?.lastName ? values.lastName : undefined,
      email: values.email !== user?.email ? values.email : undefined,
      userName:
        values.userName !== user?.userName ? values.userName : undefined,
      // Updating licenses is disabled for users
      mlsId: undefined,
      licenses: undefined,
      profilePicture:
        values.profilePictureUrl !== user?.profilePicture?.url
          ? newPictureFile
          : undefined,
      profileBio:
        values.profileBio !== user?.profileBio
          ? values.profileBio || ''
          : undefined,
    };

    await updateUser({
      variables: {
        input,
      },
    });
    await refetchUser();

    await updateNotificationSettings({
      variables: {
        input: {
          email: notifications.includes('email'),
          sms: notifications.includes('sms'),
        },
      },
    });
    await refetchUserNotificationSettings();

    toast({
      title: 'Account Updated',
      description: 'Your account has been successfully updated',
      status: 'success',
      isClosable: true,
      containerStyle: {
        position: 'relative',
        left: { base: '0px', md: `calc(${SIDEBAR_WIDTH_COLLAPSED} / 2)` },
      },
    });
  };

  const { email, sms } =
    notificationSettingsData?.userNotificationSettings || {};
  const notificationSettings = [email ? 'email' : '', sms ? 'sms' : ''].filter(
    Boolean
  );

  const isLoading =
    isUpdatingUser ||
    updateNotificationSettingsLoading ||
    notificationSettingsDataLoading;

  const urlPrefix = `${window.location.origin}/agent/`;
  const showFullUrlPrefix = useBreakpointValue({ base: false, md: true });

  return (
    <Container>
      <Flex justifyContent="space-between">
        <Heading as="h4" size="sm">
          Account
        </Heading>
        <Link color="whiteAlpha.600" href="/auth/logout">
          Log out
        </Link>
      </Flex>

      <Formik<AccountPageInputs>
        enableReinitialize
        initialValues={{
          firstName: user?.firstName || '',
          lastName: user?.lastName || '',
          email: user?.email || '',
          userName: user?.userName || '',
          mlsAccounts: userMlsAccounts || [],
          profilePictureUrl: user?.profilePicture?.url || null,
          profileBio: user?.profileBio || null,
          notifications: notificationSettings,
        }}
        onSubmit={onSubmit}
      >
        {(props: FormikProps<AccountPageInputs>) => (
          <Form>
            <Center flexDir="column">
              <VStack
                bgColor="bg.main"
                borderRadius="md"
                // NOTE: added a 'mb' here for better scroll experience on mobile
                maxWidth="500px"
                mb={{ base: '10vh', md: 0 }}
                minWidth="320px"
                p={{ base: 0, md: 6 }}
                spacing={4}
                width={{ base: '100%', md: 'auto' }}
              >
                <ProfilePicture
                  onProfilePictureChange={(picture, pictureUrl) => {
                    void props.setFieldValue('profilePictureUrl', pictureUrl);
                    setNewPictureFile(picture);
                  }}
                />
                <Card p={4} w="full">
                  <VStack gap={4}>
                    <Flex
                      alignContent="center"
                      direction="row"
                      justifyContent="space-between"
                      w="full"
                    >
                      <Text
                        color="whiteAlpha.600"
                        fontSize="xs"
                        fontWeight="bold"
                        textTransform="uppercase"
                      >
                        Profile
                      </Text>
                      <RoleFilter roles={[Roles.AGENT]}>
                        <Button
                          as={Link}
                          color="whiteAlpha.600"
                          href={getAgentProfilePage(user?.userName as string)}
                          leftIcon={<Icon as={ArrowUpRight} />}
                          rel="noopener noreferrer"
                          size="xs"
                          target="_blank"
                          variant="link"
                        >
                          View public profile
                        </Button>
                      </RoleFilter>
                    </Flex>
                    <FormControl isRequired>
                      <FormLabel>First Name</FormLabel>
                      <Input
                        as={Field}
                        data-testid="account-first-name-input"
                        id="firstName"
                        name="firstName"
                        placeholder="First Name"
                      />
                    </FormControl>

                    <FormControl isRequired>
                      <FormLabel>Last Name</FormLabel>
                      <Input
                        as={Field}
                        data-testid="account-last-name-input"
                        id="lastName"
                        name="lastName"
                        placeholder="Last Name"
                      />
                    </FormControl>

                    <FormControl isRequired>
                      <FormLabel>Email</FormLabel>
                      <Input
                        as={Field}
                        data-testid="account-email-input"
                        id="email"
                        name="email"
                        placeholder="Email"
                      />
                    </FormControl>

                    <RoleFilter roles={[Roles.AGENT]}>
                      <FormControl>
                        <FormLabel>
                          Public Profile URL (
                          <ContactUsDescription />)
                        </FormLabel>
                        <InputGroup>
                          <InputLeftAddon
                            color="whiteAlpha.600"
                            maxWidth={showFullUrlPrefix ? 'auto' : '160px'}
                            pointerEvents="none"
                            textOverflow="ellipsis"
                          >
                            {showFullUrlPrefix ? urlPrefix : `...agent/`}
                          </InputLeftAddon>
                          <Input
                            isDisabled
                            as={Field}
                            data-testid="account-username-input"
                            id="publicProfileUrl"
                            name="publicProfileUrl"
                            placeholder="username"
                            type="text"
                            value={props.values.userName}
                          />
                          <InputRightElement height="100%">
                            <CopyPasteButton
                              buttonContentCopied={<Icon as={Check} />}
                              buttonContentCopy={<Icon as={Copy} />}
                              copyText={urlPrefix + props.values.userName}
                              minWidth="40px"
                              size="md"
                              variant="link"
                            />
                          </InputRightElement>
                        </InputGroup>
                      </FormControl>
                      <FormControl>
                        <FormLabel>
                          Offer Collection Email{' '}
                          <Text as="span" textTransform="none">
                            (Send offer PDFs here)
                          </Text>
                        </FormLabel>
                        <InputGroup>
                          <Input
                            isDisabled
                            as={Field}
                            data-testid="account-offer-collection-email-input"
                            id="offerCollectionEmail"
                            name="offerCollectionEmail"
                            placeholder="No offer collection email configured"
                            type="text"
                            value={user?.offerCollectionEmail || ''}
                          />
                          {user?.offerCollectionEmail && (
                            <InputRightElement height="100%">
                              <CopyPasteButton
                                buttonContentCopied={<Icon as={Check} />}
                                buttonContentCopy={<Icon as={Copy} />}
                                copyText={user.offerCollectionEmail}
                                minWidth="40px"
                                size="md"
                                variant="link"
                              />
                            </InputRightElement>
                          )}
                        </InputGroup>
                      </FormControl>
                    </RoleFilter>

                    <FormControl>
                      <FormLabel>Bio</FormLabel>
                      <Textarea
                        data-testid="account-bio-input"
                        id="profileBio"
                        name="profileBio"
                        placeholder="Tell us a little bit about yourself!"
                        rows={4}
                        onChange={(e) => {
                          void props.setFieldValue(
                            'profileBio',
                            e.target.value
                          );
                        }}
                      />
                    </FormControl>
                  </VStack>
                </Card>

                <Card p={4} w="full">
                  <VStack gap={4}>
                    <Flex
                      alignContent="center"
                      direction="row"
                      justifyContent="space-between"
                      w="full"
                    >
                      <Text
                        color="whiteAlpha.600"
                        fontSize="xs"
                        fontWeight="bold"
                        textTransform="uppercase"
                      >
                        Settings
                      </Text>
                    </Flex>
                    <RoleFilter roles={[Roles.AGENT]}>
                      {props.values.mlsAccounts.map((mlsAccount, index) => (
                        <Card
                          key={`${mlsAccount.licenseNumber}-${mlsAccount.mlsId}`}
                          p={4}
                          w="full"
                        >
                          <VStack gap={4}>
                            <FormControl>
                              <FormLabel>
                                Agent MLS ID (
                                <ContactUsDescription />)
                              </FormLabel>
                              <Input
                                disabled
                                as={Field}
                                data-testid="account-mls-id-input"
                                id={`licenseNumber${index}`}
                                name={`mlsAccounts.${index}.licenseNumber`}
                                placeholder="Agent MLS ID you use to log into your MLS account."
                                value={mlsAccount.licenseNumber}
                              />
                            </FormControl>

                            <FormControl>
                              <FormLabel>MLS</FormLabel>
                              <Stack spacing={3}>
                                <MLSSelect
                                  isDisabled
                                  featureKeyFilter={FeatureKey.SIGN_UP}
                                  id={`mlsId${index}`}
                                  name={`mlsAccounts.${index}.mlsId`}
                                  selectedMlsId={mlsAccount.mlsId}
                                  onChange={(value) =>
                                    void props.setFieldValue(
                                      `mlsAccounts.${index}.mlsId`,
                                      value?.value
                                    )
                                  }
                                />
                              </Stack>
                            </FormControl>
                          </VStack>
                        </Card>
                      ))}
                    </RoleFilter>

                    <FormControl as="fieldset">
                      <FormLabel>Notifications</FormLabel>
                      <Stack display="flex" flexDirection="row" spacing={1}>
                        {notificationSettingsDataLoading ||
                        updateNotificationSettingsLoading ? (
                          <Flex>
                            <CenterSpinner />
                          </Flex>
                        ) : (
                          <>
                            <Box
                              bg="whiteAlpha.50"
                              border="1px solid"
                              borderColor="whiteAlpha.50"
                              borderRadius="xs"
                              px={2}
                              py={1}
                            >
                              <Field
                                as={Checkbox}
                                isChecked={props.values.notifications.includes(
                                  'email'
                                )}
                                name="notifications"
                                value="email"
                              >
                                Email
                              </Field>
                            </Box>
                            <Box
                              bg="whiteAlpha.50"
                              border="1px solid"
                              borderColor="whiteAlpha.50"
                              borderRadius="xs"
                              px={2}
                              py={1}
                            >
                              <Field
                                as={Checkbox}
                                isChecked={props.values.notifications.includes(
                                  'sms'
                                )}
                                name="notifications"
                                value="sms"
                              >
                                SMS
                              </Field>
                            </Box>
                          </>
                        )}
                      </Stack>
                    </FormControl>
                  </VStack>
                </Card>

                <Button
                  alignSelf="flex-start"
                  isDisabled={!props.dirty}
                  isLoading={isLoading}
                  mt={2}
                  size="md"
                  type="submit"
                  w="full"
                >
                  Save
                </Button>
              </VStack>
            </Center>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

const ContactUsDescription = () => {
  return (
    <Link href="mailto:help@useindigo.com" textTransform="none">
      Contact us to update
    </Link>
  );
};
