import {
  Box,
  Button,
  Flex,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { useOpenHousesForCurrentUserQuery } from '@client/graphql/__generated__/main-operations';
import {
  OffersForCurrentUserFragment,
  OpenHouseForCurrentUserFragment,
} from '@client/graphql/__generated__/types';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { ArrowUpRight, MapPinHouse, Plus } from 'lucide-react';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { LoadingCenter } from '~/services/document/common/LoadingCenter';
import { useInfiniteQuery } from '~/services/document/hooks/useInfiniteQuery';
import { CardEmptyState } from '../../components/CardEmptyState';
import { DashboardItem } from '../../components/Dashboard/DashboardItem';
import {
  listingGuestbookPath,
  openHousePublicSignupPath,
} from '../../utils/routes.utils';
import { OpenHouseCreationModal } from './OpenHouseCreationModal';

dayjs.extend(utc);

type OpenHouseTimeStatus = 'upcoming' | 'past';

type OpenHouseOverviewModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const formatOpenHouseDateTime = (
  openHouse: OpenHouseForCurrentUserFragment
) => {
  const formatTime = (date: string, time: string) =>
    dayjs
      .utc(`${date}T${time}`)
      .local()
      .format('h:mma')
      .toLowerCase()
      .replace(':00', '');

  const date = dayjs(openHouse.date).format('MMM D, YYYY');
  const startTime = formatTime(openHouse.date, openHouse.startTime);
  const endTime = formatTime(openHouse.date, openHouse.endTime);

  return { date, time: `${startTime} - ${endTime}` };
};

const OpenHouseItem = ({
  openHouse,
}: {
  openHouse: OpenHouseForCurrentUserFragment;
}) => (
  <Box
    key={openHouse.id}
    borderBottom="1px solid"
    borderColor="whiteAlpha.200"
    w="full"
  >
    <DashboardItem
      key={openHouse.id}
      externalUrl={openHousePublicSignupPath(openHouse.id)}
      listing={openHouse.listing as OffersForCurrentUserFragment['listing']}
      rightElement={
        <Button
          bg="whiteAlpha.50"
          rightIcon={<ArrowUpRight size={14} />}
          size="xs"
          variant="ghost"
        >
          Open signup link
        </Button>
      }
    >
      <HStack>
        <Text>
          {openHouse.listing.mlsListing?.address.streetNumber}{' '}
          {openHouse.listing.mlsListing?.address.streetName}
        </Text>
      </HStack>
      <Flex
        color="whiteAlpha.500"
        direction={{ base: 'column', md: 'row' }}
        gap={1}
      >
        <Text>{formatOpenHouseDateTime(openHouse).date} •</Text>
        <Text>{formatOpenHouseDateTime(openHouse).time} •</Text>
        <Text
          as={NavLink}
          textDecoration="underline"
          to={listingGuestbookPath(openHouse.listing.id)}
        >
          {openHouse.listing.followerCount} Guestbook members
        </Text>
      </Flex>
    </DashboardItem>
  </Box>
);

export const OpenHouseOverviewModal = ({
  isOpen,
  onClose,
}: OpenHouseOverviewModalProps) => {
  const [activeTab, setActiveTab] = useState<OpenHouseTimeStatus>('upcoming');
  const createOpenHouseModal = useDisclosure();

  const [queryRef, { data, loading, refetch }] = useInfiniteQuery(
    useOpenHousesForCurrentUserQuery,
    {
      variables: {
        filters: {
          isUpcoming: activeTab === 'upcoming',
        },
        // Sorted by closest date first
        orderBy: activeTab === 'upcoming' ? 'date' : '-date',
      },
      skip: !isOpen,
    }
  );

  const openHouses = data?.openHousesForCurrentUser?.results;

  const renderTabPanel = () => {
    const hasOpenHouses = openHouses && openHouses?.length !== 0;

    return (
      <TabPanel p={0}>
        {!hasOpenHouses && !loading ? (
          <Flex align="center" justify="center" minH="300px">
            <CardEmptyState
              description={`You don't have any ${activeTab} open houses scheduled.`}
              icon={MapPinHouse}
              title={`No ${activeTab} open houses`}
            />
          </Flex>
        ) : (
          openHouses?.map((openHouse) => (
            <OpenHouseItem key={openHouse.id} openHouse={openHouse} />
          ))
        )}
        {loading && <LoadingCenter />}
        {hasOpenHouses && (
          <div ref={queryRef} style={{ position: 'relative', bottom: 100 }} />
        )}
      </TabPanel>
    );
  };

  return (
    <>
      <Modal
        blockScrollOnMount={false}
        isOpen={isOpen}
        size="xl"
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            alignItems="center"
            borderBottom="none"
            display="flex"
            pb={0}
            w="calc(100% - 30px)"
          >
            <Text flex={1} fontSize="xs" textTransform="uppercase">
              Manage Open Houses
            </Text>
            <Button
              alignItems="center"
              borderRadius="xs"
              cursor="pointer"
              h="30px"
              size="sm"
              w="60px"
              onClick={createOpenHouseModal.onOpen}
            >
              <Plus size={14} />
              New
            </Button>
          </ModalHeader>
          <ModalCloseButton mt={2} />
          <ModalBody px={0} py={2}>
            <Tabs
              variant="unstyled"
              onChange={(index) =>
                setActiveTab(index === 0 ? 'upcoming' : 'past')
              }
            >
              <TabList px={4}>
                {['upcoming', 'past'].map((tab) => (
                  <Tab
                    key={tab}
                    color={activeTab === tab ? 'white' : 'whiteAlpha.600'}
                    px={2}
                  >
                    {tab.charAt(0).toUpperCase() + tab.slice(1)}
                  </Tab>
                ))}
              </TabList>
              <TabPanels h="320px" overflowY="auto" pl={1}>
                {renderTabPanel()}
                {renderTabPanel()}
              </TabPanels>
            </Tabs>
          </ModalBody>
        </ModalContent>
      </Modal>
      <OpenHouseCreationModal
        isOpen={createOpenHouseModal.isOpen}
        onClose={createOpenHouseModal.onClose}
        onCreated={() => void refetch()}
      />
    </>
  );
};
