import { PinchGesture } from '@use-gesture/vanilla';
import {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { KeyboardManager } from './useKeyboardManager';

const MAX_ZOOM = 3;
const ZOOM_OFFSET = 0.1;

export const useZoom = (options: {
  keyboardManager: KeyboardManager;
  scrollableRef?: MutableRefObject<HTMLDivElement>;
  zoomContainerRef?: MutableRefObject<HTMLDivElement>;
  defaultZoom?: number;
  onZoom?: (zoom: number) => void;
}) => {
  const defaultZoom = options.defaultZoom || 1;
  const onZoom = options.onZoom;
  const zoomRef = useRef(defaultZoom);
  const [zoom, setZoom] = useState(defaultZoom);
  const { keyboardManager, scrollableRef, zoomContainerRef } = options;
  const addEventListener = keyboardManager.addEventListener;

  const updateZoom = useCallback(
    (zoom: number) => {
      const scrollableElem = scrollableRef?.current;
      const zoomContainer = zoomContainerRef?.current;

      if (!zoomContainer || !scrollableElem) return;

      const scrollTop = scrollableElem.scrollTop;
      const prevZoom = zoomRef.current;

      if (zoom < 1) {
        zoomRef.current = 1;
      } else if (zoom > MAX_ZOOM) {
        zoomRef.current = MAX_ZOOM;
      } else {
        zoomRef.current = zoom;
      }

      setZoom(zoomRef.current);

      if (zoomContainer) {
        zoomContainer.style.transform = `scale(${zoomRef.current})`;
      }

      const zoomRatio = zoomRef.current / prevZoom;

      if (zoomRatio !== 1) {
        scrollableElem?.scrollTo({
          top: scrollTop * zoomRatio,
        });
      }

      window.requestAnimationFrame(() => {
        onZoom && onZoom(zoomRef.current);
      });
    },
    [scrollableRef, zoomContainerRef, onZoom]
  );

  useEffect(() => {
    const handler = (e: Event) => e.preventDefault();
    document.addEventListener('gesturestart', handler);
    document.addEventListener('gesturechange', handler);
    document.addEventListener('gestureend', handler);

    return () => {
      document.removeEventListener('gesturestart', handler);
      document.removeEventListener('gesturechange', handler);
      document.removeEventListener('gestureend', handler);
    };
  }, []);

  useEffect(() => {
    if (zoomContainerRef?.current) {
      zoomContainerRef.current.style.transform = `scale(${zoomRef.current})`;
    }
  }, [zoomContainerRef]);

  useEffect(() => {
    addEventListener(({ equal, minus, meta }) => {
      const scrollableElem = scrollableRef?.current;
      const currentZoom = zoomRef.current;

      if (!meta || (!equal && !minus)) {
        return;
      }

      updateZoom(equal ? currentZoom + ZOOM_OFFSET : currentZoom - ZOOM_OFFSET);

      if (scrollableElem && (equal || minus)) {
        const scrollingWidth =
          scrollableElem.scrollWidth - scrollableElem.offsetWidth;

        scrollableElem.scrollTo({
          left: scrollingWidth / 2,
        });
      }
    }, 'useZoom');
  }, [addEventListener, scrollableRef, updateZoom, zoomRef]);

  useEffect(() => {
    if (!scrollableRef?.current) return;

    const gesture = new PinchGesture(
      scrollableRef?.current,
      ({ origin, direction, last }) => {
        const scrollableElem = scrollableRef?.current;
        const zoomContainer = zoomContainerRef?.current;

        if (
          last ||
          !zoomContainer ||
          !scrollableElem ||
          (zoomRef.current === MAX_ZOOM && direction[0] > 0) ||
          (zoomRef.current === 1 && direction[0] < 0)
        )
          return;

        const { left } = scrollableElem.getBoundingClientRect();
        const prevZoom = zoomRef.current;

        updateZoom(
          direction[0] > 0 ? prevZoom + ZOOM_OFFSET : prevZoom - ZOOM_OFFSET
        );

        const mousePositionRatio =
          (origin[0] - left) / scrollableElem.offsetWidth;
        const scrollingWidth =
          scrollableElem.scrollWidth - scrollableElem.offsetWidth;

        if (prevZoom !== zoomRef.current) {
          scrollableElem.scrollTo({
            left: scrollingWidth * mousePositionRatio,
          });
        }
      },
      {
        preventDefault: true,
        from: () => {
          return [zoomRef.current, 0];
        },
        pinchOnWheel: true,
        rubberband: true,
        modifierKey: 'metaKey',
        scaleBounds: {
          min: 1,
          max: MAX_ZOOM,
        },
      }
    );

    return () => {
      gesture.destroy();
    };
  }, [defaultZoom, scrollableRef, updateZoom, zoomContainerRef]);

  return {
    zoom,
  };
};
