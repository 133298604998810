import {
  useBuyerClientsForCurrentAgentUserQuery,
  useCreatePartyForBuyersAgentMutation,
  useUpdatePartyForBuyersAgentMutation,
} from '@client/graphql/__generated__/main-operations';
import { PartyFragment } from '@client/graphql/__generated__/types';
import { BuyerFormValues } from '../../types';

interface UseAddBuyerFormProps {
  selectedParty?: PartyFragment;
  preapprovalFile?: File;
  onSelectParty?: (party: PartyFragment) => void;
}

export const useAddBuyerForm = (options: UseAddBuyerFormProps) => {
  const {
    data: buyerParties,
    loading,
    refetch,
  } = useBuyerClientsForCurrentAgentUserQuery();
  const [createPartyForBuyersAgent, { loading: creatingParty }] =
    useCreatePartyForBuyersAgentMutation();
  const [updatePartyForBuyersAgent, { loading: updatingParty }] =
    useUpdatePartyForBuyersAgentMutation();

  const createParty = async (values: BuyerFormValues) => {
    const { data } = await createPartyForBuyersAgent({
      variables: {
        input: {
          primaryBuyer: {
            fullName: values.buyerName,
            email: values.buyerEmail,
            isEntity: values.isEntity,
            entityName: values.entityName,
            entityTitle: values.entityTitle,
          },
          secondaryBuyers:
            values.coBuyerName && values.coBuyerEmail
              ? [
                  {
                    fullName: values.coBuyerName,
                    email: values.coBuyerEmail,
                  },
                ]
              : undefined,
          financingType: values.financingType,
          loanAmount: values.loanAmount ? Number(values.loanAmount) : undefined,
          percentDownPayment: values.percentDownPayment
            ? Number(values.percentDownPayment)
            : undefined,
          cashReserves: values.cashReserves
            ? Number(values.cashReserves)
            : undefined,
          preapprovalFile: options.preapprovalFile,
        },
      },
    });

    const refetched = await refetch();

    const createdParty = refetched?.data.buyerClientsForCurrentAgentUser.find(
      (party) => party.id === data?.createPartyForBuyersAgent.id
    );

    if (createdParty) {
      options.onSelectParty?.(createdParty);
    }

    return createdParty;
  };

  const updateParty = async (values: BuyerFormValues) => {
    const { data } = await updatePartyForBuyersAgent({
      variables: {
        input: {
          partyUuid: options.selectedParty?.id as string,
          primaryBuyer: {
            fullName: values.buyerName,
            email: values.buyerEmail,
            isEntity: values.isEntity,
            entityName: values.entityName,
            entityTitle: values.entityTitle,
          },
          secondaryBuyers:
            values.coBuyerName && values.coBuyerEmail
              ? [
                  {
                    fullName: values.coBuyerName,
                    email: values.coBuyerEmail,
                  },
                ]
              : undefined,
          financingType: values.financingType,
          loanAmount: values.loanAmount ? Number(values.loanAmount) : undefined,
          percentDownPayment: values.percentDownPayment
            ? Number(values.percentDownPayment)
            : undefined,
          cashReserves: values.cashReserves
            ? Number(values.cashReserves)
            : undefined,
          preapprovalFile: options.preapprovalFile,
        },
      },
    });

    const updatedParty = data?.updatePartyForBuyersAgent;

    if (updatedParty) {
      options.onSelectParty?.(updatedParty);
    }

    return updatedParty;
  };

  return {
    buyerParties: buyerParties?.buyerClientsForCurrentAgentUser,
    loading,
    createParty,
    creatingParty,
    updateParty,
    updatingParty,
  };
};
