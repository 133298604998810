import { Text } from '@chakra-ui/react';

export const NewText = () => {
  return (
    <Text
      as="sup"
      bg="purpleGradient"
      bgClip="text"
      fontSize="11px"
      fontWeight={500}
      letterSpacing="0.55px"
      lineHeight="normal"
      textTransform="uppercase"
    >
      NEW!
    </Text>
  );
};
