import { NumberInputProps } from '@chakra-ui/react';
import { useField } from 'formik';
import { PercentInput } from '~/common/components/Inputs/PercentInput';

export interface PercentFieldProps
  extends Omit<NumberInputProps, 'onChange' | 'value'> {
  name: string;
}

export const PercentField = ({ name, ...rest }: PercentFieldProps) => {
  const [field] = useField(name);

  const handleChange = (value?: number) => {
    field.onChange(name)({
      target: { value },
    } as unknown as React.ChangeEvent<HTMLInputElement>);
  };

  return (
    <PercentInput
      name={field.name}
      value={field.value as number}
      onBlur={field.onBlur(name)}
      onChange={handleChange}
      {...rest}
    />
  );
};
