export const useAuth = () => {
  const logout = () => {
    return fetch('/auth/logout', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: '{}',
    });
  };

  return {
    logout,
  };
};
