import {
  Box,
  HStack,
  Heading,
  Icon,
  IconButton,
  Tag,
  Td,
  Text,
  Tr,
  VStack,
} from '@chakra-ui/react';
import { AccessRole } from '@client/graphql/__generated__/types';
import { Star, Trash2 } from 'lucide-react';
import { FC, memo } from 'react';
import { useNavigate } from 'react-router';
import { UserAvatar } from '~/apps/consumer/components/User/UserAvatar';
import { colors } from '~/common/theme';
import { formatPhoneNumber } from '~/common/utils/formatter';
import { ConfirmModal } from '../Modal/ConfirmModal';
import { AccessControlWithIsPending } from './types';

interface AccessControlListRowProps {
  accessControl: AccessControlWithIsPending;
  currentUserId: string;
  isAdmin: boolean;
  onDelete: (accessControl: AccessControlWithIsPending) => Promise<void>;
}

export const AccessControlListRow: FC<AccessControlListRowProps> = memo(
  function AccessControlListRow({
    accessControl,
    currentUserId,
    isAdmin,
    onDelete,
  }) {
    const navigate = useNavigate();

    return (
      <>
        <Tr>
          <Td>
            <HStack>
              <UserAvatar size="sm" user={accessControl.accessUser} />
              <VStack alignItems="flex-start" spacing={1}>
                <Text fontSize="sm" lineHeight={1.2} verticalAlign="middle">
                  {accessControl.accessUser?.fullName && (
                    <Box as="span" mr={2}>
                      <strong>{accessControl.accessUser?.fullName}</strong>
                    </Box>
                  )}
                  {accessControl.isPending && (
                    <Tag colorScheme="purple" size="sm">
                      Pending
                    </Tag>
                  )}
                </Text>
                <Heading as="h6" color="gray.500" size="smallUppercase">
                  {accessControl.accessRole}
                  {currentUserId === accessControl?.accessUser?.id && (
                    <>
                      <Icon
                        as={Star}
                        fill={colors.purple[500]}
                        height="10px"
                        ml={2}
                        stroke="0"
                        width="10px"
                      />{' '}
                      You
                    </>
                  )}
                </Heading>
              </VStack>
            </HStack>
          </Td>
          <Td>{formatPhoneNumber(accessControl.accessUser?.phoneNumber)}</Td>
          <Td>{accessControl.accessUser?.email}</Td>
          <Td>
            {accessControl.accessRole !== AccessRole.OWNER &&
              (isAdmin || accessControl.accessUser?.id === currentUserId) && (
                <ConfirmModal
                  isCentered
                  description={
                    <Text as="span">
                      Are you sure you want to remove{' '}
                      <strong>
                        {accessControl.accessUser?.fullName ||
                          accessControl.accessUser?.email ||
                          ''}{' '}
                      </strong>
                      ?
                    </Text>
                  }
                  title="Remove User"
                  onConfirm={(onClose) => {
                    void onDelete(accessControl).then(() => {
                      if (accessControl.accessUser?.id === currentUserId) {
                        navigate('/', { replace: true });
                      } else {
                        onClose();
                      }
                    });
                  }}
                >
                  {(onOpen) => (
                    <IconButton
                      aria-label="Remove user"
                      colorScheme="gray"
                      data-testid="remove-user-button"
                      height="30px"
                      icon={<Icon as={Trash2} color="gray.500" />}
                      size="sm"
                      variant="ghost"
                      onClick={onOpen}
                    />
                  )}
                </ConfirmModal>
              )}
          </Td>
        </Tr>
      </>
    );
  }
);
