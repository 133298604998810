import { createContext, memo, useMemo, useState } from 'react';
import { useDocumentVersionValues } from '../DocumentVersionMapper/hooks/useDocumentVersionValues';
import { FormField, ValuesType } from '../FormFields/types';

interface DocumentFormFieldsContextValues {
  formFields: FormField[][];
  repositionedFormFields: FormField[][];
  values: ValuesType;
  isRepositionedVisible: boolean;
  showValue: boolean;
  setIsRepositionVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setShowValue: React.Dispatch<React.SetStateAction<boolean>>;
  updateValues: (values: ValuesType) => void;
}

interface DocumentFormFieldsProviderProps {
  formFields: FormField[][];
  repositionedFormFields: FormField[][];
  values?: ValuesType;
  children: React.ReactNode;
}

export const DocumentFormFieldsContext =
  createContext<DocumentFormFieldsContextValues>({
    formFields: [],
    repositionedFormFields: [],
    values: {},
    isRepositionedVisible: true,
    showValue: false,
    setShowValue: () => {},
    updateValues: () => {},
    setIsRepositionVisible: () => {},
  });

export const DocumentFormFieldsProvider = memo(
  function DocumentFormFieldsProvider(props: DocumentFormFieldsProviderProps) {
    const [showValue, setShowValue] = useState(true);
    const [isRepositionedVisible, setIsRepositionVisible] = useState(true);
    const documentVersionFields = useMemo(() => {
      return props.formFields.reduce((output, formFields) => {
        return output.concat(formFields);
      }, []);
    }, [props.formFields]);

    const { updateValues, values } = useDocumentVersionValues({
      documentVersionFields,
      values: props.values || {},
    });

    return (
      <DocumentFormFieldsContext.Provider
        value={{
          formFields: props.formFields,
          values,
          updateValues,
          showValue,
          isRepositionedVisible,
          setIsRepositionVisible,
          setShowValue,
          repositionedFormFields: props.repositionedFormFields,
        }}
      >
        {props.children}
      </DocumentFormFieldsContext.Provider>
    );
  }
);
