import { debounce } from 'lodash';
import { MutableRefObject, useEffect, useMemo, useState } from 'react';

export const usePdfSize = (options: {
  maxWidth: number;
  scrollableRef: MutableRefObject<HTMLDivElement>;
  pdfContainerRef: MutableRefObject<HTMLDivElement>;
}) => {
  const { maxWidth, scrollableRef, pdfContainerRef } = options;
  const [scrollWindow, setScrollWindow] = useState<UIEvent>();

  const setScrollWindowDebounce = useMemo(
    () => debounce(setScrollWindow, 300),
    [setScrollWindow]
  );
  const { height, width } = useMemo(() => {
    if (pdfContainerRef.current && scrollableRef.current) {
      const { top } = scrollableRef.current.getBoundingClientRect();
      const offsetWidth = pdfContainerRef.current.offsetWidth;
      const width = offsetWidth > maxWidth ? maxWidth : offsetWidth - 5;

      return {
        height: scrollableRef.current.offsetHeight - top,
        width,
      };
    }

    return {};
    // scrollWindow - to recompute whenever the browser is resized
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollableRef.current, pdfContainerRef.current, scrollWindow]);

  useEffect(() => {
    const onReisze = (e: UIEvent) => {
      const { width, height } = scrollableRef.current.getBoundingClientRect();

      if (width !== 0 && height !== 0) {
        setScrollWindowDebounce(e);
      }
    };

    window.addEventListener('resize', onReisze);

    return () => {
      window.removeEventListener('resize', onReisze);
    };
  }, [scrollableRef, setScrollWindowDebounce]);

  return {
    height,
    width,
  };
};
