import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  InputGroup,
  InputLeftAddon,
  InputProps,
  InputRightAddon,
  Text,
} from '@chakra-ui/react';
import { ListProps } from 'react-virtualized';
import { CheckboxField, CheckboxFieldProps } from '../Formik/CheckboxField';
import {
  DatePickerField,
  DatePickerFieldProps,
} from '../Formik/DatePickerField';
import {
  MultiSelectField,
  MultiSelectFieldProps,
} from '../Formik/MultiSelectField';
import { RangeField, RangeFieldProps } from '../Formik/RangeField';
import {
  SingleSelectField,
  SingleSelectFieldProps,
} from '../Formik/SingleSelectField';
import { TextField } from '../Formik/TextField';
import {
  InputWithUnitSelect,
  InputWithUnitSelectProps,
} from '../InputWithUnitSelect/InputWithUnitSelect';
import { ListInput, ListInputProps } from '../ListInput/ListInput';
import {
  NumberInputField,
  NumberInputFieldProps,
} from '../NumberInputField/NumberInputField';

export enum StyledFormInputType {
  RANGE = 'range',
  TEXT = 'text',
  NUMBER = 'number',
  DATE = 'date',
  DATETIME = 'datetime',
  UNIT_SELECT = 'unitSelect',
  SELECT = 'select',
  CHECKBOX = 'checkbox',
  LIST = 'list',
}
interface StyledFormInputProps {
  label: string;
  helperText?: string;
  errorMessage?: string;
  isDisabled?: boolean;
  inputProps?:
    | RangeFieldProps
    | InputGroupProps
    | NumberInputFieldProps
    | DatePickerFieldProps
    | InputWithUnitSelectProps
    | MultiSelectFieldProps
    | SingleSelectFieldProps
    | CheckboxFieldProps
    | ListProps;
  labelProps?: { labelIsInline: boolean };
  type?: StyledFormInputType;
  isRequired?: boolean;
  hasPlaceholder?: boolean;
  hideLabel?: boolean;
}

interface InputGroupProps extends Omit<InputProps, 'name'> {
  name: string;
  leftText?: string;
  rightText?: string;
}

export const StyledFormInput = ({
  label,
  helperText,
  errorMessage,
  inputProps,
  labelProps = { labelIsInline: false },
  isRequired = false,
  isDisabled: disabled = false,
  hasPlaceholder = false,
  hideLabel = false,
  type,
}: StyledFormInputProps) => {
  const isDisabled = disabled;

  let input;
  switch (type) {
    case StyledFormInputType.RANGE:
      input = (
        <RangeField
          {...(inputProps as RangeFieldProps)}
          isDisabled={isDisabled}
        />
      );
      break;
    case StyledFormInputType.TEXT:
      const { leftText, rightText, name, ...rest } =
        inputProps as InputGroupProps;
      input = (
        <InputGroup>
          {leftText && <InputLeftAddon>{leftText}</InputLeftAddon>}
          <TextField
            name={name}
            {...rest}
            placeholder={(inputProps as InputProps)?.placeholder || ' '}
          />
          {rightText && <InputRightAddon>{rightText}</InputRightAddon>}
        </InputGroup>
      );
      break;
    case StyledFormInputType.NUMBER:
      input = <NumberInputField {...(inputProps as NumberInputFieldProps)} />;
      break;
    case StyledFormInputType.DATE:
      input = (
        <DatePickerField
          {...(inputProps as DatePickerFieldProps)}
          isDisabled={isDisabled}
        />
      );
      break;
    case StyledFormInputType.DATETIME:
      input = (
        <DatePickerField
          {...(inputProps as DatePickerFieldProps)}
          showTimeSelect
          isDisabled={isDisabled}
        />
      );
      break;
    case StyledFormInputType.UNIT_SELECT:
      input = (
        <InputWithUnitSelect {...(inputProps as InputWithUnitSelectProps)} />
      );
      break;
    case StyledFormInputType.SELECT:
      input = (inputProps as MultiSelectFieldProps | SingleSelectFieldProps)
        ?.isMulti ? (
        <MultiSelectField {...(inputProps as MultiSelectFieldProps)} />
      ) : (
        <SingleSelectField {...(inputProps as SingleSelectFieldProps)} />
      );
      break;
    case StyledFormInputType.CHECKBOX:
      input = <CheckboxField {...(inputProps as CheckboxFieldProps)} />;
      break;
    case StyledFormInputType.LIST:
      input = <ListInput {...(inputProps as ListInputProps)} />;
      break;
  }

  const { labelIsInline } = labelProps;

  return (
    <FormControl
      id="label"
      isDisabled={isDisabled}
      isInvalid={!!errorMessage}
      isRequired={isRequired}
      variant={
        type === StyledFormInputType.CHECKBOX
          ? ''
          : hasPlaceholder
            ? 'floatingWithPlaceholder'
            : 'floating'
      }
    >
      {labelIsInline ? (
        <>
          {/* placeholder needs to be empty string for the label to properly replace with the "floating" variant */}
          {input}

          {/* It is important that the Label comes after the Control due to css selectors */}
          {!hideLabel && <FormLabel>{label}</FormLabel>}
        </>
      ) : (
        <Flex
          alignItems={{ base: 'flex-start', md: 'center' }}
          direction={{ base: 'column', md: 'row' }}
          justifyContent="space-between"
          lineHeight={1}
        >
          <Text mb={{ base: 2, md: 0 }}>{label}</Text>
          <Box width={{ base: '100%', md: '96' }}>{input}</Box>
        </Flex>
      )}

      {helperText && <FormHelperText>{helperText}</FormHelperText>}
      {errorMessage && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
    </FormControl>
  );
};
