import {
  Button,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
} from '@chakra-ui/react';
import {
  useCreateBuyerOfferAnalysisMutation,
  useListingforMlsListingLazyQuery,
} from '@client/graphql/__generated__/main-operations';
import {
  MlsListingObject,
  PartyFragment,
} from '@client/graphql/__generated__/types';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import {
  ADD_BUYER_FORM_ID,
  AddBuyerForm,
} from '../../Buyer/AddBuyerForm/AddBuyerForm';
import { useAddBuyerForm } from '../../Buyer/AddBuyerForm/hooks/useAddBuyerForm';
import { PartySelector } from '../../Buyer/PartySelector/PartySelector';
import { SelectedPartyInfoForm } from '../../Buyer/SelectedPartyInfo/SelectedPartyInfoForm';
import { MLSListingRow } from '../../Listings/MLSListingRow';
import { FindPropertyPopoverList } from '../../MakeOffer/FindPropertyPopoverList';

interface AddBuyerOfferAnalysisModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const AddBuyerOfferAnalysisModal = ({
  isOpen,
  onClose,
}: AddBuyerOfferAnalysisModalProps) => {
  const navigate = useNavigate();
  const [listingForMlsListing, { loading: loadingListing }] =
    useListingforMlsListingLazyQuery();
  const [selectedMlsListing, setSelectedMlsListing] =
    useState<MlsListingObject | null>(null);
  const [selectedParty, setSelectedParty] = useState<PartyFragment | undefined>(
    undefined
  );
  const [isEditing, setIsEditing] = useState(false);
  const [showAddBuyerForm, setShowAddBuyerForm] = useState(false);

  const [
    createBuyerOfferAnalysis,
    { loading: loadingCreateBuyerOfferAnalysis },
  ] = useCreateBuyerOfferAnalysisMutation();

  const { buyerParties, loading, createParty, updateParty } = useAddBuyerForm({
    selectedParty: selectedParty,
    onSelectParty: setSelectedParty,
  });

  const getSelectedListingId = async (mlsListingObject: MlsListingObject) => {
    const { data } = await listingForMlsListing({
      variables: {
        input: {
          id: mlsListingObject.id,
        },
      },
    });

    if (data?.syncListingFromMLS) {
      return data.syncListingFromMLS.id;
    }
  };

  const onSubmit = async () => {
    if (!selectedMlsListing || !selectedParty) {
      return;
    }
    const listingId = await getSelectedListingId(selectedMlsListing);
    if (!listingId) {
      //TODO: SHOW ERROR TOAST
      return;
    }

    const { data } = await createBuyerOfferAnalysis({
      variables: {
        input: {
          listingId,
          partyId: selectedParty.id,
        },
      },
    });

    if (!data?.createBuyerOfferAnalysis) {
      //TODO: SHOW ERROR TOAST
      return;
    }

    navigate(`/offer-coach/${data.createBuyerOfferAnalysis.id}`);
  };

  return (
    <Modal
      isOpen={isOpen}
      scrollBehavior={showAddBuyerForm ? 'inside' : undefined}
      size="lg"
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Offer Coach</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack alignItems="stretch" flexGrow={1} spacing={4}>
            <FormControl>
              <FormLabel>Select a Property</FormLabel>
              <FindPropertyPopoverList
                closeMenuOnSelect
                size="sm"
                onSelect={(mlsListing) => {
                  setSelectedMlsListing(mlsListing);
                }}
              />
            </FormControl>
            {selectedMlsListing && (
              <MLSListingRow
                key={selectedMlsListing.id}
                listing={selectedMlsListing}
                onRemove={() => {
                  setSelectedMlsListing(null);
                }}
              />
            )}

            <FormControl>
              <FormLabel>Select a Buyer</FormLabel>

              <VStack alignItems="stretch" flexGrow={1} spacing={4}>
                <PartySelector
                  parties={buyerParties}
                  selectedParty={selectedParty}
                  setShowAddBuyerForm={setShowAddBuyerForm}
                  showAddBuyerForm={showAddBuyerForm}
                  onSelectParty={(party) => {
                    if (party?.id) {
                      setSelectedParty(party);
                    } else {
                      setSelectedParty(undefined);
                    }
                  }}
                />
                {showAddBuyerForm ? (
                  <AddBuyerForm
                    showFinancingInfo={true}
                    onSubmit={async (values) => {
                      // setIsLoading(true);
                      await createParty(values);
                      // setIsLoading(false);
                      setShowAddBuyerForm(false);
                      // buyerSelectProps.onCreateParty?.();
                    }}
                  />
                ) : undefined}
                {selectedParty ? (
                  <SelectedPartyInfoForm
                    handleSubmit={updateParty}
                    isEditing={isEditing}
                    party={selectedParty}
                    setIsEditing={setIsEditing}
                    showFinancingInfo={true}
                  />
                ) : undefined}

                {showAddBuyerForm && (
                  <Button
                    form={ADD_BUYER_FORM_ID}
                    isLoading={loading}
                    type="submit"
                  >
                    Add Buyer
                  </Button>
                )}
              </VStack>
            </FormControl>
          </VStack>
        </ModalBody>
        <ModalFooter px={8}>
          <Button
            isDisabled={!selectedParty || !selectedMlsListing || isEditing}
            isLoading={loadingCreateBuyerOfferAnalysis || loadingListing}
            width="100%"
            onClick={() => void onSubmit()}
          >
            Submit
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
