import {
  Box,
  Flex,
  HStack,
  Icon,
  Image,
  TabPanel,
  Text,
} from '@chakra-ui/react';
import { useDocumentVersionListForOfferFlowQuery } from '@client/graphql/__generated__/document-operations';
import {
  ContractDocumentFragment,
  DocumentVersionFieldFragment,
  DocumentVersionListFieldFragment,
  DocumentVersionObject,
  FieldType,
  PublicDocumentVersionObject,
} from '@client/graphql/__generated__/types';
import { DndContext, DragOverlay } from '@dnd-kit/core';
import { snapCenterToCursor } from '@dnd-kit/modifiers';
import { Lock } from 'lucide-react';
import { SetStateAction, memo, useCallback, useMemo, useState } from 'react';
import { useRegisteredRoles } from '~/apps/consumer/hooks/useRegisteredRoles';
import blurredMog from '~/apps/consumer/images/blurredMog.png';
import { useOfferFlowContext } from '~/apps/consumer/pages/Storefront/OfferFlow/OfferFlowContext';
import { useIsMobile } from '~/common/hooks/useIsMobile';
import {
  DocumentMapperProvider,
  useDocumentMapperContext,
} from '~/services/document/components/DocumentMapper/context';
import { useDragAndDropCallbacks } from '~/services/document/components/DocumentMapper/hooks/useDragAndDropCallbacks';
import { DocumentsThumbnailsWidgetWithContext } from '~/services/document/components/DocumentMapperWidgets/DocumentsThumbnailsWidget/DocumentsThumbnailsWidget';
import { mapById } from '~/services/document/components/DocumentVersionMapper/hooks/useDocumentVersionMapper';
import { PDFScrollable } from '~/services/document/components/PDFScrollable/PDFScrollable';
import {
  DocumentsThumbnails,
  PageMetadata,
  VisiblePage,
} from '~/services/document/components/PDFScrollable/types';
import {
  AddFormFieldButton,
  DraggableAddFormFieldButton,
} from './AddFormFieldButton/AddFormFieldButton';
import { PDFFormFieldsList } from './FormFields/PDFFormFieldsList';
import { useUpdateCustomFields } from './hooks/useUpdateCustomFields';

const signatureFields = [
  FieldType.SIGNATURE,
  FieldType.SIGNED_DATE,
  FieldType.INITIAL,
];

export interface PreviewTabContentProps {
  disableFormFields?: boolean;
  showPDF: boolean;
  contractDocument?: ContractDocumentFragment;
  documentVersion?: DocumentVersionObject | PublicDocumentVersionObject;
  setVisiblePageNumber: React.Dispatch<SetStateAction<number | undefined>>;
  isUploadedOffer?: boolean;
  customFields?: DocumentVersionFieldFragment[];
  showAddTextBoxControls?: boolean;
  contractId?: string;
}

export const PreviewTabContent = memo(function PreviewTabContent({
  contractDocument,
  contractId,
  disableFormFields,
  documentVersion,
  showPDF,
  setVisiblePageNumber,
  isUploadedOffer = false,
  customFields,
  showAddTextBoxControls = false,
}: PreviewTabContentProps) {
  const { setShowVerifyUserModal, fieldValues, updateFieldValues } =
    useOfferFlowContext();

  const [thumbs, setThumbnails] = useState<DocumentsThumbnails>({});
  const [visiblePage, setVisiblePage] = useState<VisiblePage>();

  const { isRegisteredAgent } = useRegisteredRoles();
  const isMobile = useIsMobile();
  const {
    customFormFieldsById,
    setPagesMetadata,
    onFormFieldChange,
    onFormFieldDelete,
  } = useUpdateCustomFields(
    customFields,
    contractDocument?.id,
    contractId,
    disableFormFields
  );
  const { data: documentVersionList } = useDocumentVersionListForOfferFlowQuery(
    {
      variables: {
        isAgentVerified: isRegisteredAgent,
        skipSecondaryVersions: true,
        documentVersionFields: true,
        filters: {
          id: documentVersion?.id,
        },
      },
      fetchPolicy: 'cache-first',
      skip: !documentVersion?.id,
    }
  );
  const documentVersionWithFields = isRegisteredAgent
    ? documentVersionList?.documentVersionList?.results?.[0]
    : documentVersionList?.publicDocumentVersionList?.results?.[0];

  const filteredDocumentVersionFields = useMemo(() => {
    return (
      documentVersionWithFields?.documentVersionFields?.filter(
        (field) => !signatureFields.includes(field.fieldType)
      ) || []
    );
  }, [documentVersionWithFields?.documentVersionFields]);

  const formFieldsById = useMemo(() => {
    return mapById(
      filteredDocumentVersionFields as DocumentVersionListFieldFragment[]
    );
  }, [filteredDocumentVersionFields]);

  return (
    <TabPanel height={isMobile ? '100%' : 'auto'} pb={0}>
      <HStack
        alignItems="stretch"
        height={isMobile ? '100%' : 'auto'}
        width="100%"
      >
        {!isMobile && (
          <Box
            maxHeight="calc(100vh - 100px)"
            width={{ base: '120px', '2xl': '200px' }}
          >
            {visiblePage && (
              <DocumentsThumbnailsWidgetWithContext
                documentsThumbnails={thumbs}
                visiblePage={visiblePage}
              />
            )}
          </Box>
        )}
        <Box
          bgColor="gray.400"
          height={isMobile ? '100%' : 'calc(100vh - 100px)'}
          overflow="hidden"
          position="relative"
          width="100%"
        >
          {showPDF &&
            (!isRegisteredAgent && !isUploadedOffer ? (
              <Flex
                alignItems="center"
                direction="column"
                gap={2}
                height="100%"
                overflow="auto"
              >
                <Flex
                  alignItems="center"
                  cursor="pointer"
                  direction="column"
                  gap={2}
                  height="0"
                  position="sticky"
                  top="calc(50% - 10px)"
                  zIndex={3}
                  onClick={() => {
                    setShowVerifyUserModal(true);
                  }}
                >
                  <Icon as={Lock} textDecoration="underline" />
                  <Text textDecoration="underline">
                    Please verify your phone number to unlock
                  </Text>
                </Flex>
                {documentVersion ? (
                  documentVersion?.previewImages
                    ?.toSorted((a, b) => a.ordering - b.ordering)
                    ?.map((image) => (
                      <Image
                        key={image.id}
                        filter="brightness(30%)"
                        src={image.file?.url}
                      />
                    ))
                ) : (
                  <Image
                    filter="brightness(30%)"
                    src={blurredMog}
                    width="100%"
                  />
                )}
              </Flex>
            ) : (
              <DocumentMapperProvider
                editFormFieldsById={formFieldsById}
                formFieldsById={customFormFieldsById}
                isSaveLoading={false}
                originalFormFieldsById={customFormFieldsById}
                updateValues={updateFieldValues}
                values={fieldValues}
                onFormFieldChange={onFormFieldChange}
                onFormFieldDelete={onFormFieldDelete}
                onPagesMetadataChange={setPagesMetadata}
              >
                <DroppablePDFContent
                  contractDocument={contractDocument}
                  disableFormFields={disableFormFields}
                  documentVersion={documentVersion}
                  setPagesMetadata={setPagesMetadata}
                  setThumbnails={setThumbnails}
                  setVisiblePage={setVisiblePage}
                  setVisiblePageNumber={setVisiblePageNumber}
                  showAddTextBoxControls={showAddTextBoxControls}
                />
              </DocumentMapperProvider>
            ))}
        </Box>
      </HStack>
    </TabPanel>
  );
});

interface DroppablePDFContentProps {
  disableFormFields?: boolean;
  showAddTextBoxControls?: boolean;
  contractDocument?: ContractDocumentFragment;
  documentVersion?: DocumentVersionObject | PublicDocumentVersionObject;
  setVisiblePage: React.Dispatch<SetStateAction<VisiblePage | undefined>>;
  setVisiblePageNumber: React.Dispatch<SetStateAction<number | undefined>>;
  setThumbnails: React.Dispatch<SetStateAction<DocumentsThumbnails>>;
  setPagesMetadata: React.Dispatch<SetStateAction<PageMetadata[]>>;
}

export const DroppablePDFContent = memo(
  ({
    disableFormFields,
    showAddTextBoxControls,
    contractDocument,
    documentVersion,
    setVisiblePage,
    setPagesMetadata,
    setThumbnails,
    setVisiblePageNumber,
  }: DroppablePDFContentProps) => {
    const { onFormFieldChange, dndKitDefaults, updateValues } =
      useDocumentMapperContext();
    const {
      activeDraggingItemData,
      dropAnimationConfig,
      setActiveDraggingItemData,
      sensors,
      fixCursorSnapOffset,
    } = dndKitDefaults;
    const isMobile = useIsMobile();
    const { onDragStart, onDragEnd } = useDragAndDropCallbacks({
      onFormFieldChange,
      setActiveDraggingItemData,
      shouldSetDefaultMappingKey: true,
      shouldSetCheckboxValues: true,
      updateValues,
    });

    const isUploadedFile = !!contractDocument?.file?.url;

    const pdfFile = isUploadedFile
      ? {
          url: contractDocument?.file?.url,
          name: contractDocument?.documentVersion?.document?.name,
        }
      : {
          url: (documentVersion as DocumentVersionObject)?.file?.url,
          name: documentVersion?.document?.name,
        };

    const onVisiblePageChange = useCallback(
      (visiblePage: VisiblePage) => {
        setVisiblePageNumber((prev) => {
          if (prev !== visiblePage.pageNumber) {
            return visiblePage.pageNumber;
          }

          return prev;
        });
        setVisiblePage(visiblePage);
      },
      [setVisiblePageNumber, setVisiblePage]
    );

    return (
      <DndContext
        collisionDetection={fixCursorSnapOffset}
        sensors={sensors}
        onDragEnd={onDragEnd}
        onDragStart={onDragStart}
      >
        {pdfFile.url && (
          <PDFScrollable
            defaultZoom={isMobile ? 1.5 : 1}
            disableFormFields={disableFormFields}
            maxWidth={1200}
            pageChildren={
              !isUploadedFile || showAddTextBoxControls
                ? PDFFormFieldsList
                : undefined
            }
            pdfFiles={[pdfFile]}
            onDocumentsThumbnailsChange={setThumbnails}
            onPagesMetadataChange={setPagesMetadata}
            onVisiblePageChange={onVisiblePageChange}
          />
        )}
        {showAddTextBoxControls && !disableFormFields && (
          <Flex
            bottom={{ base: 1, md: 4 }}
            gap={2}
            left={{ base: 2, md: 4 }}
            position="absolute"
          >
            <DraggableAddFormFieldButton
              fieldType={FieldType.TEXT}
              label="Add text box"
            />
            <DraggableAddFormFieldButton
              fieldType={FieldType.CHECKBOX}
              label="Add checkbox"
            />
            {activeDraggingItemData && (
              <DragOverlay
                dropAnimation={dropAnimationConfig}
                modifiers={[snapCenterToCursor]}
                style={{
                  pointerEvents: 'none',
                }}
              >
                <AddFormFieldButton
                  fieldType={activeDraggingItemData.fieldType}
                  label={activeDraggingItemData.label || 'Add field'}
                />
              </DragOverlay>
            )}
          </Flex>
        )}
      </DndContext>
    );
  }
);
