import { Flex, FlexProps, Icon } from '@chakra-ui/react';
import { X } from 'lucide-react';

interface DismissableInfoProps extends FlexProps {
  children: React.ReactNode | string;
  onClose?: () => void;
}

export const DismissableInfo = ({
  children,
  onClose,
  ...otherProps
}: DismissableInfoProps) => {
  return (
    <Flex
      alignItems="center"
      border="1px solid"
      borderColor="purple.500"
      borderRadius="8px"
      color="purple.500"
      gap={2}
      justifyContent="space-between"
      p={3}
      {...otherProps}
    >
      {children}
      {onClose && (
        <Icon
          aria-label="Close"
          as={X}
          boxSize={4}
          color="purple.500"
          cursor="pointer"
          onClick={onClose}
        />
      )}
    </Flex>
  );
};
