import { Center, Text } from '@chakra-ui/react';
import { FC, memo } from 'react';
import { VerifiedIcon } from '~/common/icons/VerifiedIcon';

interface VerifiedBadgeProps {
  size?: 'sm' | 'md' | 'lg';
}

const sizes = {
  sm: {
    fontSize: 'xs',
    iconSize: '12px',
    lineHeight: '24px',
    px: 2,
  },
  md: {
    fontSize: 'sm',
    iconSize: '14px',
    lineHeight: '28px',
    px: 2,
  },
  lg: {
    fontSize: 'md',
    iconSize: '16px',
    lineHeight: '32px',
    px: 3,
  },
};

export const VerifiedBadge: FC<VerifiedBadgeProps> = memo(
  function VerifiedBadge({ size = 'sm' }) {
    const { fontSize, iconSize, lineHeight, px } = sizes[size];

    return (
      <Center
        _light={{
          bgColor: 'gray.100',
        }}
        bgColor="whiteAlpha.100"
        borderRadius="xs"
        lineHeight={lineHeight}
        px={px}
      >
        <VerifiedIcon
          _light={{
            stroke: 'white',
          }}
          color="green.600"
          height={iconSize}
          width={iconSize}
        />

        <Text
          color="green.600"
          fontSize={fontSize}
          fontWeight="semibold"
          ml={1}
        >
          Verified
        </Text>
      </Center>
    );
  }
);
