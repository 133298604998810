import { Box } from '@chakra-ui/react';
import { useListingQuery } from '@client/graphql/__generated__/main-operations';
import { Outlet, useParams } from 'react-router-dom';
import { getPropertySource } from '~/apps/consumer/utils/listing.utils';
import { CenterSpinner } from '~/common/components/CenterSpinner';

export const StorefrontSetupWizard = () => {
  const { listingId } = useParams();

  const { data, loading, refetch } = useListingQuery({
    variables: {
      input: {
        id: listingId as string,
      },
      preferenceValues: true,
    },
    skip: !listingId,
  });

  if (loading) {
    return <CenterSpinner />;
  }

  const imageUrl = getPropertySource(data?.listing?.mlsListing?.photos[0]);

  return (
    <Box
      _before={{
        content: '""',
        position: 'absolute',
        zIndex: '-1',
        width: '100%',
        height: '100%',
        filter: 'blur(17px)',
        background: `url(${imageUrl}) lightgray 50% / cover no-repeat`,
        backgroundAttachment: 'fixed',
      }}
      backgroundColor="rgba(9, 11, 30, 0.90);" //TODO: don't hardcode
      maxWidth="100%"
      minHeight="100vh"
    >
      <Outlet
        context={{
          listing: data?.listing,
          refetch,
        }}
      />
    </Box>
  );
};
