import { Flex, Icon, Image, Tag, Text, Tooltip } from '@chakra-ui/react';
import { MlsListingFragment } from '@client/graphql/__generated__/types';
import { DraggableAttributes } from '@dnd-kit/core';
import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';
import { CSS, Transform } from '@dnd-kit/utilities';
import dayjs from 'dayjs';
import { BathIcon, BedSingleIcon, MenuIcon, XIcon } from 'lucide-react';
import { forwardRef, useState } from 'react';

import {
  DEFAULT_PROPERTY_IMAGE_URL,
  getPropertySource,
} from '~/apps/consumer/utils/listing.utils';
import { formatToCurrency } from '~/services/document/utils/number';

interface EditCompsMapSidebarListingCardProps {
  mlsListing: MlsListingFragment;
  transition?: string;
  transform?: Transform | null;
  attributes?: DraggableAttributes;
  listeners?: SyntheticListenerMap;
  onDelete?: (mlsListing: MlsListingFragment) => void;
}

export const EditCompsMapSidebarListingCard = forwardRef<
  HTMLDivElement,
  EditCompsMapSidebarListingCardProps
>(
  (
    { mlsListing, transition, transform, attributes, listeners, onDelete },
    ref
  ) => {
    const [isHovered, setIsHovered] = useState(false);

    return (
      <Flex
        ref={ref}
        background="white"
        borderRadius="8px"
        gap={2}
        justifyContent="space-between"
        p={2}
        transform={CSS.Transform.toString(transform as Transform)}
        transition={transition}
        width="100%"
        {...attributes}
        {...listeners}
        cursor="grab"
        position="relative"
        onMouseEnter={() => {
          setIsHovered(true);
        }}
        onMouseLeave={() => {
          setIsHovered(false);
        }}
      >
        <Flex alignItems="center" gap={2}>
          <Image
            alt="Listing photo"
            fallbackSrc={DEFAULT_PROPERTY_IMAGE_URL}
            height="50px"
            src={getPropertySource(mlsListing?.photos[0])}
            width="60px"
          />
          <Flex direction="column" gap={0} lineHeight="1.2">
            <Text
              color="indigo.800"
              fontSize="sm"
              fontWeight="semibold"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
            >
              {mlsListing?.address.full}
            </Text>
            <Text color="indigo.800" fontSize="sm">
              {mlsListing?.address.city}, {mlsListing?.address.state}{' '}
              {mlsListing?.address.postalCode}
            </Text>
            <Flex alignItems="center" gap={1}>
              {!!mlsListing?.sales?.closePrice && (
                <Tooltip
                  backgroundColor="white"
                  border="none"
                  color="indigo.800"
                  label={dayjs(mlsListing?.sales?.closeDate).format(
                    'MM/DD/YYYY'
                  )}
                  padding={1}
                >
                  <Tag
                    bg="gray.200"
                    color="indigo.800"
                    p={1}
                    py={0.5}
                    size="sm"
                  >
                    SOLD
                  </Tag>
                </Tooltip>
              )}
              <Text color="indigo.800" fontSize="sm" fontWeight="medium">
                {formatToCurrency(
                  mlsListing?.sales?.closePrice || mlsListing.listPrice,
                  0
                )}
              </Text>
              {!!mlsListing?.property?.bedrooms && (
                <Flex alignItems="center" gap={0.5}>
                  <Icon as={BedSingleIcon} color="indigo.800" />
                  <Text color="indigo.800" fontSize="sm">
                    {mlsListing?.property?.bedrooms}
                  </Text>
                </Flex>
              )}
              {!!mlsListing?.property?.bathrooms && (
                <Flex alignItems="center" gap={0.5}>
                  <Icon as={BathIcon} color="indigo.800" />
                  <Text color="indigo.800" fontSize="sm">
                    {mlsListing?.property?.bathrooms}
                  </Text>
                </Flex>
              )}
            </Flex>
          </Flex>
        </Flex>
        {isHovered && (
          <>
            <Flex direction="column" gap={2} justifyContent="center">
              <Icon as={MenuIcon} color="indigo.800" cursor="grab" />
            </Flex>
            <Flex
              backgroundColor="gray.600"
              borderRadius="full"
              boxShadow="0 4px 4px 0 rgba(0,0,0,0.25)"
              cursor="pointer"
              position="absolute"
              right={-1}
              top="-6px"
              onClick={(e) => {
                e.stopPropagation();
                onDelete && onDelete(mlsListing);
              }}
              onMouseDown={(e) => {
                e.stopPropagation();
              }}
            >
              <Icon as={XIcon} boxSize="14px" color="white" m={1} />
            </Flex>
          </>
        )}
      </Flex>
    );
  }
);
