import {
  useLatestUnsubmittedOfferForBuyersAgentQuery,
  usePartyAndBuyerInfoQuery,
} from '@client/graphql/__generated__/main-operations';
import {
  ActivityObject,
  ListingAnnouncementFragment,
  ListingComparableSetFragment,
  ListingDocumentUploadObject,
  PartyFragment,
  PublicListingComparableSetFragment,
  PublicListingFragment,
  PublicPreferenceFragment,
  PublicPreferenceValueFragment,
  TourType,
} from '@client/graphql/__generated__/types';
import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Location, useLocation, useSearchParams } from 'react-router-dom';
import { useAppContext } from '~/services/main/contexts/AppContext';
import { useCustomTourContext } from '../../contexts/CustomTourContext/CustomTourContext';
import {
  FeatureKey,
  useIsFeatureEnabledForStateOrMls,
} from '../../hooks/useIsFeatureEnabledForStateOrMls';
import { useStorefrontQueries } from './hooks/useStorefrontQueries';

// Return the real values for the storefront overview regardless of whether the user is gated
// behind signing the guestbook or not.
export interface StorefrontOverviewContextType {
  listingComparableSet?: PublicListingComparableSetFragment;
  preferenceValues?: PublicPreferenceValueFragment[];
  isLoading: boolean;
}

/**
 * Context for rendering the Storefront
 * !!!NOTE: This context is intended to be used by the Storefront page and its child components.
 * Some of the values in this context will not represent the (true) current state of the storefront
 * if the current user has not signed the guestbook. (i.e. if isGatedBehindSigningGuestbook is true)
 */
export interface StorefrontContextType {
  listing: PublicListingFragment;
  listingComparableSet: ListingComparableSetFragment;
  listingDocumentUploads: ListingDocumentUploadObject[];
  listingAnnouncements: ListingAnnouncementFragment[];
  preferences: PublicPreferenceFragment[];
  loading: boolean;
  listingActivities?: ActivityObject[];
  showMakeOfferMobile: boolean;
  setShowMakeOfferMobile: React.Dispatch<React.SetStateAction<boolean>>;
  showStartTourModal: boolean;
  setShowStartTourModal: React.Dispatch<React.SetStateAction<boolean>>;
  shouldContinueOffer: boolean;
  canUploadOffer: boolean;
  canMakeOffer: boolean;
  isPremium: boolean;
  scopeToParty?: PartyFragment;
  isGatedBehindSigningGuestbook: boolean;
  storefrontOverviewContext: StorefrontOverviewContextType;
}

export const StorefrontContext = createContext<StorefrontContextType>(
  {} as StorefrontContextType
);

interface StorefrontProviderProps extends PropsWithChildren {
  propertySlug: string;
  isMakeOfferMobileVisible?: boolean;
  isPreview?: boolean;
}

export const StorefrontProvider = ({
  propertySlug,
  isMakeOfferMobileVisible,
  isPreview = false,
  children,
}: StorefrontProviderProps) => {
  const [searchParams] = useSearchParams();
  const {
    listing,
    listingComparableSet,
    listingDocumentUploads,
    loading,
    listingAnnouncements,
    listingActivities,
    preferences,
    isGatedBehindSigningGuestbook,
    storefrontOverviewContext,
  } = useStorefrontQueries(propertySlug);
  const location = useLocation() as Location<{ startNewOffer?: boolean }>;

  const { isAuthenticated } = useAppContext();

  const { viewedTours, getHasViewedTour } = useCustomTourContext();

  const [showMakeOfferMobile, setShowMakeOfferMobile] = useState(
    !!isMakeOfferMobileVisible
  );

  const canMakeOffer = useIsFeatureEnabledForStateOrMls({
    featureFlagKey: FeatureKey.MAKE_OFFER,
    state: listing?.property?.state,
  });

  const incomingPartyId = searchParams.get('party');

  const { data } = useLatestUnsubmittedOfferForBuyersAgentQuery({
    variables: {
      input: {
        listingId: listing?.id as string,
        partyId:
          isAuthenticated && incomingPartyId ? incomingPartyId : undefined,
      },
      onlyId: true,
      isAgentVerified: isAuthenticated,
    },
    skip: !listing?.id,
  });

  /**
   * this could refer to:
   * - the latest unsubmitted offer for the authenticated buyers agent (if the party is not provided)
   * - the latest unsubmitted offer for the authenticated buyers agent (if the incoming party is provided)
   * - the latest unsubmitted offer for the unauthenticated buyers agent (unauthed agents cannot have associated parties)
   */
  const latestUnsubmittedOffer = useMemo(() => {
    return (
      data?.latestUnsubmittedOfferForBuyersAgent ||
      data?.latestUnsubmittedOfferForUnauthenticatedBuyersAgent
    );
  }, [data]);

  const hasUnsubmittedOffer = useMemo(() => {
    return !!latestUnsubmittedOffer?.id;
  }, [latestUnsubmittedOffer]);

  const shouldContinueOffer =
    searchParams.get('state') !== 'startOver' &&
    !location.state?.startNewOffer &&
    hasUnsubmittedOffer;

  const [showStartTourModal, setShowStartTourModal] = useState(false);

  // Process buyer parties if the agent is authed.
  const continueOfferForBuyerPartyId = useMemo(() => {
    return isAuthenticated ? latestUnsubmittedOffer?.party?.id : null;
  }, [latestUnsubmittedOffer, isAuthenticated]);

  const scopeToPartyId =
    (shouldContinueOffer ? continueOfferForBuyerPartyId : null) ||
    incomingPartyId;

  const { data: scopeToPartyInfoData } = usePartyAndBuyerInfoQuery({
    variables: {
      input: {
        id: scopeToPartyId as string,
      },
    },
    skip: !scopeToPartyId || !isAuthenticated,
  });

  const scopeToParty = scopeToPartyInfoData?.partyAndBuyerInfo;

  useEffect(() => {
    if (!getHasViewedTour(TourType.BA_STOREFRONT) && !isPreview) {
      setShowStartTourModal(true);
    } else {
      setShowStartTourModal(false);
    }
  }, [viewedTours, getHasViewedTour, isPreview]);

  const isPremium = listing?.isPremium ?? false;

  return (
    <StorefrontContext.Provider
      value={{
        listingComparableSet:
          listingComparableSet as ListingComparableSetFragment,
        listing: listing as PublicListingFragment,
        listingDocumentUploads:
          listingDocumentUploads as ListingDocumentUploadObject[],
        listingAnnouncements:
          listingAnnouncements as ListingAnnouncementFragment[],
        preferences: preferences as PublicPreferenceFragment[],
        listingActivities,
        loading,
        showMakeOfferMobile,
        setShowMakeOfferMobile,
        showStartTourModal,
        setShowStartTourModal,
        shouldContinueOffer,
        canUploadOffer: isPremium,
        canMakeOffer,
        isPremium,
        scopeToParty,
        isGatedBehindSigningGuestbook,
        storefrontOverviewContext,
      }}
    >
      {children}
    </StorefrontContext.Provider>
  );
};

export const useStorefrontContext = () => {
  return useContext(StorefrontContext);
};
