import { Icon, IconProps } from '@chakra-ui/react';

export const TextareaIcon = (props: IconProps) => (
  <Icon
    color="primary.500"
    fill="none"
    height="23"
    viewBox="0 0 23 23"
    width="23"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <line
      stroke="currentColor"
      strokeLinecap="round"
      strokeWidth="2"
      x1="10"
      x2="14"
      y1="1.83337"
      y2="1.83337"
    />
    <line
      stroke="currentColor"
      strokeLinecap="round"
      strokeWidth="2"
      x1="10"
      x2="14"
      y1="22"
      y2="22"
    />
    <line
      stroke="currentColor"
      strokeLinecap="round"
      strokeWidth="2"
      x1="12"
      x2="12"
      y1="2"
      y2="21.0833"
    />
    <mask
      height="18"
      id="mask0_1002_4828"
      maskUnits="userSpaceOnUse"
      style={{
        maskType: 'alpha',
      }}
      width="23"
      x="0"
      y="1"
    >
      <path
        d="M0 19V8.50003V4.00003L9.1884 4.00002V1.80945L15 1.9216V4.00001L23 4V19H15V4.00001L9.1884 4.00002V19H0Z"
        fill="black"
      />
    </mask>
    <g mask="url(#mask0_1002_4828)">
      <rect
        height="13"
        stroke="currentColor"
        strokeWidth="2"
        width="21"
        x="1"
        y="5"
      />
    </g>
    <path
      d="M5.13441 8.66857H4V8H7V8.66857H5.86559V12H5.13441V8.66857Z"
      fill="currentColor"
    />
  </Icon>
);
