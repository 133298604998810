import debounce from 'lodash/debounce';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ValuesType } from '~/services/document/components/FormFields/types';
import { useOfferFlowContext } from '../OfferFlowContext';

export const useUpdateInputValuesDebounced = () => {
  const { fieldValues, updateFieldValues } = useOfferFlowContext();
  const [inputValues, setInputValues] = useState<ValuesType>({});

  const updateValuesDebounce = useMemo(
    () =>
      debounce((value: ValuesType) => {
        updateFieldValues(value);
      }, 100),
    [updateFieldValues]
  );

  const onChange = useCallback(
    (value: ValuesType) => {
      setInputValues((prev) => ({
        ...prev,
        ...value,
      }));
      updateValuesDebounce({
        ...value,
      });
    },
    [updateValuesDebounce]
  );

  useEffect(() => {
    setInputValues({ ...fieldValues });
  }, [fieldValues]);

  return { inputValues, onChange, updateValuesDebounce, setInputValues };
};
