import { Button, ButtonProps, Icon } from '@chakra-ui/react';
import { LinkIcon } from 'lucide-react';
import { FC, useEffect, useRef, useState } from 'react';
import { useCopyPaste } from '../hooks/useCopyPaste';

interface CopyButtonProps {
  copyText: string;
  buttonContentCopy?: React.ReactNode;
  buttonContentCopied?: React.ReactNode;
  showIcon?: boolean;
  onCopied?: () => void;
}

export const CopyPasteButton: FC<ButtonProps & CopyButtonProps> = ({
  copyText,
  buttonContentCopy = 'Copy',
  buttonContentCopied = 'Copied!',
  showIcon = false,
  onCopied,
  ...props
}) => {
  const componentRef = useRef(null);
  const [width, setWidth] = useState(0);

  const { copied, copyToClipboard } = useCopyPaste();

  useEffect(() => {
    if (componentRef.current) {
      setWidth((componentRef.current as HTMLElement).clientWidth);
    }
  }, []);

  return (
    <Button
      ref={componentRef}
      fontWeight="semibold"
      leftIcon={showIcon ? <Icon as={LinkIcon} /> : undefined}
      minWidth={width}
      size="xs"
      transition="all 0.2s ease-in-out"
      onClick={() => void copyToClipboard(copyText, onCopied)}
      {...props}
    >
      {copied ? buttonContentCopied : buttonContentCopy}
    </Button>
  );
};
