import { VStack } from '@chakra-ui/react';
import { PublicDocumentVersionObject } from '@client/graphql/__generated__/types';
import { useMemo } from 'react';
import {
  DEFAULT_BLUR_STYLES,
  useShowBlurOnScrollable,
} from '~/apps/consumer/hooks/useShowBlurOnScrollable';
import { useOfferFlowContext } from '~/apps/consumer/pages/Storefront/OfferFlow/OfferFlowContext';
import { AddDocumentRow } from './AddDocumentRow';

export const AddFillableTab = () => {
  const {
    disclosureDocumentTypes,
    updateFieldValues,
    activeDocumentVersion,
    additionalDocs,
    setShouldSaveAsync,
  } = useOfferFlowContext();

  const { showBlur, setInitialBlurRef, handleBlurOnScroll } =
    useShowBlurOnScrollable();

  const secondaryDocumentVersionsWithoutDisclosures = useMemo(
    () =>
      activeDocumentVersion?.secondaryVersions?.filter(
        (secondary) =>
          !disclosureDocumentTypes?.some(
            (docType) => docType.formName === secondary.document.form
          ) && !additionalDocs?.some((doc) => doc.id === secondary.id)
      ),
    [activeDocumentVersion, disclosureDocumentTypes, additionalDocs]
  );

  return (
    <VStack
      ref={setInitialBlurRef}
      _after={{
        display: showBlur ? 'block' : 'none',
        ...DEFAULT_BLUR_STYLES,
      }}
      alignItems="stretch"
      maxHeight={{ base: '200px', md: '300px' }}
      overflow="auto"
      overscrollBehavior="contain"
      px={4}
      width="100%"
      onScroll={handleBlurOnScroll}
    >
      {secondaryDocumentVersionsWithoutDisclosures?.map((docVersion) => (
        <AddDocumentRow
          key={docVersion?.id}
          documentVersion={docVersion as PublicDocumentVersionObject}
          onChange={(fieldValues) => {
            updateFieldValues(fieldValues);
            setShouldSaveAsync(true);
          }}
        />
      ))}
    </VStack>
  );
};
