import { Flex, Icon, Tag, Text, Tooltip } from '@chakra-ui/react';
import { ClockIcon, MapIcon } from 'lucide-react';
import { SignalIcon, SignalStrength } from '~/common/icons/SignalIcon';
import { formatDistance } from './utils';

interface SourceTagsProps {
  sourceLabel?: string;
  confidence?: SignalStrength;
  distanceMiles?: number;
  timePeriodMonths?: number;
  isExpanded?: boolean;
}

export const SourceTags = ({
  sourceLabel,
  confidence,
  distanceMiles,
  timePeriodMonths,
  isExpanded,
}: SourceTagsProps) => {
  return (
    <Tooltip
      label={
        <TooltipLabel
          confidence={confidence}
          distanceMiles={distanceMiles}
          sourceLabel={sourceLabel}
          timePeriodMonths={timePeriodMonths}
        />
      }
      variant="muted"
    >
      <Flex alignItems="center" gap={1}>
        {!!sourceLabel && (
          <Tag
            borderRadius="3px"
            color="whiteAlpha.600"
            size="sm"
            textTransform="capitalize"
          >
            {sourceLabel}
          </Tag>
        )}
        {!!confidence && (
          <Tag borderRadius="3px" px={1} size="sm" width={4}>
            <SignalIcon
              color="purple.500"
              strength={confidence as unknown as SignalStrength}
            />
          </Tag>
        )}
        {!!distanceMiles && isExpanded && (
          <Tag borderRadius="3px" color="whiteAlpha.600" size="sm">
            <Flex alignItems="center" gap={1}>
              <Icon as={MapIcon} />
              {formatDistance(distanceMiles)}
            </Flex>
          </Tag>
        )}
        {!!timePeriodMonths && isExpanded && (
          <Tag borderRadius="3px" color="whiteAlpha.600" size="sm">
            <Flex alignItems="center" gap={1}>
              <Icon as={ClockIcon} />
              {timePeriodMonths} months
            </Flex>
          </Tag>
        )}
      </Flex>
    </Tooltip>
  );
};

export const TooltipLabel = ({
  sourceLabel,
  confidence,
  distanceMiles,
  timePeriodMonths,
}: Omit<SourceTagsProps, 'isExpanded'>) => {
  return (
    <Flex direction="column" gap={2} width="200px">
      {!!sourceLabel && (
        <Flex alignItems="center" gap={4} justifyContent="space-between">
          <Text>Data source</Text>
          <Tag
            borderRadius="3px"
            color="whiteAlpha.600"
            size="sm"
            textTransform="capitalize"
          >
            {sourceLabel}
          </Tag>
        </Flex>
      )}
      {!!confidence && (
        <Flex alignItems="center" gap={4} justifyContent="space-between">
          <Text>Confidence</Text>
          <Tag borderRadius="3px" gap={1} px={1} size="sm">
            <SignalIcon
              color="purple.500"
              strength={confidence as unknown as SignalStrength}
            />
            <Text color="whiteAlpha.600" textTransform="capitalize">
              {confidence.toLowerCase()}
            </Text>
          </Tag>
        </Flex>
      )}
      {!!distanceMiles && (
        <Flex alignItems="center" gap={4} justifyContent="space-between">
          <Text>Distance</Text>
          <Tag borderRadius="3px" color="whiteAlpha.600" size="sm">
            <Flex alignItems="center" gap={1}>
              <Icon as={MapIcon} />
              {formatDistance(distanceMiles)}
            </Flex>
          </Tag>
        </Flex>
      )}
      {!!timePeriodMonths && (
        <Flex alignItems="center" gap={4} justifyContent="space-between">
          <Text>Time period</Text>
          <Tag borderRadius="3px" color="whiteAlpha.600" size="sm">
            <Flex alignItems="center" gap={1}>
              <Icon as={ClockIcon} />
              {timePeriodMonths} months
            </Flex>
          </Tag>
        </Flex>
      )}
    </Flex>
  );
};
