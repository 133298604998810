import { Box, BoxProps } from '@chakra-ui/react';
import { FC, LegacyRef, forwardRef } from 'react';

export const AbsoluteBox: FC<BoxProps & { ref?: LegacyRef<HTMLDivElement> }> =
  forwardRef<HTMLDivElement, BoxProps>(({ children, ...props }, ref) => {
    return (
      <Box
        ref={ref}
        bottom={0}
        left={0}
        position="absolute"
        right={0}
        top={0}
        {...props}
      >
        {children}
      </Box>
    );
  });
