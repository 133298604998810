import { useFeatureFlagEnabled as usePostHogFeatureFlagEnabled } from 'posthog-js/react';

/**
 * Hook to check if a feature flag is enabled.
 *
 * Wraps posthog-js/react's useFeatureFlagEnabled hook but always returns true if not production.
 *
 * @param featureFlag - The feature flag to check.
 * @returns Whether the feature flag is enabled.
 */
export const useProdFeatureFlagEnabled = (
  featureFlag: string
): boolean | undefined => {
  // Have to call hook first even if unnecessary to ensure ordering for React.
  const isEnabled = usePostHogFeatureFlagEnabled(featureFlag);

  if (process.env.NODE_ENV !== 'production') {
    return true;
  }

  return isEnabled;
};
