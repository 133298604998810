import { Flex, Grid, Heading } from '@chakra-ui/react';
import { FieldType } from '@client/graphql/__generated__/types';
import { DragOverlay } from '@dnd-kit/core';
import { snapCenterToCursor } from '@dnd-kit/modifiers';
import { FC, memo, useMemo } from 'react';
import { useIsMobile } from '~/common/hooks/useIsMobile';
import { enumToLabel } from '../../utils/enums';
import { useDocumentMapperContext } from '../DocumentMapper/context';
import { DraggableWidgetButton } from './WidgetButton/DraggableWidgetButton';
import { WidgetButton } from './WidgetButton/WidgetButton';

interface FieldsWidgetProps {
  fieldTypes?: FieldType[];
  gridTemplateColumns?: string;
}

export const SIGNATURE_FIELD_TYPES = [
  FieldType.SIGNATURE,
  FieldType.SIGNED_DATE,
  FieldType.INITIAL,
  FieldType.TEXT,
];

export const FieldsWidget: FC<FieldsWidgetProps> = memo(function FieldsWidget({
  fieldTypes,
  gridTemplateColumns,
}) {
  const isMobile = useIsMobile();
  const { dndKitDefaults } = useDocumentMapperContext();
  const { activeDraggingItemData, dropAnimationConfig } = dndKitDefaults;

  const fieldTypeButtons = useMemo(() => {
    if (fieldTypes) {
      return fieldTypes.filter((type) => !SIGNATURE_FIELD_TYPES.includes(type));
    }

    return Object.values(FieldType).filter(
      (type) => !SIGNATURE_FIELD_TYPES.includes(type)
    );
  }, [fieldTypes]);

  const signatureTypeButtons = useMemo(() => {
    if (fieldTypes) {
      return fieldTypes.filter((type) => SIGNATURE_FIELD_TYPES.includes(type));
    }

    return Object.values(FieldType).filter((type) =>
      SIGNATURE_FIELD_TYPES.includes(type)
    );
  }, [fieldTypes]);

  return (
    <Flex flexDirection="column">
      {fieldTypeButtons.length > 0 && (
        <>
          <Heading as="h6" color="gray.700" mb={3} size="headline">
            <strong>Form Fields</strong>
          </Heading>

          <Grid
            columnGap={2}
            gridTemplateColumns={
              gridTemplateColumns || { base: '1fr', '2xl': '1fr 1fr' }
            }
            rowGap={2}
          >
            {fieldTypeButtons.map((fieldType) => (
              <DraggableWidgetButton key={fieldType} fieldType={fieldType}>
                {enumToLabel(fieldType)}
              </DraggableWidgetButton>
            ))}
          </Grid>
        </>
      )}

      {!isMobile && (
        <Heading as="h6" color="gray.700" mb={3} mt={4} size="headline">
          <strong>Signature Fields</strong>
        </Heading>
      )}

      <Grid
        columnGap={2}
        display={{
          base: 'flex',
          md: 'grid',
        }}
        gridTemplateColumns={{
          base: signatureTypeButtons.map(() => '1fr').join(' '),
          md: gridTemplateColumns || '1fr',
          '2xl': gridTemplateColumns || '1fr 1fr',
        }}
        rowGap={2}
      >
        {signatureTypeButtons.map((fieldType, i) => (
          <DraggableWidgetButton
            key={fieldType}
            fieldType={fieldType}
            mr={i === signatureTypeButtons.length - 1 ? 0 : undefined}
          />
        ))}
      </Grid>
      {activeDraggingItemData && (
        <DragOverlay
          dropAnimation={dropAnimationConfig}
          modifiers={[snapCenterToCursor]}
          style={{
            pointerEvents: 'none',
          }}
        >
          <WidgetButton
            fieldType={activeDraggingItemData.fieldType}
            minWidth="140px"
          >
            {enumToLabel(activeDraggingItemData.fieldType)}
          </WidgetButton>
        </DragOverlay>
      )}
    </Flex>
  );
});
