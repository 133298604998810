import {
  Button,
  Card,
  CardBody,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import {
  AreaChartIcon,
  CircleDollarSignIcon,
  MegaphoneIcon,
} from 'lucide-react';
import ConfettiExplosion from 'react-confetti-explosion';
import { GradientIcon } from '~/common/components/GradientIcon';
import { TourHeading } from '../../../Tour/styled';

interface ListingSetupTourModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const ListingSetupTourModal = ({
  isOpen,
  onClose,
}: ListingSetupTourModalProps) => {
  const handleClose = () => {
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody pt={6}>
          <Flex alignItems="center" direction="column" gap={4}>
            <Flex alignItems="center" direction="column">
              <ConfettiExplosion
                particleCount={400}
                width={2000}
                zIndex={2000} // z-index higher any chakra z-index
              />

              <TourHeading fontWeight={300} textAlign="center">
                Your <b style={{ fontWeight: 500 }}>Storefront</b>
                <br /> is live!
              </TourHeading>
            </Flex>

            <Card>
              <CardBody>
                <Flex direction="column" gap={4}>
                  <Flex alignItems="center" gap={3}>
                    <GradientIcon
                      boxSize="24px"
                      gradientThemeKey="blue"
                      icon={AreaChartIcon}
                    />
                    <Text>
                      Track interested buyer agents and unlock powerful reverse
                      prospecting
                    </Text>
                  </Flex>
                  <Flex alignItems="center" gap={3}>
                    <GradientIcon
                      boxSize="24px"
                      gradientThemeKey="blue"
                      icon={MegaphoneIcon}
                    />
                    <Text>
                      Streamline communications with seller preferences and
                      announcements
                    </Text>
                  </Flex>
                  <Flex alignItems="center" gap={3}>
                    <GradientIcon
                      boxSize="24px"
                      gradientThemeKey="blue"
                      icon={CircleDollarSignIcon}
                    />
                    <Text>
                      Collect, manage, and compare all your offers side-by-side
                    </Text>
                  </Flex>
                </Flex>
              </CardBody>
            </Card>
            <Button
              background="blueGradient"
              borderRadius="32px"
              color="black"
              onClick={() => {
                handleClose();
              }}
            >
              Manage your Storefront
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
