import { Icon, IconProps } from '@chakra-ui/react';

export const SignedDateIcon = (props: IconProps) => (
  <Icon
    color="primary.500"
    fill="none"
    height="20px"
    viewBox="0 0 20 20"
    width="20px"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M13.1456 10.6913C13.1034 10.6913 13.0097 10.7094 12.8664 10.8527L12.8616 10.8575L9.0982 14.5176L8.8202 15.3516L9.661 15.0713L13.4269 11.4088C13.5682 11.2667 13.5861 11.1738 13.5861 11.1318C13.5861 11.0897 13.568 10.996 13.4247 10.8527C13.2814 10.7094 13.1877 10.6913 13.1456 10.6913ZM14.1164 10.161C13.8535 9.89801 13.5253 9.71301 13.1456 9.71301C12.7671 9.71301 12.4398 9.89681 12.1773 10.1584L8.24544 13.9823L7.27344 16.8983L10.1826 15.9286L14.1116 12.1075L14.1164 12.1027C14.3794 11.8398 14.5644 11.5116 14.5644 11.1318C14.5644 10.7521 14.3794 10.4239 14.1164 10.161C14.1164 10.161 14.1164 10.161 14.1164 10.161Z"
      fill="currentColor"
      fillRule="evenodd"
    />
    <path
      d="M13.2163 12.2056L11.9663 10.9556L12.4088 10.5131L13.2032 10.2656L14.1407 10.9687L13.6588 11.7631L13.2163 12.2056Z"
      fill="currentColor"
    />
    <path
      clipRule="evenodd"
      d="M7.2169 11.1006C7.08148 11.2583 7.05175 11.3741 7.05175 11.4375C7.05175 11.7798 7.15897 12.0031 7.2998 12.2495C7.3136 12.2737 7.32874 12.2994 7.34476 12.3266C7.47936 12.5552 7.67675 12.8904 7.67675 13.3125C7.67675 13.671 7.53679 13.9374 7.33995 14.1363C7.20199 14.2757 7.02186 14.3905 6.89667 14.4702C6.87249 14.4856 6.85036 14.4997 6.83097 14.5124C6.68474 14.6084 6.59472 14.6814 6.5335 14.7687C6.48025 14.8447 6.42675 14.9639 6.42675 15.1875C6.42675 15.6112 6.75781 15.9483 7.18762 15.9483C7.37501 15.9483 7.53547 15.8781 7.65849 15.7961C7.71905 15.7557 7.76581 15.7155 7.79605 15.6869C7.81099 15.6727 7.82135 15.6619 7.82681 15.656L7.83111 15.6513C7.83105 15.6514 7.83079 15.6517 7.83031 15.6522L7.82915 15.6536L7.82838 15.6545L7.82795 15.655L7.82772 15.6553C7.82761 15.6555 7.82749 15.6556 8.20324 15.9687C8.579 16.2819 8.57888 16.282 8.57875 16.2822L8.5785 16.2825L8.57796 16.2831L8.57676 16.2845L8.5739 16.2879L8.5663 16.2967C8.56041 16.3034 8.55285 16.3118 8.54366 16.3217C8.52532 16.3415 8.50028 16.3673 8.46883 16.3971C8.4063 16.4563 8.31635 16.5333 8.20112 16.6101C7.97258 16.7624 7.62522 16.9266 7.18762 16.9266C6.21117 16.9266 5.44849 16.1451 5.44849 15.1875C5.44849 14.7861 5.55124 14.4658 5.73236 14.2073C5.90552 13.9602 6.128 13.8037 6.29426 13.6946C6.33718 13.6664 6.3749 13.642 6.40838 13.6204C6.52526 13.545 6.59042 13.503 6.64466 13.4482C6.68219 13.4102 6.69849 13.3837 6.69849 13.3125C6.69849 13.1683 6.6402 13.0664 6.47972 12.786C6.47031 12.7696 6.46055 12.7526 6.45043 12.7349C6.27876 12.4344 6.07349 12.0327 6.07349 11.4375C6.07349 11.0712 6.23907 10.7377 6.47474 10.4633C6.71168 10.1874 7.04085 9.9441 7.4328 9.74268C8.21793 9.33921 9.31321 9.07336 10.6251 9.07336V10.0516C9.43703 10.0516 8.50105 10.2936 7.87993 10.6128C7.56876 10.7727 7.35106 10.9444 7.2169 11.1006Z"
      fill="currentColor"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M1.56396 5.3125C1.56396 4.48407 2.23554 3.8125 3.06396 3.8125H16.939C17.7674 3.8125 18.439 4.48407 18.439 5.3125V16.9375C18.439 17.7659 17.7674 18.4375 16.939 18.4375H3.06396C2.23554 18.4375 1.56396 17.7659 1.56396 16.9375V5.3125Z"
      fillRule="evenodd"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path
      d="M1.56396 8.3125H18.439"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path
      d="M6.06396 5.5V1.5625"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path
      d="M13.939 5.5V1.5625"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
  </Icon>
);
