import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import '../styles/popup-styles.css';

import {
  Map,
  MapRef,
  NavigationControl,
  Popup,
  ScaleControl,
  ViewStateChangeEvent,
} from 'react-map-gl';

import { Flex, Progress } from '@chakra-ui/react';
import debounce from 'lodash/debounce';
import { MapEvent } from 'mapbox-gl';
import { RefObject, useCallback, useMemo } from 'react';
import { getListingPrice } from '~/apps/consumer/utils/listing.utils';
import { useIsMobile } from '~/common/hooks/useIsMobile';
import { PublicListingCard } from '../../Listings/ListingCard/PublicListingCard';
import { DEFAULT_POSITION } from '../constants';
import { useExploreContext } from '../ExploreContext';
import { convertBoundsToBbox } from '../helpers';
import { ExploreMapMarker } from './ExploreMapMarker';

interface IExploreMapProps {
  mapRef: RefObject<MapRef>;
  visibility?: 'visible' | 'hidden';
}

export const ExploreMap = ({
  mapRef,
  visibility = 'visible',
}: IExploreMapProps) => {
  const isMobile = useIsMobile(true);
  const {
    selectedListing,
    setSelectedListing,
    listings,
    updateFilters,
    loading,
    utmParams,
  } = useExploreContext();
  const markers = useMemo(() => {
    return listings.map(({ mlsListing, listing }) => {
      if (!mlsListing.geo?.location?.lat || !mlsListing.geo?.location?.lon) {
        return null;
      }

      const isPremium = listing?.isPremium ?? false;

      const price = getListingPrice(mlsListing);

      return (
        <ExploreMapMarker
          key={`marker-${mlsListing.id}`}
          isPremium={isPremium}
          lat={mlsListing.geo.location.lat}
          lng={mlsListing.geo.location.lon}
          price={price}
          zoom={mapRef.current?.getZoom() || DEFAULT_POSITION.zoom}
          onClick={(event) => {
            event.originalEvent.stopPropagation();
            setSelectedListing({
              listing,
              mlsListing,
            });
          }}
        />
      );
    });
  }, [listings, setSelectedListing, mapRef]);

  const fetchListings = useCallback(
    (event: MapEvent) => {
      const bounds = event.target.getBounds();
      const bbox = convertBoundsToBbox(bounds);

      if (bbox) {
        updateFilters({
          bbox,
        });
      }
    },
    [updateFilters]
  );

  const handleMove = useMemo(
    () =>
      debounce((event: ViewStateChangeEvent) => {
        fetchListings(event);
      }, 300),
    [fetchListings]
  );

  return (
    <Flex
      direction={{ base: 'column-reverse', xl: 'column' }}
      height={{
        base: visibility === 'visible' ? '100%' : '0',
        xl: '100%',
      }}
      visibility={visibility}
      width="100%"
    >
      <Progress
        isIndeterminate
        height="2px"
        size="xs"
        visibility={loading ? 'visible' : 'hidden'}
        width="100%"
      />
      <Map
        ref={mapRef}
        initialViewState={{
          longitude: DEFAULT_POSITION.lng,
          latitude: DEFAULT_POSITION.lat,
          zoom: DEFAULT_POSITION.zoom,
        }}
        mapStyle="mapbox://styles/mapbox/navigation-guidance-night-v4"
        mapboxAccessToken={window.ENVS.mapboxApiKey}
        minZoom={3}
        testMode={window.ENVS.environment === 'e2e'}
        onLoad={fetchListings}
        onMove={handleMove}
      >
        <NavigationControl position={isMobile ? 'bottom-right' : 'top-left'} />
        <ScaleControl />
        {markers}
        {selectedListing?.mlsListing && (
          <Popup
            latitude={selectedListing.mlsListing.geo.location.lat}
            longitude={selectedListing.mlsListing.geo.location.lon}
            offset={15}
            style={{
              maxWidth: '260px',
              minWidth: '260px',
              zIndex: 2,
              borderRadius: '12px',
            }}
            onClose={() => setSelectedListing(null)}
          >
            <PublicListingCard
              listing={selectedListing.listing}
              mlsListing={selectedListing.mlsListing}
              utmParams={utmParams}
            />
          </Popup>
        )}
      </Map>
    </Flex>
  );
};
