import { Button, Flex } from '@chakra-ui/react';
import {
  FileObject,
  FinancingType,
  PartyFragment,
} from '@client/graphql/__generated__/types';
import { Plus } from 'lucide-react';
import { useEffect, useMemo, useState } from 'react';
import { Form } from '../../Formik/Form';
import { PreapprovalDropzone } from '../PreapprovalDropzone/PreapprovalDropzone';
import { BuyerFormValues } from '../types';
import { SelectedPartyInfoCard } from './SelectedPartyInfoCard';

export const UPDATE_BUYER_FORM_ID = 'update-buyer-form';

interface SelectedPartyInfoProps {
  party: PartyFragment;
  showFinancingInfo?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleSubmit: (values: BuyerFormValues) => Promise<any>;
  preapprovalFile?: File | FileObject | undefined;
  setPreapprovalFile?: (file: File) => void;
  onViewPreapproval?: () => void;
  isEditing: boolean;
  setIsEditing: (isEditing: boolean) => void;
}

export const SelectedPartyInfoForm = ({
  party,
  handleSubmit,
  preapprovalFile,
  setPreapprovalFile,
  onViewPreapproval,
  isEditing,
  setIsEditing,
  showFinancingInfo = true,
}: SelectedPartyInfoProps) => {
  const [showCoBuyer, setShowCoBuyer] = useState(false);
  const initialValue = useMemo(() => {
    return {
      buyerName: party.primaryConsumer.fullName,
      buyerEmail: party.primaryConsumer.email ?? '',
      isEntity: party.primaryConsumer.isEntity,
      entityName: party.primaryConsumer.entityName,
      entityTitle: party.primaryConsumer.entityTitle,
      coBuyerName: party.secondaryConsumers?.[0]?.fullName,
      coBuyerEmail: party.secondaryConsumers?.[0]?.email ?? '',
      financingType: party.partyFinancing?.financingType,
      loanAmount: party.partyFinancing?.loanAmount
        ? Number(party.partyFinancing?.loanAmount)
        : undefined,
      cashReserves: party.partyFinancing?.cashReserves
        ? Number(party.partyFinancing?.cashReserves)
        : undefined,
      percentDownPayment: party.partyFinancing?.percentDownPayment
        ? Number(party.partyFinancing?.percentDownPayment)
        : undefined,
    };
  }, [party]);

  useEffect(() => {
    setShowCoBuyer(!!initialValue?.coBuyerEmail || !!initialValue?.coBuyerName);
  }, [initialValue?.coBuyerEmail, initialValue?.coBuyerName]);

  return (
    <Form<BuyerFormValues>
      enableReinitialize
      formId={UPDATE_BUYER_FORM_ID}
      initialValues={initialValue}
      onSubmit={handleSubmit}
    >
      {({ values, setFieldValue, submitForm }) => (
        <Flex direction="column" gap={2} pb={2}>
          {setPreapprovalFile && (
            <PreapprovalDropzone
              file={preapprovalFile}
              onExtract={async (extracted) => {
                if (extracted?.primary_buyer) {
                  await setFieldValue('buyerName', extracted?.primary_buyer);
                }
                if (extracted?.co_buyer) {
                  setShowCoBuyer(true);
                  await setFieldValue('coBuyerName', extracted?.co_buyer);
                }
                if (extracted?.loan_type) {
                  await setFieldValue(
                    'financingType',
                    extracted?.loan_type.toUpperCase() as FinancingType
                  );
                }
                if (extracted?.loan_amount) {
                  await setFieldValue('loanAmount', extracted.loan_amount);
                }
                setIsEditing(true);
              }}
              onUpload={(file) => {
                setPreapprovalFile?.(file);
              }}
              onView={onViewPreapproval}
            />
          )}
          <SelectedPartyInfoCard
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            showCoBuyer={showCoBuyer}
            showFinancingInfo={showFinancingInfo}
            showLoanAmount={values.financingType !== FinancingType.CASH}
            values={values}
            onConfirm={() => {
              void submitForm();
              setIsEditing(false);
            }}
            onRemoveCoBuyer={async () => {
              await setFieldValue('coBuyerName', undefined);
              await setFieldValue('coBuyerEmail', undefined);
              setShowCoBuyer(false);
            }}
          />
          {!showCoBuyer && (
            <Button
              leftIcon={<Plus />}
              variant="link"
              width="fit-content"
              onClick={() => {
                setIsEditing(true);
                setShowCoBuyer(true);
              }}
            >
              Add co-buyer
            </Button>
          )}
        </Flex>
      )}
    </Form>
  );
};
