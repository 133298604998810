import { SignatureFormFieldInput } from '@client/graphql/__generated__/types';
import { useMemo, useState } from 'react';

export const useSignatureFormFields = () => {
  const [signatureFormFields, setSignatureFormFields] = useState<
    SignatureFormFieldInput[]
  >([]);

  const signatureFormFieldInputs = useMemo(() => {
    return signatureFormFields.map(
      (field) =>
        ({
          documentIndex: field.documentIndex,
          height: field.height,
          id: field.id,
          isRequired: field.isRequired,
          name: field.name,
          page: field.page,
          signerId: field.signerId,
          type: field.type,
          width: field.width,
          x: field.x,
          y: field.y,
        }) as SignatureFormFieldInput
    );
  }, [signatureFormFields]);

  return {
    signatureFormFields,
    setSignatureFormFields,
    signatureFormFieldInputs,
  };
};
