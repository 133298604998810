import { Stack } from '@chakra-ui/react';
import { Outlet } from 'react-router';
import { useAppContext } from '~/services/main/contexts/AppContext';

export const PublicLayout = () => {
  const { appState } = useAppContext();

  return (
    <Stack
      alignItems="center"
      bgColor={appState.bgColor}
      flexDir={{ base: 'column', md: 'column' }}
      justifyContent="center"
      minHeight="100dvh"
      minWidth="100vw"
      spacing={0}
    >
      <Outlet />
    </Stack>
  );
};
