import {
  useListingAutofillVariableValuesQuery,
  useOfferAutofillValuesQuery,
} from '@client/graphql/__generated__/main-operations';
import {
  ListingFragment,
  VariableFieldObject,
} from '@client/graphql/__generated__/types';
import { VariableSource } from '@common/autofill';
import { flattenObject } from '@common/utils/object';
import { useListingDocumentQueries } from '~/apps/consumer/pages/ListingSetup/Documents/hooks/useListingDocumentQueries';
import { FieldWithPage } from '~/services/document/components/DocumentSections/types';

export const useCompareOfferBaseValues = (
  listing: ListingFragment,
  offerComparisonFields: FieldWithPage[]
) => {
  const { data: listingVariableValuesData } =
    useListingAutofillVariableValuesQuery({
      variables: {
        listingIds: [listing.id],
      },
    });

  const { listingContractDocument } = useListingDocumentQueries(listing);
  const contract = listingContractDocument?.activeDocumentVersion;
  const { data: contractVariableValuesData } = useOfferAutofillValuesQuery({
    variables: {
      listingId: listing.id,
      documentVersionIds: contract?.id ? [contract.id] : [],
      fieldValues: {},
    },
    skip: !contract,
  });

  const listingVariableValues =
    listingVariableValuesData?.listingAutofillVariableValues?.[0] || {};

  const flattenedVariableValues = flattenObject(listingVariableValues);

  const baseValues: Record<
    string,
    | { value: number | string; matchingFormulaField: VariableFieldObject }
    | undefined
  > = offerComparisonFields.reduce((acc, field) => {
    const formulaFields = field.autofill?.formulaVariableFields.filter(
      (formulaField) => formulaField.source === VariableSource.INDIGO_OFFER
    );

    // only use preference fields if they exist
    const preferenceFormulaFields = formulaFields?.filter((formulaField) =>
      formulaField.key.includes('preference')
    );

    const formulaFieldsToSearch = preferenceFormulaFields?.length
      ? preferenceFormulaFields
      : formulaFields;

    const matchingFormulaField = formulaFieldsToSearch?.find(
      (formulaField) => flattenedVariableValues[formulaField.key]
    );

    const value = matchingFormulaField
      ? (flattenedVariableValues[matchingFormulaField.key] as number | string)
      : undefined;

    if (matchingFormulaField) {
      acc[field.id] = {
        value,
        matchingFormulaField,
      };
    }

    return acc;
  }, {});

  return {
    baseValues,
    documentVersionAutofillValues:
      contractVariableValuesData?.offerAutofillValues,
  };
};
