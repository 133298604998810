import {
  ActivityFragment,
  ActivityType,
} from '@client/graphql/__generated__/types';
import { enumToLabel } from '~/services/document/utils/enums';

import { StorefrontActivity } from './StorefrontActivity';

export const StorefrontUserSubscribedActivity = ({
  listingActivity,
}: {
  listingActivity: ActivityFragment;
}) => {
  const metadata = listingActivity.metadata as {
    [ActivityType.STOREFRONT_USER_SUBSCRIBED]: { reason: string };
  };
  const reason = metadata?.[ActivityType.STOREFRONT_USER_SUBSCRIBED]?.reason;

  return (
    <StorefrontActivity
      listingActivity={listingActivity}
      object={enumToLabel(reason, false)}
      verb="subscribed to"
    />
  );
};
