import { Icon, IconProps } from '@chakra-ui/react';
import { MapPinIcon } from 'lucide-react';

export const DefaultMapMarker = ({
  color = 'gray.700',
  fill = 'gray.400',
}: {
  color?: IconProps['color'];
  fill?: IconProps['fill'];
}) => {
  return <Icon as={MapPinIcon} boxSize="20px" color={color} fill={fill} />;
};
