import { Flex, Spacer, Text } from '@chakra-ui/react';
import {
  ContractObject,
  DocumentSectionModuleType,
} from '@client/graphql/__generated__/types';
import { DocumentSectionWithChildren } from '~/services/document/components/DocumentSections/types';
import { OfferField } from './OfferCard';

type OfferCardKeyTermFieldsProps = {
  fieldValues: ContractObject['fieldValues'];
  cardSections: Record<
    DocumentSectionModuleType,
    DocumentSectionWithChildren | undefined
  >;
};

export const OfferCardKeyTermFields = ({
  fieldValues,
  cardSections,
}: OfferCardKeyTermFieldsProps) => {
  return (
    <>
      {cardSections?.KEY_TERMS?.documentSectionFields?.map((sectionField) => (
        <OfferField
          key={`offer-field-${sectionField.id}`}
          field={sectionField.field}
          value={fieldValues?.[sectionField.field.mappingKey]}
        />
      ))}
      {cardSections?.KEY_TERMS?.children?.map((section) => {
        const sectionTitle = section.title;

        return (
          <Flex
            key={`offer-section-${section.id}`}
            flexDirection="column"
            mb={4}
          >
            <Text
              fontSize="14px"
              fontWeight="500"
              letterSpacing={1}
              mb={2}
              textTransform="uppercase"
            >
              {sectionTitle}
            </Text>
            <Spacer />
            {section.documentSectionFields?.map((sectionField) => (
              <OfferField
                key={`offer-field-${sectionField.id}`}
                field={sectionField.field}
                value={fieldValues?.[sectionField.field.mappingKey]}
              />
            ))}
          </Flex>
        );
      })}
    </>
  );
};
