import { SearchIcon } from '@chakra-ui/icons';
import {
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Popover,
  PopoverAnchor,
  PopoverBody,
  PopoverContent,
  Spinner,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { useSearchMlsListingsByAddressLazyQuery } from '@client/graphql/__generated__/main-operations';
import { MlsListingObject } from '@client/graphql/__generated__/types';
import { debounce } from 'lodash';
import { useCallback, useEffect } from 'react';

interface FindPropertyPopoverListProps {
  closeMenuOnSelect?: boolean;
  onSelect: (listing: MlsListingObject) => Promise<void> | void;
  size?: 'sm' | 'md' | 'lg';
}

export const FindPropertyPopoverList = ({
  closeMenuOnSelect = false,
  onSelect,
  size = 'md',
}: FindPropertyPopoverListProps) => {
  const {
    isOpen: isMenuOpen,
    onClose: onMenuClose,
    onOpen: onMenuOpen,
  } = useDisclosure();

  const [searchMlsListing, { data, loading }] =
    useSearchMlsListingsByAddressLazyQuery({
      // TODO(mike): do we need this? copy/pasta from AddComparablePopoverList
      fetchPolicy: 'no-cache',
    });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce(async (searchString: string) => {
      await searchMlsListing({
        variables: {
          input: {
            searchString,
            limit: 5,
            excludeIds: [],
            onlyLiveStatuses: true,
          },
        },
      });
    }, 500),
    [searchMlsListing]
  );

  useEffect(() => {
    const searchResults = data?.searchMlsListingsByAddress || [];

    if (searchResults?.length) {
      onMenuOpen();
    }
  }, [data, onMenuOpen]);

  const mlsListings = data?.searchMlsListingsByAddress;

  return (
    <Popover
      matchWidth
      autoFocus={false}
      isOpen={isMenuOpen}
      placement="bottom-start"
      onClose={onMenuClose}
      onOpen={onMenuOpen}
    >
      <PopoverAnchor>
        <InputGroup size={size}>
          <InputLeftElement h="full">
            <SearchIcon color="whiteAlpha.300" />
          </InputLeftElement>
          <Input
            placeholder="Search by address"
            onBlur={() => {
              window.requestAnimationFrame(() => {
                onMenuClose();
              });
            }}
            onChange={(e) => {
              void debouncedSearch(e.target.value);
            }}
            onFocus={() => {
              if (mlsListings?.length) {
                onMenuOpen();
              } else {
                onMenuClose();
              }
            }}
          />
          {loading && (
            <InputRightElement>
              <Spinner size="xs" />
            </InputRightElement>
          )}
        </InputGroup>
      </PopoverAnchor>
      {!!mlsListings?.length && (
        <PopoverContent width="inherit">
          <PopoverBody>
            {mlsListings?.map((listing: MlsListingObject) => (
              <Flex
                key={listing.id}
                _hover={{
                  backgroundColor: 'whiteAlpha.300',
                  cursor: 'pointer',
                }}
                px={2}
                py={4}
                onClick={() => {
                  void onSelect(listing);
                }}
                onMouseDown={(e) => {
                  // This is to prevent the popover from closing due to the input onBlur running before the onClick
                  if (!closeMenuOnSelect) {
                    e.preventDefault();
                  }
                }}
              >
                <Text size="lg">{listing.addressFull}</Text>
              </Flex>
            ))}
          </PopoverBody>
        </PopoverContent>
      )}
    </Popover>
  );
};
