import { EmailIcon, PhoneIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Flex,
  Heading,
  IconButton,
  Link,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import { useBuyerClientsForCurrentAgentUserQuery } from '@client/graphql/__generated__/main-operations';
import { FC, useCallback } from 'react';
import { useNavigate } from 'react-router';
import { AddBuyerModal } from '../../components/Buyer/AddBuyerModal/AddBuyerModal';
import { Container } from '../../components/Layouts/Container';
import { SIDEBAR_WIDTH_COLLAPSED } from '../../components/PageDetailLayout/SidebarItem';
import { UserAvatar } from '../../components/User/UserAvatar';
import { getPartyEmail, getPartyPhoneNumber } from '../../utils/party.utils';

export const BuyersPage: FC = () => {
  const { data: { buyerClientsForCurrentAgentUser } = {}, refetch } =
    useBuyerClientsForCurrentAgentUserQuery();

  const navigate = useNavigate();
  const handleClickRow = useCallback(
    (buyerPartyId: string) => {
      navigate(`/buyers/${buyerPartyId}`);
    },
    [navigate]
  );
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Container width={`calc(100vw - ${SIDEBAR_WIDTH_COLLAPSED}px)`}>
      <Flex justifyContent="space-between" mb={5}>
        <Heading
          as="h2"
          display={{ base: 'none', md: 'block' }}
          size="sm"
          whiteSpace="normal"
        >
          Buyers
        </Heading>

        <Button size="xs" onClick={onOpen}>
          Add a buyer
        </Button>
      </Flex>

      <Box>
        <TableContainer whiteSpace="normal">
          <Table size={{ base: 'sm', md: 'lg' }}>
            <Thead>
              <Tr>
                <Th flex={2}>Name</Th>
                <Th flex={1}>Purchase offers</Th>
                <Th flex={1}>Contact</Th>
              </Tr>
            </Thead>
            <Tbody>
              {buyerClientsForCurrentAgentUser?.map((party) => {
                const consumers = [
                  party.primaryConsumer,
                  ...party.secondaryConsumers,
                ];
                const primaryConsumer = party.primaryConsumer;
                const phoneNumber = getPartyPhoneNumber(party);
                const email = getPartyEmail(party);

                return (
                  <Tr
                    key={party.id}
                    _hover={{ cursor: 'pointer' }}
                    onClick={() => handleClickRow(party.id)}
                  >
                    <Td width={2}>
                      <Flex alignItems="center">
                        <UserAvatar mr={3} size="sm" user={primaryConsumer} />
                        <Text fontWeight="bold">
                          {consumers.map((consumer, index) => (
                            <span key={consumer.id}>
                              {index > 0 ? ', ' : ''}
                              {consumer.fullName}
                            </span>
                          ))}
                        </Text>
                      </Flex>
                    </Td>
                    <Td width={1}>{party.purchaseOffers?.length}</Td>
                    <Td width={1}>
                      <Flex>
                        {phoneNumber ? (
                          <Link isExternal href={`tel:${phoneNumber}`}>
                            <IconButton
                              aria-label="Contact by phone"
                              icon={<PhoneIcon />}
                              variant="ghost"
                            />
                          </Link>
                        ) : (
                          <IconButton
                            aria-label="Contact by phone"
                            icon={<PhoneIcon />}
                            isDisabled={true}
                            variant="ghost"
                          />
                        )}
                        {email ? (
                          <Link isExternal href={`mailto:${email}`}>
                            <IconButton
                              aria-label="Contact by email"
                              icon={<EmailIcon />}
                              variant="ghost"
                            />
                          </Link>
                        ) : (
                          <IconButton
                            aria-label="Contact by email"
                            icon={<EmailIcon />}
                            isDisabled={true}
                            variant="ghost"
                          />
                        )}
                      </Flex>
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
      <AddBuyerModal isOpen={isOpen} refetch={refetch} onClose={onClose} />
    </Container>
  );
};
