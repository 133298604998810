import { Heading } from '@chakra-ui/react';
import { theme } from '~/common/theme';

export const ComingSoon = () => {
  return (
    <Heading
      bgClip="text"
      bgGradient={theme.colors.purpleGradient}
      size="smallUppercase"
    >
      Coming soon
    </Heading>
  );
};
