import { useDocumentSectionListForOfferFlowQuery } from '@client/graphql/__generated__/document-operations';
import {
  ComparisonType,
  DocumentSectionCategory,
  DocumentSectionModuleType,
  FieldType,
} from '@client/graphql/__generated__/types';
import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { FieldWithPage } from '~/services/document/components/DocumentSections/types';
import { useListingSetupContext } from '../../../pages/ListingSetup/ListingSetupContext';
import { OfferWithContract } from '../../../pages/ListingSetup/ListingWithOffersProvider';
import { offerDetailsPath } from '../../../utils/routes.utils';
import { ComparablesContainer } from '../../Comparables/styledComponents';
import { OfferCompareColumn } from './OfferCompareColumn';
import { OfferCompareListingColumn } from './OfferCompareListingColumn';
import { getCompareValue, getSectionFields } from './helpers';
import { useCompareOfferBaseValues } from './hooks/useCompareOfferBaseValues';

export type OffersCompareViewProps = {
  offersWithContracts: OfferWithContract[];
};

export const OffersCompareView = ({
  offersWithContracts,
}: OffersCompareViewProps) => {
  const { listing } = useListingSetupContext();
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = location.search;

  const navigateToOffer = useCallback(
    (offerId: string) => {
      const baseOfferPath = offerDetailsPath(listing.id, offerId, searchParams);
      navigate(baseOfferPath);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [listing.id, searchParams]
  );

  const offers = offersWithContracts.map((o) => o.offer);

  const { data: comparisonDocumentSectionData } =
    useDocumentSectionListForOfferFlowQuery({
      variables: {
        filters: {
          usState: listing.property.state,
          documentSectionCategory: DocumentSectionCategory.OFFER_COMPARISON,
        },
      },
    });

  const offerComparisonFields = getSectionFields(
    comparisonDocumentSectionData,
    DocumentSectionModuleType.KEY_TERMS
  );

  const compareValueMap: Record<string, number | undefined> = {};

  offerComparisonFields.forEach((field) => {
    if (field.fieldType === FieldType.NUMBER) {
      const compareValue = getCompareValue(
        field.mappingKey,
        offers,
        field.comparisonType === ComparisonType.LOWEST
      );
      compareValueMap[field.id] = compareValue;
    }
  });

  // NOTE: this is a compare view so we're assuming each column shares same version of contract
  //       passing down shared values also helps reducing the number of queries
  const baseCompareValues = useCompareOfferBaseValues(
    listing,
    offerComparisonFields as FieldWithPage[]
  );

  return (
    <ComparablesContainer bgColor="transparent" border="none">
      <OfferCompareListingColumn
        baseValues={baseCompareValues.baseValues}
        offerComparisonFields={offerComparisonFields as FieldWithPage[]}
      />
      {offersWithContracts.map((offer, idx) => (
        <OfferCompareColumn
          key={offer.offer.id}
          baseCompareValues={baseCompareValues}
          borderRadius={
            idx === 0
              ? '10px 0 0 0'
              : idx === offersWithContracts.length - 1
                ? '0 10px 0 0'
                : undefined
          }
          buyersAgentName={offer.buyersAgentName}
          compareValueMap={compareValueMap}
          offer={offer.offer}
          offerComparisonFields={offerComparisonFields as FieldWithPage[]}
          officeName={offer.officeName}
          onClick={() => navigateToOffer(offer.offer.id)}
        />
      ))}
    </ComparablesContainer>
  );
};
