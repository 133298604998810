import {
  Button,
  ButtonProps,
  Flex,
  Icon,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
} from '@chakra-ui/react';
import { ChevronDownIcon } from 'lucide-react';
import { PropsWithChildren } from 'react';

interface FilterDropdownButtonProps extends PropsWithChildren {
  filterGroupName: string;
  icon: ButtonProps['leftIcon'];
  containerRef?: React.RefObject<HTMLElement>;
  activeFilterCount?: number;
  closeOnBlur?: boolean;
}

export const FilterDropdownButton = ({
  filterGroupName,
  children,
  icon,
  containerRef,
  activeFilterCount,
  closeOnBlur = true,
}: FilterDropdownButtonProps) => {
  return (
    <Popover isLazy closeOnBlur={closeOnBlur}>
      <PopoverTrigger>
        <Button
          borderColor="whiteAlpha.300"
          borderRadius="5px"
          color="white"
          leftIcon={icon}
          rightIcon={
            <Flex alignItems="center" gap={1}>
              {!!activeFilterCount && (
                <Flex
                  alignItems="center"
                  bgColor="purple.500"
                  borderRadius="50%"
                  boxSize="18px"
                  justifyContent="center"
                >
                  {activeFilterCount}
                </Flex>
              )}
              <Icon as={ChevronDownIcon} />
            </Flex>
          }
          size="sm"
          variant="outline"
        >
          {filterGroupName}
        </Button>
      </PopoverTrigger>
      <Portal containerRef={containerRef}>
        <PopoverContent p={2} width="420px">
          <PopoverArrow />
          <PopoverHeader>
            <PopoverCloseButton right={4} top={4} />
            {filterGroupName}
          </PopoverHeader>
          <PopoverBody>{children}</PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};
