import {
  Button,
  Heading,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useIsOpenHouseGuestBookSignedQuery } from '@client/graphql/__generated__/main-operations';
import {
  StorefrontSubscriptionReason,
  UserObject,
} from '@client/graphql/__generated__/types';
import { PenToolIcon } from 'lucide-react';
import { useEffect, useState } from 'react';
import { VerifyUserModalWithSignGuestbook } from '~/apps/consumer/components/Storefront/VerifyUserModalWithSignGuestbook';
import { MUTE_ERROR_TOAST_QUERY_OPTIONS } from '~/services/document/utils/apollo-client';
import { useAppContext } from '~/services/main/contexts/AppContext';
import { useSignGuestbookQueries } from './hooks/useSignGuestbookQueries';
import { useSignGuestbookState } from './hooks/useSignGuestbookState';

export const VerifyUserModalWithAutoOpenHouseSign = () => {
  const { isAuthenticated } = useAppContext();
  const [showManualSignModal, setShowManualSignModal] = useState(false);
  const [showVerifyUserModal, setShowVerifyUserModal] = useState(false);
  const {
    isSignOpenHouseGuestbook,
    openHouseId,
    isSigningFromOpenHouseQR,
    clearOpenHouseSearchParams,
  } = useSignGuestbookState();
  const { useSignGuestbookMutation } = useSignGuestbookQueries();
  const [signOpenHouseGuestbook] = useSignGuestbookMutation;

  useEffect(() => {
    if (isSignOpenHouseGuestbook) {
      if (!isAuthenticated) {
        setShowVerifyUserModal(true);
      } else {
        setShowManualSignModal(true);
      }
    }
    // we only want to run this effect once when the component mounts, no need to listen to any other related states changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSignOpenHouseGuestbook = async (user?: UserObject) => {
    if (isSignOpenHouseGuestbook && openHouseId && user?.id) {
      clearOpenHouseSearchParams();
      await signOpenHouseGuestbook({
        variables: {
          input: {
            openHouseId,
            userId: user.id,
          },
        },
      });
    }
  };

  return (
    <>
      {!isAuthenticated && (
        <VerifyUserModalWithSignGuestbook
          handleSignGuestbook={handleSignOpenHouseGuestbook}
          headingText={
            isSigningFromOpenHouseQR ? 'Sign the guestbook' : undefined
          }
          setShowVerifyUserModal={setShowVerifyUserModal}
          showVerifyUserModal={showVerifyUserModal}
          signReason={StorefrontSubscriptionReason.SIGN_OPEN_HOUSE_GUESTBOOK}
        />
      )}
      {isAuthenticated && (
        <SignOpenHouseGuestbookModal
          isOpen={showManualSignModal}
          onClose={() => setShowManualSignModal(false)}
          onSign={handleSignOpenHouseGuestbook}
        />
      )}
    </>
  );
};

const SignOpenHouseGuestbookModal = ({
  isOpen,
  onClose,
  onSign,
}: {
  isOpen: boolean;
  onClose: () => void;
  onSign: (user: UserObject) => Promise<void>;
}) => {
  const { user } = useAppContext();
  const { openHouseId } = useSignGuestbookState();

  const { data, error, loading } = useIsOpenHouseGuestBookSignedQuery({
    variables: {
      input: {
        openHouseId: openHouseId!,
      },
    },
    skip: !openHouseId,
    ...MUTE_ERROR_TOAST_QUERY_OPTIONS,
  });

  if (loading || error) {
    return null;
  }

  const handleSign = () => {
    if (user) {
      void onSign(user).then(() => {
        onClose();
      });
    }
  };

  const isSigned = data?.isOpenHouseGuestbookSigned === true;
  const heading = isSigned ? 'Guestbook signed' : 'Sign the guestbook';

  const description = isSigned ? (
    <Text>
      Thank you for signing the guestbook!
      <br />
      Please click the button below to continue.
    </Text>
  ) : (
    <Text>
      We're delighted to have you here.
      <br />
      Please sign the guestbook by clicking the button below.
    </Text>
  );

  const nextButton = isSigned ? (
    <Button w="full" onClick={onClose}>
      Continue
    </Button>
  ) : (
    <Button leftIcon={<Icon as={PenToolIcon} />} w="full" onClick={handleSign}>
      Sign
    </Button>
  );

  return (
    <Modal
      isCentered
      isOpen={isOpen}
      size={{ base: 'md', md: 'lg' }}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent
        containerProps={{
          alignItems: 'center',
        }}
      >
        <ModalCloseButton />
        <ModalBody pt={6}>
          <VStack alignItems="flex-start" spacing={4}>
            <Heading color="whiteAlpha.600" size="heading">
              {heading}
            </Heading>
            <Text fontSize="lg" fontWeight="medium">
              Welcome, {user?.firstName}!
            </Text>
            {description}
          </VStack>
        </ModalBody>
        <ModalFooter alignItems="center" justifyContent="center">
          {nextButton}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
