import { Flex, Stack } from '@chakra-ui/react';
import { useListingsForCurrentUserQuery } from '@client/graphql/__generated__/main-operations';
import { Home } from 'lucide-react';
import { FC, useState } from 'react';
import { ListingCard } from '~/apps/consumer/components/Listings/ListingCard/ListingCard';
import { CenterSpinner } from '~/common/components/CenterSpinner';
import { SearchInput } from '~/common/components/Inputs/SearchInput';
import { useInfiniteQuery } from '~/services/document/hooks';
import { EmptyState } from '../../components/EmptyState';
import { listingsTourClassNameStep1 } from '../../components/Listings/ListingsTourModal/constants';

export const ActiveListingsPage: FC = () => {
  const [search, setSearch] = useState('');
  const [triggerRef, { data, loading }] = useInfiniteQuery(
    useListingsForCurrentUserQuery,
    {
      variables: {
        filters: {
          isActive: true,
          search,
        },
      },
    }
  );

  const hasListings = !!data?.listingsForCurrentUser?.results?.length;

  return (
    <Flex direction="column" flexGrow={1} gap={4}>
      <SearchInput
        maxW={{ base: undefined, md: '300px' }}
        onChange={(e) => setSearch(e.target.value)}
      />
      {hasListings && (
        <>
          <Stack
            direction={{ base: 'column', md: 'row' }}
            flexWrap="wrap"
            pt="6"
            spacing={{ base: 3, md: 6 }}
          >
            {data?.listingsForCurrentUser?.results?.map((listing, index) => (
              <ListingCard
                key={listing.id}
                className={index === 0 ? listingsTourClassNameStep1 : undefined}
                listing={listing}
              />
            ))}

            <div ref={triggerRef} />
          </Stack>
        </>
      )}
      {loading && <CenterSpinner />}
      {!loading && !hasListings && !search && <EmptyListings />}
      {!loading && !hasListings && search && <EmptySearchListings />}
    </Flex>
  );
};

const EmptyListings: FC = () => (
  <EmptyState
    headerText="You don’t have any live listings"
    icon={Home}
    minH="70vh"
    subText="Listings are synced from the MLS every hour and will automatically show up here once published"
  />
);

const EmptySearchListings = () => (
  <EmptyState
    headerText="No active or under contract listings that match your search"
    icon={Home}
    minH="70vh"
    w="full"
  />
);
