import { FormField } from '@document/components/FormFields/types';
import EventEmitter from 'eventemitter3';
import {
  FC,
  memo,
  MutableRefObject,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { OfferFieldsPerPage } from './OfferFieldsPerPage';

interface OfferFieldsProps {
  formFieldsPerDocument: FormField[][];
  scrollableRef: React.MutableRefObject<HTMLDivElement>;
}

const useIntersectionObserver = (options: {
  scrollableRef: MutableRefObject<HTMLDivElement>;
}) => {
  const [observer, setObserver] = useState<IntersectionObserver>();
  const entriesRef = useRef<Map<HTMLDivElement, IntersectionObserverEntry>>(
    new Map()
  );
  const emitter = useMemo(() => {
    return new EventEmitter();
  }, []);

  useEffect(() => {
    const intersectionObserver = new IntersectionObserver(
      (entries) => {
        const entriesList = Array.from(entries);
        const visibleIndices: number[] = [];

        entriesList.forEach((entry) => {
          entriesRef.current.set(entry.target as HTMLDivElement, entry);
        });

        entriesRef.current.forEach((entry, target) => {
          if (entry.isIntersecting) {
            visibleIndices.push(parseInt(target.dataset.index || '0'));
          }
        });

        const max = Math.max(...visibleIndices);
        const min = Math.min(...visibleIndices);

        visibleIndices.push(
          ...[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18]
            .map((i) => min - i)
            .filter((val) => val >= 0)
        );
        visibleIndices.push(
          ...[
            1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
          ].map((i) => max + i)
        );

        emitter.emit('visibleIndicesChange', visibleIndices);
      },
      {
        root: options.scrollableRef.current,
      }
    );

    setObserver(intersectionObserver);

    return () => {
      intersectionObserver.disconnect();
    };
  }, [emitter, options.scrollableRef]);

  return {
    observer,
    emitter,
  };
};

export const OfferFields: FC<OfferFieldsProps> = memo(
  function OfferFields(props) {
    const { observer, emitter } = useIntersectionObserver({
      scrollableRef: props.scrollableRef,
    });

    return (
      <>
        {props.formFieldsPerDocument.map((formFields, index) => (
          <OfferFieldsPerPage
            key={index}
            documentIndex={index}
            emitter={emitter}
            formFields={formFields}
            observer={observer}
          />
        ))}
      </>
    );
  }
);
