import { Box, FormControl, FormLabel, Icon, VStack } from '@chakra-ui/react';
import { MlsListingFiltersInput } from '@client/graphql/__generated__/types';
import { Select } from 'chakra-react-select';
import isNil from 'lodash/isNil';
import { TrendingUpIcon } from 'lucide-react';
import { RangeCreatableSelectInput } from '../../RangeCreatableSelectInput/RangeCreatableSelectInput';
import { RangeInput } from '../../RangeInput/RangeInput';
import { useExploreContext } from '../ExploreContext';
import { FilterDropdownButton } from './FilterDropdownButton';
import { DAYS_ON_MARKET_RANGE_OPTIONS, YES_NO_OPTIONS } from './constants';
import { getNumActiveFilters } from './helpers';

export const DemandFilters = () => {
  const { filters } = useExploreContext();

  const demandFiltersList: (keyof MlsListingFiltersInput)[] = [
    'daysOnMarket',
    'hasOffers',
    'numSubscribers',
  ];

  const numActiveFilters = getNumActiveFilters(filters, demandFiltersList);

  return (
    <FilterDropdownButton
      activeFilterCount={numActiveFilters}
      filterGroupName="Demand filters"
      icon={<Icon as={TrendingUpIcon} />}
    >
      <VStack>
        <DemandFilterContent />
      </VStack>
    </FilterDropdownButton>
  );
};

export const DemandFilterContent = () => {
  const { filters, updateFilters } = useExploreContext();

  return (
    <>
      <RangeCreatableSelectInput
        initialMaxValue={filters?.daysOnMarket?.max}
        initialMinValue={filters?.daysOnMarket?.min}
        label="Days on market"
        options={DAYS_ON_MARKET_RANGE_OPTIONS}
        onChangeMax={(value) => {
          updateFilters({
            daysOnMarket: {
              max: value,
              min: filters?.daysOnMarket?.min,
            },
          });
        }}
        onChangeMin={(value) => {
          updateFilters({
            daysOnMarket: {
              max: filters?.daysOnMarket?.max,
              min: value,
            },
          });
        }}
      />
      <FormControl>
        <FormLabel>Has offers</FormLabel>
        <Box w="full">
          <Select
            isClearable
            useBasicStyles
            defaultValue={
              !isNil(filters?.hasOffers)
                ? YES_NO_OPTIONS.find(
                    (option) => option.value === filters.hasOffers
                  )
                : undefined
            }
            options={YES_NO_OPTIONS}
            placeholder="Any"
            onChange={(options) => {
              updateFilters({
                hasOffers: options?.value,
              });
            }}
          />
        </Box>
      </FormControl>
      <RangeInput
        initialMaxValue={filters?.numSubscribers?.max}
        initialMinValue={filters?.numSubscribers?.min}
        label="Number of subscribers"
        onChangeMax={(value) => {
          updateFilters({
            numSubscribers: {
              max: value,
              min: filters?.numSubscribers?.min,
            },
          });
        }}
        onChangeMin={(value) => {
          updateFilters({
            numSubscribers: {
              max: filters?.numSubscribers?.max,
              min: value,
            },
          });
        }}
      />
    </>
  );
};
