export function flattenObject(
  obj: any,
  prefix = '',
  result: Record<string, any> = {}
): Record<string, any> {
  Object.entries(obj).forEach(([key, value]) => {
    const flattenedKey = prefix ? `${prefix}.${key}` : key;
    if (value && typeof value === 'object' && !Array.isArray(value)) {
      flattenObject(value, flattenedKey, result);
    } else {
      result[flattenedKey] = value;
    }
  });

  return result;
}

export function mapById<T extends { id: string | number }>(
  arr: T[]
): Record<string | number, T> {
  return arr.reduce(
    (acc, item) => {
      acc[item.id] = item;
      return acc;
    },
    {} as Record<string | number, T>
  );
}

export function mapByUuid<T extends { uuid: string | number }>(
  arr: T[]
): Record<string | number, T> {
  return arr.reduce(
    (acc, item) => {
      acc[item.uuid] = item;
      return acc;
    },
    {} as Record<string | number, T>
  );
}

export function dotKeysToNestedObject(obj: Record<string, any>): any {
  const result: Record<string, any> = {};

  for (const [dotKey, value] of Object.entries(obj)) {
    const keys = dotKey.split('.');
    keys.reduce((res, key, idx) => {
      if (idx === keys.length - 1) {
        res[key] = value;
      } else if (!res[key] || typeof res[key] !== 'object') {
        res[key] = {};
      }
      return res[key];
    }, result);
  }

  return result;
}
