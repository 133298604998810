import { createContext, useContext } from 'react';

interface MobileHeaderContextValues {
  isDrawerOpen: boolean;
  onDrawerOpen: () => void;
  onDrawerClose: () => void;
}

export const MobileHeaderContext = createContext<MobileHeaderContextValues>(
  {} as MobileHeaderContextValues
);

export const useMobileHeaderContext = () => {
  return useContext(MobileHeaderContext);
};
