import { VariableDataType } from '@common/autofill';
import { USState } from '@common/enums';
import * as formulajsModule from '@formulajs/formulajs';
import dayjs, { ManipulateType } from 'dayjs';
import map from 'lodash/map';
import { VariableField } from '../modules/autofill/autofill.types';
export * from '../modules/autofill/autofill.types';

// eslint-disable-next-line @typescript-eslint/no-var-requires

export const formulajs = Object.keys(formulajsModule).reduce((out, key) => {
  out[key] = formulajsModule[key];
  return out;
}, {}) as any;

(globalThis as any).formulajs = formulajs;

export function getVariableValue(variable: VariableField, value?: any): string {
  switch (variable.type) {
    case VariableDataType.NUMBER:
      return String(value) || '0';
    case VariableDataType.BOOLEAN:
      return String(value) || 'false';
    case VariableDataType.LIST:
      if (Array.isArray(value)) {
        return JSON.stringify(value);
      }
      return String(value) || '[]';
    default:
      return String(value) || '';
  }
}

export function evaluateFormula(
  formula: string,
  variables: VariableField[],
  values: Record<string, string | number | boolean | string[] | undefined>
): { output?: string; error?: string } {
  let evalScript = encodeURI(formula.replace(/\w*\(/g, 'formulajs.$&'));

  variables.forEach((variable) => {
    const varValue = getVariableValue(variable, values[variable.key]);
    const value = getVariableValue(
      variable,
      !['undefined', 'null'].includes(varValue) ? String(varValue) : ''
    );

    if (variable.type === VariableDataType.TEXT) {
      evalScript = evalScript.replace(
        new RegExp(encodeURI(variable.key), 'g'),
        value
      );
    } else {
      evalScript = evalScript.replace(
        new RegExp(encodeURI(`"${variable.key}"`), 'g'),
        value
      );
    }
  });

  try {
    const output = globalThis.eval(decodeURI(evalScript));

    if (output instanceof Error) {
      return {
        error: output.message,
      };
    }

    return {
      output,
    };
  } catch (e) {
    return {
      error: e.message,
    };
  }
}

export function convertFormulaOutput(
  dataType: VariableDataType,
  output: string
) {
  switch (dataType) {
    case VariableDataType.BOOLEAN:
      return output === 'true';
    case VariableDataType.LIST:
      return output.split(', ');
    case VariableDataType.NUMBER:
      return parseFloat(output);
    default:
      return output;
  }
}

// Formulat JS Custom methods
Object.assign(formulajs, {
  DATEADD: (date: string, add: number, unit: ManipulateType) => {
    const parsed = dayjs(date);

    if (parsed.isValid()) {
      return parsed.add(add, unit).toDate();
    }

    return '';
  },
  DATESUBTRACT: (date: string, subtract: number, unit: ManipulateType) => {
    const parsed = dayjs(date);

    if (parsed.isValid()) {
      return parsed.subtract(subtract, unit).toDate();
    }

    return '';
  },
  DATEFORMAT: (date: string | Date, format: string) => {
    return dayjs(date).format(format);
  },
  WORDSUBSTR: (text: string, startCharIndex: number, length: number) => {
    // Split the input text into an array of words with their starting indices and lengths
    const words: { word: string; index: number; length: number }[] = [];
    const textLength = text?.length || 0;
    let wordStartIndex = 0;

    if (textLength === 0 || startCharIndex > textLength) return '';

    text.split(/\s+/).forEach((word) => {
      wordStartIndex = text.indexOf(word, wordStartIndex);
      words.push({ word: word, index: wordStartIndex, length: word.length });
      wordStartIndex += word.length;
    });

    // Find the closest word that starts before or at the startCharIndex
    let startIndex = words.findIndex(
      (w) => w.index + w.word.length > startCharIndex
    );
    if (startIndex > 0 && words[startIndex].index > startCharIndex) {
      startIndex--; // Move one word back if the found word starts after the startCharIndex
    }

    // Initialize variables to determine the end index based on the character length limit
    let endIndex = startIndex;
    let totalLength = 0;

    // Calculate total length of the words until it reaches or exceeds the endCharIndex
    while (endIndex < words.length && totalLength < length) {
      totalLength +=
        words[endIndex].word.length + (endIndex > startIndex ? 1 : 0); // Add 1 for space
      if (totalLength > length) break;
      endIndex++;
    }

    // Slice the array from startIndex to endIndex (not inclusive of the endIndex if it exceeds the limit)
    const selectedWords = words.slice(startIndex, endIndex);

    // Join the selected words back into a string and return it
    return selectedWords.map((w) => w.word).join(' ');
  },
  MAPBY: (value: any[], key: string) => {
    return map(value, key).filter(Boolean);
  },
  EXTRACT_US_STATE: (value: string) => {
    const states = Object.values(USState);
    const regex = new RegExp(`\\b(${states.join('|')})\\b`);
    const match = value.match(regex);

    return match ? match[0] : null;
  },
  SLICE: (value: any[], start: number, end: number) => {
    return value.slice(start, end);
  },
});
