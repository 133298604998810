import { Text } from '@chakra-ui/react';
import { ActivityFragment } from '@client/graphql/__generated__/types';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { ActivityItem } from './ActivityItem';

dayjs.extend(relativeTime);

export const ComparablesUpdateActivity = ({
  listingActivity,
}: {
  listingActivity: ActivityFragment;
}) => {
  return (
    <ActivityItem activity={listingActivity}>
      <Text as="span" color="white" fontWeight="medium">
        {listingActivity?.user?.fullName}
      </Text>{' '}
      <Text as="span">updated the listing comparables</Text>
    </ActivityItem>
  );
};
