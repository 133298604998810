import {
  Button,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Textarea,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import { useCreateOrUpdateListingComparableAdjustmentMutation } from '@client/graphql/__generated__/main-operations';
import {
  ListingComparableSetFragment,
  MlsListingFragment,
} from '@client/graphql/__generated__/types';
import { MessageCirclePlusIcon, NotepadTextIcon } from 'lucide-react';
import { memo, useEffect, useState } from 'react';

interface NotesTooltipProps {
  mlsListing?: MlsListingFragment;
  comparableSet?: ListingComparableSetFragment;
  isEditMode?: boolean;
  isHovered?: boolean;
}

export const NotesTooltip = memo(
  ({ mlsListing, comparableSet, isEditMode, isHovered }: NotesTooltipProps) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [notes, setNotes] = useState<string | null>(null);

    const [updateComparableAdjustments, { loading }] =
      useCreateOrUpdateListingComparableAdjustmentMutation();

    useEffect(() => {
      const newNotes = comparableSet?.listingComparables?.find(
        (comparable) => comparable.mlsListing.id === mlsListing?.id
      )?.adjustments?.notes;
      setNotes(newNotes ?? null);
    }, [comparableSet?.listingComparables, mlsListing?.id]);

    const tooltipContent = notes ? notes : 'Add a comment';

    const onSave = () => {
      if (!comparableSet?.id || !mlsListing?.id) {
        return;
      }
      void (async () => {
        await updateComparableAdjustments({
          variables: {
            input: {
              listingComparableSetId: comparableSet.id,
              mlsListingId: mlsListing.id,
              adjustments: {
                notes: notes || (null as unknown as undefined),
              },
            },
          },
        });
        onClose();
      })();
    };

    if ((!isEditMode && !notes) || (isEditMode && !isHovered)) {
      return null;
    }

    return (
      <>
        <Tooltip label={tooltipContent}>
          <Icon
            _hover={{
              color: 'cyan.700',
            }}
            as={notes ? NotepadTextIcon : MessageCirclePlusIcon}
            boxSize={5}
            color="cyan.500"
            mr={2}
            onClick={isEditMode ? onOpen : undefined}
            onMouseDown={(e) => e.stopPropagation()}
          />
        </Tooltip>

        {/* NOTE: Need to add onMouseDown stopPropagation to prevent the dnd draggable under the modal from hijacking the click event */}
        <Modal isOpen={isOpen} returnFocusOnClose={false} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton />
            <ModalHeader>Add a comment</ModalHeader>
            <ModalBody>
              <Textarea
                placeholder="e.g. Renovated Kitchen"
                value={notes ?? undefined}
                onChange={(e) => {
                  setNotes(e.target.value);
                }}
                onKeyDown={(e) => {
                  e.stopPropagation();
                }}
                onMouseDown={(e) => e.stopPropagation()}
              />
            </ModalBody>
            <ModalFooter gap={2}>
              <Button
                variant="outline"
                onClick={() => onClose()}
                onMouseDown={(e) => e.stopPropagation()}
              >
                Cancel
              </Button>
              <Button
                isLoading={loading}
                onClick={() => {
                  onSave();
                }}
                onMouseDown={(e) => e.stopPropagation()}
              >
                Save
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    );
  }
);
