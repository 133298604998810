import { AccessRole } from '@client/graphql/__generated__/types';
import { OrganizationHeading } from '~/apps/consumer/components/Organization/OrganizationHeading';
import { AccessControls } from '~/common/components/AccessControls/AccessControls';
import { useAppContext } from '~/services/main/contexts/AppContext';

export const AccessControlsPage = () => {
  const { selectedOrganization, refetchOrganizations } = useAppContext();

  return (
    <AccessControls
      containerProps={{ width: { base: '100%', md: '80%' } }}
      inviteAccessRoles={[
        AccessRole.CO_OWNER,
        AccessRole.ADMIN,
        AccessRole.MEMBER,
      ]}
      inviteDefaultAccessRole={AccessRole.MEMBER}
      organizationId={selectedOrganization?.id}
      title={<OrganizationHeading />}
      onSelfRemoved={() => {
        void refetchOrganizations();
      }}
    />
  );
};
