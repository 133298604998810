import { DocumentSectionType } from '@client/graphql/__generated__/types';
import { useMemo } from 'react';
import { FormField } from '../../FormFields/types';
import {
  DocumentSection,
  DocumentSectionFieldWithPageNumber,
  DocumentSectionWithChildren,
} from '../types';

interface UserDocumentSectionsByPageOptions {
  documentSections?: DocumentSection[];
  formFieldsMap: Record<string, FormField>;
}

export const useDocumentSectionsByPage = (
  options: UserDocumentSectionsByPageOptions
) => {
  const { documentSections, formFieldsMap } = options;
  const multiPageSections = useMemo(
    () => new Set<string>(),
    [documentSections, formFieldsMap] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const sectionsMap = useMemo(() => {
    const map: Map<string, DocumentSectionFieldWithPageNumber> = new Map();

    documentSections?.forEach((section) => {
      const documentSectionFields = section.documentSectionFields
        // Filter out fields that are not in the formFieldsMap
        // This is to handle the case where a field is removed from the active
        // document version but the documentSectionField is not removed from the database
        ?.filter(({ field }) => !!formFieldsMap[field.mappingKey])
        .map((field) => {
          const formField = formFieldsMap[field.field.mappingKey];

          return {
            ...field,
            pageNumber: formField.pageNumber,
          };
        });

      const pageNumbers =
        documentSectionFields?.reduce(
          (output, field) => {
            if (output[field.pageNumber]) {
              output[field.pageNumber]++;
            } else {
              output[field.pageNumber] = 1;
            }

            return output;
          },
          {} as Record<number, number>
        ) || {};

      const pageNumber = Object.entries(pageNumbers).reduce((output, next) => {
        if (!output) return next;

        if (output[1] === next[1]) {
          return parseInt(output[0]) > parseInt(next[0]) ? output : next;
        }

        return output[1] < next[1] ? next : output;
      }, null);

      const pages = new Set(
        documentSectionFields?.map((field) => field.pageNumber)
      );
      const pageNums = Array.from(pages.values()).sort();

      if (pageNums.length > 1) {
        multiPageSections.add(pageNums.join(','));
      }

      map.set(section.id, {
        ...section,
        documentSectionFields,
        pageNumber: pageNumber ? Number(pageNumber[0]) : 0,
        pageNumbers: Array.from(pages.values()).sort(),
        children: [],
      });
    });

    documentSections?.forEach((section) => {
      if (section.parent?.id) {
        map
          .get(section.parent.id)
          ?.children?.push(map.get(section.id) as DocumentSectionWithChildren);
      }
    });

    documentSections?.forEach((section) => {
      const sectionWithChildren = map.get(section.id);

      if (sectionWithChildren && !sectionWithChildren?.pageNumber) {
        const pageNumbersSet = new Set(
          sectionWithChildren?.children?.flatMap(
            ({ pageNumbers }) => pageNumbers
          )
        );
        const pageNumbers = Array.from(
          pageNumbersSet.values()
        ).sort() as number[];

        sectionWithChildren.pageNumber = pageNumbers[0];
        sectionWithChildren.pageNumbers = pageNumbers;
      }
    });

    return map;
  }, [documentSections, formFieldsMap, multiPageSections]);

  return useMemo(() => {
    const byPage = Array.from(sectionsMap.values())
      .filter(
        (section) => section.documentSectionType === DocumentSectionType.SECTION
      )
      .reduce(
        (output, section) => {
          const pageNumbersKey = section.pageNumber;

          if (pageNumbersKey) {
            if (output[pageNumbersKey]) {
              output[pageNumbersKey].push(section);
            } else {
              output[pageNumbersKey] = [section];
            }
          }

          return output;
        },
        {} as Record<string, DocumentSectionWithChildren[]>
      );

    const byPageMap = new Map(Object.entries(byPage));
    const result: Map<number[], DocumentSectionWithChildren[]> = new Map();

    multiPageSections.forEach((pages) => {
      const pageNums = pages.split(',').map((page) => parseInt(page));
      const sections = pageNums.reduce((output, pageNum) => {
        const byPageSections = byPageMap.get(String(pageNum));

        if (byPageSections) {
          byPageMap.delete(String(pageNum));

          return output.concat(byPageSections);
        }

        return output;
      }, [] as DocumentSectionWithChildren[]);

      result.set(pageNums, sections);
    });

    return Array.from(result.entries())
      .concat(
        Array.from(byPageMap.entries()).map(
          ([key, value]) =>
            [[parseInt(key)], value] as [
              number[],
              DocumentSectionWithChildren[],
            ]
        )
      )
      .sort(([a], [b]) => a[0] - b[0]);
  }, [multiPageSections, sectionsMap]);
};
