import { Center, Heading } from '@chakra-ui/react';
import { useUpdateStorefrontSetupMutation } from '@client/graphql/__generated__/main-operations';
import { StorefrontSetupStep } from '@client/graphql/__generated__/types';
import { useNavigate } from 'react-router';
import { Form } from '~/apps/consumer/components/Formik/Form';
import { getStorefrontSetupWizardRoute } from '~/apps/consumer/utils/routes.utils';
import { DocumentsPageContent } from '../Documents/DocumentsPage';
import { useDocumentsPage } from '../Documents/hooks/useDocumentsPage';
import { DocumentFormValueType } from '../Documents/types';
import { useStorefrontSetupWizardOutletContext } from './StorefrontSetupWizardContext';
import { StorefrontSetupWizardPageLayout } from './StorefrontSetupWizardPageLayout';
import { shouldUpdateContinueStep } from './utils';

export const DocumentsPage = () => {
  const { listing, refetch } = useStorefrontSetupWizardOutletContext();
  const navigate = useNavigate();
  const [updateListingSetup] = useUpdateStorefrontSetupMutation();
  const {
    initialValues,
    uploadDocuments,
    uploading,
    deleting,
    loading,
    uploadingAgentNegotiation,
    disclosureAndAgentNegotiationDocumentTypes,
    additionalDocumentTypes,
    agentNegotiationDocumentTypes,
    onSubmitAgentNegotiationPreferences,
    initialAgentNegotiationValues,
    updatingAgentNegotiationPreferences,
    loadingAgentNegotiationPreferences,
    agentNegotiationPreferenceGroup,
    showAgentCompensationForm,
  } = useDocumentsPage({ listing, refetchListing: refetch, showToast: true });

  const onSubmit = async (values: DocumentFormValueType) => {
    await uploadDocuments(values);
  };

  const updateContinueStep = async () => {
    if (
      shouldUpdateContinueStep({
        currentStep: listing?.storefrontSetup?.continueStep,
        updatingStep: StorefrontSetupStep.SHARE,
      })
    ) {
      await updateListingSetup({
        variables: {
          input: {
            listingId: listing?.id,
            continueStep: StorefrontSetupStep.SHARE,
          },
        },
      });
    }
    navigate(
      getStorefrontSetupWizardRoute(listing?.id, StorefrontSetupStep.SHARE)
    );
  };

  return (
    <Form enableReinitialize initialValues={initialValues} onSubmit={onSubmit}>
      {() => (
        <StorefrontSetupWizardPageLayout
          onNext={async () => {
            await updateContinueStep();
          }}
          onPrevious={() => {
            navigate(
              getStorefrontSetupWizardRoute(
                listing?.id,
                StorefrontSetupStep.COMPARABLES
              )
            );
          }}
        >
          <Center
            flexDirection="column"
            gap={8}
            mt={{ base: 4, lg: 16 }}
            pb={4}
            px={4}
            width={{ base: '100%', lg: '80%' }}
          >
            <Heading
              maxWidth={{
                base: '70%',
              }}
              textAlign="center"
            >
              Securely track views and downloads of your listing documents
            </Heading>
            <DocumentsPageContent
              additionalDocumentTypes={additionalDocumentTypes}
              agentNegotiationDocumentTypes={agentNegotiationDocumentTypes}
              agentNegotiationPreferenceGroup={agentNegotiationPreferenceGroup}
              deleting={deleting}
              disclosureDocumentTypes={
                disclosureAndAgentNegotiationDocumentTypes
              }
              initialAgentNegotiationValues={initialAgentNegotiationValues}
              loading={loading}
              loadingAgentNegotiationPreferences={
                loadingAgentNegotiationPreferences
              }
              showAgentCompensationForm={showAgentCompensationForm}
              updatingAgentNegotiationPreferences={
                updatingAgentNegotiationPreferences
              }
              uploading={uploading}
              uploadingAgentNegotiation={uploadingAgentNegotiation}
              onSubmitAgentNegotiationPreferences={
                onSubmitAgentNegotiationPreferences
              }
            />
          </Center>
        </StorefrontSetupWizardPageLayout>
      )}
    </Form>
  );
};
