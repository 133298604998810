import {
  Button,
  Card,
  CardBody,
  Flex,
  Icon,
  Input,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useExtractPreapprovalInfoMutation } from '@client/graphql/__generated__/document-operations';
import {
  ExtractPreapprovalInfoPayload,
  FileObject,
} from '@client/graphql/__generated__/types';
import { FileText, Paperclip } from 'lucide-react';
import { useDropzone } from 'react-dropzone';
import { LogoSpinner } from '~/common/components/LogoSpinner/LogoSpinner';

interface PreapprovalDropzoneProps {
  file?: File | FileObject;
  onUpload?: (file: File) => Promise<void> | void;
  onView?: () => void;
  onExtract?: (data?: ExtractPreapprovalInfoPayload) => Promise<void> | void;
}

export const PreapprovalDropzone = ({
  file,
  onUpload,
  onExtract,
  onView,
}: PreapprovalDropzoneProps) => {
  const [extractPreapprovalInfo, { loading }] =
    useExtractPreapprovalInfoMutation();
  const onDrop = (acceptedFiles: File[]) => {
    if (!acceptedFiles.length) {
      return;
    }
    void (async () => {
      const { data } = await extractPreapprovalInfo({
        variables: {
          input: {
            file: acceptedFiles[0],
          },
        },
      });
      await onUpload?.(acceptedFiles[0]);
      await onExtract?.(data?.extractPreapprovalInfo);
    })();
  };
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'application/pdf': ['.pdf'],
    },
    onDrop: (acceptedFiles: File[]) => void onDrop(acceptedFiles),
    multiple: false,
  });

  const loadingCard = (
    <Card>
      <CardBody p={2}>
        <Flex alignItems="center" gap={2} justifyContent="center">
          <LogoSpinner />
          <Text>Processing Buyer Information</Text>
        </Flex>
      </CardBody>
    </Card>
  );

  const dropzone = (
    <Input
      _hover={{
        backgroundColor: 'whiteAlpha.200',
        cursor: 'pointer',
      }}
      alignItems="center"
      as={VStack}
      borderColor="purple.500"
      borderStyle="dashed"
      gap={1}
      height="100%"
      justifyContent="center"
      padding={6}
      placeholder="Upload pre-approval"
      position="relative"
      {...getRootProps()}
    >
      <Icon as={Paperclip} color="purple.500" />
      <Text color="white">Upload pre-approval or proof of funds</Text>
      <Text color="text.light">We’ll automatically fill in all fields</Text>
      <input {...getInputProps()} />
    </Input>
  );

  const fileCard = (
    <Card>
      <CardBody>
        <Flex justifyContent="space-between">
          <Flex alignItems="center" gap={2} overflow="hidden">
            <Icon as={FileText} />
            <Text overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
              {file instanceof File ? file.name : file?.fileName}
            </Text>
          </Flex>
          <Flex gap={1}>
            <Button cursor="pointer" variant="link">
              Replace
              <Input
                bottom={0}
                cursor="pointer"
                height="100%"
                left={0}
                opacity={0}
                p={0}
                position="absolute"
                right={0}
                top={0}
                type="file"
                width="100%"
                onChange={(e) => {
                  void onDrop([e.target.files?.[0] as File]);
                }}
              />
            </Button>
            {onView && (
              <Button variant="link" onClick={onView}>
                View
              </Button>
            )}
          </Flex>
        </Flex>
      </CardBody>
    </Card>
  );

  return loading ? loadingCard : file ? fileCard : dropzone;
};
