import { Dispatch, SetStateAction, useCallback } from 'react';

export const useMergeState = <T = unknown>(
  setFn: Dispatch<SetStateAction<T>>,
  onChange?: (input: T) => void
) => {
  return useCallback(
    (partial: Partial<T>) => {
      setFn((prev) => {
        const merged = { ...prev, ...partial };

        window.requestAnimationFrame(() => {
          onChange && onChange(merged);
        });

        return merged;
      });
    },
    [setFn, onChange]
  );
};
