import { AddIcon } from '@chakra-ui/icons';
import { Button, Flex } from '@chakra-ui/react';
import { useComparablesContext } from '~/apps/consumer/pages/ListingSetup/Comparables/ComparablesContext';

export const ComparableEditButtons = () => {
  const { onOpenModal } = useComparablesContext();

  return (
    <Flex gap={2}>
      <Button
        height={8}
        leftIcon={<AddIcon />}
        size="sm"
        type="button"
        variant="outline"
        onClick={() => onOpenModal && onOpenModal()}
      >
        Add
      </Button>
    </Flex>
  );
};
