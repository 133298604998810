import { Box, Image, Tag, Text } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { DEFAULT_PROPERTY_IMAGE_URL } from '~/apps/consumer/utils/listing.utils';

export interface ComparablePhotoProps {
  src: string;
  status?: string;
  closeDate?: string;
}
export const ComparablePhoto = ({
  src,
  status,
  closeDate,
}: ComparablePhotoProps) => {
  return (
    <Box position="relative">
      <Image
        alt="Listing photo"
        fallbackSrc={DEFAULT_PROPERTY_IMAGE_URL}
        height={{ base: '120px', md: '150px' }}
        objectFit="cover"
        objectPosition="center"
        src={src || undefined}
        w="100%"
      />
      {status && (
        <Box left={2} position="absolute" right="60px" top={2}>
          <Tag variant="dark">
            <Text overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
              {status}
              {closeDate ? ` - ${dayjs(closeDate).format('MM/DD/YYYY')}` : ''}
            </Text>
          </Tag>
        </Box>
      )}
    </Box>
  );
};
