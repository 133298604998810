import { Button, ButtonProps } from '@chakra-ui/react';
import { FC } from 'react';
import {
  Link,
  matchPath,
  NavLink,
  NavLinkProps,
  useLocation,
} from 'react-router-dom';

export interface NavButtonProps extends Omit<NavLinkProps, 'to'> {
  activePathOverride?: string;
  isMobile?: boolean;
  isExpanded?: boolean;
  isSubNav?: boolean;
  onClick?: () => void;
  to?: string;
}

export const NavButton: FC<ButtonProps & NavButtonProps> = ({
  activePathOverride,
  children,
  isMobile,
  isExpanded,
  onClick,
  to,
  ...props
}) => {
  let ariaCurrent: 'page' | undefined = undefined;
  const location = useLocation();

  if (activePathOverride) {
    const isActive = !!matchPath(
      {
        path: activePathOverride,
        end: false,
        caseSensitive: false,
      },
      location.pathname
    );
    ariaCurrent = isActive ? 'page' : undefined;
  }

  return (
    <Button
      _activeLink={{
        color: 'link.navActive !important',
        borderLeft: '2px solid',
        borderLeftColor: 'purple.500',
        borderLeftRadius: 0,
      }}
      _hover={{
        color: 'link.navHover',
      }}
      aria-current={ariaCurrent}
      as={to ? (activePathOverride ? Link : NavLink) : undefined}
      color="link.nav"
      data-testid={`nav-${to?.replace(/\//g, '')}`}
      display="flex"
      flexDir={isMobile ? 'column' : 'row'}
      fontSize={{ base: 'sm', md: 'md' }}
      height="auto"
      iconSpacing={isExpanded ? 2 : 0}
      px={4}
      py={2}
      to={to}
      transition="color 0.3s"
      variant="unstyled"
      onClick={onClick}
      {...props}
    >
      {children}
    </Button>
  );
};
