import { Icon, IconProps } from '@chakra-ui/react';
import { FC, memo } from 'react';

export const TrashIcon: FC<IconProps> = memo(function TrashIcon(props) {
  return (
    <Icon
      fill="none"
      height="17px"
      viewBox="0 0 14 17"
      width="14px"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.99273 6.19257L8.74109 13.1155M5.25891 13.1155L5.00727 6.19257M12.2567 3.72341C12.5055 3.7634 12.7527 3.80571 13 3.85109M12.2567 3.72341L11.48 14.4024C11.4483 14.8371 11.2626 15.2432 10.96 15.5394C10.6574 15.8356 10.2603 16.0001 9.848 16H4.152C3.73971 16.0001 3.34256 15.8356 3.03999 15.5394C2.73741 15.2432 2.5517 14.8371 2.52 14.4024L1.74327 3.72341M12.2567 3.72341C11.4174 3.58919 10.5736 3.48733 9.72727 3.41803M1.74327 3.72341C1.49455 3.76263 1.24727 3.80494 1 3.85032M1.74327 3.72341C2.58264 3.58919 3.42635 3.48733 4.27273 3.41803M9.72727 3.41803V2.71343C9.72727 1.80576 9.06545 1.04886 8.20727 1.0204C7.40263 0.9932 6.59737 0.9932 5.79273 1.0204C4.93455 1.04886 4.27273 1.80653 4.27273 2.71343V3.41803M9.72727 3.41803C7.9118 3.26963 6.0882 3.26963 4.27273 3.41803"
        stroke="#DE3C2B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
});
