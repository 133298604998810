import {
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { UserObject } from '@client/graphql/__generated__/types';
import { useSearchParams } from 'react-router-dom';
import {
  LoginForm,
  LoginFormSteps,
} from '~/services/main/components/auth/Login/LoginForm';
import { useAppContext } from '~/services/main/contexts/AppContext';

export const VerifyUserModal = ({
  headingText = 'Verify your phone number',
  showVerifyUserModal,
  setShowVerifyUserModal,
  onSignup,
}: {
  headingText?: string;
  showVerifyUserModal: boolean;
  setShowVerifyUserModal: (value: boolean) => void;
  onSignup?: (user?: UserObject) => Promise<void>;
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { user, refetchUser } = useAppContext();

  const toast = useToast();

  const isSignUp = [LoginFormSteps.MLSAgent, LoginFormSteps.SignUp].includes(
    (searchParams.get('step') || '') as LoginFormSteps
  );
  const isOpen = showVerifyUserModal;
  const onClose = () => setShowVerifyUserModal(false);

  const onLogin = async (user?: UserObject) => {
    if (onSignup) {
      await onSignup(user);
    }
    toast({
      title: 'Verification Success',
      description: 'You have successfuly verified your phone number.',
      isClosable: true,
    });
    onClose();
    await refetchUser();
  };

  return (
    <Modal
      isCentered
      isOpen={isOpen}
      size={{ base: 'md', md: 'lg' }}
      onClose={() => {
        if (!isSignUp || user?.roles.length) {
          setSearchParams((prev) => {
            prev.delete('step');
            prev.delete('userType');

            return prev;
          });

          onClose();
        }
      }}
    >
      <ModalOverlay />
      <ModalContent
        containerProps={{
          alignItems: 'center',
        }}
      >
        <ModalCloseButton />
        <ModalBody pt={6}>
          <VStack alignItems="flex-start" spacing={4}>
            {!isSignUp && <Heading size="heading">{headingText}</Heading>}
            {!isSignUp && (
              <Text>Please verify your phone number to continue</Text>
            )}

            <LoginForm
              signupFormInstructions="Tell us a little about you"
              onLogin={onLogin}
            />
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
