import {
  Button,
  Card,
  CardBody,
  FormControl,
  FormErrorMessage,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { useShareStorefrontWithBuyerMutation } from '@client/graphql/__generated__/main-operations';
import { ListingFragment } from '@client/graphql/__generated__/types';
import { Check, Facebook, Link } from 'lucide-react';
import { useRef, useState } from 'react';
import { CopyPasteButton } from '~/apps/consumer/components/CopyPasteButton';
import { useSharePage } from '~/apps/consumer/pages/ListingSetup/Share/hooks/useSharePage';
import { getStorefrontUrlWithAnalytics } from '~/apps/consumer/utils/storefront.utils';
import {
  ModalTrigger,
  ModalTriggerBoxProps,
} from '~/common/components/Modal/ModalTrigger';

type ShareStorefrontModalTriggerProps = {
  listing: ListingFragment;
  children: React.ReactNode;
};

export const ShareStorefrontModalTrigger = ({
  listing,
  children,
  ...props
}: ShareStorefrontModalTriggerProps & ModalTriggerBoxProps) => (
  <ModalTrigger
    modal={ShareStorefrontModal}
    modalProps={{ listing }}
    {...props}
  >
    {children}
  </ModalTrigger>
);

type ShareStorefrontModalProps = {
  listing: ListingFragment;
  isOpen: boolean;
  onClose: () => void;
};

const ShareStorefrontModal = ({
  listing,
  isOpen,
  onClose,
}: ShareStorefrontModalProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [buyerInfo, setBuyerInfo] = useState<{
    recipientEmail?: string;
    recipientPhone?: string;
  }>();
  const [error, setError] = useState<string>();
  const toast = useToast();

  const { facebookShareLink } = useSharePage(listing);
  const [shareStorefrontWithBuyer, { loading }] =
    useShareStorefrontWithBuyerMutation();

  const shareCard = (
    icon: React.ReactNode,
    title: string,
    onClick: () => void
  ) => (
    <Card
      _hover={{
        cursor: 'pointer',
      }}
      bg="whiteAlpha.50"
      border="none"
      flex={1}
      h="150px"
      justifyContent="center"
      onClick={onClick}
    >
      <CardBody
        alignItems="center"
        display="flex"
        height="100%"
        justifyContent="center"
        width="100%"
      >
        <VStack spacing={3}>
          <Icon color="cyan.500" h="24px" w="24px">
            {icon}
          </Icon>
          <Text textAlign="center">{title}</Text>
        </VStack>
      </CardBody>
    </Card>
  );

  const handleSubmit = () => {
    if (error) {
      return;
    }
    void shareStorefrontWithBuyer({
      variables: {
        input: {
          listingId: listing.id,
          ...buyerInfo,
        },
      },
    }).then(({ data }) => {
      if (data?.shareStorefrontWithBuyer) {
        toast({
          title: 'Storefront shared!',
          description: 'The storefront details have been sent.',
          status: 'success',
        });

        setTimeout(() => {
          setBuyerInfo(undefined);

          // Clean input field after successful share
          if (inputRef.current) {
            inputRef.current.value = '';
          }
          // Reset error state
          setError(undefined);
        }, 500);
      }
    });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Share</ModalHeader>
        <ModalCloseButton />
        <ModalBody px={6}>
          <VStack alignItems="flex-start">
            <Text fontWeight="medium">Send to an email or phone number</Text>
            <FormControl isInvalid={!!buyerInfo && !!error}>
              <InputGroup size="md">
                <Input
                  ref={inputRef}
                  borderRadius="sm"
                  name="recipient"
                  placeholder="jdoe@example.com or (123) 456-7890"
                  pr="4.5rem"
                  onChange={(e) => {
                    const value = e.target.value;
                    const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(
                      value
                    );
                    const isValidPhone = /^\d{10}$/.test(
                      value.replace(/\D/g, '')
                    );
                    if (!isValidEmail && !isValidPhone) {
                      setError('Please enter a valid email or phone number');
                    } else {
                      setError(undefined);
                    }
                    if (isValidEmail) {
                      setBuyerInfo({ recipientEmail: value });
                    } else if (isValidPhone) {
                      setBuyerInfo({ recipientPhone: value });
                    }
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleSubmit();
                    }
                  }}
                />
                <InputRightElement height="100%" width="4.5rem">
                  <Button
                    borderRadius="xs"
                    h="1.75rem"
                    isLoading={loading}
                    size="sm"
                    onClick={handleSubmit}
                  >
                    Send
                  </Button>
                </InputRightElement>
              </InputGroup>
              {error && <FormErrorMessage>{error}</FormErrorMessage>}
            </FormControl>
            <HStack display="flex" flexDirection="row" gap={2} mt={4} w="full">
              {shareCard(<Facebook />, 'Share on Facebook', () =>
                window.open(facebookShareLink)
              )}

              <CopyPasteButton
                _hover={{
                  bg: 'none',
                }}
                buttonContentCopied={shareCard(
                  <Check />,
                  'Link Copied',
                  () => {}
                )}
                buttonContentCopy={shareCard(<Link />, 'Copy link', () => {})}
                copyText={getStorefrontUrlWithAnalytics(listing.property.slug, {
                  utm_content: 'copy-link',
                })}
                flex={1}
                fontSize="16px"
                fontWeight="regular"
                lineHeight="24px"
                minWidth={0}
                px={0}
                size="xs"
                variant="ghost"
              />
            </HStack>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
