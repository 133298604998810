import { Flex } from '@chakra-ui/react';
import { useCallback } from 'react';
import { useNavigate } from 'react-router';
import { useIsMobile } from '~/common/hooks/useIsMobile';
import { ActivityPageContent } from '../../components/Activity/ActivityPageContent';
import { ActivityPageProvider } from '../../components/Activity/ActivityPageProvider';
import { AlertsPageSidebar } from '../../components/Activity/AlertsPageSidebar';
import { MobileNavDrawer } from '../../components/Listings/ListingSetup/MobileNavDrawer';
import { PageDetailLayout } from '../../components/PageDetailLayout/PageDetailLayout';

export const ActivityPage = () => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();

  const handleClickBack = useCallback(() => {
    navigate('/listings');
  }, [navigate]);

  return (
    <ActivityPageProvider>
      <PageDetailLayout>
        {!isMobile && <AlertsPageSidebar />}
        {isMobile && (
          <MobileNavDrawer
            Sidebar={AlertsPageSidebar}
            backButtonText="Listing alerts"
            onClickBack={handleClickBack}
          />
        )}
        <Flex
          boxSizing="border-box"
          flexDirection="column"
          justifyContent="center"
          overflowX="hidden"
          p={{ base: 0, md: 6 }}
          width="100%"
        >
          <ActivityPageContent />
        </Flex>
      </PageDetailLayout>
    </ActivityPageProvider>
  );
};
