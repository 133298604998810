import { Text, VStack } from '@chakra-ui/react';
import { GroupBase } from 'chakra-react-select';
import { useField } from 'formik';
import { components, OptionProps } from 'react-select';
import { Select, SelectProps } from '~/services/document/common/Select';

interface OptionType {
  label: string;
  value: string;
  description?: string;
}

export interface SingleSelectWithDescriptionFieldProps
  extends SelectProps<OptionType, false> {
  name: string;
  options: OptionType[];
}

const Option = (
  props: OptionProps<OptionType, false, GroupBase<OptionType>>
) => {
  return (
    <components.Option {...props}>
      <VStack align="flex-start" gap={0}>
        <Text>{props.label}</Text>
        {props.data.description && (
          <Text color="whiteAlpha.600" fontSize="sm">
            {props.data.description}
          </Text>
        )}
      </VStack>
    </components.Option>
  );
};

export const SingleSelectWithDescriptionField = ({
  name,
  options,
  ...rest
}: SingleSelectWithDescriptionFieldProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, meta, helpers] = useField({
    name,
    type: 'select',
    multiple: false,
  });
  const value = field.value as string;

  return (
    <Select
      {...rest}
      components={{ Option }}
      isMulti={false}
      menuPlacement="auto"
      menuPortalTarget={document.body}
      options={options}
      styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
      value={options.find((option) => option.value === value)}
      onChange={(selectedOption: OptionType | null) => {
        if (selectedOption) {
          void helpers.setValue(selectedOption.value);
        }
      }}
    />
  );
};
