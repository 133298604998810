import { Input, InputProps } from '@chakra-ui/react';
import { useField } from 'formik';

export interface TextFieldProps extends InputProps {
  name: string;
}

export const TextField = ({ name, ...rest }: TextFieldProps) => {
  const [field] = useField(name);

  return <Input {...rest} {...field} />;
};
