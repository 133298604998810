import { PartyFragment } from '@client/graphql/__generated__/types';
import { FINANCING_OPTIONS } from '../components/Buyer/constants';

export const getPartyPhoneNumber = (
  party: PartyFragment
): string | undefined => {
  const primaryConsumer = party.primaryConsumer;
  const secondaryConsumers = party.secondaryConsumers;

  return (
    primaryConsumer.phoneNumber ||
    secondaryConsumers?.find((consumer) => consumer.phoneNumber)?.phoneNumber
  );
};

export const getPartyEmail = (party: PartyFragment): string | undefined => {
  const primaryConsumer = party.primaryConsumer;
  const secondaryConsumers = party.secondaryConsumers;

  return (
    primaryConsumer.email ||
    secondaryConsumers?.find((consumer) => consumer.email)?.email
  );
};

export const filterParties = (
  search: string,
  parties?: PartyFragment[]
): PartyFragment[] => {
  if (!search || !parties?.length) {
    return parties || [];
  }

  return parties.filter(
    (party) =>
      party.primaryConsumer.fullName
        .toLowerCase()
        .includes(search.toLowerCase()) ||
      party.secondaryConsumers?.some((consumer) =>
        consumer.fullName.toLowerCase().includes(search.toLowerCase())
      )
  );
};

export const formatPartyConsumers = (party: PartyFragment) => {
  const consumers = [
    party.primaryConsumer,
    ...(party.secondaryConsumers || []),
  ];

  return consumers
    .map((consumer, index) => {
      return `${index > 0 ? ', ' : ''}${consumer.fullName}`;
    })
    .join('');
};

export const getPartyFinancingTypeLabel = (party?: PartyFragment) => {
  const financingType = party?.partyFinancing?.financingType;

  return (
    FINANCING_OPTIONS.find((option) => option.value === financingType)?.label ||
    financingType
  );
};
