import {
  InputFormatObject,
  InputFormatType,
} from '@client/graphql/__generated__/types';
import { useCallback, useEffect, useMemo, useState } from 'react';

interface UseNumberFieldOptions {
  inputFormat?: Omit<InputFormatObject, '__typename'>;
  value?: string | number;
  onChange?: (value: string | number) => void;
}

export const useNumberField = ({
  onChange,
  inputFormat,
  value,
}: UseNumberFieldOptions) => {
  const [isFocus, setIsFocus] = useState(false);
  const onChangeNumber = useCallback(
    (value: string) => {
      if (onChange) {
        const matchNumbers = value.match(/(\d)(\.?)/g) || [];
        onChange(matchNumbers.join(''));
      }
    },
    [onChange]
  );
  const formatter = useMemo(() => {
    const precision =
      typeof inputFormat?.options.precision === 'number'
        ? inputFormat?.options.precision
        : 0;

    const options: Intl.NumberFormatOptions = {
      style: 'decimal',
      minimumFractionDigits: precision,
      maximumFractionDigits: precision,
    };

    if (inputFormat?.type === InputFormatType.CURRENCY) {
      Object.assign(options, {
        style: 'currency',
        currency: 'USD',
      });
    }

    return new Intl.NumberFormat('en-US', options);
  }, [inputFormat]);

  useEffect(() => {
    if (!isFocus && value) {
      const precision = inputFormat?.options.precision || 2;

      if (onChange) {
        onChange(parseFloat(parseFloat(value as string).toFixed(precision)));
      }
    }
  }, [inputFormat?.options.precision, isFocus, onChange, value]);

  const formatValue = isFocus ? value : formatter.format(value as number);

  return {
    formatValue,
    isFocus,
    setIsFocus,
    onChangeNumber,
  };
};
