import {
  ContractValidationFragment,
  DocumentSectionDisplayType,
} from '@client/graphql/__generated__/types';
import {
  DocumentSectionField,
  DocumentSection as DocumentSectionType,
  DocumentSectionWithChildren,
} from '~/services/document/components/DocumentSections/types';
import {
  FormField,
  ValuesType,
} from '~/services/document/components/FormFields/types';
import { PrefillSellerPrefs } from '../../pages/Storefront/OfferFlow/KeyTermsPage';
import {
  DocumentSectionBuyerSelect,
  DocumentSectionBuyerSelectProps,
} from './DocumentSection/DocumentSectionBuyerSelect';
import { DocumentSectionDefault } from './DocumentSection/DocumentSectionDefault';
import { DocumentSectionPropertiesSearch } from './DocumentSection/DocumentSectionPropertiesSearch';
import { DocumentSectionTab } from './DocumentSection/DocumentSectionTab';

export type DocumentSectionChildren =
  | DocumentSectionField
  | DocumentSectionType;

export interface DocumentSectionProps {
  documentSection: DocumentSectionWithChildren;
  fieldValues: ValuesType;
  mergedAutofillValues: ValuesType;
  optionsAutofillValues?: ValuesType;
  formFieldsMap: Record<string, FormField>;
  isSubSection?: boolean;
  styleRootSectionCard?: boolean;
  prefillSellerPrefs?: PrefillSellerPrefs;
  showTitle?: boolean;
  index?: number;
  validations?: ContractValidationFragment[];
  tabVariant?: string;
  buyerSelectProps?: DocumentSectionBuyerSelectProps;
  setActiveMappingKey?: (mappingKey?: string) => void;
  onChange: (value: ValuesType) => void;
}

export const getRadioFieldOption = (
  radioFields: DocumentSectionChildren[],
  fieldValues: ValuesType
) => {
  const radioField = radioFields.find(
    (radioField: DocumentSectionField) =>
      fieldValues[radioField.field.mappingKey]
  ) as DocumentSectionField;

  return radioField
    ? {
        label: radioField.field.label,
        value: radioField.field.mappingKey,
      }
    : null;
};

export const DocumentSection = (props: DocumentSectionProps) => {
  switch (props.documentSection.documentSectionDisplayType) {
    case DocumentSectionDisplayType.DEFAULT:
      return <DocumentSectionDefault {...props} />;
    case DocumentSectionDisplayType.TABS:
      return <DocumentSectionTab {...props} />;
    case DocumentSectionDisplayType.PROPERTIES_SEARCH:
      return <DocumentSectionPropertiesSearch {...props} />;
    case DocumentSectionDisplayType.BUYER_SELECT:
      return <DocumentSectionBuyerSelect {...props} />;
    default:
      return <DocumentSectionDefault {...props} />;
  }
};
