import {
  Box,
  Card,
  CardProps,
  Divider,
  Flex,
  HStack,
  Text,
} from '@chakra-ui/react';
import {
  ListingFragment,
  PublicFeaturedStorefrontFragment,
} from '@client/graphql/__generated__/types';
import isNil from 'lodash/isNil';
import { FC, ReactNode } from 'react';
import { numberFormatter } from '~/common/utils/formatter';
import {
  getListingPrice,
  getPropertySource,
} from '../../../utils/listing.utils';
import { getDaysOnMarketText } from '../../../utils/storefront.utils';
import { ListingImageInCard } from './ListingImageInCard';

export const MOBILE_CARD_MIN_HEIGHT = '150px';
export const MOBILE_IMAGE_MIN_WIDTH = '145px';

interface BaseListingCardProps extends CardProps {
  listing:
    | (ListingFragment & { isSharedListing?: boolean })
    | PublicFeaturedStorefrontFragment;
  className?: string;
  button?: ReactNode;
  adminMenu?: ReactNode;
  otherTags?: ReactNode;
  topTags?: ReactNode;
  showSubscribers?: boolean;
  onCardClick?: () => void;
  fullWidth?: boolean;
}

export const BaseListingCard: FC<BaseListingCardProps> = ({
  listing,
  className,
  button,
  adminMenu,
  otherTags,
  topTags,
  onCardClick,
  showSubscribers = true,
  fullWidth = false,
  ...otherProps
}) => {
  const price = getListingPrice(listing.mlsListing);

  return (
    <Card
      key={listing.id}
      _hover={{ cursor: 'pointer' }}
      className={className}
      data-testid="base-listing-card"
      direction="column"
      flexShrink={0}
      minH="auto"
      overflow="hidden"
      userSelect="none"
      w={fullWidth ? 'full' : '260px'}
      onClick={onCardClick}
      {...otherProps}
    >
      <Box position="relative">
        {adminMenu}
        <ListingImageInCard
          addressFull={listing.mlsListing?.addressFull}
          photo={getPropertySource(listing.mlsListing?.photos?.[0])}
        />
        <Flex
          alignItems="flex-start"
          direction="column"
          flexGrow={0}
          gap={1}
          left="10px"
          position="absolute"
          textTransform="uppercase"
          top="10px"
        >
          {topTags}
        </Flex>
      </Box>
      <Flex
        direction="column"
        gap={1}
        lineHeight={1.25}
        p={3}
        pt={2.5}
        width="100%"
      >
        <HStack spacing={2.5}>
          <Text color="white" fontSize="lg" fontWeight="semibold">
            {numberFormatter(price, {
              maxPrecision: 0,
              type: 'currency',
            })}
          </Text>

          {otherTags}
        </HStack>
        {showSubscribers && !isNil(listing.followerCount) && (
          <Text color="whiteAlpha.600" fontSize="md">
            {listing.followerCount} Subscribers
          </Text>
        )}
        <Divider />
        <Text color="whiteAlpha.600" fontSize="sm">
          {listing.mlsListing?.address.streetNumberText}{' '}
          {listing.mlsListing?.address.streetName}{' '}
          {listing.mlsListing?.address.unit}
          <br />
          {listing.mlsListing?.address.city},{' '}
          {listing.mlsListing?.address.state}{' '}
          {listing.mlsListing?.address.postalCode}
        </Text>
        <Text color="whiteAlpha.600" fontSize="sm">
          {listing.mlsListing?.property?.bedrooms} beds &bull;&nbsp;
          {listing.mlsListing?.property?.bathrooms} baths &bull;&nbsp;
          {numberFormatter(listing.mlsListing?.property?.area, {
            maxPrecision: 0,
          })}{' '}
          sq<sup>ft</sup> &bull;&nbsp;
          {getDaysOnMarketText(listing?.mlsListing?.mls?.daysOnMarket, true)}
        </Text>
        {button}
      </Flex>
    </Card>
  );
};
