import { StorefrontSetupStep } from '@client/graphql/__generated__/types';

export const STEP_ORDER = [
  StorefrontSetupStep.SELLER_PREFERENCES,
  StorefrontSetupStep.COMPARABLES,
  StorefrontSetupStep.DOCUMENTS,
  StorefrontSetupStep.SHARE,
];

export const STEP_TO_ROUTE_MAP: Record<StorefrontSetupStep, string> = {
  [StorefrontSetupStep.SELLER_PREFERENCES]: 'seller-preferences',
  [StorefrontSetupStep.COMPARABLES]: 'comparables',
  [StorefrontSetupStep.DOCUMENTS]: 'documents',
  [StorefrontSetupStep.SHARE]: 'share',
};

export const ROUTE_TO_STEP_MAP: Record<string, StorefrontSetupStep> = {
  'seller-preferences': StorefrontSetupStep.SELLER_PREFERENCES,
  comparables: StorefrontSetupStep.COMPARABLES,
  documents: StorefrontSetupStep.DOCUMENTS,
  share: StorefrontSetupStep.SHARE,
};

export const STEP_TO_TITLE_MAP = {
  [StorefrontSetupStep.SELLER_PREFERENCES]: 'Seller Preferences',
  [StorefrontSetupStep.COMPARABLES]: 'Comparables',
  [StorefrontSetupStep.DOCUMENTS]: 'Documents',
  [StorefrontSetupStep.SHARE]: 'Share',
};

export const shouldUpdateContinueStep = ({
  currentStep,
  updatingStep,
}: {
  currentStep?: StorefrontSetupStep;
  updatingStep: StorefrontSetupStep;
}) => {
  if (!currentStep) {
    return true;
  }

  return STEP_ORDER.indexOf(currentStep) < STEP_ORDER.indexOf(updatingStep);
};
