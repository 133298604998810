import { Input } from '@chakra-ui/react';
import { ComponentProps, useRef } from 'react';

type Props = {
  children: React.ReactNode;
} & Omit<ComponentProps<typeof Input>, 'type' | 'hidden' | 'ref'>;

export const UploadInputGroup = ({ children, ...props }: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    inputRef.current?.click();
  };

  return (
    <div onClick={handleClick}>
      {children}
      <Input hidden type="file" {...props} ref={inputRef} />
    </div>
  );
};
