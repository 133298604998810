import { Icon, IconProps } from '@chakra-ui/react';
import { FC } from 'react';

export const IndigoLogoSmall: FC<IconProps> = ({
  color = 'sky.300',
  ...props
}) => {
  return (
    <Icon
      color={color}
      fill="none"
      height="19"
      viewBox="0 0 18 19"
      width="18"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="9.00014" cy="3.07143" fill="currentColor" r="2.57143" />
      <circle cx="9.00014" cy="15.9269" fill="currentColor" r="2.57143" />
      <circle cx="15.4289" cy="9.50112" fill="currentColor" r="2.57143" />
      <circle cx="2.57143" cy="9.50112" fill="currentColor" r="2.57143" />
    </Icon>
  );
};
