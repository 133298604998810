import {
  Button,
  Flex,
  Heading,
  Icon,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { Zap } from 'lucide-react';
import { useState } from 'react';
import { FilesBox } from '~/common/components/FilesBox/FilesBox';
import { UploadDropzone } from '~/common/components/Upload/UploadDropzone';
import { UploadInputGroup } from '~/common/components/UploadInputGroup/UploadInputGroup';
import { theme } from '~/common/theme';

interface OfferPackagesUploadZoneProps {
  initialFiles?: File[];
  handleUpload: (files: File[]) => Promise<void> | void;
}

export const OfferPackagesUploadZone = ({
  initialFiles,
  handleUpload,
}: OfferPackagesUploadZoneProps) => {
  const [files, setFiles] = useState<File[]>(initialFiles || []);
  const {
    isOpen: isConfirmationOpen,
    onClose: onConfirmationClose,
    onOpen: onConfirmationOpen,
  } = useDisclosure();

  const onHandleUpload = () => {
    onConfirmationClose();
    void handleUpload(files);
  };

  const offerFilesCard = () => {
    return (
      <Flex
        bgColor="whiteAlpha.100"
        border="1px"
        borderColor="whiteAlpha.200"
        borderRadius={10}
        direction="column"
        padding={4}
      >
        <Flex alignItems="center" mb={5}>
          <Heading color="whiteAlpha.600" size="smallUppercase">
            Offer Files
          </Heading>
          <Spacer />
          <UploadInputGroup
            accept=".pdf"
            onChange={(e) => {
              const files = e.target.files;
              if (files?.length) {
                setFiles((prev) => [...prev, ...files]);
              }
            }}
          >
            <Button fontSize="sm" fontWeight="bold" size="xs">
              Add
            </Button>
          </UploadInputGroup>
        </Flex>

        <FilesBox
          files={files.map((file) => ({
            name: file.name,
            url: URL.createObjectURL(file),
          }))}
          onRemove={(innerIndex) =>
            setFiles((prev) => prev.filter((_, i) => i !== innerIndex))
          }
        />
      </Flex>
    );
  };

  return (
    <Flex
      direction="column"
      flex="1"
      gap={4}
      width={{ base: '100%', md: '450px' }}
    >
      {files?.length ? (
        <Flex
          alignItems="center"
          border="1px"
          borderColor={theme.colors.purple[500]}
          borderRadius="4px"
          color={theme.colors.purple[500]}
          px={3}
          py={2}
        >
          <Icon as={Zap} color={theme.colors.purple[500]} />
          <Text ml={3}>Make sure to add all offer files</Text>
        </Flex>
      ) : undefined}

      {files?.length ? (
        <Flex
          direction="column"
          gap={4}
          maxHeight={{ base: '40vh', md: 'calc(40vh - 100px)' }}
          overflowY="auto"
        >
          {offerFilesCard()}
        </Flex>
      ) : (
        <Flex flex={1}>
          <UploadDropzone
            onDrop={(files) => {
              setFiles(files);
            }}
          />
        </Flex>
      )}

      {files?.length ? (
        <Button disabled={!files?.length} onClick={() => onConfirmationOpen()}>
          Continue
        </Button>
      ) : undefined}

      <ConfirmationModal
        isOpen={isConfirmationOpen}
        onClose={onConfirmationClose}
        onConfirm={onHandleUpload}
      />
    </Flex>
  );
};

interface ConfirmationModalProps {
  onConfirm: () => void;
  onClose: () => void;
  isOpen: boolean;
}

const ConfirmationModal = ({
  onConfirm,
  onClose,
  isOpen,
}: ConfirmationModalProps) => {
  return (
    <Modal isCentered isOpen={isOpen} size="sm" onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          Are you sure you have added all offer documents?
        </ModalHeader>
        <ModalFooter gap={2}>
          <Button variant="outline" onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={onConfirm}>Confirm</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
