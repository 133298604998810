import { Card, Text } from '@chakra-ui/react';
import { QRCodeCanvas } from 'qrcode.react';
import { useIsMobile } from '~/common/hooks/useIsMobile';
import { useOpenHouseSignContext } from './OpenHouseSignContext';

export const QRCodeCard = () => {
  const { openHouseData } = useOpenHouseSignContext();
  const propertySlug = openHouseData?.openHouse.listing.property.slug;

  const isMobile = useIsMobile();

  const generateQRCodeUrl = () => {
    // !!MARK: ideally we should direct user to storefront after scanning QR code,
    //         this is for handling the case where propertySlug is not available
    const urlFallback = () => {
      const url = new URL(window.location.pathname, window.location.origin);

      return url;
    };

    const storefrontUrl = () => {
      const url = new URL(
        `/storefront/${propertySlug}`,
        window.location.origin
      );

      return url;
    };

    const url = propertySlug ? storefrontUrl() : urlFallback();
    url.searchParams.set('utm_source', 'qr');
    url.searchParams.set('utm_content', 'oh-gb-new');

    // use to track open house source
    url.searchParams.set('oh-id', openHouseData?.openHouse.id ?? '');

    return url.href;
  };

  return (
    <Card
      alignItems="center"
      direction="column"
      flex="1"
      flexShrink={0}
      gap={4}
      justifyContent="center"
      minH="auto"
      minHeight="250px"
      overflow="hidden"
      p={isMobile ? 8 : 0}
      userSelect="none"
      w={isMobile ? '100%' : '260px'}
    >
      <QRCodeCanvas
        size={isMobile ? 260 : 150}
        style={{
          padding: '0.5rem',
          background: 'white',
        }}
        value={generateQRCodeUrl()}
      />
      {!isMobile && (
        <Text color="whiteAlpha.600" fontSize="16px">
          Open on your phone
        </Text>
      )}
    </Card>
  );
};
