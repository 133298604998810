import { DocumentSectionModuleType } from '@client/graphql/__generated__/types';
import { useMemo } from 'react';
import { DocumentSection, DocumentSectionWithChildren } from '../types';

interface UseDocumentSectionOptions {
  documentSections?: DocumentSection[];
}

export const useDocumentSectionsByModuleType = ({
  documentSections,
}: UseDocumentSectionOptions) => {
  const documentSectionsMap = useMemo(() => {
    const map = documentSections?.reduce(
      (acc, section) => {
        acc[section.id] = {
          ...section,
          children: [],
        };

        return acc;
      },
      {} as Record<string, DocumentSectionWithChildren>
    );

    documentSections?.forEach((section) => {
      if (section.parent) {
        const sectionObj = map?.[section.id];
        const parentSection = map?.[section.parent.id];
        if (parentSection && sectionObj) {
          parentSection.children?.push(sectionObj);
        }
      }
    });

    return map;
  }, [documentSections]);

  const documentSectionsByModuleType = useMemo(() => {
    return Object.keys(documentSectionsMap || {}).reduce(
      (output, key) => {
        const section = documentSectionsMap?.[key];

        if (section?.documentSectionModuleType) {
          output[section?.documentSectionModuleType] = section;
        }

        return output;
      },
      {} as Record<
        DocumentSectionModuleType,
        DocumentSectionWithChildren | undefined
      >
    );
  }, [documentSectionsMap]);

  return documentSectionsByModuleType;
};
