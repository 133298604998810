import { StyleProps } from '@chakra-ui/react';
import {
  ListingComparableSetFragment,
  MlsListingFragment,
} from '@client/graphql/__generated__/types';
import isNil from 'lodash/isNil';
import { ReactNode } from 'react';
import { getPerSqFtValue } from '~/apps/consumer/utils/listing.utils';
import { numberFormatter } from '~/common/utils/formatter';
import { getDistanceInMiles } from '../../BuyerOfferAnalysis/Comps/utils';
import { NotesTooltip } from './ComparableColumns/CustomFields/NotesTooltip';
import {
  NEUTRAL_STYLE,
  getDefaultDifferenceComputer,
  getDefaultDifferenceFormatter,
  getDefaultDifferenceStyler,
} from './helpers';

type ComparableFieldsType = {
  // title field for the row, used with the main listing column
  title?: string;
  // computes a title if an explicit title cannot be provided (e.g. address row)
  titleComputer?: (mlsListing?: MlsListingFragment) => string;
  // field to use for the value, represented as a dot path if nested
  field?: string;
  // formatter for the value
  formatter?: (value: number | string) => string | undefined;
  // computes a value if more than one field is required (e.g. address row)
  valueComputer?: (
    mlsListing?: MlsListingFragment
  ) => string | number | undefined;
  subValueComputer?: (
    compMlsListing?: MlsListingFragment,
    mainMlsListing?: MlsListingFragment
  ) => string | undefined;
  // computes the difference value between the comparable and main listing
  differenceComputer?: (
    compMlsListing?: MlsListingFragment,
    mainMlsListing?: MlsListingFragment
  ) => number | undefined;
  // formatter for the difference value
  differenceFormatter?: (difference?: number) => string | undefined;
  // determines the style for the difference value (e.g. color for the pill)
  differenceStyler?: (difference?: number) => StyleProps | undefined;
  // needed for the address field since its shown for all columns, not just the main listing.
  alwaysShowTitle?: boolean;
  rightComponent?: ({
    mlsListing,
    comparableSet,
    isEditMode,
    isHovered,
  }: {
    mlsListing?: MlsListingFragment;
    comparableSet?: ListingComparableSetFragment;
    isEditMode?: boolean;
    isHovered?: boolean;
  }) => ReactNode;
};

export const COMPARABLE_FIELDS: ComparableFieldsType[] = [
  {
    titleComputer: (mlsListing?: MlsListingFragment) => {
      const streetNumber = mlsListing?.address?.streetNumberText;
      const streetName = mlsListing?.address?.streetName;
      const unit = mlsListing?.address?.unit;

      return `${streetNumber ?? ''} ${streetName ?? ''} ${unit ?? ''}`.trim();
    },
    valueComputer: (mlsListing?: MlsListingFragment) => {
      const city = mlsListing?.address?.city;
      const state = mlsListing?.address?.state;
      const postalCode = mlsListing?.address?.postalCode;

      return `${city ?? ''}, ${state ?? ''} ${postalCode ?? ''}`.trim();
    },
    subValueComputer: (
      compMlsListing?: MlsListingFragment,
      mainMlsListing?: MlsListingFragment
    ) => {
      const distanceInMiles = getDistanceInMiles(
        compMlsListing,
        mainMlsListing
      );

      return distanceInMiles
        ? `${distanceInMiles.toFixed(1)} miles away`
        : undefined;
    },
    alwaysShowTitle: true,
    rightComponent: ({ mlsListing, comparableSet, isEditMode, isHovered }) => {
      return (
        <NotesTooltip
          comparableSet={comparableSet}
          isEditMode={isEditMode}
          isHovered={isHovered}
          mlsListing={mlsListing}
        />
      );
    },
  },
  {
    title: 'Price',
    field: 'listPrice',
    formatter: (value: number) =>
      numberFormatter(value, { type: 'currency', maxPrecision: 0 }),
    differenceComputer: getDefaultDifferenceComputer({
      type: 'percent',
      field: 'listPrice',
    }),
    differenceFormatter: getDefaultDifferenceFormatter({
      maxPrecision: 0,
      type: 'percent',
    }),
    differenceStyler: getDefaultDifferenceStyler,
    valueComputer: (mlsListing?: MlsListingFragment) => {
      return (mlsListing?.sales?.closePrice || mlsListing?.listPrice) as number;
    },
  },
  {
    title: 'Bedrooms',
    field: 'property.bedrooms',
    differenceComputer: getDefaultDifferenceComputer({
      type: 'number',
      field: 'property.bedrooms',
    }),
    differenceFormatter: getDefaultDifferenceFormatter({ maxPrecision: 0 }),
    differenceStyler: getDefaultDifferenceStyler,
  },
  {
    title: 'Full Baths',
    field: 'property.bathsFull',
    differenceComputer: getDefaultDifferenceComputer({
      type: 'number',
      field: 'property.bathsFull',
    }),
    differenceFormatter: getDefaultDifferenceFormatter({ maxPrecision: 1 }),
    differenceStyler: getDefaultDifferenceStyler,
  },
  {
    title: 'Half Baths',
    field: 'property.bathsHalf',
    differenceComputer: getDefaultDifferenceComputer({
      type: 'number',
      field: 'property.bathsHalf',
    }),
    differenceFormatter: getDefaultDifferenceFormatter({ maxPrecision: 1 }),
    differenceStyler: getDefaultDifferenceStyler,
  },
  {
    title: 'Days on market',
    field: 'mls.daysOnMarket',
    differenceComputer: getDefaultDifferenceComputer({
      type: 'number',
      field: 'mls.daysOnMarket',
    }),
    differenceFormatter: getDefaultDifferenceFormatter({
      maxPrecision: 0,
      unitSuffix: 'd',
    }),
    differenceStyler: () => {
      return NEUTRAL_STYLE;
    },
  },
  {
    title: 'Property Type',
    valueComputer: (mlsListing?: MlsListingFragment) => {
      return `${mlsListing?.property?.type}${
        mlsListing?.property?.subTypeText
          ? ` - ${mlsListing?.property?.subTypeText}`
          : ''
      }`;
    },
  },
  {
    title: 'Year built',
    field: 'property.yearBuilt',
    differenceComputer: getDefaultDifferenceComputer({
      type: 'number',
      field: 'property.yearBuilt',
    }),
    differenceFormatter: (difference?: number) => {
      if (isNil(difference)) {
        return undefined;
      }
      if (difference > 0) {
        return `${difference}y newer`;
      } else if (difference < 0) {
        return `${Math.abs(difference)}y older`;
      } else {
        return '0';
      }
    },
    differenceStyler: getDefaultDifferenceStyler,
  },
  {
    title: 'Square footage',
    field: 'property.area',
    formatter: (value: number) =>
      numberFormatter(value, { maxPrecision: 0, unitSuffix: ' sq/ft' }),
    differenceComputer: getDefaultDifferenceComputer({
      type: 'percent',
      field: 'property.area',
    }),
    differenceFormatter: getDefaultDifferenceFormatter({
      maxPrecision: 0,
      type: 'percent',
    }),
    differenceStyler: getDefaultDifferenceStyler,
  },
  {
    title: 'Price per sq/ft',
    formatter: (value: number) =>
      numberFormatter(value, { maxPrecision: 0, type: 'currency' }),
    valueComputer: getPerSqFtValue,
    differenceComputer: (
      compMlsListing?: MlsListingFragment,
      mainMlsListing?: MlsListingFragment
    ) => {
      const compSqFtValue = getPerSqFtValue(compMlsListing);
      const mainSqFtValue = getPerSqFtValue(mainMlsListing);

      return !isNil(compSqFtValue) && !isNil(mainSqFtValue)
        ? (compSqFtValue - mainSqFtValue) / mainSqFtValue
        : undefined;
    },
    differenceFormatter: getDefaultDifferenceFormatter({
      maxPrecision: 0,
      type: 'percent',
    }),
    differenceStyler: getDefaultDifferenceStyler,
  },
  {
    title: 'Acres',
    field: 'property.lotSizeArea',
    formatter: (value: number) => numberFormatter(value, { maxPrecision: 1 }),
    differenceComputer: getDefaultDifferenceComputer({
      type: 'percent',
      field: 'property.lotSizeArea',
    }),
    differenceFormatter: getDefaultDifferenceFormatter({
      maxPrecision: 0,
      type: 'percent',
    }),
    differenceStyler: getDefaultDifferenceStyler,
  },
  {
    title: 'Subdivision',
    field: 'property.subdivision',
  },
  {
    title: 'MLS #',
    field: 'listingId',
  },
];
