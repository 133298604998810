export const useDisableMapControlProps = () => {
  return {
    boxZoom: false,
    doubleClickZoom: false,
    dragPan: false,
    dragRotate: false,
    keyboard: false,
    scrollZoom: false,
    touchPitch: false,
    touchZoomRotate: false,
    cursor: 'default',
  };
};
