import { OFFER_TRANSPARENCY } from './offer-transparency.types';

export const OFFER_TRANSPARENCY_FIELD = 'offerTransparency';
export const OFFER_COUNT_DISPLAY = 'offer_count_display';

export const OFFER_TRANSPARENCY_OPTIONS = [
  {
    title: 'Off',
    value: OFFER_TRANSPARENCY.OFF,
    description:
      'Only listing status and days on market will be shown. If best and final is set, it will be shown.',
    exampleTitle: 'Active',
  },
  {
    title: 'Show multiple offers',
    value: OFFER_TRANSPARENCY.MULTIPLE,
    description:
      'If multiple offers received, “Multiple offers” will be shown, not the number of offers.',
    activityLabel: 'show when multiple offers are received.',
    exampleTitle: 'Multiple offers',
  },
  {
    title: 'Show offer count',
    value: OFFER_TRANSPARENCY.COUNT,
    isRecommended: true,
    description:
      'If offers have been received, total number will be shown, not any offer details.',
    activityLabel: 'show the total number of offers.',
    exampleTitle: '3 offers',
  },
];
