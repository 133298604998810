import { Switch, Text } from '@chakra-ui/react';
import { GradientBorder } from '~/common/components/GradientBorder/GradientBorder';
import { useIsMobile } from '~/common/hooks/useIsMobile';
import { IndigoLogoSmall } from '~/common/icons';

export const PremiumFilter = ({
  backgroundColor = 'transparent',
  onChange,
  value,
}: {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  backgroundColor?: string;
  value?: boolean;
}) => {
  const isMobile = useIsMobile();

  return (
    <GradientBorder
      alignItems="center"
      backgroundColor={backgroundColor}
      direction="row"
      gap={3}
      height="44px"
      px={4}
      py={0}
    >
      <IndigoLogoSmall color="purple.gradient.end" />
      <Text background="purpleGradient" bgClip="text" fontWeight="semibold">
        {isMobile ? 'Premium Only' : 'Premium Storefronts'}
      </Text>
      <Switch
        isChecked={value}
        size="sm"
        variant="gradient"
        onChange={onChange}
      />
    </GradientBorder>
  );
};
