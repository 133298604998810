import { Box, Flex, Image, Text } from '@chakra-ui/react';
import { Options, Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import {
  DEFAULT_PROPERTY_IMAGE_URL,
  getPropertySource,
} from '~/apps/consumer/utils/listing.utils';

interface ListingPhotoCarouselProps {
  images: string[];
}

const IMAGE_MAX_HEIGHT = '266px';
const LARGE_SCREEN_BREAKPOINT = 992;

export const ListingPhotoCarousel = ({ images }: ListingPhotoCarouselProps) => {
  const options: Options = {
    type: 'swipe',
    rewind: true,
    drag: 'free',
    snap: true,
    perPage: 2,
    perMove: 1,
    gap: '0.5rem',
    pagination: false,
    breakpoints: {
      [LARGE_SCREEN_BREAKPOINT]: {
        perPage: 1,
        gap: '0.1rem',
      },
    },
    lazyLoad: 'nearby',
  };

  return (
    <Box width="100%">
      <Box
        _active={{
          cursor: 'grabbing',
        }}
        as={Splide}
        cursor="grab"
        options={options}
      >
        {images.map((image, index) => (
          <SplideSlide key={index}>
            <Image
              alt={`photo-${index}`}
              borderRadius="sm"
              fallbackSrc={DEFAULT_PROPERTY_IMAGE_URL}
              fit="cover"
              height="100%"
              maxHeight={IMAGE_MAX_HEIGHT}
              src={getPropertySource(image)}
              width="100%"
            />
            <Flex bottom={4} gap={1} position="absolute" right={4} zIndex={1}>
              <Text color="white" fontWeight="500">{`${
                index + 1
              }/${images?.length}`}</Text>
            </Flex>
          </SplideSlide>
        ))}
      </Box>
    </Box>
  );
};
