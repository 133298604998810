import { Box, Flex, Heading } from '@chakra-ui/react';
import { Form } from '~/apps/consumer/components/Formik/Form';
import { StyledFormInput } from '~/apps/consumer/components/StyledFormInput/StyledFormInput';
import { useListingSetupContext } from '~/apps/consumer/pages/ListingSetup/ListingSetupContext';
import { PublishBanner } from '~/common/components/Banner/PublishBanner';
import { CenterSpinner } from '~/common/components/CenterSpinner';
import { FormGroupCard } from '../../../components/FormGroup/FormGroupCard';
import {
  ListingSetupContainer,
  ListingSetupContentWrapper,
} from '../ListingSetupWrapperComponents';
import { FORM_ID } from './constants';
import { useSellerPreferences } from './hooks/useSellerPreferences';

export const SellerPreferencesPage = () => {
  const { listing } = useListingSetupContext();
  const {
    initialValues,
    onSubmit,
    isUpdating,
    loadingPreferences,
    preferenceGroups,
  } = useSellerPreferences({ listing });

  if (loadingPreferences) {
    return <CenterSpinner />;
  }

  return (
    <Form formId={FORM_ID} initialValues={initialValues} onSubmit={onSubmit}>
      {(formProps) => (
        <ListingSetupContainer>
          <PublishBanner
            formId={FORM_ID}
            isDirty={formProps.dirty}
            isLoading={isUpdating || loadingPreferences}
          />
          <ListingSetupContentWrapper>
            <Flex
              direction="column"
              gap={4}
              width={{ base: '100%', md: '80%' }}
            >
              <Flex direction="column" gap={4}>
                <Heading pb={2} size="xxs">
                  Seller Preferences
                </Heading>

                {preferenceGroups.map((group) => {
                  return (
                    <FormGroupCard
                      key={group.title}
                      hideButton
                      title={group.title}
                    >
                      {group.preferences.map((preference) => {
                        return (
                          <Box key={preference.name} _last={{ pb: 5 }} px={6}>
                            <StyledFormInput
                              hasPlaceholder={preference.hasPlaceholder}
                              hideLabel={preference.hideLabel}
                              inputProps={preference.inputProps}
                              label={preference.name}
                              type={preference.inputType}
                            />
                          </Box>
                        );
                      })}
                    </FormGroupCard>
                  );
                })}
              </Flex>
            </Flex>
          </ListingSetupContentWrapper>
        </ListingSetupContainer>
      )}
    </Form>
  );
};
