import { Flex, Text } from '@chakra-ui/react';
import {
  ActivityFragment,
  ActivityType,
} from '@client/graphql/__generated__/types';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { ActivityItem } from './ActivityItem';

dayjs.extend(relativeTime);

export const AnnouncementActivity = ({
  listingActivity,
}: {
  listingActivity: ActivityFragment;
}) => {
  const metadata = listingActivity.metadata as {
    [ActivityType.ANNOUNCEMENT]: { message: string };
  };
  const message = metadata?.[ActivityType.ANNOUNCEMENT]?.message;

  return (
    <Flex key={listingActivity.id} flexDirection="column" mb={6}>
      <ActivityItem activity={listingActivity} mb={0}>
        <Text as="span" color="white" fontWeight="medium">
          {listingActivity?.user?.fullName}
        </Text>{' '}
        posted an announcement{' '}
      </ActivityItem>
      {message && (
        <Flex
          background="purple.500"
          borderRadius={10}
          px={{ base: 3, md: 4 }}
          py={{ base: 2, md: 4 }}
        >
          {message}
        </Flex>
      )}
    </Flex>
  );
};
