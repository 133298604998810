import { ApolloQueryResult } from '@apollo/client';
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@chakra-ui/react';
import { useCreatePartyForBuyersAgentMutation } from '@client/graphql/__generated__/main-operations';
import {
  BuyerClientsForCurrentAgentUserQuery,
  Exact,
} from '@client/graphql/__generated__/types';
import { useState } from 'react';
import { ADD_BUYER_FORM_ID, AddBuyerForm } from '../AddBuyerForm/AddBuyerForm';
import { BuyerFormValues } from '../types';

interface AddBuyerModalProps {
  isOpen: boolean;
  onClose: () => void;
  refetch: (
    variables?:
      | Partial<
          Exact<{
            [key: string]: never;
          }>
        >
      | undefined
  ) => Promise<ApolloQueryResult<BuyerClientsForCurrentAgentUserQuery>>;
}

export const AddBuyerModal = ({
  isOpen,
  onClose,
  refetch,
}: AddBuyerModalProps) => {
  const [preapprovalFile, setPreapprovalFile] = useState<File | undefined>();
  const [createPartyForBuyersAgent, { loading }] =
    useCreatePartyForBuyersAgentMutation();
  const toast = useToast();

  const onSubmit = async (values: BuyerFormValues) => {
    await createPartyForBuyersAgent({
      variables: {
        input: {
          primaryBuyer: {
            fullName: values.buyerName,
            email: values.buyerEmail,
          },
          secondaryBuyers:
            values.coBuyerName && values.coBuyerEmail
              ? [
                  {
                    fullName: values.coBuyerName,
                    email: values.coBuyerEmail,
                  },
                ]
              : undefined,
          financingType: values.financingType,
          loanAmount: values.loanAmount,
          percentDownPayment: values.percentDownPayment,
          cashReserves: values.cashReserves,
          preapprovalFile: preapprovalFile,
        },
      },
      onCompleted: () => {
        toast({
          title: 'Buyer added',
          description: 'Buyer added successfully',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        void refetch();
        setPreapprovalFile(undefined);
        onClose();
      },
    });
  };

  return (
    <Modal isOpen={isOpen} scrollBehavior="inside" size="xl" onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add a buyer</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <AddBuyerForm
            preapprovalFile={preapprovalFile}
            setPreapprovalFile={setPreapprovalFile}
            onSubmit={onSubmit}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            form={ADD_BUYER_FORM_ID}
            isLoading={loading}
            type="submit"
            width="100%"
          >
            Add buyer
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
