import { Tag, TagProps, Text } from '@chakra-ui/react';
import { PublicOpenHouseFragment } from '@client/graphql/__generated__/types';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export const OpenHouseTag = ({
  openHouse,
  ...tagProps
}: {
  openHouse: PublicOpenHouseFragment;
} & TagProps) => {
  // TODO: BLO-880 update backend to return tz-aware timestamps instead.
  const startTsLocal = dayjs
    .utc(`${openHouse.date}T${openHouse.startTime}`)
    .local();
  const endTsLocal = dayjs
    .utc(`${openHouse.date}T${openHouse.endTime}`)
    .local();

  return (
    <Tag
      bgColor="whiteAlpha.300"
      color="whiteAlpha.700"
      textAlign="center"
      {...tagProps}
    >
      <Text color="white" fontWeight="semibold">
        Open House&nbsp;
      </Text>
      {startTsLocal.format('M/D')}&nbsp;
      {startTsLocal.format('h:mm A')} - {endTsLocal.format('h:mm A')}
    </Tag>
  );
};
