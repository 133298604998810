import { MlsListingFiltersInput } from '@client/graphql/__generated__/types';
import isArray from 'lodash/isArray';
import isNil from 'lodash/isNil';
import isPlainObject from 'lodash/isPlainObject';
import { formatToCurrency } from '~/services/document/utils/number';

export const formatCurrencyFilterValue = (value: number) => {
  return formatToCurrency(value, 0, 'compact');
};

export const formatSqFtFilterValue = (value: number) => {
  return `${value} sqft`;
};

export const formatSelectFilterValue = (
  value: string,
  options: { label: string; value: string }[]
) => {
  const option = options.find((option) => option.value === value);

  return option?.label;
};

export const formatBooleanFilterValue = (value: boolean) => {
  return value ? 'Yes' : 'No';
};

export const formatDaysFilterValue = (
  value: number | undefined,
  options: { label: string; value: number }[]
) => {
  if (isNil(value)) {
    return undefined;
  }

  const option = options.find((option) => option.value === value);

  if (option) {
    return option?.label;
  }

  return `${value} days`;
};

export const getNumActiveFilters = (
  filters: MlsListingFiltersInput,
  filterNames: (keyof MlsListingFiltersInput)[]
) => {
  return filterNames.reduce((acc, filterName) => {
    const filter = filters[filterName];

    if (isNil(filter)) {
      return acc;
    }

    if (isPlainObject(filter)) {
      const keys = Object.keys(filter);
      if (!keys.some((key) => !isNil(filter[key]))) {
        return acc;
      } else {
        return acc + 1;
      }
    }

    if (isArray(filter)) {
      return filter.length ? acc + 1 : acc;
    }

    return acc + 1;
  }, 0);
};
