import {
  Card,
  Flex,
  Image,
  Progress,
  Table,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
} from '@chakra-ui/react';
import { MlsListingStatus } from '@client/graphql/__generated__/types';
import { formatToCurrency } from '~/services/document/utils/number';
import {
  DEFAULT_BLUR_STYLES,
  useShowBlurOnScrollable,
} from '../../hooks/useShowBlurOnScrollable';
import { openInNewTab } from '../../utils/browser.utils';
import {
  DEFAULT_PROPERTY_IMAGE_URL,
  getPropertySource,
} from '../../utils/listing.utils';
import { getListingRoute } from '../../utils/routes.utils';
import { agentContactInfoFromListing } from '../Storefront/ListingAgent/ListingAgentCard';
import { UserAvatar } from '../User/UserAvatar';
import { useOrganizationListingsContext } from './OrganizationListingsContext';

export const OrganizationListingsTable = () => {
  const { listings, listingsLoading, selectedStatus, listingsQueryRef } =
    useOrganizationListingsContext();

  const { showBlur, setInitialBlurRef, handleBlurOnScroll } =
    useShowBlurOnScrollable();

  return (
    <Flex direction="column" width="100%">
      <Card
        _after={{
          display: showBlur ? 'block' : 'none',
          ...DEFAULT_BLUR_STYLES,
          minHeight: '100px',
          background:
            'linear-gradient(0deg, #161632 0%, rgba(22, 22, 50, 0.00) 100%)',
        }}
      >
        <Progress
          isIndeterminate
          height="2px"
          size="xs"
          visibility={listingsLoading ? 'visible' : 'hidden'}
          width="100%"
        />
        <Flex
          ref={setInitialBlurRef}
          direction="column"
          maxHeight="500px"
          minHeight="500px"
          overflow="auto"
          overscrollBehavior="contain"
          width="100%"
          onScroll={handleBlurOnScroll}
        >
          <Table>
            <Thead bg="indigo.700" position="sticky" top={0} zIndex={1}>
              <Tr>
                <Th w="30%">Address</Th>
                <Th w="20%">Listing Agent</Th>
                <Th w="15%">
                  {selectedStatus === MlsListingStatus.Closed
                    ? 'Sold Price'
                    : 'List Price'}
                </Th>
                <Th w="10%">Offers</Th>
                <Th w="10%">Subscribers</Th>
                <Th w="15%">Next Open House</Th>
              </Tr>
            </Thead>
            <Tbody>
              {listings.map((listing) => {
                const { fullName } = agentContactInfoFromListing(listing);
                const hasOffers = !!listing.submittedOffersCount;
                const route = hasOffers
                  ? getListingRoute(listing.id, 'offers')
                  : getListingRoute(listing.id, 'dashboard');

                return (
                  <Tr
                    key={listing.id}
                    cursor="pointer"
                    onClick={() => {
                      openInNewTab(route);
                    }}
                  >
                    <Td>
                      <Flex alignItems="center" gap={2}>
                        <Image
                          borderRadius="4px"
                          fallbackSrc={DEFAULT_PROPERTY_IMAGE_URL}
                          height="35px"
                          src={getPropertySource(
                            listing?.mlsListing?.photos?.[0]
                          )}
                          width="50px"
                        />
                        <Flex direction="column" mr={2}>
                          <Text>
                            {listing.mlsListing?.address?.streetNumberText}{' '}
                            {listing.mlsListing?.address?.streetName}{' '}
                            {listing.mlsListing?.address?.unit ?? ''}
                          </Text>
                          <Text textColor="whiteAlpha.600">
                            {listing.mlsListing?.address?.city}{' '}
                            {listing.mlsListing?.address?.state}{' '}
                            {listing.mlsListing?.address?.postalCode}
                          </Text>
                        </Flex>
                        <Tooltip
                          hasArrow
                          label={listing.mlsListing?.mls?.statusText}
                          placement="top"
                        >
                          <Tag whiteSpace="nowrap">
                            <Text overflow="hidden" textOverflow="ellipsis">
                              {listing.mlsListing?.mls?.statusText}
                            </Text>
                          </Tag>
                        </Tooltip>
                      </Flex>
                    </Td>
                    <Td>
                      <Flex alignItems="center" gap={2}>
                        <UserAvatar
                          size="sm"
                          user={{
                            fullName,
                            profilePicture:
                              listing.listingAgent?.user.profilePicture,
                          }}
                        />
                        <Text>{fullName}</Text>
                      </Flex>
                    </Td>
                    <Td>
                      {formatToCurrency(
                        listing.mlsListing?.sales?.closePrice ||
                          listing.mlsListing?.listPrice
                      )}
                    </Td>
                    <Td>{listing.submittedOffersCount}</Td>
                    <Td>{listing.guestbookSignersCount}</Td>
                    <Td>
                      {listing.currentAndUpcomingOpenHouses?.[0]?.date || '-'}
                    </Td>
                  </Tr>
                );
              })}
              <div ref={listingsQueryRef} />
              {!listings?.length && (
                <Tr>
                  <Td borderBottom="none" colSpan={100}>
                    <Text fontSize="xl" fontWeight="medium" textAlign="center">
                      No listings found
                    </Text>
                  </Td>
                </Tr>
              )}
            </Tbody>
          </Table>
        </Flex>
      </Card>
    </Flex>
  );
};
