import { parsePhoneNumber } from 'awesome-phonenumber';

/**
 * Validate phone number in E.164 format
 */
const TEST_PHONE_NUMBERS = [
  '+10000000000',
  '+1(000)000-0000',
  '0000000000',
  '(000)000-0000',
];

export const validatePhoneNumber = (phoneNumber: string) => {
  if (
    TEST_PHONE_NUMBERS.includes(phoneNumber) &&
    process.env.NODE_ENV !== 'production'
  ) {
    return phoneNumber;
  }

  const parsed = parsePhoneNumber(phoneNumber, { regionCode: 'US' });

  if (!parsed.valid) {
    throw new Error(
      `${phoneNumber} is not a valid U.S E.164 formatted phone number`
    );
  }

  if (parsed.number) {
    return parsed.number.e164;
  }
};
