/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-explicit-any */
import type { AutofillSchema } from '~/services/document/components/FormulaEditor/autocomplete/types';

export function serializeFromAutofillSchema<T extends object>(
  autofillSchema: AutofillSchema,
  object: T
): T {
  const result: T = {} as T;

  if (!autofillSchema?.children) {
    return object;
  }

  for (const [key, value] of Object.entries(autofillSchema.children)) {
    if (object?.[key] !== undefined) {
      if (value.type === 'CHILDREN') {
        result[key] = serializeFromAutofillSchema(value, object[key]);
      } else {
        result[key] = object[key];
      }
    }
  }

  return result;
}
