import { useCallback, useEffect, useState } from 'react';

export const useLocalStorageState = <T>(
  storageKey?: string,
  initialValue: T = {} as T
) => {
  const [value, setValue] = useState<T>(() => {
    if (typeof window === 'undefined' || !storageKey) {
      return initialValue;
    }

    try {
      const item = window.localStorage.getItem(storageKey);

      return (item ? JSON.parse(item) : initialValue) as T;
    } catch (error) {
      return initialValue;
    }
  });

  const saveToLocalStorage = useCallback(() => {
    if (storageKey) {
      window.localStorage.setItem(storageKey, JSON.stringify(value));

      // enable other components listening to storage events on the same tab to catch this setItem event
      window.dispatchEvent(new Event('storage'));
    }
  }, [storageKey, value]);

  const updateLocalStorageState = useCallback(
    (state: Partial<T>, prependValues?: Partial<T>) => {
      setValue((prev) => ({
        ...prependValues,
        ...prev,
        ...state,
      }));
    },
    [setValue]
  );

  useEffect(() => {
    if (storageKey) {
      const localStorageValue = window.localStorage.getItem(storageKey);

      if (localStorageValue) {
        try {
          setValue(JSON.parse(localStorageValue) as T);
        } catch (e) {
          // eslint-disable-next-line no-console
          console.warn(
            'Error parsing local storage value: ',
            localStorageValue
          );
        }
      }
    }
  }, [storageKey]);

  return {
    localStorageState: value,
    updateLocalStorageState,
    saveLocalStorageState: saveToLocalStorage,
  };
};
