import {
  Card,
  Divider,
  Flex,
  Grid,
  GridItem,
  IconProps,
  Link,
  Text,
  VStack,
} from '@chakra-ui/react';
import { PublicListingFragment } from '@client/graphql/__generated__/types';
import { ListingDisclaimer } from '~/apps/consumer/pages/Storefront/ListingDisclaimer';
import { useStorefrontContext } from '~/apps/consumer/pages/Storefront/StorefrontContext';
import { getPerSqFtValue } from '~/apps/consumer/utils/listing.utils';
import {
  DollarIcon,
  FlatRulerIcon,
  HammerIcon,
  HomeIcon,
  MailIcon,
  MonitorCheckIcon,
  TelephoneIcon,
  TreeIcon,
} from '~/common/icons';
import { numberFormatter } from '~/common/utils/formatter';
import { UserAvatar } from '../../User/UserAvatar';
import { agentContactInfoFromListing } from '../ListingAgent/ListingAgentCard';

interface ListingDetailsCardProps {
  value: string | number | undefined;
  label?: string;
  IconComponent?: React.ComponentType<IconProps>;
}

const ListingDetailsCard = ({
  value,
  label,
  IconComponent,
}: ListingDetailsCardProps) => (
  <GridItem w="100%">
    <Card h="100px">
      <VStack
        alignItems="center"
        h="100%"
        justifyContent="center"
        spacing={2}
        textAlign="center"
      >
        {IconComponent && <IconComponent color="purple.500" />}
        <VStack spacing={0}>
          <Text fontSize="sm">{value || '-'}</Text>
          <Text color="whiteAlpha.600" fontSize="sm">
            {label}
          </Text>
        </VStack>
      </VStack>
    </Card>
  </GridItem>
);

const ListingAgentCard = ({ listing }: { listing: PublicListingFragment }) => {
  const iconSize = {
    height: '17px',
  };
  const { fullName, email, phoneNumber } = agentContactInfoFromListing(listing);

  return (
    <GridItem colSpan={[2, 2, 2, 3, 2]} w="100%">
      <Card
        alignItems="center"
        direction="row"
        gap={4}
        h="100px"
        justify="center"
        p={2}
      >
        <VStack spacing={0} textAlign="center">
          <Text fontSize="sm">{fullName}</Text>
          <Text color="whiteAlpha.600" fontSize="sm">
            {listing.mlsListing?.office?.name}
          </Text>

          <Flex
            color="whiteAlpha.700"
            direction="row"
            gap={2}
            justify="flex-start"
          >
            {email && (
              <Link href={`mailto:${email}`}>
                <MailIcon {...iconSize} />
              </Link>
            )}

            {phoneNumber && (
              <Link href={`tel:${phoneNumber}`}>
                <TelephoneIcon {...iconSize} />
              </Link>
            )}
          </Flex>
        </VStack>

        <UserAvatar user={{ fullName }} />
      </Card>
    </GridItem>
  );
};

interface DetailsFieldProps {
  label: string;
  value: string | number | undefined;
}

const DetailsField: React.FC<DetailsFieldProps> = ({ label, value }) => (
  <Flex alignItems="center" h={6} justifyContent="space-between">
    <Text color="whiteAlpha.600" fontSize="sm">
      {label}
    </Text>
    <Text fontSize="sm">{value || '-'}</Text>
  </Flex>
);

export const MLSListingDetails = () => {
  const { listing } = useStorefrontContext();
  const mlsListing = listing.mlsListing;

  return (
    <VStack align="left" spacing={3}>
      <Grid
        gap={3}
        templateColumns={[
          'repeat(2, 1fr)',
          'repeat(2, 1fr)',
          'repeat(2, 1fr)',
          'repeat(3, 1fr)',
          'repeat(8, 1fr)',
        ]}
      >
        <ListingAgentCard listing={listing} />
        <ListingDetailsCard
          IconComponent={MonitorCheckIcon}
          label={`${
            mlsListing?.mls?.daysOnMarket
              ? `${mlsListing.mls.daysOnMarket} DOM`
              : ''
          }`}
          value={mlsListing?.mls?.statusText}
        />
        <ListingDetailsCard
          IconComponent={HammerIcon}
          label="Year built"
          value={mlsListing?.property?.yearBuilt}
        />
        <ListingDetailsCard
          IconComponent={TreeIcon}
          label={`${mlsListing?.property?.lotSizeAreaUnits || 'Acres'} lot`}
          value={mlsListing?.property?.lotSizeArea}
        />
        <ListingDetailsCard
          IconComponent={HomeIcon}
          value={mlsListing?.property?.subTypeText}
        />
        <ListingDetailsCard
          IconComponent={FlatRulerIcon}
          label="Per sqft"
          value={numberFormatter(getPerSqFtValue(mlsListing), {
            type: 'currency',
            maxPrecision: 0,
          })}
        />
        <ListingDetailsCard
          IconComponent={DollarIcon}
          label={
            mlsListing?.association?.frequency
              ? `HOA ${mlsListing?.association?.frequency}`
              : 'HOA'
          }
          value={numberFormatter(mlsListing?.association?.fee, {
            type: 'currency',
            maxPrecision: 0,
          })}
        />
      </Grid>
      <Card p={6}>{mlsListing?.remarks}</Card>
      <Card p={6}>
        <DetailsField label="County" value={listing.property?.county} />
        <DetailsField
          label="Subdivision"
          value={mlsListing?.property?.subdivision}
        />
        <DetailsField label="MLS #" value={listing.mlsListing?.listingId} />
      </Card>
      <Divider my={8} />
      <ListingDisclaimer listing={listing} />
    </VStack>
  );
};
