import { Image, VStack } from '@chakra-ui/react';
import {
  TourModalPageSubtitle,
  TourModalPageTitle,
} from '../../../Tour/styled';
import DashboardOnboarding2 from './assets/dashboard-onboarding-2.png';

export const Page2 = () => {
  const title = 'Personalized Guestbook';
  const subtitle =
    'Unlock real-time analytics to reverse prospect and attract new leads';

  return (
    <VStack spacing={4}>
      <TourModalPageTitle fontSize="40px" fontWeight="medium">
        {title}
      </TourModalPageTitle>
      <TourModalPageSubtitle fontSize="xl" maxWidth="385px" mb={4}>
        {subtitle}
      </TourModalPageSubtitle>
      <Image maxWidth="385px" src={DashboardOnboarding2} width="100%" />
    </VStack>
  );
};
