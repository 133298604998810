import { ListingForOrganizationFragment } from '@client/graphql/__generated__/types';
import { useMemo } from 'react';
import { Marker, MarkerProps } from 'react-map-gl';
import { getListingPrice } from '~/apps/consumer/utils/listing.utils';
import { DEFAULT_STATUS_ITEMS } from '../../Map/constants';
import { ListingDetailMapMarker } from '../../Map/MapMarkers/ListingDetailMapMarker';
import { PriceMarker } from '../../Map/MapMarkers/PriceMarker';

interface IExploreMapMarkerProps {
  lat: number;
  lng: number;
  zoom: number;
  onClick: MarkerProps['onClick'];
  listing: ListingForOrganizationFragment;
}

export const OrganizationMapMarker = ({
  lat,
  lng,
  zoom,
  onClick,
  listing,
}: IExploreMapMarkerProps) => {
  const marker = useMemo(() => {
    // zoomed out show less detail
    const statusItem = DEFAULT_STATUS_ITEMS.find(
      (item) =>
        !!listing.mlsListing?.mls?.status &&
        item.status.includes(listing.mlsListing?.mls?.status)
    );
    const price = getListingPrice(listing.mlsListing);

    if (zoom < 11) {
      return (
        <PriceMarker
          bgOverride={statusItem?.bgColor}
          colorOverride={statusItem?.primaryColor}
          isPremium={true}
          price={price}
          showOffersCount={true}
          submittedOffersCount={listing.submittedOffersCount}
        />
      );
    }

    return (
      <ListingDetailMapMarker
        address={listing.mlsListing?.address?.full || ''}
        bg={statusItem?.bgColor}
        price={price}
        primaryColor={statusItem?.primaryColor}
        secondaryColor={statusItem?.secondaryColor}
        status={listing.mlsListing?.mls?.statusText || ''}
        submittedOffersCount={listing.submittedOffersCount}
        tagColorScheme={statusItem?.tagColorScheme}
      />
    );
  }, [zoom, listing]);

  return (
    <Marker
      anchor="bottom"
      latitude={lat}
      longitude={lng}
      offset={[0, 20]}
      style={{ cursor: 'pointer' }}
      onClick={onClick}
    >
      {marker}
    </Marker>
  );
};
