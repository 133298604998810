import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Flex,
  Text,
} from '@chakra-ui/react';
import { CompsSummary } from '../../../CompsSummary';
import { DraggableEditCompsMapSidebarListingCards } from './ListingCard/DraggableEditCompsMapListingCards';

export const EditCompsMapSidebar = ({
  onAdd,
  containerRef,
}: {
  onAdd: () => void;
  containerRef: React.RefObject<HTMLDivElement>;
}) => {
  return (
    <Card
      background="black"
      height="calc(100% - 40px)"
      position="absolute"
      right="10px"
      top="10px"
      width="280px"
    >
      <CardHeader>
        <Flex alignItems="center" justifyContent="space-between">
          <Text color="whiteAlpha.600">Your Comps</Text>
          <Button size="xs" onClick={onAdd}>
            Add
          </Button>
        </Flex>
      </CardHeader>
      <CardBody minHeight="0" px={0}>
        <Flex direction="column" gap={2} height="100%">
          <Flex px={2}>
            <CompsSummary shortLabel />
          </Flex>
          <DraggableEditCompsMapSidebarListingCards
            containerRef={containerRef}
          />
        </Flex>
      </CardBody>
    </Card>
  );
};
