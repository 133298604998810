import {
  DocumentSectionListForOfferFlowQuery,
  DocumentSectionModuleType,
  OffersForListingFragment,
} from '@client/graphql/__generated__/types';
import isNil from 'lodash/isNil';

export const getCompareValue = (
  mappingKey: string,
  offers: OffersForListingFragment[],
  shouldGetLowestValue = false
): number | undefined => {
  return offers
    .map((offer) => offer.contract?.fieldValues?.[mappingKey])
    .toSorted((a?: number, b?: number) => {
      if (isNil(a) && isNil(b)) {
        return 0;
      }
      if (isNil(a)) {
        return 1;
      }
      if (isNil(b)) {
        return -1;
      }

      return shouldGetLowestValue ? a - b : b - a;
    })[0] as number | undefined;
};

export const getSectionFields = (
  comparisonDocumentSectionData:
    | DocumentSectionListForOfferFlowQuery
    | undefined,
  sectionType: DocumentSectionModuleType
) => {
  return (
    comparisonDocumentSectionData?.publicDocumentSectionList?.results
      ?.find((section) => section.documentSectionModuleType === sectionType)
      ?.documentSectionFields?.toSorted((a, b) => a?.order - b?.order)
      ?.map((field) => field.field) || []
  );
};
