import { Card, CardBody, Flex, Link, Spacer, Text } from '@chakra-ui/react';
import isNil from 'lodash/isNil';
import { PropsWithChildren, ReactNode } from 'react';

interface StorefrontCardProps {
  children: ReactNode;
  title?: string;
  linkText?: string;
  onLinkClick?: React.MouseEventHandler<HTMLAnchorElement>;
}

export const StorefrontCard = ({
  children,
  title,
  linkText,
  onLinkClick,
}: PropsWithChildren<StorefrontCardProps>) => {
  return (
    <Card flex="1" minHeight="240px">
      {title && (
        <Flex alignItems="center" px={6} py={5}>
          <Text
            fontSize="sm"
            fontWeight="500"
            letterSpacing="1px"
            lineHeight="16px"
            textTransform="uppercase"
          >
            {title}
          </Text>
          <Spacer />
          <Link fontWeight="500" onClick={onLinkClick}>
            {linkText}
          </Link>
        </Flex>
      )}
      <CardBody mb={4} p={0}>
        {children}
      </CardBody>
    </Card>
  );
};

interface CardDetailsFieldProps {
  label: string;
  value: ReactNode;
}

export const CardDetailsField: React.FC<CardDetailsFieldProps> = ({
  label,
  value,
}) => {
  const isText =
    typeof value === 'string' || typeof value === 'number' || isNil(value);

  return (
    <Flex alignItems="center" h={6} justifyContent="space-between">
      <Text color="whiteAlpha.600">{label}</Text>
      {isText ? (
        <Text textAlign={{ base: 'right' }}>{value || '-'}</Text>
      ) : (
        value
      )}
    </Flex>
  );
};
