import { isEqual } from 'lodash';
import { theme } from '~/common/theme';
import { FormField } from '../../FormFields/types';

const COLORS = {
  NEW: theme.colors.emerald[300],
  MODIFIED: theme.colors.orange[300],
  DEFAULT: theme.colors.primary[300],
  LABEL_FIELD_DEFAULT: theme.colors.yellow[300],
  UNKNOWN: theme.colors.red[300],
};

export const useFormFieldStatusColor = (
  formField?: FormField,
  originalFormField?: FormField,
  isLabelField = false,
  showUnknownColor = false
) => {
  if (formField) {
    if (formField?.isNew) {
      return COLORS.NEW;
    }

    if (showUnknownColor && formField.mappingKey.includes('UNKNOWN')) {
      return COLORS.UNKNOWN;
    }

    if (formField?.id && !isEqual(formField, originalFormField)) {
      return COLORS.MODIFIED;
    }
  }

  if (isLabelField) return COLORS.LABEL_FIELD_DEFAULT;

  return COLORS.DEFAULT;
};
