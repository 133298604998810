import { FormControl, FormLabel, Input } from '@chakra-ui/react';
import { useOfferFlowContext } from '~/apps/consumer/pages/Storefront/OfferFlow/OfferFlowContext';

export const BUYER_INFO_INPUTS = [
  {
    key: 'buyerName',
    type: 'text',
    label: 'Primary Buyer',
    isRequired: true,
  },
  {
    key: 'buyerEmail',
    type: 'email',
    label: 'Primary Buyer Email',
    isRequired: true,
  },
  {
    key: 'entityName',
    type: 'text',
    label: 'Entity Buyer Name',
  },
  {
    key: 'entityTitle',
    type: 'text',
    label: 'Entity Buyer Title',
  },
  {
    key: 'coBuyerName',
    type: 'text',
    label: 'Co-Buyer',
  },
  {
    key: 'coBuyerEmail',
    type: 'email',
    label: 'Co-Buyer Email',
  },
];

interface BuyerInfoFormProps {
  requireEmail?: boolean;
}

export const BuyerInfoForm = ({ requireEmail = true }: BuyerInfoFormProps) => {
  const { buyerInfo, updateBuyerInfo } = useOfferFlowContext();

  const isEntityBuyer = !!buyerInfo?.entityName;
  const processedBuyerInfoInputs = BUYER_INFO_INPUTS.map((input) => ({
    ...input,
    isRequired: input.isRequired && (input.type !== 'email' || requireEmail),
  })).filter((input) => isEntityBuyer || !input.key.includes('entity'));

  return (
    <>
      {processedBuyerInfoInputs.map((input) => (
        <FormControl
          key={input.key}
          id={input.key}
          isRequired={input.isRequired}
        >
          <FormLabel variant="normal">{input.label}</FormLabel>
          <Input
            size="sm"
            value={buyerInfo?.[input.key as keyof typeof buyerInfo] || ''}
            onChange={(e) => updateBuyerInfo({ [input.key]: e.target.value })}
          />
        </FormControl>
      ))}
    </>
  );
};
