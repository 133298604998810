import { useToast } from '@chakra-ui/react';
import { useListingforMlsListingLazyQuery } from '@client/graphql/__generated__/main-operations';
import {
  MlsListingObject,
  PartyFragment,
} from '@client/graphql/__generated__/types';
import { useState } from 'react';
import { useNavigate } from 'react-router';

export const useFindPropertyEvents = (party?: PartyFragment) => {
  const [isFindPropertyModalOpen, setIsFindPropertyModalOpen] =
    useState<boolean>(false);
  const [listingForMlsListing] = useListingforMlsListingLazyQuery();
  const navigate = useNavigate();
  const toast = useToast();

  const onSelect = async (mlsListingObject: MlsListingObject) => {
    try {
      const { data } = await listingForMlsListing({
        variables: {
          input: {
            id: mlsListingObject.id,
          },
        },
      });

      if (data?.syncListingFromMLS?.property?.slug) {
        navigate({
          pathname: `/storefront/${data.syncListingFromMLS.property.slug}`,
          search: party?.id ? `?party=${party.id}` : '',
        });
        setIsFindPropertyModalOpen(false);
      } else {
        toast({
          title: 'Could not find property',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: 'Could not find property',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return { isFindPropertyModalOpen, setIsFindPropertyModalOpen, onSelect };
};
