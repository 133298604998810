import { Flex } from '@chakra-ui/react';
import { OffersForListingFragment } from '@client/graphql/__generated__/types';
import isNil from 'lodash/isNil';
import { forwardRef } from 'react';
import { FieldWithPage } from '~/services/document/components/DocumentSections/types';
import { OfferCompareBuyersAgentField } from './OfferCompareBuyersAgentField';
import { OfferCompareField } from './OfferCompareField';
import { useCompareOfferBaseValues } from './hooks/useCompareOfferBaseValues';

export const OFFER_COMPARE_WIDTH = '200px';

export interface OfferCompareColumnProps {
  onClick: () => void;
  offer: OffersForListingFragment;
  compareValueMap: Record<string, number | undefined>;
  offerComparisonFields: FieldWithPage[];
  baseCompareValues: ReturnType<typeof useCompareOfferBaseValues>;
  borderRadius?: string;
  buyersAgentName?: string;
  officeName?: string;
}

export const OfferCompareColumn = forwardRef<
  HTMLDivElement,
  OfferCompareColumnProps
>(
  (
    {
      offer,
      onClick,
      compareValueMap,
      offerComparisonFields,
      baseCompareValues,
      borderRadius,
      buyersAgentName,
      officeName,
    },
    ref
  ) => {
    const { documentVersionAutofillValues } = baseCompareValues;

    return (
      <Flex
        ref={ref}
        _hover={{
          cursor: 'pointer',
        }}
        backgroundColor="card.bg"
        border="1px solid"
        borderColor="card.border"
        borderRadius={borderRadius}
        direction="column"
        flexBasis={OFFER_COMPARE_WIDTH}
        flexShrink={0}
        left={0}
        position="relative"
        sx={{
          touchAction: 'manipulate',
        }}
        zIndex={0}
        onClick={onClick}
      >
        <OfferCompareBuyersAgentField
          buyersAgentName={buyersAgentName}
          offer={offer}
          officeName={officeName}
        />
        {offerComparisonFields.map((field) => {
          const value =
            offer?.contract?.fieldValues?.[field.mappingKey] ||
            documentVersionAutofillValues?.readOnlyAutofillValues?.[
              field.mappingKey
            ];

          return (
            <OfferCompareField
              key={field.label}
              compareValue={
                documentVersionAutofillValues?.autofillValues?.[
                  field.mappingKey
                ] as number | undefined
              }
              offerComparisonField={field}
              shouldHighlight={
                !isNil(compareValueMap[field.id]) &&
                compareValueMap[field.id] === value
              }
              value={value}
            />
          );
        })}
      </Flex>
    );
  }
);
