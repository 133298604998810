import { Table, TableContainer, Tbody, Th, Thead, Tr } from '@chakra-ui/react';
import { FC } from 'react';
import { AccessControlListRow } from './AccessControlListRow';
import { AccessControlWithIsPending } from './types';

interface AccessControlListProps {
  accessControls: AccessControlWithIsPending[];
  currentUserId: string;
  isAdmin: boolean;
  onDelete: (accessControl: AccessControlWithIsPending) => Promise<void>;
}

export const AccessControlList: FC<AccessControlListProps> = ({
  accessControls,
  currentUserId,
  onDelete,
  isAdmin,
}) => {
  return (
    <TableContainer>
      <Table size="lg">
        <Thead>
          <Tr>
            <Th>Name</Th>
            <Th>Phone Number</Th>
            <Th>Email</Th>
            <Th>Actions</Th>
          </Tr>
        </Thead>

        <Tbody>
          {accessControls.map((accessControl) => (
            <AccessControlListRow
              key={accessControl.id}
              accessControl={accessControl}
              currentUserId={currentUserId}
              isAdmin={isAdmin}
              onDelete={onDelete}
            />
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};
