import { CheckIcon } from '@chakra-ui/icons';
import { Button, forwardRef } from '@chakra-ui/react';
import { Bell } from 'lucide-react';
import { useAppContext } from '~/services/main/contexts/AppContext';
import { tourClassNameStep2 } from '../../components/Storefront/StorefrontTourModal/constants';

export interface GetAlertsButtonProps {
  showUserVerifyModal?: () => void;
  isFollowingListing?: boolean;
  handleClickUnsubscribe?: () => Promise<void>;
  handleClickSubscribe?: () => Promise<void>;
  isPreview?: boolean;
}

export const GetAlertsButton = forwardRef(
  (
    {
      showUserVerifyModal,
      isFollowingListing,
      handleClickUnsubscribe,
      handleClickSubscribe,
      isPreview,
    }: GetAlertsButtonProps,
    ref
  ) => {
    const { isAuthenticated } = useAppContext();

    return (
      <>
        {!isAuthenticated ? (
          <Button
            ref={ref}
            aria-label="Get alerts"
            borderRadius={4}
            className={tourClassNameStep2}
            fontWeight="semibold"
            height={8}
            isDisabled={isPreview}
            leftIcon={<Bell size={14} />}
            size="sm"
            w="100%"
            onClick={showUserVerifyModal}
          >
            Get alerts
          </Button>
        ) : null}
        {isAuthenticated && !isFollowingListing ? (
          <Button
            ref={ref}
            aria-label="Get alerts"
            borderRadius={4}
            className={tourClassNameStep2}
            fontWeight="semibold"
            height={8}
            isDisabled={isPreview}
            leftIcon={<Bell size={14} />}
            size="sm"
            w="100%"
            onClick={() => handleClickSubscribe && void handleClickSubscribe()}
          >
            Get alerts
          </Button>
        ) : null}
        {isAuthenticated && isFollowingListing ? (
          <Button
            ref={ref}
            aria-label="Get alerts"
            borderRadius={4}
            className={tourClassNameStep2}
            fontWeight="semibold"
            height={8}
            isDisabled={isPreview}
            leftIcon={<CheckIcon h={3} w={3} />}
            size="sm"
            variant="outline"
            onClick={() =>
              handleClickUnsubscribe && void handleClickUnsubscribe()
            }
          >
            Subscribed
          </Button>
        ) : null}
      </>
    );
  }
);
