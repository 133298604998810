import { theme } from '~/common/theme';

export const baseInputStyle = {
  bg: `${theme.colors.primary[300]}50`,
  display: 'block',
  height: '100%',
  size: 'xs',
  width: '100%',
  opacity: '1 !important',
  fontWeight: 'bold',
  px: 1,
  border: 'none !important',
  borderRadius: 2,
  lineHeight: 1,
  _hover: {
    borderColor: 'transparent',
  },
  _focus: {
    bgColor: '#ffffff',
    outlineColor: 'primary.400',
    outlineOffset: 0,
  },
  color: 'gray.900',
};
