import { Flex, Spinner } from '@chakra-ui/react';

export const LoadingCenter = () => {
  return (
    <Flex
      alignItems="center"
      height="100%"
      justifyContent="center"
      width="100%"
    >
      <Spinner />
    </Flex>
  );
};
