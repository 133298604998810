import {
  Box,
  Button,
  Flex,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  SlideFade,
} from '@chakra-ui/react';
import { ArrowRight } from 'lucide-react';
import { memo, useEffect, useState } from 'react';

import { TourType } from '@client/graphql/__generated__/types';
import { useViewedTours } from '~/apps/consumer/hooks/useViewedTours';
import { PageDotIndicator } from '~/common/components/PageDotIndicator/PageDotIndicator';
import { IntroPage } from './Pages/IntroPage';
import { Page1 } from './Pages/Page1';
import { Page2 } from './Pages/Page2';
import { Page3 } from './Pages/Page3';

export const DashboardTourModal = memo(function DashboardTourModal({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) {
  const { updateHasViewedTour } = useViewedTours();

  const handleClose = () => {
    onClose();
    void updateHasViewedTour([TourType.DASHBOARD]);
  };

  useEffect(() => {
    if (isOpen) {
      setCurrentStepIndex(0);
    }
  }, [isOpen]);

  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const pages = [IntroPage, Page1, Page2, Page3];

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay backdropFilter="blur(2px)" />
      <ModalContent maxWidth="590px" minHeight="550px">
        <ModalCloseButton />
        <ModalBody alignItems="center" display="flex" px={12} py={12}>
          <Flex
            alignItems="center"
            direction="column"
            flexGrow={1}
            gap={4}
            justifyContent="space-between"
          >
            <SlideFade key={currentStepIndex} in>
              {pages.map((Page, index) => (
                <Box key={index} hidden={currentStepIndex !== index}>
                  <Page />
                </Box>
              ))}
            </SlideFade>
            <Flex alignItems="center" direction="column" gap={6}>
              {currentStepIndex === pages.length - 1 ? (
                <Button
                  _hover={{ background: 'primary.500' }}
                  background="blueGradient"
                  borderRadius="32px"
                  color="black"
                  transition="none"
                  onClick={() => {
                    handleClose();
                  }}
                >
                  Get Started
                </Button>
              ) : (
                <IconButton
                  isRound
                  _hover={{ background: 'primary.500' }}
                  aria-label="Next"
                  background="blueGradient"
                  height={12}
                  icon={<ArrowRight />}
                  mb={currentStepIndex === 0 ? 6 : 0}
                  transition="none"
                  width={12}
                  onClick={() => setCurrentStepIndex((prev) => prev + 1)}
                />
              )}
              {currentStepIndex !== 0 && (
                <PageDotIndicator
                  currentStepIndex={currentStepIndex - 1}
                  totalSteps={pages.length - 1}
                  onClick={(stepIndex: number) =>
                    setCurrentStepIndex(stepIndex + 1)
                  }
                />
              )}
            </Flex>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
});
