import {
  Alert,
  Button,
  Divider,
  Flex,
  Heading,
  VStack,
} from '@chakra-ui/react';
import {
  useCreateEmbeddedSignUrlMutation,
  useEmbeddedSignatureRequestQuery,
} from '@client/graphql/__generated__/document-operations';
import { FC, useState } from 'react';
import { useParams } from 'react-router';
import { CenterSpinner } from '~/common/components/CenterSpinner';
import { IndigoLogo } from '~/common/icons/IndigoLogo';
import { DropboxSignEmbedded } from '../components/DropboxSignEmbedded';
import { BuyerAgencyAgreementDocsReview } from '../components/Esign/BuyerAgencyAgreementDocsReview';
import { Container } from '../components/Layouts/Container';

export const EsignPage: FC = () => {
  const { code } = useParams();
  const [signed, setSigned] = useState(false);
  const [embeddedSignUrl, setEmbeddedSignUrl] = useState<string>();
  const { data, loading } = useEmbeddedSignatureRequestQuery({
    variables: {
      code: code as string,
    },
    skip: !code,
  });
  const [createEmbeddedSignUrl] = useCreateEmbeddedSignUrlMutation();
  const hasSigned =
    signed || !!data?.embeddedSignatureRequest?.signer?.signedAt;
  const isExpired = data?.embeddedSignatureRequest?.isExpired;

  if (!data?.embeddedSignatureRequest && loading)
    return (
      <Container>
        <CenterSpinner />
      </Container>
    );

  const { primaryDocument, signer } = data?.embeddedSignatureRequest || {};

  return (
    <Container display="flex" flexDirection="column" maxWidth="container.md">
      <VStack flexGrow={1} height="inherit">
        <Flex alignItems="center">
          <IndigoLogo />
        </Flex>
        <VStack flexGrow={1} justifyContent="center" spacing={4}>
          <Heading as="h2" fontFamily="serif">
            {signer?.fullName}
          </Heading>
          <Heading
            as="h3"
            color="whiteAlpha.500"
            fontFamily="serif"
            textAlign="center"
          >
            {primaryDocument?.name}
          </Heading>

          {!hasSigned && (
            <Button
              isDisabled={isExpired}
              onClick={() =>
                void createEmbeddedSignUrl({
                  variables: { code: code as string },
                }).then(({ data }) =>
                  setEmbeddedSignUrl(data?.createEmbeddedSignUrl)
                )
              }
            >
              {isExpired ? 'Expired, no longer available' : 'Get started'}
            </Button>
          )}

          {hasSigned && (
            <Alert
              bgColor="green.800"
              borderRadius="sm"
              justifyContent="center"
              status="success"
              textAlign="center"
            >
              Document successfully signed. A copy of the signed document has
              been sent to your email address
            </Alert>
          )}

          <Divider />

          {data?.embeddedSignatureRequest && (
            <BuyerAgencyAgreementDocsReview
              embeddedSignatureRequest={data?.embeddedSignatureRequest}
            />
          )}
        </VStack>
      </VStack>

      {embeddedSignUrl && (
        <DropboxSignEmbedded
          embeddedUrl={embeddedSignUrl}
          onClose={() => {
            setEmbeddedSignUrl(undefined);
          }}
          onSign={() => {
            setSigned(true);
          }}
        />
      )}
    </Container>
  );
};
