import { FieldType } from '@client/graphql/__generated__/types';
import { isEqual } from 'lodash';
import React, {
  FC,
  MutableRefObject,
  ReactNode,
  createContext,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import Selecto from 'react-selecto';
import { useDndKitDefaults } from '~/common/hooks/useDndKitDefaults';
import { FormField, ValuesType } from '../FormFields/types';
import {
  DocumentsThumbnails,
  PageMetadata,
  VisiblePage,
} from '../PDFScrollable/types';
import { useKeyboardShortcuts } from './hooks/useKeyboardShortcuts';
import { MoveableTarget } from './types';

export type DocumentMapperOnSave = (options: {
  changedFormFields: FormField[];
  newFormFields: FormField[];
}) => void;

export interface PageMousePosition {
  x: number;
  y: number;
  pageNumber: number;
  width: number;
  height: number;
}

export interface DocumentMapperProviderProps {
  children?: ReactNode;
  formFieldsById: Record<string, FormField>;
  originalFormFieldsById: Record<string, FormField>;
  editFormFieldsById?: Record<string, FormField>;
  values: ValuesType;
  isSaveLoading: boolean;
  onSaveButtonText?: string;
  updateValues: (values: ValuesType) => void;
  onFormFieldChange: (
    formField: Partial<FormField> | Partial<FormField>[]
  ) => void;
  onFormFieldDelete: (formFields: FormField[]) => void;
  onSave?: DocumentMapperOnSave;
  onCancel?: () => void;
  onPagesMetadataChange?: (pagesMetadata: PageMetadata[]) => void;
}

export interface DocumentMapperContextValue
  extends Omit<DocumentMapperProviderProps, 'children'> {
  leftPaneChildren?: ReactNode;
  rightPaneChildren?: ReactNode;
  visiblePage?: VisiblePage;
  formFields: FormField[];
  computedFormFields: FormField[];
  hiddenFormFields: FormField[];
  selectedFormField?: FormField;
  moveableTargets: MoveableTarget[];
  isChanged: boolean;
  isPreview: boolean;
  isLabelFieldMappable: boolean;
  selectoRefs?: MutableRefObject<Record<number, Selecto>>;
  pageMousePositionRef: MutableRefObject<PageMousePosition>;
  parentElem?: HTMLDivElement | null;
  parentHeight: number;
  documentsThumbnails?: DocumentsThumbnails;
  documentsThumbnailsRef?: MutableRefObject<DocumentsThumbnails | undefined>;
  moveableTargetsPage?: { pageNumber: number; documentIndex: number };
  zoom: number;
  pagesMetadata: PageMetadata[];
  dndKitDefaults: ReturnType<
    typeof useDndKitDefaults<{ fieldType: FieldType; label?: string }>
  >;
  setDocumentsThumbnails?: React.Dispatch<
    React.SetStateAction<DocumentsThumbnails>
  >;
  setMoveableTargetsPage: React.Dispatch<
    React.SetStateAction<
      | {
          pageNumber: number;
          documentIndex: number;
        }
      | undefined
    >
  >;
  setIsPreview: React.Dispatch<React.SetStateAction<boolean>>;
  setIsLabelFieldMappable: React.Dispatch<React.SetStateAction<boolean>>;
  setVisiblePage: React.Dispatch<React.SetStateAction<VisiblePage | undefined>>;
  setSelectedFormFieldId: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  setMoveableTargets: React.Dispatch<
    React.SetStateAction<MoveableTarget[] | undefined>
  >;
  setLeftPaneChildren: React.Dispatch<ReactNode>;
  setRightPaneChildren: React.Dispatch<ReactNode>;
  setParentElem: React.Dispatch<HTMLDivElement | null | undefined>;
  setParentHeight: React.Dispatch<number>;
  setZoom: React.Dispatch<number>;
  setPagesMetadata: React.Dispatch<React.SetStateAction<PageMetadata[]>>;
  onSave?: () => void;
}

export const DocumentMapperContext = createContext<DocumentMapperContextValue>(
  {} as DocumentMapperContextValue
);

export const DocumentMapperProvider: FC<DocumentMapperProviderProps> = memo(
  function DocumentMapperProvider({
    values,
    updateValues,
    children,
    formFieldsById,
    originalFormFieldsById,
    editFormFieldsById,
    onSaveButtonText,
    onFormFieldChange,
    onFormFieldDelete,
    isSaveLoading,
    onSave,
    onCancel,
    onPagesMetadataChange,
  }) {
    const [pagesMetadata, setPagesMetadata] = useState([]);
    const [zoom, setZoom] = useState(1);
    const [parentElem, setParentElem] = useState<HTMLDivElement | null>();
    const [parentHeight, setParentHeight] = useState<number>();
    const pageMousePositionRef = useRef<PageMousePosition>({
      x: 0,
      y: 0,
      pageNumber: 0,
      width: 0,
      height: 0,
    });
    const documentsThumbnailsRef = useRef<DocumentsThumbnails>({});
    const selectoRefs = useRef<Record<number, Selecto>>({});
    const history = useRef<(FormField | FormField[])[]>([]);
    const [moveableTargetsPage, setMoveableTargetsPage] = useState<{
      pageNumber: number;
      documentIndex: number;
    }>();
    const [isPreview, setIsPreview] = useState<boolean>(false);
    const [isLabelFieldMappable, setIsLabelFieldMappable] =
      useState<boolean>(false);
    const [visiblePage, setVisiblePage] = useState<VisiblePage>();
    const [moveableTargets, setMoveableTargets] = useState<MoveableTarget[]>(
      []
    );
    const [selectedFormFieldId, setSelectedFormFieldId] = useState<string>();
    const [documentsThumbnails, setDocumentsThumbnails] =
      useState<DocumentsThumbnails>();
    const [leftPaneChildren, setLeftPaneChildren] = useState<ReactNode>();
    const [rightPaneChildren, setRightPaneChildren] = useState<ReactNode>();

    const dndKitDefaults = useDndKitDefaults<{ fieldType: FieldType }>({});

    const formFields = useMemo(() => {
      return Object.values(formFieldsById).filter(
        ({ field }) => !field?.isComputed && !field?.isHidden
      );
    }, [formFieldsById]);

    const computedFormFields = useMemo(() => {
      return Object.values(formFieldsById).filter(
        ({ field, isComputed }) => isComputed || field?.isComputed
      );
    }, [formFieldsById]);

    const hiddenFormFields = useMemo(() => {
      return Object.values(formFieldsById).filter(
        ({ field, isHidden }) => isHidden || field?.isHidden
      );
    }, [formFieldsById]);

    const selectedFormField = useMemo(() => {
      if (selectedFormFieldId) {
        return formFieldsById[selectedFormFieldId];
      }
    }, [selectedFormFieldId, formFieldsById]);

    const onSaveCallback = useCallback(() => {
      const allFields = formFields
        .concat(computedFormFields)
        .concat(hiddenFormFields);

      const changedFormFields = allFields.filter((formField) => {
        if (!formField.isNew && formField.id) {
          return !isEqual(formField, originalFormFieldsById[formField.id]);
        }
      });

      const newFormFields = allFields.filter((formField) => formField.isNew);

      onSave && onSave({ changedFormFields, newFormFields });
    }, [
      formFields,
      computedFormFields,
      hiddenFormFields,
      onSave,
      originalFormFieldsById,
    ]);

    const onFormFieldChangeWithHistory = useCallback(
      (formField: FormField | FormField[]) => {
        if (Array.isArray(formField)) {
          history.current.push(
            formField.map((formField) => {
              return formFieldsById[formField.id];
            })
          );
        } else {
          const currentFormField = formFieldsById[formField.id];
          history.current.push(currentFormField);
        }

        onFormFieldChange(formField);
      },
      [onFormFieldChange, formFieldsById]
    );

    useKeyboardShortcuts({
      onSave: onSaveCallback,
      history,
      visiblePage,
      moveableTargets,
      pageMousePositionRef,
      formFieldsById,
      onFormFieldChange,
      onFormFieldDelete,
      setMoveableTargets,
      setSelectedFormFieldId,
    });

    useEffect(() => {
      const resize = () => {
        setParentHeight(parentElem?.offsetHeight);
      };

      setParentHeight(parentElem?.offsetHeight);

      window.addEventListener('resize', resize);

      return () => {
        window.removeEventListener('resize', resize);
      };
    }, [parentElem]);

    useEffect(() => {
      if (onPagesMetadataChange && pagesMetadata?.length) {
        window.requestAnimationFrame(() => {
          onPagesMetadataChange(pagesMetadata);
        });
      }
    }, [onPagesMetadataChange, pagesMetadata]);

    return (
      <DocumentMapperContext.Provider
        value={{
          onSaveButtonText,
          leftPaneChildren,
          rightPaneChildren,
          visiblePage,
          formFields,
          formFieldsById,
          editFormFieldsById,
          computedFormFields,
          hiddenFormFields,
          values,
          originalFormFieldsById,
          selectedFormField,
          moveableTargets,
          moveableTargetsPage,
          selectoRefs,
          documentsThumbnailsRef,
          documentsThumbnails,
          isSaveLoading,
          isPreview,
          isChanged: !isEqual(formFieldsById, originalFormFieldsById),
          isLabelFieldMappable,
          parentElem,
          parentHeight: parentHeight || 100,
          pageMousePositionRef,
          zoom,
          pagesMetadata,
          dndKitDefaults,
          // methods
          updateValues,
          setParentElem,
          setParentHeight,
          setDocumentsThumbnails,
          setIsPreview,
          setIsLabelFieldMappable,
          setVisiblePage,
          setLeftPaneChildren,
          setRightPaneChildren,
          setSelectedFormFieldId,
          setMoveableTargets,
          setMoveableTargetsPage,
          onFormFieldChange: onFormFieldChangeWithHistory,
          onFormFieldDelete,
          onCancel,
          onSave: onSave ? onSaveCallback : undefined,
          setZoom,
          setPagesMetadata,
        }}
      >
        {children}
      </DocumentMapperContext.Provider>
    );
  }
);

export const useDocumentMapperContext = () => {
  return useContext(DocumentMapperContext);
};
