import {
  Button,
  Card,
  Flex,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  Spacer,
  Text,
} from '@chakra-ui/react';
import { PartyFragment } from '@client/graphql/__generated__/types';
import { PlusIcon, SearchIcon } from 'lucide-react';
import { useState } from 'react';
import { filterParties } from '~/apps/consumer/utils/party.utils';
import { useAppContext } from '~/services/main/contexts/AppContext';
import { UserAvatar } from '../../User/UserAvatar';

interface PartySelectorProps {
  onSelectParty: (party?: PartyFragment) => void;
  selectedParty?: PartyFragment;
  showAddBuyerForm: boolean;
  setShowAddBuyerForm: (show: boolean) => void;
  parties?: PartyFragment[];
  onUnauthenticatedClick?: () => void;
  disableChange?: boolean;
}

export const PartySelector = ({
  onSelectParty,
  selectedParty,
  showAddBuyerForm,
  setShowAddBuyerForm,
  parties = [],
  onUnauthenticatedClick,
  disableChange = false,
}: PartySelectorProps) => {
  const [search, setSearch] = useState('');
  const { isAuthenticated } = useAppContext();

  const filteredParties = filterParties(search, parties)?.slice(0, 5);

  const selectedPartyCard = (
    <Card>
      <Flex justifyContent="space-between">
        <PartyRow party={selectedParty} />
        {!disableChange && (
          <Button
            cursor="pointer"
            mr={3}
            variant="link"
            onClick={() => onSelectParty(undefined)}
          >
            Change
          </Button>
        )}
      </Flex>
    </Card>
  );

  const addBuyerCard = (
    <Card>
      <Flex justifyContent="space-between" width="100%">
        <Flex alignItems="center" gap={2} p={3}>
          <PlusIcon />
          <Text>Add new buyer</Text>
        </Flex>
        <Button
          cursor="pointer"
          mr={3}
          variant="link"
          onClick={() => setShowAddBuyerForm(false)}
        >
          Cancel
        </Button>
      </Flex>
    </Card>
  );

  return selectedParty ? (
    selectedPartyCard
  ) : showAddBuyerForm ? (
    addBuyerCard
  ) : (
    <Flex direction="column">
      <InputGroup borderBottomRadius="none" borderColor="border.divider">
        <InputLeftElement h="full">
          <SearchIcon />
        </InputLeftElement>
        <Input
          borderBottomRadius="none"
          placeholder="Search buyers"
          onChange={(e) => {
            setSearch(e.target.value);
          }}
        />
      </InputGroup>
      <Flex
        backgroundColor="whiteAlpha.100"
        border="1px solid"
        borderColor="border.divider"
        direction="column"
        py={3}
      >
        <Heading px={3} size="smallUppercase">
          Recent
        </Heading>
        {!filteredParties?.length && <Text p={3}>No buyers found</Text>}
        {filteredParties.map((party) => {
          return (
            <PartyRow
              key={party.id}
              party={party}
              onClick={() => {
                onSelectParty(party);
                setSearch('');
              }}
            />
          );
        })}
      </Flex>
      <Flex
        _hover={{
          backgroundColor: 'whiteAlpha.200',
        }}
        backgroundColor="whiteAlpha.100"
        border="1px solid"
        borderBottomRadius="sm"
        borderColor="border.divider"
        direction="column"
        px={3}
        py={2}
      >
        <Flex
          alignItems="center"
          cursor="pointer"
          gap={2}
          onClick={() => {
            if (!isAuthenticated) {
              onUnauthenticatedClick?.();
            } else {
              setShowAddBuyerForm(true);
              setSearch('');
            }
          }}
        >
          <PlusIcon />
          <Text>Add new</Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

const PartyRow = ({
  party,
  onClick,
}: {
  party?: PartyFragment;
  onClick?: () => void;
}) => {
  return (
    <Flex
      _hover={
        onClick
          ? {
              backgroundColor: 'whiteAlpha.200',
            }
          : undefined
      }
      alignItems="center"
      cursor={onClick ? 'pointer' : undefined}
      gap={2}
      p={3}
      onClick={onClick}
    >
      <UserAvatar
        background="purple.500"
        border="none"
        color="black"
        size="xs"
        user={party?.primaryConsumer}
      />
      <Text>
        {party?.primaryConsumer?.fullName}
        {party?.secondaryConsumers?.map((consumer) => {
          return `, ${consumer.fullName}`;
        })}
      </Text>
      <Spacer />
    </Flex>
  );
};
