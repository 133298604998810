import { Box, Icon, IconButton, ResponsiveValue, Text } from '@chakra-ui/react';
import { FieldAlign, FieldType } from '@client/graphql/__generated__/types';
import { AbsoluteBox } from '@document/common/AbsoluteBox';
import { FormFieldContainer } from '@document/components/FormFields/FormFieldContainer';
import {
  FormField as FormFieldType,
  ValuesType,
} from '@document/components/FormFields/types';
import { Property } from 'csstype';
import { Trash2Icon } from 'lucide-react';
import { FC, MouseEvent, memo, useCallback, useMemo } from 'react';
import { CheckboxFormField } from '../../FormFields/CheckboxFormField';
import { FormField } from '../../FormFields/FormField';
import { useFormFieldStatusColor } from '../hooks/useFormFieldStatusColor';
import { MoveableTarget } from '../types';

type MoveableFormFieldMouseEvent = (
  e: MouseEvent<HTMLDivElement>,
  formField: FormFieldType,
  type?: 'form-field' | 'label-field'
) => void;

interface MoveableFormFieldProps {
  formField: FormFieldType;
  originalFormField: FormFieldType;
  className: string;
  sizeRatio: number;
  pageHeight: number;
  pageWidth: number;
  isActive?: boolean;
  moveableTarget?: MoveableTarget;
  isEditable?: boolean;
  onDelete?: (
    formFields: FormFieldType[],
    moveableTarget: MoveableTarget
  ) => void;
  onClick: MoveableFormFieldMouseEvent;
  values?: ValuesType;
  updateValues?: (values: ValuesType) => void;
  isDisabled?: boolean;
}

export const MoveableFormField: FC<MoveableFormFieldProps> = memo(
  function MoveableFormField({
    formField,
    originalFormField,
    sizeRatio,
    pageHeight,
    pageWidth,
    className,
    moveableTarget,
    isEditable = false,
    isDisabled = false,
    values,
    updateValues,
    onDelete,
    onClick,
  }) {
    const statusColor = useFormFieldStatusColor(
      formField,
      originalFormField,
      false,
      true
    );
    const labelStatusColor = useFormFieldStatusColor(
      formField,
      originalFormField,
      true
    );
    const positionFormField = moveableTarget?.formField || formField;
    const hideLabel = [FieldType.CHECKBOX, FieldType.RADIO].includes(
      formField.fieldType
    );
    const fontSize = useMemo(() => {
      return (
        parseInt(formField.fontSize?.replace('px', '') || '10') *
        (sizeRatio || 1)
      );
    }, [formField.fontSize, sizeRatio]);

    const containerOnClick = useCallback(
      (e: MouseEvent<HTMLDivElement>) => {
        if (isDisabled) return;
        onClick(
          e,
          formField,
          e.currentTarget.dataset.type as
            | 'form-field'
            | 'label-field'
            | undefined
        );
      },
      [formField, isDisabled, onClick]
    );

    return (
      <>
        <FormFieldContainer
          _hover={
            !moveableTarget && !isDisabled
              ? {
                  outlineColor: 'primary.500',
                  outlineStyle: 'dashed',
                  outlineWidth: '1px',
                }
              : {}
          }
          className={className}
          data-formfieldid={formField?.id}
          data-testid="moveable-item"
          data-type="form-field"
          formField={positionFormField}
          pageHeight={pageHeight}
          pageWidth={pageWidth}
          onClick={containerOnClick}
        >
          <Box
            bgColor={moveableTarget && !isDisabled ? 'rgba(0,0,0,0.5)' : ''}
            borderRadius={2}
            cursor="pointer"
            height="100%"
            outlineColor="primary.500"
            position="relative"
            width="100%"
          >
            <AbsoluteBox
              alignItems="center"
              bgColor={
                isDisabled
                  ? 'transparent'
                  : `${statusColor}${moveableTarget ? '80' : '60'}`
              }
              borderRadius={2}
              display="flex"
              justifyContent="center"
              zIndex={1}
            >
              {!hideLabel && !isEditable && (
                <Text
                  color={moveableTarget ? 'white' : 'black'}
                  display="inline-block"
                  fontFamily={formField.fontFamily}
                  fontSize={`${fontSize}px`}
                  lineHeight="100%"
                  overflow="hidden"
                  px="10px"
                  textAlign={
                    formField.align.toLowerCase() as unknown as ResponsiveValue<Property.TextAlign>
                  }
                  textOverflow="ellipsis"
                  textShadow={
                    moveableTarget ? '1px 1px 2px rgba(0,0,0,0.4)' : ''
                  }
                  userSelect="none"
                  whiteSpace="nowrap"
                  width="100%"
                >
                  <Box as="span">
                    <strong>{formField.label}</strong>
                  </Box>
                </Text>
              )}
              {moveableTarget && onDelete && !isDisabled && (
                <IconButton
                  _hover={{
                    bgColor: 'gray.100',
                  }}
                  aria-label="delete"
                  bgColor="white"
                  color="black"
                  height={{ base: 4, md: 7 }}
                  icon={
                    <Icon
                      as={Trash2Icon}
                      boxSize={{ base: '8px', md: '16px' }}
                    />
                  }
                  minWidth={{ base: 4, md: 7 }}
                  position="absolute"
                  right={{ base: -6, md: -9 }}
                  rounded="full"
                  size="xs"
                  top={{ base: '-3px', md: '-1px' }}
                  variant="outline"
                  onClick={() => {
                    onDelete([formField], moveableTarget);
                  }}
                />
              )}
              {isEditable &&
                (formField.fieldType === FieldType.CHECKBOX ? (
                  <CheckboxFormField
                    checked={!!values?.[formField.mappingKey]}
                    isDisabled={isDisabled}
                  />
                ) : (
                  <FormField
                    formField={{ ...formField, align: FieldAlign.LEFT }}
                    isDisabled={isDisabled}
                    readOnly={isDisabled}
                    sizeRatio={sizeRatio}
                    value={values?.[formField.mappingKey] || ''}
                    onChange={updateValues}
                  />
                ))}
            </AbsoluteBox>
          </Box>
        </FormFieldContainer>

        {positionFormField.labelField && (
          <FormFieldContainer
            _hover={{
              outlineColor: 'yellow.500',
              outlineStyle: 'dashed',
              outlineWidth: '1px',
            }}
            className={className}
            data-formfieldid={formField?.id}
            data-testid="moveable-item"
            data-type="label-field"
            formField={positionFormField.labelField}
            pageHeight={pageHeight}
            pageWidth={pageWidth}
            onClick={containerOnClick}
          >
            <Box
              bgColor={moveableTarget ? 'rgba(0,0,0,0.2)' : ''}
              borderRadius={2}
              cursor="pointer"
              height="100%"
              outlineColor="primary.500"
              position="relative"
              width="100%"
            >
              <AbsoluteBox
                alignItems="center"
                bgColor={`${labelStatusColor}${moveableTarget ? '68' : '30'}`}
                borderRadius={2}
                display="flex"
                justifyContent="center"
                zIndex={1}
              ></AbsoluteBox>
            </Box>
          </FormFieldContainer>
        )}
      </>
    );
  }
);
