import { useEffect, useState } from 'react';
import { ConfirmationPopover } from '~/common/components/ConfirmationPopover/ConfirmationPopover';
import { useAppContext } from '~/services/main/contexts/AppContext';
import {
  markGuestbookGetAlertsPopoverViewed,
  shouldShowGuestbookGetAlertsPopover,
} from '../../components/Storefront/helpers';
import { GetAlertsButton, GetAlertsButtonProps } from './GetAlertsButton';

interface GetAlertsProps extends GetAlertsButtonProps {
  listingId: string;
  buttonText?: string;
}

const AUTOSUBSCRIBE_TOOLTIP_TEXT =
  'We’ve automatically subscribed you to updates on this property, you can opt out by tapping this button at any time.';

export const GetAlerts = ({
  listingId,
  buttonText = 'Got it',
  showUserVerifyModal,
  isFollowingListing,
  handleClickUnsubscribe,
  handleClickSubscribe,
  isPreview,
}: GetAlertsProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { isAuthenticated } = useAppContext();

  useEffect(() => {
    // for now we show the alert hint if the user has just signed the guestbook for this Listing.
    function perhapsOpenPopover() {
      const shouldShow = shouldShowGuestbookGetAlertsPopover(listingId);
      if (shouldShow && isAuthenticated) {
        // mark the popover as viewed after 5 seconds
        setTimeout(() => {
          markGuestbookGetAlertsPopoverViewed(listingId);
        }, 5000);
      }

      setIsOpen(shouldShow);
    }

    window.addEventListener('storage', perhapsOpenPopover);

    // Trigger storage event so the that the popup is shown after the refetchUser call in
    // VerifyUserModal.onLoginOrAgentSignup
    window.dispatchEvent(new Event('storage'));

    return () => {
      window.removeEventListener('storage', perhapsOpenPopover);
    };
  }, [isAuthenticated, listingId]);

  const handleClick = () => {
    setIsOpen(false);
  };

  return (
    <ConfirmationPopover
      anchor={
        <GetAlertsButton
          handleClickSubscribe={handleClickSubscribe}
          handleClickUnsubscribe={handleClickUnsubscribe}
          isFollowingListing={isFollowingListing}
          isPreview={isPreview}
          showUserVerifyModal={showUserVerifyModal}
        />
      }
      buttonText={buttonText}
      handleClick={handleClick}
      isOpen={isOpen}
      text={AUTOSUBSCRIBE_TOOLTIP_TEXT}
    />
  );
};
