import { Flex, Heading, Text } from '@chakra-ui/react';
import {
  MlsListingStatus,
  PublicListingFragment,
} from '@client/graphql/__generated__/types';
import { getPreferenceDateString } from '~/apps/consumer/utils/listing.utils';
import { getDaysOnMarketText } from '~/apps/consumer/utils/storefront.utils';
import { IconTag } from '~/common/components/IconTag/IconTag';
import { DotIcon } from '~/common/icons';
import { StorefrontCard } from '../StorefrontCard';

interface StatusCardProps {
  listing: PublicListingFragment;
}

export const StatusCard = ({ listing }: StatusCardProps) => {
  const bestAndFinalDateFormatted = getPreferenceDateString(
    listing,
    'best_and_final_date'
  );

  const isAcceptingOffers: boolean = [
    MlsListingStatus.Active,
    MlsListingStatus.ComingSoon,
    MlsListingStatus.ActiveUnderContract,
  ].includes(listing.status);
  const headingText = listing.offersCountDisplay;
  const subText = bestAndFinalDateFormatted
    ? `Best and final due ${bestAndFinalDateFormatted}`
    : getDaysOnMarketText(listing.mlsListing?.mls?.daysOnMarket);

  const mlsStatus = listing.mlsListing?.mls?.statusText;
  // In part of the display we use this: !headingText.includes(mlsStatus) to check if we should display the mls status
  // We do this so we don't display the status twice unnecessarily but it fragile on an exact substring match.

  return (
    <StorefrontCard>
      <Flex
        alignItems="center"
        direction="column"
        gap={2}
        h="100%"
        justifyContent="center"
      >
        <Flex alignItems="center" direction="column" gap={1}>
          {isAcceptingOffers && (
            <IconTag icon={DotIcon} iconSize="6px" label="Accepting offers" />
          )}
        </Flex>
        <Heading size="md">{headingText}</Heading>
        <Text color="whiteAlpha.700">
          {subText}
          {mlsStatus && !headingText.includes(mlsStatus) && ` • ${mlsStatus}`}
        </Text>
      </Flex>
    </StorefrontCard>
  );
};
