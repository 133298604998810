import { memo, useMemo } from 'react';
import { useOfferFlowContext } from '~/apps/consumer/pages/Storefront/OfferFlow/OfferFlowContext';
import { PageFormFields } from '~/services/document/components/DocumentFormFields/PageFormFields';
import { useDocumentMapperContext } from '~/services/document/components/DocumentMapper/context';
import { FormFieldsList } from '~/services/document/components/DocumentMapper/FormFieldsList/FormFieldsList';
import { PageMetadata } from '~/services/document/components/PDFScrollable/types';

export const PDFFormFieldsList = memo(function PDFFormFieldsList({
  disableFormFields,
  page,
}: {
  page: PageMetadata;
  disableFormFields?: boolean;
}) {
  const { fieldValues, updateFieldValues, activeMappingKey } =
    useOfferFlowContext();
  const { editFormFieldsById, formFields } = useDocumentMapperContext();

  const editFormFields = useMemo(() => {
    return Object.values(editFormFieldsById || {}).filter(
      ({ field }) => !field?.isComputed && !field?.isHidden
    );
  }, [editFormFieldsById]);

  const filteredFormFields = useMemo(() => {
    return formFields?.filter((field) => field.pageNumber === page.pageNumber);
  }, [formFields, page.pageNumber]);

  return page.isVisible ? (
    <>
      {filteredFormFields?.length ? (
        <FormFieldsList
          isEditable
          showDelete
          disableFormFields={disableFormFields}
          dropContainerId={`${page.documentIndex}`}
          formFields={filteredFormFields}
          page={page}
          updateValues={updateFieldValues}
          values={fieldValues}
        />
      ) : null}
      {editFormFields?.length ? (
        <PageFormFields
          activeMappingKey={activeMappingKey}
          formFields={editFormFields}
          isDisabled={disableFormFields}
          page={page}
          updateValues={updateFieldValues}
          values={fieldValues}
        />
      ) : null}
    </>
  ) : null;
});
