import { Flex } from '@chakra-ui/react';
import { useCallback, useEffect } from 'react';
import {
  Navigate,
  Outlet,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { useCheckPermission } from '~/common/hooks/useCheckPermission';
import { useIsMobile } from '~/common/hooks/useIsMobile';
import { ListingSetupSidebar } from '../../components/Listings/ListingSetup/ListingSetupSidebar/ListingSetupSidebar';
import { ListingSetupTourModal } from '../../components/Listings/ListingSetup/ListingSetupTourModal/ListingSetupTourModal';
import { MobileNavDrawer } from '../../components/Listings/ListingSetup/MobileNavDrawer';
import { PageDetailLayout } from '../../components/PageDetailLayout/PageDetailLayout';
import {
  SIDEBAR_WIDTH,
  SIDEBAR_WIDTH_COLLAPSED,
} from '../../components/PageDetailLayout/PageDetailLayoutSidebar';
import { listingsPath } from '../../utils/routes.utils';
import { useListingSetupContext } from './ListingSetupContext';
import { ListingWithOffersProvider } from './ListingWithOffersProvider';

export const ListingSetupPageWrapper = () => {
  const { listingId } = useParams<string>();
  const { isAuthorized } = useCheckPermission(
    'listings.canViewSubmittedOffers',
    {},
    listingId ? [listingId] : []
  );

  if (!listingId || isAuthorized === false) {
    // If the user does not have permission to view the listing, redirect to the listings page
    return <Navigate to="/listings" />;
  }

  return isAuthorized ? (
    <ListingWithOffersProvider listingId={listingId}>
      <ListingSetupPageLayout />
    </ListingWithOffersProvider>
  ) : null;
};

export const ListingSetupPageLayout = () => {
  const { showListingSetupTour, setShowListingSetupTour } =
    useListingSetupContext();
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const handleClickBack = useCallback(() => {
    navigate(listingsPath());
  }, [navigate]);

  useEffect(() => {
    if (searchParams.get('published') === 'true') {
      setShowListingSetupTour(true);
      setSearchParams((prev) => {
        prev.delete('published');

        return prev;
      });
    }
  }, [searchParams, setShowListingSetupTour, setSearchParams, navigate]);

  return (
    <PageDetailLayout>
      {!isMobile && <ListingSetupSidebar />}
      {isMobile && (
        <MobileNavDrawer
          Sidebar={ListingSetupSidebar}
          backButtonText="All listings"
          onClickBack={handleClickBack}
        />
      )}
      <Flex
        boxSizing="border-box"
        overflowX="hidden"
        width={{
          base: '100%',
          md: `calc(100vw - (${SIDEBAR_WIDTH} + ${SIDEBAR_WIDTH_COLLAPSED}))`,
        }}
      >
        <Outlet />
      </Flex>
      <ListingSetupTourModal
        isOpen={showListingSetupTour}
        onClose={() => setShowListingSetupTour(false)}
      />
    </PageDetailLayout>
  );
};
