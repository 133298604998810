import { useField } from 'formik';
import { Select, SelectProps } from '~/services/document/common/Select';

interface OptionType {
  label: string;
  value: string;
}

export interface SingleSelectFieldProps extends SelectProps<OptionType, false> {
  name: string;
  options: OptionType[];
}

export const SingleSelectField = ({
  name,
  options,
  ...rest
}: SingleSelectFieldProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, meta, helpers] = useField({
    name,
    type: 'select',
    multiple: false,
  });
  const value = (field.value || []) as Array<string>;

  return (
    <Select
      {...rest}
      isMulti={false}
      menuPlacement="auto"
      menuPortalTarget={document.body}
      options={options}
      styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
      value={options?.find((option) => value.includes(option.value))}
      onChange={(value) => void helpers.setValue(value?.value)}
    />
  );
};
