import { Flex, Text } from '@chakra-ui/react';
import { ComparisonType, FieldType } from '@client/graphql/__generated__/types';
import { DaysTag } from '~/common/components/DaysTag';
import { PercentageTag } from '~/common/components/PercentageTag';
import { FieldWithPage } from '~/services/document/components/DocumentSections/types';
import { useFormatFieldValue } from '~/services/document/components/Fields/hooks/useFormatFieldValue';
import { BaseComparableField } from '../../Comparables/BaseComparableField';

interface OfferCompareFieldProps {
  offerComparisonField: FieldWithPage;
  value: string | number | boolean | string[] | undefined;
  shouldHighlight?: boolean;
  compareValue?: number | string | undefined;
}

export const OfferCompareField = ({
  shouldHighlight,
  offerComparisonField,
  value,
  compareValue,
}: OfferCompareFieldProps) => {
  const formattedValue = useFormatFieldValue({
    field: offerComparisonField,
    value,
  });

  const isLowestComparisonType =
    offerComparisonField?.comparisonType === ComparisonType.LOWEST;

  const textColor = shouldHighlight ? 'green.400' : undefined;

  return (
    <BaseComparableField
      key={offerComparisonField.label}
      border="none"
      borderBottom="1px solid"
      borderBottomColor="border.divider"
      p={4}
    >
      <Flex justifyContent="space-between" width="100%">
        <Text
          color={textColor}
          fontSize={{ base: 'sm', lg: 'md' }}
          fontWeight="500"
        >
          {formattedValue}
        </Text>

        {offerComparisonField?.fieldType === FieldType.NUMBER &&
          compareValue && (
            <PercentageTag
              compareValue={compareValue as number}
              isNegative={isLowestComparisonType}
              value={value as number}
            />
          )}
        {offerComparisonField?.fieldType === FieldType.DATE && compareValue && (
          <DaysTag
            compareValue={compareValue as string}
            size="sm"
            value={value as string}
          />
        )}
      </Flex>
    </BaseComparableField>
  );
};
