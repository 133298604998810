import { CheckIcon } from '@chakra-ui/icons';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Flex,
  HStack,
  Heading,
  Icon,
  ListIcon,
  ListItem,
  ScaleFade,
  Spinner,
  Tag,
  Text,
  UnorderedList,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import { PaperclipIcon } from 'lucide-react';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { tourClassNameStep1 } from '~/apps/consumer/components/Storefront/StorefrontTourModal/constants';
import { StartOfferUploadModal } from '~/apps/consumer/components/Storefront/UploadOffer/Upload/StartOfferUploadModal';
import { ProcessingAnimation } from '~/apps/consumer/components/Transitions/ProcessingAnimation';
import { formatPartyConsumers } from '~/apps/consumer/utils/party.utils';
import { DividerWithText } from '~/common/components/DividerWithText';
import { UploadDropzone } from '~/common/components/Upload/UploadDropzone';
import { useIsMobile } from '~/common/hooks/useIsMobile';
import { useStorefrontContext } from '../StorefrontContext';

const uploadLoadingSteps = [
  { text: 'Classifying your docs' },
  { text: 'Validating accuracy with tax records and listing data' },
  { text: 'Comparing to seller preferences' },
  { text: 'Processing', iconOverride: <Spinner size="sm" /> },
];

export const StartOfferPage: FC = () => {
  const {
    listing,
    shouldContinueOffer,
    canUploadOffer,
    canMakeOffer,
    scopeToParty,
  } = useStorefrontContext();
  const isMobile = useIsMobile();

  const {
    isOpen: isUploadModalOpen,
    onOpen: onUploadModalOpen,
    onClose: onUploadModalClose,
  } = useDisclosure({ defaultIsOpen: false });
  const { isOpen, onClose, onOpen } = useDisclosure({ defaultIsOpen: true });

  const [files, setFiles] = useState<File[]>([]);
  const [showUploadProcessing, setShowUploadProcessing] = useState(false);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  return (
    <Flex
      direction="column"
      flexGrow={1}
      justifyContent="center"
      p={4}
      width="100%"
    >
      <ScaleFade unmountOnExit delay={0.1} in={isOpen}>
        <VStack
          className={!isMobile ? tourClassNameStep1 : ''}
          spacing={6}
          width="100%"
        >
          {canMakeOffer && (
            <Card variant="secondaryGradient" width="100%">
              <CardHeader as={HStack} justifyContent="space-between">
                <Heading as="h4" fontSize="md" size="xs">
                  {shouldContinueOffer ? 'Continue offer' : 'Write a new offer'}
                </Heading>
                <Tag variant="light">25% Complete</Tag>
              </CardHeader>

              <CardBody pt={0}>
                <UnorderedList ml={0} styleType="none">
                  <ListItem>
                    <ListIcon as={CheckIcon} />
                    FREE buyer eSign
                  </ListItem>
                  <ListItem>
                    <ListIcon as={CheckIcon} />
                    Auto-filled offer documents
                  </ListItem>
                  <ListItem>
                    <ListIcon as={CheckIcon} />
                    Automatic validation so you never miss a step
                  </ListItem>
                </UnorderedList>

                <Button
                  color="white"
                  colorScheme="darkButton"
                  mt={4}
                  sx={{ textWrap: 'wrap' }}
                  width="100%"
                  onClick={() => {
                    onClose();

                    navigate(
                      {
                        pathname: 'offer',
                        search: searchParams.toString(),
                      },
                      {
                        state: shouldContinueOffer
                          ? undefined
                          : { startNewOffer: true },
                      }
                    );
                  }}
                >
                  {shouldContinueOffer ? 'Continue offer' : `Prepare new offer`}
                  {scopeToParty
                    ? ` for ${formatPartyConsumers(scopeToParty)}`
                    : ' for buyer'}
                </Button>
              </CardBody>
            </Card>
          )}

          {canUploadOffer && (
            <>
              {canMakeOffer && (
                <DividerWithText>
                  <Text fontSize="lg">or</Text>
                </DividerWithText>
              )}
              <Flex width="100%">
                <UploadDropzone
                  borderColor="cyan.500"
                  dropzoneContent={
                    <Flex
                      alignItems="center"
                      direction="column"
                      gap={2}
                      justifyContent="center"
                      margin={10}
                    >
                      <Icon
                        as={PaperclipIcon}
                        boxSize="24px"
                        color="cyan.500"
                      />
                      <Text fontSize="lg" fontWeight="medium">
                        Upload a signed offer
                      </Text>
                      <Text color="whiteAlpha.700">
                        Click or drag files here to start
                      </Text>
                    </Flex>
                  }
                  onDrop={(files) => {
                    setFiles(files);
                    onUploadModalOpen();
                  }}
                />
              </Flex>
              <StartOfferUploadModal
                initialFiles={files}
                isOpen={isUploadModalOpen}
                listing={listing}
                onClose={() => {
                  onUploadModalClose();
                  setFiles([]);
                }}
                onErrorProcessing={() => {
                  setShowUploadProcessing(false);
                  onOpen();
                }}
                onProcessing={() => {
                  onClose();
                  onUploadModalClose();
                  setShowUploadProcessing(true);
                }}
              />
            </>
          )}
        </VStack>
      </ScaleFade>
      {showUploadProcessing && (
        <ProcessingAnimation steps={uploadLoadingSteps} />
      )}
    </Flex>
  );
};
