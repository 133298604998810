import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Heading,
  Image,
  VStack,
} from '@chakra-ui/react';
import {
  DocumentForOfferFlowFragment,
  PublicDocumentForOfferFlowFragment,
} from '@client/graphql/__generated__/types';
import { FC } from 'react';
import { NavLink } from 'react-router-dom';

interface ChooseContractProps {
  activeDocumentId?: string;
  documents?: (
    | PublicDocumentForOfferFlowFragment
    | DocumentForOfferFlowFragment
  )[];
  onChange: (
    document: PublicDocumentForOfferFlowFragment | DocumentForOfferFlowFragment
  ) => void;
}

export const ChooseContract: FC<ChooseContractProps> = ({
  activeDocumentId,
  documents,
  onChange,
}) => {
  return (
    <VStack alignItems="stretch" flexGrow={1} spacing={4} width="100%">
      <Heading fontSize="lg" size="md">
        Choose Contract
      </Heading>

      {documents?.map((document) => (
        <Card
          key={document.id}
          borderColor={
            document.id === activeDocumentId ? 'primary.400' : undefined
          }
          borderWidth={activeDocumentId === document.id ? '2px' : '1px'}
          cursor="pointer"
          onClick={() => onChange(document)}
        >
          <CardHeader>{document.name}</CardHeader>
          <CardBody overflow="hidden">
            <Image
              key={document.id}
              borderRadius="sm"
              fit="cover"
              height="150px"
              objectPosition="top"
              src={document.activeDocumentVersion?.previewImages?.[0]?.file.url}
              transformOrigin="top"
              transition="transform 0.3s"
              width="100%"
              onMouseOut={(e) => {
                e.currentTarget.style.transform = 'scale(1)';
              }}
              onMouseOver={(e) => {
                e.currentTarget.style.transform = 'scale(2)';
              }}
            />
          </CardBody>
        </Card>
      ))}

      <Button as={NavLink} to="../docs">
        Next
      </Button>
    </VStack>
  );
};
