import { ApolloQueryResult } from '@apollo/client';
import { BackgroundProps, Center, Flex, Spinner } from '@chakra-ui/react';
import { useOrganizationsForCurrentUserQuery } from '@client/graphql/__generated__/main-operations';
import {
  CurrentUserFragment,
  CurrentUserQuery,
  OrganizationFragment,
  OrganizationsForCurrentUserQuery,
} from '@client/graphql/__generated__/types';
import { usePostHog } from 'posthog-js/react';
import {
  FC,
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useCheckPermission } from '~/common/hooks/useCheckPermission';
import { useCurrentUser } from '~/common/hooks/useCurrentUser';
import { useMergeState } from '~/services/document/hooks/useMergeState';

export interface AppContextValues {
  isAuthenticated: boolean;
  user?: CurrentUserFragment;
  appState: {
    bgColor: BackgroundProps['bgColor'];
  };
  refetchUser: () => Promise<ApolloQueryResult<CurrentUserQuery> | void>;
  updateAppState: (state: Partial<AppContextValues['appState']>) => void;
  organizations?: OrganizationFragment[];
  selectedOrganization?: OrganizationFragment;
  loadingOrganizations: boolean;
  refetchOrganizations: () => Promise<ApolloQueryResult<OrganizationsForCurrentUserQuery> | void>;
  isSelectedOrgAdmin?: boolean;
  loadingIsSelectedOrgAdmin?: boolean;
}

export const AppContext = createContext<AppContextValues>({
  isAuthenticated: false,
  user: undefined,
  appState: {
    bgColor: 'bg.main',
  },
  refetchUser: async () => {},
  updateAppState: () => {},
  loadingOrganizations: true,
  refetchOrganizations: async () => {},
  isSelectedOrgAdmin: false,
  loadingIsSelectedOrgAdmin: false,
});

export const AppContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [appState, setAppState] = useState({
    bgColor: 'bg.main',
  });
  const updateAppState = useMergeState(setAppState);
  const { data, refetch, loading } = useCurrentUser();
  const {
    data: organizationsData,
    loading: loadingOrganizations,
    refetch: refetchOrganizations,
  } = useOrganizationsForCurrentUserQuery({
    fetchPolicy: 'cache-first',
    skip: !data?.currentUser,
  });

  const refetchUser = useCallback(async () => refetch(), [refetch]);
  const postHog = usePostHog();

  const currentUser = data?.currentUser;

  // get organization from currentUser settings or first organization
  const selectedOrganization =
    organizationsData?.organizationsForCurrentUser?.find(
      (organization) =>
        organization.id === currentUser?.settings?.selectedOrganization?.id
    ) || organizationsData?.organizationsForCurrentUser?.[0];

  const {
    isAuthorized: isSelectedOrgAdmin,
    loading: loadingIsSelectedOrgAdmin,
  } = useCheckPermission(
    'accessControl.isAdmin',
    {
      skip: !selectedOrganization,
    },
    {
      organizationUuid: selectedOrganization?.id,
    }
  );

  useEffect(() => {
    if (currentUser) {
      postHog.identify(currentUser.id, {
        email: currentUser.email,
        name: currentUser.fullName,
      });
    }
  }, [currentUser, postHog]);

  return loading && !currentUser ? (
    <Flex alignItems="center" h="100vh" justifyContent="center" w="100vw">
      <Center>
        <Spinner />
      </Center>
    </Flex>
  ) : (
    <AppContext.Provider
      value={{
        refetchOrganizations,
        loadingOrganizations,
        isSelectedOrgAdmin,
        loadingIsSelectedOrgAdmin,
        isAuthenticated: !!currentUser && currentUser.roles.length > 0,
        user: currentUser,
        organizations: organizationsData?.organizationsForCurrentUser,
        selectedOrganization,
        refetchUser,
        appState,
        updateAppState,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  return useContext(AppContext);
};
