import { Card, Heading, SlideFade, VStack } from '@chakra-ui/react';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { IndigoLogo } from '~/common/icons/IndigoLogo';
import { AgentSignupFlow } from '~/services/main/components/AgentSignUpFlow/AgentSignupFlow';
import { useAppContext } from '~/services/main/contexts/AppContext';
import { useRegisteredRoles } from '../../hooks/useRegisteredRoles';

export const AgentSignUpPage = () => {
  const { refetchUser } = useAppContext();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { isRegisteredAgent } = useRegisteredRoles();

  const nextUrl = searchParams.get('next');

  if (isRegisteredAgent) {
    // Already registered as an agent, redirect to home
    return <Navigate replace to={{ pathname: nextUrl || '/' }} />;
  }

  return (
    <VStack
      alignItems="center"
      justifyContent="center"
      mt={{ base: 0, md: 20 }}
      width="100vw"
    >
      <SlideFade in offsetY="60px">
        <Card>
          <VStack
            bgColor="bg.mainDark"
            borderRadius="md"
            minWidth="340px"
            p={6}
          >
            <IndigoLogo width="100px" />
            <Heading as="h2" fontSize="3xl" size="sm">
              Sign Up
            </Heading>
            <AgentSignupFlow
              onSignup={async () => {
                await refetchUser();
                navigate(nextUrl || '/');
              }}
            />
          </VStack>
        </Card>
      </SlideFade>
    </VStack>
  );
};
