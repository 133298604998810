import {
  Button,
  Center,
  Flex,
  Spinner,
  Text,
  Tooltip,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import {
  useBuyerClientsForCurrentAgentUserQuery,
  useCreatePartyForBuyersAgentMutation,
  useUpdateOfferWithPartyMutation,
} from '@client/graphql/__generated__/main-operations';
import { FinancingType } from '@client/graphql/__generated__/types';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import {
  ADD_BUYER_FORM_ID,
  AddBuyerForm,
} from '~/apps/consumer/components/Buyer/AddBuyerForm/AddBuyerForm';
import { PartySelector } from '~/apps/consumer/components/Buyer/PartySelector/PartySelector';
import { SelectedPartyInfoControl } from '~/apps/consumer/components/Buyer/SelectedPartyInfo/SelectedPartyInfoControl';
import { BuyerFormValues } from '~/apps/consumer/components/Buyer/types';
import { useAppContext } from '~/services/main/contexts/AppContext';
import { useUpdatePartyForBuyersAgent } from './hooks/useUpdatePartyForBuyersAgent';
import { OfferFlowContainer } from './OfferFlowContainer';
import { useOfferFlowContext } from './OfferFlowContext';

export const BuyerPage = () => {
  const {
    latestOffer,
    refetchLatestOffer,
    latestOfferLoading,
    selectedParty,
    setSelectedParty,
    refreshBuyerInfo,
    setShowVerifyUserModal,
    isBuyersAgentViewingOffer,
    fetchAutofillValues,
    save,
  } = useOfferFlowContext();
  const { isAuthenticated } = useAppContext();
  const [isSaving, setIsSaving] = useState(false);
  const [showAddBuyerForm, setShowAddBuyerForm] = useState(false);
  const [preapprovalFile, setPreapprovalFile] = useState<File | undefined>();
  const [createPartyForBuyersAgent, { loading: creatingParty }] =
    useCreatePartyForBuyersAgentMutation();
  const { handleUpdate: updatePartyForBuyersAgent, loading: updatingParty } =
    useUpdatePartyForBuyersAgent();
  const [updateOfferWithParty, { loading: updatingOffer }] =
    useUpdateOfferWithPartyMutation();
  const [isContinueDisabled, setIsContinueDisabled] = useState(false);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const navigate = useNavigate();
  const {
    data,
    loading,
    refetch: refetchParties,
  } = useBuyerClientsForCurrentAgentUserQuery({
    skip: !isAuthenticated,
  });

  const parties = data?.buyerClientsForCurrentAgentUser;

  // Selected Party initialization
  useEffect(() => {
    if (latestOffer?.party) {
      const party = parties?.find((p) => p.id === latestOffer?.party?.id);
      setSelectedParty(party);
    }
  }, [latestOffer?.party, parties, setSelectedParty]);

  const updateAndRefresh = async (partyId: string) => {
    await updateOfferWithParty({
      variables: {
        input: {
          offerUuid: latestOffer?.id as string,
          partyUuid: partyId,
        },
      },
    });

    if (partyId === latestOffer?.party?.id) {
      const party = parties?.find((p) => p.id === latestOffer?.party?.id);
      setSelectedParty(party);
    }

    await save();

    await refetchLatestOffer();
    await refetchParties();
    await refreshBuyerInfo();
    await fetchAutofillValues();
  };

  const refreshAndNavigate = async (partyId: string) => {
    await updateAndRefresh(partyId);
    navigate('../add-docs');
  };

  const onSubmit = async (values: BuyerFormValues) => {
    const { data } = await createPartyForBuyersAgent({
      variables: {
        input: {
          primaryBuyer: {
            fullName: values.buyerName,
            email: values.buyerEmail,
            isEntity: values.isEntity,
            entityName: values.entityName,
            entityTitle: values.entityTitle,
          },
          secondaryBuyers:
            values.coBuyerName && values.coBuyerEmail
              ? [
                  {
                    fullName: values.coBuyerName,
                    email: values.coBuyerEmail,
                  },
                ]
              : undefined,
          financingType: values.financingType,
          loanAmount:
            values.loanAmount && values.financingType !== FinancingType.CASH
              ? Number(values.loanAmount)
              : undefined,
          percentDownPayment: values.percentDownPayment
            ? Number(values.percentDownPayment)
            : undefined,
          cashReserves: values.cashReserves
            ? Number(values.cashReserves)
            : undefined,
          preapprovalFile: preapprovalFile,
        },
      },
    });
    setPreapprovalFile(undefined);
    await refreshAndNavigate(data?.createPartyForBuyersAgent?.id as string);
  };

  const onUpdate = async (values: BuyerFormValues) => {
    await updatePartyForBuyersAgent(
      selectedParty?.id as string,
      values && {
        ...values,
        loanAmount:
          values.loanAmount && values.financingType !== FinancingType.CASH
            ? Number(values.loanAmount)
            : undefined,
        percentDownPayment: values.percentDownPayment
          ? Number(values.percentDownPayment)
          : undefined,
        cashReserves: values.cashReserves
          ? Number(values.cashReserves)
          : undefined,
      },
      preapprovalFile
    );
    void updateAndRefresh(selectedParty?.id as string);
  };

  if (loading || latestOfferLoading) {
    return (
      <Center>
        <Spinner />
      </Center>
    );
  }

  const customContinueButton = (
    <Tooltip
      hasArrow
      isDisabled={!isContinueDisabled}
      isOpen={isOpen}
      label={<Text>Please confirm edits to buyer(s) to continue.</Text>}
      placement="top"
      onClose={onClose}
      onOpen={onOpen}
    >
      <Button
        form={showAddBuyerForm ? ADD_BUYER_FORM_ID : undefined}
        isDisabled={isContinueDisabled}
        isLoading={creatingParty || updatingParty || updatingOffer || isSaving}
        type="submit"
        onClick={() => {
          void (async () => {
            if (!showAddBuyerForm) {
              setIsSaving(true);
              await save('./add-docs');
              setIsSaving(false);
            }
          })();
        }}
        onMouseEnter={isContinueDisabled ? onOpen : undefined}
        onMouseLeave={isContinueDisabled ? onClose : undefined}
        onTouchStart={isContinueDisabled ? onOpen : undefined}
      >
        Continue
      </Button>
    </Tooltip>
  );

  return (
    <OfferFlowContainer customContinueButton={customContinueButton}>
      <Flex
        direction="column"
        height="100%"
        justifyContent="space-between"
        pb={2}
      >
        <VStack alignItems="stretch" flexGrow={1} spacing={4}>
          <PartySelector
            disableChange={isBuyersAgentViewingOffer}
            parties={parties}
            selectedParty={selectedParty}
            setShowAddBuyerForm={setShowAddBuyerForm}
            showAddBuyerForm={showAddBuyerForm}
            onSelectParty={(party) => {
              if (party?.id) {
                void updateAndRefresh(party.id);
              } else {
                setSelectedParty(undefined);
              }
            }}
            onUnauthenticatedClick={() => setShowVerifyUserModal(true)}
          />
          {showAddBuyerForm ? (
            <AddBuyerForm
              preapprovalFile={preapprovalFile}
              setPreapprovalFile={setPreapprovalFile}
              onSubmit={onSubmit}
            />
          ) : undefined}
          {selectedParty ? (
            <SelectedPartyInfoControl
              handleSubmit={onUpdate}
              isContinueDisabled={isContinueDisabled}
              party={selectedParty}
              preapprovalFile={
                preapprovalFile ||
                (selectedParty?.partyFinancing?.preapprovalFile?.url
                  ? selectedParty?.partyFinancing?.preapprovalFile
                  : undefined)
              }
              setIsContinueDisabled={setIsContinueDisabled}
              setPreapprovalFile={setPreapprovalFile}
            />
          ) : undefined}
        </VStack>
      </Flex>
    </OfferFlowContainer>
  );
};
