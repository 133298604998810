import { Flex, Heading } from '@chakra-ui/react';
import {
  ListingDocumentTypeFragment,
  PublicListingDocumentTypeFragment,
} from '@client/graphql/__generated__/types';
import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { Form } from '~/apps/consumer/components/Formik/Form';
import { DocumentUploadGroup } from '~/apps/consumer/components/Listings/ListingSetup/Documents/DocumentUploadGroup';
import { CenterSpinner } from '~/common/components/CenterSpinner';
import { useListingSetupContext } from '../ListingSetupContext';
import {
  ListingSetupContainer,
  ListingSetupContentWrapper,
} from '../ListingSetupWrapperComponents';
import { PreferenceMetaType } from '../Preferences/constants';
import { useDocumentsPage } from './hooks/useDocumentsPage';

const FORM_ID = 'documents-form';

export const DocumentsPage = () => {
  const { listing, refetchAllData } = useListingSetupContext();
  const [initialLoading, setInitialLoading] = useState(true);

  const {
    initialValues,
    uploadDocuments,
    uploading,
    deleting,
    loading,
    uploadingAgentNegotiation,
    disclosureAndAgentNegotiationDocumentTypes,
    additionalDocumentTypes,
    agentNegotiationDocumentTypes,
    onSubmitAgentNegotiationPreferences,
    initialAgentNegotiationValues,
    updatingAgentNegotiationPreferences,
    loadingAgentNegotiationPreferences,
    agentNegotiationPreferenceGroup,
    showAgentCompensationForm,
  } = useDocumentsPage({ listing, refetchListing: refetchAllData });

  useEffect(() => {
    if (!loading && initialLoading) {
      setInitialLoading(false);
    }
  }, [loading, initialLoading]);

  if (initialLoading) {
    return <CenterSpinner />;
  }

  return (
    <Form
      enableReinitialize
      formId={FORM_ID}
      initialValues={initialValues}
      onSubmit={uploadDocuments}
    >
      {() => (
        <ListingSetupContainer>
          <ListingSetupContentWrapper>
            <DocumentsPageContent
              additionalDocumentTypes={additionalDocumentTypes}
              agentNegotiationDocumentTypes={agentNegotiationDocumentTypes}
              agentNegotiationPreferenceGroup={agentNegotiationPreferenceGroup}
              deleting={deleting}
              disclosureDocumentTypes={
                disclosureAndAgentNegotiationDocumentTypes
              }
              initialAgentNegotiationValues={initialAgentNegotiationValues}
              loading={loading}
              loadingAgentNegotiationPreferences={
                loadingAgentNegotiationPreferences
              }
              showAgentCompensationForm={showAgentCompensationForm}
              updatingAgentNegotiationPreferences={
                updatingAgentNegotiationPreferences
              }
              uploading={uploading}
              uploadingAgentNegotiation={uploadingAgentNegotiation}
              onSubmitAgentNegotiationPreferences={
                onSubmitAgentNegotiationPreferences
              }
            />
          </ListingSetupContentWrapper>
        </ListingSetupContainer>
      )}
    </Form>
  );
};

interface DocumentsPageContentProps {
  agentNegotiationDocumentTypes: (
    | PublicListingDocumentTypeFragment
    | ListingDocumentTypeFragment
  )[];
  disclosureDocumentTypes: (
    | PublicListingDocumentTypeFragment
    | ListingDocumentTypeFragment
  )[];
  additionalDocumentTypes: (
    | PublicListingDocumentTypeFragment
    | ListingDocumentTypeFragment
  )[];
  uploading: boolean;
  deleting: boolean;
  loading: boolean;
  uploadingAgentNegotiation: boolean;
  agentNegotiationPreferenceGroup: {
    title: string;
    preferences: PreferenceMetaType[];
    defaultValues: Record<string, unknown>;
  }[];
  initialAgentNegotiationValues: { [key: string]: unknown };
  loadingAgentNegotiationPreferences: boolean;
  updatingAgentNegotiationPreferences: boolean;
  showAgentCompensationForm: boolean;
  onSubmitAgentNegotiationPreferences: (values: {
    [key: string]: unknown;
  }) => Promise<void>;
}

export const DocumentsPageContent = ({
  disclosureDocumentTypes,
  additionalDocumentTypes,
  uploading,
  deleting,
  loading,
}: DocumentsPageContentProps) => {
  const { isValid, values, dirty, submitForm } = useFormikContext();

  // the form now reacts onChange instead of waiting for a publish button
  useEffect(() => {
    if (isValid && dirty) {
      void submitForm();
    }
  }, [isValid, values, dirty, submitForm]);

  return (
    <Flex direction="column" gap={4} width={{ base: '100%', md: '80%' }}>
      <Heading pb={2} size="xxs">
        Documents
      </Heading>

      <DocumentUploadGroup
        isDisclosures
        isSubmitting={uploading || deleting || loading}
        listingDocumentTypes={disclosureDocumentTypes}
        title="Seller Disclosures"
        warningText="These will automatically be added to the offer package when being signed by the buyer"
      />
      <DocumentUploadGroup
        isDisclosures={false}
        isSubmitting={uploading || deleting || loading}
        listingDocumentTypes={additionalDocumentTypes}
        title="Additional Documents"
        warningText="Additional documents will be visible on your storefront but won’t be included in the offer package"
      />
    </Flex>
  );
};
