import {
  PreferenceObject,
  PreferenceValueObject,
  PublicPreferenceObject,
} from '@client/graphql/__generated__/types';
import isNil from 'lodash/isNil';
import { match } from 'ts-pattern';
import { StyledFormInputType } from '../../../components/StyledFormInput/StyledFormInput';
import { DEFAULT_SELECTED_LOAN_TYPES, PreferenceMetaType } from './constants';

export const filterPreferenceMetaByPreference = (
  preferencesMeta: PreferenceMetaType[],
  preferences?: PreferenceObject[] | PublicPreferenceObject[]
) => {
  return preferencesMeta.filter((meta) => {
    if (!preferences) {
      return false;
    }
    switch (meta.inputType) {
      case StyledFormInputType.RANGE:
        return (
          preferences?.some(
            (preference: PreferenceObject | PublicPreferenceObject) =>
              preference.slug === meta.fromFieldSlug
          ) &&
          preferences?.some(
            (preference: PreferenceObject | PublicPreferenceObject) =>
              preference.slug === meta.toFieldSlug
          )
        );
      case StyledFormInputType.UNIT_SELECT:
        // check to make sure all the field options are available
        const prefix = meta?.fieldSlugPrefix;

        return meta?.inputProps?.unitOptions?.every((option) => {
          const fieldSlug = `${prefix as string}_${option.value}`;

          return preferences?.some(
            (preference: PreferenceObject | PublicPreferenceObject) =>
              preference.slug === fieldSlug
          );
        });
      default:
        return preferences?.some(
          (preference: PreferenceObject | PublicPreferenceObject) =>
            preference.slug === meta.fieldSlug
        );
    }
  });
};

export const getInitialPreferenceValues = (
  filteredPrefs: PreferenceMetaType[],
  preferenceValues?: PreferenceValueObject[]
) => {
  return filteredPrefs.reduce((acc, preferenceMeta) => {
    switch (preferenceMeta.inputType) {
      case StyledFormInputType.RANGE: {
        const fromPreference = preferenceValues?.find(
          (preferenceValue) =>
            preferenceValue.preference.slug === preferenceMeta.fromFieldSlug
        );
        const toPreference = preferenceValues?.find(
          (preferenceValue) =>
            preferenceValue.preference.slug === preferenceMeta.toFieldSlug
        );
        let fromPreferenceValue = fromPreference?.[preferenceMeta?.dataType] as
          | string
          | number
          | null;

        let toPreferenceValue = toPreference?.[preferenceMeta?.dataType] as
          | string
          | number
          | null;

        if (preferenceMeta.dataType === 'numberValue') {
          fromPreferenceValue = !isNil(fromPreferenceValue)
            ? String(fromPreferenceValue)
            : null;
          toPreferenceValue = !isNil(toPreferenceValue)
            ? String(toPreferenceValue)
            : null;
        }

        return {
          ...acc,
          [preferenceMeta.fromFieldSlug as string]: fromPreferenceValue || null,
          [preferenceMeta.toFieldSlug as string]: toPreferenceValue || null,
        };
      }
      case StyledFormInputType.UNIT_SELECT: {
        const prefix = preferenceMeta.fieldSlugPrefix;
        const additionalValues: { [key: string]: string | number } = {};
        preferenceMeta?.inputProps?.unitOptions?.some((option) => {
          const fieldSlug = `${prefix as string}_${option.value}`;

          const fieldPreference = preferenceValues?.find(
            (preferenceValue) => preferenceValue.preference.slug === fieldSlug
          );

          const preferenceValue = fieldPreference
            ? (fieldPreference?.[preferenceMeta.dataType] as string)
            : null;

          if (!isNil(preferenceValue)) {
            additionalValues[fieldSlug] = match(option.value)
              // NOTE:
              //  1. due to floating-point arithmetic precision issue in JS (e.g. 0.035 => 34.999999999999996),
              //     we need to round to 2 decimal places
              //  2. remove the trailing 0s
              .with('percent', () =>
                Number((Number(preferenceValue) * 100).toFixed(2))
              )
              .with('absolute', () => Number(preferenceValue))
              .otherwise(() => preferenceValue);

            preferenceMeta.inputProps.selectedUnit = option.value;

            return true;
          }

          return false;
        });

        return {
          ...acc,
          ...additionalValues,
        };
      }
      case StyledFormInputType.SELECT: {
        const fieldPreference = preferenceValues?.find(
          (preferenceValue) =>
            preferenceValue.preference.slug === preferenceMeta.fieldSlug
        );
        const fieldPreferenceValue = fieldPreference?.[
          preferenceMeta.dataType
        ] as string[];

        if (
          preferenceMeta.fieldSlug === 'loan_types' &&
          !fieldPreferenceValue
        ) {
          // set selected values default selected if no preferenceValue
          return {
            ...acc,
            [preferenceMeta.fieldSlug]: DEFAULT_SELECTED_LOAN_TYPES,
          };
        } else {
          return {
            ...acc,
            [preferenceMeta.fieldSlug as string]: fieldPreferenceValue || null,
          };
        }
      }
      default:
        const fieldPreference = preferenceValues?.find(
          (preferenceValue) =>
            preferenceValue.preference.slug === preferenceMeta.fieldSlug
        );
        const fieldPreferenceValue = fieldPreference?.[
          preferenceMeta.dataType
        ] as string | number | null;

        return {
          ...acc,
          [preferenceMeta.fieldSlug as string]: fieldPreferenceValue || null,
        };
    }
  }, {});
};

export const getFilledPreferencesCount = (
  preferencesMeta: PreferenceMetaType[],
  preferenceValues?: PreferenceValueObject[]
) => {
  const filteredMeta = preferencesMeta.filter((preferenceMeta) => {
    switch (preferenceMeta.inputType) {
      case StyledFormInputType.RANGE: {
        const fromPreference = preferenceValues?.find(
          (preferenceValue) =>
            preferenceValue.preference.slug === preferenceMeta.fromFieldSlug
        );
        const toPreference = preferenceValues?.find(
          (preferenceValue) =>
            preferenceValue.preference.slug === preferenceMeta.toFieldSlug
        );

        const fromPreferenceValue = fromPreference?.[
          preferenceMeta?.dataType
        ] as string | number | null;
        const toPreferenceValue = toPreference?.[preferenceMeta?.dataType] as
          | string
          | number
          | null;

        return !isNil(fromPreferenceValue) && !isNil(toPreferenceValue);
      }
      case StyledFormInputType.UNIT_SELECT: {
        const prefix = preferenceMeta.fieldSlugPrefix;

        return preferenceMeta?.inputProps?.unitOptions?.some((option) => {
          const fieldSlug = `${prefix as string}_${option.value}`;

          const fieldPreference = preferenceValues?.find(
            (preferenceValue) => preferenceValue.preference.slug === fieldSlug
          );

          const fieldPreferenceValue = fieldPreference?.[
            preferenceMeta.dataType
          ] as string | number | null;

          return !isNil(fieldPreferenceValue);
        });
      }
      case StyledFormInputType.SELECT: {
        const fieldPreference = preferenceValues?.find(
          (preferenceValue) =>
            preferenceValue.preference.slug === preferenceMeta.fieldSlug
        );
        const fieldPreferenceValue = fieldPreference?.[
          preferenceMeta.dataType
        ] as string[];

        // loan types always has a default
        return (
          !isNil(fieldPreferenceValue) ||
          preferenceMeta.fieldSlug === 'loan_types'
        );
      }
      default: {
        const fieldPreference = preferenceValues?.find(
          (preferenceValue) =>
            preferenceValue.preference.slug === preferenceMeta.fieldSlug
        );
        const fieldPreferenceValue = fieldPreference?.[
          preferenceMeta.dataType
        ] as string | number | null;

        return !isNil(fieldPreferenceValue);
      }
    }
  });

  return filteredMeta.length;
};
