import { useTheme } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

export interface GradientTheme {
  id: string;
  colors: string[];
  x1?: string;
  x2?: string;
  y1?: string;
  y2?: string;
}

interface SvgGradientProps {
  gradientThemeKey: string;
  onIdGenerated: (id: string) => void;
}

const generateUniqueId = () =>
  `svg-gradient-${Math.random().toString(36).substr(2, 9)}`;

/**
 * Renders svg linearGradient with an ID that can be used as a gradient stroke or fill.
 * E.g.
 *   const [generatedId, setGeneratedId] = useState('');
 *
 *   return (
 *     <>
 *       <SvgGradient
 *         gradientThemeKey={gradientThemeKey}
 *         onIdGenerated={setGeneratedId}
 *       />
 *       <Icon as={icon} stroke={`url(#${generatedId})`} {...iconProps} />
 *     </>
 *   );
 */
export const SvgGradient = ({
  gradientThemeKey = 'blue',
  onIdGenerated,
}: SvgGradientProps) => {
  const theme = useTheme() as {
    svgGradients: { [key: string]: GradientTheme };
  };
  const gradient = theme.svgGradients[gradientThemeKey];

  const [uniqueId, setUniqueId] = useState('');

  useEffect(() => {
    const id = generateUniqueId();
    setUniqueId(id);
    if (onIdGenerated) {
      onIdGenerated(id);
    }
  }, [onIdGenerated]);

  return (
    <svg height="0" width="0">
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id={uniqueId}
        x1={gradient.x1}
        x2={gradient.x2}
        y1={gradient.y1}
        y2={gradient.y2}
      >
        {gradient.colors.map((color, index) => (
          <stop key={index} offset={index} stopColor={color} />
        ))}
      </linearGradient>
    </svg>
  );
};
