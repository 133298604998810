import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { ListingFragment } from '@client/graphql/__generated__/types';
import { ShareSteps } from '~/apps/consumer/components/Share/ShareSteps';
import {
  ModalTrigger,
  ModalTriggerBoxProps,
} from '~/common/components/Modal/ModalTrigger';
import { useSharePage } from '../Share/hooks/useSharePage';

type MLSConfigureModalTriggerProps = {
  listing: ListingFragment;
  children: React.ReactNode;
};

export const MLSConfigureModalTrigger = ({
  listing,
  children,
  ...props
}: MLSConfigureModalTriggerProps & ModalTriggerBoxProps) => (
  <ModalTrigger modal={MLSConfigureModal} modalProps={{ listing }} {...props}>
    {children}
  </ModalTrigger>
);

type MLSConfigureModalProps = {
  listing: ListingFragment;
  isOpen: boolean;
  onClose: () => void;
};

const MLSConfigureModal: React.FC<MLSConfigureModalProps> = ({
  listing,
  isOpen,
  onClose,
}) => {
  const {
    listingPackage,
    loading,
    isPdfViewerOpen,
    onPdfViewerClose,
    onPdfViewerOpen,
    agentRemarksStorefrontLink,
    agentRemarksBlurbString,
    webUrlLink,
  } = useSharePage(listing);

  return (
    <Modal
      isOpen={isOpen}
      size={{
        base: 'full',
        md: '2xl',
      }}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>MLS Instructions</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <ShareSteps
            agentRemarksBlurbString={agentRemarksBlurbString}
            agentRemarksStorefrontLink={agentRemarksStorefrontLink}
            isPdfViewerOpen={isPdfViewerOpen}
            listing={listing}
            listingPackage={listingPackage}
            loading={loading}
            webUrlLink={webUrlLink}
            onPdfViewerClose={onPdfViewerClose}
            onPdfViewerOpen={onPdfViewerOpen}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
