import {
  Avatar,
  Box,
  HStack,
  Image,
  Link,
  LinkBox,
  LinkOverlay,
  Text,
  VStack,
  keyframes,
} from '@chakra-ui/react';
import {
  OffersForCurrentUserFragment,
  PublicUserFragment,
  UserFragment,
} from '@client/graphql/__generated__/types';
import { motion } from 'framer-motion';
import React, { FC, memo } from 'react';
import { NavLink } from 'react-router-dom';
import {
  DEFAULT_PROPERTY_IMAGE_URL,
  getPropertySource,
} from '../../utils/listing.utils';
import { UserContactInfoPopover } from '../Activity/UserContactInfoCard';
import { UserAvatar } from '../User/UserAvatar';

interface DashboardItemProps {
  listing?: OffersForCurrentUserFragment['listing'];
  user?: PublicUserFragment | UserFragment;
  children: React.ReactNode;
  routeTo?: string;
  externalUrl?: string;
  rightElement?: React.ReactNode;
}

const pulse = keyframes`
  0% { box-shadow: 0 0 0 0 rgba(66, 153, 225, 0.4); }
  70% { box-shadow: 0 0 0 10px rgba(66, 153, 225, 0); }
  100% { box-shadow: 0 0 0 0 rgba(66, 153, 225, 0); }
`;

export const DashboardItem: FC<DashboardItemProps> = memo(
  function DashboardItem({
    listing,
    user,
    routeTo,
    externalUrl,
    children,
    rightElement,
  }) {
    return (
      <LinkBox
        _active={{
          bgColor: 'whiteAlpha.50 !important',
        }}
        _hover={{
          bgColor: 'whiteAlpha.100',
        }}
        as={HStack}
        borderBottom="1px solid"
        borderBottomColor="whiteAlpha.50"
        justify="space-between"
        px={5}
        py={2}
      >
        <HStack spacing={listing && user ? 5 : !listing && !user ? 0 : 2}>
          <Box position="relative">
            {listing ? (
              <>
                <Image
                  alt="Listing"
                  borderRadius="xs"
                  fallbackSrc={DEFAULT_PROPERTY_IMAGE_URL}
                  height="30px"
                  objectFit="cover"
                  objectPosition="center"
                  src={getPropertySource(listing?.mlsListing?.photos[0])}
                  width="42px"
                />
                {user && (
                  <Box
                    bottom="-12px"
                    position="absolute"
                    right="-12px"
                    zIndex={1}
                  >
                    <UserContactInfoPopover
                      trigger={
                        <motion.div whileHover={{ scale: 1.1 }}>
                          <UserAvatar
                            animation={`${pulse} 2s 3`}
                            bgColor="indigo.700"
                            borderWidth="1px"
                            height="24px"
                            size="xs"
                            user={user}
                            width="24px"
                          />
                        </motion.div>
                      }
                      user={user as PublicUserFragment}
                    />
                  </Box>
                )}
              </>
            ) : (
              user && (
                <Avatar
                  height="30px"
                  name={user?.fullName}
                  size="sm"
                  src={user?.profilePicture?.url}
                  width="30px"
                />
              )
            )}
          </Box>
          <VStack alignItems="stretch" spacing={0}>
            {children}
          </VStack>
        </HStack>

        {externalUrl ? (
          <LinkOverlay
            isExternal
            as={Link}
            href={externalUrl}
            textDecoration="none !important"
          >
            <Text
              color="whiteAlpha.500"
              display={{ base: 'inline-block', md: 'none', xl: 'inline-block' }}
            >
              {rightElement ?? 'View'}
            </Text>
          </LinkOverlay>
        ) : (
          <LinkOverlay as={NavLink} to={routeTo}>
            <Text
              color="whiteAlpha.500"
              display={{ base: 'inline-block', md: 'none', xl: 'inline-block' }}
            >
              {rightElement ?? 'View'}
            </Text>
          </LinkOverlay>
        )}
      </LinkBox>
    );
  }
);
