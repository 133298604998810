import { PrefillSellerPrefs } from '~/apps/consumer/pages/Storefront/OfferFlow/KeyTermsPage';
import { useOfferFlowContext } from '~/apps/consumer/pages/Storefront/OfferFlow/OfferFlowContext';
import { ValuesType } from '~/services/document/components/FormFields/types';
import { DocumentSection } from '../DocumentSection';

export interface KeyTermsSectionProps {
  prefillSellerPrefs?: PrefillSellerPrefs;
  inputValues: ValuesType;
  onChange: (values: ValuesType) => void;
}

export const KeyTermsSection = ({
  inputValues,
  prefillSellerPrefs,
  onChange,
}: KeyTermsSectionProps) => {
  const {
    mergedAutofillValues,
    setActiveMappingKey,
    offerFlowSections,
    latestOffer,
    formFieldsMap,
  } = useOfferFlowContext();

  return (
    <>
      {offerFlowSections.KEY_TERMS && (
        <DocumentSection
          documentSection={offerFlowSections.KEY_TERMS}
          fieldValues={inputValues}
          formFieldsMap={formFieldsMap}
          mergedAutofillValues={mergedAutofillValues}
          prefillSellerPrefs={prefillSellerPrefs}
          setActiveMappingKey={setActiveMappingKey}
          styleRootSectionCard={false}
          validations={latestOffer?.contract?.validations}
          onChange={onChange}
        />
      )}
    </>
  );
};
