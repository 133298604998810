import { useCallback, useEffect, useRef, useState } from 'react';

export enum KeyboardKeyCodes {
  MetaRight = 'MetaRight', // CMD or Window key
  MetaLeft = 'MetaLeft',
  OSRight = 'OSRight',
  OSLeft = 'OSLeft',
  Space = 'Space',
  Equal = 'Equal',
  Minus = 'Minus',
}

export interface KeyboardManager {
  activeKeyCode?: KeyboardKeyCodes;
  space: boolean;
  meta: boolean;
  equal: boolean;
  minus: boolean;
  addEventListener: (
    listener: (
      keyboardManager: Omit<KeyboardManager, 'addEventListener'>
    ) => void,
    key: string
  ) => void;
}

const TRACK_KEYBOARD_KEYS = Object.values(KeyboardKeyCodes) as Array<string>;

export const useKeyboardManager = (): KeyboardManager => {
  const eventListeners = useRef<Record<string, CallableFunction>>({});
  const [activeKeyCode, setActiveKeyCode] = useState<KeyboardKeyCodes>();
  const [metaKey, setMetaKey] = useState(false);

  const addEventListener = useCallback<KeyboardManager['addEventListener']>(
    (listener, key) => {
      eventListeners.current[key] = listener;
    },
    [eventListeners]
  );

  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      const code = e.code as KeyboardKeyCodes;
      const elementType = e.target?.constructor.name;

      setMetaKey(e.metaKey);

      // Only preventDefault when target is body
      if (
        !TRACK_KEYBOARD_KEYS.includes(code) ||
        !['HTMLBodyElement', 'HTMLDivElement', 'HTMLElement'].includes(
          elementType || ''
        )
      ) {
        return;
      }

      e.preventDefault();
      setActiveKeyCode(code);

      Object.keys(eventListeners.current).forEach((key) => {
        eventListeners.current[key]({
          activeKeyCode: code,
          space: code === KeyboardKeyCodes.Space,
          meta: e.metaKey,
          equal: code === KeyboardKeyCodes.Equal,
          minus: code === KeyboardKeyCodes.Minus,
        });
      });
    };

    const onKeyUp = (e: KeyboardEvent) => {
      if (e.metaKey || TRACK_KEYBOARD_KEYS.includes(e.code)) {
        setActiveKeyCode(undefined);
        setMetaKey(false);
      }
    };
    window.addEventListener('keydown', onKeyDown);
    window.addEventListener('keyup', onKeyUp);

    return () => {
      window.removeEventListener('keydown', onKeyDown);
      window.removeEventListener('keyup', onKeyUp);
    };
  }, [activeKeyCode, setActiveKeyCode]);

  return {
    activeKeyCode,
    space: activeKeyCode === KeyboardKeyCodes.Space,
    meta: metaKey,
    equal: activeKeyCode === KeyboardKeyCodes.Equal,
    minus: activeKeyCode === KeyboardKeyCodes.Minus,
    addEventListener,
  };
};
