import {
  DocumentVersionFieldFragment,
  FieldFragment,
} from '@client/graphql/__generated__/types';
import { useMemo } from 'react';
import { FormField } from '../components/FormFields/types';

export interface UseDocumentVersionFields
  extends Omit<DocumentVersionFieldFragment, '__typename'> {
  field: Omit<FieldFragment, '__typename' | 'autofill'>;
}

export const useDocumentVersionFormFields = (
  documentVersionFields:
    | UseDocumentVersionFields[]
    | UseDocumentVersionFields[][]
) => {
  return useMemo(() => {
    return documentVersionFields.flatMap(
      (
        documentVersionField:
          | UseDocumentVersionFields
          | UseDocumentVersionFields[],
        index: number
      ) => {
        if (Array.isArray(documentVersionField)) {
          return documentVersionField.flatMap((field) => {
            return {
              ...field,
              documentIndex: index,
            };
          });
        } else {
          return {
            ...documentVersionField,
            id: documentVersionField.id,
            documentIndex: 0,
            fieldType:
              documentVersionField.fieldType ||
              documentVersionField.field?.fieldType,
            mappingKey:
              documentVersionField.mappingKey ||
              documentVersionField.field?.mappingKey,
            inputFormat:
              documentVersionField.inputFormat ||
              documentVersionField.field?.inputFormat,
            label:
              documentVersionField.label || documentVersionField.field?.label,
            readOnly:
              typeof documentVersionField.readOnly === 'boolean'
                ? documentVersionField.readOnly
                : documentVersionField.field?.readOnly,
          };
        }
      }
    ) as FormField[];
  }, [documentVersionFields]);
};
