import {
  Button,
  Card,
  CardBody,
  CardHeader,
  HStack,
  Text,
} from '@chakra-ui/react';
import { FC, memo, ReactNode } from 'react';
import { CenterSpinner } from '~/common/components/CenterSpinner';

interface DashboardCardProps {
  title: string;
  children: React.ReactNode;
  titleTag?: ReactNode;
  isLoading?: boolean;
  noData?: boolean;
  onHeaderButtonClick?: () => void;
  headerRightElement?: ReactNode;
  emptyStateElement?: ReactNode; // New prop for empty state JSX
}

export const DashboardCard: FC<DashboardCardProps> = memo(
  function DashboardCard({
    title,
    titleTag,
    onHeaderButtonClick,
    children,
    isLoading,
    noData,
    headerRightElement,
    emptyStateElement,
  }) {
    return (
      <Card data-testid={`${title} Card`} minHeight="360px" width="100%">
        <CardHeader letterSpacing="0" pb={4}>
          <HStack justify="space-between">
            <HStack>
              <Text
                as="h2"
                color="white"
                fontSize="md"
                textTransform="capitalize"
              >
                {title}
              </Text>
              {titleTag}
            </HStack>
            {headerRightElement ||
              (onHeaderButtonClick && (
                <Button height="24px" size="xs" onClick={onHeaderButtonClick}>
                  + New
                </Button>
              ))}
          </HStack>
        </CardHeader>

        <CardBody flex="auto" maxHeight="260px" overflowY="auto" pt={0} px={0}>
          {isLoading ? (
            <CenterSpinner />
          ) : noData && emptyStateElement ? (
            emptyStateElement
          ) : noData ? (
            <NoData title={title} />
          ) : (
            children
          )}
        </CardBody>
      </Card>
    );
  }
);

const NoData = ({ title = '' }) => (
  <HStack
    alignItems="center"
    height="100%"
    justifyContent="center"
    width="100%"
  >
    <Text color="whiteAlpha.500">
      You don't currently have any {title.toLowerCase()}
    </Text>
  </HStack>
);
