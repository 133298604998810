import {
  Button,
  FormControl,
  FormHelperText,
  Icon,
  VStack,
} from '@chakra-ui/react';
import {
  FindMlsAgentFragment,
  LicenseType,
  PublicUserWithLicensesFragment,
} from '@client/graphql/__generated__/types';
import { Field, Form, Formik, FormikProps } from 'formik';
import { PenToolIcon } from 'lucide-react';
import { useCallback, useState } from 'react';
import { TextField } from '~/apps/consumer/components/Formik/TextField';
import { sanitizePhoneNumber } from '~/common/components/Inputs/PhoneNumberInputGroup';
import { MLSSelect } from '~/services/main/components/AgentSignUpFlow/MLSSelect';
import { ExistingUserCard } from './ExistingUserCard';
import { useOpenHouseSignContext } from './OpenHouseSignContext';
import { UnstyledFormLabel } from './UnstyledFormLabel';
import { validateForm } from './validate';

import * as Sentry from '@sentry/react';
import { FeatureKey } from '../../hooks/useIsFeatureEnabledForStateOrMls';
import { useSignGuestbookQueries } from './hooks/useSignGuestbookQueries';
import { PhoneNumberFormControlWithValidation } from './PhoneNumberFormControlWithValidation';

const initialValues = {
  phone: '',
  firstName: '',
  lastName: '',
  email: '',
  agentMlsId: '',
  mls: {
    id: '',
    OSN: '',
  },
};

const basicFormFields = [
  { name: 'firstName', label: 'First name', placeholder: 'John' },
  { name: 'lastName', label: 'Last name', placeholder: 'Doe' },
  { name: 'email', label: 'Email', placeholder: 'john.doe@example.com' },
  {
    name: 'agentMlsId',
    label: 'Agent MLS ID',
    placeholder: 'Agent MLS ID you use to log into your MLS',
  },
];

const getUserInputFromMlsAgent = (
  mlsAgentData: FindMlsAgentFragment['mlsAgent']
) => {
  if (!mlsAgentData) {
    return undefined;
  }

  const { MemberFirstName, MemberLastName, MemberEmail, MemberMobilePhone } =
    mlsAgentData;

  return {
    firstName: MemberFirstName || '',
    lastName: MemberLastName || '',
    email: MemberEmail || '',
    phone: sanitizePhoneNumber(MemberMobilePhone || ''),
  };
};

type FormState = {
  isLoading: boolean;
  foundUser: PublicUserWithLicensesFragment | undefined;
  mlsAgentData: FindMlsAgentFragment | undefined;
  creatingUser: boolean;
};

type InitAgentFormValues = {
  phone: string;
};

type NewAgentFormValues = {
  firstName: string;
  lastName: string;
  email: string;
  agentMlsId: string;
  mls: {
    id: string;
    OSN: string;
  };
};

type AgentFormValues = InitAgentFormValues & NewAgentFormValues;

export const AgentSignerFormTab = () => {
  const { openHouseData, redirectToStorefront, setSignUpCompleted } =
    useOpenHouseSignContext();
  const openHouseId = openHouseData?.openHouse.id;

  const {
    getUser,
    findAgentsByPhoneNumber,
    getOrCreateAgent,
    useSignGuestbookMutation,
  } = useSignGuestbookQueries();
  const [signOpenHouseGuestbook] = useSignGuestbookMutation;

  const [formState, setFormState] = useState<FormState>({
    isLoading: false,
    foundUser: undefined as PublicUserWithLicensesFragment | undefined,
    mlsAgentData: undefined as FindMlsAgentFragment | undefined,
    creatingUser: false,
  });

  const resetStates = useCallback(() => {
    // clear location state
    window.history.replaceState(null, '', window.location.href);

    setFormState({
      isLoading: false,
      foundUser: undefined,
      mlsAgentData: undefined,
      creatingUser: false,
    });
  }, []);

  const [formKey, setFormKey] = useState(0);
  const reloadForm = useCallback(() => {
    setFormKey((prevKey) => prevKey + 1);
    resetStates();
  }, [resetStates]);

  const lookUpUserByPhoneNumber = useCallback(
    async (values: AgentFormValues) => {
      setFormState((prev) => ({ ...prev, isLoading: true }));
      try {
        const phoneNumber = values.phone;
        const mlsOSN =
          openHouseData?.openHouse.listing.mlsListing?.mls
            ?.originatingSystemName;

        const { data: userDataFromDb } = await getUser({
          variables: { input: { phoneNumber: `+1${phoneNumber}` } },
        });

        const { data: userDataFromMls } = await findAgentsByPhoneNumber({
          variables: {
            input: {
              phoneNumber,
              mlsOSNs: mlsOSN ? [mlsOSN] : undefined,
            },
          },
        });

        const shouldCreateUser =
          !userDataFromDb?.getPublicUserWithLicenses &&
          !((userDataFromMls?.findMLSAgentsByPhoneNumber?.length ?? 0) > 0);

        setFormState((prev) => ({
          ...prev,
          foundUser: userDataFromDb?.getPublicUserWithLicenses,
          mlsAgentData: userDataFromMls?.findMLSAgentsByPhoneNumber?.[0],
          creatingUser: shouldCreateUser,
          isLoading: false,
        }));
      } catch (error) {
        Sentry.captureException(error as Error, {
          level: 'error',
          extra: {
            component: 'AgentSignerFormTab',
            function: 'lookUpUserByPhoneNumber',
            openHouseId,
            formState,
            values,
          },
        });
      } finally {
        setFormState((prev) => ({ ...prev, isLoading: false }));
      }
    },
    [
      openHouseData?.openHouse.listing.mlsListing?.mls?.originatingSystemName,
      getUser,
      findAgentsByPhoneNumber,
      openHouseId,
      formState,
    ]
  );

  const linkOrCreateUserWithSignGuestbook = useCallback(
    async (values: AgentFormValues) => {
      if (!openHouseId) {
        Sentry.captureException(
          new Error('Open house cannot be found when signing guestbook'),
          {
            level: 'error',
            extra: {
              component: 'AgentSignerFormTab',
              function: 'linkOrCreateUserWithSignGuestbook',
              openHouseId,
              formState,
              values,
            },
          }
        );

        return;
      }

      let userId = formState.foundUser?.id;
      const userInDatabase = !!userId;
      setFormState((prev) => ({ ...prev, isLoading: true }));

      try {
        // agent is already signed up in the MLS but not in our DB
        const mlsAgentData = formState.mlsAgentData?.mlsAgent;
        const userInputFromMls = getUserInputFromMlsAgent(mlsAgentData);

        // agent is not signed up in our DB
        if (!userInDatabase) {
          const licenseNumber = values.agentMlsId || mlsAgentData?.MemberMlsId;
          const { data } = await getOrCreateAgent({
            variables: {
              input: {
                firstName:
                  values.firstName || userInputFromMls?.firstName || '',
                lastName: values.lastName || userInputFromMls?.lastName || '',
                phone: `+1${values.phone || userInputFromMls?.phone || ''}`,
                email: values.email || userInputFromMls?.email || '',
                mlsId: values.mls.id || formState.mlsAgentData?.mls?.id || '',
                licenses: licenseNumber
                  ? [
                      {
                        licenseNumber,
                        licenseType: LicenseType.MLS,
                        states: [],
                      },
                    ]
                  : [],
              },
            },
          });

          if (data?.getOrCreateAgentByPhoneNumber?.id) {
            userId = data.getOrCreateAgentByPhoneNumber.id;
          }
        }

        if (userId) {
          await signOpenHouseGuestbook({
            variables: {
              input: {
                openHouseId,
                userId,
                sendNotification: !redirectToStorefront,
                metadata: {
                  // keep a snapshot of the metadata at the time of signing, for debugging purposes
                  form: 'openhouse-agent',
                  data: JSON.stringify({
                    userUuid: userId || '',
                    ...values,
                  }),
                },
              },
            },
          });
        }
      } catch (error) {
        Sentry.captureException(error as Error, {
          level: 'error',
          extra: {
            component: 'AgentSignerFormTab',
            function: 'linkOrCreateUserWithSignGuestbook',
            openHouseId,
            formState,
            values,
          },
        });
      } finally {
        //!!MARK: we'll go to complete screen no matter what
        setSignUpCompleted(true);
        setFormState((prev) => ({ ...prev, isLoading: false }));
      }
    },
    [
      formState,
      getOrCreateAgent,
      openHouseId,
      redirectToStorefront,
      setSignUpCompleted,
      signOpenHouseGuestbook,
    ]
  );

  const handleSubmit = async (values: AgentFormValues) => {
    if (!openHouseId) {
      return;
    }

    if (
      !formState.creatingUser &&
      !formState.foundUser &&
      !formState.mlsAgentData
    ) {
      await lookUpUserByPhoneNumber(values);
    } else {
      // Handle final form submission
      await linkOrCreateUserWithSignGuestbook(values);
    }
  };

  return (
    <Formik<AgentFormValues>
      key={formKey}
      initialValues={initialValues}
      validate={(values) => validateForm(values, !formState.creatingUser)}
      onSubmit={handleSubmit}
    >
      {(props: FormikProps<AgentFormValues>) => (
        <VStack
          alignItems="flex-start"
          as={Form}
          direction="column"
          spacing={6}
        >
          {(formState.foundUser || formState.mlsAgentData) &&
          !formState.creatingUser ? (
            <ExistingUserCard
              handleReset={() => {
                if (formState.foundUser) {
                  reloadForm();
                } else if (formState.mlsAgentData) {
                  setFormState((prev) => ({
                    ...prev,
                    mlsAgentData: undefined,
                    creatingUser: true,
                  }));
                }
              }}
              mlsAgentData={formState.mlsAgentData}
              user={formState.foundUser}
            />
          ) : (
            <AgentSignerFormControls formState={formState} props={props} />
          )}
          <Button
            isDisabled={formState.isLoading || !props.isValid}
            isLoading={formState.isLoading}
            leftIcon={
              formState.foundUser ? <Icon as={PenToolIcon} /> : undefined
            }
            type="submit"
            w="100%"
          >
            {formState.foundUser ? 'Sign the guestbook' : 'Next'}
          </Button>
        </VStack>
      )}
    </Formik>
  );
};

const AgentSignerFormControls = ({
  formState,
  props,
}: {
  formState: FormState;
  props: FormikProps<AgentFormValues>;
}) => {
  const { values, setFieldValue } = props;

  return (
    <>
      {!formState.foundUser && !formState.creatingUser && (
        <PhoneNumberFormControlWithValidation<AgentFormValues>
          {...props}
          extra={
            <FormHelperText>
              Please enter your phone number so we can match your agent profile
            </FormHelperText>
          }
        />
      )}
      {formState.creatingUser && (
        <>
          {basicFormFields.map((field) => (
            <FormControl key={field.name} isRequired>
              <UnstyledFormLabel>{field.label}</UnstyledFormLabel>
              <TextField
                as={Field}
                id={field.name}
                name={field.name}
                placeholder={field.placeholder}
              />
            </FormControl>
          ))}
          <FormControl isRequired>
            <UnstyledFormLabel>MLS</UnstyledFormLabel>
            <MLSSelect
              featureKeyFilter={FeatureKey.OPEN_HOUSES}
              id="mlsId"
              name="mlsId"
              placeholder="Please select"
              selectedMlsId={values.mls.id}
              onChange={(value) =>
                void setFieldValue('mls', {
                  id: value?.value || '',
                  OSN: value?.mlsOSN || '',
                })
              }
            />
          </FormControl>
        </>
      )}
    </>
  );
};
