import { Flex, Grid, StackProps, Text, VStack } from '@chakra-ui/react';
import { FC, memo } from 'react';

interface SimpleListProps extends StackProps {
  children: React.ReactNode;
}

export const SimpleList: FC<SimpleListProps> = memo(function SimpleList({
  children,
  ...props
}) {
  return (
    <VStack spacing={3} {...props}>
      {children}
    </VStack>
  );
});

interface SimpleListRowProps {
  label: string;
  children: React.ReactNode;
}

export const SimpleListRow: FC<SimpleListRowProps> = memo(
  function SimpleListRow({ label, children }) {
    return (
      <Grid
        data-testid="simple-list-row"
        gridTemplateColumns="1fr 1fr"
        width="100%"
      >
        <Text
          _light={{ color: 'gray.500' }}
          color="whiteAlpha.500"
          data-testid="simple-list-row-label"
          fontSize="inherit"
        >
          {label}
        </Text>
        <Flex
          data-testid="simple-list-row-value"
          flexGrow={1}
          justifyContent="flex-end"
        >
          {children}
        </Flex>
      </Grid>
    );
  }
);
