import { Flex, Image } from '@chakra-ui/react';
import {
  ListingFragment,
  PublicListingFragment,
} from '@client/graphql/__generated__/types';
import { getPropertySource } from '~/apps/consumer/utils/listing.utils';

interface StartOfferUploadListingDetailsProps {
  listing: ListingFragment | PublicListingFragment;
}

export const StartOfferUploadListingDetails = ({
  listing,
}: StartOfferUploadListingDetailsProps) => {
  return (
    <Flex direction="row" gap={4}>
      <Image
        borderRadius="4px"
        height="44px"
        src={getPropertySource(listing.mlsListing?.photos[0])}
        width="60px"
      />
      <Flex direction="column">
        <Flex textStyle="bold">
          {listing.mlsListing?.address?.streetNumberText}{' '}
          {listing.mlsListing?.address?.streetName}{' '}
          {listing.mlsListing?.address?.unit ?? ''}
        </Flex>
        <Flex textColor="whiteAlpha.600">
          {listing.mlsListing?.address?.city}{' '}
          {listing.mlsListing?.address?.state}{' '}
          {listing.mlsListing?.address?.postalCode}
        </Flex>
      </Flex>
    </Flex>
  );
};
