import { WarningIcon } from '@chakra-ui/icons';
import { Button, Heading, VStack } from '@chakra-ui/react';
import { SignatureFormFieldObject } from '@client/graphql/__generated__/types';
import { FC, memo, useMemo } from 'react';
import { useIsMobile } from '~/common/hooks/useIsMobile';
import { enumToLabel } from '../../utils/enums';
import { useDocumentMapperContext } from '../DocumentMapper/context';

interface ValidationsWidgetProps {
  signatureFormFieldsById: Record<string, SignatureFormFieldObject>;
}

export const ValidationsWidget: FC<ValidationsWidgetProps> = memo(
  function ValidationsWidget({ signatureFormFieldsById }) {
    const isMobile = useIsMobile();
    const { selectoRefs } = useDocumentMapperContext();

    const signatureFormFieldsWithoutSigner = useMemo(() => {
      return Object.values(signatureFormFieldsById).filter(
        (field) => !field.signerId
      );
    }, [signatureFormFieldsById]);

    return (
      <>
        {signatureFormFieldsWithoutSigner.length > 0 ? (
          <VStack
            alignItems="stretch"
            bgColor="whiteAlpha.900"
            marginTop="-10px"
            p={4}
            pb={{ base: 2, md: 4 }}
            position="relative"
            pt={{ base: 0, md: 4 }}
            spacing={{ base: 0, md: 2 }}
            width="100%"
          >
            <Heading
              as="h6"
              color="gray.700"
              fontSize={{ base: 'md', md: 'lg' }}
              mb={{ base: 0, md: 1 }}
              size="headline"
            >
              <strong>Validations</strong>
            </Heading>

            {signatureFormFieldsWithoutSigner.map((field) => (
              <Button
                key={field.id}
                alignItems={{ base: 'center', md: 'flex-start' }}
                colorScheme="red"
                justifyContent="flex-start"
                leftIcon={<WarningIcon />}
                textAlign="left"
                variant="link"
                onClick={() => {
                  const selecto = selectoRefs?.current[field.page];
                  const newFieldElem = document.querySelector<HTMLDivElement>(
                    `.moveable-form-field[data-formfieldid="${field.id}"]`
                  );

                  if (((!isMobile && selecto) || isMobile) && newFieldElem) {
                    newFieldElem.click();
                    newFieldElem.scrollIntoView({
                      behavior: 'smooth',
                      block: 'center',
                      inline: 'center',
                    });
                  }
                }}
              >
                {enumToLabel(field.type)} requires a signer
              </Button>
            ))}
          </VStack>
        ) : null}
      </>
    );
  }
);
