import { useListingsForOrganizationLazyQuery } from '@client/graphql/__generated__/main-operations';
import {
  ListingForOrganizationFragment,
  ListingsForOrganizationFilterArgs,
} from '@client/graphql/__generated__/types';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { MapRef } from 'react-map-gl';
import { useAppContext } from '~/services/main/contexts/AppContext';
import {
  DEFAULT_POSITION,
  ZOOM_LEVEL_MARKER_CHANGE,
} from '../MapSearch/constants';

export type OrganizationMapContextType = {
  mapRef: React.RefObject<MapRef>;
  listings: ListingForOrganizationFragment[];
  setListings: React.Dispatch<
    React.SetStateAction<ListingForOrganizationFragment[]>
  >;
  selectedListing: ListingForOrganizationFragment | null;
  setSelectedListing: React.Dispatch<
    React.SetStateAction<ListingForOrganizationFragment | null>
  >;
  loading: boolean;
  filters: Omit<ListingsForOrganizationFilterArgs, 'organizationId'>;
  updateFilters: (
    filters: Partial<Omit<ListingsForOrganizationFilterArgs, 'organizationId'>>
  ) => void;
  clearFilters: () => void;
};

export const OrganizationMapContext = createContext<OrganizationMapContextType>(
  {} as OrganizationMapContextType
);

export const OrganizationMapProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { selectedOrganization } = useAppContext();
  const mapRef = useRef<MapRef>(null);
  const [listings, setListings] = useState<ListingForOrganizationFragment[]>(
    []
  );
  const [selectedListing, setSelectedListing] =
    useState<ListingForOrganizationFragment | null>(null);

  const [filters, setFilters] = useState<
    Omit<ListingsForOrganizationFilterArgs, 'organizationId'>
  >({});

  const [listingsForOrganization, { loading }] =
    useListingsForOrganizationLazyQuery();

  const updateFilters = useCallback(
    (
      filters: Partial<
        Omit<ListingsForOrganizationFilterArgs, 'organizationId'>
      >
    ) => {
      setFilters((prev) => ({
        ...prev,
        ...filters,
      }));
    },
    [setFilters]
  );

  const clearFilters = () => {
    setFilters((prev) => ({
      bbox: prev.bbox,
    }));
  };

  useEffect(() => {
    void (async () => {
      if (!selectedOrganization) return;
      const { data } = await listingsForOrganization({
        variables: {
          filters: {
            ...filters,
            organizationId: selectedOrganization?.id || '',
          },
          perPage:
            (mapRef.current?.getZoom() || DEFAULT_POSITION.zoom) >
            ZOOM_LEVEL_MARKER_CHANGE
              ? 40
              : 100,
        },
      });

      const listings = data?.listingsForOrganization?.results || [];
      setListings(listings);
      if (selectedListing) {
        if (!listings.some((listing) => listing.id === selectedListing.id)) {
          setSelectedListing(null);
        }
      }
    })();
    // Don't need to run this when search Result changes. Only when the filters change.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, listingsForOrganization, selectedOrganization]);

  return (
    <OrganizationMapContext.Provider
      value={{
        mapRef,
        listings,
        setListings,
        selectedListing,
        setSelectedListing,
        loading,
        filters,
        updateFilters,
        clearFilters,
      }}
    >
      {children}
    </OrganizationMapContext.Provider>
  );
};

export const useOrganizationMapContext = () => {
  return useContext(OrganizationMapContext);
};
