import { WarningTwoIcon } from '@chakra-ui/icons';
import {
  Box,
  CardHeaderProps,
  Icon,
  Text,
  Tooltip,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import {
  ContractValidationFragment,
  RuleType,
} from '@client/graphql/__generated__/types';
import { FC, memo } from 'react';
import { useFormatFieldValue } from '~/services/document/components/Fields/hooks/useFormatFieldValue';
import { useGetValidationDisplayLabel } from '~/services/document/components/FormFields/hooks/useFieldValidationLabel';
import { FormField } from '~/services/document/components/FormFields/types';
import { useValidationsColor } from '~/services/document/hooks/useValidationsColor';
import { SimpleList, SimpleListRow } from '../Lists/SimpleList';

interface OfferValidationsProps {
  validations?: ContractValidationFragment[];
  cardHeaderProps?: CardHeaderProps;
}

interface OfferValidationDisplayProps {
  validation: ContractValidationFragment;
  showInputValue?: boolean;
  showIcon?: boolean;
}

const FormFieldFormatValue = ({
  field,
  value,
}: {
  field: Partial<FormField>;
  value?: string | readonly string[] | number | boolean | null;
}) => {
  const formattedValue = useFormatFieldValue({ field, value });

  return <>{formattedValue}</>;
};

export const OfferValidationDisplay: FC<OfferValidationDisplayProps> = memo(
  function OfferValidationDisplay({
    validation,
    showInputValue = true,
    showIcon = true,
  }) {
    const validationColor = useValidationsColor(validation.results);
    const label = useGetValidationDisplayLabel(validation);
    const formField: Partial<FormField> = {
      ...validation.field,
      label: label,
      inputFormat:
        validation.documentVersionField.inputFormat ||
        validation.field.inputFormat,
    };
    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
      <>
        {validation.results.map((result, i) => (
          <Tooltip
            key={i}
            isOpen={isOpen}
            label={
              <>
                {validation.results?.map((validation, idx) => (
                  <VStack key={idx} alignItems="stretch" spacing={0}>
                    <Text
                      color={
                        validation.rule.ruleType === RuleType.WARNING
                          ? 'yellow.300'
                          : 'red.200'
                      }
                    >
                      {validation.message}
                    </Text>
                    {validation.validationValue && (
                      <Text>
                        <Box as="span" color="text">
                          Suggested:
                        </Box>{' '}
                        <FormFieldFormatValue
                          field={formField}
                          value={validation.validationValue}
                        />
                      </Text>
                    )}
                  </VStack>
                ))}
              </>
            }
            onClose={onClose}
            onOpen={onOpen}
          >
            <Text
              color={validationColor}
              onClick={onOpen}
              onMouseEnter={onOpen}
              onMouseLeave={onClose}
            >
              {showIcon && <Icon as={WarningTwoIcon} mr={1} />}
              {showInputValue && (
                <FormFieldFormatValue
                  field={formField}
                  value={result.inputValue}
                />
              )}
            </Text>
          </Tooltip>
        ))}
      </>
    );
  }
);

export const OfferValidations: FC<OfferValidationsProps> = memo(
  function OfferValidations({ validations }) {
    if (!validations) {
      return null;
    }

    const ValidationDisplayRow = ({
      index,
      validation,
    }: {
      index: number;
      validation: ContractValidationFragment;
    }) => {
      const label = useGetValidationDisplayLabel(validation);

      return (
        <SimpleListRow key={index} label={label}>
          <OfferValidationDisplay validation={validation} />
        </SimpleListRow>
      );
    };

    return (
      <SimpleList>
        {validations.map((validation, i) => (
          <ValidationDisplayRow key={i} index={i} validation={validation} />
        ))}
      </SimpleList>
    );
  }
);
