import { Card, CardBody, CardHeader, Flex } from '@chakra-ui/react';
import {
  PublicPreferenceObject,
  PublicPreferenceValueFragment,
  StorefrontSubscriptionReason,
} from '@client/graphql/__generated__/types';
import { useSellerPreferenceGroups } from '~/apps/consumer/hooks/useSellerPreferenceGroups';
import { useStorefrontContext } from '~/apps/consumer/pages/Storefront/StorefrontContext';
import { SignGuestbookOverlay } from '../SignGuestbookOverlay';
import { CardDetailsField } from '../StorefrontCard';
import { getDetailValue } from './helpers';

export const SellerPreferencesDetails = () => {
  const { listing, preferences, isGatedBehindSigningGuestbook } =
    useStorefrontContext();

  const actualPreferenceValues = listing?.listingPreferences;

  const preferenceGroups = useSellerPreferenceGroups(
    listing,
    preferences as PublicPreferenceObject[]
  );

  const hasVisibleSellerPrefs = preferenceGroups.some((group) =>
    group.preferences.some((preference) => {
      if (preference.fieldSlug === 'loan_types') {
        // As of 2024-09-04, this is always derived from the mls listing, so it's not a real
        // seller/listing-agent preference value that we want to gate on.
        return false;
      }
      const detailValue = getDetailValue(
        preference,
        actualPreferenceValues,
        listing
      );

      return detailValue && detailValue !== '-';
    })
  );
  let preferenceValues: PublicPreferenceValueFragment[];
  if (isGatedBehindSigningGuestbook) {
    preferenceValues = [];
  } else {
    preferenceValues = actualPreferenceValues;
  }

  return (
    <Flex direction="column" gap={4} position="relative">
      {hasVisibleSellerPrefs && (
        <SignGuestbookOverlay
          blurPx={2}
          borderRadius="md"
          height="100%"
          left={{ base: '0', md: '50%' }}
          position="absolute"
          reason={StorefrontSubscriptionReason.ACCESS_SELLER_PREFERENCES}
          width={{ base: '100%', md: '50%' }}
        />
      )}
      {preferenceGroups.map((group) => {
        const showTitle = group.title !== 'Personal property not conveying';

        return (
          <Card key={group.title} backdropFilter="none">
            {showTitle && <CardHeader>{group.title}</CardHeader>}
            <CardBody
              display="flex"
              flexDirection="column"
              gap={{ base: 10, md: 0 }}
              lineHeight={1}
            >
              {group.preferences.map((preference) => {
                const value = getDetailValue(
                  preference,
                  preferenceValues,
                  listing
                );

                return (
                  <CardDetailsField
                    key={preference.name}
                    label={preference.name}
                    value={value}
                  />
                );
              })}
            </CardBody>
          </Card>
        );
      })}
    </Flex>
  );
};
