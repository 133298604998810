import { Center, Flex, Link, Text, VStack } from '@chakra-ui/react';
import { FC, ReactNode } from 'react';
import { useDropzone } from 'react-dropzone';

interface UploadDropzoneProps {
  dropzoneContent?: ReactNode;
  onDrop: (acceptedFiles: File[]) => void;
  allowMultiple?: boolean;
  borderColor?: string;
}

export const UploadDropzone: FC<UploadDropzoneProps> = ({
  onDrop,
  allowMultiple = true,
  dropzoneContent,
  borderColor = 'dropzone.border',
}) => {
  const { getRootProps, getInputProps, isDragAccept } = useDropzone({
    // FileSystemAccess API seems to have limited support.
    // (https://github.com/react-dropzone/react-dropzone#file-dialog-cancel-callback)
    // Disabling this for now so that Playwright can actually listen to the 'filechooser' event
    // from the dropzone.
    // https://github.com/react-dropzone/react-dropzone/discussions/1339
    useFsAccessApi: false,
    onDrop,
    accept: {
      'application/pdf': ['.pdf'],
    },
    multiple: allowMultiple,
  });

  const defaultDropzoneContent = (
    <VStack p={4}>
      <Text>Drag here</Text>
      <Text color="whiteAlpha.600">or</Text>
      <Link textDecor="underline">Upload PDF</Link>
    </VStack>
  );

  return (
    <Flex flex={1}>
      <Center
        _focusVisible={{ outline: 'none' }}
        _hover={{ cursor: 'pointer', background: 'whiteAlpha.300' }}
        bgColor={isDragAccept ? 'whiteAlpha.300' : 'none'}
        border="1px"
        borderColor={borderColor}
        borderRadius="8px"
        borderStyle="dashed"
        width="100%"
        {...getRootProps()}
      >
        {dropzoneContent || defaultDropzoneContent}
        <input {...getInputProps()} />
      </Center>
    </Flex>
  );
};
