import { useToast } from '@chakra-ui/react';
import {
  useFollowListingMutation,
  useIsFollowingListingQuery,
  usePublicListingQuery,
  useUnfollowListingMutation,
} from '@client/graphql/__generated__/main-operations';
import { StorefrontSubscriptionReason } from '@client/graphql/__generated__/types';
import { PropsWithChildren, useCallback } from 'react';
import { useParams } from 'react-router';
import { ListingActivityContext } from '../../contexts/ListingActivityContext';

export const ALERT_TOOLTIP_WAS_VIEWED = '@alert-tooltip-viewed';
export const ALERT_TOOLTIP_HAS_FOLLOWED_LISTING = '@alert-tooltip-followed';
export const ALERT_TOOLTIP_CACHE_VALUE = 'true'; // localStorage doesn't store booleans

export const ListingActivityProvider = ({ children }: PropsWithChildren) => {
  const toast = useToast();

  // this Provider can be placed on pages where the listing is defined by a propertySlug in params (storefront)
  // as well as pages where the listing is defined by a listingId in the params (storefront preview)
  const { listingId: listingIdParam, propertySlug: propertySlugParam } =
    useParams<string>();

  const { data: listingData, refetch: refetchListing } = usePublicListingQuery({
    variables: {
      input: {
        propertySlug: propertySlugParam || '',
        listingId: listingIdParam || '',
      },
    },
    skip: !propertySlugParam && !listingIdParam,
    // NOTE: avoid redundant refetches when loading components under ListingActivityContext
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });

  const listing = listingData?.publicListing;
  const listingId = listingIdParam || listing?.id;

  const {
    data: useIsFollowingListingData,
    refetch: refetchIsFollowingListing,
  } = useIsFollowingListingQuery({
    variables: {
      input: {
        id: listingId ?? '',
      },
    },
    skip: !listingId,
  });

  const refreshFollowState = useCallback(
    (options: { followed: boolean }) => {
      if (options.followed) {
        localStorage.setItem(
          ALERT_TOOLTIP_HAS_FOLLOWED_LISTING,
          ALERT_TOOLTIP_CACHE_VALUE
        );

        window.dispatchEvent(new Event('storage'));
      }

      void refetchListing();
      void refetchIsFollowingListing();
    },
    [refetchIsFollowingListing, refetchListing]
  );

  const [followListingMutation] = useFollowListingMutation({
    onError: ({ message }) => {
      toast({
        description: message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    },
    onCompleted() {
      refreshFollowState({ followed: true });
    },
  });

  const [unfollowListingMutation] = useUnfollowListingMutation({
    onError: ({ message }) => {
      toast({
        description: message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    },
    onCompleted() {
      refreshFollowState({ followed: false });
    },
  });

  const followListing = useCallback(
    async (reason: StorefrontSubscriptionReason) => {
      await followListingMutation({
        variables: {
          input: {
            id: listingId ?? '',
            reason,
          },
        },
      });
    },
    [listingId, followListingMutation]
  );

  const unfollowListing = useCallback(async () => {
    await unfollowListingMutation({
      variables: {
        input: {
          id: listingId ?? '',
        },
      },
    });
  }, [listingId, unfollowListingMutation]);

  const value = {
    followListing,
    followerCount: listing?.followerCount || 0,
    guestbookSignersCount: listing?.guestbookSignersCount || 0,
    unfollowListing,
    isFollowingListing: Boolean(
      useIsFollowingListingData?.isFollowingListing.isFollowingListing
    ),
  };

  return (
    <ListingActivityContext.Provider value={value}>
      {children}
    </ListingActivityContext.Provider>
  );
};
