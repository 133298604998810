import { Flex, FormControl, FormLabel } from '@chakra-ui/react';
import { TextField } from '../../../Formik/TextField';
import { FormCard } from './FormCard';

interface CoBuyerFormCardProps {
  onRemove: () => Promise<void> | void;
}

export const CoBuyerFormCard = ({ onRemove }: CoBuyerFormCardProps) => {
  return (
    <FormCard showDeleteButton label="Co-buyer" onDelete={onRemove}>
      <Flex direction="column" gap={2}>
        <FormControl isRequired>
          <FormLabel>Co-buyer name</FormLabel>
          <TextField name="coBuyerName" />
        </FormControl>
        <FormControl isRequired>
          <FormLabel>Co-buyer email</FormLabel>
          <TextField name="coBuyerEmail" />
        </FormControl>
      </Flex>
    </FormCard>
  );
};
