import { ListingObject } from '@client/graphql/__generated__/types';
import { useOutletContext } from 'react-router';

export interface StorefrontSetupWizardOutletContextType {
  listing: ListingObject;
  refetch: () => Promise<void>;
}

export const useStorefrontSetupWizardOutletContext = () => {
  return useOutletContext<StorefrontSetupWizardOutletContextType>();
};
