import { Box } from '@chakra-ui/react';
import {
  ContractSignatureFieldsArgs,
  FieldType,
  SignatureFormFieldObject,
} from '@client/graphql/__generated__/types';
import { mapById } from '@common/utils/object';
import noop from 'lodash/noop';
import { FC, memo, useEffect, useMemo } from 'react';
import { useIsMobile } from '~/common/hooks/useIsMobile';
import {
  DocumentMapper,
  DocumentMapperLeftPane,
  DocumentMapperRightPane,
} from '../DocumentMapper';
import { DocumentsThumbnailsWidget } from '../DocumentMapperWidgets/DocumentsThumbnailsWidget';
import {
  FieldsWidget,
  SIGNATURE_FIELD_TYPES,
} from '../DocumentMapperWidgets/FieldsWidget';
import { FormField, ValuesType } from '../FormFields/types';
import { PDFFile } from '../PDFScrollable/types';
import { EditSignatureWidget } from './EditSignatureWidget';
import { ValidationsWidget } from './ValidationsWidget';
import { useDocumentSignatureMapper } from './hooks/useDocumentSignatureMapper';
export interface Signer {
  email: string;
  fullName: string;
  id: string;
}

interface DocumentSignatureMapperProps {
  contractSignatureFieldArgs: ContractSignatureFieldsArgs;
  signers: Signer[];
  pdfFiles: PDFFile[];
  values?: ValuesType;
  formFields?: FormField[];
  onValuesChange?: (values: ValuesType) => void;
  onSignatureFormFieldsChange?: (
    signatureFormFields: SignatureFormFieldObject[]
  ) => void;
}

export const DocumentSignatureMapper: FC<DocumentSignatureMapperProps> = memo(
  function DocumentSignatureMapper({
    signers,
    values,
    onValuesChange,
    contractSignatureFieldArgs,
    pdfFiles,
    onSignatureFormFieldsChange,
    formFields,
  }) {
    const {
      signatureDocumentVersionFieldsById,
      signatureFieldsById,
      setSignatureFormFieldsById,
      signatureFormFieldsById,
      signersById,
      setPagesMetadata,
      onFormFieldsChange,
      onFormFieldDelete,
    } = useDocumentSignatureMapper({
      contractSignatureFieldArgs,
      signers,
    });
    const isMobile = useIsMobile();
    const editFormFieldsById = useMemo(() => {
      return mapById(
        formFields?.filter(
          ({ fieldType }) =>
            ![
              FieldType.SIGNATURE,
              FieldType.SIGNED_DATE,
              FieldType.INITIAL,
            ].includes(fieldType)
        ) || []
      );
    }, [formFields]);

    useEffect(() => {
      onSignatureFormFieldsChange &&
        onSignatureFormFieldsChange(Object.values(signatureFormFieldsById));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [signatureFormFieldsById]);

    return signatureDocumentVersionFieldsById ? (
      <DocumentMapper
        editFormFieldsById={editFormFieldsById}
        formFieldsById={signatureDocumentVersionFieldsById}
        hideRightPane={isMobile}
        isSaveLoading={false}
        leftPaneWidth={250}
        originalFormFieldsById={signatureDocumentVersionFieldsById}
        pdfFiles={pdfFiles}
        rightPaneWidth={80}
        updateValues={onValuesChange || noop}
        values={values || {}}
        onFormFieldChange={onFormFieldsChange}
        onFormFieldDelete={onFormFieldDelete}
        onPagesMetadataChange={setPagesMetadata}
      >
        <DocumentMapperLeftPane>
          <Box p={4}>
            <FieldsWidget
              fieldTypes={SIGNATURE_FIELD_TYPES}
              gridTemplateColumns="1fr"
            />
          </Box>

          <ValidationsWidget
            signatureFormFieldsById={signatureFormFieldsById}
          />
        </DocumentMapperLeftPane>

        {!isMobile && (
          <DocumentMapperRightPane>
            <DocumentsThumbnailsWidget />
          </DocumentMapperRightPane>
        )}

        <EditSignatureWidget
          setSignatureFormFieldsById={setSignatureFormFieldsById}
          signatureDocumentVersionFieldsById={
            signatureDocumentVersionFieldsById
          }
          signatureFieldsById={signatureFieldsById}
          signatureFormFieldsById={signatureFormFieldsById}
          signers={signers}
          signersById={signersById}
        />
      </DocumentMapper>
    ) : null;
  }
);
