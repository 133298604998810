import { Flex, Grid, Skeleton, Text, VStack } from '@chakra-ui/react';
import { usePublicAgentUserListingsQuery } from '@client/graphql/__generated__/main-operations';
import {
  MlsListingFragment,
  PublicUserWithLicensesFragment,
} from '@client/graphql/__generated__/types';
import { Home } from 'lucide-react';
import { useState } from 'react';
import { Navigate } from 'react-router';
import { CenterSpinner } from '~/common/components/CenterSpinner';
import { SearchInput } from '~/common/components/Inputs/SearchInput';
import { useInfiniteQuery } from '~/services/document/hooks';
import { EmptyState } from '../../components/EmptyState';
import { PublicListingCard } from '../../components/Listings/ListingCard/PublicListingCard';

type Props = {
  // NOTE: if it's undefined, it means that we still loading the data
  user: PublicUserWithLicensesFragment | undefined;
};

export const PublicUserListings = ({ user }: Props) => {
  const [search, setSearch] = useState('');
  const [triggerRef, { data, loading, error }] = useInfiniteQuery(
    usePublicAgentUserListingsQuery,
    {
      variables: {
        input: {
          userId: user?.id ?? '',
          filters: {
            search,
          },
        },
      },
      skip: !user,
    }
  );

  if (error) {
    return <Navigate to="/not-found" />;
  }

  const hasListings = !!data?.publicAgentUserListings?.results?.length;

  const loader = search ? <CenterSpinner /> : <Skeleton h="70vh" w="full" />;

  const listingCards =
    !data && loading ? (
      loader
    ) : hasListings ? (
      <>
        <Grid
          gap={{ base: 2, md: 4 }}
          templateColumns={{ base: '1fr', md: 'repeat(3, 1fr)' }}
          templateRows={{ base: 'auto', md: 'repeat(3, 1fr)' }}
          w="100%"
        >
          {data?.publicAgentUserListings?.results?.map((listing) => (
            <PublicListingCard
              key={listing.id}
              fullWidth={true}
              listing={listing}
              mlsListing={listing.mlsListing as MlsListingFragment}
              showSubscribers={false}
            />
          ))}

          <div ref={triggerRef} />
        </Grid>

        {loading && <CenterSpinner />}
      </>
    ) : search ? (
      <EmptySearchListings />
    ) : (
      <EmptyListings />
    );

  return (
    <VStack alignItems="flex-start" spacing={3} w="full">
      <Flex
        direction={{ base: 'column', md: 'row' }}
        gap={2}
        justifyContent="space-between"
        w="full"
      >
        <Text fontSize="2xl" fontWeight="medium">
          Listings
        </Text>
        <SearchInput
          maxW={{ base: 'full', md: '300px' }}
          onChange={(e) => setSearch(e.target.value)}
        />
      </Flex>
      {listingCards}
    </VStack>
  );
};

const EmptyListings = () => (
  <EmptyState
    headerText="This agent has no active listings right now"
    icon={Home}
    minH="70vh"
    subText="We'll update this page on a regular basis. Check back later!"
    w="full"
  />
);

const EmptySearchListings = () => (
  <EmptyState
    headerText="No active or under contract listings that match your search"
    icon={Home}
    minH="70vh"
    w="full"
  />
);
