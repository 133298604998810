import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  HStack,
  Spacer,
  Text,
  VStack,
} from '@chakra-ui/react';
import { Link, Mail, Signature, Upload, Users } from 'lucide-react';
import { useState } from 'react';
import { Navigate, NavLink } from 'react-router-dom';
import { CardEmptyState } from '~/apps/consumer/components/CardEmptyState';
import { UserAvatar } from '~/apps/consumer/components/User/UserAvatar';
import {
  listingOffersPath,
  offerDetailsPath,
} from '~/apps/consumer/utils/routes.utils';
import { formatToCurrency } from '~/services/document/utils/number';
import { useAppContext } from '~/services/main/contexts/AppContext';
import { useListingSetupContext } from '../ListingSetupContext';
import { ShareWithSellerButton } from '../Offers/ShareWithSellerButton';
import { UploadOfferByListingAgentButton } from '../Offers/UploadOfferByListingAgentButton';
import { CollectOffersByEmailModalTrigger } from './CollectOffersByEmailModalTrigger';
import { MLSConfigureModalTrigger } from './MLSConfigureModalTrigger';

const ICON_PROPS = {
  size: '16px',
};

const SMALL_ICON_PROPS = {
  size: '14px',
};

const ACTION_BUTTON_STYLE = {
  _hover: {
    bg: 'whiteAlpha.100',
  },
  bg: 'whiteAlpha.50',
  color: 'cyan.500',
  variant: 'ghost',
  size: 'sm',
};

const ActionButton = ({ children, ...props }) => (
  <Button {...ACTION_BUTTON_STYLE} {...props}>
    {children}
  </Button>
);

export const OffersOverviewCard = () => {
  const { user } = useAppContext();
  const { listing } = useListingSetupContext();
  const { offersWithContracts } = useListingSetupContext();

  const [isProcessingUploadedOffer, setIsProcessingUploadedOffer] =
    useState(false);

  const listingId = listing.id;

  if (!listingId) {
    return <Navigate to="/not-found" />;
  }

  const renderActionButtons = (showText = false) => (
    <>
      {showText ? (
        <MLSConfigureModalTrigger listing={listing}>
          <Button
            borderRadius="sm"
            h="40px"
            leftIcon={<Link {...SMALL_ICON_PROPS} />}
            minW="36px"
            {...ACTION_BUTTON_STYLE}
          >
            Add to MLS
          </Button>
        </MLSConfigureModalTrigger>
      ) : (
        <ShareWithSellerButton variant="ghost">
          <Users {...ICON_PROPS} />
        </ShareWithSellerButton>
      )}
      {user?.offerCollectionEmail && (
        <CollectOffersByEmailModalTrigger
          offerCollectionEmail={user.offerCollectionEmail}
        >
          {showText ? (
            <Button
              leftIcon={<Mail {...SMALL_ICON_PROPS} />}
              {...ACTION_BUTTON_STYLE}
            >
              Collect by Email
            </Button>
          ) : (
            <Button size="sm" variant="ghost">
              <Mail {...ICON_PROPS} />
            </Button>
          )}
        </CollectOffersByEmailModalTrigger>
      )}
      <UploadOfferByListingAgentButton
        isProcessingUploadedOffer={isProcessingUploadedOffer}
        setIsProcessingUploadedOffer={setIsProcessingUploadedOffer}
        {...(showText
          ? {
              leftIcon: <Upload {...SMALL_ICON_PROPS} />,
              ...ACTION_BUTTON_STYLE,
            }
          : { variant: 'ghost' })}
      >
        {showText ? 'Upload Offer to Validate' : <Upload {...ICON_PROPS} />}
      </UploadOfferByListingAgentButton>
    </>
  );

  if (offersWithContracts.length === 0) {
    return (
      <Card
        alignItems="center"
        borderRadius="sm"
        display="flex"
        flex={1}
        justifyContent="center"
        minH="340px"
        px={{ base: 6, md: 16 }}
      >
        <CardEmptyState
          description="Fully validate your offers and put them in a beautiful view to share with your seller"
          icon={Signature}
          title="Collect Offers For Your Listing"
        />
        <HStack>{renderActionButtons(true)}</HStack>
      </Card>
    );
  }

  return (
    <Card borderRadius="sm" flex={1} minH="340px">
      <CardHeader alignItems="center" display="flex" py={2}>
        <Text>Offers</Text>
        <Spacer />
        {renderActionButtons()}
      </CardHeader>
      <CardBody py={0}>
        <VStack>
          {offersWithContracts
            .slice(0, 4)
            .map(({ offer, buyersAgentName, offerAmount }) => (
              <HStack
                key={offer.id}
                borderBottom="1px solid"
                borderBottomColor="whiteAlpha.50"
                pb={2}
                w="full"
              >
                <UserAvatar size="sm" user={offer.buyersAgent?.user} />
                <VStack align="flex-start" spacing={0}>
                  <Text>{buyersAgentName}</Text>
                  <Text color="whiteAlpha.500">
                    {formatToCurrency(offerAmount, 0)}
                  </Text>
                </VStack>
                <Spacer />
                <ActionButton
                  as={NavLink}
                  bg="transparent"
                  color="whiteAlpha.700"
                  to={offerDetailsPath(listingId, offer.id, location.search)}
                >
                  View
                </ActionButton>
              </HStack>
            ))}
        </VStack>
      </CardBody>
      <CardFooter py={2}>
        <ActionButton
          _hover={{
            bg: 'transparent',
          }}
          as={NavLink}
          bg="transparent"
          color="whiteAlpha.700"
          textTransform="uppercase"
          to={listingOffersPath(listingId)}
          w="full"
        >
          See All
        </ActionButton>
      </CardFooter>
    </Card>
  );
};
