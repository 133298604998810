import { Roles, UserObject } from '@client/graphql/__generated__/types';

export const getIsRegisteredAgent = (user: UserObject | undefined) => {
  return !!(
    ((user?.roles?.length ?? 0) > 0 && (user?.licenses?.length ?? 0) > 0) ||
    user?.roles?.includes(Roles.ASSISTANT)
  );
};

export const getIsRegisteredConsumer = (user: UserObject | undefined) => {
  return (
    user?.roles?.includes(Roles.CONSUMER) &&
    !(
      user?.roles.includes(Roles.AGENT) || user?.roles.includes(Roles.ASSISTANT)
    )
  );
};
