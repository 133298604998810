import {
  Button,
  Flex,
  Heading,
  SimpleGrid,
  useDisclosure,
} from '@chakra-ui/react';
import {
  useCreateOpenHouseMutation,
  useDeleteOpenHouseMutation,
  useListingOpenHousesQuery,
  useUpdateOpenHouseMutation,
} from '@client/graphql/__generated__/main-operations';
import {
  OpenHouseCardFragment,
  OpenHouseSource,
} from '@client/graphql/__generated__/types';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { DoorOpen } from 'lucide-react';
import { useCallback, useEffect, useState } from 'react';
import { Navigate, useParams, useSearchParams } from 'react-router-dom';
import { ListingActivityProvider } from '~/apps/consumer/components/Activity/ListingActivityProvider';
import { EmptyState } from '~/apps/consumer/components/EmptyState';
import { OpenHouseCard } from '~/apps/consumer/components/Listings/ListingSetup/OpenHouses/OpenHouseCard';
import { CenterSpinner } from '~/common/components/CenterSpinner';
import { useCheckPermission } from '~/common/hooks/useCheckPermission';
import { useProdFeatureFlagEnabled } from '~/common/hooks/useProdFeatureFlagEnabled';
import { useListingSetupContext } from '../ListingSetupContext';
import { ListingSetupContentWrapper } from '../ListingSetupWrapperComponents';
import {
  ListingOpenHouseFormModal,
  OpenHouseFormData,
} from './ListingOpenHouseFormModal';

dayjs.extend(utc);

export function OpenHousesPage() {
  const { listingId } = useParams<string>();
  const openHousesEnabled = useProdFeatureFlagEnabled('open-houses');
  const { listing, listingLoading } = useListingSetupContext();

  const { isAuthorized } = useCheckPermission('listings.isAdmin', {}, [
    listing.id,
  ]);
  const userCanEditOpenHouses = isAuthorized ?? false;

  const now = dayjs();

  const { isOpen, onOpen, onClose } = useDisclosure({
    onClose: () => {
      setEditingOpenHouse(null);
    },
  });
  const [editingOpenHouse, setEditingOpenHouse] =
    useState<OpenHouseCardFragment | null>(null);
  const handleEditOpenHouse = useCallback(
    (openHouse: OpenHouseCardFragment) => {
      setEditingOpenHouse(openHouse);
      onOpen();
    },
    [onOpen, setEditingOpenHouse]
  );

  const {
    loading: openHousesLoading,
    data,
    refetch,
  } = useListingOpenHousesQuery({
    variables: { input: { listingId: listingId || '' } },
    skip: !listingId || !openHousesEnabled,
  });

  const [createOpenHouse] = useCreateOpenHouseMutation();
  const handleCreateOpenHouse = useCallback(
    async (formData: OpenHouseFormData) => {
      if (!listingId) return;

      await createOpenHouse({
        variables: {
          input: {
            listingId,
            ...formData,
          },
        },
      });
      await refetch();
      onClose();
    },
    [createOpenHouse, listingId, refetch, onClose]
  );

  const [updateOpenHouse] = useUpdateOpenHouseMutation();
  const handleUpdateOpenHouse = useCallback(
    async (formData: OpenHouseFormData) => {
      if (!editingOpenHouse) return;

      await updateOpenHouse({
        variables: {
          input: {
            id: editingOpenHouse.id,
            ...formData,
          },
        },
      });
      onClose();
    },
    [editingOpenHouse, updateOpenHouse, onClose]
  );

  const [deleteOpenHouse] = useDeleteOpenHouseMutation();
  const handleDeleteOpenHouse = useCallback(
    async (openHouseId: string) => {
      await deleteOpenHouse({
        variables: {
          input: { id: openHouseId },
        },
      });
      await refetch();
    },
    [deleteOpenHouse, refetch]
  );

  const [searchParams, setSearchParams] = useSearchParams();
  const shouldOpenCreateModal = searchParams.get('create') === 'true';

  useEffect(() => {
    if (shouldOpenCreateModal) {
      onOpen();
    }
  }, [shouldOpenCreateModal, onOpen]);

  useEffect(() => {
    if (!isOpen) {
      setSearchParams((prev) => {
        prev.delete('create');

        return prev;
      });
    }
  }, [isOpen, setSearchParams]);

  // Render logic
  if (openHousesEnabled === false) {
    return <Navigate replace to="dashboard" />;
  }

  if (listingLoading || openHousesLoading) {
    return (
      <Flex h="100vh" w="100vw">
        <CenterSpinner />
      </Flex>
    );
  }

  // Split the results into current and upcoming, and past.
  // We do this on the client for now because the backend doesn't know about
  // the user's timezone at the moment.
  // TODO: BLO-880 update backend to return tz-aware timestamps instead.
  const hydratedResults = data?.listingOpenHouses?.results
    ?.map((openHouse) => {
      return {
        ...openHouse,
        startTsLocal: dayjs
          .utc(`${openHouse.date}T${openHouse.startTime}`)
          .local(),
        endTsLocal:
          openHouse.endTime === '00:00:00'
            ? dayjs
                .utc(`${openHouse.date}T${openHouse.endTime}`)
                .add(1, 'day')
                .local()
            : dayjs.utc(`${openHouse.date}T${openHouse.endTime}`).local(),
      };
    })
    .map((openHouse) => {
      return {
        ...openHouse,
        isHappeningNow:
          openHouse.startTsLocal.isSameOrBefore(now) &&
          now.isBefore(openHouse.endTsLocal),
      };
    });

  const currentAndUpcoming = hydratedResults
    ?.filter((openHouse) => {
      return now.isSameOrBefore(openHouse.endTsLocal);
    })
    .toSorted((a, b) => {
      // Sort by start time ascending
      return a.startTsLocal.diff(b.startTsLocal);
    });
  const past = hydratedResults
    ?.filter((openHouse) => {
      return now.isAfter(openHouse.endTsLocal);
    })
    .toSorted((a, b) => {
      // Sort by end time descending
      return b.endTsLocal.diff(a.endTsLocal);
    });

  return (
    <ListingSetupContentWrapper>
      <Flex direction="column" gap={6} w={{ base: '100%', md: '80%' }}>
        <Flex alignItems="center" justifyContent="space-between">
          <Heading as="h5" size="xxs">
            Open Houses
          </Heading>
          <Button colorScheme="blue" size="sm" onClick={onOpen}>
            + New
          </Button>
        </Flex>
        {hydratedResults?.length === 0 && (
          <EmptyState
            headerText="You don't have any open houses"
            icon={DoorOpen}
            // TODO: add cta? / message that we sync open houses
            subText="Open Houses drive listing demand and helps build your Guestbook"
          />
        )}
        {currentAndUpcoming && currentAndUpcoming.length > 0 && (
          <>
            <Heading as="h5" color="whiteAlpha.600" fontSize="lg">
              Current and upcoming
            </Heading>
            <SimpleGrid columns={{ base: 1, xl: 2 }} spacing={6}>
              {currentAndUpcoming?.map((openHouse) => (
                <OpenHouseCard
                  key={openHouse.id}
                  isEditable={
                    openHouse.source === OpenHouseSource.CREATED_BY_USER &&
                    now.isBefore(openHouse.startTsLocal) &&
                    userCanEditOpenHouses
                  }
                  listingId={listingId || ''}
                  openHouse={openHouse}
                  onDelete={handleDeleteOpenHouse}
                  onEdit={handleEditOpenHouse}
                />
              ))}
            </SimpleGrid>
          </>
        )}
        {past && past.length > 0 && (
          <>
            <Heading as="h5" color="whiteAlpha.600" fontSize="lg">
              Past
            </Heading>
            <SimpleGrid columns={{ base: 1, xl: 2 }} spacing={6}>
              {past?.map((openHouse) => (
                <OpenHouseCard
                  key={openHouse.id}
                  isEditable={false}
                  listingId={listingId || ''}
                  openHouse={openHouse}
                  onDelete={handleDeleteOpenHouse}
                  onEdit={handleEditOpenHouse}
                />
              ))}
            </SimpleGrid>
          </>
        )}
      </Flex>

      <ListingActivityProvider>
        <ListingOpenHouseFormModal
          editingOpenHouse={editingOpenHouse}
          isOpen={isOpen}
          onClose={onClose}
          onCreateOpenHouse={handleCreateOpenHouse}
          onUpdateOpenHouse={handleUpdateOpenHouse}
        />
      </ListingActivityProvider>
    </ListingSetupContentWrapper>
  );
}
