import {
  Button,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { ListingObject } from '@client/graphql/__generated__/types';
import { useState } from 'react';
import { useOfferFlowContext } from '~/apps/consumer/pages/Storefront/OfferFlow/OfferFlowContext';
import { getStorefrontRoute } from '~/apps/consumer/utils/storefront.utils';

interface StartOverModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const StartOverModal = ({ isOpen, onClose }: StartOverModalProps) => {
  const { listing } = useOfferFlowContext();
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>Start over</ModalHeader>
        <ModalBody>
          <Text>
            <Heading size="heading">
              Are you sure you want to start over?
            </Heading>
          </Text>
        </ModalBody>
        <ModalFooter>
          <Button variant="outline" width="50%" onClick={() => onClose()}>
            Cancel
          </Button>
          <Button
            isLoading={isLoading}
            ml={2}
            width="50%"
            onClick={() => {
              // page reload to reset the offer flow state
              setIsLoading(true);
              window.location.href = `${getStorefrontRoute(
                listing as ListingObject
              )}?state=startOver`;
            }}
          >
            Start over
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
