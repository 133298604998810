import {
  useCreateUserTourMutation,
  useGetUserToursQuery,
} from '@client/graphql/__generated__/main-operations';
import { TourType } from '@client/graphql/__generated__/types';
import isEmpty from 'lodash/isEmpty';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useLocalStorageState } from '~/common/hooks/useLocalStorageState';
import { useAppContext } from '~/services/main/contexts/AppContext';

export const useViewedTours = () => {
  const { isAuthenticated } = useAppContext();
  const { updateLocalStorageState, localStorageState, saveLocalStorageState } =
    useLocalStorageState<{
      viewedTours: TourType[];
    }>('viewedTours');
  const [createUserTourMutation] = useCreateUserTourMutation();
  const {
    data: userToursData,
    refetch: refetchUserTours,
    loading: userToursLoading,
  } = useGetUserToursQuery({
    skip: !isAuthenticated,
  });

  // need ref to properly call the updated saveLocalStorageState function after update
  const saveLocalStorageRef = useRef(saveLocalStorageState);
  useEffect(() => {
    saveLocalStorageRef.current = saveLocalStorageState;
  }, [saveLocalStorageState]);

  const viewedTours: TourType[] = useMemo(() => {
    if (isAuthenticated) {
      return userToursData?.getUserTours?.map((tour) => tour.tourName) || [];
    }

    return isEmpty(localStorageState?.viewedTours)
      ? []
      : localStorageState?.viewedTours || [];
  }, [isAuthenticated, localStorageState, userToursData?.getUserTours]);

  const getHasViewedTour = useCallback(
    (tourName: TourType) => {
      if (userToursLoading) {
        // we need to return true until we get the actual viewed tour data from the db
        return true;
      }

      return viewedTours?.includes?.(tourName);
    },
    [viewedTours, userToursLoading]
  );

  const updateHasViewedTour = useCallback(
    async (tourNames: TourType[]) => {
      if (isAuthenticated) {
        await createUserTourMutation({ variables: { tourNames } });
        await refetchUserTours();
      } else {
        updateLocalStorageState({
          viewedTours: [...viewedTours, ...tourNames],
        });
        setTimeout(() => {
          saveLocalStorageRef.current();
        }, 1); // need to wait for saveLocalStorageState to update with new localStorageState values
      }
    },
    [
      isAuthenticated,
      updateLocalStorageState,
      viewedTours,
      createUserTourMutation,
      refetchUserTours,
    ]
  );

  return {
    viewedTours,
    getHasViewedTour,
    updateHasViewedTour,
    isLoading: userToursLoading,
  };
};
