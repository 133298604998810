import { Icon, IconProps } from '@chakra-ui/react';

export const MailIcon = (props: IconProps) => {
  return (
    <Icon
      fill="none"
      height="13"
      viewBox="0 0 18 13"
      width="18"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.88965 12.1318C2.17871 12.1318 1.64323 11.9541 1.2832 11.5986C0.923177 11.2432 0.743164 10.7145 0.743164 10.0127V2.46582C0.743164 1.75944 0.914062 1.23079 1.25586 0.879883C1.59766 0.524414 2.07389 0.34668 2.68457 0.34668H15.0918C15.8027 0.34668 16.3382 0.524414 16.6982 0.879883C17.0583 1.23079 17.2383 1.75944 17.2383 2.46582V10.0127C17.2383 10.7145 17.0674 11.2432 16.7256 11.5986C16.3838 11.9541 15.9076 12.1318 15.2969 12.1318H2.88965ZM2.84863 11.0859H15.126C15.4678 11.0859 15.7298 10.9948 15.9121 10.8125C16.099 10.6302 16.1924 10.3636 16.1924 10.0127V2.46582C16.1924 2.11491 16.099 1.84831 15.9121 1.66602C15.7298 1.48372 15.4678 1.39258 15.126 1.39258H2.84863C2.51139 1.39258 2.24935 1.48372 2.0625 1.66602C1.88021 1.84831 1.78906 2.11263 1.78906 2.45898V10.0059C1.78906 10.3613 1.88021 10.6302 2.0625 10.8125C2.24935 10.9948 2.51139 11.0859 2.84863 11.0859ZM8.9873 8.03711C8.76855 8.03711 8.55436 7.99609 8.34473 7.91406C8.13509 7.82747 7.93229 7.69531 7.73633 7.51758L1.30371 1.72754L2.01465 1.00977L8.33105 6.7041C8.54069 6.89095 8.75944 6.98438 8.9873 6.98438C9.21973 6.98438 9.43848 6.89095 9.64355 6.7041L15.96 1.00977L16.6709 1.72754L10.2383 7.51758C10.0423 7.69531 9.83952 7.82747 9.62988 7.91406C9.42025 7.99609 9.20605 8.03711 8.9873 8.03711ZM2.11719 11.2568L1.40625 10.5391L6.25977 5.68555L6.9707 6.39648L2.11719 11.2568ZM15.8711 11.2637L11.0039 6.39648L11.7217 5.68555L16.582 10.5459L15.8711 11.2637Z"
        fill="currentColor"
      />
    </Icon>
  );
};
