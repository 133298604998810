import { Icon, IconProps } from '@chakra-ui/react';

export const InitialIcon = (props: IconProps) => (
  <Icon
    color="primary.500"
    fill="none"
    height="11px"
    viewBox="0 0 18 11"
    width="18px"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.74918 9.02925V10.2982H5.88711V9.05684L6.38366 8.94649C6.5124 8.91891 6.60435 8.88672 6.65952 8.84994C6.72389 8.80397 6.75607 8.74419 6.75607 8.67063C6.75607 8.61546 6.73309 8.50971 6.68711 8.35339L6.36987 7.36029H3.26642L2.96297 8.31201C2.9078 8.45914 2.88021 8.56489 2.88021 8.62925C2.88021 8.78558 3.01354 8.89132 3.28021 8.94649L3.74918 9.05684V10.2982H0.259521V9.02925L0.549177 8.94649C0.74228 8.90052 0.875613 8.84535 0.949177 8.78098C1.03194 8.70741 1.11469 8.55109 1.19745 8.31201L3.99745 0.367185H6.03883L8.81125 8.31201C8.894 8.55109 8.97217 8.70282 9.04573 8.76718C9.11929 8.83155 9.26182 8.89132 9.47332 8.94649L9.74918 9.02925ZM5.88711 5.89822L4.894 2.80856L4.81125 2.42236L4.71469 2.80856L3.73538 5.89822H5.88711Z"
      fill="currentColor"
    />
    <path
      d="M17.7548 10.312L10.1824 10.2982V8.79477L15.0513 1.88443H12.541C12.3663 1.88443 12.2375 1.91201 12.1548 1.96718C12.072 2.02236 12.026 2.1281 12.0168 2.28443L11.9065 3.26374H10.5272L10.4306 0.312012L17.5341 0.367185V1.82925L12.6375 8.78098H15.6306C15.8146 8.78098 15.9433 8.75339 16.0168 8.69822C16.0996 8.64305 16.1456 8.5373 16.1548 8.38098L16.2651 7.42925H17.6582L17.7548 10.312Z"
      fill="currentColor"
    />
  </Icon>
);
