import { CheckIcon } from '@chakra-ui/icons';
import {
  Box,
  Flex,
  Heading,
  IconButton,
  List,
  ListIcon,
  ListItem,
  Popover,
  PopoverAnchor,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  Portal,
  Text,
} from '@chakra-ui/react';
import {
  EventListObject,
  EventTypes,
} from '@client/graphql/__generated__/types';
import dayjs from 'dayjs';
import { useState } from 'react';
import { IconTag } from '~/common/components/IconTag/IconTag';
import { theme } from '~/common/theme';

interface OfferStatusPopoverProps {
  userEventsForOffer: EventListObject[];
}

export const OfferStatusPopover = ({
  userEventsForOffer,
}: OfferStatusPopoverProps) => {
  const [eventDrawerIsOpen, setEventDrawerIsOpen] = useState(false);

  const handleMouseEnter = () => {
    setEventDrawerIsOpen(true);
  };
  const handleMouseLeave = () => {
    setEventDrawerIsOpen(false);
  };
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
  };

  const latestDoneEvent = userEventsForOffer
    .filter((event) => event.isDone)
    ?.at(-1);

  const buyersAllSigned =
    latestDoneEvent?.name === EventTypes.BUYERS_ALL_SIGNED;

  return (
    <Popover
      isOpen={eventDrawerIsOpen}
      trigger="hover"
      onClose={handleMouseLeave}
      onOpen={handleMouseEnter}
    >
      <PopoverAnchor>
        <IconButton
          _hover={{
            textTransform: 'none',
          }}
          aria-label="View events"
          icon={
            <IconTag
              bgColor={!buyersAllSigned ? 'whiteAlpha.100' : undefined}
              borderRadius={4}
              color="white"
              display="inline-flex"
              fontSize="xs"
              height={3}
              icon={CheckIcon}
              iconColor={!buyersAllSigned ? 'green.400' : undefined}
              label={latestDoneEvent?.label || 'Sending To Buyer'}
              letterSpacing={1}
              px={2}
              textTransform="uppercase"
              variant={buyersAllSigned ? 'gradient' : undefined}
            />
          }
          variant="link"
          onClick={handleClick}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        />
      </PopoverAnchor>
      <Portal>
        <PopoverContent
          background="indigo.900"
          borderRadius={8}
          minWidth={{ base: 'sm', lg: 'lg' }}
          p={3}
          onClick={handleClick}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <PopoverArrow background="indigo.900!important" />
          <PopoverBody>
            <List spacing={3}>
              {userEventsForOffer?.map((event, i) => (
                <ListItem
                  key={event.name}
                  alignItems="center"
                  display="flex"
                  justifyContent="space-between"
                  p={2}
                >
                  <Box alignItems="center" display="flex" gap={2}>
                    <Flex
                      _before={
                        i === 0
                          ? {}
                          : {
                              content: '""',
                              position: 'absolute',
                              top: event.isDone ? '-2.37rem' : '-2.33rem',
                              left: event.isDone ? '0.32rem' : '0.4rem',
                              right: 0,
                              bottom: 0,
                              zIndex: '-1',
                              margin: '-$border',
                              background: event.isDone
                                ? theme.colors.purpleGradient
                                : theme.colors.whiteAlpha[400],
                              height: event.isDone ? '3rem' : '2.25rem',
                              width: event.isDone ? 1 : 0.5,
                            }
                      }
                      alignItems="center"
                      background={
                        event.isDone
                          ? theme.colors.purpleGradient
                          : 'transparent'
                      }
                      border="1px solid"
                      borderColor={theme.colors.whiteAlpha[400]}
                      borderRadius="50%"
                      height={4}
                      justifyContent="center"
                      position="relative"
                      width={4}
                    >
                      {event.isDone ? (
                        <ListIcon
                          as={CheckIcon}
                          color="white"
                          h={2}
                          mr={0}
                          w={2}
                        />
                      ) : undefined}
                    </Flex>
                    <Text
                      fontSize="md"
                      fontWeight="bold"
                      textTransform="initial"
                    >
                      {event.label}
                    </Text>
                  </Box>
                  {event.doneAt && (
                    <Heading color="gray.400" size="smallUppercase">
                      {dayjs(event.doneAt as Date).format(
                        'MMM DD, YYYY h:mm A'
                      )}
                    </Heading>
                  )}
                </ListItem>
              ))}
            </List>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};
