import { Box, FormControl, FormErrorMessage, Text } from '@chakra-ui/react';
import { FormField, ValuesType } from '@document/components/FormFields/types';
import type EventEmitter from 'eventemitter3';
import { debounce } from 'lodash';
import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { InputField } from '../InputField/InputField';

interface OfferFieldProps {
  formField: FormField;
  value?: string | boolean | number | string[];
  observer?: IntersectionObserver;
  index: number;
  emitter: EventEmitter;
  updateValues: (values: ValuesType) => void;
  evaluateErrorRules?: (
    formField: FormField
  ) => (string | undefined)[] | undefined;
}

export const OfferField: FC<OfferFieldProps> = memo(function OfferField({
  formField,
  value,
  observer,
  index,
  emitter,
  updateValues,
  evaluateErrorRules,
}) {
  const [inputValue, setInputValue] = useState<ValuesType>({});
  const [isVisible, setIsVisible] = useState(true);
  const errors = evaluateErrorRules ? evaluateErrorRules(formField) : [];
  const [ref, setRef] = useState<HTMLDivElement | null>(null);
  const [formControlRef, setFormControlRef] = useState<HTMLDivElement | null>(
    null
  );
  const [height, setHeight] = useState<number>(0);

  const updateValuesDebounce = useMemo(() => {
    return debounce((value: ValuesType) => {
      updateValues(value);
    }, 100);
  }, [updateValues]);
  const onChange = useCallback(
    (value: ValuesType) => {
      setInputValue(value);
      updateValuesDebounce(value);
    },
    [updateValuesDebounce]
  );

  useEffect(() => {
    setInputValue((prev) => {
      if (value !== prev[formField.mappingKey]) {
        return {
          [formField.mappingKey]: value,
        };
      }

      return prev;
    });
  }, [value, formField.mappingKey]);

  useEffect(() => {
    if (ref && observer) {
      observer?.observe(ref);
      setHeight(ref.offsetHeight);
    }

    return () => {
      ref && observer?.unobserve(ref);
    };
  }, [ref, observer]);

  useEffect(() => {
    if (formControlRef) {
      setHeight(formControlRef.offsetHeight);
    }
  }, [formControlRef]);

  useEffect(() => {
    const callback = (visibleIds: number[]) => {
      if (visibleIds.includes(index) && !isVisible) {
        setIsVisible(true);
      }

      if (!visibleIds.includes(index) && isVisible) {
        setIsVisible(false);
      }
    };

    emitter.on('visibleIndicesChange', callback);

    return () => {
      emitter.removeListener('visibleIndicesChange', callback);
    };
  }, [emitter, index, isVisible]);

  return (
    <Box
      ref={setRef}
      data-id={formField.id}
      data-index={index}
      height={`${height || 0}px`}
    >
      {!height || isVisible ? (
        <FormControl
          ref={setFormControlRef}
          isInvalid={!!errors?.length}
          pb={4}
          px={4}
        >
          <Text
            color="gray.600"
            fontSize="14px"
            fontWeight="bold"
            textTransform="none"
          >
            {formField.field?.label}
          </Text>
          <InputField
            formField={formField}
            value={inputValue[formField.mappingKey]}
            onBlur={(mappingKey) => {
              const field = document.querySelector<HTMLDivElement>(
                `div[data-mapping-key="${mappingKey || formField.mappingKey}"]`
              );
              if (field) {
                field.style.outlineColor = '';
                field.style.outlineWidth = '0px';
              }
            }}
            onChange={onChange}
            onFocus={(mappingKey) => {
              const page = document.querySelector<HTMLDivElement>(
                `div[data-page-number="${formField.pageNumber}"]`
              );

              if (page) {
                page.scrollIntoView({
                  block: 'center',
                });
              }

              const field = document.querySelector<HTMLDivElement>(
                `div[data-mapping-key="${mappingKey || formField.mappingKey}"]`
              );

              if (field) {
                field.scrollIntoView({
                  block: 'center',
                });
                field.style.outlineWidth = '4px';
                field.style.outlineColor = 'blue';
                field.style.outlineStyle = 'solid';
              }

              const interval = setInterval(() => {
                const field = document.querySelector<HTMLDivElement>(
                  `div[data-mapping-key="${formField.mappingKey}"]`
                );

                if (field) {
                  field.scrollIntoView({
                    block: 'center',
                  });
                  field.style.outlineWidth = '4px';
                  field.style.outlineColor = 'blue';
                  field.style.outlineStyle = 'solid';

                  clearInterval(interval);
                }
              }, 50);
            }}
          />

          <FormErrorMessage>{errors}</FormErrorMessage>
        </FormControl>
      ) : null}
    </Box>
  );
});
