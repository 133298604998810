import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Text,
  VStack,
  useToast,
} from '@chakra-ui/react';
import {
  useFindMlsAgentByMlsIdQuery,
  useSubmitOfferForBuyersAgentMutation,
  useSubmitOfferToNonIndigoListingAgentMutation,
} from '@client/graphql/__generated__/main-operations';
import { ListingObject } from '@client/graphql/__generated__/types';
import { FC, useEffect, useState } from 'react';
import { formatOfferAttachmentName } from '~/apps/consumer/utils/offer.utils';
import { PhoneNumberInputGroup } from '~/common/components/Inputs/PhoneNumberInputGroup';
import { FilesBox } from '../../../../../common/components/FilesBox/FilesBox';
import { SubmitOfferModal } from '../../SubmitOfferModal/SubmitOfferModal';
import { SubmitOfferToListingAgentModalProps } from './types';

export const SubmitOfferToNonIndigoListingAgentModal: FC<
  SubmitOfferToListingAgentModalProps & { listing: ListingObject }
> = ({ isOpen, onClose, offer, onFinish, finishButtonText, listing }) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [submittingOffer, setSubmittingOffer] = useState(false);
  const [submitOfferForBuyersAgent] = useSubmitOfferForBuyersAgentMutation();
  const [submitOfferToNonIndigoListingAgent] =
    useSubmitOfferToNonIndigoListingAgentMutation();

  const agentMlsId = listing.mlsListing?.agent?.mlsId;
  const mlsOSN = listing.mlsListing?.mls?.originatingSystemName;
  const { data } = useFindMlsAgentByMlsIdQuery({
    variables: {
      input: {
        agentMlsId: agentMlsId || '',
        mlsOSN: mlsOSN || '',
      },
    },
    skip: !agentMlsId || !mlsOSN,
  });

  const listingAgentData = data?.findMLSAgentByMlsId.mlsAgent;

  const [email, setEmail] = useState<string>();
  const [phone, setPhone] = useState<string>();

  useEffect(() => {
    setEmail(listingAgentData?.MemberEmail || '');
    setPhone(
      listingAgentData?.MemberMobilePhone ||
        listingAgentData?.MemberDirectPhone ||
        ''
    );
  }, [listingAgentData]);
  const toast = useToast();

  const signedDoc = offer.contract?.signatureRequests?.[0]?.allSignedDocument;

  const handleSubmit = async (note: string) => {
    if (!offer) return;
    try {
      setSubmittingOffer(true);
      await Promise.all([
        handleSendToNonIndigoListingAgent(note),
        submitOfferForBuyersAgent({
          variables: {
            input: {
              noteToListingAgent: note,
              id: offer.id,
              skipListingAgentNotifications: true,
            },
          },
        }),
      ]);
      setSubmittingOffer(false);
      setIsSubmitted(true);
    } catch (error) {
      setIsSubmitted(false);
      setSubmittingOffer(false);
      toast({
        title: 'Error submitting offer',
        description: 'An unexpected error occurred submiting your offer.',
        status: 'error',
        isClosable: true,
      });
    }
  };

  const offerAttachmentName = formatOfferAttachmentName(offer);
  const handleSendToNonIndigoListingAgent = async (note: string) => {
    if (!offer || !signedDoc) return;

    await submitOfferToNonIndigoListingAgent({
      variables: {
        input: {
          id: offer.id,
          listingAgentInfo: {
            name: listingAgentData?.MemberFullName || '',
            email: email || '',
            phone: phone || '',
          },
          offerInfo: {
            noteToListingAgent: note,
            attachmentName: offerAttachmentName,
          },
        },
      },
    });
  };

  const bodyHeader = (
    <Box fontSize="lg">
      <Text color="whiteAlpha.600">
        Please check the email and phone number of the listing agent before
        submitting the offer. <br /> Once you hit send, we'll send the offer
        package to the listing agent,
        <Text as="span" color="white" fontWeight="500">
          {' '}
          you'll be cc'ed on the email.
        </Text>
      </Text>
    </Box>
  );

  const fileAttachmentSection = () => {
    if (signedDoc) {
      return (
        <Box bg="whiteAlpha.100" borderRadius="md" p={4} w="100%">
          <FilesBox
            files={[
              {
                name: offerAttachmentName,
                url: signedDoc.url,
              },
            ]}
          />
        </Box>
      );
    }

    return (
      <Box
        alignContent="center"
        alignItems="center"
        border="1px solid"
        borderColor="border.divider"
        borderRadius="md"
        color="whiteAlpha.600"
        display="flex"
        justifyContent="center"
        p={4}
        w="100%"
      >
        <Text>No file attached</Text>
      </Box>
    );
  };

  const phoneAndEmailInputSection = () => (
    <VStack alignItems="stretch" spacing={4} width="100%">
      {listingAgentData && (
        <FormControl>
          <FormLabel>Listing Agent</FormLabel>
          <Text>{listingAgentData.MemberFullName}</Text>
        </FormControl>
      )}
      <FormControl isRequired>
        <FormLabel>Email</FormLabel>
        <Input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </FormControl>
      <FormControl>
        <FormLabel>Phone</FormLabel>
        <PhoneNumberInputGroup
          value={phone}
          onChange={(e) => setPhone(e.currentTarget.value)}
        />
      </FormControl>
    </VStack>
  );

  return (
    <SubmitOfferModal
      attachmentSection={fileAttachmentSection()}
      bodyHeader={bodyHeader}
      bodyList={[]}
      finishButtonText={finishButtonText}
      handleSubmit={handleSubmit}
      isLoading={submittingOffer}
      isOpen={isOpen}
      isSubmitted={isSubmitted}
      modalTitle="Prepare offer for listing agent"
      notePlaceholder="add a note for the listing agent"
      offer={offer}
      phoneAndEmailSection={phoneAndEmailInputSection()}
      sentMessage="We've CC-ed you on the email as well. Good luck with this offer!"
      submitButtonDisabled={!(signedDoc && email)}
      submitButtonText="Send to listing agent"
      onClose={onClose}
      onFinish={onFinish}
    />
  );
};
