import {
  Flex,
  InputGroup,
  InputProps,
  InputRightAddon,
  Text,
  chakra,
} from '@chakra-ui/react';
import { TextField } from './TextField';

export interface RangeFieldProps {
  unit?: string;
  fromPlaceholder?: InputProps['placeholder'];
  toPlaceholder?: InputProps['placeholder'];
  isDisabled?: boolean;
  fromName: string;
  toName: string;
}

export const RangeField = ({
  unit,
  fromPlaceholder,
  toPlaceholder,
  fromName,
  toName,
  isDisabled,
}: RangeFieldProps) => {
  return (
    <RangeInputWrapper aria-disabled={isDisabled}>
      <InputGroup variant="unstyled">
        <StyledInput
          isDisabled={isDisabled}
          name={fromName}
          placeholder={fromPlaceholder}
          variant="unstyled"
        />
        {unit && <InputRightAddon>{unit}</InputRightAddon>}
      </InputGroup>
      <Text px={2}>-</Text>
      <InputGroup variant="unstyled">
        <StyledInput
          isDisabled={isDisabled}
          name={toName}
          placeholder={toPlaceholder}
          variant="unstyled"
        />
        {unit && <InputRightAddon>{unit}</InputRightAddon>}
      </InputGroup>
    </RangeInputWrapper>
  );
};

const RangeInputWrapper = chakra(Flex, {
  baseStyle: {
    borderRadius: 'md',
    px: 4,
    width: '100%',
    height: 12,
    border: '1px solid',
    borderColor: 'var(--chakra-colors-chakra-border-color)',
    alignItems: 'center',
    transition: 'all 0.2s',
    _focusWithin: {
      borderColor: 'var(--chakra-colors-primary-400) !important',
      boxShadow: '0 0 0 1px var(--chakra-colors-primary-400)',
    },
    _hover: {
      borderColor: 'var(--chakra-colors-whiteAlpha-400)',
    },
    _disabled: {
      opacity: 0.4,
      cursor: 'not-allowed',
    },
    className: 'range-input',
  },
});

const StyledInput = chakra(TextField, {
  baseStyle: {
    textAlign: 'right',
    paddingRight: 2,
  },
});
