import { Stack } from '@chakra-ui/react';
import { useListingsForCurrentUserQuery } from '@client/graphql/__generated__/main-operations';
import { Home } from 'lucide-react';
import { FC } from 'react';
import { CenterSpinner } from '~/common/components/CenterSpinner';
import { useInfiniteQuery } from '~/services/document/hooks';
import { EmptyState } from '../../components/EmptyState';
import { ListingCard } from '../../components/Listings/ListingCard/ListingCard';

export const PastListingsPage: FC = () => {
  const [triggerRef, { data, loading }] = useInfiniteQuery(
    useListingsForCurrentUserQuery,
    {
      variables: {
        filters: {
          isActive: false,
        },
      },
    }
  );

  if (!data && loading) {
    return <CenterSpinner />;
  }

  return data?.listingsForCurrentUser?.results?.length ? (
    <>
      <Stack
        direction={{ base: 'column', md: 'row' }}
        flexWrap="wrap"
        pt="6"
        spacing={{ base: 3, md: 2 }}
      >
        {data?.listingsForCurrentUser?.results?.map((listing) => (
          <ListingCard key={listing.id} isActive={false} listing={listing} />
        ))}

        <div ref={triggerRef} />
      </Stack>

      {loading && <CenterSpinner />}
    </>
  ) : (
    <EmptyListings />
  );
};

const EmptyListings: FC = () => (
  <EmptyState
    headerText="You don’t have any past listings"
    icon={Home}
    minH="70vh"
    subText="Listings are synced from the MLS every hour and will automatically show up here once published"
  />
);
