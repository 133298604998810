import { useCallback } from 'react';
import { Navigate, Route, useSearchParams } from 'react-router-dom';
import { AgentSignUpPage } from '~/apps/consumer/pages/AgentSignUp/AgentSignUpPage';
import { SentryRoutes } from '~/common/utils/sentry-routes';
import { Authenticated } from '~/services/main/components/auth/Authenticated';
import { ConsumerApp } from './ConsumerApp';
import { OfferFlow } from './components/OfferFlow/OfferFlow';
import { AccountPage } from './pages/Account/AccountPage';
import { AgentProfilePage } from './pages/Account/AgentProfilePage';
import { ActivityPage } from './pages/Activity/ActivityPage';
import { BuyerOfferAnalysisPage } from './pages/BuyerOfferAnalysis/BuyerOfferAnalysisPage';
import { CompsTestPage } from './pages/BuyerOfferAnalysis/CompsTestPage';
import { AgencyAgreementsPage } from './pages/Buyers/AgencyAgreements/AgencyAgreementsPage';
import { BuyerInfoPage } from './pages/Buyers/BuyerInfoPage';
import { BuyerOfferDetailPage } from './pages/Buyers/BuyerOfferDetailPage';
import { BuyerPartyPageLayout } from './pages/Buyers/BuyerPartyPageLayout';
import { BuyersPage } from './pages/Buyers/BuyersPage';
import { PartyPageWrapper } from './pages/Buyers/PartyPageWrapper';
import { NotFoundPage } from './pages/Errors/NotFoundPage';
import { EsignPage } from './pages/EsignPage';
import { InvitationAcceptPage } from './pages/Invitation/InvitationAcceptPage';
import { AdminsPage } from './pages/ListingSetup/Admins/AdminsPage';
import { ComparablesPage } from './pages/ListingSetup/Comparables/ComparablesPage';
import { DashboardPage } from './pages/ListingSetup/Dashboard/DashboardPage';
import { DocumentsPage } from './pages/ListingSetup/Documents/DocumentsPage';
import { GuestbookSignersPage } from './pages/ListingSetup/Guestbook/GuestbookSignersPage';
import { ListingPackageInstructions } from './pages/ListingSetup/ListingPackageInstructions/ListingPackageInstructions';
import { ListingSetupPageWrapper } from './pages/ListingSetup/ListingSetupPageWrapper';
import { ListingOfferDetailPage } from './pages/ListingSetup/Offers/ListingOfferDetailPage';
import { ListingOfferReviewPage } from './pages/ListingSetup/Offers/ListingOfferReviewPage';
import { ListingOffersPage } from './pages/ListingSetup/Offers/ListingOffersPage';
import { OpenHousesPage } from './pages/ListingSetup/OpenHouses/OpenHouseManagePage';
import { OfferTransparencyPage } from './pages/ListingSetup/Preferences/OfferTransparencyPage';
import { SellerPreferencesPage } from './pages/ListingSetup/Preferences/SellerPreferencesPage';
import { ComparablesPage as StorefrontSetupWizardComparablesPage } from './pages/ListingSetup/StorefrontSetupWizard/ComparablesPage';
import { DocumentsPage as StorefrontSetupWizardDocumentsPage } from './pages/ListingSetup/StorefrontSetupWizard/DocumentsPage';
import { IntroPage } from './pages/ListingSetup/StorefrontSetupWizard/IntroPage';
import { SellerPreferencesPage as StorefrontSetupWizardSellerPreferencesPage } from './pages/ListingSetup/StorefrontSetupWizard/SellerPreferencesPage';
import { SharePage as StorefrontSetupWizardSharePage } from './pages/ListingSetup/StorefrontSetupWizard/SharePage';
import { StorefrontSetupWizard } from './pages/ListingSetup/StorefrontSetupWizard/StorefrontSetupWizard';
import { ActiveListingsPage } from './pages/Listings/ActiveListingsPage';
import { ListingsPage } from './pages/Listings/ListingsPage';
import { PastListingsPage } from './pages/Listings/PastListingsPage';
import { AgencyAgreementContinuePage } from './pages/MainDashboard/AgencyAgreementContinuePage';
import { MainDashboardPage } from './pages/MainDashboard/MainDashboardPage';
import {
  MainLayout,
  MainLayoutWithoutDefaultMobileNav,
} from './pages/MainLayout';
import { MapSearchPage } from './pages/MapSearch/MapSearchPage';
import { OfferRedirectPage } from './pages/Offers/OfferRedirectPage';
import { OpenHouseGuestBookSignFormsPage } from './pages/OpenHouseGuestbook/OpenHouseGuestBookSignFormsPage';
import { OpenHouseGuestBookSignIndexPage } from './pages/OpenHouseGuestbook/OpenHouseGuestBookSignIndexPage';
import { OpenHouseGuestBookSignPageLayout } from './pages/OpenHouseGuestbook/OpenHouseGuestBookSignPageLayout';
import { AccessControlsPage } from './pages/Organizations/AccessControls/AccessControlsPage';
import { OrganizationOverviewPage } from './pages/Organizations/OrganizationOverview/OrganizationOverviewPage';
import { OrganizationPageLayout } from './pages/Organizations/OrganizationPageLayout';
import { PropertyView } from './pages/PropertyView';
import { PublicLayout } from './pages/PublicLayout';
import { AddDocsPage } from './pages/Storefront/OfferFlow/AddDocsPage';
import { BuySideUploadedOfferReviewPage } from './pages/Storefront/OfferFlow/BuySideUploadedOfferReviewPage';
import { BuyerPage } from './pages/Storefront/OfferFlow/BuyerPage';
import { ChooseContractPage } from './pages/Storefront/OfferFlow/ChooseContractPage';
import { FillDocsPage } from './pages/Storefront/OfferFlow/FillDocsPage';
import { KeyTermsPage } from './pages/Storefront/OfferFlow/KeyTermsPage';
import { OfferFlowLayout } from './pages/Storefront/OfferFlow/OfferFlowLayout';
import { OfferProcessingAnimation } from './pages/Storefront/OfferFlow/OfferProcessingAnimation';
import { PrepareForBuyerSignature } from './pages/Storefront/OfferFlow/PrepareForBuyerSignature';
import { ReviewPage } from './pages/Storefront/OfferFlow/ReviewPage';
import { StartOfferPage } from './pages/Storefront/OfferFlow/StartOfferPage';
import { StorefrontOfferFlowPage } from './pages/Storefront/StorefrontOfferFlowPage';
import { StorefrontPage } from './pages/Storefront/StorefrontPage';
import { LoginForInvitedSellerPage } from './pages/auth/LoginForInvitedSellerPage';
import { LoginPage } from './pages/auth/LoginPage';
import { LogoutPage } from './pages/auth/LogoutPage';

export const Routes = () => {
  const [searchParams] = useSearchParams();

  const offerFlowRoutes = useCallback(
    () => (
      <>
        <Route index element={<Navigate replace to="contract" />} />
        <Route Component={BuySideUploadedOfferReviewPage} path="uploaded" />
        <Route Component={ChooseContractPage} path="contract" />
        <Route Component={KeyTermsPage} path="key-terms" />
        <Route Component={BuyerPage} path="buyer" />
        <Route Component={AddDocsPage} path="add-docs" />
        <Route Component={FillDocsPage} path="fill-docs" />
        <Route Component={ReviewPage} path="review">
          <Route
            Component={PrepareForBuyerSignature}
            path="prepare-for-signature"
          />
        </Route>
      </>
    ),
    []
  );

  return (
    <SentryRoutes>
      <Route Component={ConsumerApp} path={window.ENVS?.baseRouteUrl || '/'}>
        {/* Auth Routes */}
        <Route Component={LoginPage} path="auth/login" />
        <Route Component={LogoutPage} path="auth/logout" />
        <Route Component={LoginForInvitedSellerPage} path="i/:invitationId" />

        {/* Public Routes */}
        <Route
          Component={ListingPackageInstructions}
          path="listing-package/:propertySlug"
        />

        <Route Component={PublicLayout} path="/">
          <Route Component={EsignPage} path="esign/:code" />
          <Route Component={AgentProfilePage} path="agent/:userName" />
          <Route element={<Navigate replace to="/map" />} path="storefront" />
          <Route Component={StorefrontPage} path="storefront/:propertySlug">
            <Route index Component={StartOfferPage} />
            <Route Component={StorefrontOfferFlowPage} path="offer">
              <Route index element={<OfferProcessingAnimation />} />
              <Route Component={OfferFlowLayout} path="">
                {offerFlowRoutes()}
              </Route>
            </Route>
          </Route>
          <Route element={<Navigate replace to="/map" />} path="search" />
          <Route Component={MapSearchPage} path="map" />
          <Route
            Component={OpenHouseGuestBookSignPageLayout}
            errorElement={<NotFoundPage />}
            path="open-house/:openHouseId"
          >
            <Route index Component={OpenHouseGuestBookSignIndexPage} />
            <Route Component={OpenHouseGuestBookSignFormsPage} path="sign" />
          </Route>
        </Route>

        {/* Authenticated Routes */}
        <Route Component={Authenticated}>
          <Route Component={AgentSignUpPage} path="agent-signup" />
          <Route
            Component={InvitationAcceptPage}
            path="invitations/accept/:code"
          />

          <Route Component={MainLayout} path="/">
            <Route index element={<Navigate replace to="dashboard" />} />
            <Route Component={MainDashboardPage} path="dashboard">
              <Route
                Component={AgencyAgreementContinuePage}
                path="agency-agreement/:indigoContractId"
              />
            </Route>
            <Route Component={OfferRedirectPage} path="offers/:offerId" />

            <Route Component={ListingsPage} path="listings">
              <Route element={<Navigate replace to="active" />} path="" />
              <Route Component={ActiveListingsPage} path="active" />
              <Route Component={PastListingsPage} path="past" />
            </Route>

            {/* Organization Routes */}
            <Route Component={OrganizationPageLayout} path="organizations">
              <Route index element={<Navigate replace to="admin" />} />
              <Route Component={OrganizationOverviewPage} path="overview" />
              <Route Component={AccessControlsPage} path="admin" />
            </Route>

            {/* Buyers Page */}
            <Route Component={BuyersPage} path="buyers" />
            <Route Component={BuyerPartyPageLayout} path="buyers/:buyerPartyId">
              <Route
                index
                element={<Navigate replace to="purchase-offers" />}
              />
              <Route Component={PartyPageWrapper} path="purchase-offers">
                <Route Component={BuyerOfferDetailPage} path=":offerId">
                  <Route Component={OfferFlowLayout} path="">
                    {offerFlowRoutes()}
                  </Route>
                </Route>
              </Route>
              <Route Component={BuyerInfoPage} path="buyer-info" />
              <Route
                Component={AgencyAgreementsPage}
                path="agency-agreements"
              />
            </Route>
            <Route Component={AccountPage} path="account" />
            <Route Component={ActivityPage} path="alerts" />

            <Route Component={CompsTestPage} path="comps-test" />
          </Route>

          <Route Component={MainLayoutWithoutDefaultMobileNav} path="/">
            <Route
              Component={ListingSetupPageWrapper}
              path="listings/:listingId"
            >
              <Route
                index
                element={
                  <Navigate
                    replace
                    to={{
                      pathname: 'dashboard',
                      search: searchParams.toString(),
                    }}
                  />
                }
              />
              <Route Component={DashboardPage} path="dashboard" />
              <Route Component={ListingOffersPage} path="offers">
                <Route Component={ListingOfferDetailPage} path=":offerId">
                  <Route Component={OfferFlowLayout} path="">
                    <Route Component={ListingOfferReviewPage} path="" />
                  </Route>
                </Route>
              </Route>
              <Route Component={GuestbookSignersPage} path="guestbook" />
              <Route Component={OpenHousesPage} path="open-houses" />
              <Route Component={AdminsPage} path="admins" />
              <Route Component={SellerPreferencesPage} path="preferences" />
              <Route Component={ComparablesPage} path="comparables" />
              <Route Component={DocumentsPage} path="documents" />
              <Route
                Component={OfferTransparencyPage}
                path="offer-transparency"
              />
            </Route>
          </Route>
          <Route
            Component={StorefrontSetupWizard}
            path="storefront-setup/:listingId"
          >
            <Route index Component={IntroPage} />
            <Route
              Component={StorefrontSetupWizardSellerPreferencesPage}
              path="seller-preferences"
            />
            <Route
              Component={StorefrontSetupWizardComparablesPage}
              path="comparables"
            />
            <Route
              Component={StorefrontSetupWizardDocumentsPage}
              path="documents"
            />
            <Route Component={StorefrontSetupWizardSharePage} path="share" />
          </Route>

          {/* Buyer Offer Analysis Routes */}
          <Route
            Component={BuyerOfferAnalysisPage}
            path="offer-coach/:offerCoachId"
          />
        </Route>

        {/* TODO: remove legacy demo route */}
        <Route Component={PropertyView} path="property-view" />
        <Route Component={OfferFlow} path="offer/:contractId" />
      </Route>

      {/* Errors */}
      <Route Component={PublicLayout} path="/">
        <Route Component={NotFoundPage} path="*" />
      </Route>
    </SentryRoutes>
  );
};
