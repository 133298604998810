import { Box, Divider } from '@chakra-ui/react';
import {
  FC,
  MutableRefObject,
  memo,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Document, pdfjs } from 'react-pdf';
import { PDFScrollableDocumentPage } from './PDFScrollableDocumentPage';
import {
  DocumentsThumbnails,
  PDFFile,
  PageHeightRatio,
  PageMetadata,
} from './types';

export interface PDFScrollableDocumentProps {
  pdfFile: PDFFile;
  scrollableRef: HTMLDivElement;
  documentIndex: number;
  width?: number;
  pageChildren?: FC<{ page: PageMetadata }>;
  scale: number;
  zoom: number;
  documentsThumbnailsRef?: MutableRefObject<
    DocumentsThumbnails | null | undefined
  >;
  onPagesMetadataChange?: (pagesMetadata: PageMetadata[]) => void;
}

export const PDFScrollableDocument: FC<PDFScrollableDocumentProps> = memo(
  function PDFScrollableDocument({
    pdfFile,
    scrollableRef,
    documentIndex,
    width,
    scale,
    zoom,
    pageChildren,
    documentsThumbnailsRef,
    onPagesMetadataChange,
  }) {
    const [pageSizes, setPageSizes] = useState<Partial<PageMetadata>[]>([]);
    const [heightRatios, setHeightRatios] = useState<PageHeightRatio[]>([]);
    const options = useMemo(() => {
      return {
        cMapUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/cmaps/`,
      };
    }, []);

    useEffect(() => {
      if (width && heightRatios.length) {
        const pageSizesMap = heightRatios.map((heightRatio) => ({
          width: width,
          height: width * heightRatio.heightRatio,
          pageNumber: heightRatio.pageNumber,
          heightRatio: heightRatio.heightRatio,
          documentIndex,
        })) as PageMetadata[];

        setPageSizes((prev) => {
          const merge = pageSizesMap.map((pageSize) => {
            const prevPageSize = prev.find(
              (ps) =>
                ps.pageNumber === pageSize.pageNumber &&
                ps.documentIndex === pageSize.documentIndex
            );

            return {
              ...prevPageSize,
              ...pageSize,
            };
          });

          if (onPagesMetadataChange) {
            window.requestAnimationFrame(() => {
              onPagesMetadataChange(merge);
            });
          }

          return merge;
        });
      }
    }, [
      width,
      heightRatios,
      scrollableRef.offsetWidth,
      documentIndex,
      onPagesMetadataChange,
    ]);

    return (
      <Document
        file={pdfFile.url}
        options={options}
        renderMode="canvas"
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onLoadSuccess={async (pdf) => {
          const pagesArr: number[] = [];

          for (let i = 1; i <= pdf.numPages; i++) {
            pagesArr.push(i);
          }

          const pageSizes = await Promise.all(
            pagesArr.map(async (pageNumber) => {
              const page = await pdf.getPage(pageNumber);
              const viewPort = page.getViewport();
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              const [leftMargin, topMargin, width, height] = viewPort.viewBox;
              const scrollWidth = scrollableRef.scrollWidth / 2;
              const heightRatio = height / width;

              if (
                documentsThumbnailsRef?.current?.[documentIndex]?.thumbnails &&
                !documentsThumbnailsRef.current[documentIndex].thumbnails[
                  pageNumber
                ]
              ) {
                documentsThumbnailsRef.current[documentIndex].thumbnails[
                  pageNumber
                ] = {
                  pageNumber,
                };
              }

              return {
                pageNumber,
                width: scrollWidth,
                height: Math.floor(scrollWidth * heightRatio),
                heightRatio,
                originalWidth: width,
                originalHeight: height,
              };
            })
          );

          setPageSizes(
            pageSizes.map(
              ({
                pageNumber,
                height,
                width,
                originalWidth,
                originalHeight,
              }) => ({
                pageNumber,
                height,
                width,
                originalWidth,
                originalHeight,
                documentIndex,
              })
            )
          );
          setHeightRatios(
            pageSizes.map(({ pageNumber, heightRatio }) => ({
              pageNumber,
              heightRatio,
            }))
          );
        }}
      >
        {pageSizes.map((page: PageMetadata) => (
          <Box key={page.pageNumber}>
            <PDFScrollableDocumentPage
              documentIndex={documentIndex}
              height={page.height}
              heightRatio={page.heightRatio}
              pageChildren={pageChildren}
              pageNumber={page.pageNumber}
              scale={scale}
              sizeRatio={
                page.originalWidth ? page.width / page.originalWidth : 1
              }
              width={page.width}
              zoom={zoom}
            />

            {page.pageNumber !== pageSizes.length && (
              <Divider height={3} position="relative" zIndex={1} />
            )}
          </Box>
        ))}
      </Document>
    );
  }
);
