/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  useToast,
} from '@chakra-ui/react';
import { Roles } from '@client/graphql/__generated__/types';
// @ts-ignore
import type { CreateUserInput } from '@services/main/src/modules/user-auth/switch-users.dto';
import { FC, useState } from 'react';
import { Select } from '~/services/document/common/Select';
import { useMergeState } from '~/services/document/hooks/useMergeState';

interface AddUserFormProps {
  onUserAdded: () => void;
}

export const AddUserForm: FC<AddUserFormProps> = ({ onUserAdded }) => {
  const [input, setInput] = useState<CreateUserInput>({
    fullName: '',
    email: '',
    roles: [],
  });
  const toast = useToast();

  const updateInput = useMergeState(setInput);

  return (
    <VStack
      as="form"
      onSubmit={(e) => {
        e.preventDefault();
        void fetch('/auth/create-user', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(input),
        })
          .then((response) => response.json())
          .then(() => {
            onUserAdded();
            setInput({
              fullName: '',
              email: '',
              roles: [],
            });

            toast({
              title: 'User added',
              status: 'success',
            });
          });
      }}
    >
      <FormControl>
        <FormLabel>Name</FormLabel>
        <Input
          value={input.fullName}
          onChange={(e) => updateInput({ fullName: e.target.value })}
        />
      </FormControl>

      <FormControl>
        <FormLabel>Email</FormLabel>
        <Input
          type="email"
          value={input.email}
          onChange={(e) => updateInput({ email: e.target.value })}
        />
      </FormControl>

      <FormControl>
        <FormLabel>Roles</FormLabel>
        <Select
          isMulti
          menuPortalTarget={document.body}
          options={Object.keys(Roles).map((role: Roles) => ({
            label: role,
            value: role,
          }))}
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
          value={input.roles.map((role) => ({
            label: role,
            value: role,
          }))}
          onChange={(options) =>
            updateInput({
              roles: options.map((option) => option.value),
            })
          }
        />
      </FormControl>

      <Button type="submit">Add User</Button>
    </VStack>
  );
};
