import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { MlsListingObject } from '@client/graphql/__generated__/types';
import { FindPropertyPopoverList } from './FindPropertyPopoverList';

interface FindPropertyModalProps {
  onSelect: (listing: MlsListingObject) => Promise<void>;
  isOpen: boolean;
  onClose: () => void;
}

export const FindPropertyModal = ({
  onSelect,
  onClose,
  isOpen,
}: FindPropertyModalProps) => {
  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Find a Property</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FindPropertyPopoverList onSelect={onSelect} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
