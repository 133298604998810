import { SearchIcon } from '@chakra-ui/icons';
import {
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Popover,
  PopoverAnchor,
  PopoverBody,
  PopoverContent,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { useSearchMlsListingsByAddressLazyQuery } from '@client/graphql/__generated__/main-operations';
import { MlsListingObject } from '@client/graphql/__generated__/types';
import { debounce } from 'lodash';
import { useCallback, useEffect } from 'react';
import { useComparablesContext } from '~/apps/consumer/pages/ListingSetup/Comparables/ComparablesContext';

interface AddComparablePopoverListProps {
  mlsListing: MlsListingObject;
  onAdd: (listing: MlsListingObject) => void;
}

export const AddComparablePopoverList = ({
  mlsListing,
  onAdd,
}: AddComparablePopoverListProps) => {
  const { comparables } = useComparablesContext();
  const {
    isOpen: isMenuOpen,
    onClose: onMenuClose,
    onOpen: onMenuOpen,
  } = useDisclosure();

  const [searchMlsListing, { data }] = useSearchMlsListingsByAddressLazyQuery({
    // prevents the comparable set data from being overwritten in the cache since that query also fetches MLS Listing data
    fetchPolicy: 'no-cache',
  });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce(async (searchString: string, excludeIds: string[]) => {
      await searchMlsListing({
        variables: {
          input: {
            searchString,
            limit: 5,
            excludeIds, // exclude already selected properties in the search
          },
        },
      });
    }, 1000),
    [searchMlsListing]
  );

  useEffect(() => {
    const searchResults = data?.searchMlsListingsByAddress || [];

    if (searchResults?.length) {
      onMenuOpen();
    }
  }, [data, onMenuOpen]);

  const mlsListings = data?.searchMlsListingsByAddress;

  return (
    <Popover
      matchWidth
      autoFocus={false}
      isOpen={isMenuOpen}
      placement="bottom-start"
      onClose={onMenuClose}
      onOpen={onMenuOpen}
    >
      <PopoverAnchor>
        <InputGroup>
          <InputLeftElement h="full">
            <SearchIcon />
          </InputLeftElement>
          <Input
            placeholder="Search by address"
            onChange={(e) => {
              const excludeIds = [
                ...(comparables?.map((c) => c.mlsListing.id) || []),
                mlsListing?.id,
              ];
              void debouncedSearch(e.target.value, excludeIds);
            }}
            onFocus={() => {
              if (mlsListings?.length) {
                onMenuOpen();
              } else {
                onMenuClose();
              }
            }}
          />
        </InputGroup>
      </PopoverAnchor>
      {!!mlsListings?.length && (
        <PopoverContent width="inherit">
          <PopoverBody>
            {mlsListings
              ?.filter(
                (listing: MlsListingObject) =>
                  !comparables?.some(
                    (comp) => comp.mlsListing.id === listing.id
                  )
              )
              ?.map((listing: MlsListingObject) => (
                <Flex
                  key={listing.id}
                  _hover={{
                    backgroundColor: 'whiteAlpha.300',
                    cursor: 'pointer',
                  }}
                  px={2}
                  py={4}
                  onClick={() => {
                    onAdd(listing);
                  }}
                >
                  <Text size="lg">{listing.addressFull}</Text>
                </Flex>
              ))}
          </PopoverBody>
        </PopoverContent>
      )}
    </Popover>
  );
};
