import React, { useCallback } from 'react';
import Moveable, {
  OnClickGroup,
  OnRenderEnd,
  OnRenderGroupEnd,
} from 'react-moveable';
import Selecto, { OnDragStart, OnSelect } from 'react-selecto';
import { MoveableTarget } from '../DocumentMapper/types';
import { PositionAndSize } from './Moveable';
import { getPositionSizeOnRender } from './events';

interface UseMoveableEventsOptions {
  selectoRef: React.MutableRefObject<Selecto | undefined>;
  moveableTargets?: MoveableTarget[];
  boundHeight: number;
  boundWidth: number;
  onChange?: (changed: PositionAndSize[]) => void;
}

/**
 * Moveable events hook
 */
export const useMoveableEvents = (options: UseMoveableEventsOptions) => {
  const { selectoRef, moveableTargets, boundHeight, boundWidth, onChange } =
    options;

  const onClickGroup = useCallback(
    (event: OnClickGroup) => {
      const inputEvent = event.inputEvent as MouseEvent;
      selectoRef.current?.clickTarget(inputEvent, event.inputTarget);
    },
    [selectoRef]
  );

  const onRenderEnd = useCallback(
    (event: OnRenderEnd) => {
      if (event.target.style.transform.length === 0) {
        return;
      }

      const moveableTarget = moveableTargets?.[0];
      if (moveableTarget?.formField && boundWidth && boundHeight) {
        const positionAndSize = getPositionSizeOnRender(
          event,
          moveableTarget?.formField,
          boundWidth,
          boundHeight
        );

        onChange &&
          onChange([{ ...positionAndSize, id: moveableTarget.formField.id }]);
      }
    },
    [boundHeight, boundWidth, moveableTargets, onChange]
  );

  const onRenderGroupEnd = useCallback(
    ({ events }: OnRenderGroupEnd) => {
      if (moveableTargets) {
        const changed = events.map((event) => {
          const dataset = event.target.dataset;

          const moveableTarget = moveableTargets.find(({ formField, type }) => {
            return (
              dataset.formfieldid === formField.id && type === dataset.type
            );
          });

          if (moveableTarget) {
            const positionAndSize = getPositionSizeOnRender(
              event,
              moveableTarget?.formField,
              boundWidth,
              boundHeight
            );

            return {
              ...positionAndSize,
              id: moveableTarget.formField.id,
            };
          }
        }) as PositionAndSize[];

        onChange && onChange(changed);
      }
    },
    [boundHeight, boundWidth, moveableTargets, onChange]
  );

  return {
    onClickGroup,
    onRenderEnd,
    onRenderGroupEnd,
  };
};

/**
 * Selecto events hook
 */
export const useSelectoEvents = (options: {
  moveableRef: React.RefObject<Moveable>;
  moveableTargets?: MoveableTarget[];
  onSelect: (
    targets: (HTMLElement | SVGElement)[],
    isDragSelect: boolean
  ) => void;
  onSelectEnd?: (
    targets: (HTMLElement | SVGElement)[],
    isDragSelect: boolean
  ) => void;
}) => {
  const { moveableRef, moveableTargets, onSelect, onSelectEnd } = options;

  const onDragStart = useCallback(
    (event: OnDragStart) => {
      const moveable = moveableRef?.current;
      const inputEvent = event.inputEvent as MouseEvent;
      const startSelectedTargets = event.datas
        .startSelectedTargets as HTMLElement[];
      const target = (inputEvent.target ||
        startSelectedTargets?.[0]) as HTMLElement;

      if (
        moveable?.isMoveableElement(target) ||
        moveableTargets?.some(
          ({ formField }) => target?.dataset?.formfieldid === formField.id
        )
      ) {
        event.stop();
      }
    },
    [moveableRef, moveableTargets]
  );

  const onSelectoSelect = useCallback(
    (event: OnSelect) => {
      const inputEvent = event.inputEvent as MouseEvent;
      const target = inputEvent.target as HTMLDivElement;

      if (target?.dataset.fieldetail === 'false') {
        onSelect(event.selected, true);
      } else {
        onSelect(event.selected, event.rect.width > 0 && event.rect.height > 0);
      }
    },
    [onSelect]
  );

  const onSelectoSelectEnd = useCallback(
    (event: OnSelect) => {
      onSelectEnd &&
        onSelectEnd(
          event.selected,
          event.rect.width > 0 && event.rect.height > 0
        );
    },
    [onSelectEnd]
  );

  return {
    onDragStart,
    onSelect: onSelectoSelect,
    onSelectEnd: onSelectoSelectEnd,
  };
};
