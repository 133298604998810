import { Flex } from '@chakra-ui/react';
import { TourHeading } from './styled';

export const TourModalTitle = () => {
  return (
    <Flex alignItems="center" direction="column">
      <TourHeading fontWeight={300}>Welcome to</TourHeading>
      <TourHeading pb={1}>indigo</TourHeading>
    </Flex>
  );
};
