import { useFindMlsAgentsByPhoneNumberLazyQuery } from '@client/graphql/__generated__/main-operations';
import {
  FindMlsAgentFragment,
  UserObject,
} from '@client/graphql/__generated__/types';
import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAppContext } from '~/services/main/contexts/AppContext';
import { AutofilledAgent } from '../../AgentSignUpFlow/AutofilledAgent';
import { SignUp } from '../../SignUp/SignUp';
import { StytchLoginForm } from './StytchLoginForm';

export enum LoginFormSteps {
  StytchLogin = 'StytchLogin',
  MLSAgent = 'MLSAgent',
  SignUp = 'SignUp',
}

interface LoginFormProps {
  signupFormInstructions?: string;
  onLogin: (user: UserObject) => void | Promise<void>;
}

export const LoginForm = ({
  onLogin,
  signupFormInstructions,
}: LoginFormProps) => {
  const { user } = useAppContext();
  const [loggedInUser, setLoggedInUser] = useState(user);
  const [searchParams, setSearchParams] = useSearchParams();
  const [findAgentsByPhoneNumber] = useFindMlsAgentsByPhoneNumberLazyQuery();
  const [mlsAgentsData, setMlsAgentsData] = useState<FindMlsAgentFragment[]>();

  const onLoginCallback = useCallback(
    async (u: UserObject) => {
      await onLogin(u);

      setSearchParams((prev) => {
        prev.delete('step');
        prev.delete('userType');

        return prev;
      });
    },
    [onLogin, setSearchParams]
  );

  const onStytchLogin = useCallback(
    async (user: UserObject) => {
      const { data } = await findAgentsByPhoneNumber({
        variables: {
          input: {
            phoneNumber: user.phoneNumber,
          },
        },
      });

      setLoggedInUser(user);

      if (user.roles.length > 0) {
        return await onLogin(user);
      }

      if (data?.findMLSAgentsByPhoneNumber?.length) {
        setMlsAgentsData(data.findMLSAgentsByPhoneNumber);
        setSearchParams((prev) => {
          prev.set('step', LoginFormSteps.MLSAgent);

          return prev;
        });
      } else {
        setSearchParams((prev) => {
          prev.set('step', LoginFormSteps.SignUp);

          return prev;
        });
      }
    },
    [findAgentsByPhoneNumber, onLogin, setSearchParams]
  );

  useEffect(() => {
    if (
      !loggedInUser &&
      [LoginFormSteps.MLSAgent, LoginFormSteps.SignUp].includes(
        searchParams.get('step') as LoginFormSteps
      )
    ) {
      setSearchParams((prev) => {
        prev.delete('step');
        prev.delete('userType');

        return prev;
      });
    }

    if (user && user.roles.length === 0) {
      setSearchParams((prev) => {
        prev.set('step', LoginFormSteps.SignUp);

        return prev;
      });
    }
  }, [loggedInUser, user, searchParams, setSearchParams]);

  switch (searchParams.get('step') as LoginFormSteps) {
    case LoginFormSteps.MLSAgent:
      return (
        <AutofilledAgent
          mlsAgentsInfo={mlsAgentsData}
          setShowAgentSignup={() =>
            setSearchParams((prev) => {
              prev.set('step', LoginFormSteps.SignUp);

              return prev;
            })
          }
          onConfirm={onLoginCallback}
        />
      );
    case LoginFormSteps.SignUp:
      return (
        <SignUp
          signupFormInstructions={signupFormInstructions}
          user={loggedInUser}
          onSignup={onLoginCallback}
        />
      );

    default:
      return <StytchLoginForm onLogin={onStytchLogin} />;
  }
};
