import { Flex, Text } from '@chakra-ui/react';
import { BellIcon } from 'lucide-react';
import { useEffect, useRef, useState } from 'react';
import {
  ALERT_TOOLTIP_CACHE_VALUE,
  ALERT_TOOLTIP_HAS_FOLLOWED_LISTING,
  ALERT_TOOLTIP_WAS_VIEWED,
  ListingActivityProvider,
} from '../Activity/ListingActivityProvider';
import { NavButtonPopover } from '../Navigation/NavItemsPopover';
import { NavButton } from './NavButton';

interface NavItemAlertsProps {
  isMobile?: boolean;
  isExpanded?: boolean;
  iconSize: { height?: string; width?: string };
  onClick?: () => void;
}

const TOOLTIP_TEXT =
  "You're subscribed! You can view all alerts in the activity section of your account, or in the activity tab.";

const DEFAULT_TOOLTIP_LEFT_OFFSET = 20;

const NavItemAlertsComponent = ({
  isMobile,
  isExpanded,
  iconSize,
  onClick,
}: NavItemAlertsProps) => {
  const [showAlertHint, setShowAlertHint] = useState(false);

  const elementRef = useRef<HTMLDivElement>(null);
  const [centerPoint, setCenterPoint] = useState<number>(0);

  useEffect(() => {
    if (elementRef.current) {
      const rect = elementRef.current.getBoundingClientRect();
      const topOffset = rect.top + window.scrollY;
      const height = rect.height;
      setCenterPoint(topOffset + height / 2);
    }
  }, [elementRef]);

  useEffect(() => {
    // for now we only want to show the alert hint if the user has just followed a listing
    // and hasn't yet closed the alert hint in the past
    function checkUserData() {
      const hintWasAlreadyShown =
        localStorage.getItem(ALERT_TOOLTIP_WAS_VIEWED) ===
        ALERT_TOOLTIP_CACHE_VALUE;

      const userHasFollowedListing =
        localStorage.getItem(ALERT_TOOLTIP_HAS_FOLLOWED_LISTING) ===
        ALERT_TOOLTIP_CACHE_VALUE;

      const shouldShow = userHasFollowedListing && !hintWasAlreadyShown;

      setShowAlertHint(shouldShow);
    }

    window.addEventListener('storage', checkUserData);

    return () => {
      window.removeEventListener('storage', checkUserData);
    };
  }, []);

  const handleClose = () => {
    localStorage.setItem(ALERT_TOOLTIP_WAS_VIEWED, ALERT_TOOLTIP_CACHE_VALUE);
  };

  return (
    <Flex ref={elementRef} position="relative">
      <NavButton
        isExpanded={isExpanded}
        isMobile={isMobile}
        leftIcon={<BellIcon {...iconSize} />}
        to="/alerts"
        onClick={onClick}
      >
        {isExpanded && <Text fontWeight={500}>Alerts</Text>}
      </NavButton>
      <NavButtonPopover
        handleClose={handleClose}
        isOpen={showAlertHint}
        leftOffset={isExpanded ? DEFAULT_TOOLTIP_LEFT_OFFSET : 0}
        tooltipText={TOOLTIP_TEXT}
        topOffset={centerPoint}
      />
    </Flex>
  );
};

export const NavItemAlerts = (props: NavItemAlertsProps) => (
  <ListingActivityProvider>
    <NavItemAlertsComponent {...props} />
  </ListingActivityProvider>
);
