import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  VStack,
} from '@chakra-ui/react';
import { useExploreContext } from '../ExploreContext';
import { DemandFilterContent } from './DemandFilters';
import { PropertyFilterContent } from './PropertyFilters';
import { SellerPreferenceFilterContent } from './SellerPreferenceFilters';

interface MobileFilterDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  btnRef: React.RefObject<HTMLButtonElement>;
  clearAll: () => void;
}

export const MobileFilterDrawer = ({
  clearAll,
  isOpen,
  onClose,
  btnRef,
}: MobileFilterDrawerProps) => {
  const { hasFilters } = useExploreContext();

  return (
    <Drawer
      finalFocusRef={btnRef}
      isOpen={isOpen}
      placement="top"
      onClose={onClose}
    >
      <DrawerOverlay />
      <DrawerContent height="100dvh">
        <DrawerCloseButton />
        <DrawerHeader>Filters</DrawerHeader>

        <DrawerBody>
          <VStack>
            <PropertyFilterContent />
            <SellerPreferenceFilterContent />
            <DemandFilterContent />
          </VStack>
        </DrawerBody>

        <DrawerFooter justifyContent="space-between">
          <Button colorScheme="red" isDisabled={!hasFilters} onClick={clearAll}>
            Clear All
          </Button>
          <Button onClick={onClose}>Apply</Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};
