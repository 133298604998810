import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useAccessControlInviteMutation } from '@client/graphql/__generated__/main-operations';
import {
  AccessControlInviteInput,
  AccessRole,
} from '@client/graphql/__generated__/types';
import { Formik } from 'formik';
import { FC, memo } from 'react';
import { Select } from '~/services/document/common/Select';
import { enumToLabel } from '~/services/document/utils/enums';
import { PhoneNumberInputGroup } from '../Inputs/PhoneNumberInputGroup';

interface AccessControlInviteFormProps {
  listingId?: string;
  partyId?: string;
  organizationId?: string;
  inviteAccessRoles?: AccessRole[];
  inviteDefaultAccessRole?: AccessRole;
  onSuccess?: () => void;
}

export const AccessControlInviteForm: FC<AccessControlInviteFormProps> = memo(
  function AccessControlInviteForm({
    listingId,
    partyId,
    organizationId,
    inviteAccessRoles,
    inviteDefaultAccessRole = AccessRole.ADMIN,
    onSuccess,
  }) {
    const [invite, { loading }] = useAccessControlInviteMutation();

    return (
      <Formik<AccessControlInviteInput>
        initialValues={{
          phoneNumber: '',
          email: '',
          accessRole: inviteDefaultAccessRole,
          listingId,
          partyId,
          organizationId,
        }}
        onSubmit={async (values) => {
          await invite({
            variables: {
              input: { ...values, phoneNumber: String(values.phoneNumber) },
            },
          });
          onSuccess?.();
        }}
      >
        {({ handleSubmit, handleChange, values }) => (
          <form onSubmit={handleSubmit}>
            <Tabs>
              <TabList>
                <Tab>Phone Number</Tab>
                <Tab>Email</Tab>
              </TabList>

              <Box pt={6}>
                <Select
                  formatOptionLabel={({ label }) => (
                    <VStack alignItems="stretch" spacing={0}>
                      <Text>{label}</Text>
                    </VStack>
                  )}
                  isSearchable={false}
                  options={inviteAccessRoles?.map((role) => ({
                    label: enumToLabel(role),
                    value: role,
                  }))}
                  value={{
                    label: enumToLabel(values.accessRole),
                    value: values.accessRole,
                  }}
                  onChange={(option) =>
                    handleChange({
                      target: { name: 'accessRole', value: option?.value },
                    })
                  }
                />
              </Box>

              <TabPanels>
                <TabPanel px={0}>
                  <FormControl>
                    <FormLabel htmlFor="phoneNumber">Phone Number</FormLabel>
                    <PhoneNumberInputGroup
                      id="phoneNumber"
                      name="phoneNumber"
                      value={values.phoneNumber}
                      onChange={(e) =>
                        handleChange({
                          target: {
                            name: 'phoneNumber',
                            value: e.currentTarget.value,
                          },
                        })
                      }
                    />
                  </FormControl>
                </TabPanel>
                <TabPanel px={0}>
                  <FormControl>
                    <FormLabel htmlFor="email">Email</FormLabel>
                    <Input
                      id="email"
                      name="email"
                      type="email"
                      value={values.email}
                      onChange={handleChange}
                    />
                  </FormControl>
                </TabPanel>
              </TabPanels>
            </Tabs>

            <Button isLoading={loading} mt={4} type="submit" width="100%">
              Submit
            </Button>
          </form>
        )}
      </Formik>
    );
  }
);
