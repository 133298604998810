import {
  Card,
  CardBody,
  Flex,
  Heading,
  Icon,
  Tag,
  Text,
} from '@chakra-ui/react';
import isNil from 'lodash/isNil';
import { TrendingDownIcon, TrendingUpIcon } from 'lucide-react';
import { formatToCurrency } from '~/services/document/utils/number';

interface OrganizationListingsTabProps {
  count: number;
  label: string;
  sumPrice: number;
  countDifference?: number;
  onClick?: () => void;
  isSelected?: boolean;
}

export const OrganizationListingsStatus = ({
  count,
  label,
  sumPrice,
  countDifference,
  onClick,
  isSelected,
}: OrganizationListingsTabProps) => {
  const countDifferenceColor = !countDifference
    ? 'whiteAlpha.600'
    : countDifference > 0
      ? 'green.500'
      : 'red.500';

  const formattedSumPrice = formatToCurrency(sumPrice, 1, 'compact');

  return (
    <Card
      _after={
        isSelected
          ? {
              content: '""',
              position: 'absolute',
              bottom: '-27px',
              right: 'calc(50% - 14px)',
              borderWidth: '14px',
              borderStyle: 'solid',
              borderTopColor: 'opaque.indigo_800_whiteAlpha_100',
              borderLeftColor: 'transparent',
              borderRightColor: 'transparent',
              borderBottomColor: 'transparent',
              zIndex: 3,
            }
          : undefined
      }
      _before={
        isSelected
          ? {
              content: '""',
              position: 'absolute',
              bottom: '-30px',
              right: 'calc(50% - 15px)',
              borderWidth: '15px',
              borderStyle: 'solid',
              borderTopColor: 'cyan.500',
              borderLeftColor: 'transparent',
              borderRightColor: 'transparent',
              borderBottomColor: 'transparent',
              zIndex: 2,
            }
          : undefined
      }
      borderColor={isSelected ? 'cyan.500' : undefined}
      cursor="pointer"
      data-testid={`organization-listings-status-${label
        .split(' ')[0]
        .toLowerCase()}`}
      flexGrow={1}
      width="250px"
      onClick={onClick}
    >
      <CardBody>
        <Flex direction="column" gap={4} justifyContent="center">
          <Heading color="whiteAlpha.600" size="headline">
            {label}
          </Heading>
          <Flex alignItems="center" gap={2}>
            <Heading size="lg">{count}</Heading>
            {!isNil(countDifference) && (
              <Flex alignItems="center" gap={1}>
                {!!countDifference && (
                  <Icon
                    as={countDifference > 0 ? TrendingUpIcon : TrendingDownIcon}
                    color={countDifferenceColor}
                  />
                )}
                <Text
                  color={countDifferenceColor}
                  fontSize="sm"
                  fontWeight="medium"
                  letterSpacing="0.88px"
                  textTransform="uppercase"
                >
                  {countDifference
                    ? `${Math.abs(countDifference)} This Week`
                    : 'No Change'}
                </Text>
              </Flex>
            )}
          </Flex>
          <Flex>
            <Tag color="cyan.500">{formattedSumPrice}</Tag>
          </Flex>
        </Flex>
      </CardBody>
    </Card>
  );
};
