import { CheckCircleIcon } from '@chakra-ui/icons';
import { Flex, SlideFade, Text } from '@chakra-ui/react';
import { ReactNode, useEffect, useState } from 'react';

interface ProcessingAnimationStep {
  text: string;
  iconOverride?: ReactNode;
}

interface ProcessingAnimationProps {
  steps: ProcessingAnimationStep[];
  animationDuration?: {
    short: number;
    long: number;
  };
  onFinish?: () => void;
}

export const ProcessingAnimation = ({
  steps,
  animationDuration = {
    short: 500,
    long: 1500,
  },
  onFinish,
}: ProcessingAnimationProps) => {
  const [showStepIndex, setShowStepIndex] = useState<number>(0);
  const short =
    window.ENVS.environment === 'e2e' ? 10 : animationDuration.short;
  const long = window.ENVS.environment === 'e2e' ? 50 : animationDuration.long;

  useEffect(() => {
    if (showStepIndex === 0) {
      setTimeout(() => {
        setShowStepIndex(1);
      }, short);

      return;
    }
    if (showStepIndex < steps.length + 1) {
      setTimeout(() => {
        setShowStepIndex((prev) => prev + 1);
      }, long);
    } else {
      onFinish && onFinish();
    }
  }, [showStepIndex]); //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Flex direction="column" gap={4}>
      {steps.map((step, index) => (
        <SlideFade key={step.text} in={showStepIndex > index}>
          <Flex alignItems="center" flexGrow={1} gap={2}>
            {step.iconOverride ? (
              step.iconOverride
            ) : (
              <CheckCircleIcon color="green.500" />
            )}
            <Text>{step.text}</Text>
          </Flex>
        </SlideFade>
      ))}
    </Flex>
  );
};
