import {
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  useToast,
} from '@chakra-ui/react';
import { UserObject } from '@client/graphql/__generated__/types';
import { useState } from 'react';
import OTPInput from 'react-otp-input';
import { PhoneNumberInputGroup } from '~/common/components/Inputs/PhoneNumberInputGroup';
import { useStytchLogin } from '../hooks/useStytchLogin';

interface StytchLoginFormProps {
  onLogin: (user: UserObject) => void | Promise<void>;
}

export const StytchLoginForm = ({ onLogin }: StytchLoginFormProps) => {
  const toast = useToast();
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [methodId, setMethodId] = useState<string>();
  const [code, setCode] = useState<string>('');

  const { getMethodIdAndLogIn, authenticateOtpAndLogIn } = useStytchLogin();

  return (
    <VStack
      as="form"
      borderRadius="md"
      spacing={4}
      w="100%"
      onSubmit={(e) => {
        e.preventDefault();

        if (!methodId) {
          setIsLoading(true);

          void getMethodIdAndLogIn(`+1${phoneNumber}`)
            .then((data) => {
              setMethodId(data.method_id);
            })
            .catch((error: Error) => {
              // eslint-disable-next-line no-console
              console.error(error);
              toast({
                colorScheme: 'red',
                title: 'Error',
                description: error.message,
              });
            })
            .finally(() => {
              setIsLoading(false);
            });
        }

        if (code && methodId) {
          setIsLoading(true);

          void authenticateOtpAndLogIn(code, methodId)
            .then(async (user) => {
              await onLogin(user);
            })
            .catch(() => {
              toast({
                status: 'error',
                duration: 3000,
                isClosable: true,
                title: 'Authentication Error',
                description: 'Invalid code. Please try again.',
              });
            })
            .finally(() => {
              setIsLoading(false);
            });
        }
      }}
    >
      <FormControl isRequired>
        <PhoneNumberInputGroup
          isDisabled={!!methodId}
          onChange={(e) => setPhoneNumber(e.currentTarget.value)}
        />
      </FormControl>

      {methodId && (
        <FormControl isRequired>
          <FormLabel>Enter Code</FormLabel>
          <OTPInput
            shouldAutoFocus
            containerStyle={{
              width: '100%',
              justifyContent: 'space-between',
            }}
            inputStyle={{
              width: '40px',
              paddingLeft: 0,
              paddingRight: 0,
              fontSize: '18px',
            }}
            inputType="number"
            numInputs={6}
            renderInput={(inputProps) => <Input {...inputProps} />}
            value={code}
            onChange={setCode}
            onPaste={(e) => {
              const value = e.clipboardData.getData('text/plain');

              if (value && value.length === 6) {
                setCode(value);
              }
            }}
          />
        </FormControl>
      )}

      <Button isLoading={isLoading} type="submit" width="100%">
        Next
      </Button>
    </VStack>
  );
};
