import {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useDisclosure,
} from '@chakra-ui/react';
import { FC, useEffect, useState } from 'react';
import { Modal } from '../Modal/Modal';
import { AddUserForm } from './AddUserForm';
import { SwitchUserForm } from './SwitchUserForm';

export const SwitchUser: FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [switchUserKey, setSwitchUserKey] = useState(1);

  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'k' && e.metaKey) {
        e.preventDefault();
        onOpen();
      }
    };

    window.addEventListener('keydown', onKeyDown);

    return () => window.removeEventListener('keydown', onKeyDown);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isOpen ? (
    <Modal isOpen={true} title="Switch Users" onClose={onClose}>
      <Tabs>
        <TabList>
          <Tab>Switch User</Tab>
          <Tab>Add User</Tab>
        </TabList>

        <TabPanels>
          <TabPanel px={0}>
            <SwitchUserForm key={switchUserKey} />
          </TabPanel>
          <TabPanel px={0}>
            <AddUserForm
              onUserAdded={() => setSwitchUserKey((prev) => prev + 1)}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Modal>
  ) : null;
};
