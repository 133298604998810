import { TourType } from '@client/graphql/__generated__/types';
import { TourProps, TourProvider, useTour } from '@reactour/tour';
import { PropsWithChildren, createContext, useContext } from 'react';
import { useViewedTours } from '../../hooks/useViewedTours';
import { customStyles } from './styles';

interface CustomTourContextType extends TourProps {
  viewedTours: TourType[];
  getHasViewedTour: (tourName: TourType) => boolean;
  updateHasViewedTour: (tourNames: TourType[]) => Promise<void>;
}

const CustomTourContext = createContext<CustomTourContextType>(
  {} as CustomTourContextType
);

const CombinedTourProvider = ({ children }: PropsWithChildren) => {
  const { viewedTours, getHasViewedTour, updateHasViewedTour } =
    useViewedTours();

  const tourProps = useTour();

  return (
    <CustomTourContext.Provider
      value={{
        viewedTours,
        getHasViewedTour,
        updateHasViewedTour,
        ...tourProps,
      }}
    >
      {children}
    </CustomTourContext.Provider>
  );
};

export const CustomTourProvider = ({ children }: PropsWithChildren) => {
  return (
    // need to disable interaction with highlighted items or else the tour could get stuck in a weird state
    <TourProvider
      disableInteraction
      startAt={0}
      steps={[]}
      styles={customStyles}
    >
      <CombinedTourProvider>{children}</CombinedTourProvider>
    </TourProvider>
  );
};

export const useCustomTourContext = () => {
  return useContext(CustomTourContext);
};
