import { useToast } from '@chakra-ui/react';
import {
  DeleteListingDocumentsInput,
  FileObject,
  ListingDocumentTypeEnum,
  ListingFragment,
  UploadListingDocumentInput,
} from '@client/graphql/__generated__/types';
import { AGENT_NEGOTIATION_PREFERENCE_GROUPS } from '../../Preferences/constants';
import { useSellerPreferences } from '../../Preferences/hooks/useSellerPreferences';
import { DocumentFormValueType } from '../types';
import { useListingDocumentMutations } from './useListingDocumentMutations';
import { useListingDocumentQueries } from './useListingDocumentQueries';

interface UseDocumentsPageProps {
  listing: ListingFragment;
  refetchListing: () => Promise<unknown>;
  showToast?: boolean;
}

export const useDocumentsPage = ({
  listing,
  showToast = true,
}: UseDocumentsPageProps) => {
  const toast = useToast();

  const {
    existingDocs,
    disclosureAndAgentNegotiationDocumentTypes,
    additionalDocumentTypes,
    agentNegotiationDocumentTypes,
    loading,
    refetchExistingDocs,
  } = useListingDocumentQueries(listing);

  const {
    uploadListingDocuments,
    uploading,
    deleteListingDocuments,
    deleting,
    uploadAndExtractAgentNegotiation,
    uploadingAgentNegotiation,
  } = useListingDocumentMutations();

  const {
    initialValues: initialAgentNegotiationValues,
    onSubmit: onSubmitAgentNegotiationPreferences,
    isUpdating: updatingAgentNegotiationPreferences,
    loadingPreferences: loadingAgentNegotiationPreferences,
    preferenceGroups: agentNegotiationPreferenceGroup,
  } = useSellerPreferences({
    listing,
    showToast: false,
    skipNotifications: true,
    preferenceGroupMeta: AGENT_NEGOTIATION_PREFERENCE_GROUPS,
  });

  const initialValues: DocumentFormValueType =
    existingDocs?.reduce((acc, val) => {
      if (!val.listingDocumentType?.id) {
        const key = val.id;

        return {
          ...acc,
          [key]: {
            file: val.file,
            customName: val.customDocumentName,
            customDocumentType: val.customDocumentType,
          },
        };
      }

      return {
        ...acc,
        [val.listingDocumentType.id]: {
          file: val.file,
        },
      };
    }, {}) || {};

  const showAgentCompensationForm = agentNegotiationDocumentTypes?.some(
    (type) => (initialValues[type.id]?.file as FileObject)?.url
  );

  const uploadDocuments = async (values: DocumentFormValueType) => {
    const deleteInput: DeleteListingDocumentsInput = {
      listingId: listing.id,
      listingDocumentUploadIds:
        existingDocs
          ?.filter((doc) =>
            doc.listingDocumentType?.id
              ? !values[doc.listingDocumentType.id]?.file
              : !values[doc.id]?.file
          )
          .map((doc) => doc.id) || [],
    };

    const isDeletingAgentNegotiation = existingDocs?.some(
      (doc) =>
        doc.listingDocumentType?.type ===
          ListingDocumentTypeEnum.AGENT_NEGOTIATION &&
        !values[doc.listingDocumentType?.id]?.file
    );

    if (isDeletingAgentNegotiation) {
      await onSubmitAgentNegotiationPreferences({
        buyers_agent_compensation_absolute: null,
        buyers_agent_compensation_percent: null,
        buyers_agent_compensation_payable_by: null,
      });
    }

    const uploadInput: UploadListingDocumentInput = {
      listingId: listing?.id,
      files: Object.entries(values).reduce((acc, [key, val]) => {
        if (val.file instanceof File) {
          return [
            ...acc,
            {
              id: existingDocs?.find((doc) => doc.id === key)?.id,
              listingDocumentTypeId: !val.customName ? key : undefined,
              customDocumentName: val.customName || undefined,
              customDocumentType: val.customDocumentType,
              file: val.file,
            },
          ];
        }

        return acc;
      }, []),
    };

    // const uploadAndExtractAgentNegotiationInput = {
    //   listingId: listing?.id,
    //   files: Object.entries(values).reduce((acc, [key, val]) => {
    //     const isAgentNegotiationFile = agentNegotiationDocumentTypes?.some(
    //       (doc) => doc.id === key
    //     );
    //     if (val.file instanceof File && isAgentNegotiationFile) {
    //       return [
    //         ...acc,
    //         {
    //           id: existingDocs?.find((doc) => doc.id === key)?.id,
    //           listingDocumentTypeId: !val.customName ? key : undefined,
    //           customDocumentName: val.customName || undefined,
    //           customDocumentType: val.customDocumentType,
    //           file: val.file,
    //         },
    //       ];
    //     }

    //     return acc;
    //   }, []),
    // };

    const { listingDocumentUploadIds } = deleteInput;
    try {
      let toastMessage = 'Successfully uploaded your document';
      if (listingDocumentUploadIds.length) {
        await deleteListingDocuments({ variables: { input: deleteInput } });
        toastMessage = 'Successfully deleted your document';
      }

      const { files } = uploadInput;
      if (files.length) {
        await uploadListingDocuments({ variables: { input: uploadInput } });
        toastMessage = 'Successfully uploaded your document';
      }

      // if (uploadAndExtractAgentNegotiationInput.files.length) {
      //   await uploadAndExtractAgentNegotiation({
      //     variables: { input: uploadAndExtractAgentNegotiationInput },
      //   });
      //   toastMessage = 'Successfully uploaded your document';
      //   await refetchListing();
      // }

      if (showToast) {
        toast({
          description: toastMessage,
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      }

      await refetchExistingDocs();
    } catch (error) {
      toast({
        description:
          'There was an error uploading your documents. Please try again.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return {
    initialValues,
    uploadDocuments,
    uploading,
    deleting,
    loading,
    uploadingAgentNegotiation,
    existingDocs,
    disclosureAndAgentNegotiationDocumentTypes,
    agentNegotiationDocumentTypes,
    additionalDocumentTypes,
    refetchExistingDocs,
    uploadListingDocuments,
    deleteListingDocuments,
    uploadAndExtractAgentNegotiation,
    agentNegotiationPreferenceGroup,
    onSubmitAgentNegotiationPreferences,
    initialAgentNegotiationValues,
    updatingAgentNegotiationPreferences,
    loadingAgentNegotiationPreferences,
    showAgentCompensationForm,
  };
};
