import {
  ListingFragment,
  PreferenceObject,
  PreferenceValueObject,
  PublicListingFragment,
  PublicPreferenceObject,
} from '@client/graphql/__generated__/types';
import { useMemo } from 'react';
import { StyledFormInputType } from '~/apps/consumer/components/StyledFormInput/StyledFormInput';
import {
  GroupMetaType,
  PREFERENCE_GROUPS,
} from '~/apps/consumer/pages/ListingSetup/Preferences/constants';
import {
  filterPreferenceMetaByPreference,
  getInitialPreferenceValues,
} from '~/apps/consumer/pages/ListingSetup/Preferences/utils';

export const useSellerPreferenceGroups = (
  listing: ListingFragment | PublicListingFragment,
  preferences: PreferenceObject[] | PublicPreferenceObject[],
  preferenceGroups: GroupMetaType[] = PREFERENCE_GROUPS
) => {
  const preferenceValues = listing?.listingPreferences;

  const prefGroups = useMemo(
    () =>
      preferenceGroups.map((group) => {
        const filteredPrefs = filterPreferenceMetaByPreference(
          group?.preferences,
          preferences
        );

        group?.preferences?.forEach((preferenceMeta) => {
          if (
            preferenceMeta.inputType === StyledFormInputType.SELECT &&
            preferenceMeta.fieldSlug === 'loan_types'
          ) {
            //build options from listing mls terms data
            const options = listing?.mlsListing?.terms
              ?.split(', ')
              .map((term) => {
                return {
                  label: term,
                  value: term,
                };
              });
            preferenceMeta.inputProps.isDisabled = true;
            preferenceMeta.inputProps.options = options;
          }
        });

        const defaultValues = getInitialPreferenceValues(
          filteredPrefs,
          preferenceValues as PreferenceValueObject[]
        );

        return {
          title: group.title,
          preferences: filteredPrefs,
          defaultValues,
        };
      }),
    [listing, preferences, preferenceValues, preferenceGroups]
  );

  return prefGroups;
};
