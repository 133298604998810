import { Flex, Icon, Link, Spinner, Text } from '@chakra-ui/react';
import { FileObject } from '@client/graphql/__generated__/types';
import { useField } from 'formik';
import { ArrowUpRight, FileText, Trash2 } from 'lucide-react';
import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

interface DropzoneFieldProps {
  name: string;
  label: string;
  disabled?: boolean;
  loading?: boolean;
}

export const DropzoneField = ({
  name,
  label,
  disabled = false,
  loading = false,
}: DropzoneFieldProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, meta, helpers] = useField<File | FileObject | null>({
    name,
    type: 'file',
  });

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      void helpers.setValue(acceptedFiles[0]);
    },
    [helpers]
  );

  const { getRootProps, getInputProps, isDragAccept } = useDropzone({
    accept: {
      'application/pdf': ['.pdf'],
    },
    onDrop,
    disabled,
  });

  const hasFile = Boolean(field?.value);
  const isNewFile = field?.value instanceof File;

  return (
    <Flex
      alignItems="center"
      bgColor={isDragAccept ? 'whiteAlpha.300' : undefined}
      border="1px dashed"
      borderColor={disabled ? 'whiteAlpha.200' : 'whiteAlpha.300'}
      borderRadius="8px"
      cursor={disabled ? 'not-allowed' : 'pointer'}
      gap={2}
      height={16}
      minWidth={0}
      p={4}
      {...getRootProps()}
    >
      <Flex
        alignItems="center"
        flexBasis={9}
        flexShrink={0}
        height={10}
        justifyContent="center"
      >
        <Icon
          as={FileText}
          boxSize={6}
          color={
            disabled
              ? 'whiteAlpha.300'
              : hasFile
                ? 'purple.500'
                : 'whiteAlpha.600'
          }
        />
        <input {...getInputProps()} />
      </Flex>
      <Text
        fontWeight={isNewFile ? '700' : '400'}
        opacity={hasFile ? 1 : 0.5}
        overflow="hidden"
        textOverflow="ellipsis"
        whiteSpace="nowrap"
        width="100%"
      >
        {hasFile
          ? `${label} - ${
              (field?.value as File)?.name ||
              (field?.value as FileObject)?.fileName
            }`
          : label}
      </Text>
      {loading && <Spinner />}
      {(field?.value as FileObject)?.url && (
        <Flex alignItems="center" gap={4}>
          <Link
            isExternal
            color="whiteAlpha.700"
            href={(field?.value as FileObject)?.url}
            size="sm"
            variant="link"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Flex alignItems="center" gap={1}>
              <Icon as={ArrowUpRight} boxSize={4} />
              View
            </Flex>
          </Link>
          <Link
            color="whiteAlpha.700"
            size="sm"
            onClick={(e) => {
              e.stopPropagation();
              void helpers.setValue(null);
            }}
          >
            <Flex alignItems="center" gap={1}>
              <Icon as={Trash2} boxSize={4} />
              Delete
            </Flex>
          </Link>
        </Flex>
      )}
    </Flex>
  );
};
