import { AddIcon } from '@chakra-ui/icons';
import { Flex, Text } from '@chakra-ui/react';
import { useComparablesContext } from '~/apps/consumer/pages/ListingSetup/Comparables/ComparablesContext';
import { COLUMN_WIDTH } from './constants';

export const AddComparableColumn = () => {
  const { onOpenModal } = useComparablesContext();

  return (
    <Flex
      alignItems="center"
      backgroundColor="bg.main"
      cursor="pointer"
      direction="column"
      flexBasis={COLUMN_WIDTH}
      flexShrink={0}
      onClick={() => onOpenModal && onOpenModal()}
    >
      <Flex alignItems="center" height="50%" justifyContent="center">
        <AddIcon color="gray.400" />
        <Text color="gray.400" ml={1}>
          Add a comp
        </Text>
      </Flex>
    </Flex>
  );
};
