import { useBreakpointValue } from '@chakra-ui/react';

export const useIsMobile = (tabletsAreMobile = false) => {
  const breakpointOptions = tabletsAreMobile
    ? { base: true, lg: false }
    : { base: true, md: false };

  // need 'ssr: false' to make sure it doesn't return base value on initial load before the breakpoint is detected
  return useBreakpointValue(breakpointOptions, { ssr: false });
};
