import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Flex,
  Image,
  Text,
} from '@chakra-ui/react';
import {
  EventTypes,
  OfferSource,
  OfferStatus,
  PublicListingObject,
} from '@client/graphql/__generated__/types';
import { ExternalLinkIcon } from 'lucide-react';
import { NavLink, useNavigate } from 'react-router-dom';
import { match } from 'ts-pattern';
import { useBuyerPartyQueries } from '~/apps/consumer/pages/Buyers/hooks/useBuyerPartyQueries';
import { getStorefrontRoute } from '~/apps/consumer/utils/storefront.utils';
import { CenterSpinner } from '~/common/components/CenterSpinner';
import { useIsMobile } from '~/common/hooks/useIsMobile';
import { getPropertySource } from '../../../utils/listing.utils';
import { OfferCardKeyTermFields } from '../OfferCardKeyTermFields';
import { OfferStatusPopover } from './OfferStatusPopover';

interface PurchaseOfferCardProps {
  offerId: string;
}

// TODO: Remove 'BUYERS_ALL_SIGNED' and just use the offer status directly after BLO-732 is resolved
type OfferCardStatus = OfferStatus | 'BUYERS_ALL_SIGNED';

export const PurchaseOfferCard = ({ offerId }: PurchaseOfferCardProps) => {
  const navigate = useNavigate();
  const isMobile = useIsMobile();

  const { offer, offerCardSections, listing, events, isLoading } =
    useBuyerPartyQueries({
      offerId,
    });

  const mlsListing = listing?.mlsListing;

  if (isLoading || !events) {
    return <CenterSpinner />;
  }

  let offerStatus: OfferCardStatus | undefined;
  // TODO: Use OfferStatus.SUBMITTED_TO_LISTING_AGENT directly instead of !!offer?.submittedAt after
  // BLO-730 is resolved
  if (!!offer?.submittedAt) {
    offerStatus = OfferStatus.SUBMITTED_TO_LISTING_AGENT;
  } else if (
    offer?.events?.some((event) => event.name === EventTypes.BUYERS_ALL_SIGNED)
  ) {
    offerStatus = 'BUYERS_ALL_SIGNED';
  } else {
    offerStatus = offer?.status;
  }

  const isUploadedOffer = offer?.source === OfferSource.BA_UPLOAD;

  let ctaButtonText: string | undefined;
  if (isUploadedOffer) {
    ctaButtonText = 'View uploaded offer';
  } else {
    ctaButtonText = match(offerStatus)
      .with(
        OfferStatus.DRAFT,
        OfferStatus.BUYER_SIGNATURE_REQUEST_SENT,
        OfferStatus.ACCEPTED,
        OfferStatus.DECLINED,
        OfferStatus.RESCINDED,
        OfferStatus.CANCELED,
        OfferStatus.STATUS_UNKNOWN,
        OfferStatus.ACTIVE,
        OfferStatus.SUBMITTED_TO_LISTING_AGENT,
        OfferStatus.INGESTED,
        undefined,
        () => {
          return 'View offer';
        }
      )
      .with('BUYERS_ALL_SIGNED', () => {
        return 'Review and submit';
      })
      .exhaustive();
  }

  const offerFlowRoute = `./${offerId}/${
    isUploadedOffer ? 'uploaded' : 'review'
  }`;

  return (
    <Card
      _hover={{
        cursor: 'pointer',
      }}
      size="lg"
      width="100%"
      onClick={() => navigate(offerFlowRoute)}
    >
      <CardHeader
        borderBottom="1px solid"
        borderBottomColor="border.divider"
        p={{ base: 4, md: 7 }}
        pb={6}
      >
        <Flex
          direction={{ base: 'column', md: 'row' }}
          justifyContent="space-between"
        >
          <Flex
            as={NavLink}
            cursor="pointer"
            direction={isMobile ? 'column' : 'row'}
            gap={3}
            onClick={(e) => {
              e.stopPropagation();
              if (listing) {
                window.open(
                  getStorefrontRoute(listing as PublicListingObject),
                  '_blank'
                );
              }
            }}
          >
            <Image
              borderRadius="4px"
              height={{ base: '100%', md: '40px' }}
              src={getPropertySource(mlsListing?.photos?.[0])}
              width={{ base: '100%', md: '50px' }}
            />
            <Flex flexDirection="row" gap={3}>
              <Flex flexDirection="column" justifyContent="center">
                {mlsListing ? (
                  <>
                    <Flex>
                      <Text fontWeight="medium">
                        {mlsListing?.address?.streetNumberText}{' '}
                        {mlsListing?.address?.streetName}{' '}
                        {mlsListing?.address?.unit ?? ''}
                      </Text>
                    </Flex>
                    <Flex>
                      <Text color="gray.400">
                        {mlsListing?.address?.city} {mlsListing?.address?.state}{' '}
                        {mlsListing?.address?.postalCode}{' '}
                      </Text>
                    </Flex>
                  </>
                ) : (
                  <Flex>
                    <Text fontWeight="medium">Listing not found</Text>
                  </Flex>
                )}
              </Flex>
              <Flex alignSelf="center" color="link.nav">
                <ExternalLinkIcon />
              </Flex>
            </Flex>
          </Flex>
          {offerStatus !== OfferStatus.DRAFT && events?.length > 0 && (
            <Flex mt={{ base: 3, md: 0 }}>
              <OfferStatusPopover userEventsForOffer={events} />
            </Flex>
          )}
        </Flex>
      </CardHeader>
      <CardBody p={{ base: 4, md: 7 }}>
        <Flex flexDirection="column" gap={4}>
          <OfferCardKeyTermFields
            cardSections={offerCardSections}
            fieldValues={offer?.contract?.fieldValues}
          />
          {ctaButtonText && (
            <Flex>
              <Button width="100%" onClick={() => navigate(offerFlowRoute)}>
                {ctaButtonText}
              </Button>
            </Flex>
          )}
        </Flex>
      </CardBody>
    </Card>
  );
};
