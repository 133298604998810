/* eslint-disable @typescript-eslint/no-explicit-any */
import { QueryHookOptions, QueryResult } from '@apollo/client';
import { PageInfoObject } from '@client/graphql/__generated__/types';
import { unionBy } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';

interface UseInfiniteQueryOptions {
  scrollableRef?: HTMLDivElement;
}

interface GenericPaginated {
  pageInfo: PageInfoObject;
  results: any[];
  __typename: string;
}

const useIntersectionObserver = (
  onIntersect: () => void,
  scrollableRef?: HTMLDivElement
) => {
  return useMemo(() => {
    return new window.IntersectionObserver(
      (entries) => {
        const entry = entries[0];

        if (entry?.isIntersecting) {
          void onIntersect();
        }
      },
      { root: scrollableRef, threshold: [0.8, 1] }
    );
  }, [onIntersect, scrollableRef]);
};

const updateQuery = (
  previous: Record<string, any>,
  { fetchMoreResult }: { fetchMoreResult: Record<string, any> }
): any => {
  if (!fetchMoreResult) return previous;

  const queryName = Object.keys(fetchMoreResult)[0];
  const prevData = previous?.[queryName] as GenericPaginated;
  const nextData = fetchMoreResult?.[queryName] as GenericPaginated;
  const prevResults = prevData?.results || [];
  const nextResults = nextData?.results || [];
  const pageInfo = nextData?.pageInfo;

  const results = {
    [queryName]: {
      pageInfo: pageInfo,
      results: unionBy(prevResults?.concat(nextResults), 'id'),
      __typename: nextData?.__typename,
    },
  };

  return results;
};

/**
 * Infinite query hook for @QueryPaginated
 *
 * **Usage:**
 *
 * ```
 * import { useFieldListQuery } from '@client/graphql/__generated__/document-operations';
 *
 * const [triggerRef, { data, loading }] = useInfiniteQuery(useFieldListQuery, {
 *   variables: {
 *     filters: {
 *       search
 *     }
 *   }
 * });
 *
 * return (
 *   <Box>
 *     ... // List components
 *
 *     // Add at the end to trigger fetchMore when the element is visible
 *     <Box ref={triggerRef} />
 *   </Box>
 * );
 *
 * ```
 *
 */
export const useInfiniteQuery = <TQuery extends Record<string, any>, TVars>(
  // Auto generated query hooks imported from '@client/graphql/__generated__/document-operations'
  useQueryHook: (
    baseOptions?: QueryHookOptions<TQuery, TVars>
  ) => QueryResult<TQuery, TVars>,
  queryOptions?: QueryHookOptions<TQuery, TVars>,
  options?: UseInfiniteQueryOptions
): [(ref: HTMLDivElement) => void, QueryResult<TQuery, TVars>] => {
  const [observerRef, setObserverRef] = useState<HTMLDivElement>();
  const [loading, setLoading] = useState(false);
  const queryResult = useQueryHook(queryOptions);
  const data = queryResult.data as Record<string, any>;
  const fetchMore = queryResult.fetchMore;

  const fetchMoreAndUpdateQuery = useCallback(() => {
    if (!data) return;

    const queryName = Object.keys(data)[0];
    const paginated = data?.[queryName] as GenericPaginated;
    const pageInfo = paginated?.pageInfo;

    if (!pageInfo.hasNextPage || queryResult.loading) {
      return;
    }

    setLoading(true);

    void fetchMore({
      variables: {
        cursor: pageInfo?.nextCursor,
      },
      updateQuery,
    }).finally(() => {
      setLoading(false);
    });
  }, [data, fetchMore, queryResult.loading]);

  const observer = useIntersectionObserver(
    fetchMoreAndUpdateQuery,
    options?.scrollableRef
  );

  useEffect(() => {
    if (observerRef) {
      observer.observe(observerRef);
    }

    return () => observer.disconnect();
  }, [observerRef, observer]);

  return [
    (ref: HTMLDivElement) => setObserverRef(ref),
    {
      ...queryResult,
      loading: queryResult.loading || loading,
    },
  ];
};
