import { Box, Flex, Text } from '@chakra-ui/react';
import { MlsListingStatus } from '@client/graphql/__generated__/types';
import { DEFAULT_STATUS_ITEMS } from '../constants';

interface IListingStatusLegendProps {
  selectedStatuses?: MlsListingStatus[];
  onClick: (statuses: MlsListingStatus[]) => void;
}

export const ListingStatusLegend = ({
  onClick,
  selectedStatuses,
}: IListingStatusLegendProps) => {
  const handleClick = (status: MlsListingStatus[]) => {
    let newSelectedStatuses: MlsListingStatus[] = [];
    const isStatusAlreadySelected = status.every(
      (s) => selectedStatuses?.includes(s)
    );

    if (isStatusAlreadySelected) {
      newSelectedStatuses =
        selectedStatuses?.filter((s) => !status.includes(s)) || [];
    } else {
      newSelectedStatuses = [...(selectedStatuses || []), ...status];
    }

    const flattenedStatuses = DEFAULT_STATUS_ITEMS.reduce(
      (acc, item) => [...acc, ...item.status],
      []
    );
    if (flattenedStatuses.every((s) => selectedStatuses?.includes(s))) {
      onClick([]);
    } else {
      onClick(newSelectedStatuses);
    }
  };

  return (
    <Flex
      align="center"
      bg="black"
      borderRadius="8px"
      bottom={{ base: '70px', md: '10px' }}
      boxShadow="0px 0px 14px 0px rgba(0, 0, 0, 0.45)"
      data-testid="listing-status-legend"
      gap={{ base: 3, md: 4 }}
      left={{ base: '4px', md: '120px' }}
      position="absolute"
      px={{ base: 2, md: 5 }}
      py={{ base: 2, md: 4 }}
    >
      {DEFAULT_STATUS_ITEMS.map((item) => (
        <LegendItem
          key={item.name}
          bgColor={item.bgColor}
          isActive={
            !selectedStatuses?.length ||
            item.status.every((s) => selectedStatuses?.includes(s))
          }
          name={item.name}
          status={item.status}
          onClick={handleClick}
        />
      ))}
    </Flex>
  );
};

interface ILegendItemProps {
  status: MlsListingStatus | MlsListingStatus[];
  name: string;
  bgColor: string;
  onClick: (status: MlsListingStatus | MlsListingStatus[]) => void;
  isActive: boolean;
}

const LegendItem = ({
  status,
  name,
  bgColor,
  onClick,
  isActive,
}: ILegendItemProps) => {
  return (
    <Flex
      align="center"
      cursor="pointer"
      data-testid="legend-item"
      gap={1}
      onClick={() => onClick(status)}
    >
      <Box
        bg={bgColor}
        border="1px solid"
        borderColor="whiteAlpha.300"
        borderRadius="2px"
        boxSize={3}
        opacity={isActive ? 1 : 0.5}
      />
      <Text
        color={isActive ? 'white' : 'whiteAlpha.500'}
        fontSize={{ base: '10px', md: '12px' }}
        fontWeight="semibold"
      >
        {name}
      </Text>
    </Flex>
  );
};
