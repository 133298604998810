import { Icon, IconProps } from '@chakra-ui/react';

export const DateIcon = (props: IconProps) => (
  <Icon
    color="primary.500"
    fill="none"
    height="20px"
    viewBox="0 0 20 20"
    width="20px"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M1.56396 5.3125C1.56396 4.48407 2.23554 3.8125 3.06396 3.8125H16.939C17.7674 3.8125 18.439 4.48407 18.439 5.3125V16.9375C18.439 17.7659 17.7674 18.4375 16.939 18.4375H3.06396C2.23554 18.4375 1.56396 17.7659 1.56396 16.9375V5.3125Z"
      fillRule="evenodd"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path
      d="M1.56396 8.3125H18.439"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path
      d="M6.06396 5.5V1.5625"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path
      d="M13.939 5.5V1.5625"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
  </Icon>
);
