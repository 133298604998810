import {
  Box,
  Modal as ChakraModal,
  ModalProps as ChakraModalProps,
  Divider,
  ModalBody,
  ModalBodyProps,
  ModalCloseButton,
  ModalContent,
  ModalContentProps,
  ModalFooter,
  ModalFooterProps,
  ModalHeader,
  ModalHeaderProps,
  ModalOverlay,
} from '@chakra-ui/react';
import {
  FC,
  FormEvent,
  FormEventHandler,
  ReactNode,
  memo,
  useCallback,
  useMemo,
} from 'react';

interface ModalProps extends ChakraModalProps {
  title?: string | ReactNode;
  children: ReactNode;
  contentProps?: ModalContentProps;
  headerProps?: ModalHeaderProps;
  bodyProps?: ModalBodyProps;
  footerProps?: ModalFooterProps;
  onSubmit?: FormEventHandler<HTMLFormElement>;
  footer?: () => ReactNode;
}

export const Modal: FC<ModalProps> = memo(function Modal({
  title,
  children,
  contentProps,
  headerProps,
  bodyProps,
  footerProps,
  footer,
  onSubmit,
  ...props
}) {
  const onSubmitCallback = useCallback<
    FormEventHandler<HTMLFormElement | HTMLDivElement>
  >(
    (e) => {
      e.preventDefault();
      onSubmit && onSubmit(e as FormEvent<HTMLFormElement>);
    },
    [onSubmit]
  );

  const boxProps = useMemo(() => {
    if (onSubmit) {
      return {
        as: 'form' as const,
        onSubmit: onSubmitCallback,
      };
    }

    return {
      as: 'div' as const,
    };
  }, [onSubmit, onSubmitCallback]);

  return (
    <ChakraModal scrollBehavior="inside" {...props}>
      <ModalOverlay />
      <ModalContent {...contentProps}>
        <Box {...boxProps}>
          <ModalCloseButton />

          {title && (
            <ModalHeader color="chakra-body-text" {...headerProps}>
              {title}
            </ModalHeader>
          )}

          <ModalBody color="chakra-body-text" {...bodyProps}>
            {children}
          </ModalBody>

          <Divider />

          {footer && (
            <ModalFooter {...footerProps}>{footer && footer()}</ModalFooter>
          )}
        </Box>
      </ModalContent>
    </ChakraModal>
  );
});
