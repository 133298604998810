import { AddIcon } from '@chakra-ui/icons';
import { Button, Flex, Heading } from '@chakra-ui/react';
import {
  OfferObject,
  PartyFragment,
} from '@client/graphql/__generated__/types';
import { FileX } from 'lucide-react';
import { useProdFeatureFlagEnabled } from '~/common/hooks/useProdFeatureFlagEnabled';
import { EmptyState } from '../../components/EmptyState';
import { FindPropertyModal } from '../../components/MakeOffer/FindPropertyModal';
import { PurchaseOfferCard } from '../../components/Offers/BuyerOffers/PurchaseOfferCard';
import { useFindPropertyEvents } from './hooks/useFindPropertyEvents';

interface PartyPageProps {
  purchaseOffers: Array<Partial<OfferObject>>;
  party: PartyFragment;
}

export const PartyPage = ({ purchaseOffers, party }: PartyPageProps) => {
  const isFlagEnabled = useProdFeatureFlagEnabled('ba-make-offer-on-any-home');

  const { onSelect, isFindPropertyModalOpen, setIsFindPropertyModalOpen } =
    useFindPropertyEvents(party);

  const onStartOffer = () => {
    setIsFindPropertyModalOpen(true);
  };

  return (
    <Flex
      flexDirection="column"
      flexGrow={1}
      h="100vh"
      maxWidth="container.xl"
      mx="auto"
      p={{ base: 5, md: 10 }}
    >
      <Flex flexDirection="row" justifyContent="space-between">
        <Heading mb={6}>Purchase Offers</Heading>
        {isFlagEnabled && (
          <>
            <Button leftIcon={<AddIcon />} size="xs" onClick={onStartOffer}>
              Start an offer
            </Button>
            <FindPropertyModal
              isOpen={isFindPropertyModalOpen}
              onClose={() => setIsFindPropertyModalOpen(false)}
              onSelect={onSelect}
            />
          </>
        )}
      </Flex>

      <Flex flexDirection="column" gap={6} pb={6}>
        {!purchaseOffers.length ? (
          <EmptyState
            headerText="You haven’t made any offers for this buyer yet"
            icon={FileX}
            minH="70vh"
            subText={
              isFlagEnabled ? (
                <Button variant="link" onClick={onStartOffer}>
                  Start an offer
                </Button>
              ) : undefined
            }
          />
        ) : (
          purchaseOffers.map((purchaseOffer) => {
            return (
              <PurchaseOfferCard
                key={purchaseOffer.id}
                offerId={String(purchaseOffer?.id)}
              />
            );
          })
        )}
      </Flex>
    </Flex>
  );
};
