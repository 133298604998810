import {
  Card,
  CardBody,
  CardHeader,
  Grid,
  GridItem,
  Text,
  VStack,
} from '@chakra-ui/react';
import { ActivityType } from '@client/graphql/__generated__/types';
import { useStorefrontDashboardContext } from '../StorefrontDashboardContext';

export const AnalyticsOverviewCard = () => {
  const { activitySummary, subscribers } = useStorefrontDashboardContext();

  const docViewsSummary = activitySummary.find(
    (activity) => activity.activityType === ActivityType.LISTING_DOCUMENT_VIEWED
  );

  const dataSection = (number: number, label: string) => (
    <GridItem
      _last={{ borderRight: 'none' }}
      borderRight={{ base: 'none', md: '1px solid' }}
      borderRightColor={{
        base: 'none',
        md: 'whiteAlpha.100',
      }}
      h="full"
      maxH="160px"
    >
      <VStack
        alignItems="center"
        flex={1}
        h="full"
        justifyContent="flex-start"
        p={4}
        spacing={2}
      >
        <Text
          bg="purpleGradient"
          bgClip="text"
          fontSize="48px"
          fontWeight="medium"
        >
          {number}
        </Text>
        <Text
          color="whiteAlpha.600"
          fontSize="sm"
          fontWeight="medium"
          letterSpacing="0.02em"
          textAlign="center"
          textTransform="uppercase"
        >
          {label}
        </Text>
      </VStack>
    </GridItem>
  );

  return (
    <Card borderRadius="sm" flex={1} h="full" minH="340px">
      <CardHeader py={4}>
        <Text>Analytics</Text>
      </CardHeader>
      <CardBody
        alignItems="center"
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
        pt={0}
        px={0}
      >
        <Grid
          alignItems="center"
          gridTemplateColumns={{
            base: 'repeat(1, 1fr)',
            md: 'repeat(3, 1fr)',
          }}
          h="full"
          justifyContent="center"
          width="100%"
        >
          {dataSection(subscribers.length, 'Guestbook Subscribers')}
          {dataSection(docViewsSummary?.count ?? 0, 'Document Views')}
          {dataSection(
            docViewsSummary?.users.length ?? 0,
            'People Viewing Documents'
          )}
        </Grid>
      </CardBody>
    </Card>
  );
};
