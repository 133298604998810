import { Box, Button, Card, Flex, Text, VStack } from '@chakra-ui/react';
import { PrioritizedListingFragment } from '@client/graphql/__generated__/types';
import { LockIcon } from 'lucide-react';
import { useNavigate } from 'react-router';
import { NavLink } from 'react-router-dom';
import { ListingImageInCard } from '../../components/Listings/ListingCard/ListingImageInCard';
import { PremiumTag } from '../../components/PremiumTag/PremiumTag';
import { getPropertySource } from '../../utils/listing.utils';
import {
  getListingRoute,
  getStorefrontSetupWizardRoute,
  listingOffersPath,
  listingOpenHousesPath,
} from '../../utils/routes.utils';

type MainDashboardListingCardProps = {
  listing: PrioritizedListingFragment;
};

const BUTTON_STYLES = {
  width: 'full',
  borderRadius: 'xs',
  size: 'sm',
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => e.stopPropagation(),
  display: 'flex',
  gap: 1,
};

const GRAY_ICON_STYLES = {
  bg: 'whiteAlpha.50',
  color: 'cyan.500',
  _hover: {
    bg: 'whiteAlpha.100',
  },
};

export const MainDashboardListingCard = ({
  listing,
}: MainDashboardListingCardProps) => {
  const navigate = useNavigate();
  const { id, isPremium, submittedOffersCount, setupDetails, mlsListing } =
    listing;

  const renderOfferButton = () => {
    const commonProps = {
      ...BUTTON_STYLES,
      as: NavLink,
      to: listingOffersPath(id),
    };

    return submittedOffersCount === 0 ? (
      <Button {...commonProps} {...GRAY_ICON_STYLES}>
        Collect offers
      </Button>
    ) : (
      <Button {...commonProps}>
        View offers
        <Text as="sup" fontSize="xs">
          {submittedOffersCount}
        </Text>
      </Button>
    );
  };

  const renderSetupButton = () => {
    if (!isPremium) {
      return (
        <Button
          {...BUTTON_STYLES}
          {...GRAY_ICON_STYLES}
          isDisabled
          _hover={{ cursor: 'not-allowed' }}
          leftIcon={<LockIcon size={14} />}
          opacity={0.3}
        >
          Customize Storefront
        </Button>
      );
    }

    const commonProps = { ...BUTTON_STYLES, as: NavLink };

    if ((listing?.currentAndUpcomingOpenHouses || []).length > 0) {
      return (
        <Button {...commonProps} to={listingOpenHousesPath(id)}>
          Manage Open House
        </Button>
      );
    }

    if (setupDetails?.configuredCount === setupDetails?.progressTotal) {
      return (
        <Button
          {...commonProps}
          {...GRAY_ICON_STYLES}
          to={listingOpenHousesPath(id, true)}
        >
          Setup an Open House
        </Button>
      );
    }

    return (
      <Button
        {...commonProps}
        {...GRAY_ICON_STYLES}
        to={getStorefrontSetupWizardRoute(id)}
      >
        Customize Storefront
        <Text color="whiteAlpha.600" fontSize="xs">
          {setupDetails.configuredCount}/{setupDetails.progressTotal}
        </Text>
      </Button>
    );
  };

  const onCardClick = () => navigate(getListingRoute(id));

  return (
    <Card
      key={id}
      _hover={{ cursor: 'pointer', bg: 'whiteAlpha.100' }}
      data-testid="main-dashboard-listing-card"
      direction="column"
      flexShrink={0}
      minH="auto"
      overflow="hidden"
      userSelect="none"
      w="260px"
      onClick={onCardClick}
    >
      <Box position="relative">
        <ListingImageInCard
          addressFull={mlsListing?.addressFull}
          h="120px"
          photo={getPropertySource(mlsListing?.photos?.[0])}
        />
        {isPremium && (
          <Flex
            alignItems="flex-start"
            direction="column"
            flexGrow={0}
            position="absolute"
            textTransform="uppercase"
            top="6px"
            transform="scale(0.8)"
          >
            <PremiumTag />
            {/* TODO: add <OpenHouseTag />, with data from listing.currentAndUpcomingOpenHouses */}
          </Flex>
        )}
      </Box>
      <Flex
        direction="column"
        gap={1}
        lineHeight={1.25}
        p={3}
        pt={2.5}
        width="100%"
      >
        <Text color="white" fontSize="md" fontWeight="medium">
          {mlsListing?.address.streetNumberText}{' '}
          {mlsListing?.address.streetName} {mlsListing?.address.unit}
        </Text>
        <Text color="whiteAlpha.600" fontSize="sm">
          {mlsListing?.address.city}, {mlsListing?.address.state}{' '}
          {mlsListing?.address.postalCode}
        </Text>
        <VStack alignItems="center" mt={2} spacing={2} width="full">
          {renderOfferButton()}
          {renderSetupButton()}
        </VStack>
      </Flex>
    </Card>
  );
};
