import { Button, Divider, Flex, Heading, Image, Text } from '@chakra-ui/react';
import { useListingActivitiesQuery } from '@client/graphql/__generated__/main-operations';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useNavigate } from 'react-router';
import { CenterSpinner } from '~/common/components/CenterSpinner';
import { useActivityPageContext } from '../../contexts/ActivityContext';
import { getPropertySource } from '../../utils/listing.utils';
import { ActivitiesList } from './ActivitiesList';

dayjs.extend(relativeTime);

export const ActivityPageContent = () => {
  const { activeListing } = useActivityPageContext();
  const navigate = useNavigate();

  const { data, loading } = useListingActivitiesQuery({
    variables: {
      input: {
        id: activeListing?.id || '',
      },
    },
    skip: !activeListing?.id,
  });

  if (loading) {
    return (
      <Flex>
        <CenterSpinner />
      </Flex>
    );
  }

  const hasActivity = data?.listingActivities?.length;
  const handleClick = () => {
    if (!activeListing?.property.slug) {
      return null;
    }

    navigate(`/storefront/${activeListing.property.slug}`);
  };

  return (
    <Flex
      flexDirection="column"
      h="100%"
      px={6}
      py={{ base: 6, md: 8 }}
      w="100%"
    >
      <Flex flexDirection="column" mb={8}>
        <Flex
          alignItems="center"
          display={{ base: 'flex', md: 'none' }}
          mb={3}
          py={3}
        >
          <Image
            borderRadius="4px"
            height={5}
            mr={3}
            src={getPropertySource(activeListing?.mlsListing?.photos[0])}
            width={6}
          />
          <Text fontWeight="semiBold" size="sm">
            {activeListing?.mlsListing?.address?.streetNumberText}{' '}
            {activeListing?.mlsListing?.address?.streetName}{' '}
            {activeListing?.mlsListing?.address?.unit ?? ''}
          </Text>
        </Flex>

        <Divider display={{ base: 'block', md: 'none' }} mb={3} />

        <Flex alignItems="center" justifyContent="space-between" mb={4}>
          <Heading size="xxs">
            Activity on {activeListing?.mlsListing?.address?.streetNumberText}{' '}
            {activeListing?.mlsListing?.address?.streetName}{' '}
            {activeListing?.mlsListing?.address?.unit ?? ''}
          </Heading>
          {activeListing?.property.slug && (
            <Button size="sm" onClick={handleClick}>
              View storefront
            </Button>
          )}
        </Flex>
        <Divider />
      </Flex>
      <Flex flexDirection="column">
        {!hasActivity ? <Flex>No announcements yet!</Flex> : null}

        {hasActivity ? (
          <ActivitiesList activities={data?.listingActivities} />
        ) : null}
      </Flex>
    </Flex>
  );
};
