import {
  useAccessControlsListQuery,
  useDeleteAccessControlMutation,
  useInvitationsListQuery,
  useRevokeInvitationMutation,
} from '@client/graphql/__generated__/main-operations';
import { AccessRole } from '@client/graphql/__generated__/types';
import Fuse from 'fuse.js';
import { useCallback, useMemo, useState } from 'react';
import { useCheckPermission } from '~/common/hooks/useCheckPermission';
import { useMergeState } from '~/services/document/hooks/useMergeState';
import { useAppContext } from '~/services/main/contexts/AppContext';
import { AccessControlWithIsPending } from './types';

interface UseAccessControlsOptions {
  listingId?: string;
  organizationId?: string;
  partyId?: string;
  onSelfRemoved?: () => void;
}

export const useAccessControls = ({
  listingId,
  organizationId,
  partyId,
  onSelfRemoved,
}: UseAccessControlsOptions) => {
  const [filters, setFilters] = useState({ search: '' });
  const updateFilters = useMergeState(setFilters);
  const { user } = useAppContext();

  const { isAuthorized: isAuthorizedAsAdmin } = useCheckPermission(
    'accessControl.isAdmin',
    {
      skip: !organizationId && !listingId && !partyId,
    },
    {
      organizationUuid: organizationId,
      listingUuid: listingId,
      partyUuid: partyId,
    }
  );

  const {
    data,
    loading,
    refetch: refetchAccessControls,
  } = useAccessControlsListQuery({
    variables: {
      filters: {
        listingId,
        organizationId,
        partyId,
      },
    },
    skip: !listingId && !organizationId && !partyId,
  });
  const {
    data: invitesData,
    refetch: refetchInvites,
    loading: invitesLoading,
  } = useInvitationsListQuery({
    variables: {
      filters: {
        listingId,
        organizationId,
        partyId,
      },
    },
    skip: (!listingId && !organizationId && !partyId) || !isAuthorizedAsAdmin,
  });

  const [deleteAccessControl] = useDeleteAccessControlMutation();
  const [revokeInvitation] = useRevokeInvitationMutation();

  const acessControls = useMemo(() => {
    const results = data?.accessControlsList?.results || [];
    const invitesResult = invitesData?.invitationsList?.results || [];

    const owners = results.filter((accessControl) =>
      [AccessRole.OWNER, AccessRole.CO_OWNER].includes(accessControl.accessRole)
    );
    const others = results.filter(
      (accessControl) =>
        ![AccessRole.OWNER, AccessRole.CO_OWNER].includes(
          accessControl.accessRole
        )
    );
    const invitesToAccessControl: AccessControlWithIsPending[] =
      invitesResult.map((invite) => ({
        id: invite.id,
        accessUser: invite.invitee,
        accessRole: invite.inviteeAccessRole as AccessRole,
        isPending: !invite.acceptedAt,
      }));

    const all = [...owners, ...others, ...invitesToAccessControl];

    if (filters.search) {
      const fuse = new Fuse(all, {
        keys: [
          'accessUser.fullName',
          'accessUser.email',
          'accessUser.phoneNumber',
        ],
        threshold: 0.1,
      });
      const search = fuse.search(filters.search);

      return search.map((result) => result.item);
    }

    return all;
  }, [
    data?.accessControlsList?.results,
    invitesData?.invitationsList?.results,
    filters.search,
  ]);

  const deleteAccessControlOrInvitation = useCallback(
    async (accessControl: AccessControlWithIsPending) => {
      if (accessControl.isPending) {
        await revokeInvitation({ variables: { id: accessControl.id } });
        await refetchInvites();
      } else {
        await deleteAccessControl({
          variables: { input: { id: accessControl.id } },
        });

        if (accessControl.accessUser?.id === user?.id) {
          onSelfRemoved?.();
        } else {
          await refetchAccessControls();
        }
      }
    },
    [
      deleteAccessControl,
      onSelfRemoved,
      refetchAccessControls,
      refetchInvites,
      revokeInvitation,
      user?.id,
    ]
  );

  return {
    acessControls,
    refetchInvites,
    deleteAccessControlOrInvitation,
    filters,
    updateFilters,
    isAdmin: isAuthorizedAsAdmin,
    loading: invitesLoading || loading,
  };
};
