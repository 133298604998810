import { Divider, Flex, HStack, Text, VStack } from '@chakra-ui/react';
import dayjs from 'dayjs';
import minMax from 'dayjs/plugin/minMax';
import { getListingPrice } from '~/apps/consumer/utils/listing.utils';
import { useBuyerOfferAnalysisContext } from '../BuyerOfferAnalysisContext';
import { formatRangeCurrencyValue } from '../KeyTermsAndContingencies/utils';
import { getMaxCompDistance } from './utils';

dayjs.extend(minMax);
export const CompsSummary = ({
  shortLabel = false,
  compact = false,
}: {
  shortLabel?: boolean;
  compact?: boolean;
}) => {
  const { listing, comparableSet } = useBuyerOfferAnalysisContext();

  const numberOfComps = comparableSet?.listingComparables.length || 0;

  const minCompPrice = comparableSet?.listingComparables.reduce(
    (min, comparable) => {
      return Math.min(
        min,
        getListingPrice(comparable.mlsListing) +
          (comparable?.adjustments?.priceAdjustment || 0)
      );
    },
    Infinity
  );
  const maxCompPrice = comparableSet?.listingComparables.reduce(
    (max, comparable) => {
      return Math.max(
        max,
        getListingPrice(comparable.mlsListing) +
          (comparable?.adjustments?.priceAdjustment || 0)
      );
    },
    -Infinity
  );

  const hasAdjustments = comparableSet?.listingComparables.some(
    (comparable) => comparable?.adjustments?.priceAdjustment
  );

  const maxDistance = getMaxCompDistance(comparableSet, listing);

  const minDate = comparableSet?.listingComparables.reduce(
    (min, comparable) => {
      return dayjs.min(
        dayjs(min),
        dayjs(
          comparable.mlsListing.sales?.closeDate ||
            comparable.mlsListing.listDate ||
            ''
        )
      );
    },
    dayjs()
  );

  const priceRange =
    numberOfComps > 1
      ? `${formatRangeCurrencyValue(minCompPrice)} - ${formatRangeCurrencyValue(
          maxCompPrice
        )}`
      : formatRangeCurrencyValue(minCompPrice);

  const maxDateDiff = dayjs().diff(minDate, 'days');

  if (compact) {
    return (
      <Flex direction="column">
        <Text fontSize="lg" fontWeight="medium" mb={1}>
          {numberOfComps} Properties
        </Text>
        {numberOfComps > 0 && (
          <>
            <Text>{priceRange}</Text>
            <Text>{maxDateDiff} day oldest</Text>
            <Text>Within {maxDistance?.toFixed(1)} miles</Text>
          </>
        )}
      </Flex>
    );
  }

  return (
    <VStack gap={shortLabel ? 2 : 4} width="100%">
      <HStack justifyContent="space-between" width="100%">
        <Text fontWeight="medium">Number of Properties</Text>
        <Text>{numberOfComps}</Text>
      </HStack>
      <Divider />
      {numberOfComps > 0 && (
        <>
          <HStack justifyContent="space-between" width="100%">
            <Text fontWeight="medium">
              {shortLabel ? 'Range' : 'Comp Range'}
              {hasAdjustments && ' (Adjusted)'}
            </Text>
            <Text>{priceRange}</Text>
          </HStack>
          <Divider />
          <HStack justifyContent="space-between" width="100%">
            <Text fontWeight="medium">
              {shortLabel ? 'Distance' : 'Distance from subject property'}
            </Text>
            <Text>{maxDistance?.toFixed(1)} miles</Text>
          </HStack>
          <Divider />
          <HStack justifyContent="space-between" width="100%">
            <Text fontWeight="medium">
              {shortLabel ? 'Oldest' : 'Oldest comp'}
            </Text>
            <Text>{maxDateDiff} days</Text>
          </HStack>
          <Divider />
        </>
      )}
    </VStack>
  );
};
