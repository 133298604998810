import { MlsListingStatus } from '@client/graphql/__generated__/types';

export const TABS = [
  {
    status: MlsListingStatus.ComingSoon,
    label: 'Coming Soon',
  },
  {
    status: MlsListingStatus.Active,
    label: 'Active Listings',
  },
  {
    status: MlsListingStatus.Pending,
    label: 'Pending Listings',
  },
  {
    status: MlsListingStatus.Closed,
    label: 'Closed This Month',
  },
];
