import { Button, Text } from '@chakra-ui/react';
import { Banner } from './Banner';

export const PUBLISH_BANNER_HEIGHT = { base: 10, md: 12 };

interface PublishBannerProps {
  isLoading: boolean;
  isDirty: boolean;
  formId?: string;
  onClick?: () => void;
}

export const PublishBanner = ({
  isLoading,
  isDirty,
  formId,
  onClick,
}: PublishBannerProps) => {
  return (
    <Banner
      display={isDirty ? 'flex' : 'none'}
      minHeight={PUBLISH_BANNER_HEIGHT}
      position="sticky"
      top={0}
      transition="opacity 0.2s ease-in-out"
      zIndex={3}
    >
      <Text color="link.reverse">Your storefront has unpublished changes</Text>
      <Button
        _hover={{ backgroundColor: 'whiteAlpha.800' }}
        backgroundColor="white"
        color="black"
        form={formId}
        isLoading={isLoading}
        size={{ base: 'xs', md: 'sm' }}
        type={onClick ? 'button' : 'submit'}
        onClick={onClick}
      >
        Publish
      </Button>
    </Banner>
  );
};
