import {
  Button,
  Card,
  Heading,
  HStack,
  Icon,
  Skeleton,
  Spinner,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import {
  PrioritizedListingsForCurrentUserDocument,
  usePrioritizedListingsForCurrentUserQuery,
} from '@client/graphql/__generated__/main-operations';
import { PrioritizedListingFragment } from '@client/graphql/__generated__/types';
import { ArrowRightIcon, InfoIcon } from 'lucide-react';
import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { useInfiniteQuery } from '~/services/document/hooks';
import { CardEmptyState } from '../../components/CardEmptyState';
import { listingsPath } from '../../utils/routes.utils';
import { MainDashboardListingCard } from './MainDashboardListingCard';
import { OverflowContainer } from './OverflowContainer';

export const PRIORITIZED_LISTINGS_REFETCH_QUERY = [
  PrioritizedListingsForCurrentUserDocument,
];

const LISTINGS_COUNT = 15;
const CARD_SIZE = {
  height: '285px',
  width: '260px',
};

interface PrioritizedListingsSectionProps {
  leftMargin: number | null;
}

export const PrioritizedListingsSection: FC<
  PrioritizedListingsSectionProps
> = ({ leftMargin }) => {
  const [, { data, loading }] = useInfiniteQuery(
    usePrioritizedListingsForCurrentUserQuery
  );

  const listingsResult = data?.prioritizedListingsForCurrentUser?.results;
  const listingsTotalCount =
    data?.prioritizedListingsForCurrentUser?.pageInfo?.totalCount;

  return (
    <>
      <PrioritizedListingsHeader
        listingsTotalCount={listingsTotalCount}
        loading={loading}
      />
      <PrioritizedListingsContent
        leftMargin={leftMargin}
        listingsResult={listingsResult}
        loading={loading}
      />
    </>
  );
};

interface PrioritizedListingsHeaderProps {
  listingsTotalCount?: number;
  loading: boolean;
}

const PrioritizedListingsHeader: FC<PrioritizedListingsHeaderProps> = ({
  listingsTotalCount,
  loading,
}) => (
  <HStack>
    <Heading
      alignItems="center"
      as="h3"
      display="flex"
      flex={1}
      fontSize="lg"
      mb={2}
      size="sm"
      w="full"
    >
      Storefronts
      <Text as="sup" color="whiteAlpha.500" fontSize="xs">
        {listingsTotalCount}
      </Text>
      {loading ? (
        <Spinner
          color="whiteAlpha.500"
          emptyColor="whiteAlpha.50"
          ml={2}
          size="sm"
          speed="0.65s"
          thickness="2px"
        />
      ) : (
        <Tooltip label="Your Storefronts have been smartly ordered based on latest submitted offer, upcoming open houses, and list date.">
          <Icon
            _hover={{ transform: 'scale(1.1)' }}
            as={InfoIcon}
            color="whiteAlpha.500"
            height="16px"
            ml={2}
            opacity={loading ? 0 : 1}
            transition="all 0.2s"
            width="16px"
          />
        </Tooltip>
      )}
    </Heading>

    <Button as={NavLink} color="white" to={listingsPath()} variant="link">
      View all
    </Button>
  </HStack>
);

interface PrioritizedListingsContentProps {
  leftMargin: number | null;
  loading: boolean;
  listingsResult?: PrioritizedListingFragment[];
}

export const PrioritizedListingsContent: FC<
  PrioritizedListingsContentProps
> = ({ leftMargin, loading, listingsResult }) => {
  const hasListings = !!listingsResult?.length;

  if (hasListings) {
    return (
      <OverflowContainer leftMargin={leftMargin}>
        {listingsResult?.map((listing) => (
          <MainDashboardListingCard key={listing.id} listing={listing} />
        ))}
        <ViewAllCard key="view-all" />
      </OverflowContainer>
    );
  }

  if (loading) {
    return (
      <OverflowContainer leftMargin={leftMargin}>
        {Array.from({ length: LISTINGS_COUNT + 1 }).map((_, index) => (
          <Skeleton key={index} flexShrink={0} {...CARD_SIZE} />
        ))}
      </OverflowContainer>
    );
  }

  return (
    <Card h={CARD_SIZE.height} w="full">
      <CardEmptyState
        description="You currently don't have any active listings"
        icon={InfoIcon}
        title="No listings found"
      />
    </Card>
  );
};

const ViewAllCard: FC = () => (
  <Card
    _hover={{
      bg: 'whiteAlpha.100',
      cursor: 'pointer',
    }}
    alignItems="center"
    as={NavLink}
    bg="whiteAlpha.50"
    borderRadius="lg"
    boxShadow="md"
    display="flex"
    flexDirection="column"
    flexShrink={0}
    justifyContent="center"
    to={listingsPath()}
    {...CARD_SIZE}
  >
    <Icon as={ArrowRightIcon} boxSize={8} color="cyan.500" mb={3} />
    <Text color="cyan.500" fontSize="lg" fontWeight="medium">
      View All
    </Text>
  </Card>
);
