import { Center, Spinner } from '@chakra-ui/react';
import { usePostHog } from 'posthog-js/react';
import { useEffect } from 'react';
import { useAuth } from '../../hooks/useAuth';

export const LogoutPage = () => {
  const postHog = usePostHog();
  const { logout } = useAuth();

  useEffect(() => {
    void logout().then(() => {
      window.location.href = '/';
      postHog.reset();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Center height="90vh">
      <Spinner />
    </Center>
  );
};
