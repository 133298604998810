import {
  Box,
  Button,
  Divider,
  FormControl,
  FormErrorMessage,
  Input,
  InputGroup,
  InputLeftAddon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
  useToast,
} from '@chakra-ui/react';
import { useSendSellerInvitationMutation } from '@client/graphql/__generated__/main-operations';
import { Field, Form, Formik } from 'formik';
import { validatePhoneNumber } from '~/common/validation';

interface ShareWithSellerModalProps {
  isOpen: boolean;
  onClose: () => void;
  listingId: string;
}

interface ShareWithSellerModalFormInputs {
  name: string;
  phoneNumber: string;
}

export const ShareWithSellerModal = ({
  isOpen,
  onClose,
  listingId,
}: ShareWithSellerModalProps) => {
  const [sendSellerInvitationMutation] = useSendSellerInvitationMutation();
  const toast = useToast();

  return (
    <Modal
      isCentered
      closeOnOverlayClick={false}
      isOpen={isOpen}
      size="xl"
      onClose={onClose}
    >
      <ModalOverlay bg="blackAlpha.600" />
      <ModalContent
        backgroundColor="bg.main"
        boxShadow="none"
        // ModalContent is actually part of a 'hidden' container that is not
        // explicitly defined in the code.
        // We can use containerProps to set the styles of this hidden container.
        containerProps={{
          alignItems: {
            base: 'center',
          },
        }}
        display="flex"
        fontSize="sm"
        my="0px"
      >
        <ModalHeader fontSize="sm">Share with your seller</ModalHeader>
        <ModalCloseButton />
        <Divider />
        <ModalBody p={4}>
          <Formik<ShareWithSellerModalFormInputs>
            initialValues={{
              name: '',
              phoneNumber: '',
            }}
            onSubmit={(values) => {
              void sendSellerInvitationMutation({
                variables: {
                  input: {
                    listingId: listingId,
                    name: values.name,
                    // Convert phone number to E.164 format
                    phoneNumber: `+1${values.phoneNumber.replace(/\D/g, '')}`,
                  },
                },
              }).then(() => {
                toast({
                  title: 'Invitation sent!',
                  description: 'Your seller will be notified.',
                  status: 'success',
                  duration: 5000,
                  isClosable: true,
                });
                onClose();
              });
            }}
          >
            {({ errors, touched }) => (
              <Form>
                <VStack bgColor="bg.main" borderRadius="md" spacing={4}>
                  <Box backgroundColor="bg.main" borderRadius="md" p={4}>
                    <Text>
                      Sharing with your seller will allow them to see offers,
                      but they won’t be able to take any action without your
                      consent.
                    </Text>
                  </Box>
                  <FormControl isRequired>
                    <Input
                      as={Field}
                      id="name"
                      name="name"
                      placeholder="Name"
                    />
                  </FormControl>

                  <FormControl
                    isRequired
                    isInvalid={
                      errors.phoneNumber && touched.phoneNumber ? true : false
                    }
                  >
                    <InputGroup>
                      <InputLeftAddon>+1</InputLeftAddon>
                      <Input
                        as={Field}
                        id="phoneNumber"
                        name="phoneNumber"
                        placeholder="Phone Number"
                        type="tel"
                        validate={validatePhoneNumber}
                      />
                    </InputGroup>
                    {errors.phoneNumber && touched.phoneNumber && (
                      <FormErrorMessage>{errors.phoneNumber}</FormErrorMessage>
                    )}
                  </FormControl>

                  <Button type="submit" width="100%">
                    Share
                  </Button>
                </VStack>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
