/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-return */
export const cleanObjectTypeForInput = <TObject>(
  object: TObject & Record<string, any>
): Omit<TObject, '__typename'> => {
  return Object.keys(object).reduce((output, key) => {
    const value = object[key];

    if (value && typeof value === 'object' && !Array.isArray(value)) {
      output[key] = cleanObjectTypeForInput(value);
    } else if (Array.isArray(value)) {
      output[key] = value.map((val) => {
        if (typeof val === 'object') {
          return cleanObjectTypeForInput(val);
        }

        return val;
      });
    } else if (key !== '__typename') {
      output[key] = value;
    }

    return output;
  }, {} as any);
};

export const Null = null as unknown as undefined;
