import {
  PreferenceValueObject,
  PreferenceValueType,
} from '@client/graphql/__generated__/types';
import dayjs from 'dayjs';
import { DEFAULT_DATETIME_FORMAT } from '~/common/utils/formatter';
import { StyledFormInputType } from '../../../components/StyledFormInput/StyledFormInput';

export const FORM_ID = 'seller-preferences';

export const PREFERENCE_VALUE_FIELD_MAP: {
  [key in PreferenceValueType]: keyof PreferenceValueObject;
} = {
  [PreferenceValueType.STRING]: 'stringValue',
  [PreferenceValueType.BOOLEAN]: 'booleanValue',
  [PreferenceValueType.NUMBER]: 'numberValue',
  [PreferenceValueType.DATE]: 'dateTimeValue',
  [PreferenceValueType.STRING_ARRAY]: 'stringArray',
  [PreferenceValueType.TEXT]: 'textValue',
};

const CLOSING_TIME_FRAME_START = 'closing_time_frame_start';
const CLOSING_TIME_FRAME_END = 'closing_time_frame_end';
const SELLER_POSSESSION_AFTER_CLOSING_START =
  'seller_possession_after_closing_start';
const SELLER_POSSESSION_AFTER_CLOSING_END =
  'seller_possession_after_closing_end';
const BUYER_POSSESSION_BEFORE_CLOSING_END =
  'buyer_possession_before_closing_end';
const BUYER_POSSESSION_BEFORE_CLOSING_START =
  'buyer_possession_before_closing_start';
export const BEST_AND_FINAL_DATE = 'best_and_final_date';

export const DEFAULT_SELECTED_LOAN_TYPES = [
  'Conventional',
  'FHA',
  'VA Loan',
  'Cash',
];

export interface GroupMetaType {
  title: string;
  preferences: PreferenceMetaType[];
}
export interface PreferenceMetaType {
  name: string;
  inputType: StyledFormInputType;
  fieldSlugPrefix?: string;
  fieldSlug?: string;
  fromFieldSlug?: string;
  toFieldSlug?: string;
  dataType: keyof PreferenceValueObject;
  inputProps: {
    fromName?: string;
    toName?: string;
    fromPlaceholder?: string;
    toPlaceholder?: string;
    unit?: string;
    selectedUnit?: string;
    isMulti?: boolean;
    isDisabled?: boolean;
    options?: { label: string; value: string }[];
    unitOptions?: { label: string; value: string }[];
    unitMinWidth?: string;
    name?: string;
    label?: string;
    placeholder?: string;
    leftText?: string;
    rightText?: string;
    variant?: string;
    isNumber?: boolean;
    id?: string;
  };
  hasPlaceholder?: boolean;
  hideLabel?: boolean;
  isUrl?: boolean;
}

export const BEST_AND_FINAL_DATE_FIELD_PROPS = {
  name: 'Best and final date',
  inputType: StyledFormInputType.DATETIME,
  fieldSlug: BEST_AND_FINAL_DATE,
  dataType: PREFERENCE_VALUE_FIELD_MAP.DATE,
  inputProps: {
    name: BEST_AND_FINAL_DATE,
    id: BEST_AND_FINAL_DATE,
    placeholder: `eg. ${dayjs(new Date()).format(DEFAULT_DATETIME_FORMAT)}`,
  },
  labelProps: { labelIsInline: true },
  hasPlaceholder: true,
  hideLabel: true,
};

export const TRANSPARENCY_PREFERENCE_GROUPS: GroupMetaType[] = [
  {
    title: 'Transparency Preference Group',
    preferences: [BEST_AND_FINAL_DATE_FIELD_PROPS],
  },
];

export const AGENT_NEGOTIATION_PREFERENCE_GROUPS: GroupMetaType[] = [
  {
    title: 'Agent Compensation',
    preferences: [
      {
        name: 'Fee paid to selling firm by',
        inputType: StyledFormInputType.SELECT,
        fieldSlug: 'buyers_agent_compensation_payable_by',
        dataType: PREFERENCE_VALUE_FIELD_MAP.STRING,
        inputProps: {
          isMulti: false,
          name: 'buyers_agent_compensation_payable_by',
          options: [
            { label: 'Seller', value: 'SELLER' },
            { label: 'Listing firm', value: 'LISTING_FIRM' },
          ],
        },
        hasPlaceholder: true,
      },
      {
        name: 'Buyers agent compensation',
        inputType: StyledFormInputType.UNIT_SELECT,
        fieldSlugPrefix: 'buyers_agent_compensation',
        dataType: PREFERENCE_VALUE_FIELD_MAP.NUMBER,
        inputProps: {
          variant: 'stacked',
          name: 'buyers_agent_compensation',
          unitOptions: [
            { value: 'percent', label: '% of gross sales price' },
            { value: 'absolute', label: 'Flat fee' },
          ],
          selectedUnit: 'percent',
        },
        hasPlaceholder: true,
      },
    ],
  },
];

export const PREFERENCE_GROUPS: GroupMetaType[] = [
  {
    title: 'General',
    preferences: [
      {
        name: 'Closing time frame',
        inputType: StyledFormInputType.RANGE,
        fromFieldSlug: CLOSING_TIME_FRAME_START,
        toFieldSlug: CLOSING_TIME_FRAME_END,
        dataType: PREFERENCE_VALUE_FIELD_MAP.NUMBER,
        inputProps: {
          fromName: CLOSING_TIME_FRAME_START,
          toName: CLOSING_TIME_FRAME_END,
          fromPlaceholder: '30',
          toPlaceholder: '45',
          unit: 'days',
        },
        hasPlaceholder: true,
      },
      {
        name: 'Seller possession after closing',
        inputType: StyledFormInputType.RANGE,
        fromFieldSlug: SELLER_POSSESSION_AFTER_CLOSING_START,
        toFieldSlug: SELLER_POSSESSION_AFTER_CLOSING_END,
        dataType: PREFERENCE_VALUE_FIELD_MAP.NUMBER,
        inputProps: {
          fromName: SELLER_POSSESSION_AFTER_CLOSING_START,
          toName: SELLER_POSSESSION_AFTER_CLOSING_END,
          fromPlaceholder: '15',
          toPlaceholder: '30',
          unit: 'days',
        },
        hasPlaceholder: true,
      },
      {
        name: 'Buyer possession before closing',
        inputType: StyledFormInputType.RANGE,
        fromFieldSlug: BUYER_POSSESSION_BEFORE_CLOSING_START,
        toFieldSlug: BUYER_POSSESSION_BEFORE_CLOSING_END,
        dataType: PREFERENCE_VALUE_FIELD_MAP.NUMBER,
        inputProps: {
          fromName: BUYER_POSSESSION_BEFORE_CLOSING_START,
          toName: BUYER_POSSESSION_BEFORE_CLOSING_END,
          fromPlaceholder: '15',
          toPlaceholder: '30',
          unit: 'days',
        },
        hasPlaceholder: true,
      },
      {
        name: 'Ideal move out date',
        inputType: StyledFormInputType.DATE,
        fieldSlug: 'ideal_move_out_date',
        dataType: PREFERENCE_VALUE_FIELD_MAP.DATE,
        inputProps: {
          name: 'ideal_move_out_date',
        },
        hasPlaceholder: true,
      },
      {
        name: 'Preferred closing partner',
        inputType: StyledFormInputType.UNIT_SELECT,
        fieldSlugPrefix: 'preferred',
        dataType: PREFERENCE_VALUE_FIELD_MAP.STRING,
        inputProps: {
          id: 'preferred',
          name: 'preferred',
          unitOptions: [
            { value: 'closing_attorney', label: 'Closing Attorney' },
            { value: 'title_company', label: 'Title Company' },
          ],
          unitMinWidth: '150px',
          selectedUnit: 'closing_attorney',
        },
        hasPlaceholder: true,
      },
      {
        name: 'Earnest money deposit expectation',
        inputType: StyledFormInputType.UNIT_SELECT,
        fieldSlugPrefix: 'earnest_money_deposit',
        dataType: PREFERENCE_VALUE_FIELD_MAP.NUMBER,
        inputProps: {
          id: 'earnest_money_deposit',
          name: 'earnest_money_deposit',
          unitOptions: [
            { value: 'percent', label: '%' },
            { value: 'absolute', label: '$' },
          ],
          selectedUnit: 'absolute',
          isNumber: true,
        },
        hasPlaceholder: true,
      },
      {
        name: 'Due diligence fee expectation',
        inputType: StyledFormInputType.UNIT_SELECT,
        fieldSlugPrefix: 'due_diligence_fee_expectation',
        dataType: PREFERENCE_VALUE_FIELD_MAP.NUMBER,
        inputProps: {
          name: 'due_diligence_fee_expectation',
          id: 'due_diligence_fee_expectation',
          unitOptions: [
            { value: 'percent', label: '%' },
            { value: 'absolute', label: '$' },
          ],
          selectedUnit: 'absolute',
          isNumber: true,
        },
        hasPlaceholder: true,
      },
      {
        name: 'Seller concessions',
        inputType: StyledFormInputType.UNIT_SELECT,
        fieldSlugPrefix: 'seller_concessions',
        dataType: PREFERENCE_VALUE_FIELD_MAP.NUMBER,
        inputProps: {
          name: 'seller_concessions',
          id: 'seller_concessions',
          unitOptions: [
            { value: 'percent', label: '%' },
            { value: 'absolute', label: '$' },
          ],
          selectedUnit: 'absolute',
          isNumber: true,
        },
        hasPlaceholder: true,
      },
    ],
  },
  {
    title: 'Brokerage Information',
    preferences: [
      {
        name: 'Brokerage listing page',
        inputType: StyledFormInputType.TEXT,
        fieldSlug: 'brokerage_listing_page',
        dataType: PREFERENCE_VALUE_FIELD_MAP.STRING,
        inputProps: {
          name: 'brokerage_listing_page',
          placeholder: 'example.com',
          leftText: 'https://',
        },
        hasPlaceholder: true,
        isUrl: true,
      },
    ],
  },
  {
    title: 'Financial types accepted',
    preferences: [
      {
        name: 'Loan types',
        inputType: StyledFormInputType.SELECT,
        fieldSlug: 'loan_types',
        dataType: PREFERENCE_VALUE_FIELD_MAP.STRING_ARRAY,
        inputProps: {
          isMulti: true,
          name: 'loan_types',
          options: [
            { label: 'Conventional', value: 'Conventional' },
            { label: 'FHA', value: 'FHA' },
            { label: 'VA Loan', value: 'VA Loan' },
            { label: 'Cash', value: 'Cash' },
          ],
        },
        hasPlaceholder: true,
      },
      {
        name: 'Home sale contingency accepted',
        inputType: StyledFormInputType.CHECKBOX,
        fieldSlug: 'home_sale_contingency_accepted',
        dataType: PREFERENCE_VALUE_FIELD_MAP.BOOLEAN,
        inputProps: {
          name: 'home_sale_contingency_accepted',
          label: 'Home sale contingency accepted',
        },
        hideLabel: true,
      },
    ],
  },
  {
    title: 'Personal property not conveying',
    preferences: [
      {
        name: 'Personal property not conveying',
        inputType: StyledFormInputType.LIST,
        fieldSlug: 'personal_property_not_conveying',
        dataType: PREFERENCE_VALUE_FIELD_MAP.STRING_ARRAY,
        inputProps: {
          name: 'personal_property_not_conveying',
          placeholder: 'Washing Machine',
        },
        hideLabel: true,
        hasPlaceholder: true,
      },
    ],
  },
];

export const PREFERENCE_META_MAP: Record<string, PreferenceMetaType> = [
  ...TRANSPARENCY_PREFERENCE_GROUPS,
  ...PREFERENCE_GROUPS,
].reduce(
  (map, group) => {
    group.preferences.forEach((preference) => {
      if (preference.fieldSlug) {
        map[preference.fieldSlug] = preference;
      }
    });

    return map;
  },
  {} as Record<string, PreferenceMetaType>
);

export function getPreferenceMetaBySlug(
  slug: string
): PreferenceMetaType | undefined {
  return PREFERENCE_META_MAP[slug];
}
