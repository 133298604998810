export const formatToCurrency = (
  value?: string | number,
  decimalPoints = 2,
  notation: Intl.NumberFormatOptions['notation'] = 'standard'
) => {
  if (!value && value !== 0) return;

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: decimalPoints,
    notation,
  });

  return formatter.format(Number(value));
};

export const formatNumber = (value?: string | number, precision = 2) => {
  if (!value) return;

  const formatter = new Intl.NumberFormat('en-US', {
    maximumFractionDigits: precision,
  });

  return formatter.format(parseFloat(String(value)));
};
