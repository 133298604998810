import {
  Card,
  CardBody,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import {
  ModalTrigger,
  ModalTriggerBoxProps,
} from '~/common/components/Modal/ModalTrigger';
import { CopyPasteButton } from '../../../components/CopyPasteButton';

type CollectOffersByEmailButtonProps = {
  variant?: string;
  children?: React.ReactNode;
  offerCollectionEmail: string;
};

export const CollectOffersByEmailModalTrigger = ({
  children,
  offerCollectionEmail,
  ...props
}: CollectOffersByEmailButtonProps & ModalTriggerBoxProps) => {
  return (
    <ModalTrigger
      modal={(modalProps) => (
        <CollectOffersByEmailModal
          {...modalProps}
          offerCollectionEmail={offerCollectionEmail}
        />
      )}
      {...props}
    >
      {children}
    </ModalTrigger>
  );
};

type CollectOffersByEmailModalProps = {
  isOpen: boolean;
  onClose: () => void;
  offerCollectionEmail: string;
};

const CollectOffersByEmailModal: React.FC<CollectOffersByEmailModalProps> = ({
  isOpen,
  onClose,
  offerCollectionEmail,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      size={{
        base: 'full',
        md: '2xl',
      }}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Collect Offers by Email</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <Text mb={4}>
            Send offer PDFs to this email address and they'll show up here
            automatically.
          </Text>
          <Card>
            <CardBody>
              <Flex alignItems="center" gap={2} justifyContent="space-between">
                <Flex direction="column" minWidth="0">
                  <Text
                    color="sky.300"
                    overflow="hidden"
                    textOverflow="ellipsis"
                    whiteSpace="nowrap"
                  >
                    {offerCollectionEmail}
                  </Text>
                </Flex>
                <Flex>
                  <CopyPasteButton
                    buttonContentCopy="Copy"
                    copyText={offerCollectionEmail}
                    size="xs"
                  />
                </Flex>
              </Flex>
            </CardBody>
          </Card>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
