import { useOpenHouseQuery } from '@client/graphql/__generated__/main-operations';
import React, { createContext, ReactNode, useContext, useState } from 'react';
import { useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { MUTE_ERROR_TOAST_QUERY_OPTIONS } from '~/services/document/utils/apollo-client';

interface OpenHouseSignContextType {
  openHouseData: ReturnType<typeof useOpenHouseQuery>['data'];
  redirectToStorefront: boolean;
  loading: boolean;
  error: ReturnType<typeof useOpenHouseQuery>['error'];
  signUpCompleted: boolean;
  setSignUpCompleted: React.Dispatch<React.SetStateAction<boolean>>;
}

const OpenHouseSignContext = createContext<
  OpenHouseSignContextType | undefined
>(undefined);

export const OpenHouseSignProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [signUpCompleted, setSignUpCompleted] = useState(false);
  const { openHouseId } = useParams<{ openHouseId: string }>();

  const [searchParams] = useSearchParams();
  const redirectToStorefront = searchParams.get('utm_source') === 'qr';

  const {
    data: openHouseData,
    loading,
    error,
  } = useOpenHouseQuery({
    variables: {
      input: {
        id: openHouseId!,
      },
    },
    skip: !openHouseId,
    ...MUTE_ERROR_TOAST_QUERY_OPTIONS,
  });

  return (
    <OpenHouseSignContext.Provider
      value={{
        openHouseData,
        redirectToStorefront,
        loading,
        error,
        signUpCompleted,
        setSignUpCompleted,
      }}
    >
      {children}
    </OpenHouseSignContext.Provider>
  );
};

export const useOpenHouseSignContext = () => {
  const context = useContext(OpenHouseSignContext);
  if (context === undefined) {
    throw new Error('useOpenHouse must be used within an OpenHouseProvider');
  }

  return context;
};
