import { Box, Flex, Text, Tooltip, VStack } from '@chakra-ui/react';
import { PreferenceValueObject } from '@client/graphql/__generated__/types';
import { useMemo } from 'react';
import { StorefrontOverviewContextType } from '~/apps/consumer/pages/Storefront/StorefrontContext';
import { StorefrontCard } from '../StorefrontCard';
import { BrokeragePageButton } from './BrokeragePageButton';
import { PrefDisplay, getPrefsDisplay } from './helpers';

interface SellerPreferencesCardProps {
  storefrontOverviewContext: StorefrontOverviewContextType;
  onLinkClick?: () => void;
}

export const SellerPreferencesCard = ({
  storefrontOverviewContext,
  onLinkClick,
}: SellerPreferencesCardProps) => {
  const preferenceValues = storefrontOverviewContext.preferenceValues;

  const prefsDisplay: PrefDisplay[] = useMemo(() => {
    if (!preferenceValues) {
      return [];
    }

    return getPrefsDisplay(preferenceValues as PreferenceValueObject[]);
  }, [preferenceValues]);

  const brokerageUrl = preferenceValues?.find(
    (prefVal) => prefVal.preference.slug === 'brokerage_listing_page'
  )?.stringValue;

  if (!prefsDisplay.length) {
    return null;
  }

  return (
    <StorefrontCard
      linkText="View all"
      title="Seller Preferences"
      onLinkClick={onLinkClick}
    >
      <Flex direction="column" gap={4}>
        <Flex alignItems="center" mt={2} py={4}>
          {prefsDisplay.map((pref, index) => (
            <Box key={pref.name} flex="1" textAlign="center">
              <VStack
                borderRight={
                  index !== prefsDisplay.length - 1 ? '1px solid' : 'none'
                }
                borderRightColor="whiteAlpha.200"
                px={5}
              >
                <Flex alignItems="baseline" color="white" gap={1}>
                  <Text fontSize="3xl" fontWeight="500">
                    {pref.value}
                  </Text>
                  {pref.unitSuffix && (
                    <Text fontSize="sm"> {pref.unitSuffix}</Text>
                  )}
                </Flex>
                {pref.tooltip ? (
                  <Tooltip label={pref.tooltip} variant="muted">
                    <Text color="whiteAlpha.700" lineHeight={4}>
                      {pref.name}
                      <Text as="span" fontSize="xs">
                        &nbsp; &#9432;
                      </Text>
                    </Text>
                  </Tooltip>
                ) : (
                  <Text color="whiteAlpha.700" lineHeight={4}>
                    {pref.name}
                  </Text>
                )}
              </VStack>
            </Box>
          ))}
        </Flex>
        {brokerageUrl && (
          <Flex mx={4}>
            <BrokeragePageButton url={brokerageUrl} />
          </Flex>
        )}
      </Flex>
    </StorefrontCard>
  );
};
