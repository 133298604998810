import {
  Box,
  Button,
  Flex,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tag,
  Tooltip,
} from '@chakra-ui/react';
import {
  ListingFragment,
  StorefrontSetupStep,
} from '@client/graphql/__generated__/types';
import { EllipsisIcon } from 'lucide-react';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRegisteredRoles } from '~/apps/consumer/hooks/useRegisteredRoles';
import { openInNewTab } from '~/apps/consumer/utils/browser.utils';
import { getFormattedStatusText } from '~/apps/consumer/utils/listing.utils';
import { useIsMobile } from '~/common/hooks/useIsMobile';
import { TwoPeopleIcon } from '~/common/icons/TwoPeopleIcon';
import {
  getListingRoute,
  getStorefrontSetupWizardRoute,
} from '../../../utils/routes.utils';
import { BaseListingCard } from './BaseListingCard';

export const MOBILE_CARD_MIN_HEIGHT = '150px';
export const MOBILE_IMAGE_MIN_WIDTH = '145px';

interface ListingCardProps {
  listing: ListingFragment & { isSharedListing?: boolean };
  isActive?: boolean;
  className?: string;
  shouldOpenInNewTab?: boolean;
}

export const ListingCard: FC<ListingCardProps> = ({
  listing,
  isActive = true,
  className,
  shouldOpenInNewTab = false,
}) => {
  const navigate = useNavigate();
  const { isRegisteredAgent } = useRegisteredRoles();
  const isMobile = useIsMobile();

  const hasOffers = !!listing.submittedOffersCount;

  const hasSetupStorefront = !!listing.setupDetails?.configuredCount;
  const continueStep = listing.storefrontSetup?.continueStep;
  const isOwner = listing.isOwner;

  const routeToSetupWizard =
    continueStep !== StorefrontSetupStep.SHARE &&
    isRegisteredAgent &&
    !hasSetupStorefront;

  const generateRoute = (goDashboard?: boolean) => {
    let route = getListingRoute(listing.id, 'dashboard');
    if (goDashboard) {
      return route;
    }

    if (hasOffers) {
      route = getListingRoute(listing.id, 'offers');
    } else if (routeToSetupWizard) {
      // NOTE: we keep the record of continueStep when user clicks through listing card
      route = getStorefrontSetupWizardRoute(listing.id, continueStep);
    }

    return route;
  };

  const generateButtonText = () => {
    if (hasOffers) {
      return 'View offers';
    } else if (routeToSetupWizard) {
      return 'Setup & share storefront';
    }

    return 'Manage storefront';
  };

  const route = generateRoute();
  const buttonText = generateButtonText();

  const adminMenu = isOwner ? (
    <Box p={2} position="absolute" right={0} top={0} zIndex={1}>
      <Menu>
        <MenuButton
          _hover={{
            bgColor: 'gray.800',
          }}
          aria-label="More Actions"
          as={IconButton}
          bgColor="black"
          borderRadius="full"
          boxShadow="dark-lg"
          color="white"
          height="25px"
          icon={<EllipsisIcon height="18px" />}
          minHeight="25px"
          px={0}
          size="sma"
          width="25px"
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
        <MenuList>
          <MenuItem
            as={Link}
            href={getListingRoute(listing.id, 'admins')}
            isExternal={shouldOpenInNewTab}
            onClick={(e) => e.stopPropagation()}
          >
            Invite admin
          </MenuItem>
        </MenuList>
      </Menu>
    </Box>
  ) : null;

  const button = isActive ? (
    <Button
      as={Link}
      borderRadius="sm"
      fontSize="sm"
      href={route}
      isExternal={shouldOpenInNewTab}
      mt={2}
      size="xs"
      variant={!hasOffers && !routeToSetupWizard ? 'outline' : 'solid'}
      onClick={(e) => e.stopPropagation()}
    >
      {buttonText}
    </Button>
  ) : null;

  const otherTags = (
    <>
      {!!listing.submittedOffersCount && (
        <Tag variant="blueGradient">
          {listing.submittedOffersCount} offer
          {listing.submittedOffersCount > 1 ? 's' : ''}
        </Tag>
      )}
      {listing.isSharedListing && (
        <Flex flexGrow={1} justifyContent="flex-end">
          <Tooltip
            bgColor="gray.900"
            borderColor="gray.800"
            label="Shared listing"
            placement="top"
            portalProps={{ appendToParentPortal: true }}
            py={2}
            zIndex={10000}
          >
            <IconButton
              aria-label="Shared listing"
              color="whiteAlpha.600"
              height="auto"
              icon={<TwoPeopleIcon />}
              minWidth="auto"
              px={2}
              py={1}
              variant="ghost"
              width="auto"
            />
          </Tooltip>
        </Flex>
      )}
    </>
  );

  const topTags = listing.mlsListing?.mls?.statusText ? (
    <Tag lineHeight={1} variant="dark">
      {getFormattedStatusText(listing.mlsListing?.mls?.statusText)}
    </Tag>
  ) : undefined;

  const onCardClick = () => {
    if (shouldOpenInNewTab) {
      openInNewTab(generateRoute(true));
    } else {
      navigate(generateRoute(true));
    }
  };

  return (
    <BaseListingCard
      adminMenu={adminMenu}
      button={button}
      className={className}
      fullWidth={isMobile}
      listing={listing}
      otherTags={otherTags}
      topTags={topTags}
      onCardClick={onCardClick}
    />
  );
};
