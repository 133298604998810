import {
  Button,
  Popover,
  PopoverBody,
  PopoverContent,
  Portal,
  Text,
} from '@chakra-ui/react';
import { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { useIsMobile } from '~/common/hooks/useIsMobile';

interface NavButtonWithPopoverProps extends PropsWithChildren {
  topOffset: number;
  leftOffset: number;
  isOpen: boolean;
  tooltipText: string;
  buttonText?: string;
  handleClose?: () => void;
}

export const NAV_BUTTON_HELPER_ID = 'navButtonHelper';

// Chakra's PopoverArrow isn't rendering correctly -- likely because of the custom
// positioning within a portal. As a result, we are re-coding a centered arrow element
const PopoverArrow = {
  content: '""',
  position: 'absolute',
  top: '50%',
  right: '100%',
  marginTop: '-8px',
  borderWidth: '8px',
  borderStyle: 'solid',
  borderColor: 'transparent #716ADD transparent transparent',
};

const MobilePopoverArrow = {
  top: '-7px',
  left: '13px',
  transform: 'rotate(90deg)',
};

export const NavButtonPopover = ({
  leftOffset,
  topOffset,
  isOpen: isOpenProp,
  tooltipText,
  buttonText = 'Got it',
  handleClose,
}: NavButtonWithPopoverProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(isOpenProp);
  const isMobile = useIsMobile();

  const elementRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState<number>(0);

  const handleClick = () => {
    setIsOpen(false);

    if (handleClose) {
      handleClose();
    }
  };

  useEffect(() => {
    setIsOpen(isOpenProp);
  }, [isOpenProp]);

  useEffect(() => {
    if (elementRef.current) {
      const { height: elementHeight } =
        elementRef.current.getBoundingClientRect();
      setHeight(elementHeight);
    }
  }, [topOffset, isOpen]);

  const centeredYAxis = topOffset - height / 2;
  const adjustedLeftOffset = 20 + leftOffset;

  return (
    <Portal>
      <Popover isOpen={isOpen}>
        <PopoverContent
          _after={
            isMobile ? { ...PopoverArrow, ...MobilePopoverArrow } : PopoverArrow
          }
          bg="#716ADD" // confirm color & move this to theme
          left={{ base: '15px', md: adjustedLeftOffset }}
          position="absolute"
          top={{ base: '60px', md: centeredYAxis }}
        >
          <PopoverBody ref={elementRef} data-id={NAV_BUTTON_HELPER_ID}>
            <Text>{tooltipText}</Text>
            <Button
              fontWeight="bold"
              size="sm"
              variant="link"
              onClick={handleClick}
            >
              {buttonText}
            </Button>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Portal>
  );
};
