import { Input, InputProps } from '@chakra-ui/react';
import { InputFormatObject } from '@client/graphql/__generated__/types';
import { memo } from 'react';
import { useNumberField } from '~/services/document/components/FormFields/hooks/useNumberField';

export interface NumberInputFieldProps
  extends Omit<InputProps, 'onChange' | 'value'> {
  inputFormat?: Omit<InputFormatObject, '__typename'>;
  value?: string | number;
  onChange?: (value: string | number) => void;
  onFocus?: () => void;
  onBlur?: () => void;
}

export const NumberInputField = memo(function NumberInputField({
  id,
  onChange,
  value,
  inputFormat,
  onBlur,
  onFocus,
  placeholder,
  ...props
}: NumberInputFieldProps) {
  const { formatValue, setIsFocus, onChangeNumber } = useNumberField({
    onChange,
    inputFormat,
    value,
  });

  return (
    <Input
      id={id}
      placeholder={placeholder}
      value={value ? formatValue : ''}
      onBlur={() => {
        setIsFocus(false);
        onBlur && onBlur();
      }}
      onChange={(e) => onChangeNumber(e.currentTarget.value)}
      onFocus={() => {
        setIsFocus(true);
        onFocus && onFocus();
      }}
      {...props}
    />
  );
});
