import { ALERT_TOOLTIP_CACHE_VALUE } from '../Activity/ListingActivityProvider';

const ALERT_TOOLTIP_HAS_SIGNED_GUESTBOOK = '@alert-tooltip-signed-guestbook';

export const markGuestbookSigned = (listingId: string) => {
  localStorage.setItem(
    `${ALERT_TOOLTIP_HAS_SIGNED_GUESTBOOK}:${listingId}`,
    ALERT_TOOLTIP_CACHE_VALUE
  );
};
export const shouldShowGuestbookGetAlertsPopover = (listingId: string) => {
  return (
    localStorage.getItem(
      `${ALERT_TOOLTIP_HAS_SIGNED_GUESTBOOK}:${listingId}`
    ) === ALERT_TOOLTIP_CACHE_VALUE
  );
};
export const markGuestbookGetAlertsPopoverViewed = (listingId: string) => {
  localStorage.removeItem(`${ALERT_TOOLTIP_HAS_SIGNED_GUESTBOOK}:${listingId}`);
};
