import { Flex, HStack, Heading, Link, Text, VStack } from '@chakra-ui/react';
import { Home } from 'lucide-react';
import { FC } from 'react';
import { NavLink, Outlet } from 'react-router-dom';
import { EmptyState } from '../../components/EmptyState';
import { Container } from '../../components/Layouts/Container';
import { PublicProfileFeatureButton } from '../Account/PublicProfileFeatureButton';

const WAITLIST_EMAIL = 'waitlist@useindigo.com';

const navLinkProps = {
  color: 'link.nav',
  fontWeight: '600',
  transition: 'color 0.3s',
  _activeLink: {
    color: 'link.navActive !important',
  },
  _hover: {
    textDecoration: 'none',
    color: 'link.navHover',
  },
};
export const ListingsPage: FC = () => {
  // Reset to useProdFeatureFlagEnabled('la-premium-storefronts'); once we fix feature flag adblock issue.
  const flagEnabled = true;

  return (
    <Container>
      {flagEnabled ? (
        <VStack alignItems="stretch" justifyContent="stretch">
          <Flex
            alignItems="center"
            direction={{ base: 'column', md: 'row' }}
            gap={{ base: 4, sm: 0 }}
            mb={6}
            width="100%"
          >
            <Heading
              as="h2"
              display={{ base: 'none', md: 'block' }}
              flexGrow={1}
            >
              Listings
            </Heading>

            <PublicProfileFeatureButton />
          </Flex>

          <HStack
            justifyContent={{ base: 'center', md: 'flex-start' }}
            spacing={6}
          >
            <Link as={NavLink} to="active" {...navLinkProps}>
              Active Listings
            </Link>
            <Link as={NavLink} to="past" {...navLinkProps}>
              Past Listings
            </Link>
          </HStack>

          <Outlet />
        </VStack>
      ) : (
        <EmptyState
          headerText="Premium Storefronts are invite-only for now"
          icon={Home}
          minH="70vh"
          subText={
            <Text>
              Want early access? Email us at{' '}
              <Link color="link.cyan" href={`mailto:${WAITLIST_EMAIL}`}>
                {WAITLIST_EMAIL}
              </Link>
            </Text>
          }
        />
      )}
    </Container>
  );
};
