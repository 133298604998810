import { Button, ButtonProps, Icon } from '@chakra-ui/react';
import { useDraggable } from '@dnd-kit/core';
import { CheckSquareIcon, PencilLineIcon } from 'lucide-react';
import { forwardRef, PropsWithChildren } from 'react';
import { FieldType } from '~/test/mocks/enums.mock';

interface AddFormFieldButtonProps extends ButtonProps {
  fieldType: FieldType;
  label: string;
}

export const AddFormFieldButton = forwardRef(
  ({ label, fieldType, ...rest }: AddFormFieldButtonProps, ref) => {
    return (
      <Button
        ref={ref}
        id={`add-form-field-button-${fieldType}`}
        {...rest}
        _active={{ backgroundColor: 'sky.100' }}
        _hover={{ backgroundColor: 'gray.100' }}
        backgroundColor="white"
        boxShadow="0px 0px 9px 0px rgba(0, 0, 0, 0.25)"
        color="black"
        leftIcon={
          <Icon
            as={fieldType === FieldType.TEXT ? PencilLineIcon : CheckSquareIcon}
          />
        }
        size={{ base: 'xs', md: 'sm' }}
        variant="outline"
      >
        {label}
      </Button>
    );
  }
);

export const DraggableAddFormFieldButton = ({
  children,
  fieldType,
  label,
  ...rest
}: PropsWithChildren<AddFormFieldButtonProps>) => {
  const { attributes, listeners, setNodeRef } = useDraggable({
    id: fieldType,
    data: {
      fieldType,
      label,
    },
  });

  return (
    <AddFormFieldButton
      ref={setNodeRef}
      fieldType={fieldType}
      label={label}
      {...attributes}
      {...listeners}
      {...rest}
    >
      {children}
    </AddFormFieldButton>
  );
};
