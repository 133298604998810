import {
  Card,
  CardBody,
  CardHeader,
  Flex,
  Icon,
  IconButton,
} from '@chakra-ui/react';
import { Trash2 } from 'lucide-react';
import { PropsWithChildren, ReactNode } from 'react';

interface FormCardProps extends PropsWithChildren {
  label: ReactNode;
  showDeleteButton?: boolean;
  onDelete?: () => Promise<void> | void;
}

export const FormCard = ({
  label,
  children,
  showDeleteButton = false,
  onDelete,
}: FormCardProps) => {
  return (
    <Card>
      <CardHeader>
        <Flex alignItems="center" justifyContent="space-between">
          {label}
          {showDeleteButton && (
            <IconButton
              aria-label="Remove co-buyer"
              color="whiteAlpha.400"
              icon={<Icon as={Trash2} boxSize={4} />}
              size="xs"
              variant="ghost"
              onClick={() => {
                if (onDelete) {
                  void onDelete();
                }
              }}
            />
          )}
        </Flex>
      </CardHeader>
      <CardBody gap={2}>{children}</CardBody>
    </Card>
  );
};
