import { Box, BoxProps, Flex, Text, TextProps } from '@chakra-ui/react';
import { formatToCurrency } from '~/services/document/utils/number';

interface IPriceMarkerProps {
  price: number;
  isPremium?: boolean;
  submittedOffersCount?: number;
  showOffersCount?: boolean;
  bgOverride?: BoxProps['bg'];
  colorOverride?: TextProps['color'];
}

export const PriceMarker = ({
  price,
  isPremium,
  submittedOffersCount,
  showOffersCount = false,
  bgOverride,
  colorOverride,
}: IPriceMarkerProps) => {
  const formattedPrice = formatToCurrency(price, 0, 'compact');

  return (
    <Box
      bg={bgOverride ?? (isPremium ? 'purpleGradient' : 'gray.600')}
      borderRadius="6px"
      px={isPremium ? 2 : 1}
      py={1}
      zIndex={10}
    >
      <Flex alignItems="center" gap={1}>
        <Text
          color={colorOverride ?? (isPremium ? 'white' : 'gray.300')}
          fontWeight={isPremium ? 'semibold' : 'normal'}
        >
          {formattedPrice}
        </Text>
        {showOffersCount && !!submittedOffersCount && (
          <Flex
            alignItems="center"
            bgColor="white"
            borderRadius="50%"
            color="purple.500"
            fontWeight="semibold"
            justifyContent="center"
            minHeight={5}
            minWidth={5}
          >
            {submittedOffersCount}
          </Flex>
        )}
      </Flex>
    </Box>
  );
};
