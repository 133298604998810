import { BoxProps } from '@chakra-ui/react';
import { ReactNode, useMemo } from 'react';
import { Marker, MarkerProps } from 'react-map-gl';
import { DefaultMapMarker } from '../../Map/MapMarkers/DefaultMapMarker';
import { PriceMarker } from '../../Map/MapMarkers/PriceMarker';

interface IExploreMapMarkerProps {
  lat: number;
  lng: number;
  zoom: number;
  onClick?: MarkerProps['onClick'];
  price?: number;
  isPremium?: boolean;
  colorOverride?: BoxProps['bg'];
  bgOverride?: BoxProps['bg'];
  markerOverride?: ReactNode;
  zIndex?: number;
}

export const ExploreMapMarker = ({
  lat,
  lng,
  zoom,
  onClick,
  price,
  isPremium,
  colorOverride,
  bgOverride,
  markerOverride,
  zIndex,
}: IExploreMapMarkerProps) => {
  const marker = useMemo(() => {
    if (markerOverride) {
      return markerOverride;
    }

    if (zoom > 11 && price) {
      return (
        <PriceMarker
          bgOverride={bgOverride}
          colorOverride={colorOverride}
          isPremium={isPremium}
          price={price}
        />
      );
    }

    return isPremium && price ? (
      <PriceMarker
        bgOverride={bgOverride}
        colorOverride={colorOverride}
        isPremium={isPremium}
        price={price}
      />
    ) : (
      <DefaultMapMarker />
    );
  }, [zoom, isPremium, price, colorOverride, bgOverride, markerOverride]);

  return (
    <Marker
      anchor="bottom"
      latitude={lat}
      longitude={lng}
      offset={[0, 20]}
      style={{
        cursor: 'pointer',
        zIndex: zIndex || (isPremium ? 1 : 0),
      }}
      onClick={onClick}
    >
      {marker}
    </Marker>
  );
};
