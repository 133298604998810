import {
  Flex,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputProps,
  InputRightElement,
  Popover,
  PopoverAnchor,
  PopoverBody,
  PopoverContent,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { GeocodingFeature } from '@mapbox/search-js-core';
import debounce from 'lodash/debounce';
import { SearchIcon, XIcon } from 'lucide-react';
import { MapRef } from 'react-map-gl';

import { useMemo, useState } from 'react';
import { useMapboxGeocoder } from './useMapboxGeocoder';

const FEATURE_TYPE_TO_ZOOM: Record<string, number> = {
  country: 2,
  region: 5,
  postcode: 10,
  district: 11,
  place: 12,
  locality: 13,
  neighborhood: 14,
  street: 15,
  block: 16,
  address: 14,
  secondary_address: 14,
}; // Autofilled by Cursor Tab, may need to adjust if inaccurate

interface MapboxGeocoderProps extends Omit<InputProps, 'onChange'> {
  onChange?: (value: string) => void;
  onRetrieve?: (suggestion: GeocodingFeature) => void;
  mapRef: React.RefObject<MapRef>;
  inputRef?: React.RefObject<HTMLInputElement>;
}

export const MapboxGeocoder = ({
  onChange,
  onRetrieve,
  mapRef,
  inputRef,
  ...inputProps
}: MapboxGeocoderProps) => {
  const [searchString, setSearchString] = useState('');
  const {
    onSuggestionSelect,
    suggestions,
    searchSuggestions,
    onSuggestionsClear,
  } = useMapboxGeocoder();

  const {
    isOpen: isMenuOpen,
    onClose: onMenuClose,
    onOpen: onMenuOpen,
  } = useDisclosure();

  const debouncedSearch = useMemo(
    () =>
      debounce(async (searchString: string) => {
        await searchSuggestions(searchString);
        onMenuOpen();
      }, 500),
    [searchSuggestions, onMenuOpen]
  );

  const clearSuggestions = () => {
    setSearchString('');
    onSuggestionsClear();
    onMenuClose();
  };

  const handleSuggestionSelect = async (suggestion: GeocodingFeature) => {
    setSearchString(
      `${suggestion.properties.name}, ${suggestion.properties.place_formatted}`
    );
    const result = await onSuggestionSelect(suggestion);
    const zoom = FEATURE_TYPE_TO_ZOOM[result.properties.feature_type];
    mapRef.current?.flyTo({
      duration: 1500,
      center: {
        lng: result.properties.coordinates.longitude,
        lat: result.properties.coordinates.latitude,
      },
      zoom,
    });

    onRetrieve && onRetrieve(result);
    onMenuClose();
  };

  return (
    <Popover
      isLazy
      matchWidth
      autoFocus={false}
      isOpen={isMenuOpen}
      placement="bottom-start"
      onClose={onMenuClose}
      onOpen={onMenuOpen}
    >
      <PopoverAnchor>
        <InputGroup size="md">
          <InputLeftElement h="full">
            <Icon as={SearchIcon} boxSize="20px" color="whiteAlpha.500" />
          </InputLeftElement>
          <Input
            ref={inputRef}
            background={{ base: 'bg.mainDark', lg: 'input.bg' }}
            placeholder="State, city, neighborhood, address, etc"
            value={searchString}
            onBlur={() => {
              window.requestAnimationFrame(() => {
                onMenuClose();
              });
            }}
            onChange={(e) => {
              const value = e.target.value;
              setSearchString(value);
              onChange && onChange(value);
              void debouncedSearch(value);
              if (!value) {
                clearSuggestions();
              }
            }}
            onFocus={() => {
              if (suggestions?.length) {
                onMenuOpen();
              } else {
                onMenuClose();
              }
            }}
            {...inputProps}
          />
          {searchString && (
            <InputRightElement h="full">
              <Icon
                _hover={{ color: 'whiteAlpha.500' }}
                as={XIcon}
                boxSize="20px"
                color="white"
                cursor="pointer"
                onClick={clearSuggestions}
              />
            </InputRightElement>
          )}
        </InputGroup>
      </PopoverAnchor>
      {!!suggestions?.length && (
        <PopoverContent width="inherit">
          <PopoverBody>
            {suggestions?.map((suggestion: GeocodingFeature) => (
              <Flex
                key={suggestion.id}
                _hover={{
                  backgroundColor: 'whiteAlpha.300',
                  cursor: 'pointer',
                }}
                direction="column"
                px={2}
                py={1}
                onClick={() => {
                  void handleSuggestionSelect(suggestion);
                }}
                onMouseDown={(e) => {
                  // This is to prevent the popover from closing due to the input onBlur running before the onClick
                  e.preventDefault();
                }}
              >
                <Text fontWeight="bold" size="lg">
                  {suggestion.properties.name}
                </Text>
                <Text size="sm">{suggestion.properties.place_formatted}</Text>
              </Flex>
            ))}
          </PopoverBody>
        </PopoverContent>
      )}
    </Popover>
  );
};
