import { Icon, IconProps } from '@chakra-ui/react';
import { memo } from 'react';

export const DollarIcon = memo((props: IconProps) => {
  return (
    <Icon
      fill="none"
      height="18px"
      viewBox="0 0 10 18"
      width="10px"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.04883 15.7949C4.18294 15.7949 3.37891 15.6419 2.63672 15.3359C1.90104 15.0234 1.30534 14.5775 0.849609 13.998C0.39388 13.4121 0.146484 12.7122 0.107422 11.8984L0.0976562 11.8594L1.79688 11.8496L1.80664 11.8984C1.87174 12.3346 2.02474 12.735 2.26562 13.0996C2.50651 13.4577 2.85482 13.7441 3.31055 13.959C3.76628 14.1673 4.35221 14.2715 5.06836 14.2715C5.80404 14.2715 6.39323 14.1738 6.83594 13.9785C7.28516 13.7767 7.61068 13.5098 7.8125 13.1777C8.01432 12.8392 8.11523 12.4681 8.11523 12.0645V12.0449C8.11523 11.5111 7.94596 11.0684 7.60742 10.7168C7.27539 10.3587 6.62435 10.0625 5.6543 9.82812L4.19922 9.47656C3.39193 9.28125 2.70833 9.02734 2.14844 8.71484C1.59505 8.39583 1.17513 8.00521 0.888672 7.54297C0.602214 7.08073 0.458984 6.5306 0.458984 5.89258V5.87305C0.458984 5.11133 0.657552 4.45052 1.05469 3.89062C1.45833 3.32422 2.00521 2.88802 2.69531 2.58203C3.38542 2.26953 4.16992 2.11328 5.04883 2.11328C5.88867 2.11328 6.65039 2.27279 7.33398 2.5918C8.02409 2.9043 8.57747 3.34701 8.99414 3.91992C9.41732 4.49284 9.64518 5.16341 9.67773 5.93164V5.9707L7.97852 5.98047V5.94141C7.93294 5.45312 7.77669 5.03971 7.50977 4.70117C7.24935 4.35612 6.90755 4.09245 6.48438 3.91016C6.0612 3.72786 5.58268 3.63672 5.04883 3.63672C4.41732 3.63672 3.88672 3.74089 3.45703 3.94922C3.02734 4.15104 2.70182 4.41471 2.48047 4.74023C2.26562 5.05924 2.1582 5.39779 2.1582 5.75586V5.77539C2.1582 6.08789 2.22982 6.3776 2.37305 6.64453C2.52279 6.91146 2.76367 7.14909 3.0957 7.35742C3.42773 7.56576 3.87695 7.73828 4.44336 7.875L5.89844 8.22656C7.15495 8.52604 8.12174 8.95898 8.79883 9.52539C9.47591 10.0918 9.81445 10.8861 9.81445 11.9082V11.9277C9.81445 12.7676 9.5931 13.4772 9.15039 14.0566C8.70768 14.6296 8.125 15.0625 7.40234 15.3555C6.67969 15.6484 5.89518 15.7949 5.04883 15.7949ZM4.42383 17.416V0.492188H5.5957V17.416H4.42383Z"
        fill="currentColor"
      />
    </Icon>
  );
});
