import { Box, Flex, Heading, Image, Text, chakra } from '@chakra-ui/react';
import {
  PublicListingComparableSetFragment,
  PublicListingFragment,
} from '@client/graphql/__generated__/types';
import range from 'lodash/range';
import {
  DEFAULT_PROPERTY_IMAGE_URL,
  getPropertySource,
} from '~/apps/consumer/utils/listing.utils';
import { formatPrice } from '~/common/utils/formatter';

interface ComparablePriceChartProps {
  width: number;
  listing: PublicListingFragment;
  listingComparableSet: PublicListingComparableSetFragment;
}

export const ComparablePriceChart = ({
  listingComparableSet,
  listing,
  width,
}: ComparablePriceChartProps) => {
  const priceMinComparable = listingComparableSet?.listingComparables.reduce(
    (acc, comp) => {
      return (comp.mlsListing?.listPrice as number) <
        (acc.mlsListing?.listPrice as number)
        ? comp
        : acc;
    },
    listing
  );

  const priceMaxComparable = listingComparableSet?.listingComparables.reduce(
    (acc, comp) => {
      return (comp.mlsListing?.listPrice as number) >
        (acc.mlsListing?.listPrice as number)
        ? comp
        : acc;
    },
    listing
  );
  const priceMin = priceMinComparable?.mlsListing?.listPrice as number;
  const priceMax = priceMaxComparable?.mlsListing?.listPrice as number;
  const priceRange = priceMax - priceMin;

  const priceStep = priceRange / 4;

  const steps = range(priceMin, priceMax, priceStep);
  steps.push(priceMax); // range is exclusive of the max value

  const mainListingLocationPct =
    ((listing?.mlsListing?.listPrice as number) - priceMin) / priceRange;
  const mainListingLocation = width * mainListingLocationPct;

  const formattedPriceMin = formatPrice(priceMin);
  const formattedPriceMax = formatPrice(priceMax);
  const formattedPriceRange = `${formattedPriceMin || ''} - ${
    formattedPriceMax || ''
  }`;

  const dotWidth = 10;
  const paddingWidth = 20;
  const photoWidth = 50;
  const isMaxMain =
    listing.mlsListing?.id === priceMaxComparable?.mlsListing?.id;
  const isMinMain =
    listing.mlsListing?.id === priceMinComparable?.mlsListing?.id;

  // hides left or right photo if the main photo clips into it
  const hideRightPhoto =
    !isMaxMain &&
    !isMinMain &&
    width - mainListingLocation - photoWidth / 2 < photoWidth;
  const hideLeftPhoto =
    !isMinMain &&
    !isMaxMain &&
    mainListingLocation - photoWidth / 2 < photoWidth;
  const hideMainPhoto = isMaxMain || isMinMain;

  // measures the length between the left dot and middle dot (or right dot if there is no middle dot)
  const leftLineLength =
    isMaxMain || isMinMain
      ? width - dotWidth * 2
      : mainListingLocation - dotWidth - paddingWidth - dotWidth / 2;

  // measures the length between the middle dot and right dot (or zero if there is no middle dot)
  const rightLineLength =
    isMaxMain || isMinMain
      ? 0
      : width - mainListingLocation - dotWidth / 2 - dotWidth - paddingWidth;

  // measures the location of the main photo based on the dot location adjusting for left photo and half of middle photo
  const mainPhotoLocation =
    mainListingLocation - (hideLeftPhoto ? 0 : photoWidth) - photoWidth / 2;

  // measures the location of the right photo based on the main dot location adjusting for right photo and half of middle photo
  const rightPhotoLocation =
    isMaxMain || isMinMain
      ? width - photoWidth * 2
      : width - mainListingLocation - photoWidth - photoWidth / 2;

  return (
    <Flex direction="column" width={`${width}px`}>
      <Heading as="h3" fontSize="3xl" mb={2} textAlign="center">
        {formattedPriceRange}
      </Heading>
      <Flex alignItems="flex-end">
        {!hideLeftPhoto && (
          <Flex alignItems="center" direction="column">
            {isMinMain && <HomePill>Home</HomePill>}
            <ChartPhoto
              fallbackSrc={DEFAULT_PROPERTY_IMAGE_URL}
              src={getPropertySource(priceMinComparable?.mlsListing?.photos[0])}
            />
            <ChartDottedLine />
          </Flex>
        )}
        {!hideMainPhoto && (
          <Flex
            alignItems="center"
            direction="column"
            ml={`${mainPhotoLocation}px`}
          >
            <HomePill>Home</HomePill>
            <ChartPhoto
              fallbackSrc={DEFAULT_PROPERTY_IMAGE_URL}
              src={getPropertySource(listing?.mlsListing?.photos[0])}
            />
            <ChartDottedLine />
          </Flex>
        )}
        {!hideRightPhoto && (
          <Flex
            alignItems="center"
            direction="column"
            ml={`${rightPhotoLocation}px`}
          >
            {isMaxMain && <HomePill>Home</HomePill>}
            <ChartPhoto
              fallbackSrc={DEFAULT_PROPERTY_IMAGE_URL}
              src={getPropertySource(priceMaxComparable?.mlsListing?.photos[0])}
            />
            <ChartDottedLine />
          </Flex>
        )}
      </Flex>

      <Flex alignItems="center" justifyContent="center" mb={2} mx={5}>
        <ChartDot bgColor={isMinMain ? 'blue.300' : 'purple.500'} />
        <ChartLine width={`${leftLineLength}px`} />
        {!isMaxMain && !isMinMain && <ChartDot bgColor="blue.300" />}
        <ChartLine width={`${rightLineLength}px`} />
        <ChartDot bgColor={isMaxMain ? 'blue.300' : 'purple.500'} />
      </Flex>
      <Flex alignItems="center" justifyContent="space-between" mx="10px">
        {steps.map((step, i) => {
          return (
            <Text key={i} fontSize="xs">
              {formatPrice(step)}
            </Text>
          );
        })}
      </Flex>
    </Flex>
  );
};

const ChartDot = chakra(Box, {
  baseStyle: {
    borderRadius: '50%',
    height: '10px',
    width: '10px',
  },
});

const ChartLine = chakra(Box, {
  baseStyle: {
    bgColor: 'purple.500',
    height: '2px',
  },
});

const ChartPhoto = chakra(Image, {
  baseStyle: {
    borderRadius: '12px',
    height: '40px',
    width: '50px',
    objectFit: 'cover',
  },
});

const ChartDottedLine = chakra(Box, {
  baseStyle: {
    borderRight: '1px dashed',
    borderRightColor: 'whiteAlpha.500',
    height: '10px',
    width: '0px',
  },
});

const HomePill = chakra(Box, {
  baseStyle: {
    bgColor: 'sky.300',
    borderRadius: '4px',
    color: 'indigo.900',
    fontSize: 'xs',
    mb: 1,
    px: 1,
  },
});
