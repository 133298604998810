import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { File } from 'lucide-react';
import { useState } from 'react';
import { UploadDropzone } from '~/common/components/Upload/UploadDropzone';

interface AddCustomDocumentModalProps {
  isDisclosures: boolean;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: ({ name, file }: { name: string; file: File }) => Promise<void>;
}

export const AddCustomDocumentModal = ({
  isDisclosures,
  isOpen,
  onClose,
  onSubmit,
}: AddCustomDocumentModalProps) => {
  const [fileName, setFileName] = useState('');
  const [file, setFile] = useState<File | undefined>();

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          Add additional {isDisclosures ? 'disclosures' : 'documents'}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex direction="column" gap={3}>
            <FormControl isRequired>
              <FormLabel>Document name</FormLabel>
              <Input
                type="text"
                value={fileName}
                onChange={(e) => setFileName(e.target.value)}
              />
            </FormControl>
            {!!file && (
              <Flex
                alignItems="center"
                justifyContent="space-between"
                mb={3}
                width="100%"
              >
                <Flex alignItems="center">
                  <Icon as={File} color="whiteAlpha.500" />
                  <Text
                    minWidth="0"
                    ml={2}
                    overflow="hidden"
                    textOverflow="ellipsis"
                    whiteSpace="nowrap"
                    width={{ base: 'auto', md: '250px' }}
                  >
                    {file.name}
                  </Text>
                </Flex>
                <Button variant="link" onClick={() => setFile(undefined)}>
                  Remove
                </Button>
              </Flex>
            )}
            {!file && (
              <UploadDropzone
                allowMultiple={false}
                onDrop={(file) => setFile(file[0])}
              />
            )}
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Flex gap={4}>
            <Button variant="outline" onClick={onClose}>
              Cancel
            </Button>
            <Button
              isDisabled={!fileName || !file}
              onClick={() => {
                void onSubmit({ name: fileName, file: file as File });
                setFile(undefined);
                setFileName('');
                onClose();
              }}
            >
              Add
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
