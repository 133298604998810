import { Flex, Text } from '@chakra-ui/react';
import { VariableFieldObject } from '@client/graphql/__generated__/types';
import { numberFormatter } from '~/common/utils/formatter';
import { FieldWithPage } from '~/services/document/components/DocumentSections/types';
import { BaseComparableField } from '../../Comparables/BaseComparableField';
import { COMPARABLE_FIELD_HEIGHT } from '../../Listings/Comparables/ComparableColumns/constants';
import { useCompareOfferBaseValues } from './hooks/useCompareOfferBaseValues';

export const LABEL_COLUMN_WIDTH = { base: '120px', lg: '200px' };

interface OfferCompareListingColumnProps {
  offerComparisonFields: FieldWithPage[];
  baseValues: ReturnType<typeof useCompareOfferBaseValues>['baseValues'];
}

export const OfferCompareListingColumn = ({
  offerComparisonFields,
  baseValues,
}: OfferCompareListingColumnProps) => {
  return (
    <Flex
      alignSelf="flex-start"
      backdropFilter="blur(15px)"
      bgColor="bg.main"
      direction="column"
      flexShrink={0}
      left={0}
      position="sticky"
      pt={COMPARABLE_FIELD_HEIGHT}
      px={2}
      width={LABEL_COLUMN_WIDTH}
      zIndex={1}
    >
      {offerComparisonFields.map((field, idx) => {
        const { value, matchingFormulaField } = baseValues[field.id] || {};

        return (
          <OfferCompareListingField
            key={field.label}
            idx={idx}
            matchingFormulaField={matchingFormulaField}
            offerComparisonField={field}
            value={value}
          />
        );
      })}
    </Flex>
  );
};

interface OfferComparListingFieldProps {
  offerComparisonField: FieldWithPage;
  value: number | string | undefined;
  idx: number;
  matchingFormulaField?: VariableFieldObject;
}

const OfferCompareListingField = ({
  offerComparisonField,
  idx,
  value,
  matchingFormulaField,
}: OfferComparListingFieldProps) => {
  const formattedValue = matchingFormulaField?.key
    .toLowerCase()
    .includes('percent')
    ? numberFormatter(value as number, { type: 'percent', maxPrecision: 1 })
    : numberFormatter(value as number, { type: 'currency', maxPrecision: 0 });

  return (
    <BaseComparableField
      key={offerComparisonField.label}
      border="none"
      borderTop={idx !== 0 ? '1px solid' : 'none'}
      borderTopColor="border.divider"
    >
      <Flex alignItems="flex-start" direction="column">
        <Text color="whiteAlpha.600" fontSize={{ base: 'sm', lg: 'md' }}>
          {offerComparisonField.label}
        </Text>
        {formattedValue && <Text fontWeight="bold">{formattedValue}</Text>}
      </Flex>
    </BaseComparableField>
  );
};
