import { Input, InputProps, Portal } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { FC, memo, useMemo } from 'react';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import './DateFormField.css';
import { baseInputStyle } from './defaults';

export type DateFormFieldProps = {
  'data-testid'?: string;
  onChange: (date: string) => void;
  useBaseStyles?: boolean;
} & Omit<ReactDatePickerProps, 'onChange'> &
  Omit<InputProps, 'onChange'>;

export const DateFormField: FC<DateFormFieldProps> = memo(
  function DateFormField({
    isDisabled,
    style,
    onChange,
    dateFormat,
    value,
    useBaseStyles = true,
    ...props
  }) {
    const dateValue = useMemo(() => {
      if (value) {
        const dayjsDate = dayjs(value);

        return dayjsDate.isValid()
          ? dayjsDate.format(dateFormat as string)
          : '';
      }
    }, [value, dateFormat]);

    const selected = useMemo(() => {
      if (dateValue) {
        return dayjs(dateValue).toDate();
      }
    }, [dateValue]);

    return (
      <DatePicker
        customInput={
          <Input
            {...(useBaseStyles ? baseInputStyle : {})}
            data-testid={props['data-testid'] as string}
            isDisabled={isDisabled}
            style={style}
          />
        }
        popperContainer={Portal}
        popperProps={{ zIndex: 99999 }}
        selected={selected}
        value={dateValue}
        onChange={(date) => {
          if (date) {
            const dayjsDate = dayjs(date);
            if (dayjsDate.isValid()) {
              onChange(dayjsDate.format('YYYY-MM-DD'));
            }
          }
        }}
        onChangeRaw={(e) => {
          if (!e.currentTarget.value) {
            onChange('');
          }
        }}
        {...props}
      />
    );
  }
);
