import { OrganizationObject } from '@client/graphql/__generated__/types';
import { FC, memo, useMemo } from 'react';
import { chakraStyles, Select } from '~/services/document/common/Select';

interface OrganizationSelectProps {
  selectedOrganizationId?: string;
  organizations?: Pick<OrganizationObject, 'id' | 'name'>[];
  onChange: (organizationId?: string) => void;
}

export const OrganizationSelect: FC<OrganizationSelectProps> = memo(
  function OrganizationSelect({
    organizations,
    selectedOrganizationId,
    onChange,
  }) {
    const selectedOrganization = useMemo(() => {
      if (!organizations?.length) {
        return undefined;
      }

      const selected = organizations?.find(
        (organization) => organization.id === selectedOrganizationId
      );

      if (selected) {
        return {
          label: selected?.name,
          value: selected?.id,
        };
      }
    }, [organizations, selectedOrganizationId]);

    return (
      <Select
        chakraStyles={{
          ...chakraStyles,
          container: (base) => ({
            ...base,
            width: '100%',
          }),
        }}
        id="organization-select"
        isClearable={false}
        isSearchable={false}
        menuPortalTarget={window.document.body}
        options={organizations?.map((organization) => ({
          label: organization.name,
          value: organization.id,
        }))}
        styles={{
          menuPortal: (base) => ({
            ...base,
            zIndex: 9999,
          }),
        }}
        value={selectedOrganization}
        onChange={(value) => onChange(value?.value)}
      />
    );
  }
);
