import { Flex, Heading } from '@chakra-ui/react';
import { NewText } from '~/common/components/NewText/NewText';

interface NavGroupLabelProps {
  children: React.ReactNode;
  isExpanded?: boolean;
  isNew?: boolean;
}

export const NavGroupLabel = ({
  children,
  isExpanded,
  isNew,
}: NavGroupLabelProps) => {
  return (
    <Flex gap={1} px={4} visibility={isExpanded ? 'visible' : 'hidden'}>
      <Heading color="whiteAlpha.500" size="smallUppercase">
        {children}
      </Heading>
      {isNew && <NewText />}
    </Flex>
  );
};
