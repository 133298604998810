import {
  useContractVariableValuesQuery,
  useDocumentSectionListForOfferFlowQuery,
} from '@client/graphql/__generated__/document-operations';
import { useListingAndOffersQuery } from '@client/graphql/__generated__/main-operations';
import {
  DocumentSectionCategory,
  OfferKeyFieldsObject,
  OffersForListingFragment,
  VariableSource,
} from '@client/graphql/__generated__/types';
import { PropsWithChildren, useState } from 'react';
import { CenterSpinner } from '~/common/components/CenterSpinner';
import { useProdFeatureFlagEnabled } from '~/common/hooks/useProdFeatureFlagEnabled';
import { useDocumentSectionsByModuleType } from '~/services/document/components/DocumentSections/hooks/useDocumentSectionsByModuleType';
import { DocumentSection } from '~/services/document/components/DocumentSections/types';
import { ListingSetupContext } from './ListingSetupContext';

interface ListingWithOffersProviderProps extends PropsWithChildren {
  listingId: string;
}

export interface OfferWithContract {
  offer: OffersForListingFragment;
  buyerName: string;
  offerAmount: number;
  buyersAgentName: string;
  officeName?: string;
}

export const ListingWithOffersProvider = ({
  listingId,
  children,
}: ListingWithOffersProviderProps) => {
  const isTrackableListingPackageEnabled = useProdFeatureFlagEnabled(
    'trackable-listing-package'
  );
  const [showListingSetupTour, setShowListingSetupTour] = useState(false);
  // We are making 2 queries here, one to main and one to doc service.
  // There is a dependency on retrieving the relevant contract IDs from
  // the main service in order to query doc service.
  const {
    data: listingAndOffersData,
    loading: listingLoading,
    refetch: refetchListingAndOffers,
  } = useListingAndOffersQuery({
    variables: { listingId: listingId ?? '' },
    skip: !listingId, // we skip this if there is no listingId
  });

  // Function to call both refetch functions
  const refetchAllData = async () => {
    const refetchedData = await refetchListingAndOffers();

    return refetchedData;
  };

  const { data } = useDocumentSectionListForOfferFlowQuery({
    variables: {
      filters: {
        usState: listingAndOffersData?.listing.property.state,
        documentSectionCategory: DocumentSectionCategory.OFFER_CARD,
      },
    },
    skip: !listingAndOffersData?.listing.property.state,
  });

  const sections = useDocumentSectionsByModuleType({
    documentSections: data?.publicDocumentSectionList
      ?.results as DocumentSection[],
  });

  const { data: contractVariableValuesData } = useContractVariableValuesQuery({
    variables: {
      input:
        listingAndOffersData?.offersForListing?.map((offer) => ({
          contractId: offer?.contract?.id as string,
          variableSource: VariableSource.INDIGO_OFFER,
        })) || [],
    },
    skip: !listingAndOffersData?.offersForListing?.length,
  });

  if (!listingAndOffersData) {
    return <CenterSpinner />;
  }

  const offersWithContracts = listingAndOffersData.offersForListing
    .map((offer) => {
      if (offer && offer.contract) {
        const offerKeyFields =
          contractVariableValuesData?.contractVariableValues?.find(
            (contractVariableValue) =>
              contractVariableValue?.contractId === offer.contract?.id
          )?.contractVariableValues?.offerKeyFields as OfferKeyFieldsObject;

        const buyersAgentUser = offer.buyersAgent?.user;

        const buyersAgentName =
          (offerKeyFields?.buyersAgentName as string) ||
          buyersAgentUser?.fullName;

        const officeName = offerKeyFields?.buyersAgentOfficeName as
          | string
          | undefined;

        const primaryBuyerName = offer.primaryBuyer?.fullName;
        const coBuyerName = offer.secondaryBuyers?.[0]?.fullName;

        const OfferWithContract: OfferWithContract = {
          offer: offer,
          offerAmount: offerKeyFields?.offerAmount as number,
          buyerName: (primaryBuyerName || coBuyerName) as string,
          buyersAgentName: buyersAgentName as string,
          officeName,
        };

        return OfferWithContract;
      }

      return undefined;
    })
    .filter(Boolean) as OfferWithContract[];

  return (
    <ListingSetupContext.Provider
      value={{
        listing: listingAndOffersData.listing ?? {},
        listingLoading,

        offersWithContracts,
        refetchAllData,
        offerCardSections: sections,
        showListingSetupTour,
        setShowListingSetupTour,
        isTrackableListingPackageEnabled:
          isTrackableListingPackageEnabled || false,
      }}
    >
      {children}
    </ListingSetupContext.Provider>
  );
};
