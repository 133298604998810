import { Card, CardBody, Flex, Text } from '@chakra-ui/react';
import { ContractValidationFragment } from '@client/graphql/__generated__/types';
import { useOfferFlowContext } from '~/apps/consumer/pages/Storefront/OfferFlow/OfferFlowContext';
import { useUpdateInputValuesDebounced } from '~/apps/consumer/pages/Storefront/OfferFlow/hooks/useUpdateInputValuesDebounced';
import { useOfferValidations } from '~/services/document/hooks/useOfferValidations';
import { FormFieldControl } from '../../../InputField/FormFieldControl';
import { BuyerInfoForm } from '../../../OfferFlow/BuyerInfoForm/BuyerInfoForm';
import { StartOfferUploadListingDetails } from './StartOfferUploadListingDetails';

export const FailedExtractionFallback = () => {
  const { inputValues, onChange } = useUpdateInputValuesDebounced();

  const { listing, offerFlowSections, latestOffer, formFieldsMap } =
    useOfferFlowContext();

  const { getValidation } = useOfferValidations({
    validations: latestOffer?.contract
      ?.validations as ContractValidationFragment[],
  });

  return (
    <Flex direction="column" gap={4} pt={4}>
      <StartOfferUploadListingDetails listing={listing} />
      <Flex
        border="1px solid transparent"
        borderRadius="8px"
        direction="column"
        gap={5}
        p={4}
        position="relative"
        sx={{
          '&::before': {
            border: '1px solid transparent',
            borderRadius: '8px',
            content: '""',
            position: 'absolute',
            inset: 0,
            background: 'purpleGradient',
            '-webkit-mask':
              'linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0)',
            mask: 'linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0)',
            '-webkit-mask-composite': 'xor',
            maskComposite: 'exclude',
          },
        }}
      >
        <Text>
          We tried to get this info from your offer but we need a bit of help
          from you this time.
        </Text>
        <Text>
          Filling this out will help your offer look better to the listing
          agent.
        </Text>
      </Flex>
      <Card>
        <CardBody>
          <Flex direction="column" gap={2}>
            {offerFlowSections.KEY_TERMS &&
              offerFlowSections.KEY_TERMS?.documentSectionFields?.map(
                (definition) => {
                  const formField =
                    formFieldsMap?.[definition.field.mappingKey];

                  return (
                    <FormFieldControl
                      key={definition.field.label}
                      formField={formField}
                      label={definition.field.label}
                      validation={getValidation(definition.field.mappingKey)}
                      value={inputValues[definition.field.mappingKey] as string}
                      onChange={onChange}
                    />
                  );
                }
              )}
          </Flex>
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <Flex direction="column" gap={2}>
            <BuyerInfoForm requireEmail={false} />
          </Flex>
        </CardBody>
      </Card>
    </Flex>
  );
};
