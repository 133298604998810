export const tourClassNameStep1 = 'storefront-step-1';
export const tourClassNameStep2 = 'storefront-step-2';

export const tourSteps = [
  {
    selector: `.${tourClassNameStep1}`,
    content: 'Prepare a new offer for your buyer, or upload a signed offer.',
  },
  {
    selector: `.${tourClassNameStep2}`,
    content:
      'Get alerts to be notified of offer activity, or listing announcments.',
  },
];
