import { CloseIcon } from '@chakra-ui/icons';
import { Button, Flex, IconButton, Text, Tooltip } from '@chakra-ui/react';
import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useOfferFlowContext } from '~/apps/consumer/pages/Storefront/OfferFlow/OfferFlowContext';
import { useIsMobile } from '~/common/hooks/useIsMobile';
import { OfferFlowHeader } from '../OfferFlowHeader';

interface OfferFlowLayoutHeaderProps {
  title: string;
  showStartOverButton?: boolean;
  onStartOver?: () => void;
  isLoading?: boolean;
  shouldSaveOnClose?: boolean;
}

export const OfferFlowLayoutHeader = ({
  title,
  onStartOver,
  showStartOverButton,
  isLoading,
  shouldSaveOnClose,
}: OfferFlowLayoutHeaderProps) => {
  const isMobile = useIsMobile();
  const { showContractPreview, updateContractPreviewState, save } =
    useOfferFlowContext();
  const navigate = useNavigate();
  const location = useLocation();

  const onClose = useCallback(() => {
    if (shouldSaveOnClose) {
      void save();
    }
    navigate({ pathname: '../', search: location.search });
  }, [navigate, location.search, shouldSaveOnClose, save]);

  const tooltipContent = (
    <Flex alignItems="flex-start" direction="column" gap="2">
      <Text>Click here to preview auto-filled offer docs at any time.</Text>
    </Flex>
  );

  return (
    <OfferFlowHeader title={title}>
      {showStartOverButton && (
        <Button size="xs" variant="outline" onClick={onStartOver}>
          Start Over
        </Button>
      )}
      {(!isMobile || !showContractPreview) && (
        <Tooltip hasArrow label={tooltipContent} openDelay={500}>
          <Button
            isLoading={isLoading}
            size="xs"
            onClick={() => updateContractPreviewState('toggle')}
          >
            {showContractPreview ? 'Hide Preview' : 'Preview'}
          </Button>
        </Tooltip>
      )}
      {isMobile && (
        <IconButton
          aria-label="Close"
          icon={<CloseIcon />}
          size="xs"
          variant="unstyled"
          onClick={onClose}
        />
      )}
    </OfferFlowHeader>
  );
};
