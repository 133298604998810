import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Icon,
  IconButton,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useBreakpointValue,
  VStack,
} from '@chakra-ui/react';
import { PublicStorefrontConfigurationStatusObjectFragment } from '@client/graphql/__generated__/types';
import { lowerFirst, startCase } from 'lodash';
import { CircleHelp } from 'lucide-react';
import { FC, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { CenterSpinner } from '~/common/components/CenterSpinner';
import { useIsMobile } from '~/common/hooks/useIsMobile';
import { STOREFRONT_SIDEBAR_WIDTHS } from '~/common/theme/sizes';
import { useAppContext } from '~/services/main/contexts/AppContext';
import { SimpleHeader } from '../../components/Layouts/SimpleHeader';
import { ListingPhotoCarousel } from '../../components/Listings/ListingPhotoCarousel/ListingPhotoCarousel';
import { SIDEBAR_WIDTH_COLLAPSED } from '../../components/PageDetailLayout/PageDetailLayoutSidebar';
import { ActivitiesTab } from '../../components/Storefront/Activities/ActivitiesTab';
import { ComparablesDetails } from '../../components/Storefront/Comparables/ComparablesDetails';
import { DocumentsDetails } from '../../components/Storefront/Documents/DocumentsDetails';
import { SellerPreferencesDetails } from '../../components/Storefront/SellerPreferences/SellerPreferencesDetails';
import { MLSListingDetails } from '../../components/Storefront/Status/MLSListingDetails';
import { tourClassNameStep1 } from '../../components/Storefront/StorefrontTourModal/constants';
import { StorefrontTourModal } from '../../components/Storefront/StorefrontTourModal/StorefrontTourModal';
import { formatPartyConsumers } from '../../utils/party.utils';
import { MainLayout } from '../MainLayout';
import { VerifyUserModalWithAutoOpenHouseSign } from '../OpenHouseGuestbook/VerifyUserModalWithAutoOpenHouseSign';
import {
  StorefrontContextType,
  StorefrontProvider,
  useStorefrontContext,
} from './StorefrontContext';
import { StorefrontOverview } from './StorefrontOverview';
import { StorefrontSummary } from './StorefrontSummary';

const TAB_QUERY_PARAM_KEY = 'tab';

export enum StorefrontTab {
  Overview,
  Activity,
  SellerPreferences,
  Comparables,
  Documents,
  ListingDetails,
}

// results e.g. { [StorefrontTab.Overview]: 'Overview' }
const tabQueryParamCopy = Object.fromEntries(
  Object.entries(StorefrontTab)
    .filter(([key]) => isNaN(Number(key)))
    .map(([key]) => [StorefrontTab[key as keyof typeof StorefrontTab], key])
);

export interface StorefrontPageProps {
  // passed from the listing agent preview
  propertySlugOverride?: string;
  isPreview: boolean;
  showIsPreviewTab?: boolean;
}

const getStorefrontTabs = (
  setupDetails: PublicStorefrontConfigurationStatusObjectFragment | undefined,
  handleChangeTab: (index: StorefrontTab) => void
) => {
  const tabComponents = {
    [StorefrontTab.Overview]: (
      <StorefrontOverview handleChangeTab={handleChangeTab} />
    ),
    [StorefrontTab.Activity]: <ActivitiesTab />,
    [StorefrontTab.SellerPreferences]: <SellerPreferencesDetails />,
    [StorefrontTab.Comparables]: <ComparablesDetails />,
    [StorefrontTab.Documents]: <DocumentsDetails />,
    [StorefrontTab.ListingDetails]: <MLSListingDetails />,
  };

  // This can make sure that the tabs are in the enum order and all enum values are consumed
  const tabs = Object.values(StorefrontTab)
    .filter((tab): tab is StorefrontTab => !isNaN(Number(tab)))
    .map((tab) => ({
      tab: tab,
      name: startCase(StorefrontTab[tab]),
      component: tabComponents[tab],
    }));

  return tabs.filter(
    (tab) =>
      // NOTE: to match the backend implementation with types
      setupDetails?.[lowerFirst(tab.name) as keyof typeof setupDetails] !==
      false
  );
};

const StorefrontPageContent = ({
  isPreview,
  showIsPreviewTab = false,
}: StorefrontPageProps) => {
  const isMobile = useIsMobile();
  const [params, setSearchParams] = useSearchParams();
  const { isAuthenticated } = useAppContext();

  // if a ?tab= exists, default to loading that tab
  // otherwise, default to loading Overview tab
  const defaultTabIndex: StorefrontTab =
    StorefrontTab[
      params.get(TAB_QUERY_PARAM_KEY) as keyof typeof StorefrontTab
    ] || StorefrontTab.Overview;

  const [tabIndex, setTabIndex] = useState(defaultTabIndex);
  const navigate = useNavigate();
  const location = useLocation();
  const makeOfferSidebarWidth = useBreakpointValue(STOREFRONT_SIDEBAR_WIDTHS);

  const handleChangeTab = (index: StorefrontTab) => {
    setTabIndex(index);
    setSearchParams((prev) => {
      prev.set(TAB_QUERY_PARAM_KEY, tabQueryParamCopy[index]);

      return prev;
    });
  };

  const {
    loading,
    listing,
    setShowMakeOfferMobile,
    showMakeOfferMobile,
    shouldContinueOffer,
    setShowStartTourModal,
    isPremium,
    canUploadOffer,
    scopeToParty,
  } = useStorefrontContext();

  const isInOfferFlow = location.pathname.includes('offer');

  const storefrontTabs = getStorefrontTabs(
    listing?.setupDetails,
    handleChangeTab
  );

  const MobileOfferButton = () => (
    <Flex width="100%">
      <Button
        className={tourClassNameStep1}
        mx={5}
        my={4}
        sx={{ textWrap: 'wrap' }}
        width="100%"
        onClick={() => {
          if (shouldContinueOffer) {
            navigate('./offer');
          } else {
            setShowMakeOfferMobile(true);
          }
        }}
      >
        {shouldContinueOffer
          ? 'Continue offer'
          : `Start${canUploadOffer ? ' or upload' : ''} an offer`}
        {scopeToParty && ` for ${formatPartyConsumers(scopeToParty)}`}
      </Button>
    </Flex>
  );

  if (loading) {
    return <CenterSpinner />;
  }

  if (!listing) {
    return <div>Listing not found</div>;
  }

  if (isInOfferFlow && isMobile) {
    return <Outlet />;
  }

  return (
    <>
      <Helmet>
        {listing?.mlsListing?.address && (
          <title>
            {`${listing?.mlsListing?.address?.streetNumberText || ''} ${
              listing?.mlsListing?.address?.streetName || ''
            }`}{' '}
            Indigo Storefront
          </title>
        )}
      </Helmet>
      <Flex
        alignItems="flex-start"
        direction={{ base: 'column', md: 'row' }}
        gap={0}
        justifyContent="stretch"
        width="100%"
      >
        <VStack
          flexGrow={1}
          pt={{ base: 1, md: showIsPreviewTab ? 1 : 6 }}
          px={6}
          width={{
            base: '100%',
            md: isPreview
              ? '100%'
              : `calc(100vw - (${
                  makeOfferSidebarWidth as string
                } + ${SIDEBAR_WIDTH_COLLAPSED}))`,
          }}
        >
          {!isPreview && !isAuthenticated && <SimpleHeader />}
          <Flex
            boxSizing="border-box"
            direction="column"
            gap={8}
            m={0}
            maxWidth="container.storefront"
            width="100%"
          >
            {showIsPreviewTab && (
              <Flex>
                <Box
                  bg="#9F7BEE" //TODO: new theme color?
                  borderRadius="0px 0px 4px 4px"
                  borderTop="1px solid"
                  borderTopColor="whiteAlpha.500"
                  p={1}
                >
                  <Text
                    fontSize="11px"
                    fontWeight="600"
                    letterSpacing="0.88px"
                    textTransform="uppercase"
                  >
                    Storefront Preview
                  </Text>
                </Box>
              </Flex>
            )}
            <ListingPhotoCarousel images={listing?.mlsListing?.photos || []} />
            <StorefrontSummary isPremium={isPremium} isPreview={isPreview} />
            {isPremium ? (
              <Tabs
                index={tabIndex}
                variant="noBorder"
                onChange={handleChangeTab}
              >
                <TabList>
                  {storefrontTabs.map((tab, index) => (
                    <Tab key={index}>{tab.name}</Tab>
                  ))}
                </TabList>
                <TabPanels>
                  {storefrontTabs.map((tab, index) => (
                    <TabPanel key={index} px={0} py={4}>
                      {tab.component}
                    </TabPanel>
                  ))}
                </TabPanels>
              </Tabs>
            ) : (
              <MLSListingDetails />
            )}
          </Flex>
        </VStack>

        {!isPreview ? (
          <VStack
            alignItems="flex-start"
            bgColor="bg.mainDark"
            borderLeft="1px solid"
            borderLeftColor="card.border"
            bottom={0}
            flexShrink={0}
            height={{ base: 'auto', md: '100vh' }}
            overflowY={isMobile ? 'initial' : 'scroll'}
            overscrollBehavior="contain"
            position="sticky"
            top={0}
            width={STOREFRONT_SIDEBAR_WIDTHS}
            zIndex={2}
          >
            {isInOfferFlow ? (
              <Outlet />
            ) : (
              <>
                {isMobile && <MobileOfferButton />}
                <StoreFrontPageOutlet
                  setShowMakeOfferMobile={setShowMakeOfferMobile}
                  showMakeOfferMobile={showMakeOfferMobile}
                />

                <IconButton
                  aria-label="help"
                  bottom={{ base: 20, md: 4 }}
                  boxSize={{ base: 6, md: 8 }}
                  icon={<Icon as={CircleHelp} boxSize={{ base: 6, md: 8 }} />}
                  position="absolute"
                  right={2}
                  variant="link"
                  onClick={() => setShowStartTourModal(true)}
                />
              </>
            )}
          </VStack>
        ) : null}
      </Flex>
      <StorefrontTourModal />
    </>
  );
};

export const StorefrontPage = ({
  propertySlugOverride,
  isPreview = false,
  showIsPreviewTab = false,
}: StorefrontPageProps) => {
  const { propertySlug } = useParams<string>();

  const { isAuthenticated } = useAppContext();
  const propSlug = propertySlugOverride || propertySlug || '';

  let page = (
    <StorefrontProvider isPreview={isPreview} propertySlug={propSlug}>
      <StorefrontPageContent
        isPreview={isPreview}
        showIsPreviewTab={showIsPreviewTab}
      />
      <VerifyUserModalWithAutoOpenHouseSign />
    </StorefrontProvider>
  );

  // when user is on Storefront page is has authenticated, we wrap in MainLayout
  // to render the DesktopSidebar so user can see notifications if they've subscribed
  if (isAuthenticated && !isPreview) {
    page = (
      <MainLayout>
        <Flex w="100%">{page}</Flex>
      </MainLayout>
    );
  }

  return page;
};

const StoreFrontPageOutlet: FC<
  Pick<StorefrontContextType, 'showMakeOfferMobile' | 'setShowMakeOfferMobile'>
> = ({ setShowMakeOfferMobile, showMakeOfferMobile }) => {
  const isMobile = useIsMobile();

  return isMobile ? (
    <Drawer
      allowPinchZoom={true}
      autoFocus={false}
      blockScrollOnMount={false}
      isOpen={showMakeOfferMobile}
      placement="bottom"
      onClose={() => setShowMakeOfferMobile(false)}
    >
      <DrawerOverlay
        backdropFilter="blur(4.5px)"
        background="rgba(12, 12, 42, 0.45)"
      />
      <DrawerContent
        backgroundColor="bg.mainDark"
        borderRadius="20px 20px 0px 0px"
        borderTop="1px solid"
        borderTopColor="card.border"
      >
        <DrawerBody px={2} py={0}>
          <Outlet />
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  ) : (
    <Outlet />
  );
};
