import { ApolloQueryResult } from '@apollo/client';
import { useDisclosure } from '@chakra-ui/react';
import {
  useGetBuyerOfferAnalysisQuery,
  useGetOfferRecsQuery,
  useListingComparableSetForBuyerOfferAnalysisQuery,
  useUpdateListingComparableSetForBuyerOfferAnalysisMutation,
} from '@client/graphql/__generated__/main-operations';
import {
  BuyerOfferAnalysisFragment,
  Exact,
  ListingComparableSetForBuyerOfferAnalysisInput,
  ListingComparableSetForBuyerOfferAnalysisQuery,
  ListingComparableSetFragment,
  ListingFragment,
  OfferRecsFragment,
  UpdateListingComparableInput,
} from '@client/graphql/__generated__/types';
import isEqualWith from 'lodash/isEqualWith';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { ComparableState } from '../../pages/ListingSetup/Comparables/ComparablesPage';

interface BuyerOfferAnalysisContextType {
  buyerOfferAnalysis?: BuyerOfferAnalysisFragment;
  listing?: ListingFragment;
  comparableSet?: ListingComparableSetFragment;
  comparableSetLoading: boolean;
  buyerOfferAnalysisLoading: boolean;
  offerRecsLoading: boolean;
  isEditCompsModalOpen: boolean;
  onEditCompsModalOpen: (comp?: ComparableState) => void;
  onEditCompsModalClose: () => void;
  comps: ComparableState[];
  setComps: React.Dispatch<React.SetStateAction<ComparableState[]>>;
  offerRecs?: OfferRecsFragment;
  refetchComparableSet: (
    variables?:
      | Partial<
          Exact<{ input: ListingComparableSetForBuyerOfferAnalysisInput }>
        >
      | undefined
  ) => Promise<
    ApolloQueryResult<ListingComparableSetForBuyerOfferAnalysisQuery>
  >;
  initialSelectedComp?: ComparableState;
}

export const BuyerOfferAnalysisContext =
  createContext<BuyerOfferAnalysisContextType>(
    {} as BuyerOfferAnalysisContextType
  );

export const BuyerOfferAnalysisProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [initialSelectedComp, setInitialSelectedComp] =
    useState<ComparableState>();
  const [comps, setComps] = useState<ComparableState[]>([]);
  const [isDirty, setIsDirty] = useState(false);
  const [isCompsUpdated, setIsCompsUpdated] = useState(false);
  const [updateComparableSet] =
    useUpdateListingComparableSetForBuyerOfferAnalysisMutation();
  const { offerCoachId } = useParams();
  const { data: buyerOfferAnalysisData, loading: buyerOfferAnalysisLoading } =
    useGetBuyerOfferAnalysisQuery({
      variables: {
        uuid: offerCoachId ?? '',
      },
      skip: !offerCoachId,
    });

  const buyerOfferAnalysis = useMemo(
    () => buyerOfferAnalysisData?.getBuyerOfferAnalysis,
    [buyerOfferAnalysisData]
  );

  const listing = useMemo(
    () => buyerOfferAnalysis?.listing,
    [buyerOfferAnalysis]
  );

  const {
    data: comparableSetData,
    loading: comparableSetLoading,
    refetch: refetchComparableSet,
  } = useListingComparableSetForBuyerOfferAnalysisQuery({
    variables: {
      input: {
        listingId: buyerOfferAnalysis?.listing?.id || '',
        buyerOfferAnalysisId: buyerOfferAnalysis?.id || '',
      },
    },
    skip: !buyerOfferAnalysis,
  });

  const { isOpen: isEditCompsModalOpen, onOpen, onClose } = useDisclosure();

  const updateComps = async (comparables: UpdateListingComparableInput[]) => {
    await updateComparableSet({
      variables: {
        input: {
          comparables,
          buyerOfferAnalysisId: buyerOfferAnalysis?.id || '',
        },
      },
    });
    await refetchComparableSet();
  };

  const {
    data: offerRecsData,
    loading: offerRecsLoading,
    refetch: refetchOfferRecs,
  } = useGetOfferRecsQuery({
    variables: {
      input: {
        buyerOfferAnalysisId: buyerOfferAnalysis?.id || '',
      },
    },
    skip: !buyerOfferAnalysis?.id,
  });

  const onEditCompsModalOpen = (comp?: ComparableState) => {
    comp && setInitialSelectedComp(comp);
    onOpen();
  };

  const onEditCompsModalClose = () => {
    if (isCompsUpdated) {
      void refetchOfferRecs();
      setIsCompsUpdated(false);
    }
    onClose();
  };

  const originalOrderedComps = useMemo(() => {
    if (
      comparableSetData?.listingComparableSetForBuyerOfferAnalysis
        ?.listingComparables
    ) {
      return comparableSetData?.listingComparableSetForBuyerOfferAnalysis?.listingComparables
        ?.toSorted((a, b) => a.order - b.order)
        ?.map((comp) => ({
          mlsListing: comp.mlsListing,
        }));
    }

    return [];
  }, [comparableSetData]);

  useEffect(() => {
    if (!isDirty) {
      setComps(originalOrderedComps || []);
    }
  }, [isDirty, originalOrderedComps]);

  useEffect(() => {
    const comparator = (a: ComparableState[], b: ComparableState[]) =>
      a.length === b.length &&
      a.every(
        (comparable, index) =>
          comparable.mlsListing.id === b[index].mlsListing.id
      );
    if (!isEqualWith(comps, originalOrderedComps, comparator)) {
      setIsDirty(true);
    } else {
      setIsDirty(false);
    }
  }, [comps]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isDirty) {
      const input = comps.map((comp, index) => ({
        mlsListingId: comp.mlsListing.id,
        order: index + 1,
      }));
      void updateComps(input);
      setIsCompsUpdated(true);
    }
  }, [isDirty, comps]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <BuyerOfferAnalysisContext.Provider
      value={{
        offerRecs: offerRecsData?.getOfferRecs,
        buyerOfferAnalysis,
        listing,
        buyerOfferAnalysisLoading,
        comparableSetLoading,
        offerRecsLoading,
        comparableSet:
          comparableSetData?.listingComparableSetForBuyerOfferAnalysis,
        isEditCompsModalOpen,
        onEditCompsModalOpen,
        onEditCompsModalClose,
        comps,
        setComps,
        refetchComparableSet,
        initialSelectedComp,
      }}
    >
      {children}
    </BuyerOfferAnalysisContext.Provider>
  );
};

export const useBuyerOfferAnalysisContext = () => {
  return useContext(BuyerOfferAnalysisContext);
};
