import { Icon, IconProps } from '@chakra-ui/react';
import { memo } from 'react';

export const RulerIcon = memo((props: IconProps) => {
  return (
    <Icon
      fill="none"
      height="18"
      viewBox="0 0 21 18"
      width="21"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_1194_4551)">
        <path
          d="M4.54102 0.834961H0.538569C0.331072 0.834961 0.163574 1.00246 0.163574 1.20996V16.7923C0.163574 16.9998 0.331072 17.1673 0.538569 17.1673H4.54102C4.74852 17.1673 4.91601 16.9998 4.91601 16.7923V1.20996C4.91601 1.00246 4.74852 0.834961 4.54102 0.834961ZM4.16602 16.4173H0.913565V15.4348H1.44106C1.64856 15.4348 1.81605 15.2673 1.81605 15.0598C1.81605 14.8523 1.64856 14.6848 1.44106 14.6848H0.913565V13.7048H1.86355C2.07105 13.7048 2.23855 13.5373 2.23855 13.3298C2.23855 13.1223 2.07105 12.9548 1.86355 12.9548H0.913565V11.9748H1.44106C1.64856 11.9748 1.81605 11.8073 1.81605 11.5998C1.81605 11.3923 1.64856 11.2248 1.44106 11.2248H0.913565V10.2423H1.86355C2.07105 10.2423 2.23855 10.0748 2.23855 9.86735C2.23855 9.65985 2.07105 9.49235 1.86355 9.49235H0.913565V8.51236H1.44106C1.64856 8.51236 1.81605 8.34487 1.81605 8.13737C1.81605 7.92987 1.64856 7.76237 1.44106 7.76237H0.913565V6.78239H1.86355C2.07105 6.78239 2.23855 6.61489 2.23855 6.40739C2.23855 6.19989 2.07105 6.0324 1.86355 6.0324H0.913565V5.05241H1.44106C1.64856 5.05241 1.81605 4.88491 1.81605 4.67741C1.81605 4.46991 1.64856 4.30242 1.44106 4.30242H0.913565V3.31993H1.86355C2.07105 3.31993 2.23855 3.15243 2.23855 2.94493C2.23855 2.73744 2.07105 2.56994 1.86355 2.56994H0.913565V1.58995H4.16602V16.4223V16.4173Z"
          fill="currentColor"
        />
        <path
          d="M7.01845 0.967169C6.91595 0.847171 6.75095 0.802171 6.60345 0.85467C6.45596 0.90967 6.35596 1.04967 6.35596 1.20717V16.7895C6.35596 16.997 6.52345 17.1645 6.73095 17.1645H19.7883C19.9333 17.1645 20.0658 17.0795 20.1283 16.947C20.1908 16.8145 20.1683 16.6595 20.0758 16.547L7.01845 0.967169ZM7.10595 16.4145V15.4195H7.41844C7.62594 15.4195 7.79344 15.252 7.79344 15.0445C7.79344 14.837 7.62594 14.6695 7.41844 14.6695H7.10595V13.6895H7.83844C8.04594 13.6895 8.21343 13.522 8.21343 13.3145C8.21343 13.107 8.04594 12.9395 7.83844 12.9395H7.10595V11.9595H7.41844C7.62594 11.9595 7.79344 11.792 7.79344 11.5845C7.79344 11.377 7.62594 11.2095 7.41844 11.2095H7.10595V10.2271H7.83844C8.04594 10.2271 8.21343 10.0596 8.21343 9.85206C8.21343 9.64456 8.04594 9.47706 7.83844 9.47706H7.10595V8.49708H7.41844C7.62594 8.49708 7.79344 8.32958 7.79344 8.12208C7.79344 7.91458 7.62594 7.74709 7.41844 7.74709H7.10595V6.7671H7.83844C8.04594 6.7671 8.21343 6.5996 8.21343 6.3921C8.21343 6.1846 8.04594 6.01711 7.83844 6.01711H7.10595V5.03462H7.41844C7.62594 5.03462 7.79344 4.86712 7.79344 4.65962C7.79344 4.45213 7.62594 4.28463 7.41844 4.28463H7.10595V2.24215L18.9833 16.417H7.10595V16.4145Z"
          fill="currentColor"
        />
        <path
          d="M9.62597 14.2696H13.5859C13.7309 14.2696 13.8634 14.1846 13.9259 14.0521C13.9884 13.9196 13.9659 13.7646 13.8734 13.6521L9.91347 8.92713C9.81347 8.80713 9.64597 8.76213 9.49847 8.81463C9.35098 8.86963 9.25098 9.00963 9.25098 9.16713V13.8921C9.25098 14.0996 9.41847 14.2671 9.62597 14.2671V14.2696ZM10.001 10.1996L12.7809 13.5196H10.001V10.1996Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_1194_4551">
          <rect
            fill="white"
            height="16.4998"
            transform="translate(0.163574 0.75)"
            width="20"
          />
        </clipPath>
      </defs>
    </Icon>
  );
});
