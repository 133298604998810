import {
  FieldObject,
  SignatureFormFieldObject,
} from '@client/graphql/__generated__/types';
import { ChangeEvent, useCallback, useMemo, useRef } from 'react';
import { enumToLabel } from '~/services/document/utils/enums';
import { useDocumentMapperContext } from '../../DocumentMapper/context';
import { FormField } from '../../FormFields/types';
import { Signer } from '../DocumentSignatureMapper';
import { useDocumentSignatureMapper } from './useDocumentSignatureMapper';

interface UseEditSignatureWidgetOptions {
  signatureFormFieldsById: Record<string, SignatureFormFieldObject>;
  signersById: Record<string, Signer>;
  signatureDocumentVersionFieldsById: Record<string, FormField>;
  signatureFieldsById: ReturnType<
    typeof useDocumentSignatureMapper
  >['signatureFieldsById'];
  setSignatureFormFieldsById: ReturnType<
    typeof useDocumentSignatureMapper
  >['setSignatureFormFieldsById'];
}

export const useEditSignatureWidget = ({
  signatureFormFieldsById,
  signersById,
  signatureFieldsById,
  signatureDocumentVersionFieldsById,
  setSignatureFormFieldsById,
}: UseEditSignatureWidgetOptions) => {
  const {
    onFormFieldChange,
    onFormFieldDelete,
    pagesMetadata,
    setMoveableTargets,
    selectedFormField,
  } = useDocumentMapperContext();

  const rectRef = useRef<DOMRect>({
    left: 0,
    width: 0,
    top: 0,
  } as DOMRect);

  const activeFormField = useMemo(() => {
    if (selectedFormField) {
      return signatureDocumentVersionFieldsById[selectedFormField.id];
    }
  }, [selectedFormField, signatureDocumentVersionFieldsById]);
  const moveableTargetElem = useMemo(() => {
    if (activeFormField) {
      return document.querySelector(
        `div[data-formfieldid="${activeFormField.id}"`
      ) as HTMLElement;
    }
  }, [activeFormField]);

  const activeSignatureFormField = useMemo(() => {
    if (activeFormField) {
      return signatureFormFieldsById[activeFormField.id];
    }
  }, [activeFormField, signatureFormFieldsById]);

  const selectedSigner = useMemo(() => {
    if (!activeSignatureFormField) return;

    return signersById[activeSignatureFormField.signerId];
  }, [activeSignatureFormField, signersById]);

  const activePageMetadata = useMemo(() => {
    return pagesMetadata.find(({ pageNumber, documentIndex }) => {
      return (
        pageNumber === activeFormField?.pageNumber &&
        documentIndex === activeFormField.documentIndex
      );
    });
  }, [pagesMetadata, activeFormField]);

  const onConfirmDelete = useCallback(
    (onClose: () => void) => {
      if (activeFormField) {
        onFormFieldDelete([activeFormField]);
        setMoveableTargets([]);
        onClose();
      }
    },
    [activeFormField, onFormFieldDelete, setMoveableTargets]
  );

  const onSignerChange = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      const value = e.currentTarget.value;

      if (value && activeFormField) {
        const fields = signatureFieldsById[value] || {};
        const field = fields[activeFormField.fieldType] as FieldObject;
        const changed = {
          ...activeFormField,
          label: enumToLabel(activeFormField.fieldType),
        };

        if (field) {
          Object.assign(changed, {
            field,
            mappingKey: field.mappingKey,
            fieldType: field.fieldType,
            label: field.label,
          });
        }

        onFormFieldChange(changed);

        setSignatureFormFieldsById((prev) => {
          if (activePageMetadata && activeFormField) {
            const signatureFormField = prev[activeFormField.id];

            return {
              ...prev,
              [activeFormField.id]: {
                ...signatureFormField,
                signerId: value || '',
              },
            };
          }

          return prev;
        });
      }
    },
    [
      activeFormField,
      activePageMetadata,
      onFormFieldChange,
      setSignatureFormFieldsById,
      signatureFieldsById,
    ]
  );

  if (moveableTargetElem) {
    const rect = moveableTargetElem.getBoundingClientRect();
    rectRef.current = rect;
  }

  return {
    activeFormField,
    moveableTargetElem,
    selectedSigner,
    onConfirmDelete,
    onSignerChange,
    rectRef,
  };
};
