import { HStack, Icon, Text } from '@chakra-ui/react';
import {
  ContractValidationFragment,
  FieldObject,
  FieldType,
  PublicFieldObject,
} from '@client/graphql/__generated__/types';
import { CheckIcon, TriangleAlertIcon } from 'lucide-react';
import { FC, memo } from 'react';
import { OfferValidationDisplay } from '~/apps/consumer/components/OfferFlow/OfferValidations';
import { DaysTag } from '~/common/components/DaysTag';
import { PercentageTag } from '~/common/components/PercentageTag';
import { ValidationTag } from '~/common/components/ValidationTag';
import { useValidationsColor } from '../../hooks/useValidationsColor';
import { useFormatFieldValue } from './hooks/useFormatFieldValue';

interface FieldValueDisplayProps {
  field: FieldObject | PublicFieldObject;
  validation?: ContractValidationFragment;
  value?: string | readonly string[] | number | boolean | null;
  compareValue?: string | readonly string[] | number | boolean | null;
  showCheck?: boolean;
}

export const FieldValueDisplay: FC<FieldValueDisplayProps> = memo(
  function FieldValueDisplay({
    field,
    value,
    compareValue,
    validation,
    showCheck = true,
  }) {
    const formattedValue = useFormatFieldValue({
      field,
      value,
    });
    const validationColor = useValidationsColor(
      validation?.results,
      'validation.success'
    );

    return (
      <HStack>
        <HStack spacing={0}>
          {validation ? (
            <OfferValidationDisplay showIcon={false} validation={validation} />
          ) : (
            <Text>{formattedValue}</Text>
          )}
        </HStack>

        {field.fieldType === FieldType.NUMBER && compareValue && (
          <PercentageTag
            compareValue={compareValue as number}
            size="sm"
            value={value as number}
          />
        )}

        {field.fieldType === FieldType.DATE && compareValue && (
          <DaysTag
            compareValue={compareValue as string}
            size="sm"
            value={value as string}
          />
        )}

        {!compareValue && formattedValue && showCheck && (
          <ValidationTag lineHeight={0} minH={5} py={0}>
            <Icon
              as={
                validationColor === 'validation.success'
                  ? CheckIcon
                  : TriangleAlertIcon
              }
              color={validationColor}
            />
          </ValidationTag>
        )}
      </HStack>
    );
  }
);
