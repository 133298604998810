import {
  ListingDocumentTypeEnum,
  ListingDocumentTypeFragment,
  PublicListingDocumentTypeFragment,
  UsState,
} from '@client/graphql/__generated__/types';

// sort global doc types first
// sort by doc type array order second
const sortDocTypes =
  (types: ListingDocumentTypeEnum[]) =>
  (a: ListingDocumentTypeFragment, b: ListingDocumentTypeFragment) => {
    if (
      (a.usState === UsState.GLOBAL && b.usState === UsState.GLOBAL) ||
      (a.usState !== UsState.GLOBAL && b.usState !== UsState.GLOBAL)
    ) {
      return types.indexOf(a.type) - types.indexOf(b.type);
    }
    if (a.usState === UsState.GLOBAL) {
      return -1;
    }
    if (b.usState === UsState.GLOBAL) {
      return 1;
    }

    return 0;
  };
export const getFilteredListingDocumentTypes = (
  listingDocumentTypes?:
    | ListingDocumentTypeFragment[]
    | PublicListingDocumentTypeFragment[],
  types: ListingDocumentTypeEnum[] = [ListingDocumentTypeEnum.OTHER]
) => {
  if (!listingDocumentTypes?.length) {
    return [];
  }

  return listingDocumentTypes
    .filter((docType) => types.includes(docType.type))
    .sort(sortDocTypes(types));
};
