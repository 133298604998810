import { Icon, IconProps } from '@chakra-ui/react';

export const NumberIcon = (props: IconProps) => (
  <Icon
    color="primary.500"
    fill="none"
    height="23"
    viewBox="0 0 23 23"
    width="23"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <line
      stroke="currentColor"
      strokeLinecap="round"
      strokeWidth="2"
      x1="10"
      x2="14"
      y1="1.83337"
      y2="1.83337"
    />
    <line
      stroke="currentColor"
      strokeLinecap="round"
      strokeWidth="2"
      x1="10"
      x2="14"
      y1="22"
      y2="22"
    />
    <line
      stroke="currentColor"
      strokeLinecap="round"
      strokeWidth="2"
      x1="12"
      x2="12"
      y1="2"
      y2="21.0833"
    />
    <mask
      height="18"
      id="mask0_1002_4828"
      maskUnits="userSpaceOnUse"
      style={{
        maskType: 'alpha',
      }}
      width="23"
      x="0"
      y="1"
    >
      <path
        d="M0 19V8.50003V4.00003L9.1884 4.00002V1.80945L15 1.9216V4.00001L23 4V19H15V4.00001L9.1884 4.00002V19H0Z"
        fill="black"
      />
    </mask>
    <g mask="url(#mask0_1002_4828)">
      <rect
        height="13"
        stroke="currentColor"
        strokeWidth="2"
        width="21"
        x="1"
        y="5"
      />
    </g>
    <path
      d="M5.3015 14V9.11295H4.36676C4.26465 9.625 3.74622 10.0241 3 10.0316V10.7319H4.22537V14H5.3015Z"
      fill="currentColor"
    />
    <path
      d="M7.33717 11.003C7.3136 10.9202 7.29789 10.8223 7.29789 10.7319C7.29789 10.2726 7.56496 9.91867 8.10695 9.91867C8.61753 9.91867 8.87674 10.2349 8.87674 10.5889C8.87674 10.9277 8.70393 11.1837 8.27191 11.4322L7.43143 11.9142C6.52025 12.4413 6.23747 13.1416 6.22962 14H10V13.1039H7.55711C7.63566 12.9157 7.80061 12.7801 7.99698 12.6596L8.97886 12.0873C9.60725 11.7184 9.96858 11.2063 9.96858 10.5738C9.96858 9.72289 9.26949 9 8.13052 9C6.94442 9 6.26889 9.7756 6.26889 10.634C6.26889 10.7545 6.29246 10.9051 6.30817 10.9729L7.33717 11.003Z"
      fill="currentColor"
    />
    <path
      d="M16.568 11.7184C16.68 11.6807 16.816 11.6431 17.04 11.6431C17.536 11.6431 17.888 11.9066 17.888 12.366C17.888 12.7726 17.56 13.1039 17.008 13.1039C16.464 13.1039 16.088 12.7425 16.064 12.2455L15 12.4413C15.064 13.262 15.8 14 17.008 14C18.264 14 19 13.2244 19 12.381C19 11.4849 18.28 10.9202 17.496 10.8901L18.84 9.83584V9H15.168V9.89608H17.44L16.08 10.9955L16.568 11.7184Z"
      fill="currentColor"
    />
  </Icon>
);
