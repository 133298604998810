import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import 'mapbox-gl/dist/mapbox-gl.css';

import { Flex, Icon } from '@chakra-ui/react';
import {
  ListingComparableSetFragment,
  ListingFragment,
} from '@client/graphql/__generated__/types';
import { debounce } from 'lodash';
import { StarIcon } from 'lucide-react';
import { LngLatBounds, MapEvent } from 'mapbox-gl';
import { useCallback, useEffect, useMemo } from 'react';
import {
  Map,
  NavigationControl,
  Popup,
  ScaleControl,
  ViewStateChangeEvent,
} from 'react-map-gl';
import { TextMarker } from '~/apps/consumer/components/Map/MapMarkers/TextMarker';
import { DEFAULT_POSITION } from '~/apps/consumer/components/MapSearch/constants';
import { useExploreContext } from '~/apps/consumer/components/MapSearch/ExploreContext';
import { ExploreMapMarker } from '~/apps/consumer/components/MapSearch/ExploreMap/ExploreMapMarker';
import { convertBoundsToBbox } from '~/apps/consumer/components/MapSearch/helpers';
import { ComparableState } from '~/apps/consumer/pages/ListingSetup/Comparables/ComparablesPage';
import { getListingPrice } from '~/apps/consumer/utils/listing.utils';
import { useBuyerOfferAnalysisContext } from '../../../BuyerOfferAnalysisContext';
import { getInitialCompsBBox } from '../../utils';
import { EditCompsListingCard } from '../EditCompsListingCard';

export const EditCompsMap = ({
  mainListing,
  comparableSet,
}: {
  mainListing: ListingFragment;
  comparableSet?: ListingComparableSetFragment;
}) => {
  const { comps, setComps, initialSelectedComp } =
    useBuyerOfferAnalysisContext();
  const {
    listings,
    updateFilters,
    selectedListing,
    setSelectedListing,
    mapRef,
  } = useExploreContext();

  useEffect(() => {
    if (initialSelectedComp) {
      setSelectedListing(initialSelectedComp);
    }
  }, [initialSelectedComp, setSelectedListing]);

  const initialBBox = getInitialCompsBBox(comparableSet, mainListing);
  const initialBounds = initialBBox
    ? new LngLatBounds([
        initialBBox[0],
        initialBBox[1],
        initialBBox[2],
        initialBBox[3],
      ])
    : undefined;

  const fetchListings = useCallback(
    (event: MapEvent) => {
      const bounds = event.target.getBounds();
      const bbox = convertBoundsToBbox(bounds);

      if (bbox) {
        updateFilters({
          bbox,
        });
      }
    },
    [updateFilters]
  );

  const handleMove = useMemo(
    () =>
      debounce((event: ViewStateChangeEvent) => {
        fetchListings(event);
      }, 300),
    [fetchListings]
  );

  const markers = useMemo(() => {
    const mainMarker = mainListing?.mlsListing?.geo?.location ? (
      <ExploreMapMarker
        key={`marker-${mainListing.id}-main`}
        lat={mainListing.mlsListing.geo?.location.lat}
        lng={mainListing.mlsListing.geo?.location.lon}
        markerOverride={
          <TextMarker
            text={
              <Flex alignItems="center" gap={1}>
                <Icon as={StarIcon} />
                This home
              </Flex>
            }
            zIndex={100}
          />
        }
        zIndex={2}
        zoom={mapRef.current?.getZoom() || DEFAULT_POSITION.zoom}
      />
    ) : null;

    const comparableMarkers = comps?.map(({ mlsListing }) => {
      if (!mlsListing.geo?.location?.lat || !mlsListing.geo?.location?.lon) {
        return null;
      }

      const price = getListingPrice(mlsListing);

      return (
        <ExploreMapMarker
          key={`marker-${mlsListing.id}-comp`}
          isPremium={true}
          lat={mlsListing.geo.location.lat}
          lng={mlsListing.geo.location.lon}
          price={price}
          zoom={mapRef.current?.getZoom() || DEFAULT_POSITION.zoom}
          onClick={(event) => {
            event.originalEvent.stopPropagation();
            setSelectedListing({
              mlsListing: mlsListing,
            });
          }}
        />
      );
    });

    const otherMarkers = listings?.map(({ listing, mlsListing }) => {
      if (!mlsListing.geo?.location?.lat || !mlsListing.geo?.location?.lon) {
        return null;
      }

      // filter out other markers if there is already a comp
      if (comps?.some(({ mlsListing: comp }) => comp.id === mlsListing.id)) {
        return null;
      }

      const price = getListingPrice(mlsListing);

      return (
        <ExploreMapMarker
          key={`marker-${mlsListing.id}-other`}
          isPremium={false}
          lat={mlsListing.geo.location.lat}
          lng={mlsListing.geo.location.lon}
          price={price}
          zoom={mapRef.current?.getZoom() || DEFAULT_POSITION.zoom}
          onClick={(event) => {
            event.originalEvent.stopPropagation();
            setSelectedListing({
              listing,
              mlsListing,
            });
          }}
        />
      );
    });

    return [...(comparableMarkers ?? []), mainMarker, ...otherMarkers];
  }, [mainListing, mapRef, listings, setSelectedListing, comps]);

  const isSelectedListingAdded = comparableSet?.listingComparables?.some(
    (comparable) => comparable.mlsListing.id === selectedListing?.mlsListing?.id
  );

  const handleAddRemove = () => {
    if (!selectedListing?.mlsListing) {
      return;
    }

    let newComps = [] as ComparableState[];
    if (isSelectedListingAdded) {
      newComps = comps?.filter(
        (comp) => comp.mlsListing.id !== selectedListing?.mlsListing?.id
      );
    } else {
      newComps = [
        ...comps,
        {
          mlsListing: selectedListing?.mlsListing,
        },
      ];
    }

    setComps(newComps);
  };

  return (
    <Flex
      direction={{ base: 'column-reverse', xl: 'column' }}
      flexBasis={0}
      flexGrow={1}
      height="100%"
    >
      <Map
        ref={mapRef}
        initialViewState={{
          bounds: initialBounds,
          longitude: mainListing?.mlsListing?.geo?.location?.lon,
          latitude: mainListing?.mlsListing?.geo?.location?.lat,
          zoom: 12,
        }}
        mapStyle="mapbox://styles/mapbox/navigation-guidance-night-v4"
        mapboxAccessToken={window.ENVS.mapboxApiKey}
        minZoom={3}
        padding={{ top: 50, right: 300, bottom: 0, left: 0 }}
        scrollZoom={false}
        style={{ borderRadius: '8px' }}
        testMode={window.ENVS.environment === 'e2e'}
        onLoad={fetchListings}
        onMove={handleMove}
      >
        <NavigationControl position="bottom-left" />
        <ScaleControl />
        {markers}
        {selectedListing?.mlsListing && (
          <Popup
            latitude={selectedListing.mlsListing.geo.location.lat}
            longitude={selectedListing.mlsListing.geo.location.lon}
            offset={15}
            style={{
              maxWidth: '260px',
              minWidth: '260px',
              zIndex: 2,
              borderRadius: '12px',
            }}
            onClose={() => setSelectedListing(null)}
          >
            <EditCompsListingCard
              isAdded={comparableSet?.listingComparables?.some(
                (comparable) =>
                  comparable.mlsListing.id === selectedListing.mlsListing.id
              )}
              listing={selectedListing.listing as ListingFragment}
              mlsListing={selectedListing.mlsListing}
              onClick={handleAddRemove}
            />
          </Popup>
        )}
      </Map>
    </Flex>
  );
};
