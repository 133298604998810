import { Box, Center, Flex, Heading } from '@chakra-ui/react';
import { useUpdateStorefrontSetupMutation } from '@client/graphql/__generated__/main-operations';
import { StorefrontSetupStep } from '@client/graphql/__generated__/types';
import { useNavigate } from 'react-router';
import { FormGroupCard } from '~/apps/consumer/components/FormGroup/FormGroupCard';
import { Form } from '~/apps/consumer/components/Formik/Form';
import { StyledFormInput } from '~/apps/consumer/components/StyledFormInput/StyledFormInput';
import { getStorefrontSetupWizardRoute } from '~/apps/consumer/utils/routes.utils';
import { CenterSpinner } from '~/common/components/CenterSpinner';
import { useSellerPreferences } from '../Preferences/hooks/useSellerPreferences';
import { useStorefrontSetupWizardOutletContext } from './StorefrontSetupWizardContext';
import { StorefrontSetupWizardPageLayout } from './StorefrontSetupWizardPageLayout';
import { shouldUpdateContinueStep } from './utils';

export const SellerPreferencesPage = () => {
  const { listing } = useStorefrontSetupWizardOutletContext();
  const navigate = useNavigate();
  const [updateListingSetup] = useUpdateStorefrontSetupMutation();

  const { initialValues, onSubmit, loadingPreferences, preferenceGroups } =
    useSellerPreferences({ listing, showToast: false });

  return (
    <Form enableReinitialize initialValues={initialValues} onSubmit={onSubmit}>
      {(formProps) => (
        <StorefrontSetupWizardPageLayout
          onNext={() => {
            if (formProps.dirty) {
              formProps.handleSubmit();
            }
            if (
              shouldUpdateContinueStep({
                currentStep: listing?.storefrontSetup?.continueStep,
                updatingStep: StorefrontSetupStep.COMPARABLES,
              })
            ) {
            }
            void updateListingSetup({
              variables: {
                input: {
                  listingId: listing?.id,
                  continueStep: StorefrontSetupStep.COMPARABLES,
                },
              },
            });
            navigate(
              getStorefrontSetupWizardRoute(
                listing?.id,
                StorefrontSetupStep.COMPARABLES
              )
            );
          }}
          onPrevious={() => {
            navigate(getStorefrontSetupWizardRoute(listing?.id));
          }}
        >
          {loadingPreferences ? (
            <CenterSpinner />
          ) : (
            <Center
              flexDirection="column"
              gap={8}
              mt={{ base: 4, lg: 16 }}
              pb={4}
              px={4}
              width={{ base: '100%', lg: '80%' }}
            >
              <Heading textAlign="center">
                Receive the offer your seller wants
              </Heading>
              <Flex direction="column" gap={4} width="100%">
                {preferenceGroups.map((group) => {
                  return (
                    <FormGroupCard
                      key={group.title}
                      hideButton
                      title={group.title}
                    >
                      {group.preferences.map((preference) => {
                        return (
                          <Box key={preference.name} _last={{ pb: 5 }} px={6}>
                            <StyledFormInput
                              hasPlaceholder={preference.hasPlaceholder}
                              hideLabel={preference.hideLabel}
                              inputProps={preference.inputProps}
                              label={preference.name}
                              type={preference.inputType}
                            />
                          </Box>
                        );
                      })}
                    </FormGroupCard>
                  );
                })}
              </Flex>
            </Center>
          )}
        </StorefrontSetupWizardPageLayout>
      )}
    </Form>
  );
};
