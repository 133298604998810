import { FormControl, FormErrorMessage } from '@chakra-ui/react';
import { FormikProps } from 'formik';
import React from 'react';
import { PhoneNumberInputGroup } from '~/common/components/Inputs/PhoneNumberInputGroup';
import { UnstyledFormLabel } from './UnstyledFormLabel';

export const PhoneNumberFormControlWithValidation = <
  T extends Record<'phone', string>,
>({
  errors,
  setFieldTouched,
  setFieldValue,
  touched,
  extra,
}: FormikProps<T> & {
  extra?: React.ReactNode;
}) => {
  const isInvalid = !!errors.phone && !!touched.phone;

  return (
    <FormControl isRequired isInvalid={isInvalid}>
      <UnstyledFormLabel>Phone number</UnstyledFormLabel>
      <PhoneNumberInputGroup
        id="phone"
        name="phone"
        placeholder="e.g. (516) 425-1234"
        onBlur={() => void setFieldTouched('phone', true)}
        onChange={(e) => {
          void setFieldValue('phone', e.currentTarget.value);
        }}
      />
      <FormErrorMessage>{errors.phone as React.ReactNode}</FormErrorMessage>
      {!isInvalid && extra}
    </FormControl>
  );
};
