import {
  Button,
  Card,
  Divider,
  Flex,
  FormControl,
  Input,
  Link,
  Spacer,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import {
  useGetInviteeDetailByCodeQuery,
  useSendOtpFromInvitationLinkMutation,
} from '@client/graphql/__generated__/main-operations';
import { useState } from 'react';
import OtpInput from 'react-otp-input';
import {
  listingOffersPath,
  listingsPath,
} from '~/apps/consumer/utils/routes.utils';
import { IndigoLogo } from '~/common/icons/IndigoLogo';
import { useAppContext } from '../../contexts/AppContext';

export const LoginForInvitedSeller = () => {
  const toast = useToast();
  const [codeSent, setCodeSent] = useState(false);
  const [code, setCode] = useState<string>('');
  const { appState, refetchUser } = useAppContext();

  const [sendOtpFromInvitationLinkMutation, { data, loading }] =
    useSendOtpFromInvitationLinkMutation({
      variables: {
        input: {
          invitationLink: window.location.href,
        },
      },
    });

  const { data: inviteeData, error } = useGetInviteeDetailByCodeQuery({
    variables: {
      input: {
        invitationLink: window.location.href,
      },
    },
  });

  const { firstName, lastName, phoneNumber } =
    inviteeData?.getInviteeDetailByCode || {};

  const validLinkContent = (
    <>
      <Text>
        Hey {firstName} {lastName}!
      </Text>
      <Text>
        Your listing agent{' '}
        <Text as="span" color="primary.500">
          {data?.sendOTPFromInvitationLink?.agentName}{' '}
        </Text>{' '}
        would like to share offers on your home with you.
      </Text>
      <Divider
        borderColor="primary.500"
        borderWidth={1}
        opacity={1}
        width={12}
      />
      {!codeSent && !error && (
        <>
          <Text>
            Send code to phone number ending in{' '}
            <Text as="span" color="primary.500">
              {String(phoneNumber)?.slice(-4)}
            </Text>
          </Text>
          <Button
            isLoading={loading}
            onClick={() => {
              void sendOtpFromInvitationLinkMutation().then(() => {
                setCodeSent(true);
                toast({
                  title: 'Code sent!',
                  description: 'Please enter the code sent to your phone.',
                  status: 'info',
                  duration: 2000,
                  isClosable: true,
                });
              });
            }}
          >
            Send code
          </Button>
        </>
      )}
      {codeSent && !error && (
        <Text>
          Please enter the code sent to the phone ending in{' '}
          <Text as="span" color="primary.500">
            {String(phoneNumber)?.slice(-4)}
          </Text>{' '}
          to continue
        </Text>
      )}
    </>
  );

  return (
    <Flex
      alignItems="center"
      bgColor={appState.bgColor}
      flexDirection="column"
      height="100vh"
      justifyContent="center"
      width="100hv"
    >
      <Card alignItems="center" justifyContent="center" p={4}>
        <VStack
          flex={3}
          fontSize="md"
          justifyContent="center"
          p={2}
          spacing={6}
          textAlign="center"
          width="300px"
        >
          <IndigoLogo width="100px" />
          {error ? (
            <Text>
              Your invitation link is expired or invalid. Please ask your agent
              to send you a new one.
            </Text>
          ) : (
            validLinkContent
          )}
        </VStack>
        {codeSent && !error && (
          <>
            <VStack
              as="form"
              bgColor="bg.mainDark"
              borderRadius="md"
              flex={1}
              p={6}
              spacing={4}
              width="340px"
            >
              {/* // TODO: only show if method id received */}
              <FormControl isRequired>
                <OtpInput
                  shouldAutoFocus
                  containerStyle={{
                    width: '100%',
                    justifyContent: 'space-between',
                  }}
                  inputStyle={{
                    width: '40px',
                    height: '60px',
                    paddingLeft: 0,
                    paddingRight: 0,
                    fontSize: '30px',
                    fontWeight: 'bold',
                  }}
                  numInputs={6}
                  renderInput={(inputProps) => <Input {...inputProps} />}
                  value={code}
                  onChange={(otp) => {
                    setCode(otp);
                    if (otp.length === 6) {
                      void fetch('/auth/authenticate-otp', {
                        method: 'POST',
                        headers: {
                          'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                          code: otp,
                          methodId: data?.sendOTPFromInvitationLink?.methodId,
                        }),
                      })
                        .then(() => {
                          return refetchUser();
                        })
                        .finally(() => {
                          const listingId =
                            data?.sendOTPFromInvitationLink?.listingId;
                          const redirectPath = listingId
                            ? listingOffersPath(listingId)
                            : listingsPath();
                          window.location.assign(redirectPath);
                        });
                    }
                  }}
                  onPaste={(e) => {
                    const value = e.clipboardData.getData('text/plain');

                    if (value && value.length === 6) {
                      setCode(value);
                    }
                  }}
                />
              </FormControl>
            </VStack>
            <Spacer flex={4} />
            <VStack flex={1}>
              <Link
                textDecoration="underline"
                onClick={(e) => {
                  e.preventDefault();
                  void sendOtpFromInvitationLinkMutation();
                  toast({
                    title: 'Resent code!',
                    description: 'Check your phone for the new code.',
                    status: 'info',
                    duration: 2000,
                    isClosable: true,
                  });
                }}
              >
                Resend
              </Link>
            </VStack>
          </>
        )}
      </Card>
    </Flex>
  );
};
