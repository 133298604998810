import {
  Box,
  Button,
  Flex,
  HStack,
  Heading,
  Link,
  Spacer,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { Roles } from '@client/graphql/__generated__/types';
import { CircleDollarSign, Mail } from 'lucide-react';
import { Dispatch, useState } from 'react';
import { Outlet, useMatch } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { ListingUploadOfferModal } from '~/apps/consumer/components/Offers/ListingUploadOfferModal';
import { OfferCard } from '~/apps/consumer/components/Offers/OfferCard';
import { useListingSetupContext } from '~/apps/consumer/pages/ListingSetup/ListingSetupContext';
import { useIsMobile } from '~/common/hooks/useIsMobile';
import { SwapIcon } from '~/common/icons/SwapIcon';
import { useAppContext } from '~/services/main/contexts/AppContext';
import { EmptyState } from '../../../components/EmptyState';
import { OffersCompareView } from '../../../components/Offers/CompareOffers/OffersCompareView';
import { RoleFilter } from '../../../components/User/RoleFilter';
import { CollectOffersByEmailModalTrigger } from '../Dashboard/CollectOffersByEmailModalTrigger';
import { ShareStorefrontModalTrigger } from '../Dashboard/ShareStorefrontModalTrigger';
import { ListingSetupContentWrapper } from '../ListingSetupWrapperComponents';
import { ShareWithSellerButton } from './ShareWithSellerButton';
import { UploadOfferByListingAgentButton } from './UploadOfferByListingAgentButton';

export const ListingOffersPage = () => {
  const { user } = useAppContext();
  const isMobile = useIsMobile();
  const { listing, offersWithContracts, offerCardSections } =
    useListingSetupContext();
  const [isProcessingUploadedOffer, setIsProcessingUploadedOffer] =
    useState(false);

  const [searchParams, setSearchParams] = useSearchParams();

  const isShowingListView = searchParams.get('view')?.toLowerCase() === 'list';
  const toggleShowingCompareView = () => {
    setSearchParams(isShowingListView ? {} : { view: 'list' });
  };

  const isOfferDetailsView = useMatch('/listings/:listingId/offers/:offerId');

  if (isMobile && isOfferDetailsView) {
    return (
      <Outlet context={{ listing, offersWithContracts, offerCardSections }} />
    );
  }

  return (
    <ListingSetupContentWrapper>
      <Flex direction="column" gap={4} width={{ base: '100%', md: '80%' }}>
        <Flex direction={{ base: 'column-reverse', md: 'row' }} gap={2}>
          <HStack width="100%">
            <Heading size="xxs">Offers</Heading>
            <Text fontSize="xs" mb="8px">
              {offersWithContracts.length}
            </Text>
          </HStack>
          <Spacer />
          <HStack>
            {user?.offerCollectionEmail && (
              <CollectOffersByEmailModalTrigger
                offerCollectionEmail={user.offerCollectionEmail}
              >
                <Button
                  fontWeight="semibold"
                  iconSpacing={1}
                  leftIcon={<Mail size={16} />}
                  size="sm"
                  variant="outline"
                >
                  Email
                </Button>
              </CollectOffersByEmailModalTrigger>
            )}
            <UploadOfferByListingAgentButton
              isProcessingUploadedOffer={isProcessingUploadedOffer}
              setIsProcessingUploadedOffer={setIsProcessingUploadedOffer}
            />
            <Button
              fontWeight="semibold"
              iconSpacing={1}
              leftIcon={<SwapIcon boxSize={4} />}
              size="sm"
              variant="outline"
              onClick={toggleShowingCompareView}
            >
              {isShowingListView ? 'Compare' : 'List'}
            </Button>
            <ShareWithSellerButton />
          </HStack>
        </Flex>
        {offersWithContracts.length === 0 ? (
          <NoOffersView
            setIsProcessingUploadedOffer={setIsProcessingUploadedOffer}
          />
        ) : isShowingListView ? (
          <OffersViewList />
        ) : (
          <OffersCompareView offersWithContracts={offersWithContracts} />
        )}

        <Outlet context={{ listing, offersWithContracts, offerCardSections }} />
      </Flex>
    </ListingSetupContentWrapper>
  );
};

export const OffersViewList = () => {
  const { offersWithContracts, offerCardSections } = useListingSetupContext();

  return (
    <Box width="100%">
      <Flex direction="column" gap={4}>
        {offersWithContracts.map((offerProps, idx) => {
          return (
            <OfferCard
              key={idx}
              offerCardSections={offerCardSections}
              offerWithContract={offerProps}
            />
          );
        })}
      </Flex>
    </Box>
  );
};

interface NoOffersViewProps {
  setIsProcessingUploadedOffer: Dispatch<React.SetStateAction<boolean>>;
}

export const NoOffersView = ({
  setIsProcessingUploadedOffer,
}: NoOffersViewProps) => {
  const { listing, refetchAllData } = useListingSetupContext();

  const {
    isOpen: isUploadModalOpen,
    onOpen: onUploadModalOpen,
    onClose: onUploadModalClose,
  } = useDisclosure();

  return (
    <EmptyState
      headerText="You haven’t received any offers yet"
      icon={CircleDollarSign}
      minH="70vh"
      subText={
        <RoleFilter roles={[Roles.AGENT]}>
          <ShareStorefrontModalTrigger as="span" listing={listing}>
            <Link color="link.skyBlue">Share your storefront</Link>
          </ShareStorefrontModalTrigger>
          &nbsp;or&nbsp;
          <Link as="span" color="link.skyBlue" onClick={onUploadModalOpen}>
            Upload an offer
          </Link>
          <ListingUploadOfferModal
            isOpen={isUploadModalOpen}
            listingId={listing.id}
            refetchAllData={refetchAllData}
            setIsProcessingUploadedOffer={setIsProcessingUploadedOffer}
            onClose={onUploadModalClose}
          />
        </RoleFilter>
      }
    />
  );
};
