import { Box, Flex, Image, Link, Text } from '@chakra-ui/react';
import { PublicListingFragment } from '@client/graphql/__generated__/types';
import React, { useState } from 'react';

interface ListingDisclaimerProps {
  listing: PublicListingFragment;
  linesShown?: number;
  extraText?: string;
}

export const ListingDisclaimer: React.FC<ListingDisclaimerProps> = ({
  listing,
  linesShown = 2,
  extraText,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => setIsExpanded(!isExpanded);

  const { disclaimer, logoUrl } = listing.mlsListing?.mls || {};

  if (!disclaimer) return null;

  return (
    <Flex alignItems="center" gap={6}>
      {logoUrl && (
        <Box maxW="58px" w="100%">
          <Image alt="MLS Logo" objectFit="contain" src={logoUrl} w="100%" />
        </Box>
      )}
      <Box color="whiteAlpha.600" fontSize="sm" lineHeight={1.25}>
        <Text noOfLines={isExpanded ? undefined : linesShown}>
          {disclaimer}
        </Text>
        <Link as="span" fontSize="sm" onClick={toggleExpand}>
          {isExpanded ? 'Less' : 'More'}
        </Link>
        {extraText && <Text mt={4}>{extraText}</Text>}
      </Box>
    </Flex>
  );
};
