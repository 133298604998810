import {
  ListingFragment,
  PublicListingFragment,
} from '@client/graphql/__generated__/types';

export const getStorefrontUrl = (
  propertySlug: string,
  queryParams?: Record<string, string>
): string => {
  let url = `${window.location.origin}/storefront/${propertySlug}`;

  if (queryParams && Object.keys(queryParams).length > 0) {
    const searchParams = new URLSearchParams(queryParams);
    url += `?${searchParams.toString()}`;
  }

  return url;
};

interface UtmParams {
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_term?: string;
  utm_content?: string;
}

export const getStorefrontUrlWithAnalytics = (
  propertySlug: string,
  utmParams?: UtmParams
): string => {
  const queryParams: Record<string, string> = {};

  if (utmParams) {
    Object.entries(utmParams).forEach(([key, value]) => {
      if (value) {
        queryParams[key as keyof UtmParams] = value as string;
      }
    });
  }

  return getStorefrontUrl(
    propertySlug,
    Object.keys(queryParams).length > 0 ? queryParams : undefined
  );
};

export const getStorefrontRoute = (
  listing: ListingFragment | PublicListingFragment
) => {
  const slug = listing?.property?.slug;

  if (slug) {
    return `/storefront/${slug}`;
  } else {
    return '/storefront';
  }
};

export const getDaysOnMarketText = (daysOnMarket?: number, isShort = false) => {
  if (typeof daysOnMarket === 'number') {
    if (daysOnMarket <= 0) {
      // Negative should also be 0
      return isShort ? '0 days' : '0 days on market';
    } else if (daysOnMarket === 1) {
      return isShort ? '1 day' : '1 day on market';
    } else {
      return isShort
        ? `${daysOnMarket} days`
        : `${daysOnMarket} days on market`;
    }
  }

  return isShort ? '- days' : '- days on market';
};
