import { ContractValidationFragment } from '@client/graphql/__generated__/types';
import { startCase } from 'lodash';
import { useMemo } from 'react';
import { shouldFieldHaveDisplayLabel } from '../helpers';

/**
 * Hook to get the display label for a validation field.
 * @param validation - The validation object containing field information.
 * @returns The display label for the validation field.
 */
export const useGetValidationDisplayLabel = (
  validation: ContractValidationFragment
) => {
  return useMemo(() => {
    const fieldType =
      validation.documentVersionField.fieldType || validation.field.fieldType;
    const originalLabel =
      validation.documentVersionField.label || validation.field.label;

    if (shouldFieldHaveDisplayLabel(fieldType)) {
      const groupLabel = startCase(String(validation.field.group || ''));

      return (
        validation.documentVersionField.displayLabel ||
        validation.field.displayLabel ||
        groupLabel ||
        originalLabel
      );
    }

    return originalLabel;
  }, [validation]);
};
