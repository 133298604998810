import { Flex, VStack } from '@chakra-ui/react';
import { FC } from 'react';

import { useDeleteContractDocumentsMutation } from '@client/graphql/__generated__/document-operations';
import { PublicDocumentVersionObject } from '@client/graphql/__generated__/types';
import { AddAddendaCard } from '~/apps/consumer/components/OfferFlow/AddAddendaCard/AddAddendaCard';
import { DocumentRow } from '~/apps/consumer/components/OfferFlow/DocumentRow';
import { CenterSpinner } from '~/common/components/CenterSpinner';
import { OfferFlowContainer } from './OfferFlowContainer';
import { useOfferFlowContext } from './OfferFlowContext';

export const AddDocsPage: FC = () => {
  const {
    activeDocument,
    activeDocumentVersion,
    additionalDocs,
    otherDisclosureContractDocs,
    latestOffer,
    updateFieldValues,
  } = useOfferFlowContext();

  const [deleteContractDocuments, { loading: isDeleting }] =
    useDeleteContractDocumentsMutation();

  const updateMappingKeyFieldValues = (mappingKeys?: string[]) => {
    if (!mappingKeys?.length) {
      return;
    }
    updateFieldValues(
      mappingKeys.reduce((acc, mappingKey) => {
        acc[mappingKey] = false;

        return acc;
      }, {})
    );
  };

  const onDelete = async (
    contractDocumentId: string,
    dv?: PublicDocumentVersionObject
  ) => {
    const mappingKeys = dv?.mappingKeys;
    await deleteContractDocuments({
      variables: {
        input: {
          contractId: latestOffer?.contract?.id as string,
          contractDocuments: [
            {
              contractDocumentUuid: contractDocumentId,
              mappingKeys: mappingKeys?.length ? mappingKeys : undefined,
            },
          ],
        },
      },
    });
    updateMappingKeyFieldValues(mappingKeys);
  };

  if (!activeDocument || !activeDocumentVersion) {
    return <CenterSpinner />;
  }

  return (
    <OfferFlowContainer onContinueRoutePath="./fill-docs">
      <VStack alignItems="stretch" flexGrow={1} spacing={4}>
        <Flex direction="column" flexShrink={0} width="100%">
          <AddAddendaCard />
        </Flex>

        <VStack alignItems="stretch" flexGrow={1} spacing={2}>
          {additionalDocs?.map(
            (
              doc: NonNullable<
                (typeof activeDocumentVersion)['secondaryVersions']
              >[0],
              index: number
            ) => {
              const mappingKey = doc.mappingKeys?.[0];

              const existingContractDoc =
                latestOffer?.contract?.contractDocuments?.find(
                  (cd) =>
                    cd.documentVersion?.document.form === doc.document.form
                );

              const previewIndex = index + 1;

              return (
                <DocumentRow
                  key={mappingKey}
                  isLoading={isDeleting}
                  name={doc.document.name}
                  previewIndex={previewIndex}
                  onDelete={() => {
                    if (existingContractDoc) {
                      void onDelete(
                        existingContractDoc.id,
                        doc as PublicDocumentVersionObject
                      );
                    } else {
                      updateMappingKeyFieldValues([mappingKey]);
                    }
                  }}
                />
              );
            }
          )}
          {otherDisclosureContractDocs.map((contractDoc, index) => (
            <DocumentRow
              key={contractDoc.id}
              isLoading={isDeleting}
              name={
                contractDoc.documentVersion?.document.name ||
                contractDoc.externalDocumentName ||
                ''
              }
              previewIndex={(additionalDocs?.length || 0) + 1 + index}
              onDelete={() => void onDelete(contractDoc.id)}
            />
          ))}
        </VStack>
      </VStack>
    </OfferFlowContainer>
  );
};
