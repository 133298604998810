import { Box } from '@chakra-ui/react';
import { useDroppable } from '@dnd-kit/core';
import {
  FC,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Page } from 'react-pdf';
import { useIsMobile } from '~/common/hooks/useIsMobile';
import { PDFScrollableContext, PDFScrollableContextValue } from './context';
import { PageMetadata } from './types';

interface PDFScrollableDocumentPageProps {
  disableFormFields?: boolean;
  pageNumber: number;
  documentIndex: number;
  width: number;
  height: number;
  scale: number;
  heightRatio: number;
  zoom: number;
  sizeRatio: number;
  pageChildren?: FC<{ page: PageMetadata; disableFormFields?: boolean }>;
  isVisible: boolean;
  observer?: IntersectionObserver;
  thumbnailPages: PDFScrollableContextValue['thumbnailPages'];
  documentsThumbnailsRef: PDFScrollableContextValue['documentsThumbnailsRef'];
  setDocumentsThumbnails: PDFScrollableContextValue['setDocumentsThumbnails'];
}

const PDFScrollableDocumentPageWithContext: FC<PDFScrollableDocumentPageProps> =
  memo(function PDFScrollableDocumentPageWithContext(props) {
    const isMobile = useIsMobile();
    const { setNodeRef } = useDroppable({
      id: `droppable-${props.documentIndex}-${props.pageNumber}`,
      data: {
        documentIndex: props.documentIndex,
        pageNumber: props.pageNumber,
      },
    });
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const [shouldRender, setShouldRender] = useState(false);
    const onRenderSuccess = useCallback(() => {
      if (!props.documentsThumbnailsRef.current || !canvasRef.current) return;

      const canvas = document.createElement('canvas');

      canvas.width = 600;
      canvas.height = 600 * props.heightRatio;

      const canvasContext = canvas.getContext('2d');

      const documentThumbnails =
        props.documentsThumbnailsRef.current[props.documentIndex];
      const shouldGetThumbnail =
        !props.thumbnailPages ||
        props.thumbnailPages.includes(props.pageNumber);

      if (
        props.setDocumentsThumbnails &&
        shouldGetThumbnail &&
        canvasContext &&
        documentThumbnails &&
        canvasRef.current &&
        !documentThumbnails.thumbnails[props.pageNumber]?.thumbnail
      ) {
        canvasContext.imageSmoothingEnabled = true;
        canvasContext.imageSmoothingQuality = 'low';

        canvasContext.drawImage(
          canvasRef.current,
          0,
          0,
          canvas.width,
          canvas.height
        );

        canvas.toBlob((blob) => {
          if (blob) {
            documentThumbnails.thumbnails[props.pageNumber] = {
              pageNumber: props.pageNumber,
              thumbnail: URL.createObjectURL(blob),
            };

            if (props.documentsThumbnailsRef.current) {
              props.setDocumentsThumbnails &&
                props.setDocumentsThumbnails({
                  ...props.documentsThumbnailsRef.current,
                });
            }
          }
        });
      }
    }, [canvasRef, props]);

    useEffect(() => {
      if (!shouldRender && props.isVisible) {
        setShouldRender(true);
      }
    }, [props.isVisible, shouldRender]);

    const pageChildrenPage = useMemo(() => {
      return {
        width: props.width,
        height: props.height,
        heightRatio: props.heightRatio,
        pageNumber: props.pageNumber,
        documentIndex: props.documentIndex,
        sizeRatio: props.sizeRatio,
        isVisible: props.isVisible,
      };
    }, [
      props.documentIndex,
      props.height,
      props.heightRatio,
      props.isVisible,
      props.pageNumber,
      props.sizeRatio,
      props.width,
    ]);

    return (
      <Box
        ref={(ref) => {
          if (ref) {
            props.observer?.observe(ref);
            setNodeRef(ref);
          }
        }}
        data-document-index={props.documentIndex}
        data-page-number={props.pageNumber}
        data-testid="pdf-scrollable-page"
        height={props.height}
        position="relative"
        transformOrigin="0px 0px"
        width={props.width}
      >
        <>
          <Box
            data-testid="pdf-page-container"
            transform={`scale(${
              window.devicePixelRatio === 1 ? 1 / props.scale : 1
            })`}
            transformOrigin="0px 0px"
          >
            {!isMobile || shouldRender ? (
              <Page
                key={props.pageNumber}
                canvasRef={canvasRef}
                pageNumber={props.pageNumber}
                renderAnnotationLayer={false}
                renderForms={false}
                renderTextLayer={false}
                scale={window.devicePixelRatio === 1 ? props.scale : 1}
                width={props.width}
                onRenderSuccess={onRenderSuccess}
              />
            ) : null}
          </Box>
          {props.pageChildren && (
            <Box
              bottom={0}
              height="100%"
              left={0}
              position="absolute"
              right={0}
              top={0}
            >
              <props.pageChildren
                disableFormFields={props.disableFormFields}
                page={pageChildrenPage}
              />
            </Box>
          )}
        </>
      </Box>
    );
  });

export const PDFScrollableDocumentPage: FC<
  Omit<
    PDFScrollableDocumentPageProps,
    | 'observer'
    | 'isVisible'
    | 'documentsThumbnailsRef'
    | 'setDocumentsThumbnails'
    | 'thumbnailPages'
  >
> = memo(function PDFScrollableDocumentPage(props) {
  const {
    observer,
    visiblePages,
    visibleDocumentIndex,
    documentsThumbnailsRef,
    setDocumentsThumbnails,
    thumbnailPages,
    disableFormFields,
  } = useContext(PDFScrollableContext);

  return (
    <PDFScrollableDocumentPageWithContext
      {...props}
      disableFormFields={disableFormFields}
      documentsThumbnailsRef={documentsThumbnailsRef}
      isVisible={
        !!visiblePages?.includes(props.pageNumber) &&
        visibleDocumentIndex === props.documentIndex
      }
      observer={observer}
      setDocumentsThumbnails={setDocumentsThumbnails}
      thumbnailPages={thumbnailPages}
    />
  );
});
