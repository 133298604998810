import { Icon, IconProps } from '@chakra-ui/react';
import { memo } from 'react';

export const FacebookIcon = memo((props: IconProps) => {
  return (
    <Icon
      fill="none"
      viewBox="0 0 21 20"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <svg fill="none" viewBox="0 0 16 26" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M14.31 14.8482L15.233 10.1609H9.8594V8.50345C9.8594 6.02629 10.9021 5.0734 13.5995 5.0734C14.4373 5.0734 15.1115 5.09268 15.5 5.13066V0.881518C14.7644 0.691061 12.9661 0.5 11.9234 0.5C6.42631 0.5 3.89237 2.9205 3.89237 8.14122V10.1609H0.5V14.8482H3.89237V25.0486C5.16516 25.3427 6.49612 25.5 7.86652 25.5C8.54137 25.5 9.20588 25.4614 9.8594 25.3879V14.8482H14.31Z"
          fill="#1F1F3B"
        />
      </svg>
    </Icon>
  );
});
