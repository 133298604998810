import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Select,
  Text,
  chakra,
} from '@chakra-ui/react';
import { SignatureFormFieldObject } from '@client/graphql/__generated__/types';
import { FC, memo } from 'react';
import { ConfirmModal } from '~/common/components/Modal/ConfirmModal';
import { enumToLabel } from '../../utils/enums';
import { FormField } from '../FormFields/types';
import { Signer } from './DocumentSignatureMapper';
import { useDocumentSignatureMapper } from './hooks/useDocumentSignatureMapper';
import { useEditSignatureWidget } from './hooks/useEditSignatureWidget';

export interface EditSignatureWidgetProps {
  signers: Signer[];
  signersById: Record<string, Signer>;
  signatureDocumentVersionFieldsById: Record<string, FormField>;
  signatureFormFieldsById: Record<string, SignatureFormFieldObject>;
  signatureFieldsById: ReturnType<
    typeof useDocumentSignatureMapper
  >['signatureFieldsById'];
  setSignatureFormFieldsById: ReturnType<
    typeof useDocumentSignatureMapper
  >['setSignatureFormFieldsById'];
}

export const EditSignatureWidget: FC<EditSignatureWidgetProps> = memo(
  function EditSignatureWidget({
    signers,
    signersById,
    signatureFormFieldsById,
    signatureFieldsById,
    signatureDocumentVersionFieldsById,
    setSignatureFormFieldsById,
  }) {
    const {
      activeFormField,
      moveableTargetElem,
      selectedSigner,
      rectRef,
      onConfirmDelete,
      onSignerChange,
    } = useEditSignatureWidget({
      signatureFormFieldsById,
      signersById,
      signatureDocumentVersionFieldsById,
      setSignatureFormFieldsById,
      signatureFieldsById,
    });

    return (
      <EditSignatureWidgetContainer
        bottom={{
          base: 0,
          md: 'auto',
        }}
        display={moveableTargetElem ? 'flex' : 'none'}
        left={{
          base: 0,
          md: rectRef.current.left + 25 + rectRef.current.width,
        }}
        opacity={moveableTargetElem ? 1 : 0}
        top={{
          md: moveableTargetElem ? `${rectRef.current?.top - 50}px` : '-100%',
        }}
      >
        <Card
          backdropFilter="blur(10px)"
          bgColor="whiteAlpha.800"
          boxShadow="sm"
          height="100%"
          width="100%"
        >
          <CardHeader as={HStack} px={4} py={2}>
            <Text flexGrow={1}>
              {activeFormField?.label ||
                enumToLabel(activeFormField?.fieldType || '')}
            </Text>

            <HStack>
              <ConfirmModal
                isCentered
                description="Are you sure you want to delete this field?"
                title={`Delete ${
                  activeFormField?.label ||
                  enumToLabel(activeFormField?.fieldType || '') ||
                  ''
                }?`}
                type="delete"
                onConfirm={onConfirmDelete}
              >
                {(onOpen) => (
                  <Button colorScheme="red" size="xs" onClick={onOpen}>
                    Delete
                  </Button>
                )}
              </ConfirmModal>
            </HStack>
          </CardHeader>
          <Divider />
          <CardBody px={4} py={4}>
            <FormControl>
              <FormLabel>Signer</FormLabel>
              <Select
                key={activeFormField?.id || 'signer'}
                bgColor="white"
                borderRadius="sm"
                placeholder="Select signer..."
                value={selectedSigner?.id || ''}
                width="100%"
                onChange={onSignerChange}
              >
                {signers.map((signer) => (
                  <option key={signer.id} value={signer.id}>
                    {signer.fullName}
                  </option>
                ))}
              </Select>
            </FormControl>
          </CardBody>
        </Card>
      </EditSignatureWidgetContainer>
    );
  }
);

const EditSignatureWidgetContainer = chakra(Flex, {
  baseStyle: {
    justifyContent: 'center',
    position: { base: 'absolute', md: 'fixed' },
    right: {
      base: 0,
      md: 'auto',
    },
    transition: 'all 0.3s',
    width: {
      base: '100%',
      md: '300px',
    },
    zIndex: 'modal',
  },
});
