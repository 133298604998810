import { HStack } from '@chakra-ui/react';
import { PropsWithChildren } from 'react';

export const PageDetailLayout = ({ children }: PropsWithChildren) => {
  return (
    <HStack
      alignItems="flex-start"
      backgroundColor="bg.main"
      flexGrow={0}
      gap={0}
      id="PageDetailLayout"
      mx="auto"
      width="100%"
    >
      {children}
    </HStack>
  );
};
