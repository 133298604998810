import {
  Box,
  Button,
  Center,
  Link as ChakraLink,
  Flex,
  Image,
  Text,
} from '@chakra-ui/react';
import { useUpdateStorefrontSetupMutation } from '@client/graphql/__generated__/main-operations';
import { StorefrontSetupStep } from '@client/graphql/__generated__/types';
import { Link, useNavigate } from 'react-router-dom';
import storefrontImage from '~/apps/consumer/images/storefrontSetupImage.png';
import { useStorefrontSetupWizardOutletContext } from './StorefrontSetupWizardContext';

export const IntroPage = () => {
  const [updateListingSetup] = useUpdateStorefrontSetupMutation();
  const { listing } = useStorefrontSetupWizardOutletContext();
  const navigate = useNavigate();

  return (
    <Box maxWidth="100%" minHeight="100vh" position="relative">
      <Box position="absolute" right={8} top={8}>
        <ChakraLink
          color="whiteAlpha.600"
          fontSize="lg"
          fontWeight="medium"
          onClick={() => navigate(`/listings/${listing?.id}`)}
        >
          Skip setup
        </ChakraLink>
      </Box>
      <Center
        alignItems="center"
        flexDirection={{ base: 'column-reverse', md: 'row' }}
        gap={{ base: 4, md: 0 }}
        justifyContent="center"
        maxWidth="100%"
        minHeight="100vh"
        mx={{ base: 2, md: 20 }}
      >
        <Flex direction="column" gap={2}>
          <Text
            background="blueGradient"
            backgroundClip="text"
            fontFamily="Cardinal Photo"
            fontSize={{ base: '60px', md: '90px' }}
            fontWeight={300}
            lineHeight={{ base: '65px', md: '86px' }}
            textAlign={{ base: 'center', md: 'left' }}
          >
            Let's setup your <br />
            <b style={{ fontWeight: 500 }}>Storefront</b>
          </Text>
          <Flex justifyContent={{ base: 'center', md: 'flex-start' }}>
            <Button
              as={Link}
              background="blueGradient"
              borderRadius="32px"
              fontWeight={600}
              to="seller-preferences"
              onClick={() => {
                if (!listing?.storefrontSetup?.continueStep) {
                  void updateListingSetup({
                    variables: {
                      input: {
                        listingId: listing?.id,
                        continueStep: StorefrontSetupStep.SELLER_PREFERENCES,
                      },
                    },
                  });
                }
              }}
            >
              Get Started
            </Button>
          </Flex>
        </Flex>
        <Flex maxWidth={{ base: '100%', md: '50%' }}>
          <Image src={storefrontImage} />
        </Flex>
      </Center>
    </Box>
  );
};
