import { Icon, IconProps } from '@chakra-ui/react';
import { FC, memo } from 'react';

export const HomeIcon: FC<IconProps> = memo(function HomeIcon(props) {
  return (
    <Icon
      fill="none"
      height="20px"
      viewBox="0 0 22 20"
      width="22px"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.46875 18.1689V11.8232C8.46875 11.6182 8.53027 11.4541 8.65332 11.3311C8.78223 11.208 8.94922 11.1465 9.1543 11.1465H12.8457C13.0508 11.1465 13.2148 11.208 13.3379 11.3311C13.4609 11.4541 13.5225 11.6182 13.5225 11.8232V18.1689H8.46875ZM5.03223 19.3115C4.36426 19.3115 3.84277 19.1299 3.46777 18.7666C3.09277 18.4033 2.90527 17.8994 2.90527 17.2549V7.50781L4.63672 6.33008V16.7188C4.63672 17 4.70996 17.2139 4.85645 17.3604C5.00293 17.5127 5.21387 17.5889 5.48926 17.5889H16.4668C16.7422 17.5889 16.9502 17.5127 17.0908 17.3604C17.2373 17.2139 17.3105 17 17.3105 16.7188V6.33008L19.0332 7.50781V17.2549C19.0332 17.8994 18.8486 18.4033 18.4795 18.7666C18.1104 19.1299 17.5918 19.3115 16.9238 19.3115H5.03223ZM0.268555 9.38867C0.268555 9.11328 0.382812 8.88184 0.611328 8.69434L9.76953 1.00391C10.1504 0.6875 10.5635 0.529297 11.0088 0.529297C11.4541 0.529297 11.8643 0.6875 12.2393 1.00391L21.3887 8.69434C21.6172 8.88184 21.7314 9.12207 21.7314 9.41504C21.7314 9.66699 21.6436 9.86621 21.4678 10.0127C21.2979 10.1592 21.0869 10.2324 20.835 10.2324C20.7002 10.2324 20.5713 10.2002 20.4482 10.1357C20.3252 10.0713 20.2109 9.99805 20.1055 9.91602L11.3867 2.59473C11.2637 2.48926 11.1377 2.43652 11.0088 2.43652C10.8799 2.43652 10.7539 2.48926 10.6309 2.59473L1.90332 9.91602C1.79199 9.99805 1.6748 10.0713 1.55176 10.1357C1.43457 10.2002 1.30859 10.2324 1.17383 10.2324C0.892578 10.2324 0.669922 10.1504 0.505859 9.98633C0.347656 9.81641 0.268555 9.61719 0.268555 9.38867ZM16.5459 5.12598V2.97266C16.5459 2.7793 16.6045 2.62402 16.7217 2.50684C16.8447 2.38379 17.0029 2.32227 17.1963 2.32227H18.3916C18.585 2.32227 18.7402 2.38379 18.8574 2.50684C18.9746 2.62402 19.0332 2.7793 19.0332 2.97266V7.21777L16.5459 5.12598Z"
        fill="currentColor"
      />
    </Icon>
  );
});
