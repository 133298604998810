import { Flex, FlexProps } from '@chakra-ui/react';
import { FC, ReactNode } from 'react';

interface MainDashboardWidgetsSectionProps extends FlexProps {
  children: ReactNode;
}

export const MainDashboardWidgetsSection: FC<
  MainDashboardWidgetsSectionProps
> = ({ children, ...rest }) => {
  return (
    <Flex
      flexDirection={{ base: 'column', md: 'row' }}
      gap={4}
      justifyContent="space-evenly"
      mt={{
        base: 2,
        lg: 8,
      }}
      width="100%"
      {...rest}
    >
      {children}
    </Flex>
  );
};
