/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { GroupBase, useChakraSelectProps } from 'chakra-react-select';

import { useFormControlContext } from '@chakra-ui/react';
import React from 'react';
import ReactSelect, { Props, SelectInstance } from 'react-select';
import ReactAsyncSelect, { AsyncProps } from 'react-select/async';
import ReactCreatableSelect, { CreatableProps } from 'react-select/creatable';
import './select.css';

export type SelectProps<
  Option = unknown,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
> = Props<Option, IsMulti, Group>;

export type AsyncSelectProps<
  Option = unknown,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
> = AsyncProps<Option, IsMulti, Group>;

export type CreatableSelectProps<
  Option = unknown,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
> = CreatableProps<Option, IsMulti, Group>;

export const chakraStyles = {
  container: (provided: any) => ({
    ...provided,
    minWidth: 50,
    borderRadius: 'sm',
  }),
  control: (provided: any) => ({
    height: 12,
    ...provided,
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    bg: 'transparent',
    pl: 0,
    pr: 2,
    cursor: 'inherit',
  }),
  indicatorSeparator: (provided: any) => ({
    ...provided,
    display: 'none',
  }),
  menu: (provided: any) => ({
    ...provided,
    zIndex: 3,
  }),
  menuList: (provided: any) => ({
    ...provided,
    minWidth: 'auto',
    borderRadius: 'sm',
    _dark: {
      bg: 'bg.mainDark',
    },
  }),
  option: (provided: any, state: { isSelected: any; isFocused: any }) => ({
    ...provided,
    bg: () => {
      if (!state.isSelected && state.isFocused) {
        return 'gray.200';
      }

      return state.isSelected ? 'primary.500' : 'transparent';
    },
    _dark: {
      color: () => {
        if (state.isSelected) {
          return 'black';
        }

        return 'whiteAlpha.700';
      },
      bg: () => {
        if (!state.isSelected && state.isFocused) {
          return 'whiteAlpha.100';
        }

        return state.isSelected ? 'primary.500' : 'transparent';
      },
    },
  }),
};

export const Select = <
  Option = unknown,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  selectRef,
  ...props
}: SelectProps<Option, IsMulti, Group> & {
  selectRef?: React.Ref<SelectInstance<Option, IsMulti, Group>>;
}) => {
  const { inputId, ...chakraProps } = useChakraSelectProps<
    Option,
    IsMulti,
    Group
  >({
    chakraStyles,
    ...props,
  });
  const context = useFormControlContext();

  return (
    <ReactSelect
      ref={selectRef}
      classNamePrefix="select"
      inputId={inputId || context?.id}
      {...chakraProps}
    />
  );
};

export const AsyncSelect = <
  Option = unknown,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: AsyncSelectProps<Option, IsMulti, Group>
) => {
  const { inputId, ...chakraProps } = useChakraSelectProps<
    Option,
    IsMulti,
    Group
  >({
    chakraStyles,
    ...props,
  });

  const context = useFormControlContext();

  return (
    <ReactAsyncSelect
      classNamePrefix="async-select"
      inputId={inputId || context?.id}
      {...chakraProps}
    />
  );
};

export const CreatableSelect = <
  Option = unknown,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: CreatableSelectProps<Option, IsMulti, Group>
) => {
  const { inputId, ...chakraProps } = useChakraSelectProps<
    Option,
    IsMulti,
    Group
  >({
    chakraStyles,
    ...props,
  });

  const context = useFormControlContext();

  return (
    <ReactCreatableSelect
      classNamePrefix="createable-select"
      inputId={inputId || context?.id}
      {...chakraProps}
    />
  );
};
