import { Text } from '@chakra-ui/react';
import {
  ActivityFragment,
  ActivityType,
} from '@client/graphql/__generated__/types';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import isNil from 'lodash/isNil';
import { OFFER_TRANSPARENCY_OPTIONS } from '../../pages/ListingSetup/Preferences/offer-transparency.constants';
import { OFFER_TRANSPARENCY } from '../../pages/ListingSetup/Preferences/offer-transparency.types';
import { getFormattedDateForPreferenceValue } from '../../utils/listing.utils';
import { ActivityItem } from './ActivityItem';

dayjs.extend(relativeTime);

const parseSellerPreferenceValue = (
  sellerPreferenceValue: string,
  prefSlug: string
) => {
  const dateVal = getFormattedDateForPreferenceValue(
    sellerPreferenceValue,
    prefSlug
  );
  if (dateVal) {
    return dateVal;
  }

  if (sellerPreferenceValue === OFFER_TRANSPARENCY.MULTIPLE) {
    return OFFER_TRANSPARENCY_OPTIONS.find(
      (el) => el.value === OFFER_TRANSPARENCY.MULTIPLE
    )?.activityLabel;
  } else if (sellerPreferenceValue === OFFER_TRANSPARENCY.COUNT) {
    return OFFER_TRANSPARENCY_OPTIONS.find(
      (el) => el.value === OFFER_TRANSPARENCY.COUNT
    )?.activityLabel;
  }

  return sellerPreferenceValue;
};

export const PreferenceUpdateActivity = ({
  listingActivity,
}: {
  listingActivity: ActivityFragment;
}) => {
  const metadata = listingActivity.metadata as {
    [ActivityType.PREFERENCE_UPDATE]: {
      sellerPreferenceName: string;
      sellerPreferenceValue: string;
      slug: string;
    };
  };
  const { sellerPreferenceName, slug, sellerPreferenceValue } =
    metadata?.[ActivityType.PREFERENCE_UPDATE] || {};

  if (
    slug === 'offer_count_display' &&
    sellerPreferenceValue === OFFER_TRANSPARENCY.OFF
  ) {
    return null;
  }

  const parsedSellerPreferenceValue = parseSellerPreferenceValue(
    sellerPreferenceValue,
    slug
  );

  const defaultMessage = (
    <Text as="span" color="gray.500">
      <Text as="span" color="white" fontWeight="medium">
        {listingActivity?.user?.fullName}
      </Text>{' '}
      updated the{' '}
      <Text as="span" color="white" fontWeight="medium">
        {sellerPreferenceName}
      </Text>{' '}
      to{' '}
      <Text as="span" color="white" fontWeight="medium">
        {parsedSellerPreferenceValue}
      </Text>
    </Text>
  );

  const unsetMessage = (
    <Text as="span" color="gray.500">
      <Text as="span" color="white" fontWeight="medium">
        {listingActivity?.user?.fullName}
      </Text>{' '}
      removed the{' '}
      <Text as="span" color="white" fontWeight="medium">
        {sellerPreferenceName}
      </Text>
    </Text>
  );

  return (
    <ActivityItem activity={listingActivity}>
      {isNil(parsedSellerPreferenceValue) || parsedSellerPreferenceValue === ''
        ? unsetMessage
        : defaultMessage}
    </ActivityItem>
  );
};
