import { IconTag } from '~/common/components/IconTag/IconTag';
import { IndigoLogoSmall } from '~/common/icons';

export const PremiumTag = () => {
  return (
    <IconTag
      color="white"
      display="inline-flex"
      fontSize="s"
      icon={IndigoLogoSmall}
      label="Premium"
      letterSpacing="normal"
      px={3}
      textTransform="none"
      variant="gradient"
    />
  );
};
