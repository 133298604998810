import { Flex, Link, Text } from '@chakra-ui/react';
import { MailIcon, TelephoneIcon } from '~/common/icons';
import { BaseComparableField } from '../../../Comparables/BaseComparableField';
import { COLUMN_WIDTH } from './constants';

export interface ListingAgentComparableFieldProps {
  name: string;
  company?: string;
  email?: string;
  phoneNumber?: string;
}

const iconSize = {
  width: '14px',
  height: '14px',
};

export const ListingAgentComparableField = ({
  name,
  company,
  email,
  phoneNumber,
}: ListingAgentComparableFieldProps) => {
  return (
    <BaseComparableField color="whiteAlpha.700" px={4} py={3}>
      <Flex direction="column" justifyContent="center" width={COLUMN_WIDTH}>
        <Text
          color="neutral.150"
          fontWeight="500"
          minWidth="0"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
        >
          {name}
        </Text>
        {company && (
          <Text
            fontSize="sm"
            minWidth="0"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
          >
            {company}
          </Text>
        )}
      </Flex>
      {(email || phoneNumber) && (
        <Flex alignItems="center" direction="row" gap={2}>
          {email && (
            <Link href={`mailto:${email ?? ''}`}>
              <MailIcon {...iconSize} />
            </Link>
          )}
          {phoneNumber && (
            <Link href={`tel:${phoneNumber ?? ''}`}>
              <TelephoneIcon {...iconSize} />
            </Link>
          )}
        </Flex>
      )}
    </BaseComparableField>
  );
};
