import {
  FieldObject,
  FieldType,
  InputFormatType,
  VariableSource,
} from '@client/graphql/__generated__/types';
import * as Sentry from '@sentry/react';
import { DocumentSectionWithChildren } from '../components/DocumentSections/types';
import { ValuesType } from '../components/FormFields/types';
import { formatNumber, formatToCurrency } from './number';

export function formatFieldValue<T>(field: Partial<FieldObject>, value: T) {
  if (field?.fieldType === FieldType.NUMBER) {
    if (field?.inputFormat?.type === InputFormatType.CURRENCY) {
      return formatToCurrency(value as string);
    }

    return formatNumber(
      value as string,
      field?.inputFormat?.options.precision || 2
    );
  }

  return value;
}

export function getFieldValuesForDocumentSection(
  section: DocumentSectionWithChildren | undefined,
  fieldValues: ValuesType
) {
  if (!section) {
    return {};
  }
  try {
    return (
      section?.documentSectionFields?.reduce((acc, field) => {
        if (field.field) {
          switch (field.field.fieldType) {
            case FieldType.SELECT:
              const mappingKeys = field.field.autofill?.formulaVariableFields
                ?.filter(
                  (variableField) =>
                    variableField.source === VariableSource.FIELD
                )
                ?.map((variableField) => variableField.key);

              mappingKeys?.forEach((key) => {
                acc[key] = fieldValues[key];
              });

              return acc;

            default:
              const mappingKey = field.field.mappingKey;

              return { ...acc, [mappingKey]: fieldValues[mappingKey] };
          }
        }

        return acc;
      }, {} as ValuesType) || ({} as ValuesType)
    );
  } catch (error) {
    Sentry.captureException(error);

    return {};
  }
}
