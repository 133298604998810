import { validatePhoneNumber } from '@common/utils/phoneNumber';

export const validateForm = <T extends object>(
  values: T,
  validatePhoneOnly: boolean = false
) => {
  const errors: {
    [key in keyof T]?: string;
  } = {};

  if ('phone' in values && typeof values.phone === 'string') {
    try {
      validatePhoneNumber(values.phone);
    } catch (error) {
      errors['phone' as keyof T] = 'Please enter a valid phone number';
    }

    if (validatePhoneOnly) {
      return errors;
    }
  }

  Object.keys(values).forEach((key) => {
    if (
      values[key as keyof T] === undefined ||
      values[key as keyof T] === null ||
      values[key as keyof T] === ''
    ) {
      errors[key as keyof T] = `${key} is required`;
    }
  });

  return errors;
};
