import {
  Box,
  Button,
  Flex,
  Portal,
  TabList,
  TabPanels,
  Tabs,
  VStack,
  useBreakpointValue,
} from '@chakra-ui/react';
import { useDeleteContractDocumentsMutation } from '@client/graphql/__generated__/document-operations';
import {
  ContractDocumentObject,
  DocumentVersionObject,
} from '@client/graphql/__generated__/types';
import { Select } from 'chakra-react-select';
import { FC, useEffect, useMemo, useState } from 'react';
import { useLocation, useOutletContext } from 'react-router';
import { useRegisteredRoles } from '~/apps/consumer/hooks/useRegisteredRoles';
import { useIsMobile } from '~/common/hooks/useIsMobile';
import { STOREFRONT_SIDEBAR_WIDTHS } from '~/common/theme/sizes';
import { useOfferFlowContext } from '../../../pages/Storefront/OfferFlow/OfferFlowContext';
import { ContractTab } from './ContractTab';
import { DocPreviewButton } from './DocPreviewButton';
import { PreviewTabContent } from './PreviewTabContent';

interface ContractPreviewProps {
  noPortal?: boolean;
}
export const ContractPreview: FC<ContractPreviewProps> = ({ noPortal }) => {
  const { isRegisteredAgent } = useRegisteredRoles();
  const outletContext = useOutletContext<{
    maxWidth: string;
    viewportHeight: number;
  }>();
  const maxWidth = outletContext?.maxWidth || '100%';
  const {
    activeDocument,
    activeDocumentVersion,
    updateFieldValues,
    latestOffer,
    setVisiblePageNumber,
    showContractPreview,
    refetchLatestOffer,
    contractPreviewDocIndex,
    setContractPreviewDocIndex,
    additionalDocs,
    contractDocsWithFiles,
    isListingAgentViewingOffer,
    allDocumentVersions,
    otherDisclosureContractDocs,
  } = useOfferFlowContext();
  const [deleteContractDocuments] = useDeleteContractDocumentsMutation();
  const [delayedShowPDF, setDelayedShowPDF] = useState(false);
  const [width, setWidth] = useState('0');
  const [isExpanded, setIsExpanded] = useState(false);
  const isMobile = useIsMobile();
  const sidebarWidth = useBreakpointValue(STOREFRONT_SIDEBAR_WIDTHS, {
    ssr: false,
  }) as string;

  const location = useLocation();
  const isUploaded =
    location.pathname.includes('uploaded') || latestOffer?.isUploaded;
  const disableFormFields =
    location.pathname.includes('review') || isListingAgentViewingOffer;
  const shouldShowContractPreview =
    activeDocumentVersion && latestOffer && showContractPreview;

  const signedMakeOfferContract =
    latestOffer?.contract?.signatureRequests?.find(
      (signatureRequest) => !!signatureRequest.allSignedDocument?.url
    )?.allSignedDocument;

  useEffect(() => {
    if (shouldShowContractPreview) {
      // could use another useEffect to trigger this but want to keep the logic in same place
      setWidth(`calc(${maxWidth} - ${sidebarWidth})`);
      // needed to prevent the PDF from being rendered before animation is done and causing issues
    } else {
      setWidth('0');
    }
  }, [maxWidth, shouldShowContractPreview, sidebarWidth]);

  useEffect(() => {
    // If contract doc preview index has not been set, set it to 0
    if (showContractPreview && typeof contractPreviewDocIndex !== 'number') {
      setContractPreviewDocIndex(0);
    }
  }, [
    showContractPreview,
    contractPreviewDocIndex,
    setContractPreviewDocIndex,
  ]);

  useEffect(() => {
    if (
      !isUploaded &&
      !signedMakeOfferContract &&
      additionalDocs?.length + (otherDisclosureContractDocs?.length || 0) <
        (contractPreviewDocIndex || 0)
    ) {
      setContractPreviewDocIndex(
        additionalDocs?.length + (otherDisclosureContractDocs?.length || 0)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    additionalDocs,
    otherDisclosureContractDocs,
    isUploaded,
    signedMakeOfferContract,
  ]);

  const signedMakeOfferContractDataSource = useMemo(() => {
    return {
      id: signedMakeOfferContract?.fileName,
      label: 'Signed contract package',
    };
  }, [signedMakeOfferContract]);

  const existingDocs = isUploaded
    ? contractDocsWithFiles
    : otherDisclosureContractDocs;

  const existingDocTabsDataSource = useMemo(
    () =>
      // show all docs if isUploaded, otherwise only show other disclosure docs since the normal ones will be displayed in the additionalDocs
      existingDocs?.map((doc) => ({
        id: doc.id,
        label:
          doc?.documentVersion?.document?.name ||
          doc?.externalDocumentName ||
          doc?.file?.fileName,
      })),
    [existingDocs]
  );

  const availableOptionsDataSource = useMemo(() => {
    if (isUploaded) {
      return existingDocTabsDataSource;
    }
    if (signedMakeOfferContract) {
      return [signedMakeOfferContractDataSource];
    }

    return [
      {
        id: activeDocument?.id,
        label: activeDocument?.name,
      },
      ...additionalDocs.map((doc) => ({
        id: doc.id,
        label: doc.document.name,
      })),
      ...existingDocTabsDataSource,
    ];
  }, [
    activeDocument,
    additionalDocs,
    existingDocTabsDataSource,
    isUploaded,
    signedMakeOfferContract,
    signedMakeOfferContractDataSource,
  ]);

  if (!activeDocumentVersion) {
    return null;
  }

  const signedMakeOfferContractTab = signedMakeOfferContract ? (
    <ContractTab
      key={signedMakeOfferContractDataSource.id}
      label={signedMakeOfferContractDataSource.label}
    />
  ) : null;

  const signedMakeOfferContractPreview = signedMakeOfferContract ? (
    <PreviewTabContent
      key={signedMakeOfferContractDataSource.id}
      contractDocument={{
        file: signedMakeOfferContract,
        id: '',
        order: 0,
      }}
      setVisiblePageNumber={setVisiblePageNumber}
      showPDF={isMobile || delayedShowPDF}
    />
  ) : null;

  const deleteExistingDoc = (
    contractDoc: ContractDocumentObject,
    docVersion?: DocumentVersionObject
  ) => {
    return () => {
      void (async () => {
        await deleteContractDocuments({
          variables: {
            input: {
              contractId: latestOffer?.contract?.id as string,
              contractDocuments: [
                {
                  contractDocumentUuid: contractDoc.id,
                  mappingKeys: docVersion?.mappingKeys,
                },
              ],
            },
          },
        });

        if (docVersion?.mappingKeys[0]) {
          updateFieldValues({
            [docVersion.mappingKeys[0]]: false,
          });
        }
        await refetchLatestOffer();
      })();
    };
  };

  const existingDocTabs = existingDocTabsDataSource?.map((doc, index) => {
    const docPreview = existingDocs[index];

    return (
      <ContractTab
        key={doc.id}
        label={doc.label}
        menuItems={
          isUploaded
            ? undefined
            : [
                {
                  label: 'Delete',
                  onClick: deleteExistingDoc(
                    docPreview as ContractDocumentObject,
                    docPreview.documentVersion as DocumentVersionObject
                  ),
                },
              ]
        }
      />
    );
  });

  const existingDocTabContent = existingDocs.map((doc) => (
    <PreviewTabContent
      key={doc.id}
      contractDocument={doc}
      contractId={latestOffer?.contract?.id}
      customFields={doc?.fields}
      disableFormFields={disableFormFields}
      isUploadedOffer={isUploaded}
      setVisiblePageNumber={setVisiblePageNumber}
      showAddTextBoxControls={!isUploaded}
      showPDF={isMobile || delayedShowPDF}
    />
  ));

  const contractPreviewContent =
    activeDocumentVersion && typeof contractPreviewDocIndex === 'number' ? (
      <Tabs
        isLazy
        borderTop={isMobile ? '1px solid' : undefined}
        borderTopColor="border.divider"
        bottom={isMobile ? '80px' : undefined}
        height={{
          base: showContractPreview
            ? isExpanded
              ? '70vh'
              : `${
                  (outletContext?.viewportHeight || window.innerHeight) * 0.3
                }px`
            : 0,
          md: 0,
        }}
        id="contract-preview-tabs"
        index={contractPreviewDocIndex}
        lazyBehavior="keepMounted"
        top={0}
        variant="document"
        width="100%"
        zIndex={10}
        onChange={(index) => {
          setContractPreviewDocIndex(index);
          window.requestAnimationFrame(() => {
            window.dispatchEvent(new Event('resize'));
          });
        }}
      >
        <Flex
          bgColor="bg.mainDark"
          display="flex"
          height={isMobile ? '100%' : '100vh'}
          overflow="hidden"
          position={isMobile ? 'relative' : 'fixed'}
          right={isMobile ? 0 : STOREFRONT_SIDEBAR_WIDTHS}
          top={0}
          transition="0.3s width ease-in-out"
          whiteSpace="nowrap"
          width={isMobile ? '100%' : width}
          zIndex={10}
          onTransitionEnd={() => {
            if (showContractPreview && !delayedShowPDF) {
              window.setTimeout(() => {
                setDelayedShowPDF(true);
              }, 500);
            }
          }}
        >
          <VStack spacing={0} width="100%">
            {!isMobile ? (
              <Flex justifyContent="space-between" pt={4} px={4} width="100%">
                <TabList borderColor="tab.border" mx={4} overflowX="auto">
                  {isUploaded
                    ? existingDocTabs
                    : signedMakeOfferContractTab || (
                        <>
                          <ContractTab label={activeDocument?.name || ''} />

                          {additionalDocs.map((doc) => {
                            const existingDoc = contractDocsWithFiles.find(
                              (contractDoc) =>
                                contractDoc.documentVersion?.id === doc.id
                            );

                            return (
                              <ContractTab
                                key={doc.id}
                                label={doc.document.name}
                                menuItems={[
                                  {
                                    label: 'Delete',
                                    onClick: existingDoc
                                      ? deleteExistingDoc(
                                          existingDoc as ContractDocumentObject,
                                          doc
                                        )
                                      : () => {
                                          updateFieldValues({
                                            [doc.mappingKeys[0]]: false,
                                          });
                                        },
                                  },
                                ]}
                              />
                            );
                          })}
                          {existingDocTabs}
                        </>
                      )}
                </TabList>
              </Flex>
            ) : (
              <Box pt={4} width="90%">
                <Select
                  chakraStyles={{
                    menuList: (base) => ({
                      ...base,
                      maxHeight: !isExpanded ? '180px' : undefined,
                    }),
                  }}
                  id="mobile-contract-preview-select"
                  menuPortalTarget={document.body}
                  options={availableOptionsDataSource.map((doc) => ({
                    label: doc.label,
                    value: doc.id,
                  }))}
                  styles={{
                    menuPortal: (base) => ({
                      ...base,
                      zIndex: 9999,
                    }),
                  }}
                  value={{
                    label:
                      availableOptionsDataSource[contractPreviewDocIndex]
                        ?.label,
                    value:
                      availableOptionsDataSource[contractPreviewDocIndex]?.id,
                  }}
                  onChange={(option) => {
                    if (option) {
                      const docIndex = availableOptionsDataSource.findIndex(
                        (doc) => doc.id === option.value
                      );
                      setContractPreviewDocIndex(docIndex);
                    }
                  }}
                />
              </Box>
            )}
            <TabPanels overflow="hidden" position="relative">
              {isUploaded
                ? existingDocTabContent
                : signedMakeOfferContractPreview
                  ? [signedMakeOfferContractPreview]
                  : [
                      ...allDocumentVersions.map((doc) => {
                        const contractDoc =
                          latestOffer?.contract?.contractDocuments?.find(
                            (contractDoc) =>
                              contractDoc.documentVersion?.document?.id ===
                              doc.document?.id
                          );

                        const hasUploadedContractDoc = !!contractDoc?.file?.url;

                        return (
                          <PreviewTabContent
                            key={contractDoc?.id || doc.id}
                            contractDocument={contractDoc}
                            contractId={latestOffer?.contract?.id}
                            customFields={contractDoc?.fields}
                            disableFormFields={disableFormFields}
                            documentVersion={
                              // only give the document version if not an uploaded contract doc or need to show the unauthed preview
                              !hasUploadedContractDoc || !isRegisteredAgent
                                ? doc
                                : undefined
                            }
                            setVisiblePageNumber={setVisiblePageNumber}
                            showAddTextBoxControls={!isUploaded}
                            showPDF={isMobile || delayedShowPDF}
                          />
                        );
                      }),
                      ...(existingDocTabContent || []),
                    ]}
            </TabPanels>
            {isMobile && showContractPreview && (
              <Button
                backgroundColor="black"
                bottom="85px"
                color="white"
                position="fixed"
                right="30px"
                size="xs"
                onClick={() => setIsExpanded((prev) => !prev)}
              >
                {isExpanded ? 'Collapse' : 'Expand'}
              </Button>
            )}
          </VStack>
        </Flex>
      </Tabs>
    ) : null;

  const contractPreview = noPortal ? (
    contractPreviewContent
  ) : (
    <Portal>{contractPreviewContent}</Portal>
  );

  if (isMobile) {
    return (
      <Flex align="flex-end" direction="column">
        <DocPreviewButton />
        {contractPreview}
      </Flex>
    );
  }

  return contractPreview;
};
