import { useToast } from '@chakra-ui/react';
import {
  usePreferencesQuery,
  useUpdateListingPreferenceMutation,
} from '@client/graphql/__generated__/main-operations';
import {
  ListingFragment,
  PreferenceObject,
  PreferenceValueType,
  UpdateListingPreferenceValueInput,
  UsState,
} from '@client/graphql/__generated__/types';
import { isNil } from 'lodash';
import { LISTING_SETUP_SIDEBAR_REFETCH_QUERIES } from '~/apps/consumer/components/Listings/ListingSetup/ListingSetupSidebar/ListingSetupSidebar';
import { useSellerPreferenceGroups } from '~/apps/consumer/hooks/useSellerPreferenceGroups';
import {
  GroupMetaType,
  PREFERENCE_GROUPS,
  PREFERENCE_VALUE_FIELD_MAP,
} from '../constants';

interface useSellerPreferencesProps {
  listing: ListingFragment;
  showToast?: boolean;
  skipNotifications?: boolean;
  preferenceGroupMeta?: GroupMetaType[];
}

export const useSellerPreferences = ({
  listing,
  showToast = true,
  skipNotifications = false,
  preferenceGroupMeta = PREFERENCE_GROUPS,
}: useSellerPreferencesProps) => {
  const toast = useToast();

  const [updateListingPreference, { loading: isUpdating }] =
    useUpdateListingPreferenceMutation({
      refetchQueries: LISTING_SETUP_SIDEBAR_REFETCH_QUERIES,
    });

  const {
    data: preferencesData,
    loading: loadingPreferences,
    refetch: refetchPreferences,
  } = usePreferencesQuery({
    variables: {
      input: {
        usState: listing?.mlsListing?.address?.state as UsState,
      },
    },
  });

  const preferences = preferencesData?.preferences as PreferenceObject[];

  const preferenceGroups = useSellerPreferenceGroups(
    listing,
    preferences,
    preferenceGroupMeta
  );

  const initialValues = preferenceGroups.reduce((acc, group) => {
    return {
      ...acc,
      ...group.defaultValues,
    };
  }, {});

  const onSubmit = async (values: { [key: string]: unknown }) => {
    const input: UpdateListingPreferenceValueInput = {
      listingId: listing?.id,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      preferenceValues: Object.entries(values).map(([slug, value]) => {
        const matchingPreference = preferencesData?.preferences?.find(
          (preference) => preference.slug === slug
        );
        const dataType =
          PREFERENCE_VALUE_FIELD_MAP[
            matchingPreference?.valueType as PreferenceValueType
          ];

        if (matchingPreference?.valueType === PreferenceValueType.NUMBER) {
          value = value === '' ? null : value; // convert empty input from empty string to null;
          value = !isNil(value) ? Number(value) : null;

          if (matchingPreference.slug.includes('percent')) {
            value = !isNil(value) ? (value as number) / 100 : null;
          }
        }
        if (matchingPreference?.valueType === PreferenceValueType.BOOLEAN) {
          // converts null values to false
          value = !!value;
        }

        return {
          preference: { id: matchingPreference?.id as string },
          [dataType]: value,
        };
      }),
      skipNotifications,
    };

    await updateListingPreference({
      variables: {
        input,
      },
    });

    await refetchPreferences();

    if (showToast) {
      toast({
        title: 'Published',
        status: 'success',
        duration: 2000,
      });
    }
  };

  return {
    initialValues,
    onSubmit,
    isUpdating,
    loadingPreferences,
    preferenceGroups,
  };
};
