import { Text } from '@chakra-ui/react';
import {
  ActivityFragment,
  ActivityType,
} from '@client/graphql/__generated__/types';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { IndigoLogoAvatar } from '~/common/icons/IndigoLogoAvatar';
import { OFFER_TRANSPARENCY } from '../../pages/ListingSetup/Preferences/offer-transparency.types';
import { ActivityItem } from './ActivityItem';

dayjs.extend(relativeTime);

export const OfferSubmittedActivity = ({
  listingActivity,
}: {
  listingActivity: ActivityFragment;
}) => {
  const metadata = listingActivity.metadata as {
    [ActivityType.OFFER_SUBMITTED]: {
      offerCount: number;
      offerTransparency: string;
    };
  };

  const { offerCount, offerTransparency } =
    metadata?.[ActivityType.OFFER_SUBMITTED] || {};

  let helperCopy;
  if (offerTransparency === OFFER_TRANSPARENCY.MULTIPLE && offerCount >= 2) {
    helperCopy = 'There are multiple offers on this home.';
  } else if (offerTransparency === OFFER_TRANSPARENCY.COUNT) {
    helperCopy = `This listing now has ${offerCount} live offers.`;
  }

  return (
    <ActivityItem
      activity={listingActivity}
      avatar={<IndigoLogoAvatar mr={3} />}
    >
      <Text as="span" color="white" fontWeight="medium">
        An offer was submitted.
      </Text>
      {helperCopy ? (
        <Text as="span" color="white" fontWeight="medium">
          {' '}
          {helperCopy}
        </Text>
      ) : null}
    </ActivityItem>
  );
};
