import {
  Box,
  Button,
  Flex,
  HStack,
  Icon,
  SkeletonCircle,
  SkeletonText,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { useFindMlsAgentsByPhoneNumberQuery } from '@client/graphql/__generated__/main-operations';
import {
  FindMlsAgentFragment,
  PublicUserWithLicensesFragment,
} from '@client/graphql/__generated__/types';
import { motion, MotionValue, useScroll } from 'framer-motion';
import { ChevronDown, ChevronUp, Mail, Pen, Phone } from 'lucide-react';
import { FC, useCallback, useEffect, useState } from 'react';
import { Navigate } from 'react-router';
import { useIsMobile } from '~/common/hooks/useIsMobile';
import { formatPhoneNumber } from '~/common/utils/formatter';
import { SIDEBAR_WIDTH_COLLAPSED } from '../../components/PageDetailLayout/SidebarItem';
import { UserAvatar } from '../../components/User/UserAvatar';

type AgentCardForPublicPageProps = {
  // NOTE: if it's undefined, it means that we still loading the data
  user: PublicUserWithLicensesFragment | undefined;
  isAuthenticated: boolean;
  onEdit?: () => void;
};

export const AgentCardForPublicPage: FC<AgentCardForPublicPageProps> = ({
  user,
  onEdit,
  isAuthenticated,
}) => {
  const isMobile = useIsMobile() ?? false;

  const { mlsAgentData, isLoading, error } = useMLSAgentData(user);

  /**
   * Scroll behavior related states and functions
   */
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { scrollY } = useScroll();
  useScrollEffect(user, scrollY, onOpen, onClose, isMobile);
  const scrollBackToTop = useCallback(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  /**
   * Error handling
   */
  if (error) return <Navigate to="/not-found" />;

  /**
   * Loading states
   */
  if (isLoading || !mlsAgentData || !user) return <LoadingSkeleton />;

  return (
    <>
      <ExpandedCard
        isMobile={isMobile}
        mlsAgentData={mlsAgentData}
        user={user}
        onEdit={onEdit}
      />
      {isOpen && (
        <AnimatedAgentCard onClick={scrollBackToTop}>
          <StickyHeader
            isAuthenticated={isAuthenticated}
            isMobile={isMobile}
            mlsAgentData={mlsAgentData}
            user={user}
          />
        </AnimatedAgentCard>
      )}
    </>
  );
};

// -------------------------------------------------------------------------------------------------
// -----------------------------------------MLS Agent Data Hook--------------------------------------
// -------------------------------------------------------------------------------------------------
const useMLSAgentData = (user: PublicUserWithLicensesFragment | undefined) => {
  const mlsOSNs = user?.licenses
    ?.map((license) => license.mls?.originatingSystemName)
    ?.filter((osn): osn is string => !!osn);

  const { data, loading, error } = useFindMlsAgentsByPhoneNumberQuery({
    variables: {
      input: {
        phoneNumber: user?.phoneNumber ?? '',
        mlsOSNs: mlsOSNs && mlsOSNs.length ? mlsOSNs : undefined,
      },
    },
    skip: !user?.phoneNumber,
  });

  return {
    mlsAgentData: data?.findMLSAgentsByPhoneNumber ?? [],
    isLoading: loading,
    error,
  };
};

// -------------------------------------------------------------------------------------------------
// -----------------------------------------Skeleton-------------------------------------------
// -------------------------------------------------------------------------------------------------
const LoadingSkeleton = () => (
  <Box
    bg="whiteAlpha.50"
    border="1px solid"
    borderColor="whiteAlpha.100"
    borderRadius="md"
    minH="300px"
    p={8}
    width="100%"
  >
    <HStack alignItems="start" spacing={8}>
      <SkeletonCircle size="24" />
      <SkeletonText
        mt={4}
        noOfLines={5}
        skeletonHeight={4}
        spacing={4}
        w="80%"
      />
    </HStack>
  </Box>
);

// -------------------------------------------------------------------------------------------------
// -----------------------------------------Info Card Related-----------------------------------
// -------------------------------------------------------------------------------------------------
const AgentHeaderInfo: FC<{
  user: PublicUserWithLicensesFragment;
  mlsAgentData: FindMlsAgentFragment[];
  isOpen: boolean;
  isMobile: boolean;
  onEdit?: () => void;
}> = ({ user, mlsAgentData, isOpen, isMobile, onEdit }) => {
  const { fullName } = user;
  const officeNames = [
    ...new Set(
      mlsAgentData.map((agent) => agent.office?.OfficeName).filter(Boolean)
    ),
  ].join(' • ');

  return (
    <HStack alignItems="start" pr={isMobile ? 0 : 4} w="100%">
      <Flex alignItems="flex-start" direction="column" flexGrow={1} gap={0}>
        <Text
          alignItems={officeNames ? 'flex-start' : 'center'}
          display="flex"
          fontSize={isOpen || isMobile ? '2xl' : '4xl'}
          fontWeight="semibold"
          height={officeNames ? 'auto' : '100%'}
        >
          {fullName}
        </Text>
        {!!officeNames && (
          <Text
            color="whiteAlpha.600"
            fontSize={isOpen ? 'md' : isMobile ? '2xl' : '4xl'}
          >
            {officeNames}
          </Text>
        )}
      </Flex>
      {onEdit && (
        <Button
          leftIcon={<Icon as={Pen} />}
          px={4}
          py={0}
          rounded="full"
          size="sm"
          onClick={onEdit}
        >
          Edit
        </Button>
      )}
    </HStack>
  );
};

const ContactInfo: FC<{
  email: string | undefined;
  phoneNumber: string | undefined;
}> = ({ email, phoneNumber }) => (
  <Flex
    alignItems="center"
    color="whiteAlpha.600"
    flexDirection="row"
    flexWrap="wrap"
    gap={4}
  >
    {email && (
      <Flex alignItems="center" gap={1}>
        <Icon as={Mail} />
        <Text>{email}</Text>
      </Flex>
    )}
    {phoneNumber && (
      <Flex alignItems="center" gap={1}>
        <Icon as={Phone} />
        <Text>{formatPhoneNumber(phoneNumber)}</Text>
      </Flex>
    )}
  </Flex>
);

const AgentBio: FC<{
  profileBio: string | undefined;
  isMobile: boolean;
}> = ({ profileBio, isMobile }) => {
  const [isBioExpanded, setIsBioExpanded] = useState(false);

  return (
    <Box color="whiteAlpha.600" mb={isMobile ? 0 : 2}>
      <Text
        color="whiteAlpha.600"
        noOfLines={isBioExpanded || !isMobile ? undefined : 3}
      >
        {profileBio}
      </Text>
      {isMobile && profileBio && profileBio.length > 100 && (
        <Button
          color="whiteAlpha.600"
          leftIcon={<Icon as={isBioExpanded ? ChevronUp : ChevronDown} />}
          mt={2}
          variant="link"
          onClick={() => setIsBioExpanded(!isBioExpanded)}
        >
          {isBioExpanded ? 'Show less' : 'Show more'}
        </Button>
      )}
    </Box>
  );
};

const AgentSubDetails: FC<{
  user: PublicUserWithLicensesFragment;
  isMobile: boolean;
}> = ({ isMobile, user }) => {
  const { email, phoneNumber, profileBio } = user;

  return (
    <VStack alignItems="flex-start" gap={4}>
      <ContactInfo email={email} phoneNumber={phoneNumber} />
      <AgentBio isMobile={isMobile} profileBio={profileBio} />
    </VStack>
  );
};

// -------------------------------------------------------------------------------------------------
// -----------------------------------------Scroll Effect----------------------------------------
// -------------------------------------------------------------------------------------------------

/**
 * This hook is used to open and close the agent card (make it sticky or not) when the user scrolls.
 * It also checks if the user has listings to open the card.
 */
const useScrollEffect = (
  user: PublicUserWithLicensesFragment | undefined,
  scrollY: MotionValue<number>,
  onOpen: () => void,
  onClose: () => void,
  isMobile: boolean
) => {
  useEffect(() => {
    if (user?.hasListings === true) {
      return scrollY.onChange(() => {
        if (scrollY.get() > (isMobile ? 300 : 200)) {
          onOpen();
        } else {
          onClose();
        }
      });
    }
  }, [scrollY, onOpen, onClose, isMobile, user?.hasListings]);
};

const AnimatedAgentCard: FC<{
  onClick?: () => void;
  children: React.ReactNode;
}> = ({ onClick, children }) => (
  <motion.div
    animate="sticky"
    initial={false}
    transition={{ duration: 0.5 }}
    variants={{
      sticky: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        height: '80px',
        zIndex: 2,
        background: 'var(--chakra-colors-chakra-body-bg)',
        cursor: 'pointer',
      },
    }}
    onClick={onClick}
  >
    {children}
  </motion.div>
);

type StickyHeaderProps = {
  user: PublicUserWithLicensesFragment;
  mlsAgentData: FindMlsAgentFragment[];
  isMobile: boolean;
  isAuthenticated: boolean;
};

const StickyHeader: FC<StickyHeaderProps> = ({
  user,
  mlsAgentData,
  isMobile,
  isAuthenticated,
}) => {
  return (
    <Flex justifyContent="center" w="full">
      <HStack
        alignItems="center"
        height="80px"
        maxWidth={isMobile ? '500px' : '1000px'}
        minWidth={isMobile ? '300px' : 'auto'}
        px={{
          base: 5,
          md: isAuthenticated && !isMobile ? SIDEBAR_WIDTH_COLLAPSED : 6,
        }}
        spacing={isMobile ? 4 : 8}
        w="100%"
      >
        <UserAvatar size="md" user={user} />
        <VStack alignItems="start" spacing={6}>
          <AgentHeaderInfo
            isMobile={!!isMobile}
            isOpen={true}
            mlsAgentData={mlsAgentData}
            user={user}
          />
        </VStack>
      </HStack>
    </Flex>
  );
};

type ExpandedCardProps = {
  user: PublicUserWithLicensesFragment;
  mlsAgentData: FindMlsAgentFragment[];
  isMobile: boolean;
  onEdit?: () => void;
};

const ExpandedCard: FC<ExpandedCardProps> = ({
  user,
  mlsAgentData,
  isMobile,
  onEdit,
}) => {
  const agentInfoContent = (
    <AgentHeaderInfo
      isMobile={isMobile}
      isOpen={false}
      mlsAgentData={mlsAgentData}
      user={user}
      onEdit={onEdit}
    />
  );

  return (
    <Box
      bg="whiteAlpha.50"
      border="1px solid"
      borderColor="whiteAlpha.100"
      borderRadius="md"
      minH={isMobile ? '100px' : '160px'}
      minW="320px"
      p={4}
      width="100%"
    >
      <Flex direction={isMobile ? 'column' : 'row'} gap={isMobile ? 4 : 8}>
        <Flex alignItems="start" gap={6}>
          <UserAvatar size={isMobile ? 'lg' : 'xl'} user={user} />
          {isMobile && agentInfoContent}
        </Flex>
        <Flex direction="column" flex={1} gap={6}>
          {!isMobile && agentInfoContent}
          <AgentSubDetails isMobile={isMobile} user={user} />
        </Flex>
      </Flex>
    </Box>
  );
};
