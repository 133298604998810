import { noop } from 'lodash';
import { createContext, createRef, MutableRefObject } from 'react';
import { KeyboardManager } from './hooks/useKeyboardManager';
import { DocumentsThumbnails } from './types';

export interface PDFScrollableContextValue {
  disableFormFields?: boolean;
  visiblePages?: number[];
  visibleDocumentIndex?: number;
  visiblePageNumber?: number;
  observer?: IntersectionObserver;
  scrollableRef?: HTMLDivElement | null;
  keyboardManager?: KeyboardManager;
  zoom: number;
  thumbnailPages?: number[];
  documentsThumbnailsRef: MutableRefObject<
    DocumentsThumbnails | null | undefined
  >;
  setDocumentsThumbnails?: (documentsThumbnails: DocumentsThumbnails) => void;
}

export const PDFScrollableContext = createContext<PDFScrollableContextValue>({
  zoom: 1,
  setDocumentsThumbnails: noop,
  documentsThumbnailsRef: createRef<DocumentsThumbnails>(),
});
