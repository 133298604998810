import '~/common/utils/sentry';

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { ApolloProvider } from '@apollo/client';
import { ErrorResponse } from '@apollo/client/link/error';
import {
  ChakraProvider,
  createStandaloneToast,
  extendTheme,
} from '@chakra-ui/react';
import { createApolloClient } from '@document/utils/apollo-client';
import { StytchProvider } from '@stytch/react';
import { StytchUIClient } from '@stytch/vanilla-js';
import { PostHogProvider } from 'posthog-js/react';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { Helmet, HelmetProvider } from 'react-helmet-async'; // Added HelmetProvider import
import { BrowserRouter } from 'react-router-dom';
import { colors, theme } from '~/common/theme';
import { setupE2ECoverage } from '~/common/utils/e2e';
import { AppContextProvider } from '~/services/main/contexts/AppContext';
import { Routes } from './Routes';
import { CustomTourProvider } from './contexts/CustomTourContext/CustomTourContext';

declare global {
  interface Window {
    ENVS: {
      baseRouteUrl: string;
      graphqlUri: string;
      stytchPublicToken: string;
      postHogPublicKey: string;
      postHogPublicHost: string;
      dropboxSignClientId: string;
      baseUrl: string;
      emailedOfferDomain: string;
      isCloud: boolean;
      version: string;
      environment: string;
      mapboxApiKey: string;
      switchedUser?: boolean;
    };
    __coverage__: Record<string, any>;
    __saveCoverage__: () => Promise<void>;
  }
}

setupE2ECoverage();

const stytch = new StytchUIClient(window.ENVS.stytchPublicToken, {
  cookieOptions: {
    availableToSubdomains: true,
  },
});

const postHogOptions = {
  api_host: window.ENVS.postHogPublicHost,
};

const bloomTheme = extendTheme(theme, {
  config: {
    initialColorMode: 'dark',
    useSystemColorMode: false,
  },
  styles: {
    global: {
      body: {
        overflowX: 'clip',
      },
    },
  },
  semanticTokens: {
    colors: {
      'chakra-body-text': { _light: 'gray.800', _dark: 'whiteAlpha.900' },
      'chakra-body-bg': { _light: 'white', _dark: 'indigo.900' },
      'chakra-inverse-text': { _light: 'white', _dark: 'gray.800' },
      'chakra-subtle-bg': { _light: 'gray.100', _dark: 'gray.700' },
      'chakra-subtle-text': { _light: 'gray.600', _dark: 'gray.400' },
    },
  },
  colors: {
    primary: colors.cyan,
    primaryButton: colors.skyButton,
  },
});
const { toast, ToastContainer } = createStandaloneToast({ theme: bloomTheme });
const apolloClientOptions = {
  uri: '/graphql',
  onError: (error: ErrorResponse) => {
    // NOTE: prevent duplicate toasts for the same error
    // [reference](https://v2.chakra-ui.com/docs/components/toast#preventing-duplicate-toast)
    if (error.networkError) {
      if (toast.isActive('network-error')) {
        return;
      }
      toast({
        id: 'network-error',
        status: 'error',
        isClosable: true,
        duration: 10000,
        description: error.networkError?.name,
      });
    }
    error?.graphQLErrors?.map((err) => {
      const errorId = JSON.stringify(err.path?.join('-'));
      if (toast.isActive(errorId)) {
        return;
      }
      toast({
        id: errorId,
        status: 'error',
        isClosable: true,
        duration: 10000,
        description: err.message,
      });
    });
  },
};
const apolloClient = createApolloClient({
  clientOptions: {
    ...apolloClientOptions,
  },
});

const DefaultTitle = () => (
  <Helmet>
    <title>Indigo | AI home buying and selling platform</title>
  </Helmet>
);

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);

root.render(
  <StrictMode>
    <ToastContainer />
    <HelmetProvider>
      <StytchProvider stytch={stytch}>
        <PostHogProvider
          apiKey={
            window.ENVS.environment === 'e2e'
              ? undefined
              : window.ENVS.postHogPublicKey
          }
          options={
            window.ENVS.environment === 'e2e' ? undefined : postHogOptions
          }
        >
          <ApolloProvider client={apolloClient}>
            <ChakraProvider theme={bloomTheme}>
              <AppContextProvider>
                <CustomTourProvider>
                  <BrowserRouter>
                    <DefaultTitle />
                    <Routes />
                  </BrowserRouter>
                </CustomTourProvider>
              </AppContextProvider>
            </ChakraProvider>
          </ApolloProvider>
        </PostHogProvider>
      </StytchProvider>
    </HelmetProvider>
  </StrictMode>
);
