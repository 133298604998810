import {
  Box,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tab,
  Text,
} from '@chakra-ui/react';
import { MoreHorizontal } from 'lucide-react';
import { forwardRef } from 'react';
import { useIsMobile } from '~/common/hooks/useIsMobile';

interface MenuItem {
  onClick: () => void;
  label: string;
}

interface ContractTabProps {
  label: string;
  menuItems?: MenuItem[];
}

export const ContractTab = forwardRef(
  ({ label, menuItems }: ContractTabProps, ref) => {
    const isMobile = useIsMobile();

    return (
      <Tab ref={ref} as={Box} cursor="pointer" p={isMobile ? 0 : 4}>
        <Text mr={menuItems?.length ? 4 : 0}>{label}</Text>
        {menuItems?.length && (
          <Menu>
            <MenuButton
              as={IconButton}
              color="inherit"
              icon={<MoreHorizontal />}
              minWidth="unset"
              variant="link"
            />
            <MenuList>
              {menuItems.map((item) => (
                <MenuItem key={item.label} onClick={item.onClick}>
                  {item.label}
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
        )}
      </Tab>
    );
  }
);
