import { Icon, Text, Tooltip } from '@chakra-ui/react';
import { NotepadText } from 'lucide-react';

export const OfferNoteTooltip = ({
  isOpen,
  onClose,
  onOpen,
  note,
}: {
  isOpen: boolean;
  onClose: () => void;
  onOpen: () => void;
  note: string;
}) => {
  return (
    <Tooltip
      hasArrow
      isOpen={isOpen}
      label={<Text whiteSpace="pre-line">{note}</Text>}
      placement="auto"
      variant="muted"
      onClose={onClose}
      onOpen={onOpen}
    >
      <Icon
        as={NotepadText}
        bg="purpleGradient"
        borderRadius="xs"
        color="white"
        minH="24px"
        minW="24px"
        p={1}
        onClick={(e) => {
          e.stopPropagation();
          isOpen ? onClose() : onOpen();
        }}
        onMouseEnter={onOpen}
        onMouseLeave={onClose}
      />
    </Tooltip>
  );
};
