import { HStack, Image, Text, VStack } from '@chakra-ui/react';
import {
  ListingFragment,
  PublicListingFragment,
} from '@client/graphql/__generated__/types';
import { FC, memo } from 'react';
import { getPropertySource } from '../../utils/listing.utils';

interface ListingHeaderProps {
  listing: PublicListingFragment | ListingFragment;
}

export const ListingHeader: FC<ListingHeaderProps> = memo(
  function ListingHeader({ listing }) {
    return (
      <HStack flexGrow={1} justifyContent="stretch" spacing={4}>
        <Image
          borderRadius="xs"
          height="44px"
          src={getPropertySource(listing.mlsListing?.photos[0])}
        />
        <VStack alignItems="stretch" flexGrow={1} spacing={0}>
          <Text fontSize="md">
            <strong>{listing.mlsListing?.address.full}</strong>
          </Text>
          <Text fontSize="md">
            {listing.mlsListing?.address.city},{' '}
            {listing.mlsListing?.address.state}{' '}
            {listing.mlsListing?.address.postalCode}
          </Text>
        </VStack>
      </HStack>
    );
  }
);
