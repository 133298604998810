import { Flex } from '@chakra-ui/react';
import { TourModalTitle } from '../../../Tour/TourModalTitle';
import { TourModalPageSubtitle } from '../../../Tour/styled';

export const IntroPage = () => {
  return (
    <Flex direction="column" flexGrow={1} gap={4} justifyContent="center">
      <TourModalTitle />
      <TourModalPageSubtitle fontSize="xl" maxWidth="320px">
        The home checkout platform where agents, buyers and sellers collaborate
      </TourModalPageSubtitle>
    </Flex>
  );
};
