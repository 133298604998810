import { FormLabel, FormLabelProps } from '@chakra-ui/react';
import { XIcon } from '@document/common/Icons/XIcon';
import { FC, memo } from 'react';
import { baseInputStyle } from './defaults';

interface CheckboxFieldProps extends Omit<FormLabelProps, 'onChange'> {
  checked?: boolean;
  group?: string;
  isDisabled?: boolean;
  tabIndex?: number;
  title?: string;
  onChange?: (checked: boolean) => void;
}

export const CheckboxFormField: FC<CheckboxFieldProps> = memo(
  function CheckboxFormField({
    checked,
    group,
    tabIndex,
    title,
    onChange,
    isDisabled,
    ...props
  }) {
    return (
      <FormLabel
        {...baseInputStyle}
        _focusWithin={{
          outline: '2px',
          outlineStyle: 'solid',
          outlineColor: 'primary.400',
        }}
        cursor="pointer"
        display="block"
        height="100%"
        m={0}
        padding={0}
        width="100%"
        {...(isDisabled ? { bg: 'transparent' } : {})}
        {...props}
      >
        {checked && <XIcon height="100%" width="100%" />}
        <input
          checked={checked}
          disabled={isDisabled}
          name={group}
          style={{ opacity: 0, width: 0, height: 0 }}
          tabIndex={tabIndex}
          title={title}
          type="checkbox"
          onChange={(e) => onChange && onChange(e.currentTarget.checked)}
        />
      </FormLabel>
    );
  }
);
