import { useSignUpAgentV2Mutation } from '@client/graphql/__generated__/main-operations';
import { UserFragment, UserObject } from '@client/graphql/__generated__/types';
import { SignUpForm } from '../SignUp/SignUpForm';

interface AgentSignUpFormProps {
  signupFormInstructions?: string;
  user?: UserFragment;
  onSignup: (user: UserObject) => Promise<void> | void;
}

export const AgentSignupForm = ({
  signupFormInstructions,
  user,
  onSignup,
}: AgentSignUpFormProps) => {
  const [signUpAgent, { loading }] = useSignUpAgentV2Mutation();

  return (
    <SignUpForm
      loading={loading}
      signupFormInstructions={signupFormInstructions}
      user={user}
      withMls={true}
      onSubmit={async (input) => {
        const { data } = await signUpAgent({
          variables: {
            input: {
              firstName: input.firstName || '',
              lastName: input.lastName || '',
              email: input.email || '',
              profilePicture: input.profilePicture,
              licenses: input.mlsAccounts.map((mlsAccount) => ({
                licenseNumber: mlsAccount.licenseNumber || '',
                states: [],
                mlsId: mlsAccount.mlsId || '',
              })),
            },
          },
        });

        if (data?.signUpAgentV2?.user) {
          await onSignup(data.signUpAgentV2.user as UserObject);
        }
      }}
    />
  );
};
