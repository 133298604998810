import { BoxProps, Button, Flex, Text, VStack } from '@chakra-ui/react';
import { StorefrontSubscriptionReason } from '@client/graphql/__generated__/types';
import { FC, useState } from 'react';
import { PenToolIcon } from '~/common/icons';
import { enumToLabel } from '~/services/document/utils/enums';
import { useStorefrontContext } from '../../pages/Storefront/StorefrontContext';
import { VerifyUserModalWithSignGuestbook } from './VerifyUserModalWithSignGuestbook';

type SignGuestbookOverlayProps = BoxProps & {
  reason: StorefrontSubscriptionReason;
  blurPx?: number;
  onSign?: () => void;
};

export const SignGuestbookOverlay: FC<SignGuestbookOverlayProps> = ({
  reason,
  blurPx = 10,
  onSign,
  ...props
}) => {
  const [showVerifyUserModal, setShowVerifyUserModal] = useState(false);
  const { isGatedBehindSigningGuestbook } = useStorefrontContext();
  const { ...otherProps } = props;

  return (
    <Flex
      backdropFilter={`blur(${blurPx}px)`}
      bg="rgba(0,0,0,0.5)"
      border="1px"
      borderColor="whiteAlpha.100"
      bottom={0}
      data-testid="sign-guestbook-overlay"
      display={isGatedBehindSigningGuestbook ? 'flex' : 'none'}
      flexDirection="column"
      height="100%"
      justifyContent="start"
      left={0}
      p={6}
      position="absolute"
      right={0}
      top={0}
      transition="opacity 0.3s"
      zIndex={1}
      {...otherProps}
    >
      <VStack alignItems="center" justifyContent="center" textAlign="center">
        <PenToolIcon />
        <Text color="white" fontWeight="medium">
          Sign the guestbook to view
        </Text>
        <Text color="whiteAlpha.700" textAlign="center">
          To {enumToLabel(reason, false)} is completely free, but the listing
          agent has requested you sign the guestbook first.
        </Text>
        <VerifyUserModalWithSignGuestbook
          setShowVerifyUserModal={setShowVerifyUserModal}
          showVerifyUserModal={showVerifyUserModal}
          signReason={reason}
          onSign={onSign}
        >
          {(handleClickChildren) => (
            <Button onClick={handleClickChildren}>Sign</Button>
          )}
        </VerifyUserModalWithSignGuestbook>
      </VStack>
    </Flex>
  );
};
