import {
  FieldObject,
  FieldType,
  InputFormatType,
} from '@client/graphql/__generated__/types';
import dayjs from 'dayjs';
import {
  formatNumber,
  formatToCurrency,
} from '~/services/document/utils/number';

interface UseFormatFieldValueOptions {
  field?: Pick<Partial<FieldObject>, 'inputFormat' | 'fieldType'>;
  value?: string | readonly string[] | number | boolean | null;
}

export const useFormatFieldValue = ({
  field,
  value,
}: UseFormatFieldValueOptions) => {
  if (!value) return value;

  switch (field?.fieldType) {
    // Number format
    case FieldType.NUMBER:
      const precision = field?.inputFormat?.options?.precision || 0;

      if (field?.inputFormat?.type === InputFormatType.CURRENCY) {
        return formatToCurrency(Number(value), precision);
      }

      return formatNumber(Number(value), precision);

    // Date format
    case FieldType.DATE:
      const date = dayjs(value as string);
      const dateFormat =
        field?.inputFormat?.options?.formatString || 'MMM DD, YYYY';

      if (date.isValid()) {
        return date.format(dateFormat);
      }
  }

  return value;
};
