import {
  Box,
  Button,
  ButtonProps,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { useCreateOrUpdateListingAnnouncementMutation } from '@client/graphql/__generated__/main-operations';
import { ListingAnnouncementType } from '@client/graphql/__generated__/types';
import { Form, Formik } from 'formik';
import { Megaphone } from 'lucide-react';
import { ListingActivityProvider } from '~/apps/consumer/components/Activity/ListingActivityProvider';
import { DatePickerField } from '~/apps/consumer/components/Formik/DatePickerField';
import { SingleSelectWithDescriptionField } from '~/apps/consumer/components/Formik/SingleSelectWithDescriptionField';
import { useListingActivityContext } from '~/apps/consumer/contexts/ListingActivityContext';
import { useProdFeatureFlagEnabled } from '~/common/hooks/useProdFeatureFlagEnabled';
import { useListingSetupContext } from '../ListingSetupContext';

const defaultChildren = <Text>Post Announcement</Text>;

type Props = {
  children?: React.ReactNode;
} & Omit<ButtonProps, 'onClick'>;

export const AddAnnouncementButton = ({
  children = defaultChildren,
  ...buttonProps
}: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button
        size="sm"
        onClick={onOpen}
        {...buttonProps}
        leftIcon={<Icon as={Megaphone} />}
      >
        {children}
      </Button>
      <ListingActivityProvider>
        <AddAnnouncementModal isOpen={isOpen} onClose={onClose} />
      </ListingActivityProvider>
    </>
  );
};

// ------------------------------
// Modal Component (private)
// ------------------------------
const OPTION_TYPES: {
  value: ListingAnnouncementType;
  label: string;
  description: string;
}[] = [
  {
    value: ListingAnnouncementType.GENERAL,
    label: 'General announcement',
    description: 'Upcoming open house, listing changes, etc',
  },
  {
    value: ListingAnnouncementType.BEST_AND_FINAL,
    label: 'Best and final',
    description: 'Announce your best and final date',
  },
];

type AddAnnouncementFormValues = {
  type: ListingAnnouncementType;
  message: string;
  bestAndFinalDate?: Date;
};

interface AddAnnouncementModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const AddAnnouncementModal = ({
  isOpen,
  onClose,
}: AddAnnouncementModalProps) => {
  const isBestAndFinalEnabled = useProdFeatureFlagEnabled(
    'best-and-final-announcement'
  );

  const { listing } = useListingSetupContext();
  const { followerCount } = useListingActivityContext();

  const [createOrUpdateListingAnnouncement, { loading }] =
    useCreateOrUpdateListingAnnouncementMutation();

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          fontSize="sm"
          fontWeight="medium"
          textTransform="uppercase"
        >
          Add Announcement
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Formik<AddAnnouncementFormValues>
            initialValues={{
              type: ListingAnnouncementType.GENERAL,
              message: '',
            }}
            validate={(values) => {
              const errors: { message?: string } = {};
              if (!values.type) {
                errors.message = 'Type is required';
              }
              if (
                values.type === ListingAnnouncementType.BEST_AND_FINAL &&
                !values.bestAndFinalDate
              ) {
                errors.message = 'Best and final date is required';
              }
              if (
                !values.message &&
                values.type === ListingAnnouncementType.GENERAL
              ) {
                errors.message = 'Message is required';
              }

              return errors;
            }}
            onSubmit={(values) => {
              void createOrUpdateListingAnnouncement({
                variables: {
                  input: {
                    ...values,
                    listingId: listing.id,
                  },
                },
              }).then((resp) => {
                if (resp.data?.createOrUpdateListingAnnouncement) {
                  onClose();
                }
              });
            }}
          >
            {({ values, setFieldValue, isValid }) => {
              const isGeneral = values.type === ListingAnnouncementType.GENERAL;

              return (
                <VStack as={Form} direction="column" gap={6}>
                  <FormControl isRequired>
                    <FormLabel>Type</FormLabel>
                    <SingleSelectWithDescriptionField
                      name="type"
                      options={
                        isBestAndFinalEnabled
                          ? OPTION_TYPES
                          : OPTION_TYPES.filter(
                              (option) =>
                                option.value !==
                                ListingAnnouncementType.BEST_AND_FINAL
                            )
                      }
                      onChange={(option) => {
                        void setFieldValue('type', option?.value);
                      }}
                    />
                  </FormControl>
                  {values.type === ListingAnnouncementType.BEST_AND_FINAL && (
                    <FormControl isRequired>
                      <FormLabel>Best and final date</FormLabel>
                      <DatePickerField
                        name="bestAndFinalDate"
                        onChange={(date) => {
                          void setFieldValue('bestAndFinalDate', date);
                        }}
                      />
                    </FormControl>
                  )}
                  <FormControl isRequired={isGeneral}>
                    <FormLabel>
                      {isGeneral ? 'Message' : 'Note (optional)'}
                    </FormLabel>
                    <Textarea
                      name="message"
                      rows={15}
                      value={values.message}
                      onChange={(e) => {
                        void setFieldValue('message', e.target.value);
                      }}
                    />
                  </FormControl>
                  <Flex
                    alignItems="center"
                    direction="row"
                    gap={2}
                    justifyContent="flex-end"
                    width="100%"
                  >
                    {followerCount > 0 && (
                      <Box color="whiteAlpha.600" flex={1} fontSize="sm">
                        {`${followerCount} people will be notified`}
                      </Box>
                    )}
                    <Button size="sm" variant="outline" onClick={onClose}>
                      Cancel
                    </Button>
                    <Button
                      isDisabled={!isValid}
                      isLoading={loading}
                      size="sm"
                      type="submit"
                    >
                      Post
                    </Button>
                  </Flex>
                </VStack>
              );
            }}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
