import { Button, Flex } from '@chakra-ui/react';
import { FileObject, FinancingType } from '@client/graphql/__generated__/types';
import { Plus } from 'lucide-react';
import { useState } from 'react';
import { Form } from '../../Formik/Form';
import { PreapprovalDropzone } from '../PreapprovalDropzone/PreapprovalDropzone';
import { BuyerFormValues } from '../types';
import { BuyerFormCard } from './BuyerFormCards/BuyerFormCard';
import { CoBuyerFormCard } from './BuyerFormCards/CoBuyerFormCard';
import { FinancialInfoFormCard } from './BuyerFormCards/FinancialInfoFormCard';

interface AddBuyerFormProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSubmit: (values: BuyerFormValues) => Promise<any>;
  preapprovalFile?: File | FileObject | undefined;
  setPreapprovalFile?: (file: File | undefined) => void;
  showFinancingInfo?: boolean;
}

export const ADD_BUYER_FORM_ID = 'add-buyer-form';

export const AddBuyerForm = ({
  onSubmit,
  preapprovalFile,
  setPreapprovalFile,
  showFinancingInfo = !!setPreapprovalFile,
}: AddBuyerFormProps) => {
  const [showCoBuyer, setShowCoBuyer] = useState(false);

  const handleSubmit = async (values: BuyerFormValues) => {
    await onSubmit(values);
    setShowCoBuyer(false);
  };

  return (
    <Form<BuyerFormValues> formId={ADD_BUYER_FORM_ID} onSubmit={handleSubmit}>
      {({ values, setFieldValue }) => (
        <Flex direction="column" gap={2}>
          {setPreapprovalFile && (
            <PreapprovalDropzone
              file={preapprovalFile}
              onExtract={async (extracted) => {
                if (extracted?.primary_buyer) {
                  await setFieldValue('buyerName', extracted?.primary_buyer);
                }
                if (extracted?.co_buyer) {
                  setShowCoBuyer(true);
                  await setFieldValue('coBuyerName', extracted?.co_buyer);
                }
                if (extracted?.loan_type) {
                  await setFieldValue(
                    'financingType',
                    extracted?.loan_type.toUpperCase() as FinancingType
                  );
                }
                if (extracted?.loan_amount) {
                  await setFieldValue('loanAmount', extracted.loan_amount);
                }
              }}
              onUpload={(file) => {
                setPreapprovalFile?.(file);
              }}
            />
          )}
          <BuyerFormCard isEntity={values.isEntity} />
          {showFinancingInfo && (
            <FinancialInfoFormCard
              showLoanAmount={values.financingType !== FinancingType.CASH}
            />
          )}

          {!showCoBuyer && (
            <Button
              leftIcon={<Plus />}
              variant="link"
              width="fit-content"
              onClick={() => setShowCoBuyer(true)}
            >
              Add co-buyer
            </Button>
          )}
          {showCoBuyer && (
            <CoBuyerFormCard
              onRemove={async () => {
                await setFieldValue('coBuyerName', undefined);
                await setFieldValue('coBuyerEmail', undefined);
                setShowCoBuyer(false);
              }}
            />
          )}
        </Flex>
      )}
    </Form>
  );
};
