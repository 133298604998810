import { ApolloQueryResult, FetchResult } from '@apollo/client';
import { useDisclosure, useToast } from '@chakra-ui/react';
import {
  useListingComparableSetQuery,
  useUpdateListingComparableSetMutation,
} from '@client/graphql/__generated__/main-operations';
import {
  Exact,
  ListingComparableSetInput,
  ListingComparableSetQuery,
  ListingFragment,
  MlsListingFragment,
  UpdateListingComparableSetMutation,
} from '@client/graphql/__generated__/types';
import isEqualWith from 'lodash/isEqualWith';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { LISTING_SETUP_SIDEBAR_REFETCH_QUERIES } from '~/apps/consumer/components/Listings/ListingSetup/ListingSetupSidebar/ListingSetupSidebar';
import { ComparableState } from './ComparablesPage';

interface ComparablesContextProps {
  mainMlsListing?: MlsListingFragment;
  comparables?: ComparableState[];
  setComparables?: React.Dispatch<React.SetStateAction<ComparableState[]>>;
  isEditMode?: boolean;
  setIsEditMode?: (isEditMode: boolean) => void;
  updateComparableSet?: (options: unknown) => Promise<
    FetchResult<
      UpdateListingComparableSetMutation,
      Record<string, any>, // eslint-disable-line @typescript-eslint/no-explicit-any
      Record<string, any> // eslint-disable-line @typescript-eslint/no-explicit-any
    >
  >;
  updating?: boolean;
  onOpenModal?: () => void;
  onCloseModal?: () => void;
  isModalOpen?: boolean;
  notes?: string;
  setNotes?: React.Dispatch<React.SetStateAction<string>>;
  refetchComparables?: (
    variables?:
      | Partial<
          Exact<{
            input: ListingComparableSetInput;
          }>
        >
      | undefined
  ) => Promise<ApolloQueryResult<ListingComparableSetQuery>>;
  loading?: boolean;
  onPublish?: () => void;
  isDirty?: boolean;
}

export const ComparablesContext = createContext<ComparablesContextProps>(
  {} as ComparablesContextProps
);

export const ComparablesProvider = ({
  children,
  listing,
  showToast = true,
}: {
  children: React.ReactNode;
  listing: ListingFragment;
  showToast?: boolean;
}) => {
  // const { listing } = useListingSetupContext();
  const [isEditMode, setIsEditMode] = useState(true);

  const {
    isOpen: isModalOpen,
    onClose: onCloseModal,
    onOpen: onOpenModal,
  } = useDisclosure();
  const [comparables, setComparables] = useState<ComparableState[]>([]);
  const [notes, setNotes] = useState<string>('');
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const toast = useToast();

  const mlsListing = listing?.mlsListing;

  const {
    data,
    loading,
    refetch: refetchComparables,
  } = useListingComparableSetQuery({
    variables: {
      input: {
        listingId: listing?.id,
      },
    },
  });

  const [updateComparableSet, { loading: updating }] =
    useUpdateListingComparableSetMutation({
      refetchQueries: LISTING_SETUP_SIDEBAR_REFETCH_QUERIES,
    });

  const originalOrderedComparables = useMemo(() => {
    if (data?.listingComparableSet?.listingComparables) {
      return data?.listingComparableSet?.listingComparables
        ?.toSorted((a, b) => a.order - b.order)
        ?.map((comp) => ({
          mlsListing: comp.mlsListing,
        }));
    }

    return [];
  }, [data]);
  useEffect(() => {
    if (!isDirty) {
      setComparables(originalOrderedComparables || []);
    }
  }, [isDirty, originalOrderedComparables]);

  const originalNotes = useMemo(
    () =>
      data?.listingComparableSet?.agentNotes || listing?.mlsListing?.remarks,
    [data?.listingComparableSet?.agentNotes, listing?.mlsListing?.remarks]
  );
  useEffect(() => {
    setNotes(originalNotes || '');
  }, [originalNotes]);

  const comparator = (a: ComparableState[], b: ComparableState[]) =>
    a.length === b.length &&
    a.every(
      (comparable, index) => comparable.mlsListing.id === b[index].mlsListing.id
    );

  useEffect(() => {
    if (
      notes !== originalNotes ||
      !isEqualWith(comparables, originalOrderedComparables, comparator)
    ) {
      setIsDirty(true);
    } else {
      setIsDirty(false);
    }
  }, [notes, originalNotes, originalOrderedComparables, comparables]);

  const onPublish = () => {
    void (async () => {
      updateComparableSet &&
        (await updateComparableSet({
          variables: {
            input: {
              listingId: listing?.id || '',
              comparables: comparables?.map((comp, index) => ({
                mlsListingId: comp.mlsListing.id,
                order: index + 1,
              })),
              agentNotes: notes,
            },
          },
        }));
      if (showToast) {
        toast({
          description: 'Successfully published your updates',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      }
      refetchComparables && (await refetchComparables());
    })();
  };

  return (
    <ComparablesContext.Provider
      value={{
        isDirty,
        loading,
        mainMlsListing: mlsListing,
        comparables,
        setComparables,
        isEditMode,
        setIsEditMode,
        updateComparableSet,
        updating,
        onOpenModal,
        onCloseModal,
        isModalOpen,
        notes,
        refetchComparables,
        onPublish,
        setNotes,
      }}
    >
      {children}
    </ComparablesContext.Provider>
  );
};

export const useComparablesContext = () => {
  return useContext(ComparablesContext);
};
