import { Icon, IconProps } from '@chakra-ui/react';
import { FC, memo } from 'react';

export const HomeHeartIcon: FC<IconProps> = memo(function HomeHeartIcon(props) {
  return (
    <Icon
      fill="none"
      height="20px"
      viewBox="0 0 22 20"
      width="22px"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2 5.99998L8 1.33331L14 5.99998V13.3333C14 13.6869 13.8595 14.0261 13.6095 14.2761C13.3594 14.5262 13.0203 14.6666 12.6667 14.6666H3.33333C2.97971 14.6666 2.64057 14.5262 2.39052 14.2761C2.14048 14.0261 2 13.6869 2 13.3333V5.99998Z M10.6673 8.46667C10.6673 7.66667 10.0007 7 9.20065 7C8.66732 7 8.26732 7.2 8.00065 7.6C7.73398 7.2 7.33398 7 6.80065 7C6.00065 7 5.33398 7.66667 5.33398 8.46667C5.33398 8.86667 5.53398 9.26667 5.80065 9.53333C6.66732 10.4 8.00065 11.6667 8.00065 11.6667C8.00065 11.6667 9.33398 10.4 10.2007 9.6C10.4673 9.33333 10.6673 8.93333 10.6673 8.46667Z"
        stroke="currentColor"
      />
    </Icon>
  );
});
