import { FormControl, FormLabel, Switch } from '@chakra-ui/react';
import { Field } from 'formik';
import { TextField } from '../Formik/TextField';

const REGULAR_BUYER_FIELDS = [
  {
    label: 'Buyer name',
    name: 'buyerName',
    isRequired: true,
  },
  {
    label: 'Buyer email',
    name: 'buyerEmail',
    isRequired: true,
  },
];
const ENTITY_BUYER_EXTRA_FIELDS = [
  {
    label: 'Entity buyer name',
    name: 'entityName',
    isRequired: true,
  },
  {
    label: 'Entity buyer title',
    name: 'entityTitle',
    isRequired: true,
  },
];

type BuyerFormFieldsProps = {
  isEntity: boolean;
};

export const BuyerFormFields = ({ isEntity }: BuyerFormFieldsProps) => {
  return (
    <>
      <Field name="isEntity">
        {({ field }) => {
          return (
            <FormControl alignItems="flex-start" display="flex" gap={2}>
              <FormLabel>Entity buyer?</FormLabel>
              <Switch {...field} defaultChecked={isEntity} size="sm" />
            </FormControl>
          );
        }}
      </Field>
      {[
        ...REGULAR_BUYER_FIELDS,
        ...(isEntity ? ENTITY_BUYER_EXTRA_FIELDS : []),
      ].map((field) => (
        <FormControl key={field.name} isRequired>
          <FormLabel>{field.label}</FormLabel>
          <TextField name={field.name} />
        </FormControl>
      ))}
    </>
  );
};
