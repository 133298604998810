import { Flex, Link, Text } from '@chakra-ui/react';
import {
  ActivityFragment,
  PublicUserObject,
} from '@client/graphql/__generated__/types';
import dayjs from 'dayjs';
import { NavLink } from 'react-router-dom';
import { UserAvatar } from '../User/UserAvatar';
import { DATE_MOBILE_OFFSET } from './activity.constants';

export const ActivityItem = ({
  activity,
  children,
  avatar,
  mb = 4,
}: {
  activity: ActivityFragment;
  children: React.ReactNode;
  avatar?: React.ReactNode;
  mb?: number;
}) => {
  return (
    <Flex flexDirection="column" mb={mb}>
      <Flex
        direction={{ base: 'column', md: 'row' }}
        justifyContent="space-between"
        mb={4}
        width="100%"
      >
        <Flex alignItems="center">
          {avatar ? (
            avatar
          ) : (
            <UserAvatar
              mr={3}
              size="sm"
              user={activity.user as PublicUserObject}
            />
          )}
          <Text color="gray.500">
            {children}
            {activity.listing && (
              <>
                {' '}
                for
                <Link
                  as={NavLink}
                  color="white"
                  to={`/storefront/${activity.listing.property.slug}`}
                >
                  {' '}
                  {activity?.listing?.mlsListing?.address?.full}
                </Link>
              </>
            )}
          </Text>
        </Flex>

        <Flex ml={{ base: DATE_MOBILE_OFFSET, md: 0 }}>
          <Text color="gray.500">
            {dayjs(activity.createdAt as Date).fromNow()}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};
