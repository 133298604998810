import {
  Modal,
  ModalContent,
  ModalOverlay,
  Spinner,
  Text,
} from '@chakra-ui/react';

interface OfferProcessingModalProps {
  isProcessingUploadedOffer: boolean;
}
export const OfferProcessingModal = ({
  isProcessingUploadedOffer,
}: OfferProcessingModalProps) => {
  return (
    <Modal
      isCentered
      closeOnOverlayClick={false}
      isOpen={isProcessingUploadedOffer}
      onClose={() => {}}
    >
      <ModalOverlay bg="blackAlpha.600" />
      <ModalContent
        alignItems="center"
        backgroundColor="transparent"
        boxShadow="none"
        display="flex"
        justifyContent="center"
      >
        <Text color="white" fontSize="xl" mb={4}>
          Processing your offer
        </Text>
        <Spinner
          color="white"
          emptyColor="gray.300"
          size="xl"
          speed="0.65s"
          thickness="4px"
        />
      </ModalContent>
    </Modal>
  );
};
