import { useToast } from '@chakra-ui/react';
import {
  useHasSignedListingGuestbookQuery,
  useIsFollowingListingQuery,
  useSignListingGuestbookMutation,
} from '@client/graphql/__generated__/main-operations';
import {
  StorefrontSubscriptionReason,
  UserObject,
} from '@client/graphql/__generated__/types';
import { useCallback } from 'react';
import { useAppContext } from '~/services/main/contexts/AppContext';
import { useStorefrontContext } from '../../pages/Storefront/StorefrontContext';
import { VerifyUserModal } from '../VerifyUser/VerifyUserModal';
import { markGuestbookSigned } from './helpers';

type Props = {
  headingText?: string;
  showVerifyUserModal: boolean;
  setShowVerifyUserModal: (show: boolean) => void;

  children?: (onClick?: () => void) => React.ReactNode;
  handleSignGuestbook?: (user?: UserObject) => Promise<void>;
  onSign?: () => void;
  signReason: StorefrontSubscriptionReason;
};

export const VerifyUserModalWithSignGuestbook = ({
  headingText,
  showVerifyUserModal,
  setShowVerifyUserModal,
  children,
  handleSignGuestbook: handleSignGuestbookProp,
  onSign,
  signReason,
}: Props) => {
  const toast = useToast();
  const { listing } = useStorefrontContext();
  const { isAuthenticated } = useAppContext();

  const { refetch: refetchIsFollowingListing } = useIsFollowingListingQuery({
    variables: {
      input: {
        id: listing?.id ?? '',
      },
    },
    skip: !listing?.id || !isAuthenticated,
  });

  const { refetch: refetchHasSignedListingGuestbook } =
    useHasSignedListingGuestbookQuery({
      variables: {
        input: {
          id: listing?.id ?? '',
        },
      },
      skip: !listing?.id || !isAuthenticated,
    });

  const [signListingGuestbookMutation] = useSignListingGuestbookMutation({
    onError: ({ message }) => {
      toast({
        description: message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    },
  });

  const handleSignGuestbook = useCallback(
    async (user?: UserObject) => {
      if (!listing?.id) {
        return;
      }

      if (handleSignGuestbookProp) {
        await handleSignGuestbookProp(user);
      } else {
        await signListingGuestbookMutation({
          variables: {
            listingId: listing.id,
            followListingReason: signReason,
          },
        });
      }

      void refetchHasSignedListingGuestbook();
      void refetchIsFollowingListing();

      markGuestbookSigned(listing.id);

      // trigger a storage event to trigger the popover in GetAlerts
      window.dispatchEvent(new Event('storage'));

      toast({
        title: 'Thanks for signing the guestbook!',
        status: 'success',
      });
      onSign?.();
    },
    [
      handleSignGuestbookProp,
      listing?.id,
      onSign,
      refetchHasSignedListingGuestbook,
      refetchIsFollowingListing,
      signListingGuestbookMutation,
      signReason,
      toast,
    ]
  );

  const handleClickChildren = () => {
    if (!isAuthenticated) {
      setShowVerifyUserModal(true);
    } else {
      void handleSignGuestbook();
    }
  };

  return (
    <>
      {children && children(handleClickChildren)}
      <VerifyUserModal
        headingText={headingText}
        setShowVerifyUserModal={setShowVerifyUserModal}
        showVerifyUserModal={showVerifyUserModal}
        onSignup={handleSignGuestbook}
      />
    </>
  );
};
