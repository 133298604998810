import { Icon, IconProps } from '@chakra-ui/react';

export const CheckboxIcon = (props: IconProps) => (
  <Icon
    color="primary.500"
    fill="none"
    height="16px"
    viewBox="0 0 17 16"
    width="17px"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      height="14"
      stroke="currentColor"
      strokeWidth="2"
      width="15"
      x="1"
      y="1"
    />
    <path
      d="M5.1106 8.62645L6.59236 10.4024C6.64856 10.4733 6.72401 10.5318 6.81241 10.5731C6.90081 10.6144 6.99962 10.6373 7.10061 10.6399C7.2016 10.6424 7.30185 10.6246 7.39302 10.5878C7.48418 10.551 7.56363 10.4964 7.62475 10.4284L12.3682 5.36011"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </Icon>
);
