import {
  Box,
  Card,
  Divider,
  Flex,
  Heading,
  Stack,
  useRadioGroup,
} from '@chakra-ui/react';
import { ListingFragment } from '@client/graphql/__generated__/types';
import { OfferCountCardRadio } from '~/apps/consumer/components/Preferences/OfferCountCard';
import { StyledFormInput } from '~/apps/consumer/components/StyledFormInput/StyledFormInput';
import { getFormattedDate } from '~/apps/consumer/utils/listing.utils';
import { DEFAULT_DATETIME_FORMAT } from '~/common/utils/formatter';
import { BEST_AND_FINAL_DATE_FIELD_PROPS } from './constants';
import {
  OFFER_TRANSPARENCY_FIELD,
  OFFER_TRANSPARENCY_OPTIONS,
} from './offer-transparency.constants';

interface OfferTransparencyPrefGroupProps {
  listing: ListingFragment;
  bestAndFinalDate: Date;
  setBestAndFinalDate: (value: Date) => void;
  setOfferTransparency: (value: string) => void;
  offerTransparencyDefaultValue: string;
}

export const OfferTransparencyPrefGroup = ({
  bestAndFinalDate,
  setBestAndFinalDate,
  setOfferTransparency,
  offerTransparencyDefaultValue,
  listing,
}: OfferTransparencyPrefGroupProps) => {
  const handleDateChange = (value: Date) => {
    setBestAndFinalDate(value);
  };

  const { getRadioProps, getRootProps } = useRadioGroup({
    defaultValue: offerTransparencyDefaultValue,
    name: OFFER_TRANSPARENCY_FIELD,
    onChange: (value) => {
      setOfferTransparency(value);
    },
  });

  return (
    <Card bg="whiteAlpha.25" gap={4} p={6}>
      <Flex
        alignItems="center"
        borderRadius="md"
        justifyContent="space-between"
      >
        <Heading size="smallUppercase">Offer transparency</Heading>
      </Flex>
      <Box mb={6} mt={3} opacity="0.6">
        Turning on offer transparency helps potential buyers and increases
        average offer amounts. This is how it appears in your Storefront.
      </Box>
      <Box overflowX={{ base: 'scroll', md: 'auto' }}>
        <Stack direction="row" spacing={4} {...getRootProps()}>
          {OFFER_TRANSPARENCY_OPTIONS.map((option) => {
            // Show real example for multiple if there are already offers.
            if (option.value === 'COUNT' && listing.submittedOffersCount) {
              option.exampleTitle = `${listing.submittedOffersCount} offer${
                listing.submittedOffersCount === 1 ? '' : 's'
              }`;
            }

            return (
              <OfferCountCardRadio
                {...getRadioProps({ value: option.value })}
                key={option.value}
                bestAndFinalDateStr={getFormattedDate(
                  bestAndFinalDate,
                  DEFAULT_DATETIME_FORMAT
                )}
                {...option}
              />
            );
          })}
        </Stack>
      </Box>

      <Divider />

      <Heading mt={6} size="smallUppercase">
        Best and Final Date
      </Heading>
      <Box mt={4}>
        <StyledFormInput
          hasPlaceholder={BEST_AND_FINAL_DATE_FIELD_PROPS.hasPlaceholder}
          hideLabel={BEST_AND_FINAL_DATE_FIELD_PROPS.hideLabel}
          inputProps={{
            ...BEST_AND_FINAL_DATE_FIELD_PROPS.inputProps,
            onDateChange: handleDateChange,
          }}
          label={BEST_AND_FINAL_DATE_FIELD_PROPS.name}
          labelProps={BEST_AND_FINAL_DATE_FIELD_PROPS.labelProps}
          type={BEST_AND_FINAL_DATE_FIELD_PROPS.inputType}
        />
      </Box>
    </Card>
  );
};
