import {
  Button,
  Card,
  CardBody,
  Flex,
  Heading,
  VStack,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { useAppContext } from '~/services/main/contexts/AppContext';

export const NotFoundPage = () => {
  const { appState } = useAppContext();

  return (
    <VStack
      alignItems="center"
      bgColor={appState.bgColor}
      height="100vh"
      justifyContent="center"
      width="100hv"
    >
      <Card w="340px">
        <CardBody>
          <Flex direction="column" gap={8}>
            <Heading as="h2" fontSize="lg">
              Sorry, we can't seem to find the page you're looking for.
            </Heading>
            <Button as={Link} to="/">
              Go Home
            </Button>
          </Flex>
        </CardBody>
      </Card>
    </VStack>
  );
};
