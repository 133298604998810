import { FieldType } from '@client/graphql/__generated__/types';
import { AbsoluteBox } from '@document/common/AbsoluteBox';
import { useDevice } from '@document/hooks/useDevice';
import { isEqual } from 'lodash';
import {
  FC,
  memo,
  MouseEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { OnClick } from 'react-moveable';
import Selecto from 'react-selecto';
import { FormField, ValuesType } from '../../FormFields/types';
import { Moveable } from '../../Moveable/Moveable';
import { PDFScrollableContext } from '../../PDFScrollable/context';
import { KeyboardManager } from '../../PDFScrollable/hooks/useKeyboardManager';
import { PageMetadata } from '../../PDFScrollable/types';
import {
  DocumentMapperContextValue,
  useDocumentMapperContext,
} from '../context';
import { MoveableFormField } from '../FormField/MoveableFormField';
import { MoveableTarget, MoveableTargetType } from '../types';
import { DropContainer } from './DropContainer';
import { useMoveableEvents } from './hooks/useMoveableEvents';

export interface FormFieldsListProps {
  dropContainerId?: string;
  page: PageMetadata;
  formFields: FormField[];
  disableFormFields?: boolean;
  isEditable?: boolean;
  showDelete?: boolean;
  values?: ValuesType;
  updateValues?: (values: ValuesType) => void;
}

export interface FormFieldsListWithContextProps extends FormFieldsListProps {
  zoom: number;
  keyboardManager?: KeyboardManager;
  isFormFieldSelected: boolean;
  moveableTargets: DocumentMapperContextValue['moveableTargets'];
  originalFormFieldsById: DocumentMapperContextValue['originalFormFieldsById'];
  moveableTargetsPage: DocumentMapperContextValue['moveableTargetsPage'];
  setMoveableTargetsPage: DocumentMapperContextValue['setMoveableTargetsPage'];
  formFieldsById: DocumentMapperContextValue['formFieldsById'];
  selectoRefs: DocumentMapperContextValue['selectoRefs'];
  onFormFieldChange: DocumentMapperContextValue['onFormFieldChange'];
  onFormFieldDelete?: DocumentMapperContextValue['onFormFieldDelete'];
  updateMoveableTargets: DocumentMapperContextValue['setMoveableTargets'];
  setSelectedFormFieldId: DocumentMapperContextValue['setSelectedFormFieldId'];
  pageMousePositionRef: DocumentMapperContextValue['pageMousePositionRef'];
}

export const FormFieldsListWithContext: FC<FormFieldsListWithContextProps> =
  memo(function FormFieldsList({
    dropContainerId,
    page,
    zoom,
    isEditable,
    showDelete,
    values,
    updateValues,
    disableFormFields,
    isFormFieldSelected,
    keyboardManager,
    formFields,
    formFieldsById,
    moveableTargetsPage,
    selectoRefs,
    onFormFieldChange,
    onFormFieldDelete,
    updateMoveableTargets,
    setSelectedFormFieldId,
    setMoveableTargetsPage,
    originalFormFieldsById,
    pageMousePositionRef,
    moveableTargets: moveableTargetsProp,
  }) {
    const { isTouch } = useDevice();
    const [moveableTargets, setMoveableTargets] = useState<MoveableTarget[]>(
      []
    );
    const [dragContainer, setDragContainer] = useState<HTMLDivElement | null>(
      null
    );
    const deselectAll = useCallback((targetsMoveable: MoveableTarget[]) => {
      targetsMoveable.forEach(({ formField }) => {
        const targets = document.querySelectorAll<HTMLDivElement>(
          `div[data-formfieldid="${formField?.id}"]`
        );

        if (targets.length > 0) {
          targets.forEach((target) => {
            target.style.transform = '';
          });
        }
      });
    }, []);
    const pageFormFieldListOnClick = useCallback(() => {
      if (isTouch) {
        deselectAll(moveableTargets);

        updateMoveableTargets([]);
        setMoveableTargets([]);
        setSelectedFormFieldId(undefined);
      } else {
        if (moveableTargets.length === 0 && isFormFieldSelected) {
          setSelectedFormFieldId(undefined);
        }
      }
    }, [
      isTouch,
      deselectAll,
      moveableTargets,
      updateMoveableTargets,
      setSelectedFormFieldId,
      isFormFieldSelected,
    ]);

    const moveableFormFieldOnClick = useCallback(
      (e: MouseEvent, formField: FormField, type: MoveableTargetType) => {
        e.stopPropagation();

        const targets = [
          {
            formField,
            page,
            type,
          },
        ];

        const selectoRef = selectoRefs?.current[formField.pageNumber];
        const selectedTargets = selectoRef?.getSelectedTargets();

        if (selectedTargets && selectedTargets.length > 0) {
          const currentSelected = selectedTargets.find(
            (target) => target === e.currentTarget
          );
          if (currentSelected) {
            return;
          }
        }

        if (e.currentTarget) {
          selectoRef?.setSelectedTargets([e.currentTarget as HTMLElement]);
        }
        setMoveableTargetsPage({
          pageNumber: page.pageNumber,
          documentIndex: page.documentIndex,
        });
        updateMoveableTargets(targets);
        setMoveableTargets(targets);
        setSelectedFormFieldId(formField.id);
      },
      [
        page,
        selectoRefs,
        setMoveableTargetsPage,
        updateMoveableTargets,
        setSelectedFormFieldId,
      ]
    );

    const { onMoveableChange, onMoveableSelect, onMoveableSelectEnd } =
      useMoveableEvents({
        formFieldsById,
        moveableTargets: moveableTargets,
        onFormFieldChange,
        page,
        setSelectedFormFieldId,
        setMoveableTargetsPage,
        updateMoveableTargets,
        setMoveableTargets,
      });

    const onMouseMove = useCallback(
      (e: MouseEvent) => {
        const { x, y, width, height } = e.currentTarget.getBoundingClientRect();

        pageMousePositionRef.current = {
          pageNumber: page.pageNumber,
          x: e.clientX - x,
          y: e.clientY - y,
          height,
          width,
        };
      },
      [page.pageNumber, pageMousePositionRef]
    );

    const onMoveableTargetClick = useCallback(
      (e: OnClick) => {
        const formFieldId = e.target.dataset.formfieldid;
        const formField = formFieldId ? formFieldsById[formFieldId] : undefined;
        if (isEditable) {
          if (formField?.fieldType === FieldType.CHECKBOX) {
            updateValues?.({
              [formField.mappingKey]: !values?.[formField.mappingKey],
            });
          } else if (formFieldId) {
            document.getElementById(formFieldId)?.focus();
          }
        }
        setSelectedFormFieldId(formFieldId);
      },
      [setSelectedFormFieldId, isEditable, updateValues, values, formFieldsById]
    );

    const onSelectoRef = useCallback(
      (selecto: Selecto) => {
        if (selectoRefs) {
          selectoRefs.current[page.pageNumber] = selecto;
        }
      },
      [page.pageNumber, selectoRefs]
    );
    const handleDelete =
      showDelete && onFormFieldDelete
        ? (deletedFormFields: FormField[], moveableTarget: MoveableTarget) => {
            const moveableTargets = moveableTarget
              ? [moveableTarget]
              : deletedFormFields.map(
                  (formField) =>
                    ({
                      formField,
                      page,
                      type: 'form-field',
                    }) as MoveableTarget
                );

            onFormFieldDelete(deletedFormFields);
            deselectAll(moveableTargets);
            updateMoveableTargets([]);
          }
        : undefined;

    useEffect(() => {
      if (
        moveableTargetsPage?.pageNumber !== page.pageNumber ||
        moveableTargetsPage.documentIndex !== page.documentIndex
      ) {
        if (moveableTargets.length) {
          setMoveableTargets([]);
        }
      }
    }, [
      moveableTargetsPage,
      page.documentIndex,
      page.pageNumber,
      moveableTargets.length,
    ]);

    useEffect(() => {
      window.setTimeout(() => {
        setMoveableTargets((prev) => {
          if (!isEqual(prev, moveableTargetsProp)) {
            deselectAll(prev);

            return moveableTargetsProp ?? [];
          }

          return prev;
        });
      }, 50);
    }, [moveableTargetsProp, deselectAll]);

    return (
      <AbsoluteBox
        ref={setDragContainer}
        className="page-form-field-list"
        data-document-index={page.documentIndex}
        data-page-number={page.pageNumber}
        onMouseMove={onMouseMove}
      >
        <DropContainer
          documentIndex={page.documentIndex}
          id={dropContainerId}
          pageNumber={page.pageNumber}
          onClick={pageFormFieldListOnClick}
        />
        {formFields.map((formField) => (
          <MoveableFormField
            key={formField.id}
            className="moveable-form-field"
            formField={formField}
            isDisabled={disableFormFields}
            isEditable={isEditable}
            moveableTarget={moveableTargets?.find(
              (target) => target.formField?.id === formField?.id
            )}
            originalFormField={originalFormFieldsById[formField.id]}
            pageHeight={page.height}
            pageWidth={page.width}
            sizeRatio={page.sizeRatio || 1}
            updateValues={updateValues}
            values={values}
            onClick={moveableFormFieldOnClick}
            onDelete={handleDelete}
          />
        ))}
        {!disableFormFields && (
          <Moveable
            boundHeight={page.height}
            boundWidth={page.width}
            dragContainer={dragContainer}
            isSelectoActive={!isTouch && !keyboardManager?.space}
            moveableTargets={moveableTargets}
            selectableTargets={['.moveable-form-field']}
            zoom={zoom}
            onChange={onMoveableChange}
            onMoveableTargetClick={onMoveableTargetClick}
            onSelect={onMoveableSelect}
            onSelectEnd={onMoveableSelectEnd}
            onSelectoRef={onSelectoRef}
          />
        )}
      </AbsoluteBox>
    );
  });

export const FormFieldsList: FC<FormFieldsListProps> = memo(
  function FormFieldsList(props) {
    const {
      formFieldsById,
      originalFormFieldsById,
      onFormFieldChange,
      onFormFieldDelete,
      moveableTargetsPage,
      moveableTargets,
      setMoveableTargets,
      setSelectedFormFieldId,
      setMoveableTargetsPage,
      selectoRefs,
      selectedFormField,
      pageMousePositionRef,
    } = useDocumentMapperContext();
    const { zoom, keyboardManager } = useContext(PDFScrollableContext);

    return (
      <FormFieldsListWithContext
        {...props}
        formFieldsById={formFieldsById}
        isFormFieldSelected={!!selectedFormField}
        keyboardManager={keyboardManager}
        moveableTargets={moveableTargets}
        moveableTargetsPage={moveableTargetsPage}
        originalFormFieldsById={originalFormFieldsById}
        pageMousePositionRef={pageMousePositionRef}
        selectoRefs={selectoRefs}
        setMoveableTargetsPage={setMoveableTargetsPage}
        setSelectedFormFieldId={setSelectedFormFieldId}
        updateMoveableTargets={setMoveableTargets}
        zoom={zoom}
        onFormFieldChange={onFormFieldChange}
        onFormFieldDelete={onFormFieldDelete}
      />
    );
  }
);
