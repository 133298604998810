/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { BoxProps } from '@chakra-ui/react';
import { FormField } from '@document/components/FormFields/FormField';
import { FormFieldContainer } from '@document/components/FormFields/FormFieldContainer';
import { RadioFormFieldGroup } from '@document/components/FormFields/RadioFormField';
import { FC, memo } from 'react';
import { useIsMobile } from '~/common/hooks/useIsMobile';
import { FormField as FormFieldType, ValuesType } from '../FormFields/types';
import { PageMetadata } from '../PDFScrollable/types';
import { useFormFields } from './hooks/useFormFields';

interface PageFormFieldsProps {
  formFields: FormFieldType[];
  isDisabled?: boolean;
  page: PageMetadata;
  values: ValuesType;
  containerProps?: BoxProps;
  activeMappingKey?: string;
  updateValues: (values: ValuesType) => void;
}

interface PageFormFieldProps {
  formField: FormFieldType;
  isDisabled?: boolean;
  isActive?: boolean;
  containerProps?: BoxProps;
  width: number;
  height: number;
  sizeRatio?: number;
  tabIndex: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
  updateValues: (values: ValuesType) => void;
}

const PageFormField: FC<PageFormFieldProps> = memo(function PageFormFields({
  formField,
  isActive,
  containerProps,
  tabIndex,
  value,
  updateValues,
  width,
  height,
  sizeRatio,
  isDisabled,
}) {
  const isMobile = useIsMobile();

  return (
    <>
      <FormFieldContainer
        borderRadius="xs"
        className={isActive ? 'scale-down-center' : ''}
        data-formfield-id={formField.id}
        data-mapping-key={formField.mappingKey || formField.field?.mappingKey}
        formField={formField}
        outline={isMobile ? '1px solid' : '2px solid'}
        outlineColor={isActive ? 'primary.500' : 'transparent'}
        pageHeight={height}
        pageWidth={width}
        transition="outline-color 0.3s"
        {...containerProps}
      >
        <FormField
          bgColor={isActive ? '#ffffff' : undefined}
          formField={formField}
          isDisabled={!!formField.readOnly || isDisabled}
          readOnly={formField.readOnly || isDisabled}
          sizeRatio={sizeRatio}
          tabIndex={tabIndex}
          value={value}
          onChange={updateValues}
        />
      </FormFieldContainer>
    </>
  );
});

export const PageFormFields: FC<PageFormFieldsProps> = memo(
  function PageFormFields({
    formFields,
    page,
    values,
    activeMappingKey,
    containerProps,
    updateValues,
    isDisabled,
  }) {
    const { radioFormFields, pageFormFields, tabIndices } = useFormFields({
      formFields,
      documentIndex: page.documentIndex,
      pageNumber: page.pageNumber,
    });

    return (
      <>
        {radioFormFields &&
          Object.keys(radioFormFields)?.map((group) => (
            <RadioFormFieldGroup
              key={group}
              formFields={radioFormFields[group]}
              isDisabled={isDisabled}
              pageHeight={page.height}
              pageWidth={page.width}
              tabIndices={tabIndices}
              values={radioFormFields[group].reduce((output, formField) => {
                output[formField.mappingKey] = values[formField.mappingKey];

                return output;
              }, {} as ValuesType)}
              onChange={updateValues}
            />
          ))}

        {pageFormFields?.map((formField) => (
          <PageFormField
            key={formField.id}
            containerProps={containerProps}
            formField={formField}
            height={page.height}
            isActive={formField.mappingKey === activeMappingKey}
            isDisabled={isDisabled}
            sizeRatio={page.sizeRatio}
            tabIndex={tabIndices[formField.id]}
            updateValues={updateValues}
            value={values[formField.mappingKey] ?? ''}
            width={page.width}
          />
        ))}
      </>
    );
  }
);
