import { Box, Center, Text, VStack } from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';
import { useIsMobile } from '~/common/hooks/useIsMobile';

export const OpenHouseGuestBookSignIndexPage = () => {
  const isMobile = useIsMobile();

  const indexBox = (key: string, label: string) => {
    return (
      <Box
        as={NavLink}
        bg="blueGradient"
        borderColor="transparent"
        h={isMobile ? '20vh' : '50%'}
        p="1px"
        rounded="sm"
        to={`./sign?form=${key}`}
        w="full"
      >
        <Center bg="indigo.800" h="full" rounded="sm" w="full">
          <Text bg="blueGradient" bgClip="text" fontSize="24px">
            {label}
          </Text>
        </Center>
      </Box>
    );
  };

  return (
    <VStack h="100%" spacing={4}>
      {indexBox('buyers', "I'm a Buyer")}
      {indexBox('agents', "I'm an Agent")}
    </VStack>
  );
};
