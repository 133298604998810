import { useParams } from 'react-router';
import { AccessControls } from '~/common/components/AccessControls/AccessControls';
import { ListingSetupContentWrapper } from '../ListingSetupWrapperComponents';

export const AdminsPage = () => {
  const { listingId } = useParams();

  return listingId ? (
    <ListingSetupContentWrapper>
      <AccessControls listingId={listingId} />
    </ListingSetupContentWrapper>
  ) : null;
};
