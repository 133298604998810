import { CloseIcon } from '@chakra-ui/icons';
import { Flex, HStack, IconButton, Image, Text } from '@chakra-ui/react';
import { MlsListingObject } from '@client/graphql/__generated__/types';
import { FC } from 'react';
import { DEFAULT_PROPERTY_IMAGE_URL } from '../../utils/listing.utils';

interface MLSListingRowProps {
  listing: MlsListingObject;
  onRemove: (listing: MlsListingObject) => void;
}

export const MLSListingRow: FC<MLSListingRowProps> = ({
  listing,
  onRemove,
}) => {
  return (
    <HStack
      _last={{ borderBottom: 'none' }}
      bgColor="whiteAlpha.50"
      borderBottom="1px solid"
      borderColor="whiteAlpha.100"
      flexGrow={1}
      px={2}
      py={2}
      width="100%"
    >
      <HStack flexGrow={1}>
        <Image
          fallbackSrc={DEFAULT_PROPERTY_IMAGE_URL}
          height="32px"
          objectFit="cover"
          objectPosition="center"
          src={listing.photos[0]}
          width="45px"
        />

        <Flex flexDir={{ base: 'column', xl: 'row' }} gap={{ base: 0, xl: 1 }}>
          <Text>{listing.address.full}</Text>
          <Text color="whiteAlpha.500">
            {listing.address.city} {listing.address.state}{' '}
            {listing.address.postalCode}
          </Text>
        </Flex>
      </HStack>

      <IconButton
        aria-label="Remove listing"
        color="whiteAlpha.400"
        colorScheme="gray"
        height="auto"
        icon={<CloseIcon height="8px" width="8px" />}
        minHeight="auto"
        py={2}
        size="xs"
        variant="ghost"
        onClick={() => onRemove(listing)}
      />
    </HStack>
  );
};
