import { Box, Divider, Text } from '@chakra-ui/react';
import { FC, memo } from 'react';

interface DividerWithTextProps {
  children: React.ReactNode;
}

export const DividerWithText: FC<DividerWithTextProps> = memo(
  function DividerWithText(props) {
    return (
      <Box position="relative" width="100%">
        <Box
          left="50%"
          position="absolute"
          px={2}
          top="50%"
          transform="translateX(-50%) translateY(-50%)"
        >
          <Text fontSize="sm" fontWeight="medium">
            {props.children}
          </Text>
        </Box>
        <Divider />
      </Box>
    );
  }
);
