import { useField } from 'formik';
import { Select, SelectProps } from '~/services/document/common/Select';

interface OptionType {
  label: string;
  value: string;
}

export interface MultiSelectFieldProps extends SelectProps<OptionType, true> {
  name: string;
  options: OptionType[];
}

export const MultiSelectField = ({
  name,
  options,
  ...rest
}: MultiSelectFieldProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, meta, helpers] = useField({
    name,
    type: 'select',
    multiple: true,
  });
  const value = (field.value || []) as Array<string>;

  return (
    <Select
      {...rest}
      options={options}
      value={options?.filter((option) => value.includes(option.value))}
      onChange={(values) =>
        void helpers.setValue(values.map((value) => value.value))
      }
    />
  );
};
