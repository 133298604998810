import { Roles, UserFragment } from '@client/graphql/__generated__/types';
import { FC } from 'react';
import { useSearchParams } from 'react-router-dom';
import { AgentSignupForm } from '../AgentSignUpFlow/AgentSignupForm';
import { ChooseUserType } from './ChooseUserType';
import { ConsumerSignUp } from './ConsumerSignUp';

interface SignUpProps {
  signupFormInstructions?: string;
  user?: UserFragment;
  onSignup: (user: UserFragment) => Promise<void> | void;
}

export const SignUp: FC<SignUpProps> = ({
  onSignup,
  user,
  signupFormInstructions,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const userType = searchParams.get('userType');

  if (!userType) {
    return (
      <ChooseUserType
        onSelect={(role) => {
          setSearchParams((prev) => {
            prev.set('userType', role);

            return prev;
          });
        }}
      />
    );
  }

  switch (searchParams.get('userType')) {
    case Roles.CONSUMER:
      return (
        <ConsumerSignUp
          signupFormInstructions={signupFormInstructions}
          user={user}
          onSignup={onSignup}
        />
      );
    case Roles.AGENT:
      return (
        <AgentSignupForm
          signupFormInstructions={signupFormInstructions}
          user={user}
          onSignup={onSignup}
        />
      );
    default:
      return null;
  }
};
