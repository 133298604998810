import { useDraggable } from '@dnd-kit/core';
import { PropsWithChildren } from 'react';
import { WidgetButton, WidgetButtonProps } from './WidgetButton';

export const DraggableWidgetButton = ({
  children,
  fieldType,
  ...rest
}: PropsWithChildren<WidgetButtonProps>) => {
  const { attributes, listeners, setNodeRef } = useDraggable({
    id: fieldType,
    data: {
      fieldType,
    },
  });

  return (
    <WidgetButton
      ref={setNodeRef}
      fieldType={fieldType}
      {...attributes}
      {...listeners}
      {...rest}
    >
      {children}
    </WidgetButton>
  );
};
