import {
  useFindMlsAgentsByPhoneNumberLazyQuery,
  useGetOrCreateAgentByPhoneNumberMutation,
  useGetOrCreateConsumerByPhoneNumberMutation,
  usePublicUserWithLicensesLazyQuery,
  useSignOpenHouseGuestbookMutation,
} from '@client/graphql/__generated__/main-operations';
import { MUTE_ERROR_TOAST_QUERY_OPTIONS } from '~/services/document/utils/apollo-client';

//!!MARK: All queries for signing the guestbook will mute the global error toast
export const useSignGuestbookQueries = () => {
  /**
   * Base user queries
   */
  const [getUser] = usePublicUserWithLicensesLazyQuery(
    MUTE_ERROR_TOAST_QUERY_OPTIONS
  );

  /**
   * Open house queries
   */
  const useSignGuestbookMutation = useSignOpenHouseGuestbookMutation(
    MUTE_ERROR_TOAST_QUERY_OPTIONS
  );

  /**
   * Consumer queries
   */
  const useGetOrCreateConsumerMutation =
    useGetOrCreateConsumerByPhoneNumberMutation(MUTE_ERROR_TOAST_QUERY_OPTIONS);

  /**
   * Agent queries
   */

  const [findAgentsByPhoneNumber] = useFindMlsAgentsByPhoneNumberLazyQuery(
    MUTE_ERROR_TOAST_QUERY_OPTIONS
  );
  const [getOrCreateAgent] = useGetOrCreateAgentByPhoneNumberMutation(
    MUTE_ERROR_TOAST_QUERY_OPTIONS
  );

  return {
    getUser,

    useSignGuestbookMutation,

    useGetOrCreateConsumerMutation,

    findAgentsByPhoneNumber,
    getOrCreateAgent,
  };
};
