import {
  useListingsForOrganizationQuery,
  useOrgListingAggDeltaByStatusQuery,
  useOrganizationListingAggregatesByStatusQuery,
} from '@client/graphql/__generated__/main-operations';
import {
  ListingAggDeltaByStatus,
  ListingCountsByStatus,
  ListingForOrganizationFragment,
  MlsListingStatus,
} from '@client/graphql/__generated__/types';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useInfiniteQuery } from '~/services/document/hooks';
import { useAppContext } from '~/services/main/contexts/AppContext';

interface ListingAggregateStatusMap {
  [key: string]: ListingCountsByStatus;
}

interface ListingAggregateDifferenceStatusMap {
  [key: string]: ListingAggDeltaByStatus;
}

interface OrganizationListingsContextValue {
  listingAggregateStatusMap: ListingAggregateStatusMap;
  aggregateDifferenceStatusMap: ListingAggregateDifferenceStatusMap;
  selectedStatus: MlsListingStatus;
  setSelectedStatus: React.Dispatch<React.SetStateAction<MlsListingStatus>>;
  listings: ListingForOrganizationFragment[];
  listingsLoading: boolean;
  listingsQueryRef: (ref: HTMLDivElement) => void;
}

export const OrganizationListingsContext =
  createContext<OrganizationListingsContextValue>(
    {} as OrganizationListingsContextValue
  );

export const OrganizationListingsProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [selectedStatus, setSelectedStatus] = useState<MlsListingStatus>(
    MlsListingStatus.Active
  );
  const [listings, setListings] = useState<ListingForOrganizationFragment[]>(
    []
  );
  const { selectedOrganization } = useAppContext();
  const { data: aggregateData } = useOrganizationListingAggregatesByStatusQuery(
    {
      variables: {
        organizationId: selectedOrganization?.id || '',
      },
      skip: !selectedOrganization?.id,
    }
  );

  const { data: aggregateDifferenceData } = useOrgListingAggDeltaByStatusQuery({
    variables: {
      input: {
        organizationId: selectedOrganization?.id || '',
      },
    },
    skip: !selectedOrganization?.id,
  });

  const [listingsQueryRef, { data: listingsData, loading: listingsLoading }] =
    useInfiniteQuery(useListingsForOrganizationQuery, {
      variables: {
        filters: {
          status:
            // Combine Pending and ActiveUnderContract "Pending" selected status
            selectedStatus === MlsListingStatus.Pending
              ? [selectedStatus, MlsListingStatus.ActiveUnderContract]
              : [selectedStatus],
          organizationId: selectedOrganization?.id || '',
        },
      },
      skip: !selectedOrganization?.id,
    });

  useEffect(() => {
    if (!listingsLoading) {
      setListings(listingsData?.listingsForOrganization?.results || []);
    }
  }, [listingsData, listingsLoading]);

  const listingAggregateStatusMap = useMemo(
    () =>
      (
        aggregateData?.organizationListingAggregatesByStatus.aggregates || []
      ).reduce((acc, curr) => {
        // combine totals for Pending and ActiveUnderContract
        if (
          curr.status === MlsListingStatus.ActiveUnderContract ||
          curr.status === MlsListingStatus.Pending
        ) {
          if (!acc[MlsListingStatus.Pending]) {
            acc[MlsListingStatus.Pending] = {
              ...curr,
              status: MlsListingStatus.Pending,
            };
          } else {
            acc[MlsListingStatus.Pending].count += curr.count;
            acc[MlsListingStatus.Pending].sumListPrice =
              (acc[MlsListingStatus.Pending].sumListPrice || 0) +
              (curr.sumListPrice || 0);
            acc[MlsListingStatus.Pending].sumClosePrice =
              (acc[MlsListingStatus.Pending].sumClosePrice || 0) +
              (curr.sumClosePrice || 0);
          }
        } else {
          acc[curr.status] = curr;
        }

        return acc;
      }, {} as ListingAggregateStatusMap),
    [aggregateData?.organizationListingAggregatesByStatus.aggregates]
  );

  const aggregateDifferenceStatusMap = useMemo(
    () =>
      (
        aggregateDifferenceData?.orgListingAggDeltaByStatus.differences || []
      ).reduce((acc, curr) => {
        // combine diffs for Pending and ActiveUnderContract
        if (
          curr.status === MlsListingStatus.ActiveUnderContract ||
          curr.status === MlsListingStatus.Pending
        ) {
          if (!acc[MlsListingStatus.Pending]) {
            acc[MlsListingStatus.Pending] = {
              ...curr,
              status: MlsListingStatus.Pending,
            };
          } else {
            acc[MlsListingStatus.Pending].countDifference +=
              curr.countDifference;
            acc[MlsListingStatus.Pending].sumListPriceDifference =
              (acc[MlsListingStatus.Pending].sumListPriceDifference || 0) +
              (curr.sumListPriceDifference || 0);
          }
        } else {
          acc[curr.status] = curr;
        }

        return acc;
      }, {} as ListingAggregateDifferenceStatusMap),
    [aggregateDifferenceData]
  );

  return (
    <OrganizationListingsContext.Provider
      value={{
        listingsQueryRef,
        listingAggregateStatusMap,
        aggregateDifferenceStatusMap,
        selectedStatus,
        setSelectedStatus,
        listings,
        listingsLoading,
      }}
    >
      {children}
    </OrganizationListingsContext.Provider>
  );
};

export const useOrganizationListingsContext = () => {
  return useContext(OrganizationListingsContext);
};
