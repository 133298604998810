import { ComponentProps, useEffect, useState } from 'react';
import { SelectedPartyInfoForm } from './SelectedPartyInfoForm';

type Props = {
  isContinueDisabled?: boolean;
  setIsContinueDisabled?: (isContinueDisabled: boolean) => void;
} & Omit<
  ComponentProps<typeof SelectedPartyInfoForm>,
  'isEditing' | 'setIsEditing'
>;

export const SelectedPartyInfoControl = (props: Props) => {
  const [isEditing, setIsEditing] = useState(false);
  const { setIsContinueDisabled } = props;

  useEffect(() => {
    setIsContinueDisabled?.(isEditing);
  }, [isEditing, setIsContinueDisabled]);

  return (
    <SelectedPartyInfoForm
      {...props}
      isEditing={isEditing}
      setIsEditing={setIsEditing}
    />
  );
};
