import { Icon, IconProps } from '@chakra-ui/react';
import { ElementType, FC, useState } from 'react';
import { SvgGradient } from './Svg/SvgGradient';

interface GradientIconProps extends IconProps {
  icon: ElementType;
  gradientThemeKey: string;
}

export const GradientIcon: FC<GradientIconProps> = ({
  icon,
  gradientThemeKey,
  ...iconProps
}) => {
  const [generatedId, setGeneratedId] = useState('');

  return (
    <>
      <SvgGradient
        gradientThemeKey={gradientThemeKey}
        onIdGenerated={setGeneratedId}
      />
      <Icon as={icon} stroke={`url(#${generatedId})`} {...iconProps} />
    </>
  );
};
