import { Box, ModalProps, useDisclosure } from '@chakra-ui/react';
import { ComponentProps, FC } from 'react';

/**
 * ModalTrigger Component
 *
 * This component creates a trigger that opens a modal when clicked.
 *
 * @param children - The content to be rendered as the trigger.
 * @param modal - The modal component to be rendered when the trigger is clicked.
 * @param modalProps - Additional props to be passed to the modal component.
 *
 * @example
 * // Basic usage
 * <ModalTrigger modal={MyModal}>
 *   <Button>Open Modal</Button>
 * </ModalTrigger>
 *
 * // With custom modal props
 * <ModalTrigger<{ title: string; data: any }>
 *   modal={MyModal}
 *   modalProps={{ title: "Custom Title", data: someData }}
 * >
 *   <Button>Open Modal with Custom Props</Button>
 * </ModalTrigger>
 *
 * // With additional Box props
 * <ModalTrigger
 *   modal={MyModal}
 *   bg="gray.100"
 *   p={2}
 *   borderRadius="md"
 * >
 *   <Text>Click me to open modal</Text>
 * </ModalTrigger>
 */

export type ModalTriggerBoxProps = Omit<
  ComponentProps<typeof Box>,
  'onClick' | 'children'
>;

type ModalTriggerProps<T = object> = {
  children: React.ReactNode;
  modal: FC<Omit<ModalProps, 'children'> & T>;
  modalProps?: T;
} & ModalTriggerBoxProps;

export const ModalTrigger = <T extends object>({
  children,
  modal: Modal,
  modalProps,
  ...boxProps
}: ModalTriggerProps<T>) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Box
        _hover={{
          cursor: 'pointer',
        }}
        onClick={(e) => {
          e.stopPropagation();
          onOpen();
        }}
        {...boxProps}
      >
        {children}
      </Box>
      <Modal isOpen={isOpen} onClose={onClose} {...(modalProps as T)} />
    </>
  );
};
