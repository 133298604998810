import { Box, StackProps, VStack } from '@chakra-ui/react';
import React, { ElementType, ReactNode } from 'react';
import { GradientIcon } from '~/common/components/GradientIcon';

interface EmptyStateProps extends StackProps {
  icon: ElementType;
  headerText: ReactNode;
  subText?: ReactNode;
}

export const EmptyState: React.FC<EmptyStateProps> = ({
  icon,
  headerText,
  subText,
  ...stackProps
}) => {
  return (
    <VStack
      align="center"
      bg="card.bg"
      border="1px solid"
      borderColor="card.border"
      borderRadius="xl"
      height="100%"
      justify="center"
      minH="294px"
      p={5}
      spacing={6}
      textAlign="center"
      {...stackProps}
    >
      <Box>
        <GradientIcon gradientThemeKey="blue" h="44px" icon={icon} w="44px" />
      </Box>
      <Box fontSize="xl" fontWeight="normal">
        {headerText}
      </Box>
      {subText && <Box>{subText}</Box>}
    </VStack>
  );
};
