import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Flex,
  Icon,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
  Tag,
  Text,
  VStack,
} from '@chakra-ui/react';
import {
  OpenHouseCardFragment,
  OpenHouseSource,
} from '@client/graphql/__generated__/types';
import { format } from 'date-fns';
import dayjs from 'dayjs';
import { ArrowUpRight, Ellipsis } from 'lucide-react';
import { NavLink } from 'react-router-dom';

export function OpenHouseCard({
  openHouse,
  onDelete,
  listingId,
  isEditable = false,
  onEdit,
}: {
  openHouse: OpenHouseCardFragment & {
    startTsLocal: dayjs.Dayjs;
    endTsLocal: dayjs.Dayjs;
    isHappeningNow: boolean;
  };
  onDelete: (openHouseId: string) => Promise<void>;
  listingId: string;
  isEditable: boolean;
  onEdit: (openHouse: OpenHouseCardFragment) => void;
}) {
  const start = openHouse.startTsLocal.toDate();
  const end = openHouse.endTsLocal.toDate();

  return (
    <Card
      key={openHouse.id}
      border={openHouse.isHappeningNow ? '1px solid' : 'none'}
      borderColor={openHouse.isHappeningNow ? 'cyan.500' : 'whiteAlpha.200'}
      borderRadius="lg"
      height="400px"
      p={0}
    >
      <CardHeader>
        <Flex alignItems="center">
          <Text fontSize="lg" textTransform="none">
            {format(dayjs(openHouse.date).toDate(), 'MMMM do')}
          </Text>
          {openHouse.isHappeningNow && (
            <Tag ml="2" variant="blueGradient">
              Live now
            </Tag>
          )}
          <Spacer />
          {openHouse.source === OpenHouseSource.MLS && (
            <Text color="whiteAlpha.700" fontSize="xs">
              Sync'd from MLS
            </Text>
          )}
          {isEditable && (
            <Menu>
              <MenuButton
                as={IconButton}
                bgColor="transparent"
                color="whiteAlpha.600"
                icon={<Icon as={Ellipsis} />}
                size="sm"
              />
              <MenuList>
                <MenuItem
                  onClick={() => {
                    onEdit(openHouse);
                  }}
                >
                  Edit
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    void onDelete(openHouse.id);
                  }}
                >
                  Delete
                </MenuItem>
              </MenuList>
            </Menu>
          )}
        </Flex>
        <Text color="whiteAlpha.600" fontSize="md" textTransform="none">
          {format(start, 'h:mma').toLowerCase()} -{' '}
          {format(end, 'h:mma').toLowerCase()}
        </Text>
      </CardHeader>
      <CardBody>
        <VStack alignItems="start" height="100%" justifyContent="center">
          {openHouse.numGuestbookSigners > 0 ? (
            <>
              <Text color="purple.500" fontSize="100px">
                {openHouse.numGuestbookSigners}
              </Text>
              <Text color="whiteAlpha.700" fontSize="xs">
                GUESTBOOK SIGNERS
              </Text>
              <Link
                as={NavLink}
                color="whiteAlpha.700"
                fontSize="md"
                textDecoration="underline"
                to={`/listings/${listingId}/guestbook`}
              >
                View all
              </Link>
            </>
          ) : (
            <Text color="whiteAlpha.400" fontSize="4xl">
              No signups yet
            </Text>
          )}
        </VStack>
      </CardBody>
      <CardFooter borderTopWidth="0px">
        <Flex justifyContent="center" width="100%">
          <Button
            as={Link}
            bg="cyan.500"
            href={`/open-house/${openHouse.id}`}
            rel="noopener noreferrer"
            rightIcon={<Icon as={ArrowUpRight} />}
            target="_blank"
            width="100%"
          >
            Open signup link
          </Button>
        </Flex>
      </CardFooter>
    </Card>
  );
}
