import { Box, Flex, Portal } from '@chakra-ui/react';
import { useCreateEventForOfferMutation } from '@client/graphql/__generated__/main-operations';
import { EventTypes } from '@client/graphql/__generated__/types';
import { useCallback, useEffect } from 'react';
import { Navigate, useLocation, useNavigate, useParams } from 'react-router';
import { useIsMobile } from '~/common/hooks/useIsMobile';
import { STOREFRONT_SIDEBAR_WIDTHS } from '~/common/theme/sizes';
import { useAppContext } from '~/services/main/contexts/AppContext';
import { OfferFlowOutlet } from '../../Storefront/OfferFlow/OfferFlowOutlet';
import { useListingOffersOutletContext } from '../ListingSetupContext';

export const ListingOfferDetailPage = () => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const location = useLocation();
  const { offerId } = useParams();
  const { listing, offersWithContracts } = useListingOffersOutletContext();
  const [createEventForOffer] = useCreateEventForOfferMutation();
  const { user } = useAppContext();

  // Extract specific offerWithContract based on URL param
  const offerWithContract = offersWithContracts.find(
    (owc) => owc.offer.id === offerId
  );

  const createEvent = useCallback(
    async (offerId: string, userId: string) => {
      await createEventForOffer({
        variables: {
          input: {
            type: EventTypes.LISTING_AGENT_VIEWED,
            offerId,
            doneByUserId: userId,
          },
        },
      });
    },
    [createEventForOffer]
  );

  // Send LA viewed offer event onload
  useEffect(() => {
    if (!offerWithContract || !user) return;

    createEvent(offerWithContract.offer.id, user.id).catch(() => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClose = useCallback(
    () => navigate({ pathname: '../', search: location.search }),
    [navigate, location.search]
  );

  if (!offerWithContract) {
    return <Navigate replace to={`/listings/${listing.id}/offers`} />;
  }

  return (
    <Portal>
      <Box
        backdropFilter="blur(5px)"
        bgColor="rgba(0,0,0,0.1)"
        bottom={0}
        left={0}
        position="fixed"
        right={0}
        top={0}
        zIndex={4}
        onClick={onClose}
      />
      <Flex
        alignItems="flex-start"
        bgColor={isMobile ? 'transparent' : 'bg.mainDark'}
        borderLeft="1px solid"
        borderLeftColor="card.border"
        bottom={0}
        direction="column"
        flexShrink={0}
        height={{ base: 'auto', md: '100vh' }}
        overflowY="scroll"
        overscrollBehavior="contain"
        position={isMobile ? 'sticky' : 'fixed'}
        right={0}
        top={0}
        width={STOREFRONT_SIDEBAR_WIDTHS}
        zIndex={5}
      >
        <OfferFlowOutlet
          headerTitle="Your offer"
          listing={listing}
          maxWidth="90%"
          showStartOverButton={false}
        />
      </Flex>
    </Portal>
  );
};
