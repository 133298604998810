import { ScrollGesture } from '@use-gesture/vanilla';
import { useEffect, useState } from 'react';
import { useIsMobile } from './useIsMobile';

export const useMobileScrollShowHide = (minOffsetTop = 0) => {
  const [show, setShow] = useState(true);
  const [target, setTarget] = useState<HTMLDivElement | null>();
  const isMobile = useIsMobile();

  useEffect(() => {
    if (!isMobile || !target) return;

    const gesture = new ScrollGesture(
      target,
      ({ direction, offset, delta }) => {
        if (direction[1] > 0 && delta[1] > 15 && offset[1] > minOffsetTop) {
          setShow(false);
        } else if (
          (direction[1] < 0 && delta[1] < -25) ||
          offset[1] < minOffsetTop
        ) {
          setShow(true);
        }
      }
    );

    return () => gesture.destroy();
  }, [target, isMobile, minOffsetTop]);

  return { show, setTarget };
};
