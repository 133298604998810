import {
  useDocumentListForOfferFlowQuery,
  useDocumentSectionListForOfferFlowQuery,
  useDocumentVersionListForOfferFlowQuery,
} from '@client/graphql/__generated__/document-operations';
import {
  useCreateIndigoContractMutation,
  useUpdateIndigoContractMutation,
} from '@client/graphql/__generated__/main-operations';
import {
  DocumentForOfferFlowFragment,
  DocumentSectionCategory,
  LicenseType,
  PartyFragment,
  UsState,
} from '@client/graphql/__generated__/types';
import { useCallback, useMemo, useState } from 'react';
import { useDocumentSectionsByModuleType } from '~/services/document/components/DocumentSections/hooks/useDocumentSectionsByModuleType';
import { DocumentSection } from '~/services/document/components/DocumentSections/types';
import { useDocumentVersionValues } from '~/services/document/components/DocumentVersionMapper/hooks/useDocumentVersionValues';
import { useFormFieldsMap } from '~/services/document/components/FormFields/hooks/useFormFieldsMap';
import { useDocumentVersionFormFields } from '~/services/document/hooks/useDocumentVersionFormFields';
import { useMergeState } from '~/services/document/hooks/useMergeState';
import { useAppContext } from '~/services/main/contexts/AppContext';
import { IndigoContractFormProps } from '../IndigoContractForm';

export interface UseIndigoContractFormState {
  usState: UsState;
  selectedDocument?: DocumentForOfferFlowFragment;
  selectedParty?: PartyFragment;
  contractId?: string;
}

export const useIndigoContractForm = (props: IndigoContractFormProps) => {
  const { user } = useAppContext();
  const mlsStates = useMemo(() => {
    const mlsLicense = user?.licenses?.find(
      (license) => license.licenseType === LicenseType.MLS
    );

    return mlsLicense?.states || [];
  }, [user?.licenses]);

  const [state, setState] = useState<UseIndigoContractFormState>({
    usState: mlsStates[0],
    selectedParty: props.indigoContract?.party,
    contractId: props.indigoContract?.id,
  });
  const updateState = useMergeState(setState, (newState) => {
    let isReady = props.requiredFormStates
      ? props.requiredFormStates.every((key) => !!newState[key])
      : true;

    if (props.requiredFormStates?.includes('selectedParty')) {
      const secondaryConsumerEmails =
        newState.selectedParty?.secondaryConsumers.map(
          (consumer) => consumer.email
        ) || [];
      if (
        !newState.selectedParty?.primaryConsumer.email ||
        (secondaryConsumerEmails.length > 0 &&
          !secondaryConsumerEmails.every((email) => !!email))
      ) {
        isReady = false;
      }
    }

    props.onIsReady?.(isReady);
  });

  /**
   * Queries
   */
  const { data: documents, loading: documentsLoading } =
    useDocumentListForOfferFlowQuery({
      variables: {
        activeDocumentVersion: true,
        isAgentVerified: true,
        filters: {
          documentType: props.documentType,
          usStates: [state.usState, UsState.GLOBAL],
          withActiveDocumentVersion: true,
          withDocumentSections: [
            DocumentSectionCategory.OFFER_FLOW,
            DocumentSectionCategory.OFFER_CARD,
          ],
        },
      },
      onCompleted(data) {
        const primaryContractDoc =
          props.indigoContract?.contract?.contractDocuments?.[0];
        const primaryDoc = data.documentList?.results?.find(
          (doc) => doc.id === primaryContractDoc?.documentVersion?.document?.id
        );

        if (primaryDoc) {
          updateState({ selectedDocument: primaryDoc });
        }
      },
    });

  const { data: documentVersions, loading: documentVersionsLoading } =
    useDocumentVersionListForOfferFlowQuery({
      variables: {
        filters: {
          id: state.selectedDocument?.activeDocumentVersion?.id,
        },
        documentVersionFields: true,
        isAgentVerified: true,
      },
      skip: !state.selectedDocument?.activeDocumentVersion?.id,
    });

  const { data: documentSectionsList, loading: documentSectionsLoading } =
    useDocumentSectionListForOfferFlowQuery({
      variables: {
        filters: {
          documentId: state.selectedDocument?.id,
          documentSectionCategory: DocumentSectionCategory.OFFER_FLOW,
        },
      },
      skip: !state.selectedDocument?.id,
    });

  /**
   * Mutations
   */
  const [createIndigoContract] = useCreateIndigoContractMutation();
  const [updateIndigoContract] = useUpdateIndigoContractMutation();

  /**
   * Computed Hooks/Callbacks
   */

  const formFields = useDocumentVersionFormFields(
    documentVersions?.documentVersionList?.results?.[0]
      ?.documentVersionFields || []
  );
  const formFieldsMap = useFormFieldsMap(formFields);
  const documentSections = useDocumentSectionsByModuleType({
    documentSections: documentSectionsList?.publicDocumentSectionList
      ?.results as DocumentSection[],
  });
  const { updateValues, values } = useDocumentVersionValues({
    documentVersionFields: formFields,
    values: props.indigoContract?.contract?.fieldValues,
  });

  const createIndigoContractCallback = useCallback(async () => {
    if (!state.selectedDocument || !state.selectedParty?.id) {
      return;
    }

    const { data } = await createIndigoContract({
      variables: {
        input: {
          documentId: state.selectedDocument?.id,
          partyId: state.selectedParty?.id,
          documentType: state.selectedDocument.documentType,
          fieldValues: values,
        },
      },
    });

    updateState({ contractId: data?.createIndigoContract?.id });

    return data?.createIndigoContract;
  }, [
    createIndigoContract,
    state.selectedDocument,
    state.selectedParty?.id,
    updateState,
    values,
  ]);

  const updateIndigoContractCallback = useCallback(async () => {
    if (
      !props.indigoContract?.id ||
      !state.selectedDocument?.id ||
      !state.selectedParty?.id
    ) {
      return;
    }

    const { data } = await updateIndigoContract({
      variables: {
        input: {
          id: props.indigoContract?.id,
          fieldValues: values,
          documentId: state.selectedDocument?.id,
          documentType: state.selectedDocument?.documentType,
          partyId: state.selectedParty?.id,
        },
      },
    });

    return data?.updateIndigoContract;
  }, [
    props.indigoContract?.id,
    state.selectedDocument?.id,
    state.selectedDocument?.documentType,
    state.selectedParty?.id,
    updateIndigoContract,
    values,
  ]);

  if (props.indigoContract?.id) {
    props.mutationRef.current = updateIndigoContractCallback;
  } else {
    props.mutationRef.current = createIndigoContractCallback;
  }

  return {
    user,
    documents: documents?.documentList?.results,
    documentSection: documentSections.KEY_TERMS,
    isLoading: !documents?.documentList?.results && documentsLoading,
    sectionsLoading: documentSectionsLoading || documentVersionsLoading,
    updateState,
    formFieldsMap,
    state,
    values,
    updateValues,
    mlsStates,
  };
};
