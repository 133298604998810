import {
  NumberInput,
  NumberInputField,
  NumberInputFieldProps,
  NumberInputProps,
} from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';

export interface IPercentInputProps
  extends Omit<NumberInputProps, 'onChange' | 'onBlur'> {
  value?: number;
  onChange: (value?: number) => void;
  onBlur: NumberInputFieldProps['onBlur'];
}

export const PercentInput = ({
  value,
  onChange,
  onBlur,
  size = 'lg',
  ...rest
}: IPercentInputProps) => {
  const ref = useRef<HTMLInputElement>(null);
  const [localValue, setLocalValue] = useState<string | undefined>();

  const handleChange = (valueAsString: string, valueAsNumber: number) => {
    setLocalValue(valueAsString);

    const newParsedValue = !Number.isNaN(valueAsNumber)
      ? parseFloat((valueAsNumber / 100).toFixed(5))
      : undefined;

    if (value !== newParsedValue) {
      onChange(newParsedValue);
    }
  };

  useEffect(() => {
    if (value !== undefined) {
      setLocalValue((value * 100).toFixed(5).replace(/\.?0+$/, ''));
    }
  }, [value]);

  return (
    <NumberInput
      size={size}
      value={localValue}
      onChange={handleChange}
      {...rest}
    >
      <NumberInputField ref={ref} background="whiteAlpha.100" onBlur={onBlur} />
    </NumberInput>
  );
};
