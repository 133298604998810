import { Text } from '@chakra-ui/react';
import {
  ActivityFragment,
  ActivityType,
} from '@client/graphql/__generated__/types';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { IndigoLogoAvatar } from '~/common/icons/IndigoLogoAvatar';
import { ActivityItem } from './ActivityItem';

dayjs.extend(relativeTime);

export const ListingUpdateSystemActivity = ({
  listingActivity,
}: {
  listingActivity: ActivityFragment;
}) => {
  const metadata = listingActivity.metadata as {
    [ActivityType.SYSTEM_UPDATE]: {
      // TODO: make this share activity-feed.types.ts types
      updatedField: string;
      previousValue?: string;
      nextValue: string;
    };
  };
  const { updatedField, previousValue, nextValue } =
    metadata?.[ActivityType.SYSTEM_UPDATE] || {};

  return (
    <ActivityItem
      activity={listingActivity}
      avatar={<IndigoLogoAvatar mr={3} />}
    >
      <Text as="span" fontWeight="medium">
        {updatedField}
      </Text>{' '}
      changed{' '}
      {previousValue && (
        <>
          from{' '}
          <Text as="span" color="white" fontWeight="medium">
            {previousValue}
          </Text>{' '}
        </>
      )}
      to{' '}
      <Text as="span" color="white" fontWeight="medium">
        {nextValue}{' '}
      </Text>
    </ActivityItem>
  );
};
