import { Flex, Stack, useDisclosure } from '@chakra-ui/react';
import { FC, PropsWithChildren } from 'react';
import { Outlet } from 'react-router-dom';
import { useAppContext } from '~/services/main/contexts/AppContext';
import { DesktopSideBar } from '../components/Layouts/DesktopSideBar';
import { MobileHeader } from '../components/Layouts/MobileHeader';
import { MobileHeaderContext } from '../contexts/MobileHeaderContext';

interface MainLayoutProps extends PropsWithChildren {
  displayDefaultMobileNav?: boolean;
  transparentMobileHeader?: boolean;
  tabletIsMobile?: boolean;
}

export const MainLayout: FC<MainLayoutProps> = ({
  transparentMobileHeader = false,
  displayDefaultMobileNav = true,
  tabletIsMobile = false,
  children,
}) => {
  const { appState } = useAppContext();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const page = (
    <Stack
      alignItems="stretch"
      bgColor={appState.bgColor}
      flexDir={
        tabletIsMobile
          ? { base: 'column', lg: 'row' }
          : { base: 'column', md: 'row' }
      }
      minHeight="100dvh"
      spacing={0}
      w="100%"
    >
      <DesktopSideBar tabletIsMobile={tabletIsMobile} />
      <MobileHeaderContext.Provider
        value={{
          isDrawerOpen: isOpen,
          onDrawerOpen: onOpen,
          onDrawerClose: onClose,
        }}
      >
        <MobileHeader
          displayDefaultNav={displayDefaultMobileNav}
          tabletIsMobile={tabletIsMobile}
          transparentMobileHeader={transparentMobileHeader}
        />
        <Flex flexGrow={1}>{children ? children : <Outlet />}</Flex>
      </MobileHeaderContext.Provider>
    </Stack>
  );

  return page;
};

export const MainLayoutWithoutDefaultMobileNav: FC = () => {
  return <MainLayout displayDefaultMobileNav={false} />;
};
