import { Flex, Heading } from '@chakra-ui/react';
import { StorefrontSetupStep } from '@client/graphql/__generated__/types';
import { useNavigate } from 'react-router';
import { ShareSteps } from '~/apps/consumer/components/Share/ShareSteps';
import {
  getListingRoute,
  getStorefrontSetupWizardRoute,
} from '~/apps/consumer/utils/routes.utils';
import { useSharePage } from '../Share/hooks/useSharePage';
import { useStorefrontSetupWizardOutletContext } from './StorefrontSetupWizardContext';
import { StorefrontSetupWizardPageLayout } from './StorefrontSetupWizardPageLayout';

export const SharePage = () => {
  const { listing } = useStorefrontSetupWizardOutletContext();
  const navigate = useNavigate();

  const {
    isPdfViewerOpen,
    onPdfViewerClose,
    onPdfViewerOpen,
    agentRemarksStorefrontLink,
    agentRemarksBlurbString,
    loading,
    listingPackage,
    webUrlLink,
  } = useSharePage(listing);

  return (
    <StorefrontSetupWizardPageLayout
      nextButtonText="Done"
      onNext={() => {
        navigate(
          `${getListingRoute(listing?.id, 'dashboard')}?published=true`,
          {
            replace: true,
          }
        );
      }}
      onPrevious={() => {
        navigate(
          getStorefrontSetupWizardRoute(
            listing?.id,
            StorefrontSetupStep.DOCUMENTS
          )
        );
      }}
    >
      <Flex
        flexDirection="column"
        flexGrow={1}
        gap={8}
        mt={{ base: 4, lg: 16 }}
        pb={4}
        px={4}
        width={{ base: '100%', md: '80%' }}
      >
        <Heading textAlign="center">
          Share to MLS to collect offers & streamline communications
        </Heading>
        <ShareSteps
          agentRemarksBlurbString={agentRemarksBlurbString}
          agentRemarksStorefrontLink={agentRemarksStorefrontLink}
          isPdfViewerOpen={isPdfViewerOpen}
          listing={listing}
          listingPackage={listingPackage}
          loading={loading}
          webUrlLink={webUrlLink}
          onPdfViewerClose={onPdfViewerClose}
          onPdfViewerOpen={onPdfViewerOpen}
        />
      </Flex>
    </StorefrontSetupWizardPageLayout>
  );
};
