import { useUpdatePartyForBuyersAgentMutation } from '@client/graphql/__generated__/main-operations';
import { BuyerFormValues } from '~/apps/consumer/components/Buyer/types';

export const useUpdatePartyForBuyersAgent = () => {
  const [updatePartyForBuyersAgent, { loading }] =
    useUpdatePartyForBuyersAgentMutation();

  const handleUpdate = async (
    partyId: string,
    values: BuyerFormValues,
    preapprovalFile: File | undefined
  ) => {
    await updatePartyForBuyersAgent({
      variables: {
        input: {
          partyUuid: partyId,
          primaryBuyer: {
            fullName: values.buyerName,
            email: values.buyerEmail,
            isEntity: values.isEntity,
            entityName: values.entityName,
            entityTitle: values.entityTitle,
          },
          secondaryBuyers:
            values.coBuyerName && values.coBuyerEmail
              ? [
                  {
                    fullName: values.coBuyerName,
                    email: values.coBuyerEmail,
                  },
                ]
              : undefined,
          financingType: values.financingType,
          loanAmount: values.loanAmount ? Number(values.loanAmount) : undefined,
          percentDownPayment: values.percentDownPayment
            ? Number(values.percentDownPayment)
            : undefined,
          cashReserves: values.cashReserves
            ? Number(values.cashReserves)
            : undefined,
          preapprovalFile: preapprovalFile,
        },
      },
    });
  };

  return { handleUpdate, loading };
};
