export const StorefrontSetupTourClassNameStep1 = 'storefront-setup-step-1';
export const StorefrontSetupTourClassNameStep2 = 'storefront-setup-step-2';
export const StorefrontSetupTourClassNameStep3 = 'storefront-setup-step-3';

export const tourSteps = [
  {
    selector: `.${StorefrontSetupTourClassNameStep1}`,
    content:
      'Customize your storefront with seller preferences, comparables, and supplemental documents.',
  },
  {
    selector: `.${StorefrontSetupTourClassNameStep2}`,
    content:
      'Share it with prospective buying agents to collect and manage offers.',
  },
  {
    selector: `.${StorefrontSetupTourClassNameStep3}`,
    content:
      'Post announcements to notify anyone who is interested of any listing changes, or requests for offers.',
  },
];
