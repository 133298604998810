import { Flex, Heading, Text } from '@chakra-ui/react';
import { FileText } from 'lucide-react';
import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useStorefrontContext } from '~/apps/consumer/pages/Storefront/StorefrontContext';
import { useAppContext } from '~/services/main/contexts/AppContext';
import { EmptyState } from '../../EmptyState';
import { DocumentsDetailsCard } from './DocumentsDetailsCard';
import { ListingDocumentsViewerModal } from './ListingDocumentsViewerModal';
import { filterAndGroupListingDocs } from './helpers';

const UNAUTH_SELECTED_DOC_ID_PARAM = 'doc';

export const DocumentsDetails = () => {
  const { isAuthenticated } = useAppContext();
  const { listingDocumentUploads } = useStorefrontContext();
  const [isViewerModalOpen, setIsViewerModalOpen] = useState(false);
  const [viewerSelectedDocId, setViewerSelectedDocId] = useState<string | null>(
    null
  );
  const [searchParams, setSearchParams] = useSearchParams();

  const handleViewDocument = useCallback(
    (documentId: string) => {
      if (!isAuthenticated) {
        // Set param so that we can open the viewer modal with this document once the user has
        // authenticated. See the below useEffect.
        setSearchParams((prev) => {
          prev.set(UNAUTH_SELECTED_DOC_ID_PARAM, documentId);

          return prev;
        });
      }
      setViewerSelectedDocId(documentId);
      setIsViewerModalOpen(true);
    },
    [isAuthenticated, setSearchParams]
  );

  useEffect(() => {
    const docIdFromParams = searchParams.get(UNAUTH_SELECTED_DOC_ID_PARAM);
    if (docIdFromParams && isAuthenticated) {
      // Remove param since it is only relevant to this StorefrontPage tab and we don't want to
      // manage removing this param in the StorefrontPageContent.handleChangeTab.
      setSearchParams((prev) => {
        prev.delete(UNAUTH_SELECTED_DOC_ID_PARAM);

        return prev;
      });
      setViewerSelectedDocId(docIdFromParams);
      setIsViewerModalOpen(true);
    }
  }, [isAuthenticated, searchParams, setSearchParams]);

  const { disclosures, additionalDocuments } = filterAndGroupListingDocs(
    listingDocumentUploads
  );

  return (
    <>
      {listingDocumentUploads.length > 0 ? (
        <>
          <Flex direction="column" gap={4}>
            <Heading size="heading">Listing Documents</Heading>
            {disclosures.length > 0 && (
              <DocumentsDetailsCard
                documentUploads={disclosures}
                title="Seller Disclosures"
                warningText="These docs will automatically be added to the offer package when you start an offer"
                onViewDocument={handleViewDocument}
              />
            )}
            {additionalDocuments.length > 0 && (
              <DocumentsDetailsCard
                documentUploads={additionalDocuments}
                title="Additional Documents"
                warningText="Additional documents won’t be included in the offer package"
                onViewDocument={handleViewDocument}
              />
            )}
          </Flex>
          {viewerSelectedDocId && (
            <ListingDocumentsViewerModal
              initialDocumentId={viewerSelectedDocId}
              isOpen={isViewerModalOpen}
              onClose={() => {
                setViewerSelectedDocId(null);
                setIsViewerModalOpen(false);
              }}
            />
          )}
        </>
      ) : (
        <EmptyState
          headerText="The listing agent hasn’t added any additional documents"
          icon={FileText}
          subText={
            <Text>
              Subscribe to{' '}
              <Text as="span" color="link.skyBlue">
                get alerts
              </Text>{' '}
              and stay up to date
            </Text>
          }
        />
      )}
    </>
  );
};
