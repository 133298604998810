import { StorefrontSubscriptionReason } from '@client/graphql/__generated__/types';
import { createContext, useContext } from 'react';

interface ListingActivityContextValues {
  followListing: (reason: StorefrontSubscriptionReason) => Promise<void>;
  unfollowListing: () => Promise<void>;
  isFollowingListing: boolean;
  followerCount: number;
  guestbookSignersCount: number;
}

export const ListingActivityContext =
  createContext<ListingActivityContextValues>(
    {} as ListingActivityContextValues
  );

export const useListingActivityContext = () => {
  return useContext(ListingActivityContext);
};
