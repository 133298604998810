import { Marker } from 'react-map-gl';
import { DefaultMapMarker } from '../../Map/MapMarkers/DefaultMapMarker';

export const CompsMiniMapMarker = ({
  lat,
  lng,
  isMainListing,
}: {
  lat: number;
  lng: number;
  isMainListing: boolean;
}) => {
  return (
    <Marker anchor="bottom" latitude={lat} longitude={lng}>
      <DefaultMapMarker
        color="indigo.800"
        fill={isMainListing ? 'white' : 'purple.500'}
      />
    </Marker>
  );
};
