import {
  ListingFragment,
  PublicListingFragment,
} from '@client/graphql/__generated__/types';
import React, { FC, createContext, memo, useContext, useEffect } from 'react';
import { isAndroid } from 'react-device-detect';
import { useIsMobile } from '~/common/hooks/useIsMobile';
import { useOfferFlow } from './hooks/useOfferFlow';

export interface OfferFlowContextProviderProps {
  children: React.ReactNode;
  listing: ListingFragment | PublicListingFragment;
  showHeader?: boolean;
  waitToLoadQueries?: boolean;
  headerTitle?: string;
  showStartOverButton?: boolean;
}

export type OfferFlowContextValue = ReturnType<typeof useOfferFlow> &
  Omit<OfferFlowContextProviderProps, 'children'>;

export const OfferFlowContext = createContext<OfferFlowContextValue>(
  {} as OfferFlowContextValue
);

export const useOfferFlowContext = () => useContext(OfferFlowContext);

export const OfferFlowContextProvider: FC<OfferFlowContextProviderProps> = memo(
  function OfferFlowContextProvider({
    children,
    listing,
    showHeader = true,
    waitToLoadQueries = false,
    headerTitle,
    showStartOverButton = true,
  }) {
    const isMobile = useIsMobile();
    const offerFlow = useOfferFlow({
      listing,
      waitToLoadQueries,
    });

    useEffect(() => {
      // this was causing weird flickering behavior on mobile and was covered by the mobile keyboard
      if (isMobile) {
        return;
      }
      if (offerFlow.showContractPreview && offerFlow.activeMappingKey) {
        requestAnimationFrame(() => {
          const pdfFormFieldElem = document.querySelector(
            `#contract-preview-tabs [role="tabpanel"]:not([hidden]) div[data-mapping-key="${
              offerFlow.activeMappingKey || ''
            }"]`
          );

          if (pdfFormFieldElem) {
            pdfFormFieldElem.scrollIntoView({
              // 'nearest' is required on mobile to prevent the pdf form from scrolling the whole drawer
              // since the pdf is in a sticky element
              block: 'center',
              inline: 'center',
              // 'smooth' doesn't work on android mobile so we use 'auto' instead
              behavior: isAndroid ? 'auto' : 'smooth',
            });
          } else if (offerFlow.activeMappingKey) {
            const formField =
              offerFlow.formFieldsMap[offerFlow.activeMappingKey];
            if (formField) {
              const pdfFormFieldPageElem = document.querySelector(
                `#contract-preview-tabs [role="tabpanel"]:not([hidden]) div[data-page-number="${
                  formField.pageNumber || ''
                }"]`
              );
              pdfFormFieldPageElem?.scrollIntoView();

              window.setTimeout(() => {
                const pdfFormFieldElem = document.querySelector(
                  `#contract-preview-tabs [role="tabpanel"]:not([hidden]) div[data-mapping-key="${
                    offerFlow.activeMappingKey || ''
                  }"]`
                );

                pdfFormFieldElem?.scrollIntoView({
                  block: 'center',
                  inline: 'center',
                  behavior: isAndroid ? 'auto' : 'smooth',
                });
              }, 300);
            }
          }
        });
      }
    }, [
      offerFlow.showContractPreview,
      offerFlow.activeMappingKey,
      offerFlow.formFieldsMap,
      isMobile,
    ]);

    return (
      <OfferFlowContext.Provider
        value={{
          ...offerFlow,
          headerTitle,
          showStartOverButton,
          listing,
          showHeader,
        }}
      >
        {children}
      </OfferFlowContext.Provider>
    );
  }
);
