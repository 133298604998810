import {
  useListingFollowersQuery,
  useStorefrontActivitiesSummaryQuery,
} from '@client/graphql/__generated__/main-operations';
import {
  ActivityType,
  ListingFollowerFragment,
  StorefrontActivitySummaryFragment,
} from '@client/graphql/__generated__/types';
import React, { createContext, useContext } from 'react';

const activityTypes = [ActivityType.LISTING_DOCUMENT_VIEWED];

interface StorefrontDashboardContextType {
  loading: boolean;
  subscribers: ListingFollowerFragment[];
  activitySummary: StorefrontActivitySummaryFragment[];
}

const defaultActivitySummary = activityTypes.map((activityType) => ({
  count: 0,
  activityType,
  users: [],
  activities: [],
}));

const StorefrontDashboardContext =
  createContext<StorefrontDashboardContextType>({
    loading: true,
    activitySummary: defaultActivitySummary,
    subscribers: [],
  });

interface StorefrontDashboardProviderProps extends React.PropsWithChildren {
  listingId: string;
}

export const StorefrontDashboardProvider: React.FC<
  StorefrontDashboardProviderProps
> = ({ children, listingId }) => {
  const { data: listingDocViewsData, loading: listingDocViewsLoading } =
    useStorefrontActivitiesSummaryQuery({
      variables: {
        input: {
          listingId: listingId,
          activityTypes: [ActivityType.LISTING_DOCUMENT_VIEWED],
        },
      },
    });

  const { data: listingFollowersData, loading: listingFollowersLoading } =
    useListingFollowersQuery({
      variables: {
        input: {
          id: listingId,
        },
      },
    });

  const value: StorefrontDashboardContextType = {
    loading: listingDocViewsLoading || listingFollowersLoading,
    activitySummary:
      listingDocViewsData?.storefrontActivitiesSummary ??
      defaultActivitySummary,
    subscribers: listingFollowersData?.listingFollowers?.listingFollowers ?? [],
  };

  return (
    <StorefrontDashboardContext.Provider value={value}>
      {children}
    </StorefrontDashboardContext.Provider>
  );
};

export const useStorefrontDashboardContext = () => {
  return useContext(StorefrontDashboardContext);
};
