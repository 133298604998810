import { Icon, IconProps } from '@chakra-ui/react';
import { FC } from 'react';

export const IndigoLogoAvatar: FC<IconProps> = (props) => {
  return (
    <Icon
      fill="none"
      height="8"
      viewBox="0 0 32 32"
      width="8"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="16" cy="16" fill="#64BEFF" r="16" />
      <ellipse cx="16" cy="10.2857" fill="#0C0C2A" rx="2.28568" ry="2.28568" />
      <ellipse cx="16" cy="21.7143" fill="#0C0C2A" rx="2.28568" ry="2.28568" />
      <ellipse cx="21.7144" cy="16" fill="#0C0C2A" rx="2.28568" ry="2.28568" />
      <ellipse cx="10.2857" cy="16" fill="#0C0C2A" rx="2.28568" ry="2.28568" />
    </Icon>
  );
};
