import {
  useAddOrUpdateDisclosureContractDocumentsMutation,
  useDocumentListForOfferFlowLazyQuery,
} from '@client/graphql/__generated__/document-operations';
import {
  useCreateOfferMutation,
  usePublicListingDocumentUploadsQuery,
} from '@client/graphql/__generated__/main-operations';
import {
  ContractDocumentFileInput,
  DocumentType,
  ExternalDocumentType,
  ListingDocumentTypeEnum,
  OfferSource,
} from '@client/graphql/__generated__/types';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Location, useLocation } from 'react-router';
import { useAppContext } from '~/services/main/contexts/AppContext';
import { OfferFlowOutlet } from './OfferFlow/OfferFlowOutlet';
import { useStorefrontContext } from './StorefrontContext';

export const StorefrontOfferFlowPage = () => {
  const { listing, scopeToParty, canMakeOffer } = useStorefrontContext();
  const creatingNewOffer = useRef(false);
  const [newOfferCreated, setNewOfferCreated] = useState<boolean>(false);
  const location = useLocation() as Location<{ startNewOffer?: boolean }>;

  const { isAuthenticated } = useAppContext();
  const [createOffer] = useCreateOfferMutation();
  const [getDocuments] = useDocumentListForOfferFlowLazyQuery();
  const [addOrUpdateDisclosure] =
    useAddOrUpdateDisclosureContractDocumentsMutation();

  // Manually fetch listing doc uploads instead of depending on the StorefrontContext we want to
  // attach the real disclosures to the offer regardless of whether or not the user has signed the
  // guestbook
  const { data: publicListingDocumentUploadsData } =
    usePublicListingDocumentUploadsQuery({
      variables: {
        input: {
          listingId: listing.id,
        },
      },
    });

  const publicListingDocumentUploads = useMemo(() => {
    return publicListingDocumentUploadsData?.publicListingDocumentUploads || [];
  }, [publicListingDocumentUploadsData]);

  const createNewOffer = useCallback(async () => {
    const newOfferData = await createOffer({
      variables: {
        input: {
          listingId: listing.id,
          source: OfferSource.BA_WRITE,
          partyId: scopeToParty?.id,
        },
        isAgentVerified: isAuthenticated,
      },
    });
    const newOffer =
      newOfferData.data?.createOfferForBuyersAgent ||
      newOfferData.data?.createOfferForUnauthenticatedBuyersAgent;
    const { data } = await getDocuments({
      variables: {
        defaultSecondaryDocuments: true,
        filters: {
          documentType: DocumentType.PROPERTY_PURCHASE_CONTRACT,
          usStates: [listing.property.state],
        },
      },
    });

    const activeDocument = data?.publicDocumentList?.results?.[0];
    const includedDocumentTypes = [
      // BLO-818: Treat agent negotiation as a regular disclosure
      ListingDocumentTypeEnum.AGENT_NEGOTIATION,
      ListingDocumentTypeEnum.DISCLOSURE,
    ];
    const listingAgentDisclosures = publicListingDocumentUploads.filter(
      (doc) =>
        activeDocument?.defaultSecondaryDocuments?.some(
          (secDoc) =>
            secDoc.secondaryDocument.form === doc?.listingDocumentType?.formName
        ) ||
        doc?.customDocumentType === ListingDocumentTypeEnum.DISCLOSURE ||
        includedDocumentTypes.includes(
          doc?.listingDocumentType?.type as ListingDocumentTypeEnum
        )
    );
    if (listingAgentDisclosures.length) {
      const contractDocumentInputs: ContractDocumentFileInput[] =
        await Promise.all(
          listingAgentDisclosures.map(async (disc) => {
            const matchingDocument =
              activeDocument?.defaultSecondaryDocuments?.find(
                (secDoc) =>
                  secDoc.secondaryDocument.form ===
                  disc?.listingDocumentType?.formName
              );

            const input: ContractDocumentFileInput = {
              documentVersionId:
                matchingDocument?.secondaryDocument?.activeDocumentVersion?.id,
              order: (matchingDocument?.ordering as number) || 100, // if no matching doc then sort last
              mappingKeys: matchingDocument?.mappingKey
                ? [matchingDocument?.mappingKey]
                : undefined,
              externalContractDocumentName:
                disc?.customDocumentName ||
                (!matchingDocument
                  ? disc?.listingDocumentType?.name
                  : undefined),
              externalContractDocumentType: !matchingDocument
                ? ExternalDocumentType.UNKNOWN
                : undefined,
            };

            if (disc.file) {
              const response = await fetch(disc?.file?.url);
              const fileData = await response.blob();
              const file = new File([fileData], disc?.file?.fileName);

              input.file = file;
            }

            return input;
          })
        );
      await addOrUpdateDisclosure({
        variables: {
          input: {
            contractId: newOffer?.offer?.contractId as string,
            contractDocuments: contractDocumentInputs,
            shouldDetectFormFields: false,
          },
        },
      });
    }

    setNewOfferCreated(true);
  }, [
    createOffer,
    listing.id,
    listing.property.state,
    scopeToParty?.id,
    isAuthenticated,
    getDocuments,
    addOrUpdateDisclosure,
    publicListingDocumentUploads,
  ]);

  useEffect(() => {
    if (!!location.state?.startNewOffer) {
      if (creatingNewOffer.current || !canMakeOffer) return;

      creatingNewOffer.current = true;
      void createNewOffer();
    }
  }, [location.state, createNewOffer, canMakeOffer]);

  return (
    <OfferFlowOutlet
      listing={listing}
      waitToLoadQueries={!!location.state?.startNewOffer && !newOfferCreated}
    />
  );
};
