import { ChevronRightIcon } from '@chakra-ui/icons';
import {
  Box,
  Divider,
  Flex,
  HStack,
  Progress,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';

import { useUpdateOfferForBuyersAgentMutation } from '@client/graphql/__generated__/main-operations';
import {
  ListingObject,
  OfferSource,
  OfferStatus,
} from '@client/graphql/__generated__/types';
import { FC, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router';
import {
  StepNavLink,
  StepStatus,
} from '~/apps/consumer/components/Navigation/StepNavLink';
import { OfferFlowLayoutHeader } from '~/apps/consumer/components/OfferFlow/OfferFlowLayoutHeader/OfferFlowLayoutHeader';
import { StartOverModal } from '~/apps/consumer/components/OfferFlow/StartOverModal/StartOverModal';
import { VerifyUserModal } from '~/apps/consumer/components/VerifyUser/VerifyUserModal';
import { getStorefrontRoute } from '~/apps/consumer/utils/storefront.utils';
import { useIsMobile } from '~/common/hooks/useIsMobile';
import { useMobileScrollShowHide } from '~/common/hooks/useMobileScrollShowHide';
import { ContractPreview } from '../../../components/OfferFlow/ContractPreview/ContractPreview';
import { useStorefrontContext } from '../StorefrontContext';
import { useOfferFlowContext } from './OfferFlowContext';

export const OfferFlowLayout: FC = () => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const {
    showStartOverButton,
    headerTitle,
    showVerifyUserModal,
    setShowVerifyUserModal,
    latestOffer,
    showLoading,
    queriesLoading,
    listing,
    isListingAgentViewingOffer,
    refetchLatestOffer,
    save,
  } = useOfferFlowContext();
  const { canMakeOffer } = useStorefrontContext();

  const [updateOfferForBuyersAgent] = useUpdateOfferForBuyersAgentMutation();
  const { show, setTarget } = useMobileScrollShowHide(130);
  const isWaitingForBuyerSignature =
    latestOffer?.status === OfferStatus.BUYER_SIGNATURE_REQUEST_SENT;
  const sentToListingAgent = !!latestOffer?.submittedAt;

  const onSignup = async () => {
    await updateOfferForBuyersAgent({
      variables: {
        input: {
          id: latestOffer?.id as string,
          setCurrentUserInCreatedByField: true,
        },
      },
    });
    await refetchLatestOffer();
  };

  useEffect(() => {
    if (isListingAgentViewingOffer) {
      return;
    }

    if (latestOffer?.source === OfferSource.BA_UPLOAD) {
      navigate('uploaded', { replace: true });

      return;
    }

    if (isWaitingForBuyerSignature || sentToListingAgent) {
      // when user can't edit the form because it's sent for signature or finally sent to listing agent
      // we hide the breadcrumb and ensure users are always redirected to /view
      navigate('review', { replace: true });

      return;
    }
    /*
     * Only gets to here if the user is not an LA viewing the offer,
     * the offer is not uploaded, and the offer is not waiting for buyer
     * signature or sent to listing agent. We kick the user back to storefront if
     * there is no offer or if the user cannot make an offer.
     */
    if ((!queriesLoading && !latestOffer) || !canMakeOffer) {
      navigate(getStorefrontRoute(listing as ListingObject));

      return;
    }
  }, [
    latestOffer,
    navigate,
    queriesLoading,
    listing,
    isWaitingForBuyerSignature,
    sentToListingAgent,
    isListingAgentViewingOffer,
    canMakeOffer,
  ]);

  const offerInProgress =
    !latestOffer?.isUploaded && latestOffer?.status === OfferStatus.DRAFT;

  const flowTitle = offerInProgress
    ? 'Prepare offer for buyer esign'
    : 'Offer details';

  const hideSteps =
    latestOffer?.isUploaded ||
    isWaitingForBuyerSignature ||
    sentToListingAgent ||
    isListingAgentViewingOffer;

  return (
    <VStack
      ref={setTarget}
      alignItems="stretch"
      flexGrow={1}
      flexShrink={1}
      overflow={isMobile ? 'auto' : 'initial'}
      overscrollBehavior="contain"
      spacing={0}
      width="100%"
    >
      <Flex
        backgroundColor="bg.mainDark"
        direction="column"
        overscrollBehavior="contain"
        position={
          !isMobile || window.visualViewport?.height === window.innerHeight
            ? 'sticky'
            : 'relative'
        }
        top="0"
        transform={show ? 'translateY(0)' : 'translateY(-100%)'}
        transition="transform 0.3s"
        zIndex="3"
      >
        <OfferFlowLayoutHeader
          isLoading={queriesLoading}
          shouldSaveOnClose={offerInProgress}
          showStartOverButton={showStartOverButton}
          title={headerTitle ?? flowTitle}
          onStartOver={() => onOpen()}
        />

        <Divider />

        {!hideSteps && (
          <>
            <HStack
              gap={{ base: 1, md: 2 }}
              justifyContent="flex-start"
              p={5}
              position="relative"
            >
              <StepNavLink
                status={StepStatus.INCOMPLETE}
                to="key-terms"
                onClick={() => void save()}
              >
                Key terms
              </StepNavLink>
              <ChevronRightIcon
                color="whiteAlpha.500"
                height="20px"
                width="20px"
              />
              <StepNavLink
                status={StepStatus.INCOMPLETE}
                to="buyer"
                onClick={() => void save()}
              >
                Buyer
              </StepNavLink>
              <ChevronRightIcon
                color="whiteAlpha.500"
                height="20px"
                width="20px"
              />
              <StepNavLink
                status={StepStatus.INCOMPLETE}
                to="add-docs"
                onClick={() => void save()}
              >
                Add Docs
              </StepNavLink>
              <ChevronRightIcon
                color="whiteAlpha.500"
                height="20px"
                width="20px"
              />
              <StepNavLink
                status={StepStatus.INCOMPLETE}
                to="fill-docs"
                onClick={() => void save()}
              >
                Fill Docs
              </StepNavLink>
              <ChevronRightIcon
                color="whiteAlpha.500"
                height="20px"
                width="20px"
              />
              <StepNavLink
                status={StepStatus.INCOMPLETE}
                to="review"
                onClick={() => void save()}
              >
                Review
              </StepNavLink>

              {showLoading && (
                <Box bottom={0} left={0} position="absolute" right={0}>
                  <Progress isIndeterminate height="2px" size="xs" />
                </Box>
              )}
            </HStack>
          </>
        )}
      </Flex>
      <Outlet />
      {!isMobile && <ContractPreview />}
      <VerifyUserModal
        setShowVerifyUserModal={setShowVerifyUserModal}
        showVerifyUserModal={showVerifyUserModal}
        onSignup={onSignup}
      />
      <StartOverModal isOpen={isOpen} onClose={onClose} />
    </VStack>
  );
};
