import {
  useContractByIdQuery,
  useUpdateContractMutation,
} from '@client/graphql/__generated__/document-operations';
import {
  useCreateEmbeddedSignatureRequestMutation,
  useIndigoContractsForCurrentUserQuery,
} from '@client/graphql/__generated__/main-operations';
import { SignatureFormFieldObject } from '@client/graphql/__generated__/types';
import { useCallback, useMemo, useState } from 'react';
import { useDocumentVersionValues } from '~/services/document/components/DocumentVersionMapper/hooks/useDocumentVersionValues';
import {
  UseDocumentVersionFields,
  useDocumentVersionFormFields,
} from '~/services/document/hooks/useDocumentVersionFormFields';
import { PrepareIndigoContractProps } from '../PrepareIndigoContract';

export const usePrepareIndigoContract = ({
  indigoContractId,
  mutationRef,
}: Pick<PrepareIndigoContractProps, 'indigoContractId' | 'mutationRef'>) => {
  const [signatureFormFields, setSignatureFormFields] =
    useState<SignatureFormFieldObject[]>();
  const [embeddedUrl, setEmbeddedUrl] = useState<string>();

  /**
   * Queries
   */
  const { data, loading } = useIndigoContractsForCurrentUserQuery({
    variables: {
      filters: {
        id: indigoContractId,
      },
      includeFieldValues: true,
    },
    skip: !indigoContractId,
  });

  const { data: contractData, loading: contractLoading } = useContractByIdQuery(
    {
      variables: {
        id: data?.indigoContractsForCurrentUser?.results?.[0]?.contract
          ?.id as string,
        documentVersionFields: true,
        documentVersionFile: true,
        autofill: false,
      },
      skip: !data?.indigoContractsForCurrentUser?.results?.[0]?.contract?.id,
    }
  );

  const indigoContract = data?.indigoContractsForCurrentUser?.results?.[0];
  const contractDocuments = contractData?.contractById?.contractDocuments;

  const transformDocumentVersionFields = useMemo(() => {
    return Array.from(contractDocuments || [])
      .sort((a, b) => a.order - b.order)
      .reduce(
        (out, next) => {
          const lastPage = out.prevLastPage;
          const documentVersionFields =
            next.documentVersion?.documentVersionFields || [];

          out.fields = out.fields.concat(
            documentVersionFields.map((field) => {
              return {
                ...field,
                pageNumber: field.pageNumber + lastPage,
              };
            }) || []
          );

          out.prevLastPage =
            lastPage +
            Math.max(
              ...documentVersionFields.map(({ pageNumber }) => pageNumber)
            );

          return out;
        },
        { prevLastPage: 0, fields: [] } as {
          prevLastPage: number;
          fields: UseDocumentVersionFields[];
        }
      );
  }, [contractDocuments]);

  const formFields = useDocumentVersionFormFields(
    transformDocumentVersionFields?.fields || []
  );

  const { values, updateValues } = useDocumentVersionValues({
    documentVersionFields: formFields,
    values: indigoContract?.contract?.fieldValues,
  });

  /**
   * Mutations
   */
  const [updateContract] = useUpdateContractMutation();
  const [createEmbeddedSignatureRequest] =
    useCreateEmbeddedSignatureRequestMutation();

  /**
   * Computed hooks/callbacks
   */
  const saveAndCreateDraftSignatureRequest = useCallback(async () => {
    if (!indigoContract?.contract) return;

    await updateContract({
      variables: {
        input: {
          id: indigoContract?.contract?.id,
          fieldValues: values,
        },
      },
    });

    const { data } = await createEmbeddedSignatureRequest({
      variables: {
        input: {
          indigoContractId: indigoContract.id,
          partyId: indigoContract.party.id,
          signatureFormFields: signatureFormFields?.map((field) => ({
            documentIndex: field.documentIndex,
            isRequired: true,
            page: field.page,
            height: field.height,
            width: field.width,
            x: field.x,
            y: field.y,
            id: field.id,
            signerId: field.signerId,
            type: field.type,
            name: field.name,
          })),
        },
      },
    });

    setEmbeddedUrl(data?.createEmbeddedSignatureRequest?.embeddedSignUrl);
  }, [
    indigoContract?.contract,
    indigoContract?.id,
    indigoContract?.party?.id,
    updateContract,
    values,
    createEmbeddedSignatureRequest,
    signatureFormFields,
  ]);

  mutationRef.current = saveAndCreateDraftSignatureRequest;

  return {
    embeddedUrl,
    saveAndCreateDraftSignatureRequest,
    signatureFormFields,
    setSignatureFormFields,
    indigoContract,
    contractDocuments,
    formFields,
    values,
    updateValues,
    isLoading:
      (!indigoContract && loading) ||
      (!contractData?.contractById && contractLoading),
  };
};
