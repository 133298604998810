import { Button } from '@chakra-ui/react';
import { PropsWithChildren } from 'react';

export interface ToggleButtonProps extends PropsWithChildren {
  size?: 'sm' | 'md' | 'lg';
  isActive: boolean;
  activeColor?: string;
  activeTextColor?: string;
  onClick: () => void;
}

const buttonHeightToSize = {
  sm: 6,
  md: 8,
  lg: 10,
};

export const ToggleButton = ({
  activeColor = 'white',
  activeTextColor = 'black',
  size = 'md',
  children,
  isActive,
  onClick,
}: ToggleButtonProps) => {
  return (
    <Button
      _hover={{
        background: isActive ? activeColor : 'whiteAlpha.300',
      }}
      background={isActive ? activeColor : 'transparent'}
      borderRadius="6px"
      color={isActive ? activeTextColor : 'white'}
      height={buttonHeightToSize[size]}
      width="50%"
      onClick={onClick}
    >
      {children}
    </Button>
  );
};
