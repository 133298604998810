import { useFieldListQuery } from '@client/graphql/__generated__/document-operations';
import {
  FieldType,
  VariableDataType,
  VariableSource,
} from '@client/graphql/__generated__/types';
import { useMemo } from 'react';
import { AutocompleteVariables } from './types';

export const FIELD_TO_VARIABLE_DATA_TYPE = {
  [FieldType.SELECT]: VariableDataType.LIST,
  [FieldType.CHECKBOX]: VariableDataType.BOOLEAN,
  [FieldType.DATE]: VariableDataType.TEXT,
  [FieldType.IMAGE]: VariableDataType.TEXT,
  [FieldType.INITIAL]: VariableDataType.TEXT,
  [FieldType.NUMBER]: VariableDataType.NUMBER,
  [FieldType.RADIO]: VariableDataType.BOOLEAN,
  [FieldType.SIGNATURE]: VariableDataType.TEXT,
  [FieldType.TEXT]: VariableDataType.TEXT,
  [FieldType.TEXTAREA]: VariableDataType.TEXT,
  [FieldType.SIGNED_DATE]: VariableDataType.TEXT,
};

export const useFieldAutocomplete = (
  variableSources: VariableSource[],
  documentVersionId: string
) => {
  const { data } = useFieldListQuery({
    variables: {
      perPage: 10000,
      filters: {
        documentVersionId,
      },
    },
    fetchPolicy: 'cache-first',
    skip: !variableSources.includes(VariableSource.FIELD),
  });

  const autocomplete = useMemo(() => {
    if (!data?.fieldList?.results) {
      return [];
    }

    const fieldList: AutocompleteVariables[] = data?.fieldList?.results?.map(
      (field) => ({
        type: FIELD_TO_VARIABLE_DATA_TYPE[field.fieldType],
        key: field.mappingKey,
        source: VariableSource.FIELD,
        caption: `${field.mappingKey} - ${field.fieldType}`,
        value: `"${field.mappingKey}"`,
        score: 1,
        meta: 'Fields',
      })
    );

    return fieldList;
  }, [data?.fieldList?.results]);

  return autocomplete;
};
