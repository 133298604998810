import { QueryResult } from '@apollo/client';
import { Button, HStack, Text } from '@chakra-ui/react';
import {
  IndigoContractsForCurrentUserQuery,
  IndigoContractsForCurrentUserQueryVariables,
  SignatureRequestEventObject,
  SignatureRequestEventType,
} from '@client/graphql/__generated__/types';
import { UserRoundPenIcon } from 'lucide-react';
import { FC } from 'react';
import { BetaTag } from '~/common/components/BetaTag';
import { enumToLabel } from '~/services/document/utils/enums';
import { CardEmptyState } from '../../components/CardEmptyState';
import { DashboardCard } from '../../components/Dashboard/DashboardCard';
import { DashboardItem } from '../../components/Dashboard/DashboardItem';
import { getFormattedDate } from '../../utils/listing.utils';

const getMostRecentEvent = (events: SignatureRequestEventObject[]) => {
  return events
    ?.filter((event) =>
      [
        SignatureRequestEventType.SIGNATURE_REQUEST_SENT,
        SignatureRequestEventType.SIGNATURE_REQUEST_ALL_SIGNED,
      ].includes(event.eventType)
    )
    .reduce((mostRecent: SignatureRequestEventObject | null, event) => {
      if (!mostRecent) return event;

      return new Date(mostRecent.createdAt as string) >
        new Date(event.createdAt as string)
        ? mostRecent
        : event;
    }, null);
};

interface AgencyAgreementsWidgetProps {
  queryRef: (ref: HTMLDivElement) => void;
  query: QueryResult<
    IndigoContractsForCurrentUserQuery,
    IndigoContractsForCurrentUserQueryVariables
  >;
  onNewAgreement: () => void;
}

export const AgencyAgreementsWidget: FC<AgencyAgreementsWidgetProps> = ({
  queryRef,
  query,
  onNewAgreement,
}) => {
  const { data, loading } = query;
  const indigoContractsResult = data?.indigoContractsForCurrentUser?.results;

  return (
    <DashboardCard
      emptyStateElement={
        <CardEmptyState
          description="Adapt to ever changing requirements with your clients using our easy to use contract writing"
          icon={UserRoundPenIcon}
          title="On-the-fly buyer agreements"
        />
      }
      isLoading={loading && !indigoContractsResult}
      noData={indigoContractsResult?.length === 0}
      title="Buyer Agreements"
      titleTag={<BetaTag />}
      onHeaderButtonClick={onNewAgreement}
    >
      {indigoContractsResult?.map((indigoContract) => (
        <DashboardItem
          key={indigoContract.id}
          rightElement={
            !indigoContract.contract?.signatureRequests?.length ? (
              <Button size="xs" variant="outline">
                Continue
              </Button>
            ) : (
              enumToLabel(
                getMostRecentEvent(
                  indigoContract.contract?.signatureRequests?.[0]?.events
                )?.eventType?.replace('SIGNATURE_REQUEST', '') || 'View'
              )
            )
          }
          routeTo={
            !indigoContract.contract?.signatureRequests?.length
              ? `./agency-agreement/${indigoContract.id}`
              : `/buyers/${indigoContract.party.id}/agency-agreements`
          }
        >
          <HStack>
            <Text>{indigoContract.party.primaryConsumer.fullName}</Text>
          </HStack>
          <Text color="whiteAlpha.500">
            Created{' '}
            {getFormattedDate(indigoContract.createdAt as Date, 'MMMM DD')}
          </Text>
        </DashboardItem>
      ))}
      <div ref={queryRef} style={{ position: 'relative', bottom: 100 }} />
    </DashboardCard>
  );
};
