import { useUpdateContractDocumentCustomFieldsMutation } from '@client/graphql/__generated__/document-operations';
import {
  DocumentVersionFieldFragment,
  DocumentVersionListFieldFragment,
} from '@client/graphql/__generated__/types';
import isEmpty from 'lodash/isEmpty';
import { useCallback, useEffect, useState } from 'react';
import { mapById } from '~/services/document/components/DocumentVersionMapper/hooks/useDocumentVersionMapper';
import {
  FormField,
  ValuesType,
} from '~/services/document/components/FormFields/types';
import { PageMetadata } from '~/services/document/components/PDFScrollable/types';

export const useUpdateCustomFields = (
  customFields?: DocumentVersionFieldFragment[],
  contractDocumentId?: string,
  contractId?: string,
  disableFormFields?: boolean
) => {
  const [updateContractDocumentCustomFields] =
    useUpdateContractDocumentCustomFieldsMutation();

  const [customFormFieldsById, setCustomFormFieldsById] = useState<
    Record<string, FormField>
  >({});
  const [pagesMetadata, setPagesMetadata] = useState<PageMetadata[]>([]);

  const updateCustomField = useCallback(
    (formField: FormField) => {
      setCustomFormFieldsById((prevCustomFields) => {
        const pageMetadata = pagesMetadata.find(
          (pageMetadata) =>
            pageMetadata.pageNumber === formField.pageNumber &&
            pageMetadata.documentIndex === formField.documentIndex
        );
        if (pageMetadata) {
          const prevCustomField = prevCustomFields[formField.id] || {
            ...formField,
          };

          const updatedCustomField = Object.assign({}, prevCustomField, {
            width: formField.width,
            height: formField.height,
            x: formField.x,
            y: formField.y,
          });

          return {
            ...prevCustomFields,
            [formField.id]: {
              ...updatedCustomField,
            },
          };
        }

        return prevCustomFields;
      });
    },
    [pagesMetadata]
  );

  const updateCustomFields = useCallback(async () => {
    if (contractDocumentId && contractId) {
      const customFields = Object.values(customFormFieldsById) || [];

      await updateContractDocumentCustomFields({
        variables: {
          input: {
            contractDocumentUuid: contractDocumentId,
            contractUuid: contractId,
            customFields: customFields as unknown as ValuesType[],
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractDocumentId, contractId, customFormFieldsById]);

  const onFormFieldChange = useCallback(
    (formFields: FormField | FormField[]) => {
      if (Array.isArray(formFields)) {
        formFields.forEach((formField) => {
          if (formField?.id) {
            updateCustomField(formField);
          }
        });
      } else {
        updateCustomField(formFields);
      }
    },
    [updateCustomField]
  );

  const onFormFieldDelete = useCallback((formFields: FormField[]) => {
    formFields.forEach((formField) => {
      if (formField?.id) {
        setCustomFormFieldsById((prev) => {
          const next = { ...prev };
          delete next[formField.id];

          return next;
        });

        setCustomFormFieldsById((prev) => {
          const next = { ...prev };
          delete next[formField.id];

          return next;
        });
      }
    });
  }, []);

  useEffect(() => {
    if (isEmpty(customFormFieldsById) && customFields) {
      const customFieldsById = mapById(
        (customFields || []) as DocumentVersionListFieldFragment[]
      );
      setCustomFormFieldsById(customFieldsById);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customFields]);

  useEffect(() => {
    if (!disableFormFields) {
      void updateCustomFields();
    }
  }, [updateCustomFields, disableFormFields]);

  return {
    customFormFieldsById,
    pagesMetadata,
    setCustomFormFieldsById,
    setPagesMetadata,
    onFormFieldChange,
    onFormFieldDelete,
  };
};
