import { As, Tag, TagLabel, TagLeftIcon, TagProps } from '@chakra-ui/react';

export interface IconTagProps extends TagProps {
  icon: As;
  label: string;
  iconSize?: string;
  variant?: string;
  color?: string;
  bgColor?: string;
  iconColor?: string;
}

export const IconTag = ({
  icon,
  label,
  iconSize = '10px',
  color = 'indigo.900',
  bgColor = 'green.500',
  variant = 'solid',
  iconColor,
  ...rest
}: IconTagProps) => {
  return (
    <Tag
      bgColor={bgColor}
      color={color}
      textAlign="center"
      variant={variant}
      {...rest}
    >
      <TagLeftIcon as={icon} boxSize={iconSize} color={iconColor} mr={1} />
      <TagLabel fontSize={rest.fontSize || 'sm'}>{label}</TagLabel>
    </Tag>
  );
};
