import { Flex, Heading, Text, Textarea } from '@chakra-ui/react';
import {
  MlsListingFragment,
  MlsListingObject,
} from '@client/graphql/__generated__/types';
import { ComparablesContainer } from '~/apps/consumer/components/Comparables/styledComponents';
import { AddComparableModal } from '~/apps/consumer/components/Listings/Comparables/AddComparableModal/AddComparableModal';
import { AddComparableColumn } from '~/apps/consumer/components/Listings/Comparables/ComparableColumns/AddComparableColumn';
import { ComparableColumn } from '~/apps/consumer/components/Listings/Comparables/ComparableColumns/ComparableColumn';
import { ComparableEditButtons } from '~/apps/consumer/components/Listings/Comparables/ComparableEditButtons';
import { DraggableComparableColumns } from '~/apps/consumer/components/Listings/Comparables/DraggableComparableColumns';
import { useListingSetupContext } from '~/apps/consumer/pages/ListingSetup/ListingSetupContext';
import {
  PUBLISH_BANNER_HEIGHT,
  PublishBanner,
} from '~/common/components/Banner/PublishBanner';
import { CenterSpinner } from '~/common/components/CenterSpinner';
import {
  ListingSetupContainer,
  ListingSetupContentWrapper,
} from '../ListingSetupWrapperComponents';
import {
  ComparablesProvider,
  useComparablesContext,
} from './ComparablesContext';

export interface ComparableState {
  mlsListing: MlsListingFragment;
}

export const ComparablesBody = ({
  isHeaderSticky = true,
}: {
  isHeaderSticky?: boolean;
}) => {
  const {
    mainMlsListing,
    setComparables,
    isEditMode,
    onCloseModal,
    notes,
    isDirty,
    setNotes,
  } = useComparablesContext();

  return (
    <>
      <Flex direction="column" gap={6} pb={6} w={{ base: '100%', md: '80%' }}>
        <Flex
          alignItems="center"
          bg={isHeaderSticky ? 'bg.main' : undefined}
          justifyContent="space-between"
          pb={2}
          position={isHeaderSticky ? 'sticky' : undefined}
          top={isDirty ? PUBLISH_BANNER_HEIGHT : 0}
          zIndex={3}
        >
          <Heading as="h5" size="xxs">
            Comparables
          </Heading>
          <ComparableEditButtons />
        </Flex>
        <Flex direction="column" gap={2}>
          <Heading size="headline">Agent Remarks</Heading>
          {isEditMode ? (
            <Textarea
              height="140px"
              value={notes}
              width="100%"
              onChange={(e) => {
                const inputValue = e.target.value;
                setNotes?.(inputValue);
              }}
            />
          ) : (
            <Text>{notes}</Text>
          )}
        </Flex>

        <ComparablesContainer>
          <ComparableColumn
            isMainListing
            isEditMode={isEditMode}
            mainMlsListing={mainMlsListing as MlsListingObject}
            mlsListing={mainMlsListing as MlsListingObject}
          />
          <DraggableComparableColumns />
          {isEditMode && <AddComparableColumn />}
        </ComparablesContainer>
      </Flex>
      <AddComparableModal
        mlsListing={mainMlsListing as MlsListingObject}
        onAdd={(newMlsListing: MlsListingObject) => {
          setComparables?.((prevState) => [
            ...prevState,
            { mlsListing: newMlsListing, agentNotes: '' },
          ]);
          onCloseModal?.();
        }}
      />
    </>
  );
};

export const ComparablesPageContent = () => {
  const { updating, loading, onPublish, isDirty } = useComparablesContext();

  if (loading) {
    return <CenterSpinner />;
  }

  return (
    <ListingSetupContainer>
      <PublishBanner
        isDirty={isDirty ?? false}
        isLoading={updating || loading || false}
        onClick={onPublish}
      />
      <ListingSetupContentWrapper>
        <ComparablesBody />
      </ListingSetupContentWrapper>
    </ListingSetupContainer>
  );
};

export const ComparablesPage = () => {
  const { listing } = useListingSetupContext();

  return (
    <ComparablesProvider listing={listing}>
      <ComparablesPageContent />
    </ComparablesProvider>
  );
};
