import { Flex, Icon, Text } from '@chakra-ui/react';
import {
  ConfidenceLevel,
  MarketDataPercentages,
  MarketDataSourceType,
} from '@client/graphql/__generated__/types';
import isNil from 'lodash/isNil';
import { CircleAlertIcon } from 'lucide-react';
import { useEffect, useState } from 'react';
import { ToggleButtons } from '~/common/components/ToggleButtons/ToggleButtons';
import { SignalStrength } from '~/common/icons/SignalIcon';
import { formatPercent } from '~/common/utils/formatter';
import { COLLAPSED_ROW_HEIGHT } from './constants';
import { SourceTags } from './SourceTags';

interface ContingenciesRowProps {
  label: string;
  source?: MarketDataSourceType;
  confidence?: ConfidenceLevel;
  marketData?: MarketDataPercentages;
  distanceMiles?: number;
  timePeriodMonths?: number;
  errorMessage?: string;
}

export const ContingenciesRow = ({
  label,
  marketData,
  source,
  confidence,
  distanceMiles,
  timePeriodMonths,
  errorMessage,
}: ContingenciesRowProps) => {
  const [value, setValue] = useState<'yes' | 'no'>('no');

  const getMarketDataDisplay = (marketData: MarketDataPercentages) => {
    if (isNil(marketData.no) && isNil(marketData.yes)) {
      return null;
    }

    formatPercent;
    if (isNil(marketData.no)) {
      return `${formatPercent(marketData.yes)} Yes`;
    }

    if (isNil(marketData.yes)) {
      return `${formatPercent(marketData.no)} No`;
    }

    return marketData.no > marketData.yes
      ? `${formatPercent(marketData.no)} No`
      : `${formatPercent(marketData.yes)} Yes`;
  };

  const marketDataDisplay = marketData
    ? getMarketDataDisplay(marketData)
    : undefined;

  const sourceLabel = source?.toLowerCase()?.split('_')?.join(' ');

  useEffect(() => {
    if (!isNil(marketData?.yes) && !isNil(marketData?.no)) {
      setValue(marketData.no > marketData.yes ? 'no' : 'yes');
    }
  }, [marketData]);

  return (
    <Flex
      borderBottom="1px solid"
      borderBottomColor="whiteAlpha.200"
      direction="column"
      justifyContent="center"
      px={4}
    >
      <Flex
        alignItems="center"
        gap={6}
        height={COLLAPSED_ROW_HEIGHT}
        py={4}
        transition="height 0.3s ease-in-out"
      >
        <Flex flexBasis={0} flexGrow={1} height="100%">
          <Flex direction="column" height="100%" justifyContent="space-between">
            <Text fontWeight="medium" textTransform="capitalize">
              {label}
            </Text>
          </Flex>
        </Flex>
        <Flex flexBasis={0} flexGrow={4} height="100%">
          <Flex
            direction="column"
            height="100%"
            justifyContent="space-between"
            width="100%"
          >
            <Flex height="100%">
              <Flex flexBasis={0} flexGrow={2} height="100%">
                {marketData && (
                  <Flex direction="column">
                    <Text fontSize="24px" fontWeight="medium">
                      {marketDataDisplay}
                    </Text>
                    <SourceTags
                      confidence={confidence as unknown as SignalStrength}
                      distanceMiles={distanceMiles}
                      isExpanded={false}
                      sourceLabel={sourceLabel}
                      timePeriodMonths={timePeriodMonths}
                    />
                  </Flex>
                )}
                {errorMessage && (
                  <Flex alignItems="center" gap={1}>
                    <Icon as={CircleAlertIcon} color="red.500" />
                    <Text>{errorMessage}</Text>
                  </Flex>
                )}
              </Flex>
              <Flex flexBasis={0} flexGrow={2} justifyContent="center">
                <ToggleButtons
                  activeButton={value}
                  activeButtonColor="purpleGradient"
                  activeButtonTextColor="white"
                  buttons={[
                    {
                      label: 'Yes',
                      value: 'yes',
                    },
                    {
                      label: 'No',
                      value: 'no',
                    },
                  ]}
                  setActiveButton={(value) => setValue(value as 'yes' | 'no')}
                  size="lg"
                />
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
