import {
  Box,
  Flex,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
} from '@chakra-ui/react';
import { Roles } from '@client/graphql/__generated__/types';
import { Outlet, useSearchParams } from 'react-router-dom';
import { SimpleHeader } from '~/apps/consumer/components/Layouts/SimpleHeader';
import { useIsMobile } from '~/common/hooks/useIsMobile';
import { useAppContext } from '~/services/main/contexts/AppContext';
import { NotFoundPage } from '../Errors/NotFoundPage';
import { OpenHouseDetailCard } from './OpenHouseDetailCard';
import {
  OpenHouseSignProvider,
  useOpenHouseSignContext,
} from './OpenHouseSignContext';
import { QRCodeCard } from './QRCodeCard';
import { SignCompleteScreen } from './SignCompleteScreen';

export const OpenHouseGuestBookSignPageLayout = () => {
  return (
    <OpenHouseSignProvider>
      <OpenHouseGuestBookSignPageLayoutContent />
    </OpenHouseSignProvider>
  );
};

const OpenHouseGuestBookSignPageLayoutContent = () => {
  const { user } = useAppContext();
  const showQRCodeOnMobile = user?.roles.includes(Roles.AGENT);

  const { signUpCompleted, error, loading } = useOpenHouseSignContext();
  const isMobile = useIsMobile();
  const [searchParams, setSearchParams] = useSearchParams();
  const activeTab = searchParams.get('tab') === 'qr' ? 'qr' : 'sign';

  if (loading) {
    return null;
  }

  if (error) {
    return <NotFoundPage />;
  }

  const placeholderComponent = signUpCompleted ? (
    <SignCompleteScreen />
  ) : (
    <Outlet />
  );

  const handleTabChange = (newTab: string) => {
    setSearchParams(
      (prev) => {
        prev.set('tab', newTab);

        return prev;
      },
      { replace: true }
    );
  };

  const desktopLayout = (
    <Flex flexDirection="row" gap={4} mt={12} width="80%">
      <Box flex={1}>{placeholderComponent}</Box>
      <Flex flexDirection="column" gap={2}>
        <OpenHouseDetailCard />
        <QRCodeCard />
      </Flex>
    </Flex>
  );

  const mobileLayoutWhenUnauthed = (
    <Flex flexDirection="column" gap={8} width="95%">
      <OpenHouseDetailCard />
      {placeholderComponent}
    </Flex>
  );

  const mobileLayoutWhenAuthed = (
    <Flex flexDirection="column" gap={4} width="95%">
      <OpenHouseDetailCard />
      <Tabs
        index={activeTab === 'qr' ? 0 : 1}
        variant="unstyled"
        onChange={(index) => handleTabChange(index === 0 ? 'qr' : 'sign')}
      >
        <TabList>
          <Tab>QR Code</Tab>
          <Tab>Sign</Tab>
        </TabList>
        <TabIndicator
          bg="purple.500"
          borderRadius="1px"
          height="2px"
          mt="-1.5px"
        />
        <TabPanels>
          <TabPanel px={0} py={8}>
            <QRCodeCard />
          </TabPanel>
          <TabPanel>{placeholderComponent}</TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  );

  return (
    <VStack
      alignItems="flex-start"
      flexDirection="column"
      maxWidth={isMobile ? '500px' : '960px'}
      minHeight="100vh"
      minWidth={isMobile ? '300px' : 'auto'}
      px={{ base: 3, md: 6 }}
      spacing={8}
      width="100%"
    >
      <SimpleHeader showSignUpLogin={false} />
      <Flex
        alignItems="center"
        flexDirection="column"
        gap={4}
        justifyContent="center"
        mb={{ base: 4, md: 8 }}
        width="100%"
      >
        <Text
          color="white"
          fontFamily='"Cardinal Photo"'
          fontSize="54px"
          fontStyle="normal"
          fontWeight={500}
          lineHeight="60px"
          textAlign="center"
        >
          {!isMobile ? (
            <>
              Welcome to the Open House
              <br />
              <Text color="whiteAlpha.600">Please sign the Guestbook</Text>
            </>
          ) : (
            <Text fontSize="32px">Sign the Guestbook</Text>
          )}
        </Text>
        {isMobile
          ? showQRCodeOnMobile
            ? mobileLayoutWhenAuthed
            : mobileLayoutWhenUnauthed
          : desktopLayout}
      </Flex>
    </VStack>
  );
};
