import {
  Button,
  Popover,
  PopoverAnchor,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  Portal,
  Text,
} from '@chakra-ui/react';

interface ConfirmationPopoverProps {
  isOpen: boolean;
  handleClick: () => void;
  buttonText?: string;
  anchor: React.ReactNode;
  text: string;
}

export const ConfirmationPopover = ({
  isOpen,
  handleClick,
  buttonText = 'Got it',
  anchor,
  text,
}: ConfirmationPopoverProps) => {
  return (
    <Popover isOpen={isOpen}>
      <PopoverAnchor>{anchor}</PopoverAnchor>
      <Portal>
        <PopoverContent bg="purple.500">
          <PopoverArrow bg="purple.500" />
          <PopoverBody>
            <Text>{text}</Text>
            <Button
              color="white"
              fontWeight="bold"
              size="sm"
              variant="link"
              onClick={(event) => {
                event.stopPropagation();
                handleClick();
              }}
            >
              {buttonText}
            </Button>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};
