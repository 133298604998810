import { useUpdateContractMutation } from '@client/graphql/__generated__/document-operations';
import {
  ContractObject,
  DocumentSectionModuleType,
  DocumentVersionFieldFragment,
  ListingFragment,
  OfferAutofillValuesPayload,
  PublicDocumentVersionFieldFragment,
  PublicListingFragment,
} from '@client/graphql/__generated__/types';
import { debounce, isEqual } from 'lodash';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useLocalStorageState } from '~/common/hooks/useLocalStorageState';
import { DocumentSectionWithChildren } from '~/services/document/components/DocumentSections/types';
import { useDocumentVersionValues } from '~/services/document/components/DocumentVersionMapper/hooks/useDocumentVersionValues';
import { ValuesType } from '~/services/document/components/FormFields/types';

interface UseOfferContractValues {
  listing: ListingFragment | PublicListingFragment;
  contract?: ContractObject;
  autofillValues?: OfferAutofillValuesPayload;
  defaultFieldValues?: ValuesType;
  saveContract?: boolean;
  documentVersionFields?: (
    | PublicDocumentVersionFieldFragment
    | DocumentVersionFieldFragment
  )[];
  onFieldValuesUpdate?: (values: ValuesType) => void;
  onSave?: () => void;
  offerFlowSections?: Record<
    DocumentSectionModuleType,
    DocumentSectionWithChildren | undefined
  >;
}

export const useOfferContractValues = ({
  listing,
  contract,
  documentVersionFields,
  autofillValues,
  defaultFieldValues,
  onFieldValuesUpdate,
  onSave,
  saveContract = false,
  offerFlowSections,
}: UseOfferContractValues) => {
  const prevFieldValues = useRef<ValuesType>();
  // Local storage state for non-saved offer
  const { localStorageState, updateLocalStorageState, saveLocalStorageState } =
    useLocalStorageState<ValuesType>(listing.id);

  // If contract is provided, use the contract field values
  const { updateValues, values } = useDocumentVersionValues({
    offerFlowSections,
    documentVersionFields: documentVersionFields,
    values: contract?.fieldValues as ValuesType,
  });

  const [updateContract, { loading }] = useUpdateContractMutation({
    onCompleted: onSave,
  });

  const save = useCallback(async () => {
    if (contract && saveContract) {
      // Skip if nothing changed
      if (isEqual(contract.fieldValues, values)) {
        return;
      }

      await updateContract({
        variables: {
          input: {
            id: contract.id,
            fieldValues: {
              ...defaultFieldValues,
              ...values,
            },
          },
        },
      });
    } else {
      saveLocalStorageState();
    }
  }, [
    contract,
    defaultFieldValues,
    saveContract,
    saveLocalStorageState,
    updateContract,
    values,
  ]);

  const onFieldValuesUpdateDebounce = useMemo(() => {
    if (onFieldValuesUpdate) return debounce(onFieldValuesUpdate, 1000);
  }, [onFieldValuesUpdate]);

  const updateFieldValues = useCallback(
    (newValues: ValuesType) => {
      if (contract) {
        updateValues(
          { ...autofillValues?.readOnlyAutofillValues, ...newValues },
          { ...autofillValues?.autofillValues, ...defaultFieldValues }
        );
      } else {
        updateLocalStorageState(
          { ...autofillValues?.readOnlyAutofillValues, ...newValues },
          { ...autofillValues?.autofillValues, ...defaultFieldValues }
        );
      }
    },
    [
      autofillValues?.autofillValues,
      autofillValues?.readOnlyAutofillValues,
      contract,
      defaultFieldValues,
      updateLocalStorageState,
      updateValues,
    ]
  );

  const fieldValues = contract ? values : localStorageState;

  useEffect(() => {
    if (
      onFieldValuesUpdateDebounce &&
      !isEqual(fieldValues, {
        ...prevFieldValues.current,
        ...autofillValues?.readOnlyAutofillValues,
      }) &&
      documentVersionFields?.length
    ) {
      onFieldValuesUpdateDebounce(fieldValues);
      prevFieldValues.current = fieldValues;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldValues]);

  return {
    fieldValues: {
      ...fieldValues,
      ...autofillValues?.readOnlyAutofillValues,
    } as ValuesType,
    updateFieldValues,
    save,
    isLoading: loading,
  };
};
