import { Box, Divider, SimpleGrid } from '@chakra-ui/react';
import { ComparablesCard } from '../../components/Storefront/Comparables/ComparablesCard';
import { ListingAgentCard } from '../../components/Storefront/ListingAgent/ListingAgentCard';
import { SellerPreferencesCard } from '../../components/Storefront/SellerPreferences/SellerPreferencesCard';
import { StatusCard } from '../../components/Storefront/Status/StatusCard';
import { ListingDisclaimer } from './ListingDisclaimer';
import { useStorefrontContext } from './StorefrontContext';
import { StorefrontTab } from './StorefrontPage';
interface StorefrontOverviewProps {
  handleChangeTab: (index: number) => void;
}

export const StorefrontOverview = ({
  handleChangeTab,
}: StorefrontOverviewProps) => {
  const { listing, storefrontOverviewContext, listingAnnouncements } =
    useStorefrontContext();

  return (
    <Box p={0} width="100%">
      <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={3}>
        <StatusCard listing={listing} />
        <SellerPreferencesCard
          storefrontOverviewContext={storefrontOverviewContext}
          onLinkClick={() => {
            handleChangeTab(StorefrontTab.SellerPreferences);
          }}
        />
        <ComparablesCard
          listing={listing}
          storefrontOverviewContext={storefrontOverviewContext}
          onLinkClick={() => {
            handleChangeTab(StorefrontTab.Comparables);
          }}
        />
        <ListingAgentCard
          listing={listing}
          listingAnnouncements={listingAnnouncements}
          onLinkClick={(e) => {
            e.stopPropagation();
            handleChangeTab(StorefrontTab.Activity);
          }}
        />
      </SimpleGrid>
      <Divider my={12} />
      <ListingDisclaimer listing={listing} />
    </Box>
  );
};
