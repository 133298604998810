import {
  Button,
  Center,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Spinner,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { useExtractContractMutation } from '@client/graphql/__generated__/document-operations';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const MakeOfferModal = () => {
  const [isProcessing, setIsProcessing] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [extractContract] = useExtractContractMutation();
  const navigate = useNavigate();

  return (
    <>
      <Button colorScheme="teal" width="100%" onClick={onOpen}>
        Make Offer
      </Button>

      <Modal isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalBody>
          <ModalContent>
            {!isProcessing && (
              <VStack alignItems="stretch" px={6} py={4} spacing={6}>
                <Heading as="h4" fontSize="xl" size="sm">
                  Write your offer in 3 minutes with our free autofill
                </Heading>
                <Button colorScheme="teal">Start Offer</Button>

                <VStack>
                  <Heading as="h4" fontSize="xl" size="sm" width="100%">
                    Already prepared your offer?
                  </Heading>
                  <Text color="gray.400">
                    Upload and we’ll magically extract info to present to the
                    Listing Agent
                  </Text>
                </VStack>

                <Button
                  colorScheme="teal"
                  position="relative"
                  variant="outline"
                >
                  Upload
                  <Input
                    bottom={0}
                    cursor="pointer"
                    left={0}
                    opacity={0}
                    position="absolute"
                    right={0}
                    top={0}
                    type="file"
                    onChange={(e) => {
                      setIsProcessing(true);
                      void extractContract({
                        variables: {
                          input: [
                            {
                              file: e.target.files?.[0],
                            },
                          ],
                        },
                      })
                        .then(({ data }) => {
                          navigate(
                            `/offer/${
                              data?.extractContract?.contract?.id as string
                            }`
                          );
                        })
                        .finally(() => {
                          setIsProcessing(false);
                        });
                    }}
                  />
                </Button>
              </VStack>
            )}

            {isProcessing && (
              <VStack alignItems="stretch" px={6} py={4} spacing={6}>
                <Heading as="h4" fontSize="xl" size="sm" textAlign="center">
                  Processing your offer...
                </Heading>

                <Center>
                  <Spinner />
                </Center>
              </VStack>
            )}
          </ModalContent>
        </ModalBody>
      </Modal>
    </>
  );
};
