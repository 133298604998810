import { Flex, Heading, Text } from '@chakra-ui/react';
import { PartyAndBuyerInfoQuery } from '@client/graphql/__generated__/types';

import { useState } from 'react';
import { useOutletContext } from 'react-router';
import { CenterSpinner } from '~/common/components/CenterSpinner';
import { SelectedPartyInfoForm } from '../../components/Buyer/SelectedPartyInfo/SelectedPartyInfoForm';
import { BuyerFormValues } from '../../components/Buyer/types';
import { useUpdatePartyForBuyersAgent } from '../Storefront/OfferFlow/hooks/useUpdatePartyForBuyersAgent';

export const BuyerInfoPage = () => {
  const { partyAndBuyerInfo, refetchPartyAndBuyerInfo } = useOutletContext<{
    partyAndBuyerInfo: PartyAndBuyerInfoQuery['partyAndBuyerInfo'];
    refetchPartyAndBuyerInfo: () => void;
  }>();
  const { handleUpdate: updatePartyForBuyersAgent } =
    useUpdatePartyForBuyersAgent();

  const [isEditing, setIsEditing] = useState(false);
  const [preapprovalFile, setPreapprovalFile] = useState<File | undefined>();

  const onUpdateBuyerInfo = async (values: BuyerFormValues) => {
    await updatePartyForBuyersAgent(
      partyAndBuyerInfo?.id,
      values,
      preapprovalFile
    );

    refetchPartyAndBuyerInfo();
  };

  if (!partyAndBuyerInfo) {
    return <CenterSpinner />;
  }

  return (
    <Flex
      flexDirection="column"
      flexGrow={1}
      h="100vh"
      maxWidth="container.xl"
      mx="auto"
      p={{ base: 5, md: 10 }}
    >
      <Flex flexDirection="column" gap={2} mb={6}>
        <Heading>Buyer Info & Docs</Heading>
        <Text color="gray.500">
          New changes will only take effect on next purchase offer.
        </Text>
      </Flex>

      <SelectedPartyInfoForm
        handleSubmit={onUpdateBuyerInfo}
        isEditing={isEditing}
        party={partyAndBuyerInfo}
        preapprovalFile={
          preapprovalFile ||
          (partyAndBuyerInfo?.partyFinancing?.preapprovalFile?.url
            ? partyAndBuyerInfo?.partyFinancing?.preapprovalFile
            : undefined)
        }
        setIsEditing={setIsEditing}
        setPreapprovalFile={setPreapprovalFile}
      />
    </Flex>
  );
};
