import { Icon, IconProps } from '@chakra-ui/react';
import { FC, memo } from 'react';

export const VerifiedIcon: FC<IconProps> = memo(function UserIcon(props) {
  return (
    <Icon
      fill="none"
      height="15"
      viewBox="0 0 15 15"
      width="15"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15 7.5C15 6.39551 14.3057 5.45215 13.3271 5.08594C13.7578 4.13672 13.585 2.97949 12.8027 2.19727C12.0205 1.41504 10.8633 1.24219 9.91406 1.67285C9.54785 0.694336 8.60449 0 7.5 0C6.39551 0 5.45215 0.694336 5.08594 1.67285C4.13672 1.24219 2.97656 1.41504 2.19727 2.19727C1.41504 2.97949 1.24219 4.13672 1.67285 5.08594C0.694336 5.45215 0 6.39551 0 7.5C0 8.60449 0.694336 9.54785 1.67285 9.91406C1.24219 10.8633 1.41504 12.0234 2.19727 12.8027C2.97656 13.582 4.13379 13.7607 5.08594 13.3271C5.45215 14.3027 6.39551 15 7.5 15C8.60449 15 9.54785 14.3057 9.91406 13.3271C10.8691 13.7607 12.0234 13.582 12.8027 12.8027C13.585 12.0205 13.7578 10.8633 13.3271 9.91406C14.3057 9.54785 15 8.60449 15 7.5ZM10.7578 6.20361L6.91875 10.0119C6.79248 10.1373 6.58828 10.1364 6.46318 10.0102L4.24482 7.77393C4.11943 7.64766 4.12031 7.44346 4.24658 7.31807L5.00918 6.56162C5.13545 6.43623 5.33965 6.43711 5.46504 6.56338L6.6999 7.8082L9.54756 4.9834C9.67383 4.85801 9.87803 4.85889 10.0031 4.98516L10.7596 5.74775C10.885 5.87432 10.8844 6.07852 10.7578 6.20361Z"
        fill="currentColor"
      />
    </Icon>
  );
});
