import { Flex } from '@chakra-ui/react';
import {
  MlsListingObject,
  PublicListingFragment,
  StorefrontSubscriptionReason,
} from '@client/graphql/__generated__/types';
import { useComparablesContext } from '~/apps/consumer/pages/ListingSetup/Comparables/ComparablesContext';
import { useStorefrontContext } from '~/apps/consumer/pages/Storefront/StorefrontContext';
import { ComparablesContainer } from '../../Comparables/styledComponents';
import { ComparableColumn } from '../../Listings/Comparables/ComparableColumns/ComparableColumn';
import { DraggableComparableColumns } from '../../Listings/Comparables/DraggableComparableColumns';
import { SignGuestbookOverlay } from '../SignGuestbookOverlay';

export function ComparablesColumns({
  listing,
}: {
  listing: PublicListingFragment;
}) {
  const { comparables, setComparables, isEditMode, mainMlsListing } =
    useComparablesContext();
  const { isGatedBehindSigningGuestbook } = useStorefrontContext();

  return (
    <ComparablesContainer>
      <ComparableColumn
        isMainListing
        isEditMode={isEditMode}
        mainMlsListing={mainMlsListing}
        mlsListing={listing.mlsListing as MlsListingObject}
      />
      <Flex
        flex={1}
        overflow={isGatedBehindSigningGuestbook ? 'hidden' : 'visible'}
        position="relative"
      >
        {comparables && comparables.length > 0 && (
          <SignGuestbookOverlay
            blurPx={3}
            reason={StorefrontSubscriptionReason.ACCESS_COMPARABLES}
          />
        )}
        <DraggableComparableColumns
          comparables={comparables}
          isEditMode={isEditMode}
          mainMlsListing={mainMlsListing}
          setComparables={setComparables}
        />
      </Flex>
    </ComparablesContainer>
  );
}
