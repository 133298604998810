import { useDocumentListForOfferFlowQuery } from '@client/graphql/__generated__/document-operations';
import {
  useListingDocumentTypesQuery,
  useListingDocumentUploadsQuery,
} from '@client/graphql/__generated__/main-operations';
import {
  DocumentType,
  ListingDocumentTypeEnum,
  ListingFragment,
} from '@client/graphql/__generated__/types';
import { useMemo } from 'react';
import { getFilteredListingDocumentTypes } from '~/apps/consumer/utils/listingDocuments.utils';

export const useListingDocumentQueries = (listing: ListingFragment) => {
  const { data: listingContractDocument } = useDocumentListForOfferFlowQuery({
    variables: {
      filters: {
        usStates: [listing?.property?.state],
        documentType: DocumentType.PROPERTY_PURCHASE_CONTRACT,
      },
      activeDocumentVersion: true,
      perPage: 1,
    },
  });

  const {
    data: existingDocData,
    loading: loadingExistingDocs,
    refetch: refetchExistingDocs,
  } = useListingDocumentUploadsQuery({
    variables: {
      input: {
        listingId: listing?.id,
      },
    },
  });

  const { data: docTypeData, loading: loadingDocTypes } =
    useListingDocumentTypesQuery({
      variables: {
        input: {
          usState: listing?.property?.state,
        },
      },
    });

  const disclosureAndAgentNegotiationDocumentTypes = useMemo(
    () =>
      getFilteredListingDocumentTypes(docTypeData?.listingDocumentTypes, [
        ListingDocumentTypeEnum.DISCLOSURE,
        ListingDocumentTypeEnum.AGENT_NEGOTIATION,
      ]),
    [docTypeData?.listingDocumentTypes]
  );

  const additionalDocumentTypes = useMemo(
    () =>
      getFilteredListingDocumentTypes(docTypeData?.listingDocumentTypes, [
        ListingDocumentTypeEnum.OTHER,
      ]),
    [docTypeData?.listingDocumentTypes]
  );

  const agentNegotiationDocumentTypes = useMemo(
    () =>
      getFilteredListingDocumentTypes(docTypeData?.listingDocumentTypes, [
        ListingDocumentTypeEnum.AGENT_NEGOTIATION,
      ]),
    [docTypeData?.listingDocumentTypes]
  );

  return {
    existingDocs: existingDocData?.listingDocumentUploads,
    listingContractDocument:
      listingContractDocument?.publicDocumentList?.results?.[0],
    disclosureAndAgentNegotiationDocumentTypes,
    additionalDocumentTypes,
    agentNegotiationDocumentTypes,
    loading: loadingExistingDocs || loadingDocTypes,
    refetchExistingDocs,
  };
};
