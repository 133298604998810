import { Box, Center, Flex, Icon } from '@chakra-ui/react';
import { ArrowUpRight, Pen } from 'lucide-react';
import { NavLink } from 'react-router-dom';
import { NewText } from '~/common/components/NewText/NewText';
import { useAppContext } from '~/services/main/contexts/AppContext';
import { getAgentProfilePage } from '../../utils/routes.utils';

export const PublicProfileFeatureButton = () => {
  const { user } = useAppContext();

  return (
    <Box
      bg="purpleGradient"
      borderColor="transparent"
      h="40px"
      maxW="300px"
      minW="270px"
      p="1px"
      rounded="sm"
      w="100%"
    >
      <Center bg="indigo.800" h="full" rounded="sm" w="full">
        <Flex
          alignItems="flex-start"
          justifyContent="space-between"
          p={4}
          w="full"
        >
          <Flex alignItems="center" justifyContent="space-between" width="100%">
            <Flex alignItems="center" flexGrow={1} width="100%">
              Public profile
              <NewText />
            </Flex>
          </Flex>
          <Flex
            alignItems="center"
            as={NavLink}
            color="whiteAlpha.600"
            gap={1}
            px={2}
            to={getAgentProfilePage(user?.userName as string)}
          >
            <Icon as={ArrowUpRight} />
            View
          </Flex>
          <Flex
            alignItems="center"
            as={NavLink}
            color="whiteAlpha.600"
            gap={1}
            px={2}
            to="../account"
          >
            <Icon as={Pen} size="xs" />
            Edit
          </Flex>
        </Flex>
      </Center>
    </Box>
  );
};
