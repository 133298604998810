import { useOfferAutofillValuesFromContractQuery } from '@client/graphql/__generated__/main-operations';
import { ExtractedContractBuyerInfos } from '@client/graphql/__generated__/types';
import React, { createContext, useContext } from 'react';
import { BuyerInfoInput } from './hooks/useOfferFlow';

export type OfferContractContextValue = {
  buyerInfo?: BuyerInfoInput;
};

export const OfferContractContext = createContext<OfferContractContextValue>(
  {} as OfferContractContextValue
);

export const useOfferContractContext = () => {
  return useContext(OfferContractContext);
};

export const OfferContractContextProvider = ({
  children,
  offerId,
}: {
  children: React.ReactNode;
  offerId: string;
}) => {
  const { data } = useOfferAutofillValuesFromContractQuery({
    variables: {
      offerId,
    },
  });

  const extractedContractBuyerInfos =
    data?.offerAutofillVariableValuesFromContract?.extractedBuyerInfos;

  const buyerInfo = toBuyerInfoInput(extractedContractBuyerInfos);

  return (
    <OfferContractContext.Provider value={{ buyerInfo }}>
      {children}
    </OfferContractContext.Provider>
  );
};

const toBuyerInfoInput = (
  extractedBuyerInfos?: ExtractedContractBuyerInfos
): BuyerInfoInput | undefined => {
  if (!extractedBuyerInfos) {
    return undefined;
  }

  return {
    buyerName: extractedBuyerInfos.primary?.fullName,
    buyerEmail: undefined,
    entityName: extractedBuyerInfos.primary?.entity?.name,
    entityTitle: extractedBuyerInfos.primary?.entity?.title,
    coBuyerName: extractedBuyerInfos.secondary[0]?.fullName,
    coBuyerEmail: undefined,
  };
};
