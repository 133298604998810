export function enumToLabel(enumValue: string, shouldCapitalize = true) {
  return enumValue
    .split('_')
    .map((word: string) => {
      const lower = word.toLowerCase();
      if (shouldCapitalize) {
        const firstLetter = lower.charAt(0).toUpperCase();

        return firstLetter + lower.substring(1);
      }

      return lower;
    })
    .join(' ');
}
