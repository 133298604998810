import { Text } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { FC, memo, useMemo } from 'react';
import { ValidationTag } from './ValidationTag';

interface DaysTagProps {
  value: string;
  compareValue?: string;
  size?: string;
}

export const DaysTag: FC<DaysTagProps> = memo(function DaysTag({
  value,
  compareValue,
  size,
}) {
  const valueDate = useMemo(() => {
    const date = dayjs(value);

    return date.isValid() ? date : null;
  }, [value]);

  const compareValueDate = useMemo(() => {
    const date = dayjs(compareValue as string);

    return compareValue && date.isValid() ? date : undefined;
  }, [compareValue]);

  const diffDays = useMemo(() => {
    return valueDate?.diff(compareValueDate, 'days') || 0;
  }, [valueDate, compareValueDate]);

  return (
    <ValidationTag isError={diffDays > 0} isSuccess={diffDays < 0} size={size}>
      {diffDays < 0 ? '-' : '+'}
      {Math.abs(diffDays)} <Text fontSize="xs">&nbsp;days</Text>
    </ValidationTag>
  );
});
