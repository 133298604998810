import {
  useContractValidationsLazyQuery,
  useContractVariableValuesQuery,
  useDocumentListForOfferFlowQuery,
  useDocumentSectionListForOfferFlowQuery,
  useDocumentVersionListForOfferFlowQuery,
} from '@client/graphql/__generated__/document-operations';
import {
  useGetOfferForBuyersAgentQuery,
  useGetOfferForListingAgentQuery,
  useLatestUnsubmittedOfferForBuyersAgentQuery,
  useOfferAutofillValuesQuery,
  useOfferAutofillVariableValuesQuery,
  usePublicListingDocumentTypesQuery,
} from '@client/graphql/__generated__/main-operations';
import {
  ContractObject,
  DocumentSectionCategory,
  DocumentType,
  ListingDocumentTypeEnum,
  ListingFragment,
  OfferKeyFieldsObject,
  PublicListingDocumentTypeFragment,
  PublicListingFragment,
  UsState,
  VariableSource,
} from '@client/graphql/__generated__/types';
import { useMemo } from 'react';
import { useParams } from 'react-router';
import { useContractDocumentsDocumentVersions } from '~/apps/consumer/hooks/useContractDocumentsDocumentVersions';
import { getFilteredListingDocumentTypes } from '~/apps/consumer/utils/listingDocuments.utils';
import { useDocumentSectionsByModuleType } from '~/services/document/components/DocumentSections/hooks/useDocumentSectionsByModuleType';
import { DocumentSection } from '~/services/document/components/DocumentSections/types';

interface UseOfferFlowQueriesOptions {
  listing: ListingFragment | PublicListingFragment;
  activeDocumentId?: string;
  isAgentVerified: boolean;
  isListingAgentViewingOffer?: boolean;
  isBuyersAgentViewingOffer?: boolean;
  waitToLoadQueries?: boolean;
}
export const useOfferFlowQueries = ({
  listing,
  activeDocumentId,
  isAgentVerified,
  isListingAgentViewingOffer,
  isBuyersAgentViewingOffer,
  waitToLoadQueries = false,
}: UseOfferFlowQueriesOptions) => {
  const { offerId } = useParams();
  // Get all property purchase contract document and based on us states,
  // it can have more than 1 property purchase contract document
  const { data: documentList } = useDocumentListForOfferFlowQuery({
    variables: {
      isAgentVerified,
      activeDocumentVersion: true,
      filters: {
        documentType: DocumentType.PROPERTY_PURCHASE_CONTRACT,
        usStates: [listing.property.state],
      },
    },
    skip: !listing || waitToLoadQueries,
  });

  // Documents are filtered based on us states, so we can have multiple documents
  const documents = isAgentVerified
    ? documentList?.documentList?.results
    : documentList?.publicDocumentList?.results;

  // Get active document and its active version
  const activeDocument = documents?.find(({ id }) => id === activeDocumentId);

  const { data: activeDocumentVersions } =
    useDocumentVersionListForOfferFlowQuery({
      variables: {
        isAgentVerified,
        documentVersionFields: false,
        previewImages: true,
        skipSecondaryVersions: isListingAgentViewingOffer,
        filters: {
          id: activeDocument?.activeDocumentVersion?.id,
        },
      },
      skip: !activeDocument?.activeDocumentVersion?.id || waitToLoadQueries,
    });

  // Get active document version based on agent verification
  const activeDocumentVersion = isAgentVerified
    ? activeDocumentVersions?.documentVersionList?.results?.[0]
    : activeDocumentVersions?.publicDocumentVersionList?.results?.[0];

  const {
    data: offerForListingAgentData,
    loading: offerForListingAgentLoading,
  } = useGetOfferForListingAgentQuery({
    variables: {
      input: {
        offerId: offerId as string,
      },
    },
    skip: !isListingAgentViewingOffer || !offerId || waitToLoadQueries,
  });

  const { data: offerForBuyersAgentData, loading: offerForBuyersAgentLoading } =
    useGetOfferForBuyersAgentQuery({
      variables: {
        input: {
          offerId: offerId as string,
        },
      },
      skip: !offerId || !isBuyersAgentViewingOffer || waitToLoadQueries,
    });

  const {
    data: latestOfferData,
    loading: latestUnsubmittedOfferLoading,
    refetch: refetchLatestOffer,
  } = useLatestUnsubmittedOfferForBuyersAgentQuery({
    variables: {
      input: {
        listingId: listing.id,
      },
      isAgentVerified,
    },
    skip:
      isListingAgentViewingOffer ||
      isBuyersAgentViewingOffer ||
      waitToLoadQueries,
  });

  const latestOffer = useMemo(() => {
    if (isListingAgentViewingOffer) {
      return offerForListingAgentData?.getOfferForListingAgent;
    }

    if (isBuyersAgentViewingOffer) {
      return offerForBuyersAgentData?.getOfferForBuyersAgent;
    }

    return isAgentVerified
      ? latestOfferData?.latestUnsubmittedOfferForBuyersAgent
      : latestOfferData?.latestUnsubmittedOfferForUnauthenticatedBuyersAgent;
  }, [
    isAgentVerified,
    latestOfferData?.latestUnsubmittedOfferForBuyersAgent,
    latestOfferData?.latestUnsubmittedOfferForUnauthenticatedBuyersAgent,
    offerForBuyersAgentData?.getOfferForBuyersAgent,
    offerForListingAgentData?.getOfferForListingAgent,
    isListingAgentViewingOffer,
    isBuyersAgentViewingOffer,
  ]);

  const documentVersionIds = useContractDocumentsDocumentVersions(
    latestOffer?.contract as ContractObject
  ).map((doc) => doc.id);

  // Lazy Fetch autofill values
  const {
    data: autofillValues,
    loading: autofillValuesLoading,
    refetch,
  } = useOfferAutofillValuesQuery({
    variables: {
      documentVersionIds,
      listingId: listing.id,
      fieldValues: latestOffer?.contract?.fieldValues || {},
      offerId: latestOffer?.id as string,
    },
    skip:
      !activeDocumentVersion?.id ||
      !listing.id ||
      !latestOffer?.id ||
      !latestOffer?.contract?.fieldValues ||
      waitToLoadQueries,
  });

  const { data: listingDocumentTypes } = usePublicListingDocumentTypesQuery({
    variables: {
      input: {
        usState: (listing.mlsListing?.address?.state ||
          UsState.GLOBAL) as UsState,
      },
    },
  });

  // Offer flow key terms sections
  const { data: offerFlowSectionsData, loading: offerFlowSectionsDataLoading } =
    useDocumentSectionListForOfferFlowQuery({
      variables: {
        filters: {
          documentVersionId: activeDocumentVersion?.id,
          documentSectionCategory: DocumentSectionCategory.OFFER_FLOW,
        },
      },
      skip: !activeDocumentVersion?.id || waitToLoadQueries,
    });

  const offerFlowSections = useDocumentSectionsByModuleType({
    documentSections: offerFlowSectionsData?.publicDocumentSectionList
      ?.results as DocumentSection[],
  });

  const disclosureDocumentTypes = useMemo(
    () =>
      getFilteredListingDocumentTypes(
        listingDocumentTypes?.publicListingDocumentTypes,
        [ListingDocumentTypeEnum.DISCLOSURE]
      ) as PublicListingDocumentTypeFragment[],
    [listingDocumentTypes]
  );

  const agentNegotiationDocumentTypes = useMemo(
    () =>
      getFilteredListingDocumentTypes(
        listingDocumentTypes?.publicListingDocumentTypes,
        [ListingDocumentTypeEnum.AGENT_NEGOTIATION]
      ) as PublicListingDocumentTypeFragment[],
    [listingDocumentTypes]
  );

  // Offer key fields
  const { data, loading: offerKeyFieldsLoading } =
    useContractVariableValuesQuery({
      variables: {
        input: [
          {
            contractId: latestOffer?.contract?.id as string,
            variableSource: VariableSource.INDIGO_OFFER,
          },
        ],
      },
      skip: !latestOffer?.contract?.id || waitToLoadQueries,
    });

  const offerKeyFields = data?.contractVariableValues?.[0]
    ?.contractVariableValues?.offerKeyFields as OfferKeyFieldsObject;

  // Offer variable values
  const {
    data: offerAutofillVariableValues,
    refetch: refetchOfferAutofillVariableValues,
  } = useOfferAutofillVariableValuesQuery({
    variables: {
      offerId: latestOffer?.id as string,
    },
    skip: !latestOffer?.id || waitToLoadQueries,
  });

  // Contract validations query
  const [contractValidationsQuery, { data: contractValidationsData }] =
    useContractValidationsLazyQuery();

  return {
    documents,
    activeDocument,
    activeDocumentVersion,
    queriesLoading:
      !documents ||
      !activeDocument ||
      !activeDocumentVersion ||
      latestUnsubmittedOfferLoading ||
      offerForListingAgentLoading ||
      offerForBuyersAgentLoading,
    autofillValuesLoading,
    autofillValues: autofillValues?.offerAutofillValues || {},
    fetchAutofillValues: refetch,
    latestOffer,
    latestOfferLoading:
      latestUnsubmittedOfferLoading ||
      offerForListingAgentLoading ||
      offerForBuyersAgentLoading,
    refetchLatestOffer,
    refetchOfferAutofillVariableValues,
    disclosureDocumentTypes,
    agentNegotiationDocumentTypes,
    offerFlowSections,
    offerFlowSectionsDataLoading,
    contractValidationsQuery,
    contractValidations: contractValidationsData,
    offerAutofillVariableValues:
      offerAutofillVariableValues?.offerAutofillVariableValues?.[0],
    offerKeyFields,
    offerKeyFieldsLoading,
  };
};
