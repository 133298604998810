import {
  Card,
  CardBody,
  CardHeader,
  Flex,
  Skeleton,
  Text,
} from '@chakra-ui/react';
import { useBuyerOfferAnalysisContext } from '../BuyerOfferAnalysisContext';
import { ContingenciesRow } from './ContingenciesRow';

export const ContingenciesTable = () => {
  const { offerRecs, offerRecsLoading } = useBuyerOfferAnalysisContext();
  const contingencies = offerRecs?.contingencies;

  if (!contingencies || offerRecsLoading) {
    return <Skeleton height="300px" width="100%" />;
  }

  return (
    <Card width="100%">
      <CardHeader
        borderBottom="1px solid"
        borderBottomColor="whiteAlpha.200"
        pb={4}
      >
        <Flex alignItems="center" gap={6} justifyContent="space-between">
          <Flex basis={0} flexGrow={1}>
            <Text color="whiteAlpha.600">Contingencies</Text>
          </Flex>
          <Flex basis={0} flexGrow={2}>
            <Text color="whiteAlpha.600">Market Data</Text>
          </Flex>
          <Flex basis={0} flexGrow={2}>
            <Text color="whiteAlpha.600">Your Recommendations</Text>
          </Flex>
        </Flex>
      </CardHeader>
      <CardBody p={0}>
        {contingencies?.map((contingency) => {
          if (contingency.__typename === 'ContingencyError') {
            return (
              <ContingenciesRow
                key={contingency.type}
                errorMessage={contingency.reason}
                label={contingency.type?.split('_').join(' ').toLowerCase()}
              />
            );
          }

          const confidence =
            contingency.context?.source.__typename === 'IndigoOffersSource'
              ? contingency.context?.source?.metadata?.confidence
              : undefined;

          const distanceMiles =
            contingency.context?.source.__typename === 'IndigoOffersSource'
              ? contingency.context?.source?.metadata?.distanceMiles
              : undefined;

          const timePeriodMonths =
            contingency.context?.source.__typename === 'IndigoOffersSource'
              ? contingency.context?.source?.metadata?.timePeriodMonths
              : undefined;

          return (
            <ContingenciesRow
              key={contingency.type}
              confidence={confidence}
              distanceMiles={distanceMiles}
              label={contingency.type?.split('_').join(' ').toLowerCase()}
              marketData={contingency.context?.marketData}
              source={contingency.context?.source?.type}
              timePeriodMonths={timePeriodMonths}
            />
          );
        })}
      </CardBody>
    </Card>
  );
};
