import { Button, Card, Center, Icon, Text } from '@chakra-ui/react';
import { PenToolIcon } from 'lucide-react';
import { useOpenHouseSignContext } from './OpenHouseSignContext';

export const SignCompleteScreen = () => {
  const { setSignUpCompleted } = useOpenHouseSignContext();

  return (
    <Card px={4} py={8}>
      <Center
        alignItems="center"
        alignSelf="center"
        flexDirection="column"
        gap={4}
        justifyContent="center"
      >
        <Icon as={PenToolIcon} boxSize={8} color="green.500" mb={4} />
        <Text fontSize="xl" fontWeight="500" textAlign="center">
          You're signed in! Enjoy the open house.
        </Text>
        <Button
          w="100%"
          onClick={() => {
            setSignUpCompleted(false);
            window.history.back();
          }}
        >
          Done
        </Button>
      </Center>
    </Card>
  );
};
