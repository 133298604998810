import { useFollowedListingsQuery } from '@client/graphql/__generated__/main-operations';
import { ListingObject } from '@client/graphql/__generated__/types';
import { PropsWithChildren, useEffect, useState } from 'react';
import { ActivityPageContext } from '../../contexts/ActivityContext';

export const ActivityPageProvider = ({ children }: PropsWithChildren) => {
  const { data } = useFollowedListingsQuery({
    variables: {
      preferenceValues: false,
    },
  });

  // the actively selected listing on the activity page
  const [activeListing, setActiveListing] = useState<ListingObject | undefined>(
    data?.followedListings?.[0] as ListingObject | undefined
  );

  useEffect(() => {
    if (data?.followedListings?.length) {
      setActiveListing(data?.followedListings[0] as ListingObject);
    }
  }, [data?.followedListings]);

  const value = {
    followedListingsForCurrentUser: data?.followedListings as
      | ListingObject[]
      | undefined,
    activeListing,
    setActiveListing,
  };

  return (
    <ActivityPageContext.Provider value={value}>
      {children}
    </ActivityPageContext.Provider>
  );
};
