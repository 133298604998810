import { DarkMode } from '@chakra-ui/react';
import { FC, MutableRefObject, useEffect, useRef } from 'react';
import { CenterSpinner } from '~/common/components/CenterSpinner';
import { DocumentSignatureMapper } from '~/services/document/components/DocumentSignatureMapper';
import { Signer } from '~/services/document/components/DocumentSignatureMapper/DocumentSignatureMapper';
import { DropboxSignEmbedded } from '../DropboxSignEmbedded';
import { usePrepareIndigoContract } from './hooks/usePrepareIndigoContract';

export interface PrepareIndigoContractProps {
  indigoContractId: string;
  mutationRef: MutableRefObject<(() => Promise<void>) | undefined>;
  onReady: () => void;
  onClose: () => void;
}

export const PrepareIndigoContract: FC<PrepareIndigoContractProps> = ({
  indigoContractId,
  mutationRef,
  onReady,
  onClose,
}) => {
  const isReady = useRef(false);
  const {
    formFields,
    indigoContract,
    values,
    updateValues,
    setSignatureFormFields,
    signatureFormFields,
    isLoading,
    embeddedUrl,
  } = usePrepareIndigoContract({ indigoContractId, mutationRef });

  useEffect(() => {
    if (signatureFormFields && !isReady.current) {
      isReady.current = true;
      onReady();
    }
  }, [onReady, signatureFormFields]);

  if (isLoading) {
    return <CenterSpinner />;
  }

  if (indigoContract?.contract && !embeddedUrl) {
    return (
      <DocumentSignatureMapper
        contractSignatureFieldArgs={{
          contractId: indigoContract?.contract?.id,
          partyId: indigoContract?.party?.id,
          buyersAgentSignatures: true,
          buyerSignatures: true,
        }}
        formFields={formFields}
        pdfFiles={[
          {
            url: `/documents/contracts/${indigoContract.contract.id}/blank-pdf`,
          },
        ]}
        signers={
          [
            indigoContract?.party?.buyersAgent?.user,
            indigoContract?.party?.primaryConsumer,
          ]
            .concat(indigoContract?.party?.secondaryConsumers)
            .filter(Boolean) as Signer[]
        }
        values={values}
        onSignatureFormFieldsChange={setSignatureFormFields}
        onValuesChange={updateValues}
      />
    );
  }

  if (embeddedUrl) {
    return (
      <DarkMode>
        <DropboxSignEmbedded embeddedUrl={embeddedUrl} onClose={onClose} />
      </DarkMode>
    );
  }

  return null;
};
