import { Button, Flex, HStack, Heading } from '@chakra-ui/react';
import { useIndigoContractsForCurrentUserQuery } from '@client/graphql/__generated__/main-operations';
import { DocumentType } from '@client/graphql/__generated__/types';
import { FC } from 'react';
import { useParams } from 'react-router';
import { NavLink } from 'react-router-dom';
import { IndigoContractDetailCard } from '~/apps/consumer/components/IndigoContract/IndigoContractDetailCard';
import { Container } from '~/apps/consumer/components/Layouts/Container';
import { SIDEBAR_WIDTH_COLLAPSED } from '~/apps/consumer/components/PageDetailLayout/SidebarItem';
import { CenterSpinner } from '~/common/components/CenterSpinner';

export const AgencyAgreementsPage: FC = () => {
  const { buyerPartyId } = useParams();
  const { data, loading } = useIndigoContractsForCurrentUserQuery({
    variables: {
      filters: {
        partyId: buyerPartyId,
        documentType: DocumentType.AGENCY_AGREEMENT,
      },
      includeContractDocuments: true,
      includeFieldValues: true,
    },
    skip: !buyerPartyId,
  });

  const indigoContracts = data?.indigoContractsForCurrentUser?.results;

  if (!indigoContracts && loading) {
    return <CenterSpinner />;
  }

  return (
    <Container width={`calc(100vw - ${SIDEBAR_WIDTH_COLLAPSED}px)`}>
      <Flex justifyContent="space-between" mb={5}>
        <Heading
          as="h2"
          display={{ base: 'none', md: 'block' }}
          size="sm"
          whiteSpace="normal"
        >
          Buyer Agreements
        </Heading>

        <Button
          as={NavLink}
          size="xs"
          to="/dashboard?state=new-agency-agreement"
        >
          + New
        </Button>
      </Flex>

      <HStack flexWrap="wrap" spacing={4}>
        {indigoContracts?.map((contract) => (
          <IndigoContractDetailCard
            key={contract.id}
            indigoContract={contract}
          />
        ))}
      </HStack>
    </Container>
  );
};
