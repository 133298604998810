import { HStack, Heading } from '@chakra-ui/react';
import { FC, memo } from 'react';

interface OfferFlowHeaderProps {
  title: string;
  children: React.ReactNode;
}

export const OfferFlowHeader: FC<OfferFlowHeaderProps> = memo(
  function OfferFlowHeader({ title, children }) {
    return (
      <HStack justifyContent="space-between" p={5}>
        <Heading as="h3" fontSize="md" size="xs">
          {title}
        </Heading>
        <HStack>{children}</HStack>
      </HStack>
    );
  }
);
