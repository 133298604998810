import { Input, Portal, useColorMode } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { useField } from 'formik';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {
  DEFAULT_DATETIME_FORMAT,
  DEFAULT_DATE_FORMAT,
} from '~/common/utils/formatter';

export interface DatePickerFieldProps extends ReactDatePickerProps {
  name: string;
  placeholder?: string;
  isDisabled?: boolean;
  onDateChange?: (value: Date | null) => Promise<void>;
  showTimeSelect?: boolean;
  dateFormat?: string;
}

export const DatePickerField = ({
  name,
  onDateChange,
  isDisabled,
  placeholder,
  showTimeSelect = false,
  dateFormat,
}: DatePickerFieldProps) => {
  const { colorMode } = useColorMode();
  const [field, meta, helpers] = useField(name); // eslint-disable-line @typescript-eslint/no-unused-vars
  const selected =
    ((field.value as string) && dayjs(field.value as string).toDate()) || null;

  const defaultDateFormat = showTimeSelect
    ? DEFAULT_DATETIME_FORMAT
    : DEFAULT_DATE_FORMAT;
  const value =
    ((field.value as string) &&
      dayjs(field.value as string).format(
        dateFormat ? dateFormat : defaultDateFormat
      )) ||
    undefined;

  const timeProps = showTimeSelect
    ? {
        showTimeSelect: true,
        timeIntervals: 15,
      }
    : {};

  return (
    <DatePicker
      {...field}
      customInput={<Input isDisabled={isDisabled} />}
      disabled={isDisabled}
      placeholderText={placeholder}
      popperClassName={colorMode === 'dark' ? 'dark' : undefined}
      popperContainer={Portal}
      selected={selected}
      value={value}
      onChange={(value) => {
        void helpers.setValue(value);
        void onDateChange?.(value);
      }}
      {...timeProps}
    />
  );
};
