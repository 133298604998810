import { useSearchParams } from 'react-router-dom';

export const useSignGuestbookState = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const isSignOpenHouseGuestbook =
    (!!searchParams.get('utm_source') &&
      searchParams.get('utm_content')?.includes('oh-gb')) ||
    false;
  const isSigningFromOpenHouseQR =
    isSignOpenHouseGuestbook && searchParams.get('utm_source') === 'qr';

  const openHouseId = searchParams.get('oh-id');

  const clearOpenHouseSearchParams = () => {
    setSearchParams((prev) => {
      prev.delete('utm_source');
      prev.delete('utm_content');
      prev.delete('oh-id');

      return prev;
    });
  };

  return {
    isSignOpenHouseGuestbook,
    isSigningFromOpenHouseQR,
    openHouseId,
    clearOpenHouseSearchParams,
  };
};
