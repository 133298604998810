import { useMemo } from 'react';
import { FormField } from '../types';

export const useFormFieldsMap = (formFields: FormField[]) => {
  return useMemo(() => {
    return formFields.reduce(
      (output, field) => {
        if (!output[field.mappingKey]) {
          output[field.mappingKey] = field;
        }

        return output;
      },
      {} as Record<string, FormField>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(formFields)]);
};
