import { Container, Text, VStack } from '@chakra-ui/react';
import { useIndigoContractsForCurrentUserQuery } from '@client/graphql/__generated__/main-operations';
import { DocumentType, TourType } from '@client/graphql/__generated__/types';
import { MapPinHouse, Signature, SquareChartGanttIcon } from 'lucide-react';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { FC, useEffect } from 'react';
import { Outlet } from 'react-router';
import { BetaTag } from '~/common/components/BetaTag';
import { useIsMobile } from '~/common/hooks/useIsMobile';
import { useInfiniteQuery } from '~/services/document/hooks/useInfiniteQuery';
import { useAppContext } from '~/services/main/contexts/AppContext';
import { AddBuyerOfferAnalysisModal } from '../../components/BuyerOfferAnalysis/AddBuyerOfferAnalysisModal/AddBuyerOfferAnalysisModal';
import { ActionCard } from '../../components/Dashboard/ActionCard';
import { DashboardTourModal } from '../../components/Dashboard/DashboardTourModal/DashboardTourModal';
import { SendIndigoContractModal } from '../../components/IndigoContract/SendIndigoContractModal';
import { CONTAINER_PADDINGS } from '../../components/Layouts/Container';
import { FindPropertyModal } from '../../components/MakeOffer/FindPropertyModal';
import { SIDEBAR_WIDTH_COLLAPSED } from '../../components/PageDetailLayout/SidebarItem';
import { useViewedTours } from '../../hooks/useViewedTours';
import { getIsRegisteredConsumer } from '../../utils/roles.utils';
import { ActivitySection } from './ActivitySection';
import { AgencyAgreementsWidget } from './AgencyAgreementsWidget';
import { useMainDashboardLeftMargin } from './hooks/useMainDashboardLeftMargin';
import { useMainDashboardModals } from './hooks/useMainDashboardModals';
import { MainDashboardHeader } from './MainDashboardHeader';
import { MainDashboardWidgetsSection } from './MainDashboardWidgetsSection';
import { OffersWidget } from './OffersWidget';
import { OpenHouseOverviewModal } from './OpenHouseOverviewModal';
import { OverflowContainer } from './OverflowContainer';
import { PrioritizedListingsSection } from './PrioritizedListingsSection';

export const MainDashboardPage: FC = () => {
  // Hooks
  const isMobile = useIsMobile();
  const { user } = useAppContext();
  const isConsumer = getIsRegisteredConsumer(user);
  const { leftMargin, flexRef } = useMainDashboardLeftMargin();
  const { viewedTours, getHasViewedTour } = useViewedTours();
  const hasViewedTour = getHasViewedTour(TourType.DASHBOARD);
  const isBuyerOfferAnalysisEnabled = useFeatureFlagEnabled('offer-coach');

  // Queries
  const [indigoContractsRef, indigoContractsQuery] = useInfiniteQuery(
    useIndigoContractsForCurrentUserQuery,
    {
      variables: {
        filters: { documentType: DocumentType.AGENCY_AGREEMENT },
        perPage: 6,
      },
    }
  );

  // Modals
  const {
    agencyAgreementModal,
    addBuyerOfferAnalysisModal,
    dashboardTourModal,
    findPropertyModal,
    openHouseOverviewModal,
  } = useMainDashboardModals();

  // Effects
  useEffect(() => {
    if (!hasViewedTour) {
      dashboardTourModal.onOpen();
    } else {
      dashboardTourModal.onClose();
    }
  }, [viewedTours, hasViewedTour, dashboardTourModal]);

  // Data
  const actionCardsData = [
    isBuyerOfferAnalysisEnabled
      ? {
          // TODO BLO-1110: FINALIZE COPY
          icon: <SquareChartGanttIcon />,
          description: 'Create a buyer offer analysis',
          header: 'Offer Coach',
          onClick: addBuyerOfferAnalysisModal.onOpen,
        }
      : undefined,
    {
      icon: <MapPinHouse />,
      description: 'Share and collect attendees',
      header: 'Manage Open Houses',
      onClick: openHouseOverviewModal.onOpen,
    },
    {
      icon: <Signature />,
      description: 'Auto-filled and validated',
      header: 'Write An Offer For A Buyer',
      onClick: findPropertyModal.onOpen,
    },
    {
      icon: <Signature />,
      description: 'Personalized, and adaptable',
      header: (
        <Text>
          Generate A Buyer Agreement <BetaTag as="sup" />
        </Text>
      ),
      onClick: agencyAgreementModal.onOpen,
    },
  ].filter((actionCard) => !!actionCard);

  // Render helpers
  const renderActionCards = () => (
    <OverflowContainer
      gap={4}
      leftMargin={leftMargin}
      mb={{
        base: 6,
        lg: 10,
      }}
    >
      {actionCardsData.map((actionCard, index) => (
        <ActionCard key={index} {...actionCard} />
      ))}
    </OverflowContainer>
  );

  const renderWidgets = () => (
    <MainDashboardWidgetsSection>
      <OffersWidget onNewOffer={findPropertyModal.onOpen} />
      {!isConsumer && (
        <AgencyAgreementsWidget
          query={indigoContractsQuery}
          queryRef={indigoContractsRef}
          onNewAgreement={agencyAgreementModal.onOpen}
        />
      )}
    </MainDashboardWidgetsSection>
  );

  const renderModals = () => (
    <>
      <SendIndigoContractModal
        buttonText="Generate agreement docs"
        documentType={DocumentType.AGENCY_AGREEMENT}
        isOpen={agencyAgreementModal.isOpen}
        title="New Buyer Agreement"
        titleTag={<BetaTag />}
        onClose={agencyAgreementModal.onClose}
        onContinue={agencyAgreementModal.onContinue}
      />
      <FindPropertyModal
        isOpen={findPropertyModal.isOpen}
        onClose={findPropertyModal.onClose}
        onSelect={findPropertyModal.handleSelect}
      />
      <DashboardTourModal
        isOpen={dashboardTourModal.isOpen}
        onClose={dashboardTourModal.onClose}
      />
      <OpenHouseOverviewModal
        isOpen={openHouseOverviewModal.isOpen}
        onClose={openHouseOverviewModal.onClose}
      />
      {isBuyerOfferAnalysisEnabled && (
        <AddBuyerOfferAnalysisModal
          isOpen={addBuyerOfferAnalysisModal.isOpen}
          onClose={addBuyerOfferAnalysisModal.onClose}
        />
      )}
    </>
  );

  return (
    <Container
      centerContent
      maxW="container.xl"
      w={isMobile ? 'full' : `calc(100vw - ${SIDEBAR_WIDTH_COLLAPSED})`}
      {...CONTAINER_PADDINGS}
    >
      <VStack alignItems="stretch" width="full">
        <MainDashboardHeader
          flexRef={flexRef}
          hasViewedTour={hasViewedTour}
          user={user}
        />
        {renderActionCards()}
        {!isConsumer && <PrioritizedListingsSection leftMargin={leftMargin} />}
        {renderWidgets()}
        <ActivitySection />
      </VStack>
      <Outlet context={{ refetch: indigoContractsQuery.refetch }} />
      {renderModals()}
    </Container>
  );
};
