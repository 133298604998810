import { useDocumentSectionListForOfferFlowQuery } from '@client/graphql/__generated__/document-operations';
import {
  useEventsForOfferQuery,
  useGetOfferForBuyersAgentQuery,
  usePublicListingQuery,
} from '@client/graphql/__generated__/main-operations';
import { DocumentSectionCategory } from '@client/graphql/__generated__/types';
import { useDocumentSectionsByModuleType } from '~/services/document/components/DocumentSections/hooks/useDocumentSectionsByModuleType';
import { DocumentSection } from '~/services/document/components/DocumentSections/types';

export const useBuyerPartyQueries = ({ offerId }: { offerId: string }) => {
  const { data: offerData, loading: offerLoading } =
    useGetOfferForBuyersAgentQuery({
      variables: {
        input: {
          offerId,
        },
      },
      skip: !offerId,
    });

  const offer = offerData?.getOfferForBuyersAgent;
  const propertySlug = offer?.listing?.property?.slug;
  const { data: listingData, loading: listingLoading } = usePublicListingQuery({
    variables: {
      input: {
        propertySlug: propertySlug || '',
      },
    },
    skip: !propertySlug,
  });

  const listing = listingData?.publicListing;

  const { data: eventsData, loading: eventsLoading } = useEventsForOfferQuery({
    variables: {
      input: {
        offerId,
      },
    },
    skip: !offerId,
  });

  const { data } = useDocumentSectionListForOfferFlowQuery({
    variables: {
      filters: {
        usState: listing?.property?.state,
        documentSectionCategory: DocumentSectionCategory.OFFER_CARD,
      },
    },
    skip: !listing?.property?.state,
  });

  const sections = useDocumentSectionsByModuleType({
    documentSections: data?.publicDocumentSectionList
      ?.results as DocumentSection[],
  });

  return {
    offer,
    offerCardSections: sections,
    listing,
    events: eventsData?.eventsForOffer,
    isLoading: offerLoading || listingLoading || eventsLoading,
  };
};
