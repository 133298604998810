import { Card, CardBody, HStack, Switch, Text, VStack } from '@chakra-ui/react';
import { FC, useState } from 'react';
import { KeyTermsSection } from '~/apps/consumer/components/OfferFlow/KeyTerms/KeyTermsSection';
import { useMergeState } from '~/services/document/hooks/useMergeState';
import { OfferFlowContainer } from './OfferFlowContainer';
import { useOfferFlowContext } from './OfferFlowContext';
import { useUpdateInputValuesDebounced } from './hooks/useUpdateInputValuesDebounced';

export interface PrefillSellerPrefs {
  loading: boolean;
  done: boolean;
  isChecked: boolean;
}

export const KeyTermsPage: FC = () => {
  const { fieldValues, updateFieldValues, prefillSellerPreferences } =
    useOfferFlowContext();

  const { inputValues, onChange } = useUpdateInputValuesDebounced();

  const [prefillSellerPrefs, setPrefillSellerPrefs] =
    useState<PrefillSellerPrefs>({
      loading: false,
      done: false,
      isChecked: !!fieldValues.prefillSellerPreferences,
    });

  const updatePrefillSellerPrefs = useMergeState(
    setPrefillSellerPrefs,
    (input) => {
      updateFieldValues({ prefillSellerPreferences: input.isChecked });
    }
  );

  return (
    <OfferFlowContainer onContinueRoutePath="./buyer">
      <VStack alignItems="stretch" flexGrow={1} mb="40px" spacing={4}>
        <Card border="none">
          <CardBody as={HStack} justifyContent="space-between" py={2}>
            <Text color="primary.500" fontWeight="medium">
              Prefill seller preferences
            </Text>
            <Switch
              id="prefill-seller-preferences-switch"
              isChecked={!!fieldValues.prefillSellerPreferences}
              size="sm"
              onChange={(e) => {
                const isChecked = e.currentTarget.checked;
                updatePrefillSellerPrefs({
                  loading: isChecked,
                  isChecked,
                });

                if (e.currentTarget.checked) {
                  void prefillSellerPreferences()
                    .then(() => {
                      updatePrefillSellerPrefs({
                        done: true,
                      });

                      return new Promise((resolve) => {
                        window.setTimeout(resolve, 1500);
                      });
                    })
                    .then(() => {
                      updatePrefillSellerPrefs({
                        done: false,
                        loading: false,
                      });
                    });
                }
              }}
            />
          </CardBody>
        </Card>
        <KeyTermsSection
          inputValues={inputValues}
          prefillSellerPrefs={prefillSellerPrefs}
          onChange={onChange}
        />
      </VStack>
    </OfferFlowContainer>
  );
};
