import { Card, CardBody, CardHeader, Flex, Text } from '@chakra-ui/react';
import { useOfferContractContext } from '~/apps/consumer/pages/Storefront/OfferFlow/OfferContractContext';
import { SimpleListRow } from '../../Lists/SimpleList';

const BUYER_INFO_ROWS = [
  {
    key: 'buyerName',
    label: 'Primary Buyer',
  },
  {
    key: 'entityName',
    label: 'Entity Buyer Name',
  },
  {
    key: 'entityTitle',
    label: 'Entity Buyer Title',
  },
  {
    key: 'coBuyerName',
    label: 'Co-Buyer',
  },
];

export const ContractBuyerInfoDetails = () => {
  const { buyerInfo: contractBuyerInfo } = useOfferContractContext();

  const buyerInfo = contractBuyerInfo;

  if (!buyerInfo) {
    return null;
  }

  return (
    <Card width="100%">
      <CardHeader>
        <Flex justifyContent="space-between">Buyer Info</Flex>
      </CardHeader>
      <CardBody>
        {BUYER_INFO_ROWS.map((row) => (
          <SimpleListRow key={row.key} label={row.label}>
            <Text>
              {buyerInfo?.[row.key as keyof typeof buyerInfo] as string}
            </Text>
          </SimpleListRow>
        ))}
      </CardBody>
    </Card>
  );
};
