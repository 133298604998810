import {
  ContractSignatureFieldsArgs,
  FieldObject,
  FieldType,
  SignatureFormFieldObject,
} from '@client/graphql/__generated__/types';
import { mapById } from '@common/utils/object';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormField } from '../../FormFields/types';
import { PageMetadata } from '../../PDFScrollable/types';
import { Signer } from '../DocumentSignatureMapper';
import { useDocumentSignatureMapperQueries } from './useDocumentSignatureMapperQueries';

interface UseDocumentSignatureMapperOptions {
  contractSignatureFieldArgs: ContractSignatureFieldsArgs;
  signers: Signer[];
}

export const useDocumentSignatureMapper = ({
  contractSignatureFieldArgs,
  signers,
}: UseDocumentSignatureMapperOptions) => {
  const [
    signatureDocumentVersionFieldsById,
    setSignatureDocumentVersionFieldsById,
  ] = useState<Record<string, FormField>>({});
  const [signatureFormFieldsById, setSignatureFormFieldsById] = useState<
    Record<string, SignatureFormFieldObject>
  >({});
  const [pagesMetadata, setPagesMetadata] = useState<PageMetadata[]>([]);

  const queries = useDocumentSignatureMapperQueries(contractSignatureFieldArgs);

  const signersById = useMemo(() => {
    return signers.reduce(
      (acc, signer) => {
        acc[signer.id] = signer;

        return acc;
      },
      {} as Record<string, Signer>
    );
  }, [signers]);

  const signatureFieldsById = useMemo(() => {
    const output: Record<
      string,
      Partial<Record<FieldType, Partial<FieldObject>>>
    > = {};

    const fieldsById = mapById(
      queries.signatureDocumentVersionFields.map((formField) => ({
        ...formField,
        isNew: true,
      }))
    );

    queries.signatureFormFields.forEach((signatureFormField) => {
      const formField = fieldsById[signatureFormField.id];
      const signerFields = output[signatureFormField.signerId];

      if (!signerFields) {
        output[signatureFormField.signerId] = {};
      }

      if (formField) {
        Object.assign(output[signatureFormField.signerId], {
          [formField.fieldType]: formField.field,
        });
      }
    });

    return output;
  }, [queries.signatureDocumentVersionFields, queries.signatureFormFields]);

  // Callbacks
  const updateFormField = useCallback(
    (formField: FormField) => {
      setSignatureFormFieldsById((prevSignatureFormFields) => {
        const pageMetadata = pagesMetadata.find(
          (pageMetadata) =>
            pageMetadata.pageNumber === formField.pageNumber &&
            pageMetadata.documentIndex === formField.documentIndex
        );
        if (pageMetadata) {
          const width = pageMetadata.originalWidth as number;
          const height = pageMetadata.originalHeight as number;

          const prevSignatureFormField = prevSignatureFormFields[
            formField.id
          ] || {
            documentIndex: formField.documentIndex,
            height: formField.height * pageMetadata.height,
            id: formField.id,
            isRequired: true,
            page: formField.pageNumber,
            signerId: '',
            type: formField.fieldType,
            width: formField.width * width,
            x: formField.x * width,
            y: formField.y * height,
          };

          const updatedSignatureFormField = Object.assign(
            {},
            prevSignatureFormField,
            {
              width: width * formField.width,
              height: height * formField.height,
              x: width * formField.x,
              y: height * formField.y,
            }
          );

          setSignatureDocumentVersionFieldsById(
            (prevSignatureDocumentVersionFields) => {
              const prevFormField =
                prevSignatureDocumentVersionFields[formField.id];

              return {
                ...prevSignatureDocumentVersionFields,
                [formField.id]: {
                  ...prevFormField,
                  ...formField,
                  height: updatedSignatureFormField.height / height,
                  width: updatedSignatureFormField.width / width,
                  x: updatedSignatureFormField.x / width,
                  y: updatedSignatureFormField.y / height,
                },
              };
            }
          );

          return {
            ...prevSignatureFormFields,
            [formField.id]: {
              ...updatedSignatureFormField,
            },
          };
        }

        return prevSignatureFormFields;
      });
    },
    [pagesMetadata]
  );

  const onFormFieldsChange = useCallback(
    (formFields: FormField | FormField[]) => {
      if (Array.isArray(formFields)) {
        formFields.forEach((formField) => {
          if (formField?.id) {
            updateFormField(formField);
          }
        });
      } else {
        updateFormField(formFields);
      }
    },
    [updateFormField]
  );

  const onFormFieldDelete = useCallback((formFields: FormField[]) => {
    formFields.forEach((formField) => {
      if (formField?.id) {
        setSignatureDocumentVersionFieldsById((prev) => {
          const next = { ...prev };
          delete next[formField.id];

          return next;
        });

        setSignatureFormFieldsById((prev) => {
          const next = { ...prev };
          delete next[formField.id];

          return next;
        });
      }
    });
  }, []);

  useEffect(() => {
    if (queries.signatureDocumentVersionFields.length > 0) {
      setSignatureDocumentVersionFieldsById(
        mapById(
          queries.signatureDocumentVersionFields.map((formField) => ({
            ...formField,
            isNew: true,
          }))
        )
      );
    }
  }, [queries.signatureDocumentVersionFields]);

  useEffect(() => {
    if (queries.signatureFormFields) {
      setSignatureFormFieldsById(mapById(queries.signatureFormFields));
    }
  }, [queries.signatureFormFields]);

  return {
    ...queries,
    signersById,
    signatureDocumentVersionFieldsById,
    signatureFieldsById,
    signatureFormFieldsById,
    setSignatureFormFieldsById,
    updateFormField,
    pagesMetadata,
    setPagesMetadata,
    onFormFieldsChange,
    onFormFieldDelete,
  };
};
