import { OfferStatus } from '@client/graphql/__generated__/types';
import { FC, useMemo } from 'react';
import { Navigate, useParams, useSearchParams } from 'react-router-dom';
import { useCheckPermission } from '~/common/hooks/useCheckPermission';
import {
  offerDetailsPath,
  offerDetailsPathForBuyer,
} from '../../utils/routes.utils';

export const OfferRedirectPage: FC = () => {
  const { offerId } = useParams();
  const [searchParams] = useSearchParams();
  const listingId = searchParams.get('listingId') as string;
  const partyId = searchParams.get('partyId') as string;
  const offerStatus = searchParams.get('offerStatus') as string;

  const { isAuthorized: listingAuthorized } = useCheckPermission(
    'listings.canViewSubmittedOffers',
    {},
    [listingId]
  );

  const redirectPath = useMemo(() => {
    if (
      listingAuthorized === false ||
      [OfferStatus.DRAFT, OfferStatus.BUYER_SIGNATURE_REQUEST_SENT].includes(
        offerStatus as OfferStatus
      )
    ) {
      return offerDetailsPathForBuyer(partyId, offerId as string);
    }

    if (listingAuthorized === true) {
      return offerDetailsPath(listingId, offerId as string, '');
    }
  }, [listingAuthorized, listingId, offerId, offerStatus, partyId]);

  return redirectPath ? <Navigate replace to={redirectPath} /> : null;
};
