import { HamburgerIcon } from '@chakra-ui/icons';
import { Flex, IconButton } from '@chakra-ui/react';
import { FC, memo } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { IndigoLogo } from '~/common/icons/IndigoLogo';
import { useMobileHeaderContext } from '../../contexts/MobileHeaderContext';
import { ProfilePicture } from '../User/ProfilePicture';
import { DefaultMobileNavDrawer } from './DefaultMobileNavDrawer';

export const MOBILE_HEADER_HEIGHT = '64px';

interface MobileHeaderProps {
  displayDefaultNav?: boolean;
  transparentMobileHeader?: boolean;
  tabletIsMobile?: boolean;
}

export const MobileHeader: FC<MobileHeaderProps> = memo(
  ({ displayDefaultNav, transparentMobileHeader, tabletIsMobile = false }) => {
    const navigate = useNavigate();
    const { onDrawerOpen } = useMobileHeaderContext();

    return (
      <Flex
        backdropFilter={transparentMobileHeader ? 'blur(2px)' : undefined}
        background={
          transparentMobileHeader
            ? 'linear-gradient(180deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.25) 100%)'
            : undefined
        }
        display={
          tabletIsMobile
            ? { base: 'flex', lg: 'none' }
            : { base: 'flex', md: 'none' }
        }
        justifyContent="space-between"
        position={transparentMobileHeader ? 'absolute' : 'relative'}
        px={4}
        py={4}
        top={0}
        width="100%"
        zIndex={transparentMobileHeader ? '5' : undefined}
      >
        <IconButton
          aria-label="Open menu"
          color="chakra-body-text"
          icon={<HamburgerIcon boxSize={8} />}
          variant="link"
          onClick={onDrawerOpen}
        />
        <NavLink to="/">
          <IndigoLogo />
        </NavLink>
        <ProfilePicture
          isEdit={false}
          size="sm"
          onClick={() => navigate('/account')}
        />
        {displayDefaultNav && <DefaultMobileNavDrawer />}
      </Flex>
    );
  }
);
