import {
  OfferObject,
  PartyAndBuyerInfoQuery,
} from '@client/graphql/__generated__/types';
import { useMemo } from 'react';
import { Outlet, useOutletContext } from 'react-router';
import { CenterSpinner } from '~/common/components/CenterSpinner';
import { PartyPage } from './PartyPage';

export const PartyPageWrapper = () => {
  const { partyAndBuyerInfo } = useOutletContext<{
    partyAndBuyerInfo: PartyAndBuyerInfoQuery['partyAndBuyerInfo'];
  }>();

  const purchaseOffersMap = useMemo(() => {
    return partyAndBuyerInfo?.purchaseOffers?.reduce(
      (out, next) => {
        out[next.id] = next as Partial<OfferObject>;

        return out;
      },
      {} as Record<string, Partial<OfferObject>>
    );
  }, [partyAndBuyerInfo?.purchaseOffers]);

  if (!partyAndBuyerInfo?.purchaseOffers) {
    return <CenterSpinner />;
  }

  return (
    <>
      <PartyPage
        party={partyAndBuyerInfo}
        purchaseOffers={
          partyAndBuyerInfo.purchaseOffers as Partial<OfferObject>[]
        }
      />

      <Outlet context={{ purchaseOffersMap }} />
    </>
  );
};
