import * as Types from './types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export const SignatureRequestFragmentDoc = gql`
    fragment SignatureRequest on SignatureRequestObject {
  id
  provider
  externalId
  allSignedDocument {
    fileName
    url
  }
}
    `;
export const InputFormatFragmentDoc = gql`
    fragment InputFormat on InputFormatObject {
  type
  options {
    precision
    formatString
    currencySymbol
  }
}
    `;
export const BoundingBoxFragmentDoc = gql`
    fragment BoundingBox on BoundingBoxObject {
  left
  top
  right
  bottom
}
    `;
export const DocumentVersionFieldFragmentDoc = gql`
    fragment DocumentVersionField on DocumentVersionFieldObject {
  id
  align
  pageNumber
  fontFamily
  fontSize
  label
  inputFormat {
    ...InputFormat
  }
  boundingBox {
    ...BoundingBox
  }
  readOnly
  width
  height
  x
  y
  mappingKey
  fieldType
  labelBoundingBox {
    ...BoundingBox
  }
  labelField {
    height
    width
    x
    y
  }
}
    ${InputFormatFragmentDoc}
${BoundingBoxFragmentDoc}`;
export const FieldFragmentDoc = gql`
    fragment Field on FieldObject {
  id
  group
  label
  displayLabel
  mappingKey
  fieldType
  isComputed
  isHidden
  readOnly
  comparisonType
  inputFormat {
    ...InputFormat
  }
}
    ${InputFormatFragmentDoc}`;
export const VariableFieldFragmentDoc = gql`
    fragment VariableField on VariableFieldObject {
  key
  type
  source
}
    `;
export const AutofillFragmentDoc = gql`
    fragment Autofill on AutofillObject {
  id
  variableSources
  formula
  variableField {
    ...VariableField
  }
  formulaVariableFields {
    ...VariableField
  }
  formulaTestCases {
    values
    expected
  }
}
    ${VariableFieldFragmentDoc}`;
export const RuleFragmentDoc = gql`
    fragment Rule on RuleObject {
  id
  message
  ruleType
  autofill {
    ...Autofill
  }
}
    ${AutofillFragmentDoc}`;
export const ContractDetailFragmentDoc = gql`
    fragment ContractDetail on ContractObject {
  id
  fieldValues
  signatureRequests {
    ...SignatureRequest
  }
  extractedValues @include(if: $extractedValues) {
    values
    mappedValuesBBox
    repositionedDocumentVersionFields {
      ...DocumentVersionField
      field {
        ...Field
      }
    }
  }
  documentML @include(if: $documentML) {
    id
    pdfFileUrl
    documentClassification
    documentFile {
      fileName
      url
    }
  }
  contractDocuments {
    id
    order
    file {
      fileName
      url
    }
    externalDocumentName
    externalDocumentType
    documentVersion {
      id
      document {
        id
        form
        name
        documentType
      }
      file @include(if: $documentVersionFile) {
        fileName
        url
      }
      documentVersionFields @include(if: $documentVersionFields) {
        ...DocumentVersionField
        autofill @include(if: $autofill) {
          ...Autofill
        }
        rules @include(if: $autofill) {
          ...Rule
        }
        field {
          ...Field
          readOnly
          defaultRules @include(if: $autofill) {
            ...Rule
          }
          autofill @include(if: $autofill) {
            ...Autofill
          }
        }
      }
    }
  }
}
    ${SignatureRequestFragmentDoc}
${DocumentVersionFieldFragmentDoc}
${FieldFragmentDoc}
${AutofillFragmentDoc}
${RuleFragmentDoc}`;
export const ContractDocumentForContractFragmentDoc = gql`
    fragment ContractDocumentForContract on ContractDocumentObject {
  id
  order
  documentVersion {
    id
    document {
      id
      name
      form
      documentType
    }
    mappingKeys
    version
  }
  file {
    fileName
    url
  }
  externalDocumentType
  externalDocumentName
}
    `;
export const ContractValidationFragmentDoc = gql`
    fragment ContractValidation on ContractValidationObject {
  field {
    id
    mappingKey
    fieldType
    label
    displayLabel
    group
    inputFormat {
      type
      options {
        precision
        formatString
        currencySymbol
      }
    }
  }
  documentVersionField {
    id
    mappingKey
    fieldType
    label
    displayLabel
    inputFormat {
      type
      options {
        precision
        formatString
        currencySymbol
      }
    }
  }
  results {
    inputValue
    message
    validationValue
    rule {
      id
      ruleType
    }
  }
}
    `;
export const FieldsToColumnFragmentDoc = gql`
    fragment FieldsToColumn on FieldsToColumnObject {
  id
  columnName
  visibility
  fieldType
  documentType
  fields {
    ...Field
  }
  transformAutofill {
    ...Autofill
  }
}
    ${FieldFragmentDoc}
${AutofillFragmentDoc}`;
export const PageInfoFragmentDoc = gql`
    fragment PageInfo on PageInfoObject {
  totalCount
  hasNextPage
  hasPrevPage
  nextCursor
  prevCursor
}
    `;
export const SignatureFormFieldFragmentDoc = gql`
    fragment SignatureFormField on SignatureFormFieldObject {
  id
  documentIndex
  x
  y
  height
  isRequired
  name
  page
  signerId
  type
  width
  x
}
    `;
export const DetectFormFieldsFragmentDoc = gql`
    fragment DetectFormFields on DocumentVersionFieldObject {
  ...DocumentVersionField
  field {
    id
    fieldType
    mappingKey
    label
  }
}
    ${DocumentVersionFieldFragmentDoc}`;
export const ExtractContractMutationFragmentDoc = gql`
    fragment ExtractContractMutation on ExtractContractPayload {
  contract {
    id
    fieldValues
    contractDocuments {
      id
      documentVersion {
        id
      }
    }
  }
  extracted {
    formName
    documentType
    usState
    values
  }
}
    `;
export const ContractFieldValuesFragmentDoc = gql`
    fragment ContractFieldValues on ContractObject {
  id
  fieldValues
}
    `;
export const DocumentFragmentDoc = gql`
    fragment Document on DocumentObject {
  id
  documentType
  name
  description
  form
  usState
}
    `;
export const DocumentVersionFragmentDoc = gql`
    fragment DocumentVersion on DocumentVersionObject {
  id
  status
  searchRegex
  version
  file {
    fileName
    url
  }
}
    `;
export const DefaultSecondaryDocumentFragmentDoc = gql`
    fragment DefaultSecondaryDocument on DefaultSecondaryDocumentObject {
  id
  ordering
  mappingKey
  defaultRules {
    ...Rule
  }
  secondaryDocument {
    id
    documentType
    form
    name
    usState
  }
}
    ${RuleFragmentDoc}`;
export const FieldDetailFragmentDoc = gql`
    fragment FieldDetail on FieldObject {
  id
  autofill {
    ...Autofill
  }
  optionsAutofill {
    ...Autofill
  }
  helpTextAutofill {
    ...Autofill
  }
  defaultRules {
    ...Rule
  }
}
    ${AutofillFragmentDoc}
${RuleFragmentDoc}`;
export const DocumentListFragmentDoc = gql`
    fragment DocumentList on DocumentObject {
  ...Document
  documentVersions @include(if: $includeDocumentVersions) {
    ...DocumentVersion
    documentVersionFieldsCount
  }
  defaultSecondaryDocuments @include(if: $includeDefaultSecondaryDocuments) {
    ...DefaultSecondaryDocument
  }
  activeDocumentVersion @include(if: $includeActiveDocumentVersion) {
    ...DocumentVersion
    documentVersionFields {
      ...DocumentVersionField
      field {
        ...Field
        ...FieldDetail
      }
    }
  }
}
    ${DocumentFragmentDoc}
${DocumentVersionFragmentDoc}
${DefaultSecondaryDocumentFragmentDoc}
${DocumentVersionFieldFragmentDoc}
${FieldFragmentDoc}
${FieldDetailFragmentDoc}`;
export const DocumentAssetFileFragmentDoc = gql`
    fragment DocumentAssetFile on AssetFileObject {
  id
  ordering
  file {
    url
  }
}
    `;
export const PublicDocumentForOfferFlowFragmentDoc = gql`
    fragment PublicDocumentForOfferFlow on PublicDocumentObject {
  id
  description
  form
  name
  documentType
  defaultSecondaryDocuments @include(if: $defaultSecondaryDocuments) {
    id
    mappingKey
    ordering
    secondaryDocument {
      id
      name
      form
      activeDocumentVersion {
        id
      }
    }
  }
  activeDocumentVersion @include(if: $activeDocumentVersion) {
    id
    previewImages: hiddenPreviewImages {
      ...DocumentAssetFile
    }
  }
}
    ${DocumentAssetFileFragmentDoc}`;
export const DocumentForOfferFlowFragmentDoc = gql`
    fragment DocumentForOfferFlow on DocumentObject {
  id
  description
  form
  name
  documentType
  defaultSecondaryDocuments @include(if: $defaultSecondaryDocuments) {
    id
    mappingKey
    ordering
    secondaryDocument {
      id
      name
      form
    }
  }
  activeDocumentVersion @include(if: $activeDocumentVersion) {
    id
    previewImages {
      ...DocumentAssetFile
    }
  }
}
    ${DocumentAssetFileFragmentDoc}`;
export const DocumentSectionFragmentDoc = gql`
    fragment DocumentSection on DocumentSectionObject {
  id
  description
  documentSectionDisplayType
  documentSectionType
  documentSectionCategory
  documentSectionModuleType
  title
  order
  documentSectionFields {
    id
    order
    field {
      ...Field
      ...FieldDetail
    }
  }
}
    ${FieldFragmentDoc}
${FieldDetailFragmentDoc}`;
export const DocumentSectionListFragmentDoc = gql`
    fragment DocumentSectionList on DocumentSectionObject {
  ...DocumentSection
  parent {
    id
    description
  }
}
    ${DocumentSectionFragmentDoc}`;
export const PublicFieldFragmentDoc = gql`
    fragment PublicField on PublicFieldObject {
  id
  group
  label
  mappingKey
  fieldType
  isComputed
  isHidden
  readOnly
  comparisonType
  inputFormat {
    ...InputFormat
  }
  autofill {
    id
    formulaVariableFields {
      key
      source
      type
    }
    variableField {
      key
      source
      type
    }
  }
}
    ${InputFormatFragmentDoc}`;
export const DocumentSectionListForOfferFlowFieldFragmentDoc = gql`
    fragment DocumentSectionListForOfferFlowField on PublicDocumentSectionFieldObject {
  id
  order
  field {
    ...PublicField
  }
}
    ${PublicFieldFragmentDoc}`;
export const DocumentSectionListForOfferFlowFragmentDoc = gql`
    fragment DocumentSectionListForOfferFlow on PublicDocumentSectionObject {
  id
  title
  description
  documentSectionType
  documentSectionDisplayType
  documentSectionCategory
  documentSectionModuleType
  order
  document {
    id
    usState
  }
  parent {
    id
    description
  }
  documentSectionFields {
    ...DocumentSectionListForOfferFlowField
  }
}
    ${DocumentSectionListForOfferFlowFieldFragmentDoc}`;
export const DocumentVersionListFieldFragmentDoc = gql`
    fragment DocumentVersionListField on DocumentVersionFieldObject {
  ...DocumentVersionField
  autofill {
    ...Autofill
  }
  field {
    ...Field
    autofill {
      ...Autofill
    }
  }
}
    ${DocumentVersionFieldFragmentDoc}
${AutofillFragmentDoc}
${FieldFragmentDoc}`;
export const SecondaryDocumentVersionFragmentDoc = gql`
    fragment SecondaryDocumentVersion on SecondaryDocumentVersionObject {
  id
  ordering
  rules {
    ...Rule
    autofill {
      ...Autofill
    }
  }
  secondaryDocumentVersion {
    id
    version
    status
    searchRegex
    file {
      fileName
      url
    }
    document {
      ...Document
    }
  }
}
    ${RuleFragmentDoc}
${AutofillFragmentDoc}
${DocumentFragmentDoc}`;
export const DocumentVersionListFragmentDoc = gql`
    fragment DocumentVersionList on DocumentVersionObject {
  ...DocumentVersion
  document @include(if: $document) {
    ...Document
  }
  documentVersionFields @include(if: $fields) {
    ...DocumentVersionListField
  }
  secondaryDocumentVersions @include(if: $secondaryDocumentVersions) {
    ...SecondaryDocumentVersion
  }
}
    ${DocumentVersionFragmentDoc}
${DocumentFragmentDoc}
${DocumentVersionListFieldFragmentDoc}
${SecondaryDocumentVersionFragmentDoc}`;
export const PublicDocumentVersionFieldFragmentDoc = gql`
    fragment PublicDocumentVersionField on PublicDocumentVersionFieldObject {
  id
  align
  pageNumber
  fontFamily
  fontSize
  label
  inputFormat {
    ...InputFormat
  }
  boundingBox {
    ...BoundingBox
  }
  readOnly
  width
  height
  x
  y
  mappingKey
  fieldType
}
    ${InputFormatFragmentDoc}
${BoundingBoxFragmentDoc}`;
export const PublicDocumentVersionForOfferFlowFragmentDoc = gql`
    fragment PublicDocumentVersionForOfferFlow on PublicDocumentVersionObject {
  id
  previewImages: hiddenPreviewImages @include(if: $previewImages) {
    ...DocumentAssetFile
  }
  documentVersionFields {
    ...PublicDocumentVersionField
    field {
      ...PublicField
    }
  }
  document {
    id
  }
  secondaryVersions @skip(if: $skipSecondaryVersions) {
    id
    mappingKeys
    previewImages: hiddenPreviewImages @include(if: $previewImages) {
      ...DocumentAssetFile
    }
    documentVersionFields @include(if: $documentVersionFields) {
      ...PublicDocumentVersionField
      field {
        ...PublicField
      }
    }
    document {
      id
      name
      usState
      form
    }
  }
}
    ${DocumentAssetFileFragmentDoc}
${PublicDocumentVersionFieldFragmentDoc}
${PublicFieldFragmentDoc}`;
export const DocumentVersionForOfferFlowFragmentDoc = gql`
    fragment DocumentVersionForOfferFlow on DocumentVersionObject {
  id
  status
  version
  file {
    fileName
    url
  }
  documentVersionFields {
    ...DocumentVersionField
    field {
      ...Field
    }
  }
  document {
    id
  }
  secondaryVersions @skip(if: $skipSecondaryVersions) {
    id
    status
    version
    file {
      fileName
      url
    }
    mappingKeys
    previewImages @include(if: $previewImages) {
      id
    }
    documentVersionFields @include(if: $documentVersionFields) {
      ...DocumentVersionField
      field {
        ...Field
      }
    }
    document {
      id
      name
      usState
      form
    }
  }
}
    ${DocumentVersionFieldFragmentDoc}
${FieldFragmentDoc}`;
export const EmbeddedSignatureRequestSecondaryDocumentFragmentDoc = gql`
    fragment EmbeddedSignatureRequestSecondaryDocument on DocumentObject {
  id
  name
  activeDocumentVersion {
    id
    file {
      fileName
      url
    }
  }
}
    `;
export const EmbeddedSignatureRequestFragmentDoc = gql`
    fragment EmbeddedSignatureRequest on ContractEmbeddedSignatureRequestPayload {
  isExpired
  contract {
    id
    fieldValues
  }
  primaryDocument {
    id
    documentType
    name
    defaultSecondaryDocuments {
      id
      secondaryDocument {
        ...EmbeddedSignatureRequestSecondaryDocument
      }
    }
  }
  signer {
    id
    signedAt
    fullName
  }
  signatureRequest {
    id
    requester {
      id
      firstName
      lastName
      fullName
      profilePicture {
        fileName
        url
      }
    }
  }
}
    ${EmbeddedSignatureRequestSecondaryDocumentFragmentDoc}`;
export const FieldsToColumnListDocument = gql`
    query fieldsToColumnList($filters: FieldsToColumnListFilterArgs, $cursor: String, $orderBy: String = "-id", $perPage: Int = 20) {
  fieldsToColumnList(
    filters: $filters
    cursor: $cursor
    orderBy: $orderBy
    perPage: $perPage
  ) {
    pageInfo {
      ...PageInfo
    }
    results {
      ...FieldsToColumn
    }
  }
}
    ${PageInfoFragmentDoc}
${FieldsToColumnFragmentDoc}`;

/**
 * __useFieldsToColumnListQuery__
 *
 * To run a query within a React component, call `useFieldsToColumnListQuery` and pass it any options that fit your needs.
 * When your component renders, `useFieldsToColumnListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFieldsToColumnListQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      cursor: // value for 'cursor'
 *      orderBy: // value for 'orderBy'
 *      perPage: // value for 'perPage'
 *   },
 * });
 */
export function useFieldsToColumnListQuery(baseOptions?: Apollo.QueryHookOptions<Types.FieldsToColumnListQuery, Types.FieldsToColumnListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.FieldsToColumnListQuery, Types.FieldsToColumnListQueryVariables>(FieldsToColumnListDocument, options);
      }
export function useFieldsToColumnListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.FieldsToColumnListQuery, Types.FieldsToColumnListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.FieldsToColumnListQuery, Types.FieldsToColumnListQueryVariables>(FieldsToColumnListDocument, options);
        }
export type FieldsToColumnListQueryHookResult = ReturnType<typeof useFieldsToColumnListQuery>;
export type FieldsToColumnListLazyQueryHookResult = ReturnType<typeof useFieldsToColumnListLazyQuery>;
export type FieldsToColumnListQueryResult = Apollo.QueryResult<Types.FieldsToColumnListQuery, Types.FieldsToColumnListQueryVariables>;
export const AddOrUpdateDisclosureContractDocumentsDocument = gql`
    mutation AddOrUpdateDisclosureContractDocuments($input: AddOrUpdateDisclosureContractDocumentsInput!) {
  addOrUpdateDisclosureContractDocuments(input: $input) {
    contract {
      id
      contractDocuments {
        ...ContractDocumentForContract
      }
    }
  }
}
    ${ContractDocumentForContractFragmentDoc}`;
export type AddOrUpdateDisclosureContractDocumentsMutationFn = Apollo.MutationFunction<Types.AddOrUpdateDisclosureContractDocumentsMutation, Types.AddOrUpdateDisclosureContractDocumentsMutationVariables>;

/**
 * __useAddOrUpdateDisclosureContractDocumentsMutation__
 *
 * To run a mutation, you first call `useAddOrUpdateDisclosureContractDocumentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddOrUpdateDisclosureContractDocumentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addOrUpdateDisclosureContractDocumentsMutation, { data, loading, error }] = useAddOrUpdateDisclosureContractDocumentsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddOrUpdateDisclosureContractDocumentsMutation(baseOptions?: Apollo.MutationHookOptions<Types.AddOrUpdateDisclosureContractDocumentsMutation, Types.AddOrUpdateDisclosureContractDocumentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AddOrUpdateDisclosureContractDocumentsMutation, Types.AddOrUpdateDisclosureContractDocumentsMutationVariables>(AddOrUpdateDisclosureContractDocumentsDocument, options);
      }
export type AddOrUpdateDisclosureContractDocumentsMutationHookResult = ReturnType<typeof useAddOrUpdateDisclosureContractDocumentsMutation>;
export type AddOrUpdateDisclosureContractDocumentsMutationResult = Apollo.MutationResult<Types.AddOrUpdateDisclosureContractDocumentsMutation>;
export type AddOrUpdateDisclosureContractDocumentsMutationOptions = Apollo.BaseMutationOptions<Types.AddOrUpdateDisclosureContractDocumentsMutation, Types.AddOrUpdateDisclosureContractDocumentsMutationVariables>;
export const AddOrUpdatePreapprovalContractDocumentDocument = gql`
    mutation AddOrUpdatePreapprovalContractDocument($input: AddOrUpdatePreapprovalContractDocumentInput!) {
  addOrUpdatePreapprovalContractDocument(input: $input) {
    contract {
      id
    }
  }
}
    `;
export type AddOrUpdatePreapprovalContractDocumentMutationFn = Apollo.MutationFunction<Types.AddOrUpdatePreapprovalContractDocumentMutation, Types.AddOrUpdatePreapprovalContractDocumentMutationVariables>;

/**
 * __useAddOrUpdatePreapprovalContractDocumentMutation__
 *
 * To run a mutation, you first call `useAddOrUpdatePreapprovalContractDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddOrUpdatePreapprovalContractDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addOrUpdatePreapprovalContractDocumentMutation, { data, loading, error }] = useAddOrUpdatePreapprovalContractDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddOrUpdatePreapprovalContractDocumentMutation(baseOptions?: Apollo.MutationHookOptions<Types.AddOrUpdatePreapprovalContractDocumentMutation, Types.AddOrUpdatePreapprovalContractDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AddOrUpdatePreapprovalContractDocumentMutation, Types.AddOrUpdatePreapprovalContractDocumentMutationVariables>(AddOrUpdatePreapprovalContractDocumentDocument, options);
      }
export type AddOrUpdatePreapprovalContractDocumentMutationHookResult = ReturnType<typeof useAddOrUpdatePreapprovalContractDocumentMutation>;
export type AddOrUpdatePreapprovalContractDocumentMutationResult = Apollo.MutationResult<Types.AddOrUpdatePreapprovalContractDocumentMutation>;
export type AddOrUpdatePreapprovalContractDocumentMutationOptions = Apollo.BaseMutationOptions<Types.AddOrUpdatePreapprovalContractDocumentMutation, Types.AddOrUpdatePreapprovalContractDocumentMutationVariables>;
export const CopyFieldsFromPreviousVersionDocument = gql`
    mutation copyFieldsFromPreviousVersion($input: CopyFieldsFromPreviousVersionInput!) {
  copyFieldsFromPreviousVersion(input: $input) {
    documentVersion {
      ...DocumentVersion
      documentVersionFieldsCount
      documentVersionFields {
        ...DocumentVersionField
      }
    }
  }
}
    ${DocumentVersionFragmentDoc}
${DocumentVersionFieldFragmentDoc}`;
export type CopyFieldsFromPreviousVersionMutationFn = Apollo.MutationFunction<Types.CopyFieldsFromPreviousVersionMutation, Types.CopyFieldsFromPreviousVersionMutationVariables>;

/**
 * __useCopyFieldsFromPreviousVersionMutation__
 *
 * To run a mutation, you first call `useCopyFieldsFromPreviousVersionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyFieldsFromPreviousVersionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyFieldsFromPreviousVersionMutation, { data, loading, error }] = useCopyFieldsFromPreviousVersionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCopyFieldsFromPreviousVersionMutation(baseOptions?: Apollo.MutationHookOptions<Types.CopyFieldsFromPreviousVersionMutation, Types.CopyFieldsFromPreviousVersionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CopyFieldsFromPreviousVersionMutation, Types.CopyFieldsFromPreviousVersionMutationVariables>(CopyFieldsFromPreviousVersionDocument, options);
      }
export type CopyFieldsFromPreviousVersionMutationHookResult = ReturnType<typeof useCopyFieldsFromPreviousVersionMutation>;
export type CopyFieldsFromPreviousVersionMutationResult = Apollo.MutationResult<Types.CopyFieldsFromPreviousVersionMutation>;
export type CopyFieldsFromPreviousVersionMutationOptions = Apollo.BaseMutationOptions<Types.CopyFieldsFromPreviousVersionMutation, Types.CopyFieldsFromPreviousVersionMutationVariables>;
export const CreateAutofillDocument = gql`
    mutation createAutofill($input: CreateAutofillInput!) {
  createAutofill(input: $input) {
    autofill {
      id
      field {
        id
        autofill {
          ...Autofill
        }
      }
      rule {
        id
        autofill {
          ...Autofill
        }
      }
      optionsField {
        id
        autofill {
          ...Autofill
        }
      }
      helpTextField {
        id
        autofill {
          ...Autofill
        }
      }
      documentVersionField {
        id
        autofill {
          ...Autofill
        }
      }
      optionsDocumentVersionField {
        id
        autofill {
          ...Autofill
        }
      }
      helpTextDocumentVersionField {
        id
        autofill {
          ...Autofill
        }
      }
    }
  }
}
    ${AutofillFragmentDoc}`;
export type CreateAutofillMutationFn = Apollo.MutationFunction<Types.CreateAutofillMutation, Types.CreateAutofillMutationVariables>;

/**
 * __useCreateAutofillMutation__
 *
 * To run a mutation, you first call `useCreateAutofillMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAutofillMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAutofillMutation, { data, loading, error }] = useCreateAutofillMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAutofillMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateAutofillMutation, Types.CreateAutofillMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateAutofillMutation, Types.CreateAutofillMutationVariables>(CreateAutofillDocument, options);
      }
export type CreateAutofillMutationHookResult = ReturnType<typeof useCreateAutofillMutation>;
export type CreateAutofillMutationResult = Apollo.MutationResult<Types.CreateAutofillMutation>;
export type CreateAutofillMutationOptions = Apollo.BaseMutationOptions<Types.CreateAutofillMutation, Types.CreateAutofillMutationVariables>;
export const CreateContractDocument = gql`
    mutation createContract($input: CreateContractInput) {
  createContract(input: $input) {
    contract {
      id
    }
  }
}
    `;
export type CreateContractMutationFn = Apollo.MutationFunction<Types.CreateContractMutation, Types.CreateContractMutationVariables>;

/**
 * __useCreateContractMutation__
 *
 * To run a mutation, you first call `useCreateContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContractMutation, { data, loading, error }] = useCreateContractMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateContractMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateContractMutation, Types.CreateContractMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateContractMutation, Types.CreateContractMutationVariables>(CreateContractDocument, options);
      }
export type CreateContractMutationHookResult = ReturnType<typeof useCreateContractMutation>;
export type CreateContractMutationResult = Apollo.MutationResult<Types.CreateContractMutation>;
export type CreateContractMutationOptions = Apollo.BaseMutationOptions<Types.CreateContractMutation, Types.CreateContractMutationVariables>;
export const CreateDefaultSecondaryDocumentDocument = gql`
    mutation createDefaultSecondaryDocument($input: CreateDefaultSecondaryDocumentInput!) {
  createDefaultSecondaryDocument(input: $input) {
    defaultSecondaryDocument {
      id
      document {
        id
        defaultSecondaryDocuments {
          ...DefaultSecondaryDocument
        }
      }
    }
  }
}
    ${DefaultSecondaryDocumentFragmentDoc}`;
export type CreateDefaultSecondaryDocumentMutationFn = Apollo.MutationFunction<Types.CreateDefaultSecondaryDocumentMutation, Types.CreateDefaultSecondaryDocumentMutationVariables>;

/**
 * __useCreateDefaultSecondaryDocumentMutation__
 *
 * To run a mutation, you first call `useCreateDefaultSecondaryDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDefaultSecondaryDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDefaultSecondaryDocumentMutation, { data, loading, error }] = useCreateDefaultSecondaryDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDefaultSecondaryDocumentMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateDefaultSecondaryDocumentMutation, Types.CreateDefaultSecondaryDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateDefaultSecondaryDocumentMutation, Types.CreateDefaultSecondaryDocumentMutationVariables>(CreateDefaultSecondaryDocumentDocument, options);
      }
export type CreateDefaultSecondaryDocumentMutationHookResult = ReturnType<typeof useCreateDefaultSecondaryDocumentMutation>;
export type CreateDefaultSecondaryDocumentMutationResult = Apollo.MutationResult<Types.CreateDefaultSecondaryDocumentMutation>;
export type CreateDefaultSecondaryDocumentMutationOptions = Apollo.BaseMutationOptions<Types.CreateDefaultSecondaryDocumentMutation, Types.CreateDefaultSecondaryDocumentMutationVariables>;
export const CreateDocumentDocument = gql`
    mutation createDocument($input: CreateDocumentInput!) {
  createDocument(input: $input) {
    document {
      ...Document
    }
  }
}
    ${DocumentFragmentDoc}`;
export type CreateDocumentMutationFn = Apollo.MutationFunction<Types.CreateDocumentMutation, Types.CreateDocumentMutationVariables>;

/**
 * __useCreateDocumentMutation__
 *
 * To run a mutation, you first call `useCreateDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDocumentMutation, { data, loading, error }] = useCreateDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDocumentMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateDocumentMutation, Types.CreateDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateDocumentMutation, Types.CreateDocumentMutationVariables>(CreateDocumentDocument, options);
      }
export type CreateDocumentMutationHookResult = ReturnType<typeof useCreateDocumentMutation>;
export type CreateDocumentMutationResult = Apollo.MutationResult<Types.CreateDocumentMutation>;
export type CreateDocumentMutationOptions = Apollo.BaseMutationOptions<Types.CreateDocumentMutation, Types.CreateDocumentMutationVariables>;
export const CreateDocumentSectionDocument = gql`
    mutation createDocumentSection($input: CreateDocumentSectionInput!) {
  createDocumentSection(input: $input) {
    documentSection {
      ...DocumentSectionList
    }
  }
}
    ${DocumentSectionListFragmentDoc}`;
export type CreateDocumentSectionMutationFn = Apollo.MutationFunction<Types.CreateDocumentSectionMutation, Types.CreateDocumentSectionMutationVariables>;

/**
 * __useCreateDocumentSectionMutation__
 *
 * To run a mutation, you first call `useCreateDocumentSectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDocumentSectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDocumentSectionMutation, { data, loading, error }] = useCreateDocumentSectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDocumentSectionMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateDocumentSectionMutation, Types.CreateDocumentSectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateDocumentSectionMutation, Types.CreateDocumentSectionMutationVariables>(CreateDocumentSectionDocument, options);
      }
export type CreateDocumentSectionMutationHookResult = ReturnType<typeof useCreateDocumentSectionMutation>;
export type CreateDocumentSectionMutationResult = Apollo.MutationResult<Types.CreateDocumentSectionMutation>;
export type CreateDocumentSectionMutationOptions = Apollo.BaseMutationOptions<Types.CreateDocumentSectionMutation, Types.CreateDocumentSectionMutationVariables>;
export const CreateDocumentVersionDocument = gql`
    mutation createDocumentVersion($input: CreateDocumentVersionInput!) {
  createDocumentVersion(input: $input) {
    documentVersion {
      ...DocumentVersion
      document {
        id
        documentVersions {
          id
        }
      }
    }
  }
}
    ${DocumentVersionFragmentDoc}`;
export type CreateDocumentVersionMutationFn = Apollo.MutationFunction<Types.CreateDocumentVersionMutation, Types.CreateDocumentVersionMutationVariables>;

/**
 * __useCreateDocumentVersionMutation__
 *
 * To run a mutation, you first call `useCreateDocumentVersionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDocumentVersionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDocumentVersionMutation, { data, loading, error }] = useCreateDocumentVersionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDocumentVersionMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateDocumentVersionMutation, Types.CreateDocumentVersionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateDocumentVersionMutation, Types.CreateDocumentVersionMutationVariables>(CreateDocumentVersionDocument, options);
      }
export type CreateDocumentVersionMutationHookResult = ReturnType<typeof useCreateDocumentVersionMutation>;
export type CreateDocumentVersionMutationResult = Apollo.MutationResult<Types.CreateDocumentVersionMutation>;
export type CreateDocumentVersionMutationOptions = Apollo.BaseMutationOptions<Types.CreateDocumentVersionMutation, Types.CreateDocumentVersionMutationVariables>;
export const CreateDocumentVersionFieldsDocument = gql`
    mutation createDocumentVersionFields($inputs: [CreateDocumentVersionFieldInput!]!) {
  createDocumentVersionFields(inputs: $inputs) {
    documentVersionFields {
      ...DocumentVersionField
    }
  }
}
    ${DocumentVersionFieldFragmentDoc}`;
export type CreateDocumentVersionFieldsMutationFn = Apollo.MutationFunction<Types.CreateDocumentVersionFieldsMutation, Types.CreateDocumentVersionFieldsMutationVariables>;

/**
 * __useCreateDocumentVersionFieldsMutation__
 *
 * To run a mutation, you first call `useCreateDocumentVersionFieldsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDocumentVersionFieldsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDocumentVersionFieldsMutation, { data, loading, error }] = useCreateDocumentVersionFieldsMutation({
 *   variables: {
 *      inputs: // value for 'inputs'
 *   },
 * });
 */
export function useCreateDocumentVersionFieldsMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateDocumentVersionFieldsMutation, Types.CreateDocumentVersionFieldsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateDocumentVersionFieldsMutation, Types.CreateDocumentVersionFieldsMutationVariables>(CreateDocumentVersionFieldsDocument, options);
      }
export type CreateDocumentVersionFieldsMutationHookResult = ReturnType<typeof useCreateDocumentVersionFieldsMutation>;
export type CreateDocumentVersionFieldsMutationResult = Apollo.MutationResult<Types.CreateDocumentVersionFieldsMutation>;
export type CreateDocumentVersionFieldsMutationOptions = Apollo.BaseMutationOptions<Types.CreateDocumentVersionFieldsMutation, Types.CreateDocumentVersionFieldsMutationVariables>;
export const CreateEmbeddedSignUrlDocument = gql`
    mutation createEmbeddedSignUrl($code: String!) {
  createEmbeddedSignUrl(code: $code)
}
    `;
export type CreateEmbeddedSignUrlMutationFn = Apollo.MutationFunction<Types.CreateEmbeddedSignUrlMutation, Types.CreateEmbeddedSignUrlMutationVariables>;

/**
 * __useCreateEmbeddedSignUrlMutation__
 *
 * To run a mutation, you first call `useCreateEmbeddedSignUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEmbeddedSignUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEmbeddedSignUrlMutation, { data, loading, error }] = useCreateEmbeddedSignUrlMutation({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useCreateEmbeddedSignUrlMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateEmbeddedSignUrlMutation, Types.CreateEmbeddedSignUrlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateEmbeddedSignUrlMutation, Types.CreateEmbeddedSignUrlMutationVariables>(CreateEmbeddedSignUrlDocument, options);
      }
export type CreateEmbeddedSignUrlMutationHookResult = ReturnType<typeof useCreateEmbeddedSignUrlMutation>;
export type CreateEmbeddedSignUrlMutationResult = Apollo.MutationResult<Types.CreateEmbeddedSignUrlMutation>;
export type CreateEmbeddedSignUrlMutationOptions = Apollo.BaseMutationOptions<Types.CreateEmbeddedSignUrlMutation, Types.CreateEmbeddedSignUrlMutationVariables>;
export const CreateFallbackContractDocument = gql`
    mutation createFallbackContract($input: CreateFallbackContractInput!) {
  createFallbackContract(input: $input) {
    contract {
      id
    }
  }
}
    `;
export type CreateFallbackContractMutationFn = Apollo.MutationFunction<Types.CreateFallbackContractMutation, Types.CreateFallbackContractMutationVariables>;

/**
 * __useCreateFallbackContractMutation__
 *
 * To run a mutation, you first call `useCreateFallbackContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFallbackContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFallbackContractMutation, { data, loading, error }] = useCreateFallbackContractMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateFallbackContractMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateFallbackContractMutation, Types.CreateFallbackContractMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateFallbackContractMutation, Types.CreateFallbackContractMutationVariables>(CreateFallbackContractDocument, options);
      }
export type CreateFallbackContractMutationHookResult = ReturnType<typeof useCreateFallbackContractMutation>;
export type CreateFallbackContractMutationResult = Apollo.MutationResult<Types.CreateFallbackContractMutation>;
export type CreateFallbackContractMutationOptions = Apollo.BaseMutationOptions<Types.CreateFallbackContractMutation, Types.CreateFallbackContractMutationVariables>;
export const CreateFieldDocument = gql`
    mutation createField($input: CreateFieldInput!) {
  createField(input: $input) {
    field {
      ...Field
      ...FieldDetail
    }
  }
}
    ${FieldFragmentDoc}
${FieldDetailFragmentDoc}`;
export type CreateFieldMutationFn = Apollo.MutationFunction<Types.CreateFieldMutation, Types.CreateFieldMutationVariables>;

/**
 * __useCreateFieldMutation__
 *
 * To run a mutation, you first call `useCreateFieldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFieldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFieldMutation, { data, loading, error }] = useCreateFieldMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateFieldMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateFieldMutation, Types.CreateFieldMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateFieldMutation, Types.CreateFieldMutationVariables>(CreateFieldDocument, options);
      }
export type CreateFieldMutationHookResult = ReturnType<typeof useCreateFieldMutation>;
export type CreateFieldMutationResult = Apollo.MutationResult<Types.CreateFieldMutation>;
export type CreateFieldMutationOptions = Apollo.BaseMutationOptions<Types.CreateFieldMutation, Types.CreateFieldMutationVariables>;
export const CreateRuleDocument = gql`
    mutation createRule($input: CreateRuleInput!, $relationInput: RuleRelationInput!) {
  createRule(input: $input, relationInput: $relationInput) {
    rule {
      id
      field {
        id
        defaultRules {
          ...Rule
        }
      }
      documentVersionField {
        id
        rules {
          ...Rule
        }
      }
      defaultSecondaryDocument {
        id
        defaultRules {
          ...Rule
        }
      }
      secondaryDocumentVersion {
        id
        rules {
          ...Rule
        }
      }
    }
  }
}
    ${RuleFragmentDoc}`;
export type CreateRuleMutationFn = Apollo.MutationFunction<Types.CreateRuleMutation, Types.CreateRuleMutationVariables>;

/**
 * __useCreateRuleMutation__
 *
 * To run a mutation, you first call `useCreateRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRuleMutation, { data, loading, error }] = useCreateRuleMutation({
 *   variables: {
 *      input: // value for 'input'
 *      relationInput: // value for 'relationInput'
 *   },
 * });
 */
export function useCreateRuleMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateRuleMutation, Types.CreateRuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateRuleMutation, Types.CreateRuleMutationVariables>(CreateRuleDocument, options);
      }
export type CreateRuleMutationHookResult = ReturnType<typeof useCreateRuleMutation>;
export type CreateRuleMutationResult = Apollo.MutationResult<Types.CreateRuleMutation>;
export type CreateRuleMutationOptions = Apollo.BaseMutationOptions<Types.CreateRuleMutation, Types.CreateRuleMutationVariables>;
export const DeleteContractDocumentsDocument = gql`
    mutation DeleteContractDocuments($input: DeleteContractDocumentsInput!) {
  deleteContractDocuments(input: $input) {
    contract {
      id
      fieldValues
      contractDocuments {
        ...ContractDocumentForContract
      }
    }
  }
}
    ${ContractDocumentForContractFragmentDoc}`;
export type DeleteContractDocumentsMutationFn = Apollo.MutationFunction<Types.DeleteContractDocumentsMutation, Types.DeleteContractDocumentsMutationVariables>;

/**
 * __useDeleteContractDocumentsMutation__
 *
 * To run a mutation, you first call `useDeleteContractDocumentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteContractDocumentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteContractDocumentsMutation, { data, loading, error }] = useDeleteContractDocumentsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteContractDocumentsMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteContractDocumentsMutation, Types.DeleteContractDocumentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteContractDocumentsMutation, Types.DeleteContractDocumentsMutationVariables>(DeleteContractDocumentsDocument, options);
      }
export type DeleteContractDocumentsMutationHookResult = ReturnType<typeof useDeleteContractDocumentsMutation>;
export type DeleteContractDocumentsMutationResult = Apollo.MutationResult<Types.DeleteContractDocumentsMutation>;
export type DeleteContractDocumentsMutationOptions = Apollo.BaseMutationOptions<Types.DeleteContractDocumentsMutation, Types.DeleteContractDocumentsMutationVariables>;
export const DeleteDefaultSecondaryDocumentDocument = gql`
    mutation deleteDefaultSecondaryDocument($input: DeleteDefaultSecondaryDocumentInput!) {
  deleteDefaultSecondaryDocument(input: $input) {
    success
  }
}
    `;
export type DeleteDefaultSecondaryDocumentMutationFn = Apollo.MutationFunction<Types.DeleteDefaultSecondaryDocumentMutation, Types.DeleteDefaultSecondaryDocumentMutationVariables>;

/**
 * __useDeleteDefaultSecondaryDocumentMutation__
 *
 * To run a mutation, you first call `useDeleteDefaultSecondaryDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDefaultSecondaryDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDefaultSecondaryDocumentMutation, { data, loading, error }] = useDeleteDefaultSecondaryDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteDefaultSecondaryDocumentMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteDefaultSecondaryDocumentMutation, Types.DeleteDefaultSecondaryDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteDefaultSecondaryDocumentMutation, Types.DeleteDefaultSecondaryDocumentMutationVariables>(DeleteDefaultSecondaryDocumentDocument, options);
      }
export type DeleteDefaultSecondaryDocumentMutationHookResult = ReturnType<typeof useDeleteDefaultSecondaryDocumentMutation>;
export type DeleteDefaultSecondaryDocumentMutationResult = Apollo.MutationResult<Types.DeleteDefaultSecondaryDocumentMutation>;
export type DeleteDefaultSecondaryDocumentMutationOptions = Apollo.BaseMutationOptions<Types.DeleteDefaultSecondaryDocumentMutation, Types.DeleteDefaultSecondaryDocumentMutationVariables>;
export const DeleteDocumentDocument = gql`
    mutation deleteDocument($id: ID!) {
  deleteDocument(id: $id) {
    deleted
  }
}
    `;
export type DeleteDocumentMutationFn = Apollo.MutationFunction<Types.DeleteDocumentMutation, Types.DeleteDocumentMutationVariables>;

/**
 * __useDeleteDocumentMutation__
 *
 * To run a mutation, you first call `useDeleteDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDocumentMutation, { data, loading, error }] = useDeleteDocumentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDocumentMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteDocumentMutation, Types.DeleteDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteDocumentMutation, Types.DeleteDocumentMutationVariables>(DeleteDocumentDocument, options);
      }
export type DeleteDocumentMutationHookResult = ReturnType<typeof useDeleteDocumentMutation>;
export type DeleteDocumentMutationResult = Apollo.MutationResult<Types.DeleteDocumentMutation>;
export type DeleteDocumentMutationOptions = Apollo.BaseMutationOptions<Types.DeleteDocumentMutation, Types.DeleteDocumentMutationVariables>;
export const DeleteDocumentSectionDocument = gql`
    mutation deleteDocumentSection($id: ID!) {
  deleteDocumentSection(id: $id) {
    deleted
  }
}
    `;
export type DeleteDocumentSectionMutationFn = Apollo.MutationFunction<Types.DeleteDocumentSectionMutation, Types.DeleteDocumentSectionMutationVariables>;

/**
 * __useDeleteDocumentSectionMutation__
 *
 * To run a mutation, you first call `useDeleteDocumentSectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDocumentSectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDocumentSectionMutation, { data, loading, error }] = useDeleteDocumentSectionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDocumentSectionMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteDocumentSectionMutation, Types.DeleteDocumentSectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteDocumentSectionMutation, Types.DeleteDocumentSectionMutationVariables>(DeleteDocumentSectionDocument, options);
      }
export type DeleteDocumentSectionMutationHookResult = ReturnType<typeof useDeleteDocumentSectionMutation>;
export type DeleteDocumentSectionMutationResult = Apollo.MutationResult<Types.DeleteDocumentSectionMutation>;
export type DeleteDocumentSectionMutationOptions = Apollo.BaseMutationOptions<Types.DeleteDocumentSectionMutation, Types.DeleteDocumentSectionMutationVariables>;
export const DeleteDocumentVersionFieldsDocument = gql`
    mutation deleteDocumentVersionFields($ids: [ID!]!) {
  deleteDocumentVersionFields(ids: $ids) {
    deletedIds
  }
}
    `;
export type DeleteDocumentVersionFieldsMutationFn = Apollo.MutationFunction<Types.DeleteDocumentVersionFieldsMutation, Types.DeleteDocumentVersionFieldsMutationVariables>;

/**
 * __useDeleteDocumentVersionFieldsMutation__
 *
 * To run a mutation, you first call `useDeleteDocumentVersionFieldsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDocumentVersionFieldsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDocumentVersionFieldsMutation, { data, loading, error }] = useDeleteDocumentVersionFieldsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteDocumentVersionFieldsMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteDocumentVersionFieldsMutation, Types.DeleteDocumentVersionFieldsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteDocumentVersionFieldsMutation, Types.DeleteDocumentVersionFieldsMutationVariables>(DeleteDocumentVersionFieldsDocument, options);
      }
export type DeleteDocumentVersionFieldsMutationHookResult = ReturnType<typeof useDeleteDocumentVersionFieldsMutation>;
export type DeleteDocumentVersionFieldsMutationResult = Apollo.MutationResult<Types.DeleteDocumentVersionFieldsMutation>;
export type DeleteDocumentVersionFieldsMutationOptions = Apollo.BaseMutationOptions<Types.DeleteDocumentVersionFieldsMutation, Types.DeleteDocumentVersionFieldsMutationVariables>;
export const DeleteFieldDocument = gql`
    mutation deleteField($id: ID!) {
  deleteField(id: $id) {
    id
  }
}
    `;
export type DeleteFieldMutationFn = Apollo.MutationFunction<Types.DeleteFieldMutation, Types.DeleteFieldMutationVariables>;

/**
 * __useDeleteFieldMutation__
 *
 * To run a mutation, you first call `useDeleteFieldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFieldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFieldMutation, { data, loading, error }] = useDeleteFieldMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteFieldMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteFieldMutation, Types.DeleteFieldMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteFieldMutation, Types.DeleteFieldMutationVariables>(DeleteFieldDocument, options);
      }
export type DeleteFieldMutationHookResult = ReturnType<typeof useDeleteFieldMutation>;
export type DeleteFieldMutationResult = Apollo.MutationResult<Types.DeleteFieldMutation>;
export type DeleteFieldMutationOptions = Apollo.BaseMutationOptions<Types.DeleteFieldMutation, Types.DeleteFieldMutationVariables>;
export const DeletePreapprovalContractDocumentDocument = gql`
    mutation deletePreapprovalContractDocument($input: DeletePreapprovalContractDocumentInput!) {
  deletePreapprovalContractDocument(input: $input) {
    contract {
      id
    }
  }
}
    `;
export type DeletePreapprovalContractDocumentMutationFn = Apollo.MutationFunction<Types.DeletePreapprovalContractDocumentMutation, Types.DeletePreapprovalContractDocumentMutationVariables>;

/**
 * __useDeletePreapprovalContractDocumentMutation__
 *
 * To run a mutation, you first call `useDeletePreapprovalContractDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePreapprovalContractDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePreapprovalContractDocumentMutation, { data, loading, error }] = useDeletePreapprovalContractDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePreapprovalContractDocumentMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeletePreapprovalContractDocumentMutation, Types.DeletePreapprovalContractDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeletePreapprovalContractDocumentMutation, Types.DeletePreapprovalContractDocumentMutationVariables>(DeletePreapprovalContractDocumentDocument, options);
      }
export type DeletePreapprovalContractDocumentMutationHookResult = ReturnType<typeof useDeletePreapprovalContractDocumentMutation>;
export type DeletePreapprovalContractDocumentMutationResult = Apollo.MutationResult<Types.DeletePreapprovalContractDocumentMutation>;
export type DeletePreapprovalContractDocumentMutationOptions = Apollo.BaseMutationOptions<Types.DeletePreapprovalContractDocumentMutation, Types.DeletePreapprovalContractDocumentMutationVariables>;
export const DeleteRuleDocument = gql`
    mutation deleteRule($id: ID!) {
  deleteRule(id: $id) {
    id
  }
}
    `;
export type DeleteRuleMutationFn = Apollo.MutationFunction<Types.DeleteRuleMutation, Types.DeleteRuleMutationVariables>;

/**
 * __useDeleteRuleMutation__
 *
 * To run a mutation, you first call `useDeleteRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRuleMutation, { data, loading, error }] = useDeleteRuleMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteRuleMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteRuleMutation, Types.DeleteRuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteRuleMutation, Types.DeleteRuleMutationVariables>(DeleteRuleDocument, options);
      }
export type DeleteRuleMutationHookResult = ReturnType<typeof useDeleteRuleMutation>;
export type DeleteRuleMutationResult = Apollo.MutationResult<Types.DeleteRuleMutation>;
export type DeleteRuleMutationOptions = Apollo.BaseMutationOptions<Types.DeleteRuleMutation, Types.DeleteRuleMutationVariables>;
export const DetectFormFieldsDocument = gql`
    mutation detectFormFields($input: ExtractContractInput!) {
  detectFormFields(input: $input) {
    ...DetectFormFields
  }
}
    ${DetectFormFieldsFragmentDoc}`;
export type DetectFormFieldsMutationFn = Apollo.MutationFunction<Types.DetectFormFieldsMutation, Types.DetectFormFieldsMutationVariables>;

/**
 * __useDetectFormFieldsMutation__
 *
 * To run a mutation, you first call `useDetectFormFieldsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDetectFormFieldsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [detectFormFieldsMutation, { data, loading, error }] = useDetectFormFieldsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDetectFormFieldsMutation(baseOptions?: Apollo.MutationHookOptions<Types.DetectFormFieldsMutation, Types.DetectFormFieldsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DetectFormFieldsMutation, Types.DetectFormFieldsMutationVariables>(DetectFormFieldsDocument, options);
      }
export type DetectFormFieldsMutationHookResult = ReturnType<typeof useDetectFormFieldsMutation>;
export type DetectFormFieldsMutationResult = Apollo.MutationResult<Types.DetectFormFieldsMutation>;
export type DetectFormFieldsMutationOptions = Apollo.BaseMutationOptions<Types.DetectFormFieldsMutation, Types.DetectFormFieldsMutationVariables>;
export const DetectFormFieldsForDocumentVersionDocument = gql`
    mutation detectFormFieldsForDocumentVersion($id: ID!) {
  detectFormFieldsForDocumentVersion(id: $id) {
    documentVersion {
      id
      documentVersionFieldsCount
    }
  }
}
    `;
export type DetectFormFieldsForDocumentVersionMutationFn = Apollo.MutationFunction<Types.DetectFormFieldsForDocumentVersionMutation, Types.DetectFormFieldsForDocumentVersionMutationVariables>;

/**
 * __useDetectFormFieldsForDocumentVersionMutation__
 *
 * To run a mutation, you first call `useDetectFormFieldsForDocumentVersionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDetectFormFieldsForDocumentVersionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [detectFormFieldsForDocumentVersionMutation, { data, loading, error }] = useDetectFormFieldsForDocumentVersionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDetectFormFieldsForDocumentVersionMutation(baseOptions?: Apollo.MutationHookOptions<Types.DetectFormFieldsForDocumentVersionMutation, Types.DetectFormFieldsForDocumentVersionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DetectFormFieldsForDocumentVersionMutation, Types.DetectFormFieldsForDocumentVersionMutationVariables>(DetectFormFieldsForDocumentVersionDocument, options);
      }
export type DetectFormFieldsForDocumentVersionMutationHookResult = ReturnType<typeof useDetectFormFieldsForDocumentVersionMutation>;
export type DetectFormFieldsForDocumentVersionMutationResult = Apollo.MutationResult<Types.DetectFormFieldsForDocumentVersionMutation>;
export type DetectFormFieldsForDocumentVersionMutationOptions = Apollo.BaseMutationOptions<Types.DetectFormFieldsForDocumentVersionMutation, Types.DetectFormFieldsForDocumentVersionMutationVariables>;
export const ExtractContractDocument = gql`
    mutation extractContract($input: [ExtractContractInput!]!) {
  extractContract(inputs: $input) {
    ...ExtractContractMutation
  }
}
    ${ExtractContractMutationFragmentDoc}`;
export type ExtractContractMutationFn = Apollo.MutationFunction<Types.ExtractContractMutation, Types.ExtractContractMutationVariables>;

/**
 * __useExtractContractMutation__
 *
 * To run a mutation, you first call `useExtractContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExtractContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [extractContractMutation, { data, loading, error }] = useExtractContractMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExtractContractMutation(baseOptions?: Apollo.MutationHookOptions<Types.ExtractContractMutation, Types.ExtractContractMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ExtractContractMutation, Types.ExtractContractMutationVariables>(ExtractContractDocument, options);
      }
export type ExtractContractMutationHookResult = ReturnType<typeof useExtractContractMutation>;
export type ExtractContractMutationResult = Apollo.MutationResult<Types.ExtractContractMutation>;
export type ExtractContractMutationOptions = Apollo.BaseMutationOptions<Types.ExtractContractMutation, Types.ExtractContractMutationVariables>;
export const ExtractPreapprovalInfoDocument = gql`
    mutation extractPreapprovalInfo($input: ExtractPreapprovalInfoInput!) {
  extractPreapprovalInfo(input: $input) {
    primary_buyer
    co_buyer
    loan_type
    loan_amount
    purchase_price
  }
}
    `;
export type ExtractPreapprovalInfoMutationFn = Apollo.MutationFunction<Types.ExtractPreapprovalInfoMutation, Types.ExtractPreapprovalInfoMutationVariables>;

/**
 * __useExtractPreapprovalInfoMutation__
 *
 * To run a mutation, you first call `useExtractPreapprovalInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExtractPreapprovalInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [extractPreapprovalInfoMutation, { data, loading, error }] = useExtractPreapprovalInfoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExtractPreapprovalInfoMutation(baseOptions?: Apollo.MutationHookOptions<Types.ExtractPreapprovalInfoMutation, Types.ExtractPreapprovalInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ExtractPreapprovalInfoMutation, Types.ExtractPreapprovalInfoMutationVariables>(ExtractPreapprovalInfoDocument, options);
      }
export type ExtractPreapprovalInfoMutationHookResult = ReturnType<typeof useExtractPreapprovalInfoMutation>;
export type ExtractPreapprovalInfoMutationResult = Apollo.MutationResult<Types.ExtractPreapprovalInfoMutation>;
export type ExtractPreapprovalInfoMutationOptions = Apollo.BaseMutationOptions<Types.ExtractPreapprovalInfoMutation, Types.ExtractPreapprovalInfoMutationVariables>;
export const SetDocumentVersionAsActiveDocument = gql`
    mutation setDocumentVersionAsActive($input: SetDocumentVersionAsActiveInput!) {
  setDocumentVersionAsActive(input: $input) {
    documentVersion {
      id
      document {
        id
        documentVersions {
          id
          status
        }
      }
    }
  }
}
    `;
export type SetDocumentVersionAsActiveMutationFn = Apollo.MutationFunction<Types.SetDocumentVersionAsActiveMutation, Types.SetDocumentVersionAsActiveMutationVariables>;

/**
 * __useSetDocumentVersionAsActiveMutation__
 *
 * To run a mutation, you first call `useSetDocumentVersionAsActiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetDocumentVersionAsActiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setDocumentVersionAsActiveMutation, { data, loading, error }] = useSetDocumentVersionAsActiveMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetDocumentVersionAsActiveMutation(baseOptions?: Apollo.MutationHookOptions<Types.SetDocumentVersionAsActiveMutation, Types.SetDocumentVersionAsActiveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SetDocumentVersionAsActiveMutation, Types.SetDocumentVersionAsActiveMutationVariables>(SetDocumentVersionAsActiveDocument, options);
      }
export type SetDocumentVersionAsActiveMutationHookResult = ReturnType<typeof useSetDocumentVersionAsActiveMutation>;
export type SetDocumentVersionAsActiveMutationResult = Apollo.MutationResult<Types.SetDocumentVersionAsActiveMutation>;
export type SetDocumentVersionAsActiveMutationOptions = Apollo.BaseMutationOptions<Types.SetDocumentVersionAsActiveMutation, Types.SetDocumentVersionAsActiveMutationVariables>;
export const SyncAcroformToDocumentVersionFieldsDocument = gql`
    mutation syncAcroformToDocumentVersionFields($input: SyncAcroformToDocumentVersionFieldsInput!) {
  syncAcroformToDocumentVersionFields(input: $input) {
    documentVersion {
      id
      documentVersionFieldsCount
    }
  }
}
    `;
export type SyncAcroformToDocumentVersionFieldsMutationFn = Apollo.MutationFunction<Types.SyncAcroformToDocumentVersionFieldsMutation, Types.SyncAcroformToDocumentVersionFieldsMutationVariables>;

/**
 * __useSyncAcroformToDocumentVersionFieldsMutation__
 *
 * To run a mutation, you first call `useSyncAcroformToDocumentVersionFieldsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncAcroformToDocumentVersionFieldsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncAcroformToDocumentVersionFieldsMutation, { data, loading, error }] = useSyncAcroformToDocumentVersionFieldsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSyncAcroformToDocumentVersionFieldsMutation(baseOptions?: Apollo.MutationHookOptions<Types.SyncAcroformToDocumentVersionFieldsMutation, Types.SyncAcroformToDocumentVersionFieldsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SyncAcroformToDocumentVersionFieldsMutation, Types.SyncAcroformToDocumentVersionFieldsMutationVariables>(SyncAcroformToDocumentVersionFieldsDocument, options);
      }
export type SyncAcroformToDocumentVersionFieldsMutationHookResult = ReturnType<typeof useSyncAcroformToDocumentVersionFieldsMutation>;
export type SyncAcroformToDocumentVersionFieldsMutationResult = Apollo.MutationResult<Types.SyncAcroformToDocumentVersionFieldsMutation>;
export type SyncAcroformToDocumentVersionFieldsMutationOptions = Apollo.BaseMutationOptions<Types.SyncAcroformToDocumentVersionFieldsMutation, Types.SyncAcroformToDocumentVersionFieldsMutationVariables>;
export const SyncSecondaryDocumentVersionsDocument = gql`
    mutation syncSecondaryDocumentVersions($input: SyncSecondaryDocumentVersionsInput!) {
  syncSecondaryDocumentVersions(input: $input) {
    documentVersion {
      id
      secondaryDocumentVersions {
        ...SecondaryDocumentVersion
      }
    }
  }
}
    ${SecondaryDocumentVersionFragmentDoc}`;
export type SyncSecondaryDocumentVersionsMutationFn = Apollo.MutationFunction<Types.SyncSecondaryDocumentVersionsMutation, Types.SyncSecondaryDocumentVersionsMutationVariables>;

/**
 * __useSyncSecondaryDocumentVersionsMutation__
 *
 * To run a mutation, you first call `useSyncSecondaryDocumentVersionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncSecondaryDocumentVersionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncSecondaryDocumentVersionsMutation, { data, loading, error }] = useSyncSecondaryDocumentVersionsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSyncSecondaryDocumentVersionsMutation(baseOptions?: Apollo.MutationHookOptions<Types.SyncSecondaryDocumentVersionsMutation, Types.SyncSecondaryDocumentVersionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SyncSecondaryDocumentVersionsMutation, Types.SyncSecondaryDocumentVersionsMutationVariables>(SyncSecondaryDocumentVersionsDocument, options);
      }
export type SyncSecondaryDocumentVersionsMutationHookResult = ReturnType<typeof useSyncSecondaryDocumentVersionsMutation>;
export type SyncSecondaryDocumentVersionsMutationResult = Apollo.MutationResult<Types.SyncSecondaryDocumentVersionsMutation>;
export type SyncSecondaryDocumentVersionsMutationOptions = Apollo.BaseMutationOptions<Types.SyncSecondaryDocumentVersionsMutation, Types.SyncSecondaryDocumentVersionsMutationVariables>;
export const UpdateAutofillDocument = gql`
    mutation updateAutofill($input: UpdateAutofillInput!) {
  updateAutofill(input: $input) {
    autofill {
      ...Autofill
    }
  }
}
    ${AutofillFragmentDoc}`;
export type UpdateAutofillMutationFn = Apollo.MutationFunction<Types.UpdateAutofillMutation, Types.UpdateAutofillMutationVariables>;

/**
 * __useUpdateAutofillMutation__
 *
 * To run a mutation, you first call `useUpdateAutofillMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAutofillMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAutofillMutation, { data, loading, error }] = useUpdateAutofillMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAutofillMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateAutofillMutation, Types.UpdateAutofillMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateAutofillMutation, Types.UpdateAutofillMutationVariables>(UpdateAutofillDocument, options);
      }
export type UpdateAutofillMutationHookResult = ReturnType<typeof useUpdateAutofillMutation>;
export type UpdateAutofillMutationResult = Apollo.MutationResult<Types.UpdateAutofillMutation>;
export type UpdateAutofillMutationOptions = Apollo.BaseMutationOptions<Types.UpdateAutofillMutation, Types.UpdateAutofillMutationVariables>;
export const UpdateContractDocument = gql`
    mutation updateContract($input: UpdateContractInput!) {
  updateContract(input: $input) {
    contract {
      id
      fieldValues
    }
  }
}
    `;
export type UpdateContractMutationFn = Apollo.MutationFunction<Types.UpdateContractMutation, Types.UpdateContractMutationVariables>;

/**
 * __useUpdateContractMutation__
 *
 * To run a mutation, you first call `useUpdateContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContractMutation, { data, loading, error }] = useUpdateContractMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateContractMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateContractMutation, Types.UpdateContractMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateContractMutation, Types.UpdateContractMutationVariables>(UpdateContractDocument, options);
      }
export type UpdateContractMutationHookResult = ReturnType<typeof useUpdateContractMutation>;
export type UpdateContractMutationResult = Apollo.MutationResult<Types.UpdateContractMutation>;
export type UpdateContractMutationOptions = Apollo.BaseMutationOptions<Types.UpdateContractMutation, Types.UpdateContractMutationVariables>;
export const UpdateContractDocumentCustomFieldsDocument = gql`
    mutation UpdateContractDocumentCustomFields($input: UpdateContractDocumentCustomFieldsInput!) {
  updateContractDocumentCustomFields(input: $input) {
    id
    fields {
      ...DocumentVersionField
    }
  }
}
    ${DocumentVersionFieldFragmentDoc}`;
export type UpdateContractDocumentCustomFieldsMutationFn = Apollo.MutationFunction<Types.UpdateContractDocumentCustomFieldsMutation, Types.UpdateContractDocumentCustomFieldsMutationVariables>;

/**
 * __useUpdateContractDocumentCustomFieldsMutation__
 *
 * To run a mutation, you first call `useUpdateContractDocumentCustomFieldsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContractDocumentCustomFieldsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContractDocumentCustomFieldsMutation, { data, loading, error }] = useUpdateContractDocumentCustomFieldsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateContractDocumentCustomFieldsMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateContractDocumentCustomFieldsMutation, Types.UpdateContractDocumentCustomFieldsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateContractDocumentCustomFieldsMutation, Types.UpdateContractDocumentCustomFieldsMutationVariables>(UpdateContractDocumentCustomFieldsDocument, options);
      }
export type UpdateContractDocumentCustomFieldsMutationHookResult = ReturnType<typeof useUpdateContractDocumentCustomFieldsMutation>;
export type UpdateContractDocumentCustomFieldsMutationResult = Apollo.MutationResult<Types.UpdateContractDocumentCustomFieldsMutation>;
export type UpdateContractDocumentCustomFieldsMutationOptions = Apollo.BaseMutationOptions<Types.UpdateContractDocumentCustomFieldsMutation, Types.UpdateContractDocumentCustomFieldsMutationVariables>;
export const UpdateContractDocumentsDocument = gql`
    mutation updateContractDocuments($input: UpdateContractDocumentsInput!) {
  updateContractDocuments(input: $input) {
    contract {
      id
      contractDocuments {
        ...ContractDocumentForContract
      }
    }
  }
}
    ${ContractDocumentForContractFragmentDoc}`;
export type UpdateContractDocumentsMutationFn = Apollo.MutationFunction<Types.UpdateContractDocumentsMutation, Types.UpdateContractDocumentsMutationVariables>;

/**
 * __useUpdateContractDocumentsMutation__
 *
 * To run a mutation, you first call `useUpdateContractDocumentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContractDocumentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContractDocumentsMutation, { data, loading, error }] = useUpdateContractDocumentsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateContractDocumentsMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateContractDocumentsMutation, Types.UpdateContractDocumentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateContractDocumentsMutation, Types.UpdateContractDocumentsMutationVariables>(UpdateContractDocumentsDocument, options);
      }
export type UpdateContractDocumentsMutationHookResult = ReturnType<typeof useUpdateContractDocumentsMutation>;
export type UpdateContractDocumentsMutationResult = Apollo.MutationResult<Types.UpdateContractDocumentsMutation>;
export type UpdateContractDocumentsMutationOptions = Apollo.BaseMutationOptions<Types.UpdateContractDocumentsMutation, Types.UpdateContractDocumentsMutationVariables>;
export const UpdateDefaultSecondaryDocumentsDocument = gql`
    mutation updateDefaultSecondaryDocuments($input: [UpdateDefaultSecondaryDocumentInput!]!) {
  updateDefaultSecondaryDocuments(input: $input) {
    defaultSecondaryDocuments {
      ...DefaultSecondaryDocument
    }
  }
}
    ${DefaultSecondaryDocumentFragmentDoc}`;
export type UpdateDefaultSecondaryDocumentsMutationFn = Apollo.MutationFunction<Types.UpdateDefaultSecondaryDocumentsMutation, Types.UpdateDefaultSecondaryDocumentsMutationVariables>;

/**
 * __useUpdateDefaultSecondaryDocumentsMutation__
 *
 * To run a mutation, you first call `useUpdateDefaultSecondaryDocumentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDefaultSecondaryDocumentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDefaultSecondaryDocumentsMutation, { data, loading, error }] = useUpdateDefaultSecondaryDocumentsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDefaultSecondaryDocumentsMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateDefaultSecondaryDocumentsMutation, Types.UpdateDefaultSecondaryDocumentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateDefaultSecondaryDocumentsMutation, Types.UpdateDefaultSecondaryDocumentsMutationVariables>(UpdateDefaultSecondaryDocumentsDocument, options);
      }
export type UpdateDefaultSecondaryDocumentsMutationHookResult = ReturnType<typeof useUpdateDefaultSecondaryDocumentsMutation>;
export type UpdateDefaultSecondaryDocumentsMutationResult = Apollo.MutationResult<Types.UpdateDefaultSecondaryDocumentsMutation>;
export type UpdateDefaultSecondaryDocumentsMutationOptions = Apollo.BaseMutationOptions<Types.UpdateDefaultSecondaryDocumentsMutation, Types.UpdateDefaultSecondaryDocumentsMutationVariables>;
export const UpdateDocumentDocument = gql`
    mutation updateDocument($input: UpdateDocumentInput!) {
  updateDocument(input: $input) {
    document {
      ...Document
    }
  }
}
    ${DocumentFragmentDoc}`;
export type UpdateDocumentMutationFn = Apollo.MutationFunction<Types.UpdateDocumentMutation, Types.UpdateDocumentMutationVariables>;

/**
 * __useUpdateDocumentMutation__
 *
 * To run a mutation, you first call `useUpdateDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDocumentMutation, { data, loading, error }] = useUpdateDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDocumentMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateDocumentMutation, Types.UpdateDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateDocumentMutation, Types.UpdateDocumentMutationVariables>(UpdateDocumentDocument, options);
      }
export type UpdateDocumentMutationHookResult = ReturnType<typeof useUpdateDocumentMutation>;
export type UpdateDocumentMutationResult = Apollo.MutationResult<Types.UpdateDocumentMutation>;
export type UpdateDocumentMutationOptions = Apollo.BaseMutationOptions<Types.UpdateDocumentMutation, Types.UpdateDocumentMutationVariables>;
export const UpdateDocumentSectionFieldsDocument = gql`
    mutation updateDocumentSectionFields($input: UpdateDocumentSectionFieldsInput!) {
  updateDocumentSectionFields(input: $input) {
    documentSectionFields {
      id
    }
  }
}
    `;
export type UpdateDocumentSectionFieldsMutationFn = Apollo.MutationFunction<Types.UpdateDocumentSectionFieldsMutation, Types.UpdateDocumentSectionFieldsMutationVariables>;

/**
 * __useUpdateDocumentSectionFieldsMutation__
 *
 * To run a mutation, you first call `useUpdateDocumentSectionFieldsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDocumentSectionFieldsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDocumentSectionFieldsMutation, { data, loading, error }] = useUpdateDocumentSectionFieldsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDocumentSectionFieldsMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateDocumentSectionFieldsMutation, Types.UpdateDocumentSectionFieldsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateDocumentSectionFieldsMutation, Types.UpdateDocumentSectionFieldsMutationVariables>(UpdateDocumentSectionFieldsDocument, options);
      }
export type UpdateDocumentSectionFieldsMutationHookResult = ReturnType<typeof useUpdateDocumentSectionFieldsMutation>;
export type UpdateDocumentSectionFieldsMutationResult = Apollo.MutationResult<Types.UpdateDocumentSectionFieldsMutation>;
export type UpdateDocumentSectionFieldsMutationOptions = Apollo.BaseMutationOptions<Types.UpdateDocumentSectionFieldsMutation, Types.UpdateDocumentSectionFieldsMutationVariables>;
export const UpdateDocumentSectionsDocument = gql`
    mutation updateDocumentSections($inputs: [UpdateDocumentSectionInput!]!) {
  updateDocumentSections(inputs: $inputs) {
    documentSections {
      ...DocumentSectionList
    }
  }
}
    ${DocumentSectionListFragmentDoc}`;
export type UpdateDocumentSectionsMutationFn = Apollo.MutationFunction<Types.UpdateDocumentSectionsMutation, Types.UpdateDocumentSectionsMutationVariables>;

/**
 * __useUpdateDocumentSectionsMutation__
 *
 * To run a mutation, you first call `useUpdateDocumentSectionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDocumentSectionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDocumentSectionsMutation, { data, loading, error }] = useUpdateDocumentSectionsMutation({
 *   variables: {
 *      inputs: // value for 'inputs'
 *   },
 * });
 */
export function useUpdateDocumentSectionsMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateDocumentSectionsMutation, Types.UpdateDocumentSectionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateDocumentSectionsMutation, Types.UpdateDocumentSectionsMutationVariables>(UpdateDocumentSectionsDocument, options);
      }
export type UpdateDocumentSectionsMutationHookResult = ReturnType<typeof useUpdateDocumentSectionsMutation>;
export type UpdateDocumentSectionsMutationResult = Apollo.MutationResult<Types.UpdateDocumentSectionsMutation>;
export type UpdateDocumentSectionsMutationOptions = Apollo.BaseMutationOptions<Types.UpdateDocumentSectionsMutation, Types.UpdateDocumentSectionsMutationVariables>;
export const UpdateDocumentSectionsOrderDocument = gql`
    mutation updateDocumentSectionsOrder($inputs: [UpdateDocumentSectionsOrderInput!]!) {
  updateDocumentSectionsOrder(inputs: $inputs) {
    documentSections {
      id
    }
  }
}
    `;
export type UpdateDocumentSectionsOrderMutationFn = Apollo.MutationFunction<Types.UpdateDocumentSectionsOrderMutation, Types.UpdateDocumentSectionsOrderMutationVariables>;

/**
 * __useUpdateDocumentSectionsOrderMutation__
 *
 * To run a mutation, you first call `useUpdateDocumentSectionsOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDocumentSectionsOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDocumentSectionsOrderMutation, { data, loading, error }] = useUpdateDocumentSectionsOrderMutation({
 *   variables: {
 *      inputs: // value for 'inputs'
 *   },
 * });
 */
export function useUpdateDocumentSectionsOrderMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateDocumentSectionsOrderMutation, Types.UpdateDocumentSectionsOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateDocumentSectionsOrderMutation, Types.UpdateDocumentSectionsOrderMutationVariables>(UpdateDocumentSectionsOrderDocument, options);
      }
export type UpdateDocumentSectionsOrderMutationHookResult = ReturnType<typeof useUpdateDocumentSectionsOrderMutation>;
export type UpdateDocumentSectionsOrderMutationResult = Apollo.MutationResult<Types.UpdateDocumentSectionsOrderMutation>;
export type UpdateDocumentSectionsOrderMutationOptions = Apollo.BaseMutationOptions<Types.UpdateDocumentSectionsOrderMutation, Types.UpdateDocumentSectionsOrderMutationVariables>;
export const UpdateDocumentVersionDocument = gql`
    mutation updateDocumentVersion($id: ID!, $input: UpdateDocumentVersionInput!) {
  updateDocumentVersion(id: $id, input: $input) {
    documentVersion {
      id
      version
      searchRegex
      file {
        fileName
        url
      }
    }
  }
}
    `;
export type UpdateDocumentVersionMutationFn = Apollo.MutationFunction<Types.UpdateDocumentVersionMutation, Types.UpdateDocumentVersionMutationVariables>;

/**
 * __useUpdateDocumentVersionMutation__
 *
 * To run a mutation, you first call `useUpdateDocumentVersionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDocumentVersionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDocumentVersionMutation, { data, loading, error }] = useUpdateDocumentVersionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDocumentVersionMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateDocumentVersionMutation, Types.UpdateDocumentVersionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateDocumentVersionMutation, Types.UpdateDocumentVersionMutationVariables>(UpdateDocumentVersionDocument, options);
      }
export type UpdateDocumentVersionMutationHookResult = ReturnType<typeof useUpdateDocumentVersionMutation>;
export type UpdateDocumentVersionMutationResult = Apollo.MutationResult<Types.UpdateDocumentVersionMutation>;
export type UpdateDocumentVersionMutationOptions = Apollo.BaseMutationOptions<Types.UpdateDocumentVersionMutation, Types.UpdateDocumentVersionMutationVariables>;
export const UpdateDocumentVersionFieldsDocument = gql`
    mutation updateDocumentVersionFields($inputs: [UpdateDocumentVersionFieldInput!]!) {
  updateDocumentVersionFields(inputs: $inputs) {
    documentVersionFields {
      ...DocumentVersionField
      field {
        ...Field
      }
    }
  }
}
    ${DocumentVersionFieldFragmentDoc}
${FieldFragmentDoc}`;
export type UpdateDocumentVersionFieldsMutationFn = Apollo.MutationFunction<Types.UpdateDocumentVersionFieldsMutation, Types.UpdateDocumentVersionFieldsMutationVariables>;

/**
 * __useUpdateDocumentVersionFieldsMutation__
 *
 * To run a mutation, you first call `useUpdateDocumentVersionFieldsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDocumentVersionFieldsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDocumentVersionFieldsMutation, { data, loading, error }] = useUpdateDocumentVersionFieldsMutation({
 *   variables: {
 *      inputs: // value for 'inputs'
 *   },
 * });
 */
export function useUpdateDocumentVersionFieldsMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateDocumentVersionFieldsMutation, Types.UpdateDocumentVersionFieldsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateDocumentVersionFieldsMutation, Types.UpdateDocumentVersionFieldsMutationVariables>(UpdateDocumentVersionFieldsDocument, options);
      }
export type UpdateDocumentVersionFieldsMutationHookResult = ReturnType<typeof useUpdateDocumentVersionFieldsMutation>;
export type UpdateDocumentVersionFieldsMutationResult = Apollo.MutationResult<Types.UpdateDocumentVersionFieldsMutation>;
export type UpdateDocumentVersionFieldsMutationOptions = Apollo.BaseMutationOptions<Types.UpdateDocumentVersionFieldsMutation, Types.UpdateDocumentVersionFieldsMutationVariables>;
export const UpdateFieldDocument = gql`
    mutation updateField($input: UpdateFieldInput!) {
  updateField(input: $input) {
    field {
      ...Field
      ...FieldDetail
    }
  }
}
    ${FieldFragmentDoc}
${FieldDetailFragmentDoc}`;
export type UpdateFieldMutationFn = Apollo.MutationFunction<Types.UpdateFieldMutation, Types.UpdateFieldMutationVariables>;

/**
 * __useUpdateFieldMutation__
 *
 * To run a mutation, you first call `useUpdateFieldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFieldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFieldMutation, { data, loading, error }] = useUpdateFieldMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateFieldMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateFieldMutation, Types.UpdateFieldMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateFieldMutation, Types.UpdateFieldMutationVariables>(UpdateFieldDocument, options);
      }
export type UpdateFieldMutationHookResult = ReturnType<typeof useUpdateFieldMutation>;
export type UpdateFieldMutationResult = Apollo.MutationResult<Types.UpdateFieldMutation>;
export type UpdateFieldMutationOptions = Apollo.BaseMutationOptions<Types.UpdateFieldMutation, Types.UpdateFieldMutationVariables>;
export const UpdateOrCreateFieldsToColumnDocument = gql`
    mutation updateOrCreateFieldsToColumn($input: UpdateOrCreateFieldsToColumnInput!) {
  updateOrCreateFieldsToColumn(input: $input) {
    ...FieldsToColumn
  }
}
    ${FieldsToColumnFragmentDoc}`;
export type UpdateOrCreateFieldsToColumnMutationFn = Apollo.MutationFunction<Types.UpdateOrCreateFieldsToColumnMutation, Types.UpdateOrCreateFieldsToColumnMutationVariables>;

/**
 * __useUpdateOrCreateFieldsToColumnMutation__
 *
 * To run a mutation, you first call `useUpdateOrCreateFieldsToColumnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrCreateFieldsToColumnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrCreateFieldsToColumnMutation, { data, loading, error }] = useUpdateOrCreateFieldsToColumnMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrCreateFieldsToColumnMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateOrCreateFieldsToColumnMutation, Types.UpdateOrCreateFieldsToColumnMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateOrCreateFieldsToColumnMutation, Types.UpdateOrCreateFieldsToColumnMutationVariables>(UpdateOrCreateFieldsToColumnDocument, options);
      }
export type UpdateOrCreateFieldsToColumnMutationHookResult = ReturnType<typeof useUpdateOrCreateFieldsToColumnMutation>;
export type UpdateOrCreateFieldsToColumnMutationResult = Apollo.MutationResult<Types.UpdateOrCreateFieldsToColumnMutation>;
export type UpdateOrCreateFieldsToColumnMutationOptions = Apollo.BaseMutationOptions<Types.UpdateOrCreateFieldsToColumnMutation, Types.UpdateOrCreateFieldsToColumnMutationVariables>;
export const UpdateRuleDocument = gql`
    mutation updateRule($input: UpdateRuleInput!) {
  updateRule(input: $input) {
    rule {
      ...Rule
    }
  }
}
    ${RuleFragmentDoc}`;
export type UpdateRuleMutationFn = Apollo.MutationFunction<Types.UpdateRuleMutation, Types.UpdateRuleMutationVariables>;

/**
 * __useUpdateRuleMutation__
 *
 * To run a mutation, you first call `useUpdateRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRuleMutation, { data, loading, error }] = useUpdateRuleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRuleMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateRuleMutation, Types.UpdateRuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateRuleMutation, Types.UpdateRuleMutationVariables>(UpdateRuleDocument, options);
      }
export type UpdateRuleMutationHookResult = ReturnType<typeof useUpdateRuleMutation>;
export type UpdateRuleMutationResult = Apollo.MutationResult<Types.UpdateRuleMutation>;
export type UpdateRuleMutationOptions = Apollo.BaseMutationOptions<Types.UpdateRuleMutation, Types.UpdateRuleMutationVariables>;
export const UpdateSecondaryDocumentVersionDocument = gql`
    mutation updateSecondaryDocumentVersion($input: UpdateSecondaryDocumentVersionInput!) {
  updateSecondaryDocumentVersion(input: $input) {
    secondaryDocumentVersion {
      ...SecondaryDocumentVersion
    }
  }
}
    ${SecondaryDocumentVersionFragmentDoc}`;
export type UpdateSecondaryDocumentVersionMutationFn = Apollo.MutationFunction<Types.UpdateSecondaryDocumentVersionMutation, Types.UpdateSecondaryDocumentVersionMutationVariables>;

/**
 * __useUpdateSecondaryDocumentVersionMutation__
 *
 * To run a mutation, you first call `useUpdateSecondaryDocumentVersionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSecondaryDocumentVersionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSecondaryDocumentVersionMutation, { data, loading, error }] = useUpdateSecondaryDocumentVersionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSecondaryDocumentVersionMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateSecondaryDocumentVersionMutation, Types.UpdateSecondaryDocumentVersionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateSecondaryDocumentVersionMutation, Types.UpdateSecondaryDocumentVersionMutationVariables>(UpdateSecondaryDocumentVersionDocument, options);
      }
export type UpdateSecondaryDocumentVersionMutationHookResult = ReturnType<typeof useUpdateSecondaryDocumentVersionMutation>;
export type UpdateSecondaryDocumentVersionMutationResult = Apollo.MutationResult<Types.UpdateSecondaryDocumentVersionMutation>;
export type UpdateSecondaryDocumentVersionMutationOptions = Apollo.BaseMutationOptions<Types.UpdateSecondaryDocumentVersionMutation, Types.UpdateSecondaryDocumentVersionMutationVariables>;
export const ContractByIdDocument = gql`
    query contractById($id: ID!, $resolveFieldValues: Boolean = false, $documentML: Boolean = false, $documentVersionFields: Boolean = false, $extractedValues: Boolean = false, $documentVersionFile: Boolean = false, $autofill: Boolean = true) {
  contractById(id: $id, resolveFieldValues: $resolveFieldValues) {
    ...ContractDetail
  }
}
    ${ContractDetailFragmentDoc}`;

/**
 * __useContractByIdQuery__
 *
 * To run a query within a React component, call `useContractByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContractByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *      resolveFieldValues: // value for 'resolveFieldValues'
 *      documentML: // value for 'documentML'
 *      documentVersionFields: // value for 'documentVersionFields'
 *      extractedValues: // value for 'extractedValues'
 *      documentVersionFile: // value for 'documentVersionFile'
 *      autofill: // value for 'autofill'
 *   },
 * });
 */
export function useContractByIdQuery(baseOptions: Apollo.QueryHookOptions<Types.ContractByIdQuery, Types.ContractByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ContractByIdQuery, Types.ContractByIdQueryVariables>(ContractByIdDocument, options);
      }
export function useContractByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ContractByIdQuery, Types.ContractByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ContractByIdQuery, Types.ContractByIdQueryVariables>(ContractByIdDocument, options);
        }
export type ContractByIdQueryHookResult = ReturnType<typeof useContractByIdQuery>;
export type ContractByIdLazyQueryHookResult = ReturnType<typeof useContractByIdLazyQuery>;
export type ContractByIdQueryResult = Apollo.QueryResult<Types.ContractByIdQuery, Types.ContractByIdQueryVariables>;
export const ContractFieldValuesDocument = gql`
    query ContractFieldValues($ids: [ID!]!, $resolveFieldValues: Boolean = false) {
  contractByIds(ids: $ids, resolveFieldValues: $resolveFieldValues) {
    ...ContractFieldValues
  }
}
    ${ContractFieldValuesFragmentDoc}`;

/**
 * __useContractFieldValuesQuery__
 *
 * To run a query within a React component, call `useContractFieldValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractFieldValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContractFieldValuesQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *      resolveFieldValues: // value for 'resolveFieldValues'
 *   },
 * });
 */
export function useContractFieldValuesQuery(baseOptions: Apollo.QueryHookOptions<Types.ContractFieldValuesQuery, Types.ContractFieldValuesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ContractFieldValuesQuery, Types.ContractFieldValuesQueryVariables>(ContractFieldValuesDocument, options);
      }
export function useContractFieldValuesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ContractFieldValuesQuery, Types.ContractFieldValuesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ContractFieldValuesQuery, Types.ContractFieldValuesQueryVariables>(ContractFieldValuesDocument, options);
        }
export type ContractFieldValuesQueryHookResult = ReturnType<typeof useContractFieldValuesQuery>;
export type ContractFieldValuesLazyQueryHookResult = ReturnType<typeof useContractFieldValuesLazyQuery>;
export type ContractFieldValuesQueryResult = Apollo.QueryResult<Types.ContractFieldValuesQuery, Types.ContractFieldValuesQueryVariables>;
export const ContractListDocument = gql`
    query contractList($cursor: String, $orderBy: String, $perPage: Int! = 20, $filters: ContractListFilterArgs, $extractedValues: Boolean! = false, $documentML: Boolean! = false, $documentVersionFields: Boolean! = false, $documentVersionFile: Boolean! = false, $autofill: Boolean! = true) {
  contractList(
    cursor: $cursor
    filters: $filters
    orderBy: $orderBy
    perPage: $perPage
  ) @include(if: $documentML) {
    pageInfo {
      ...PageInfo
    }
    results {
      ...ContractDetail
    }
  }
}
    ${PageInfoFragmentDoc}
${ContractDetailFragmentDoc}`;

/**
 * __useContractListQuery__
 *
 * To run a query within a React component, call `useContractListQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContractListQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *      orderBy: // value for 'orderBy'
 *      perPage: // value for 'perPage'
 *      filters: // value for 'filters'
 *      extractedValues: // value for 'extractedValues'
 *      documentML: // value for 'documentML'
 *      documentVersionFields: // value for 'documentVersionFields'
 *      documentVersionFile: // value for 'documentVersionFile'
 *      autofill: // value for 'autofill'
 *   },
 * });
 */
export function useContractListQuery(baseOptions?: Apollo.QueryHookOptions<Types.ContractListQuery, Types.ContractListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ContractListQuery, Types.ContractListQueryVariables>(ContractListDocument, options);
      }
export function useContractListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ContractListQuery, Types.ContractListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ContractListQuery, Types.ContractListQueryVariables>(ContractListDocument, options);
        }
export type ContractListQueryHookResult = ReturnType<typeof useContractListQuery>;
export type ContractListLazyQueryHookResult = ReturnType<typeof useContractListLazyQuery>;
export type ContractListQueryResult = Apollo.QueryResult<Types.ContractListQuery, Types.ContractListQueryVariables>;
export const ContractSignatureFieldsDocument = gql`
    query contractSignatureFields($args: ContractSignatureFieldsArgs!) {
  contractSignatureFields(input: $args) {
    documentVersionFields {
      ...PublicDocumentVersionField
      field {
        id
        label
        group
        mappingKey
        fieldType
        isComputed
        isHidden
        readOnly
      }
    }
    signatureFormFields {
      ...SignatureFormField
    }
  }
}
    ${PublicDocumentVersionFieldFragmentDoc}
${SignatureFormFieldFragmentDoc}`;

/**
 * __useContractSignatureFieldsQuery__
 *
 * To run a query within a React component, call `useContractSignatureFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractSignatureFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContractSignatureFieldsQuery({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useContractSignatureFieldsQuery(baseOptions: Apollo.QueryHookOptions<Types.ContractSignatureFieldsQuery, Types.ContractSignatureFieldsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ContractSignatureFieldsQuery, Types.ContractSignatureFieldsQueryVariables>(ContractSignatureFieldsDocument, options);
      }
export function useContractSignatureFieldsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ContractSignatureFieldsQuery, Types.ContractSignatureFieldsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ContractSignatureFieldsQuery, Types.ContractSignatureFieldsQueryVariables>(ContractSignatureFieldsDocument, options);
        }
export type ContractSignatureFieldsQueryHookResult = ReturnType<typeof useContractSignatureFieldsQuery>;
export type ContractSignatureFieldsLazyQueryHookResult = ReturnType<typeof useContractSignatureFieldsLazyQuery>;
export type ContractSignatureFieldsQueryResult = Apollo.QueryResult<Types.ContractSignatureFieldsQuery, Types.ContractSignatureFieldsQueryVariables>;
export const ContractValidationsDocument = gql`
    query contractValidations($autofillVariableValues: JSONObject!, $contractId: ID!, $fieldValues: JSONObject!) {
  contractValidations(
    autofillVariableValues: $autofillVariableValues
    contractId: $contractId
    fieldValues: $fieldValues
  ) {
    id
    validations {
      ...ContractValidation
    }
  }
}
    ${ContractValidationFragmentDoc}`;

/**
 * __useContractValidationsQuery__
 *
 * To run a query within a React component, call `useContractValidationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractValidationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContractValidationsQuery({
 *   variables: {
 *      autofillVariableValues: // value for 'autofillVariableValues'
 *      contractId: // value for 'contractId'
 *      fieldValues: // value for 'fieldValues'
 *   },
 * });
 */
export function useContractValidationsQuery(baseOptions: Apollo.QueryHookOptions<Types.ContractValidationsQuery, Types.ContractValidationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ContractValidationsQuery, Types.ContractValidationsQueryVariables>(ContractValidationsDocument, options);
      }
export function useContractValidationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ContractValidationsQuery, Types.ContractValidationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ContractValidationsQuery, Types.ContractValidationsQueryVariables>(ContractValidationsDocument, options);
        }
export type ContractValidationsQueryHookResult = ReturnType<typeof useContractValidationsQuery>;
export type ContractValidationsLazyQueryHookResult = ReturnType<typeof useContractValidationsLazyQuery>;
export type ContractValidationsQueryResult = Apollo.QueryResult<Types.ContractValidationsQuery, Types.ContractValidationsQueryVariables>;
export const ContractVariableValuesDocument = gql`
    query contractVariableValues($input: [ContractVariableValuesInput!]!) {
  contractVariableValues(input: $input) {
    contractId
    contractVariableValues
    variableSource
    state
  }
}
    `;

/**
 * __useContractVariableValuesQuery__
 *
 * To run a query within a React component, call `useContractVariableValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractVariableValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContractVariableValuesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useContractVariableValuesQuery(baseOptions: Apollo.QueryHookOptions<Types.ContractVariableValuesQuery, Types.ContractVariableValuesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ContractVariableValuesQuery, Types.ContractVariableValuesQueryVariables>(ContractVariableValuesDocument, options);
      }
export function useContractVariableValuesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ContractVariableValuesQuery, Types.ContractVariableValuesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ContractVariableValuesQuery, Types.ContractVariableValuesQueryVariables>(ContractVariableValuesDocument, options);
        }
export type ContractVariableValuesQueryHookResult = ReturnType<typeof useContractVariableValuesQuery>;
export type ContractVariableValuesLazyQueryHookResult = ReturnType<typeof useContractVariableValuesLazyQuery>;
export type ContractVariableValuesQueryResult = Apollo.QueryResult<Types.ContractVariableValuesQuery, Types.ContractVariableValuesQueryVariables>;
export const DocumentListDocument = gql`
    query documentList($cursor: String, $orderBy: String = "-id", $perPage: Int = 20, $filters: DocumentListArgs!, $includeDocumentVersions: Boolean! = false, $includeDefaultSecondaryDocuments: Boolean! = false, $includeActiveDocumentVersion: Boolean! = false) {
  documentList(
    cursor: $cursor
    orderBy: $orderBy
    perPage: $perPage
    filters: $filters
  ) {
    pageInfo {
      ...PageInfo
    }
    results {
      ...DocumentList
    }
  }
}
    ${PageInfoFragmentDoc}
${DocumentListFragmentDoc}`;

/**
 * __useDocumentListQuery__
 *
 * To run a query within a React component, call `useDocumentListQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentListQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *      orderBy: // value for 'orderBy'
 *      perPage: // value for 'perPage'
 *      filters: // value for 'filters'
 *      includeDocumentVersions: // value for 'includeDocumentVersions'
 *      includeDefaultSecondaryDocuments: // value for 'includeDefaultSecondaryDocuments'
 *      includeActiveDocumentVersion: // value for 'includeActiveDocumentVersion'
 *   },
 * });
 */
export function useDocumentListQuery(baseOptions: Apollo.QueryHookOptions<Types.DocumentListQuery, Types.DocumentListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.DocumentListQuery, Types.DocumentListQueryVariables>(DocumentListDocument, options);
      }
export function useDocumentListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DocumentListQuery, Types.DocumentListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.DocumentListQuery, Types.DocumentListQueryVariables>(DocumentListDocument, options);
        }
export type DocumentListQueryHookResult = ReturnType<typeof useDocumentListQuery>;
export type DocumentListLazyQueryHookResult = ReturnType<typeof useDocumentListLazyQuery>;
export type DocumentListQueryResult = Apollo.QueryResult<Types.DocumentListQuery, Types.DocumentListQueryVariables>;
export const DocumentListForOfferFlowDocument = gql`
    query documentListForOfferFlow($cursor: String, $orderBy: String = "-id", $perPage: Int = 20, $filters: DocumentListArgs!, $isAgentVerified: Boolean = false, $activeDocumentVersion: Boolean = false, $defaultSecondaryDocuments: Boolean! = false) {
  publicDocumentList(
    cursor: $cursor
    orderBy: $orderBy
    perPage: $perPage
    filters: $filters
  ) @skip(if: $isAgentVerified) {
    pageInfo {
      ...PageInfo
    }
    results {
      ...PublicDocumentForOfferFlow
    }
  }
  documentList(
    cursor: $cursor
    orderBy: $orderBy
    perPage: $perPage
    filters: $filters
  ) @include(if: $isAgentVerified) {
    pageInfo {
      ...PageInfo
    }
    results {
      ...DocumentForOfferFlow
    }
  }
}
    ${PageInfoFragmentDoc}
${PublicDocumentForOfferFlowFragmentDoc}
${DocumentForOfferFlowFragmentDoc}`;

/**
 * __useDocumentListForOfferFlowQuery__
 *
 * To run a query within a React component, call `useDocumentListForOfferFlowQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentListForOfferFlowQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentListForOfferFlowQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *      orderBy: // value for 'orderBy'
 *      perPage: // value for 'perPage'
 *      filters: // value for 'filters'
 *      isAgentVerified: // value for 'isAgentVerified'
 *      activeDocumentVersion: // value for 'activeDocumentVersion'
 *      defaultSecondaryDocuments: // value for 'defaultSecondaryDocuments'
 *   },
 * });
 */
export function useDocumentListForOfferFlowQuery(baseOptions: Apollo.QueryHookOptions<Types.DocumentListForOfferFlowQuery, Types.DocumentListForOfferFlowQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.DocumentListForOfferFlowQuery, Types.DocumentListForOfferFlowQueryVariables>(DocumentListForOfferFlowDocument, options);
      }
export function useDocumentListForOfferFlowLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DocumentListForOfferFlowQuery, Types.DocumentListForOfferFlowQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.DocumentListForOfferFlowQuery, Types.DocumentListForOfferFlowQueryVariables>(DocumentListForOfferFlowDocument, options);
        }
export type DocumentListForOfferFlowQueryHookResult = ReturnType<typeof useDocumentListForOfferFlowQuery>;
export type DocumentListForOfferFlowLazyQueryHookResult = ReturnType<typeof useDocumentListForOfferFlowLazyQuery>;
export type DocumentListForOfferFlowQueryResult = Apollo.QueryResult<Types.DocumentListForOfferFlowQuery, Types.DocumentListForOfferFlowQueryVariables>;
export const DocumentSectionListDocument = gql`
    query documentSectionList($filters: DocumentSectionListFilters) {
  documentSectionList(filters: $filters, perPage: 50, orderBy: "order") {
    pageInfo {
      ...PageInfo
    }
    results {
      ...DocumentSectionList
    }
  }
}
    ${PageInfoFragmentDoc}
${DocumentSectionListFragmentDoc}`;

/**
 * __useDocumentSectionListQuery__
 *
 * To run a query within a React component, call `useDocumentSectionListQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentSectionListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentSectionListQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useDocumentSectionListQuery(baseOptions?: Apollo.QueryHookOptions<Types.DocumentSectionListQuery, Types.DocumentSectionListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.DocumentSectionListQuery, Types.DocumentSectionListQueryVariables>(DocumentSectionListDocument, options);
      }
export function useDocumentSectionListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DocumentSectionListQuery, Types.DocumentSectionListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.DocumentSectionListQuery, Types.DocumentSectionListQueryVariables>(DocumentSectionListDocument, options);
        }
export type DocumentSectionListQueryHookResult = ReturnType<typeof useDocumentSectionListQuery>;
export type DocumentSectionListLazyQueryHookResult = ReturnType<typeof useDocumentSectionListLazyQuery>;
export type DocumentSectionListQueryResult = Apollo.QueryResult<Types.DocumentSectionListQuery, Types.DocumentSectionListQueryVariables>;
export const DocumentSectionListForOfferFlowDocument = gql`
    query documentSectionListForOfferFlow($filters: DocumentSectionListFilters, $perPage: Int = 100) {
  publicDocumentSectionList(
    filters: $filters
    perPage: $perPage
    orderBy: "order"
  ) {
    pageInfo {
      ...PageInfo
    }
    results {
      ...DocumentSectionListForOfferFlow
    }
  }
}
    ${PageInfoFragmentDoc}
${DocumentSectionListForOfferFlowFragmentDoc}`;

/**
 * __useDocumentSectionListForOfferFlowQuery__
 *
 * To run a query within a React component, call `useDocumentSectionListForOfferFlowQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentSectionListForOfferFlowQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentSectionListForOfferFlowQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      perPage: // value for 'perPage'
 *   },
 * });
 */
export function useDocumentSectionListForOfferFlowQuery(baseOptions?: Apollo.QueryHookOptions<Types.DocumentSectionListForOfferFlowQuery, Types.DocumentSectionListForOfferFlowQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.DocumentSectionListForOfferFlowQuery, Types.DocumentSectionListForOfferFlowQueryVariables>(DocumentSectionListForOfferFlowDocument, options);
      }
export function useDocumentSectionListForOfferFlowLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DocumentSectionListForOfferFlowQuery, Types.DocumentSectionListForOfferFlowQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.DocumentSectionListForOfferFlowQuery, Types.DocumentSectionListForOfferFlowQueryVariables>(DocumentSectionListForOfferFlowDocument, options);
        }
export type DocumentSectionListForOfferFlowQueryHookResult = ReturnType<typeof useDocumentSectionListForOfferFlowQuery>;
export type DocumentSectionListForOfferFlowLazyQueryHookResult = ReturnType<typeof useDocumentSectionListForOfferFlowLazyQuery>;
export type DocumentSectionListForOfferFlowQueryResult = Apollo.QueryResult<Types.DocumentSectionListForOfferFlowQuery, Types.DocumentSectionListForOfferFlowQueryVariables>;
export const DocumentVersionAutofillValuesDocument = gql`
    query documentVersionAutofillValues($variableValues: JSONObject!, $documentVersionIds: [ID!]!, $fieldValues: JSONObject!) {
  documentVersionAutofillValues(
    variableValues: $variableValues
    documentVersionIds: $documentVersionIds
    fieldValues: $fieldValues
  ) {
    readOnlyAutofillValues
    autofillValues
    optionsAutofillValues
    helpTextAutofillValues
  }
}
    `;

/**
 * __useDocumentVersionAutofillValuesQuery__
 *
 * To run a query within a React component, call `useDocumentVersionAutofillValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentVersionAutofillValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentVersionAutofillValuesQuery({
 *   variables: {
 *      variableValues: // value for 'variableValues'
 *      documentVersionIds: // value for 'documentVersionIds'
 *      fieldValues: // value for 'fieldValues'
 *   },
 * });
 */
export function useDocumentVersionAutofillValuesQuery(baseOptions: Apollo.QueryHookOptions<Types.DocumentVersionAutofillValuesQuery, Types.DocumentVersionAutofillValuesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.DocumentVersionAutofillValuesQuery, Types.DocumentVersionAutofillValuesQueryVariables>(DocumentVersionAutofillValuesDocument, options);
      }
export function useDocumentVersionAutofillValuesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DocumentVersionAutofillValuesQuery, Types.DocumentVersionAutofillValuesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.DocumentVersionAutofillValuesQuery, Types.DocumentVersionAutofillValuesQueryVariables>(DocumentVersionAutofillValuesDocument, options);
        }
export type DocumentVersionAutofillValuesQueryHookResult = ReturnType<typeof useDocumentVersionAutofillValuesQuery>;
export type DocumentVersionAutofillValuesLazyQueryHookResult = ReturnType<typeof useDocumentVersionAutofillValuesLazyQuery>;
export type DocumentVersionAutofillValuesQueryResult = Apollo.QueryResult<Types.DocumentVersionAutofillValuesQuery, Types.DocumentVersionAutofillValuesQueryVariables>;
export const DocumentVersionListDocument = gql`
    query documentVersionList($cursor: String, $orderBy: String = "-id", $perPage: Int = 10, $filters: DocumentVersionListFilterArgs, $document: Boolean = false, $fields: Boolean = false, $secondaryDocumentVersions: Boolean = false) {
  documentVersionList(
    cursor: $cursor
    orderBy: $orderBy
    perPage: $perPage
    filters: $filters
  ) {
    pageInfo {
      ...PageInfo
    }
    results {
      ...DocumentVersionList
    }
  }
}
    ${PageInfoFragmentDoc}
${DocumentVersionListFragmentDoc}`;

/**
 * __useDocumentVersionListQuery__
 *
 * To run a query within a React component, call `useDocumentVersionListQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentVersionListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentVersionListQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *      orderBy: // value for 'orderBy'
 *      perPage: // value for 'perPage'
 *      filters: // value for 'filters'
 *      document: // value for 'document'
 *      fields: // value for 'fields'
 *      secondaryDocumentVersions: // value for 'secondaryDocumentVersions'
 *   },
 * });
 */
export function useDocumentVersionListQuery(baseOptions?: Apollo.QueryHookOptions<Types.DocumentVersionListQuery, Types.DocumentVersionListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.DocumentVersionListQuery, Types.DocumentVersionListQueryVariables>(DocumentVersionListDocument, options);
      }
export function useDocumentVersionListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DocumentVersionListQuery, Types.DocumentVersionListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.DocumentVersionListQuery, Types.DocumentVersionListQueryVariables>(DocumentVersionListDocument, options);
        }
export type DocumentVersionListQueryHookResult = ReturnType<typeof useDocumentVersionListQuery>;
export type DocumentVersionListLazyQueryHookResult = ReturnType<typeof useDocumentVersionListLazyQuery>;
export type DocumentVersionListQueryResult = Apollo.QueryResult<Types.DocumentVersionListQuery, Types.DocumentVersionListQueryVariables>;
export const DocumentVersionListForOfferFlowDocument = gql`
    query documentVersionListForOfferFlow($cursor: String, $orderBy: String = "-id", $perPage: Int = 20, $filters: DocumentVersionListFilterArgs!, $isAgentVerified: Boolean = false, $documentVersionFields: Boolean = false, $previewImages: Boolean = false, $skipSecondaryVersions: Boolean = false) {
  publicDocumentVersionList(
    cursor: $cursor
    orderBy: $orderBy
    perPage: $perPage
    filters: $filters
  ) @skip(if: $isAgentVerified) {
    pageInfo {
      ...PageInfo
    }
    results {
      ...PublicDocumentVersionForOfferFlow
    }
  }
  documentVersionList(
    cursor: $cursor
    orderBy: $orderBy
    perPage: $perPage
    filters: $filters
  ) @include(if: $isAgentVerified) {
    pageInfo {
      ...PageInfo
    }
    results {
      ...DocumentVersionForOfferFlow
    }
  }
}
    ${PageInfoFragmentDoc}
${PublicDocumentVersionForOfferFlowFragmentDoc}
${DocumentVersionForOfferFlowFragmentDoc}`;

/**
 * __useDocumentVersionListForOfferFlowQuery__
 *
 * To run a query within a React component, call `useDocumentVersionListForOfferFlowQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentVersionListForOfferFlowQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentVersionListForOfferFlowQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *      orderBy: // value for 'orderBy'
 *      perPage: // value for 'perPage'
 *      filters: // value for 'filters'
 *      isAgentVerified: // value for 'isAgentVerified'
 *      documentVersionFields: // value for 'documentVersionFields'
 *      previewImages: // value for 'previewImages'
 *      skipSecondaryVersions: // value for 'skipSecondaryVersions'
 *   },
 * });
 */
export function useDocumentVersionListForOfferFlowQuery(baseOptions: Apollo.QueryHookOptions<Types.DocumentVersionListForOfferFlowQuery, Types.DocumentVersionListForOfferFlowQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.DocumentVersionListForOfferFlowQuery, Types.DocumentVersionListForOfferFlowQueryVariables>(DocumentVersionListForOfferFlowDocument, options);
      }
export function useDocumentVersionListForOfferFlowLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DocumentVersionListForOfferFlowQuery, Types.DocumentVersionListForOfferFlowQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.DocumentVersionListForOfferFlowQuery, Types.DocumentVersionListForOfferFlowQueryVariables>(DocumentVersionListForOfferFlowDocument, options);
        }
export type DocumentVersionListForOfferFlowQueryHookResult = ReturnType<typeof useDocumentVersionListForOfferFlowQuery>;
export type DocumentVersionListForOfferFlowLazyQueryHookResult = ReturnType<typeof useDocumentVersionListForOfferFlowLazyQuery>;
export type DocumentVersionListForOfferFlowQueryResult = Apollo.QueryResult<Types.DocumentVersionListForOfferFlowQuery, Types.DocumentVersionListForOfferFlowQueryVariables>;
export const EmbeddedSignatureRequestDocument = gql`
    query embeddedSignatureRequest($code: String!) {
  embeddedSignatureRequest(code: $code) {
    ...EmbeddedSignatureRequest
  }
}
    ${EmbeddedSignatureRequestFragmentDoc}`;

/**
 * __useEmbeddedSignatureRequestQuery__
 *
 * To run a query within a React component, call `useEmbeddedSignatureRequestQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmbeddedSignatureRequestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmbeddedSignatureRequestQuery({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useEmbeddedSignatureRequestQuery(baseOptions: Apollo.QueryHookOptions<Types.EmbeddedSignatureRequestQuery, Types.EmbeddedSignatureRequestQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.EmbeddedSignatureRequestQuery, Types.EmbeddedSignatureRequestQueryVariables>(EmbeddedSignatureRequestDocument, options);
      }
export function useEmbeddedSignatureRequestLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.EmbeddedSignatureRequestQuery, Types.EmbeddedSignatureRequestQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.EmbeddedSignatureRequestQuery, Types.EmbeddedSignatureRequestQueryVariables>(EmbeddedSignatureRequestDocument, options);
        }
export type EmbeddedSignatureRequestQueryHookResult = ReturnType<typeof useEmbeddedSignatureRequestQuery>;
export type EmbeddedSignatureRequestLazyQueryHookResult = ReturnType<typeof useEmbeddedSignatureRequestLazyQuery>;
export type EmbeddedSignatureRequestQueryResult = Apollo.QueryResult<Types.EmbeddedSignatureRequestQuery, Types.EmbeddedSignatureRequestQueryVariables>;
export const FieldListDocument = gql`
    query fieldList($cursor: String, $orderBy: String = "-id", $perPage: Int = 20, $filters: FieldListFilterArgs, $fieldDetail: Boolean = false) {
  fieldList(
    cursor: $cursor
    orderBy: $orderBy
    perPage: $perPage
    filters: $filters
  ) {
    pageInfo {
      ...PageInfo
    }
    results {
      ...Field
      ...FieldDetail @include(if: $fieldDetail)
      includedDocumentVersions @include(if: $fieldDetail) {
        id
        version
        document {
          id
          form
          usState
        }
      }
    }
  }
}
    ${PageInfoFragmentDoc}
${FieldFragmentDoc}
${FieldDetailFragmentDoc}`;

/**
 * __useFieldListQuery__
 *
 * To run a query within a React component, call `useFieldListQuery` and pass it any options that fit your needs.
 * When your component renders, `useFieldListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFieldListQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *      orderBy: // value for 'orderBy'
 *      perPage: // value for 'perPage'
 *      filters: // value for 'filters'
 *      fieldDetail: // value for 'fieldDetail'
 *   },
 * });
 */
export function useFieldListQuery(baseOptions?: Apollo.QueryHookOptions<Types.FieldListQuery, Types.FieldListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.FieldListQuery, Types.FieldListQueryVariables>(FieldListDocument, options);
      }
export function useFieldListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.FieldListQuery, Types.FieldListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.FieldListQuery, Types.FieldListQueryVariables>(FieldListDocument, options);
        }
export type FieldListQueryHookResult = ReturnType<typeof useFieldListQuery>;
export type FieldListLazyQueryHookResult = ReturnType<typeof useFieldListLazyQuery>;
export type FieldListQueryResult = Apollo.QueryResult<Types.FieldListQuery, Types.FieldListQueryVariables>;
export const GetDocumentVersionFieldDocument = gql`
    query getDocumentVersionField($id: ID!) {
  getDocumentVersionField(id: $id) {
    ...DocumentVersionField
    field {
      ...Field
      ...FieldDetail
    }
    rules {
      ...Rule
    }
    autofill {
      ...Autofill
    }
    optionsAutofill {
      ...Autofill
    }
    helpTextAutofill {
      ...Autofill
    }
  }
}
    ${DocumentVersionFieldFragmentDoc}
${FieldFragmentDoc}
${FieldDetailFragmentDoc}
${RuleFragmentDoc}
${AutofillFragmentDoc}`;

/**
 * __useGetDocumentVersionFieldQuery__
 *
 * To run a query within a React component, call `useGetDocumentVersionFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocumentVersionFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocumentVersionFieldQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDocumentVersionFieldQuery(baseOptions: Apollo.QueryHookOptions<Types.GetDocumentVersionFieldQuery, Types.GetDocumentVersionFieldQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetDocumentVersionFieldQuery, Types.GetDocumentVersionFieldQueryVariables>(GetDocumentVersionFieldDocument, options);
      }
export function useGetDocumentVersionFieldLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetDocumentVersionFieldQuery, Types.GetDocumentVersionFieldQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetDocumentVersionFieldQuery, Types.GetDocumentVersionFieldQueryVariables>(GetDocumentVersionFieldDocument, options);
        }
export type GetDocumentVersionFieldQueryHookResult = ReturnType<typeof useGetDocumentVersionFieldQuery>;
export type GetDocumentVersionFieldLazyQueryHookResult = ReturnType<typeof useGetDocumentVersionFieldLazyQuery>;
export type GetDocumentVersionFieldQueryResult = Apollo.QueryResult<Types.GetDocumentVersionFieldQuery, Types.GetDocumentVersionFieldQueryVariables>;
export const InternalContractVariableValuesDocument = gql`
    query internalContractVariableValues($input: [ContractVariableValuesInput!]!) {
  internalContractVariableValues(input: $input) {
    contractId
    contractVariableValues
    variableSource
    state
  }
}
    `;

/**
 * __useInternalContractVariableValuesQuery__
 *
 * To run a query within a React component, call `useInternalContractVariableValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useInternalContractVariableValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInternalContractVariableValuesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInternalContractVariableValuesQuery(baseOptions: Apollo.QueryHookOptions<Types.InternalContractVariableValuesQuery, Types.InternalContractVariableValuesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.InternalContractVariableValuesQuery, Types.InternalContractVariableValuesQueryVariables>(InternalContractVariableValuesDocument, options);
      }
export function useInternalContractVariableValuesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.InternalContractVariableValuesQuery, Types.InternalContractVariableValuesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.InternalContractVariableValuesQuery, Types.InternalContractVariableValuesQueryVariables>(InternalContractVariableValuesDocument, options);
        }
export type InternalContractVariableValuesQueryHookResult = ReturnType<typeof useInternalContractVariableValuesQuery>;
export type InternalContractVariableValuesLazyQueryHookResult = ReturnType<typeof useInternalContractVariableValuesLazyQuery>;
export type InternalContractVariableValuesQueryResult = Apollo.QueryResult<Types.InternalContractVariableValuesQuery, Types.InternalContractVariableValuesQueryVariables>;
export const PreviewFieldsToColumnDocument = gql`
    query previewFieldsToColumn($contractId: ID!) {
  previewFieldsToColumn(contractId: $contractId)
}
    `;

/**
 * __usePreviewFieldsToColumnQuery__
 *
 * To run a query within a React component, call `usePreviewFieldsToColumnQuery` and pass it any options that fit your needs.
 * When your component renders, `usePreviewFieldsToColumnQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePreviewFieldsToColumnQuery({
 *   variables: {
 *      contractId: // value for 'contractId'
 *   },
 * });
 */
export function usePreviewFieldsToColumnQuery(baseOptions: Apollo.QueryHookOptions<Types.PreviewFieldsToColumnQuery, Types.PreviewFieldsToColumnQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PreviewFieldsToColumnQuery, Types.PreviewFieldsToColumnQueryVariables>(PreviewFieldsToColumnDocument, options);
      }
export function usePreviewFieldsToColumnLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PreviewFieldsToColumnQuery, Types.PreviewFieldsToColumnQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PreviewFieldsToColumnQuery, Types.PreviewFieldsToColumnQueryVariables>(PreviewFieldsToColumnDocument, options);
        }
export type PreviewFieldsToColumnQueryHookResult = ReturnType<typeof usePreviewFieldsToColumnQuery>;
export type PreviewFieldsToColumnLazyQueryHookResult = ReturnType<typeof usePreviewFieldsToColumnLazyQuery>;
export type PreviewFieldsToColumnQueryResult = Apollo.QueryResult<Types.PreviewFieldsToColumnQuery, Types.PreviewFieldsToColumnQueryVariables>;