import { useUpdateContractDocumentsMutation } from '@client/graphql/__generated__/document-operations';
import {
  ContractDocumentInput,
  ContractDocumentObject,
  DocumentVersionForOfferFlowFragment,
  DocumentVersionObject,
  OfferContractFragment,
  PublicDocumentVersionForOfferFlowFragment,
  PublicListingDocumentTypeFragment,
} from '@client/graphql/__generated__/types';
import { isEqual, sortBy } from 'lodash';
import { useCallback, useMemo } from 'react';
import {
  filterActiveSecondaryVersions,
  getAgentNegotiationContractDocs,
  getDisclosureContractDocs,
  getOtherDisclosureContractDocs,
} from '~/apps/consumer/utils/offer.utils';
import { ValuesType } from '~/services/document/components/FormFields/types';

interface UseOfferContractDocuments {
  documentVersion?:
    | DocumentVersionForOfferFlowFragment
    | PublicDocumentVersionForOfferFlowFragment;
  fieldValues: ValuesType;
  contract?: OfferContractFragment;
  disclosureDocumentTypes: PublicListingDocumentTypeFragment[];
  agentNegotiationDocumentTypes: PublicListingDocumentTypeFragment[];
}

export const useOfferContractDocuments = ({
  documentVersion,
  fieldValues,
  contract,
  disclosureDocumentTypes,
  agentNegotiationDocumentTypes,
}: UseOfferContractDocuments) => {
  const [mutation] = useUpdateContractDocumentsMutation();

  const additionalDocs = useMemo(() => {
    return (
      filterActiveSecondaryVersions(
        (documentVersion?.secondaryVersions || []) as DocumentVersionObject[],
        contract?.enabledSecondaryDocumentVersionsFromRules || [],
        fieldValues
      ) || []
    );
  }, [
    fieldValues,
    documentVersion,
    contract?.enabledSecondaryDocumentVersionsFromRules,
  ]);

  const allDocumentVersions: Array<DocumentVersionObject> = useMemo(() => {
    return (
      documentVersion?.id ? [documentVersion as DocumentVersionObject] : []
    ).concat(additionalDocs);
  }, [documentVersion, additionalDocs]);

  const contractDocumentInputs = useMemo(() => {
    const contractDocuments: ContractDocumentInput[] = [
      {
        documentVersionId: documentVersion?.id as string,
        order: 0,
      },
    ];
    const externalContractDocuments = (contract?.contractDocuments
      .filter((cd) => !!cd.externalDocumentType)
      .map((cd) => ({
        id: cd.id,
        documentVersionId: cd.documentVersion?.id,
        order: cd.order,
      })) || []) as ContractDocumentInput[];

    const secondaryContractDocuments = additionalDocs?.map(
      (doc: DocumentVersionObject, i: number) => {
        // if the contract doc already exists, include the id in the input
        const contractDoc = contract?.contractDocuments.find(
          (cd) => cd.documentVersion?.document.id === doc?.document?.id
        );

        return {
          id: contractDoc?.id,
          documentVersionId: contractDoc?.documentVersion?.id || doc.id,
          order: contractDoc?.order || i,
        } as ContractDocumentInput;
      }
    );

    return contractDocuments
      .concat(secondaryContractDocuments)
      .concat(externalContractDocuments)
      .map((doc, i) => ({
        ...doc,
        order: i,
      }));
  }, [contract?.contractDocuments, documentVersion?.id, additionalDocs]);

  const updateContractDocuments = useCallback(() => {
    const contractDocuments = sortBy(
      contract?.contractDocuments.map((doc) => ({
        documentVersionId: doc.documentVersion?.id,
        order: doc.order,
        ...(doc.id && doc.order !== 0 && { id: doc.id }),
      })),
      'order'
    );

    if (
      contract?.id &&
      contractDocumentInputs &&
      !isEqual(contractDocuments, contractDocumentInputs)
    ) {
      return mutation({
        variables: {
          input: {
            contractId: contract.id,
            contractDocuments: contractDocumentInputs,
          },
        },
      });
    }
  }, [contract, contractDocumentInputs, mutation]);

  const contractDocsWithFiles = useMemo(
    () =>
      contract?.contractDocuments?.filter((doc) => doc.file?.fileName) || [],
    [contract]
  );

  const otherDisclosureContractDocs = useMemo(
    () =>
      getOtherDisclosureContractDocs(
        contractDocsWithFiles as ContractDocumentObject[]
      ),
    [contractDocsWithFiles]
  );

  const disclosureContractDocs = useMemo(
    () =>
      getDisclosureContractDocs(
        contractDocsWithFiles as ContractDocumentObject[],
        disclosureDocumentTypes
      ),
    [contractDocsWithFiles, disclosureDocumentTypes]
  );

  const agentNegotiationContractDocs = useMemo(
    () =>
      getAgentNegotiationContractDocs(
        contractDocsWithFiles as ContractDocumentObject[],
        agentNegotiationDocumentTypes
      ),
    [contractDocsWithFiles, agentNegotiationDocumentTypes]
  );

  return {
    contractDocsWithFiles,
    otherDisclosureContractDocs,
    disclosureContractDocs,
    agentNegotiationContractDocs,
    additionalDocs,
    allDocumentVersions,
    updateContractDocuments,
  };
};
