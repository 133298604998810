import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  HStack,
  Icon,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import {
  DocumentSectionModuleType,
  EventTypes,
  ListingObject,
  OfferObject,
  OfferStatus,
} from '@client/graphql/__generated__/types';
import { FileTextIcon } from 'lucide-react';
import { FC } from 'react';
import { NavLink, Outlet, useNavigate } from 'react-router-dom';
import { AgentCard } from '~/apps/consumer/components/Agent/AgentCard';
import { ListingHeader } from '~/apps/consumer/components/Listings/ListingHeader';
import {
  SimpleList,
  SimpleListRow,
} from '~/apps/consumer/components/Lists/SimpleList';
import { OfferValidations } from '~/apps/consumer/components/OfferFlow/OfferValidations';
import { PrepareOfferForBuyerSignatureModal } from '~/apps/consumer/components/OfferFlow/SubmitOfferModal/PrepareOfferForBuyerSignatureModal';
import { SubmitOfferToListingAgentModal } from '~/apps/consumer/components/OfferFlow/SubmitOfferModal/SubmitOfferToListingAgentModal';
import { SubmitOfferToNonIndigoListingAgentModal } from '~/apps/consumer/components/OfferFlow/SubmitOfferModal/SubmitOfferToNonIndigoListingAgentModal';
import { DownloadOfferDocumentsButton } from '~/apps/consumer/components/Offers/DownloadOfferDocumentsButton';
import { useRegisteredRoles } from '~/apps/consumer/hooks/useRegisteredRoles';
import { getIsBuyerInformationIncomplete } from '~/apps/consumer/utils/offer.utils';
import { FieldValueDisplay } from '~/services/document/components/Fields/FieldValueDisplay';
import { useOfferValidations } from '~/services/document/hooks/useOfferValidations';
import { useAppContext } from '~/services/main/contexts/AppContext';
import { OfferFlowContainer } from './OfferFlowContainer';
import { useOfferFlowContext } from './OfferFlowContext';

export const ReviewPage: FC = () => {
  const { user } = useAppContext();
  const navigate = useNavigate();
  const { isRegisteredAgent } = useRegisteredRoles();
  const {
    activeDocument,
    latestOffer,
    listing,
    fieldValues,
    mergedAutofillValues,
    save,
    setShowVerifyUserModal,
    buyerInfo,
    offerFlowSections,
    offerKeyFields,
    additionalDocs,
    otherDisclosureContractDocs,
    setContractPreviewDocIndex,
  } = useOfferFlowContext();

  const {
    isOpen: isSendToListingAgentModalOpen,
    onClose: hideSendToListingAgentModal,
    onOpen: showSendToListingAgentModal,
  } = useDisclosure();

  const { getValidation } = useOfferValidations({
    validations: latestOffer?.contract?.validations ?? [],
  });

  const buyersAgentName = offerKeyFields?.buyersAgentName as string | undefined;

  const officeName = offerKeyFields?.buyersAgentOfficeName as
    | string
    | undefined;

  const sections = [
    offerFlowSections.KEY_TERMS,
    offerFlowSections.COMMISSIONS,
    offerFlowSections.OTHER_FIELDS,
  ];
  const routesTo = {
    [DocumentSectionModuleType.KEY_TERMS]: '../key-terms',
    [DocumentSectionModuleType.OTHER_FIELDS]: '../other',
    buyerInfo: '../buyer',
  };

  const primaryBuyerName = buyerInfo?.buyerName;
  const primaryBuyerEmail = buyerInfo?.buyerEmail;
  const primaryBuyerentityName = buyerInfo?.entityName;
  const primaryBuyerentityTitle = buyerInfo?.entityTitle;
  const isEntity = !!primaryBuyerentityName;

  const coBuyerName = buyerInfo?.coBuyerName;
  const coBuyerEmail = buyerInfo?.coBuyerEmail;

  const signatureRequestSent =
    latestOffer?.status === OfferStatus.BUYER_SIGNATURE_REQUEST_SENT;

  const allBuyersSigned = latestOffer?.events?.some(
    (event) => event.name === EventTypes.BUYERS_ALL_SIGNED
  );

  const sentToListingAgent = !!latestOffer?.submittedAt;
  const isPremium = listing?.isPremium;

  const isWaitingForBuyerSignature = signatureRequestSent && !allBuyersSigned;

  const isDocumentClickable = !sentToListingAgent && !allBuyersSigned;

  let continueButtonText: string;
  if (!isRegisteredAgent) {
    continueButtonText = 'Verify your phone number';
  } else if (allBuyersSigned) {
    continueButtonText = 'Prepare for listing agent';
  } else if (isWaitingForBuyerSignature) {
    continueButtonText = 'Waiting for buyer to sign';
  } else {
    continueButtonText = 'Prepare for buyer signature';
  }

  const onSave = async () => {
    if (!isRegisteredAgent) {
      setShowVerifyUserModal(true);
    } else if (allBuyersSigned) {
      showSendToListingAgentModal();
    } else {
      await save();
      navigate('./prepare-for-signature');
    }
  };

  const isBuyerInfoIncomplete = getIsBuyerInformationIncomplete(buyerInfo);

  return (
    <OfferFlowContainer
      continueButtonText={continueButtonText}
      isDisabled={
        isWaitingForBuyerSignature ||
        sentToListingAgent ||
        isBuyerInfoIncomplete
      }
      showContinueButton={!sentToListingAgent}
      showTooltip={isBuyerInfoIncomplete}
      toolTipLabel="Please complete buyer info to continue"
      onContinueRoutePath="/"
      onSaveOverride={onSave}
    >
      <VStack alignItems="stretch" flexGrow={1} pt={5} spacing={4}>
        <ListingHeader listing={listing} />

        <AgentCard
          agentName={buyersAgentName}
          isVerified={isRegisteredAgent}
          officeName={officeName}
          user={user}
          onVerifyUser={() => setShowVerifyUserModal(true)}
        />

        {sections.map((section) =>
          section ? (
            <Card key={section.id}>
              <CardHeader>
                <HStack justifyContent="space-between">
                  <Box>{section.title}</Box>
                  {!(
                    signatureRequestSent ||
                    sentToListingAgent ||
                    section.documentSectionModuleType ===
                      DocumentSectionModuleType.COMMISSIONS
                  ) && (
                    <Button
                      as={NavLink}
                      size="xs"
                      to={
                        routesTo[
                          section.documentSectionModuleType as
                            | DocumentSectionModuleType.KEY_TERMS
                            | DocumentSectionModuleType.OTHER_FIELDS
                        ]
                      }
                      variant="card-header"
                    >
                      Edit
                    </Button>
                  )}
                </HStack>
              </CardHeader>
              <CardBody>
                <SimpleList fontSize="sm">
                  {section?.documentSectionFields?.map(({ field }) =>
                    fieldValues[field.mappingKey] ? (
                      <SimpleListRow key={field.id} label={field.label}>
                        <FieldValueDisplay
                          compareValue={mergedAutofillValues[field.mappingKey]}
                          field={field}
                          validation={getValidation(field.mappingKey)}
                          value={fieldValues[field.mappingKey]}
                        />
                      </SimpleListRow>
                    ) : null
                  )}
                </SimpleList>
              </CardBody>
            </Card>
          ) : null
        )}

        {latestOffer?.noteToListingAgent && (
          <Card>
            <CardHeader>Note to Listing Agent</CardHeader>
            <CardBody>
              <Text color="whiteAlpha.600" whiteSpace="pre-line">
                {latestOffer?.noteToListingAgent}
              </Text>
            </CardBody>
          </Card>
        )}
        <Card>
          <CardHeader>
            <HStack justifyContent="space-between">
              <Box>Buyer Info</Box>
              {!(signatureRequestSent || sentToListingAgent) && (
                <Button
                  as={NavLink}
                  size="xs"
                  to={routesTo.buyerInfo}
                  variant="card-header"
                >
                  Edit
                </Button>
              )}
            </HStack>
          </CardHeader>
          <CardBody>
            {primaryBuyerName && (
              <SimpleList>
                <SimpleListRow label="Buyer">
                  <Box>{primaryBuyerName}</Box>
                </SimpleListRow>
                <SimpleListRow label="Email">
                  <Box>{primaryBuyerEmail}</Box>
                </SimpleListRow>
                {isEntity && (
                  <>
                    <SimpleListRow label="Entity buyer">
                      <Box>{primaryBuyerentityName}</Box>
                    </SimpleListRow>
                    <SimpleListRow label="Buyer title">
                      <Box>{primaryBuyerentityTitle}</Box>
                    </SimpleListRow>
                  </>
                )}
                {coBuyerName && (
                  <>
                    <SimpleListRow label="Co Buyer">
                      <Box>{coBuyerName}</Box>
                    </SimpleListRow>
                    <SimpleListRow label="Email">
                      <Box>{coBuyerEmail}</Box>
                    </SimpleListRow>
                  </>
                )}
              </SimpleList>
            )}
          </CardBody>
        </Card>
        <Card>
          <CardHeader>Documents included in offer package</CardHeader>
          <CardBody>
            <VStack alignItems="start" gap={3} width="100%">
              <ReviewDocumentRow
                key={activeDocument?.id}
                name={activeDocument?.name || ''}
                onClick={
                  isDocumentClickable
                    ? () => {
                        setContractPreviewDocIndex(0);
                      }
                    : undefined
                }
              />
              {additionalDocs?.map((docVersion, index) => {
                return (
                  <ReviewDocumentRow
                    key={docVersion.id}
                    name={docVersion.document.name}
                    onClick={
                      isDocumentClickable
                        ? () => {
                            setContractPreviewDocIndex(index + 1);
                          }
                        : undefined
                    }
                  />
                );
              })}
              {otherDisclosureContractDocs?.map((contractDoc, index) => {
                return (
                  <ReviewDocumentRow
                    key={contractDoc.id}
                    name={
                      contractDoc.documentVersion?.document.name ||
                      contractDoc.externalDocumentName ||
                      ''
                    }
                    onClick={
                      isDocumentClickable
                        ? () => {
                            setContractPreviewDocIndex(
                              index + (additionalDocs.length || 0) + 1
                            );
                          }
                        : undefined
                    }
                  />
                );
              })}
            </VStack>
          </CardBody>
        </Card>
        {latestOffer?.contract?.validations?.length && (
          <Card>
            <CardHeader>Validation</CardHeader>
            <CardBody>
              <OfferValidations
                validations={latestOffer?.contract?.validations}
              />
            </CardBody>
          </Card>
        )}

        {sentToListingAgent && (
          <DownloadOfferDocumentsButton
            buyersAgentName={latestOffer?.buyersAgent?.user.fullName}
            contractId={latestOffer?.contract?.id}
            propertySlug={listing.property.slug}
          />
        )}
      </VStack>
      <Outlet />
      {latestOffer &&
        (isPremium ? (
          <SubmitOfferToListingAgentModal
            isOpen={isSendToListingAgentModalOpen}
            offer={latestOffer as OfferObject}
            onClose={hideSendToListingAgentModal}
          />
        ) : (
          <SubmitOfferToNonIndigoListingAgentModal
            isOpen={isSendToListingAgentModalOpen}
            listing={listing as ListingObject}
            offer={latestOffer as OfferObject}
            onClose={hideSendToListingAgentModal}
          />
        ))}
      <PrepareOfferForBuyerSignatureModal />
    </OfferFlowContainer>
  );
};

const ReviewDocumentRow = ({
  name,
  onClick,
}: {
  name: string;
  onClick?: () => void;
}) => {
  return (
    <HStack
      cursor={onClick ? 'pointer' : 'default'}
      minWidth="0px"
      onClick={onClick}
    >
      <Icon as={FileTextIcon} boxSize="20px" color="purple.500" />
      <Text overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
        {name}
      </Text>
    </HStack>
  );
};
