import { Center, Spinner, useDisclosure } from '@chakra-ui/react';
import {
  ListingObject,
  OfferObject,
} from '@client/graphql/__generated__/types';
import { FC, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { DownloadOfferDocumentsButton } from '~/apps/consumer/components/Offers/DownloadOfferDocumentsButton';
import { OfferDetails } from '~/apps/consumer/components/Offers/OfferDetails/OfferDetails';
import { FailedExtractionFallback } from '~/apps/consumer/components/Storefront/UploadOffer/Upload/FailedExtractionFallback';
import { useRegisteredRoles } from '~/apps/consumer/hooks/useRegisteredRoles';
import {
  getIsBuyerInformationIncomplete,
  getIsUploadedOfferExtractionFailed,
} from '~/apps/consumer/utils/offer.utils';
import { SubmitOfferToListingAgentModal } from '../../../components/OfferFlow/SubmitOfferModal/SubmitOfferToListingAgentModal';
import { SubmitOfferToNonIndigoListingAgentModal } from '../../../components/OfferFlow/SubmitOfferModal/SubmitOfferToNonIndigoListingAgentModal';
import { OfferFlowContainer } from './OfferFlowContainer';
import { useOfferFlowContext } from './OfferFlowContext';

export const BuySideUploadedOfferReviewPage: FC = () => {
  const {
    listing,
    setShowVerifyUserModal,
    latestOffer,
    save,
    latestOfferLoading,
    buyerInfo,
  } = useOfferFlowContext();
  const { isRegisteredAgent } = useRegisteredRoles();
  const {
    isOpen: isSubmitModalOpen,
    onOpen: handleOpenSubmitModal,
    onClose: handleCloseSubmitModal,
  } = useDisclosure();

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get('submit') && isRegisteredAgent) {
      setSearchParams((prev) => {
        prev.delete('submit');

        return prev;
      });
      handleOpenSubmitModal();
    }
  }, [searchParams, setSearchParams, handleOpenSubmitModal, isRegisteredAgent]);

  const onSave = async () => {
    if (!isRegisteredAgent) {
      setSearchParams((prev) => {
        prev.set('submit', 'true');

        return prev;
      });
      setShowVerifyUserModal(true);
    } else {
      await save(undefined, true);
      handleOpenSubmitModal();
    }
  };

  const isOfferSentToLA = !!latestOffer?.submittedAt;

  const isPremium = listing?.isPremium;

  const isExtractionFailed = getIsUploadedOfferExtractionFailed(
    latestOffer as OfferObject
  );

  if (latestOfferLoading) {
    return (
      <Center mt={6}>
        <Spinner />
      </Center>
    );
  }

  const offerId = (latestOffer as OfferObject).id;

  return (
    <>
      <OfferFlowContainer
        //TODO: add back in for upload offer -> make offer flow
        // showCancelButton
        // cancelButtonText="Make revisions to my offer"
        continueButtonText="Prepare for listing agent"
        customContinueButton={
          isOfferSentToLA ? (
            <DownloadOfferDocumentsButton
              buyersAgentName={latestOffer?.buyersAgent?.user?.fullName}
              contractId={latestOffer?.contract?.id}
              propertySlug={listing.property.slug}
            />
          ) : undefined
        }
        isDisabled={
          isExtractionFailed &&
          getIsBuyerInformationIncomplete(buyerInfo, !isExtractionFailed)
        }
        onSaveOverride={onSave}
      >
        {isExtractionFailed ? (
          <FailedExtractionFallback />
        ) : (
          <OfferDetails listing={listing} offerId={offerId} />
        )}
      </OfferFlowContainer>
      {isPremium ? (
        <SubmitOfferToListingAgentModal
          isOpen={isSubmitModalOpen}
          offer={latestOffer as OfferObject}
          onClose={handleCloseSubmitModal}
        />
      ) : (
        <SubmitOfferToNonIndigoListingAgentModal
          isOpen={isSubmitModalOpen}
          listing={listing as ListingObject}
          offer={latestOffer as OfferObject}
          onClose={handleCloseSubmitModal}
        />
      )}
    </>
  );
};
