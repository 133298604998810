import {
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import {
  useCreateOpenHouseMutation,
  useListingsForCurrentUserLazyQuery,
} from '@client/graphql/__generated__/main-operations';
import { Form, Formik, FormikHelpers } from 'formik';
import { debounce } from 'lodash';
import { useState } from 'react';
import { AsyncSelect } from '~/services/document/common/Select';
import {
  DEFAULT_CREATE_OPEN_HOUSE_OPTIONS,
  OpenHouseFormControls,
  OpenHouseFormData,
  OpenHouseFormFooter,
} from '../ListingSetup/OpenHouses/ListingOpenHouseFormModal';
import { PRIORITIZED_LISTINGS_REFETCH_QUERY } from './PrioritizedListingsSection';

type DashboardOpenHouseFormData = {
  listingId: string;
} & OpenHouseFormData;

const initialValues: DashboardOpenHouseFormData = {
  listingId: '',
  ...DEFAULT_CREATE_OPEN_HOUSE_OPTIONS,
};

interface OpenHouseCreationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onCreated: () => void;
}

export const OpenHouseCreationModal = ({
  isOpen,
  onClose,
  onCreated,
}: OpenHouseCreationModalProps) => {
  const [selectedListing, setSelectedListing] =
    useState<ListingSelectOption | null>(null);

  const [createOpenHouse] = useCreateOpenHouseMutation({
    refetchQueries: PRIORITIZED_LISTINGS_REFETCH_QUERY,
  });

  const onSubmit = async (
    values: DashboardOpenHouseFormData,
    { setSubmitting }: FormikHelpers<DashboardOpenHouseFormData>
  ) => {
    setSubmitting(true);
    await createOpenHouse({
      variables: { input: { ...values, listingId: values.listingId } },
    })
      .then((resp) => {
        if (resp.data?.createOpenHouse) {
          onClose();
          onCreated();
        }
      })
      .finally(() => {
        setSubmitting(false);
        setSelectedListing(null);
      });
  };

  return (
    <Modal
      blockScrollOnMount={false}
      isOpen={isOpen}
      size="xl"
      onClose={onClose}
    >
      <ModalOverlay />
      <Formik<DashboardOpenHouseFormData>
        initialValues={initialValues}
        onSubmit={onSubmit}
      >
        {(props) => {
          const { setFieldValue } = props;

          return (
            <Form>
              <ModalContent>
                <ModalHeader>Create Open House</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <FormControl isRequired mb={4}>
                    <FormLabel>Listing</FormLabel>
                    <ListingSelect
                      onSelect={(option) => {
                        void setFieldValue('listingId', option.value);
                        setSelectedListing(option);
                      }}
                    />
                  </FormControl>
                  {selectedListing && <OpenHouseFormControls props={props} />}
                </ModalBody>
                {selectedListing && (
                  <OpenHouseFormFooter
                    editingOpenHouse={false}
                    followerCount={selectedListing.followerCount}
                    formikProps={props}
                    onClose={onClose}
                  />
                )}
              </ModalContent>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

interface ListingSelectOption {
  value: string;
  label: string;
  followerCount: number;
}

interface ListingSelectProps {
  onSelect: (option: ListingSelectOption) => void;
}

const ListingSelect = ({ onSelect }: ListingSelectProps) => {
  const [listingsQuery, { loading }] = useListingsForCurrentUserLazyQuery();

  const loadOptions = (
    inputValue: string,
    callback: (options: ListingSelectOption[]) => void
  ) => {
    void listingsQuery({
      fetchPolicy: 'cache-first',
      nextFetchPolicy: 'cache-first',
      variables: {
        filters: {
          isActive: true,
          search: inputValue,
        },
      },
    }).then(({ data }) => {
      callback(
        data?.listingsForCurrentUser?.results?.map((listing) => ({
          value: listing.id,
          label: listing.mlsListing?.addressFull || 'Unknown Address',
          followerCount: listing.followerCount ?? 0,
        })) ?? []
      );
    });
  };

  const loadOptionsDebounce = debounce(loadOptions, 300);

  return (
    <AsyncSelect<ListingSelectOption>
      autoFocus
      defaultMenuIsOpen
      defaultOptions
      isLoading={loading}
      loadOptions={loadOptionsDebounce}
      name="listingId"
      placeholder="Search for a listing..."
      onChange={onSelect}
    />
  );
};
