import { Box, Button, Card, Icon } from '@chakra-ui/react';
import { ArrowLeft } from 'lucide-react';
import { Navigate, NavLink, useSearchParams } from 'react-router-dom';
import { useIsMobile } from '~/common/hooks/useIsMobile';
import { AgentSignerFormTab } from './AgentSignerFormTab';
import { BuyerSignerFormTab } from './BuyersSignerFormTab';

export const OpenHouseGuestBookSignFormsPage = () => {
  const isMobile = useIsMobile();
  const [searchParams] = useSearchParams();
  const activeTab = searchParams.get('form');

  if (!activeTab) {
    return <Navigate to=".." />;
  }

  const isBuyersTab = activeTab === 'buyers';

  const cardStyleProps = isMobile
    ? {
        px: 2,
        py: 1,
      }
    : {
        flex: 1,
        p: 2,
      };

  return (
    <Card {...cardStyleProps}>
      <Button
        as={NavLink}
        color="whiteAlpha.600"
        leftIcon={<Icon as={ArrowLeft} />}
        p={2}
        to=".."
        variant="none"
        width="fit-content"
      >
        Back
      </Button>
      <Box p={2}>
        {isBuyersTab ? <BuyerSignerFormTab /> : <AgentSignerFormTab />}
      </Box>
    </Card>
  );
};
