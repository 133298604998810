import { Box, Heading, Image, Link, Tag, VStack } from '@chakra-ui/react';
import { LoadingCenter } from '@document/common/LoadingCenter';
import { FC, RefObject, memo, useEffect, useRef } from 'react';
import { useDocumentMapperContext } from '../../DocumentMapper/context';
import {
  DocumentThumbnails,
  DocumentsThumbnails,
  PageThumbnail,
  VisiblePage,
} from '../../PDFScrollable/types';

interface DocumentsThumbnailsWidgetWithContextProps {
  documentsThumbnails?: DocumentsThumbnails;
  visiblePage?: VisiblePage;
  showName?: boolean;
}

interface PageThumbnailProps {
  document: DocumentThumbnails;
  pageThumbnail: PageThumbnail;
  isVisiblePage: boolean;
  scrollRef: RefObject<HTMLDivElement>;
}

const DocumentPageThumbnail: FC<PageThumbnailProps> = memo(
  function DocumentPageThumbnail({
    document,
    pageThumbnail,
    isVisiblePage,
    scrollRef,
  }) {
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
      if (!isVisiblePage) return;

      if (scrollRef.current && ref.current) {
        const scrollTop = scrollRef.current.scrollTop;
        const scrollBottom = scrollTop + scrollRef.current.offsetHeight;
        const refPositionBottom =
          ref.current.offsetTop + ref.current.offsetHeight;

        if (
          ref.current.offsetTop < scrollTop ||
          refPositionBottom > scrollBottom
        ) {
          ref.current.scrollIntoView({ block: 'nearest' });
        }
      }
    }, [isVisiblePage, scrollRef, pageThumbnail.pageNumber]);

    return (
      <VStack
        key={`${document.documentIndex} - ${pageThumbnail.pageNumber}`}
        ref={ref}
        pt={4}
        textAlign="center"
      >
        {pageThumbnail.thumbnail ? (
          <Box
            border="1px solid"
            borderColor="gray.300"
            outline="4px solid"
            outlineColor={isVisiblePage ? 'primary.500' : 'transparent'}
            width="80%"
          >
            <Link
              data-testid={`thumbnail-link-${pageThumbnail.pageNumber}`}
              display="block"
              height="auto"
              variant="ghost"
              onClick={() => {
                const pageElems = window.document.querySelectorAll(
                  `div[data-page-number="${pageThumbnail.pageNumber}"][data-document-index="${document.documentIndex}"]`
                );
                const pageElemsFilter = Array.from(pageElems).filter((elem) => {
                  const { width, height } = elem.getBoundingClientRect();

                  return width === 0 && height === 0 ? false : true;
                });

                if (pageElemsFilter.length) {
                  pageElemsFilter.forEach((pageElem) =>
                    pageElem.scrollIntoView({ block: 'start' })
                  );
                }
              }}
            >
              <Image
                display="inline-block"
                src={pageThumbnail.thumbnail}
                verticalAlign="top"
                width="100%"
              />
            </Link>
          </Box>
        ) : (
          <Box height={300} width="100%">
            <LoadingCenter />
          </Box>
        )}
        <Tag
          bgColor="white"
          border="1px solid"
          borderColor="gray.300"
          boxShadow="sm"
          color="gray.500"
          transform="translateY(-20px)"
        >
          <strong>{pageThumbnail.pageNumber}</strong>
        </Tag>
      </VStack>
    );
  }
);

export const DocumentsThumbnailsWidgetWithContext: FC<
  DocumentsThumbnailsWidgetWithContextProps
> = ({ documentsThumbnails, visiblePage, showName }) => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const thumbnailsByDocuments = Object.values(documentsThumbnails || {}).map(
    (documentThumbnails) => {
      return {
        ...documentThumbnails,
        thumbnails: Object.values(documentThumbnails.thumbnails),
      };
    }
  );

  return (
    <Box
      ref={scrollRef}
      className="thin-scrollbar"
      flexGrow={0}
      height="100%"
      overflow="auto"
    >
      {thumbnailsByDocuments.map((document) => (
        <Box key={document.documentIndex} position="relative">
          {showName && (
            <Heading
              backdropFilter="blur(3px)"
              bgColor="bg.mainDark"
              borderBottom="1px solid"
              borderColor="gray.200"
              fontSize="xs"
              position="sticky"
              px={4}
              py={3}
              size="sm"
              top={0}
              zIndex={1}
            >
              {document.name}
            </Heading>
          )}

          {document.thumbnails.map((thumbnail, i) => (
            <DocumentPageThumbnail
              key={i}
              document={document}
              isVisiblePage={
                visiblePage?.documentIndex === document.documentIndex &&
                visiblePage?.pageNumber === thumbnail.pageNumber
              }
              pageThumbnail={thumbnail}
              scrollRef={scrollRef}
            />
          ))}
        </Box>
      ))}
    </Box>
  );
};

export const DocumentsThumbnailsWidget: FC<{ showName?: boolean }> = memo(
  function DocumentsThumbnailsWidget(props) {
    const { showName = true } = props;
    const { documentsThumbnails, visiblePage } = useDocumentMapperContext();

    return (
      <DocumentsThumbnailsWidgetWithContext
        documentsThumbnails={documentsThumbnails}
        showName={showName}
        visiblePage={visiblePage}
      />
    );
  }
);
