import { Flex } from '@chakra-ui/react';
import { TABS } from '../constants';
import { useOrganizationListingsContext } from '../OrganizationListingsContext';
import { OrganizationListingsStatus } from './OrganizationListingsStatus';

export const OrganizationListingsStatuses = () => {
  const {
    listingAggregateStatusMap,
    aggregateDifferenceStatusMap,
    selectedStatus,
    setSelectedStatus,
  } = useOrganizationListingsContext();

  return (
    <Flex flexGrow={1} gap={4} justifyContent="space-between" mb={2}>
      {TABS.map((tab) => {
        const listingAggregateForStatus = listingAggregateStatusMap[tab.status];

        return (
          <OrganizationListingsStatus
            key={tab.status}
            count={listingAggregateForStatus?.count || 0}
            countDifference={
              aggregateDifferenceStatusMap[tab.status]?.countDifference
            }
            isSelected={selectedStatus === tab.status}
            label={tab.label}
            sumPrice={
              listingAggregateForStatus?.sumListPrice ||
              listingAggregateForStatus?.sumClosePrice ||
              0
            }
            onClick={() => setSelectedStatus(tab.status)}
          />
        );
      })}
    </Flex>
  );
};
