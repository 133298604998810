import {
  PreferenceValueFragment,
  PreferenceValueObject,
  PublicListingFragment,
  PublicPreferenceValueFragment,
  PublicPreferenceValueObject,
} from '@client/graphql/__generated__/types';
import { ReactElement } from 'react';
import { PreferenceMetaType } from '~/apps/consumer/pages/ListingSetup/Preferences/constants';

import dayjs from 'dayjs';
import isNil from 'lodash/isNil';
import { NumberFormatterType, numberFormatter } from '~/common/utils/formatter';
import { StyledFormInputType } from '../../StyledFormInput/StyledFormInput';
import { BrokeragePageButton } from './BrokeragePageButton';

export const getFeePercentOrValue = (
  slugPrefix: string,
  preferenceValues: PreferenceValueObject[] | PublicPreferenceValueObject[]
): [number | undefined, NumberFormatterType | undefined] => {
  let ddFeeType: NumberFormatterType | undefined;
  const ddFeeMin = preferenceValues?.find((val) => {
    const slug = val.preference.slug;

    if (slug.includes(slugPrefix) && !isNil(val.numberValue)) {
      ddFeeType = slug.includes('percent') ? 'percent' : 'currency';

      return true;
    }

    return false;
  });

  return [ddFeeMin?.numberValue, ddFeeType];
};

/** This is a helper function that takes a preference meta object and preference values and returns a
 * CardDetailsField component with the formatted values.
 **/
export const getDetailValue = (
  preference: PreferenceMetaType,
  preferenceValues: PreferenceValueFragment[] | PublicPreferenceValueFragment[],
  listing: PublicListingFragment
): string | ReactElement | undefined => {
  switch (preference.dataType) {
    case 'numberValue': {
      if (preference.inputType === StyledFormInputType.RANGE) {
        const fromValue = preferenceValues.find((value) => {
          return value.preference.slug === preference.inputProps.fromName;
        });
        const toValue = preferenceValues.find((value) => {
          return value.preference.slug === preference.inputProps.toName;
        });

        let value = '-';
        if (fromValue?.numberValue && toValue?.numberValue) {
          value = `${fromValue?.numberValue} ${
            preference.inputProps.unit as string
          } - ${toValue?.numberValue} ${preference.inputProps.unit as string}`;
        }

        return value;
      } else if (preference.inputType === StyledFormInputType.UNIT_SELECT) {
        const prefix = preference.fieldSlugPrefix;
        const fieldName = `${prefix as string}_${
          preference.inputProps.selectedUnit as string
        }`;
        const value = preferenceValues.find((value) => {
          return value.preference.slug === fieldName;
        });

        const formatType =
          preference.inputProps.selectedUnit === 'percent'
            ? 'percent'
            : 'currency';

        return value
          ? numberFormatter(value.numberValue, {
              type: formatType,
              maxPrecision: formatType === 'percent' ? 1 : 0,
            })
          : '-';
      }
    }
    case 'dateTimeValue': {
      const value = preferenceValues.find((value) => {
        return value.preference.slug === (preference.fieldSlug as string);
      });

      return value?.dateTimeValue
        ? dayjs(value?.dateTimeValue as string).format('MM/DD/YYYY')
        : '-';
    }
    case 'stringValue': {
      if (preference.inputType === StyledFormInputType.UNIT_SELECT) {
        const prefix = preference.fieldSlugPrefix;
        const fieldName = `${prefix as string}_${
          preference.inputProps.selectedUnit as string
        }`;
        const value = preferenceValues.find((value) => {
          return value.preference.slug === fieldName;
        });

        return value?.stringValue || '-';
      } else if (preference.isUrl) {
        const url = preferenceValues.find((value) => {
          return value.preference.slug === preference.fieldSlug;
        })?.stringValue;

        return url ? <BrokeragePageButton url={url} /> : '-';
      } else {
        const value = preferenceValues.find((value) => {
          return value.preference.slug === preference.fieldSlug;
        });

        return value?.stringValue || '-';
      }
    }
    case 'booleanValue': {
      const value = preferenceValues.find((value) => {
        return value.preference.slug === preference.fieldSlug;
      });

      return value?.booleanValue === true
        ? 'Yes'
        : value?.booleanValue === false
          ? 'No'
          : '-';
    }
    case 'stringArray': {
      const value = preferenceValues.find((value) => {
        return value.preference.slug === preference.fieldSlug;
      });

      return (
        value?.stringArray?.join(', ') ||
        (preference.fieldSlug === 'loan_types'
          ? listing?.mlsListing?.terms || '-'
          : '-')
      );
    }
  }
};

export const getTimeFrameDisplay = (start?: number, end?: number): string => {
  if (start && end) {
    return `${start}-${end}`;
  } else if (start && !end) {
    return `> ${start}`;
  } else if (!start && end) {
    return `< ${end}`;
  } else {
    return '';
  }
};

export const getDateDisplay = (date?: string): string => {
  if (date) {
    return dayjs(date).format('MM/DD/YYYY');
  } else {
    return '';
  }
};

export interface PrefDisplay {
  value?: string;
  unitSuffix?: string;
  name: string;
  tooltip?: string;
}

const MAX_PREFS_TO_DISPLAY = 3;

export const getPrefsDisplay = (
  preferenceValues: PreferenceValueObject[]
): PrefDisplay[] => {
  const [ddFeeMin, ddFeeType] = getFeePercentOrValue(
    'due_diligence_fee_expectation',
    preferenceValues
  );

  const [earnestMin, earnestType] = getFeePercentOrValue(
    'earnest_money_deposit',
    preferenceValues
  );

  const [concessionsMin, concessionsType] = getFeePercentOrValue(
    'seller_concessions',
    preferenceValues
  );

  const closingTimeFrameStart = preferenceValues?.find(
    (prefVal) => prefVal.preference.slug === 'closing_time_frame_start'
  )?.numberValue;
  const closingTimeFrameEnd = preferenceValues?.find(
    (prefVal) => prefVal.preference.slug === 'closing_time_frame_end'
  )?.numberValue;
  const closingTimeFrameDisplay = getTimeFrameDisplay(
    closingTimeFrameStart,
    closingTimeFrameEnd
  );

  const sellerPossessionAfterClosingStart = preferenceValues?.find(
    (prefVal) =>
      prefVal.preference.slug === 'seller_possession_after_closing_start'
  )?.numberValue;
  const sellerPossessionAfterClosingEnd = preferenceValues?.find(
    (prefVal) =>
      prefVal.preference.slug === 'seller_possession_after_closing_end'
  )?.numberValue;
  const sellerPossessionAfterClosingDisplay = getTimeFrameDisplay(
    sellerPossessionAfterClosingStart,
    sellerPossessionAfterClosingEnd
  );

  const buyerPossessionBeforeClosingStart = preferenceValues?.find(
    (prefVal) =>
      prefVal.preference.slug === 'buyer_possession_before_closing_start'
  )?.numberValue;
  const buyerPossessionBeforeClosingEnd = preferenceValues?.find(
    (prefVal) =>
      prefVal.preference.slug === 'buyer_possession_before_closing_end'
  )?.numberValue;
  const buyerPossessionBeforeClosingDisplay = getTimeFrameDisplay(
    buyerPossessionBeforeClosingStart,
    buyerPossessionBeforeClosingEnd
  );

  const idealMoveOutDate = preferenceValues?.find(
    (prefVal) => prefVal.preference.slug === 'ideal_move_out_date'
  )?.dateTimeValue as string;
  const idealMoveOutDateDisplay = getDateDisplay(idealMoveOutDate);

  const preferredClosingPartner = preferenceValues?.find(
    (prefVal) =>
      prefVal.preference.slug === 'preferred_closing_attorney' ||
      prefVal.preference.slug === 'preferred_title_company'
  )?.stringValue;

  // Filters out empty preferences and limits to the first MAX_PREFS_TO_DISPLAY items.
  const prefsDisplay: PrefDisplay[] = [
    {
      value: numberFormatter(concessionsMin, {
        maxPrecision: concessionsType === 'percent' ? 1 : 0,
        type: concessionsType,
      }),
      name: 'seller concessions',
    },
    {
      value: numberFormatter(ddFeeMin, {
        maxPrecision: ddFeeType === 'percent' ? 1 : 0,
        type: ddFeeType,
      }),
      name: 'minimum due diligence',
    },
    {
      value: numberFormatter(earnestMin, {
        maxPrecision: earnestType === 'percent' ? 1 : 0,
        type: earnestType,
      }),
      name: 'minimum earnest money deposit',
    },
    {
      value: closingTimeFrameDisplay,
      unitSuffix: 'days',
      name: 'closing time frame',
    },
    {
      value: sellerPossessionAfterClosingDisplay,
      unitSuffix: 'days',
      name: 'seller possession after closing',
    },
    {
      value: buyerPossessionBeforeClosingDisplay,
      unitSuffix: 'days',
      name: 'buyer possession before closing',
    },
    {
      value: idealMoveOutDateDisplay,
      name: 'ideal move out date',
    },
    {
      value: preferredClosingPartner,
      name: 'preferred closing partner',
    },
  ]
    .filter((pref) => pref.value)
    .slice(0, MAX_PREFS_TO_DISPLAY);

  return prefsDisplay;
};
