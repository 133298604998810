import {
  Button,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  OrderedList,
  Text,
  Textarea,
  VStack,
} from '@chakra-ui/react';
import { useBuyerClientsForCurrentAgentUserLazyQuery } from '@client/graphql/__generated__/main-operations';
import { OfferObject } from '@client/graphql/__generated__/types';
import * as Sentry from '@sentry/react';
import { ChangeEvent, FC, ReactNode, useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface SubmitOfferModalProps {
  isOpen: boolean;
  onClose: () => void;
  handleSubmit: (note: string) => Promise<void> | void;
  modalTitle: string;
  bodyHeader: ReactNode;
  bodyList: string[];
  submitButtonText: string;
  isSubmitted: boolean;
  notePlaceholder: string;
  sentMessage: string;
  isLoading: boolean;
  offer: OfferObject;
  attachmentSection?: ReactNode;
  phoneAndEmailSection?: ReactNode;
  submitButtonDisabled?: boolean;
  finishButtonText?: string;
  onFinish?: () => void;
}

export const SubmitOfferModal: FC<SubmitOfferModalProps> = ({
  isOpen,
  onClose,
  handleSubmit,
  modalTitle,
  bodyHeader,
  bodyList,
  submitButtonText,
  isSubmitted,
  notePlaceholder,
  sentMessage,
  isLoading,
  offer,
  attachmentSection,
  phoneAndEmailSection,
  submitButtonDisabled = false,
  finishButtonText = 'Go to my dashboard',
  onFinish,
}) => {
  const [note, setNote] = useState('');

  const navigate = useNavigate();

  const [getBuyerClientsForCurrentAgent, { loading: buyerClientsLoading }] =
    useBuyerClientsForCurrentAgentUserLazyQuery();

  const handleNoteChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setNote(event.target.value);
  };

  const handleClick = async () => {
    // we need to fetch using useBuyerClientsForCurrentAgentUserLazyQuery
    // to ensure it has the latest offer that was just created
    // so that we can get the related Party ID and correctly redirect
    const { data } = await getBuyerClientsForCurrentAgent();

    const party = data?.buyerClientsForCurrentAgentUser?.find(
      (party) =>
        party.purchaseOffers?.some((partyOffer) => partyOffer.id === offer?.id)
    );
    if (party) {
      navigate(`/buyers/${party.id}/purchase-offers`);
    } else {
      Sentry.captureMessage(
        'On submit offer: Party not found, redirecting to dashboard',
        {
          extra: {
            offerId: offer?.id,
          },
          level: 'warning',
        }
      );
      navigate('/');
    }
  };

  return (
    <Modal
      blockScrollOnMount={false}
      isOpen={isOpen}
      scrollBehavior="inside"
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>{modalTitle}</ModalHeader>
        <ModalBody>
          <VStack
            alignItems="flex-start"
            color="whiteAlpha.800"
            lineHeight="1.2"
            maxH="60vh"
            overflowY="scroll"
            spacing={5}
          >
            {!isSubmitted ? (
              <>
                {bodyHeader}
                {phoneAndEmailSection}
                <OrderedList
                  color="whiteAlpha.800"
                  fontSize="sm"
                  spacing={3}
                  sx={{
                    '& > li': {
                      paddingLeft: 4,
                    },
                    '& > li::marker': {
                      color: 'purple.500',
                      mr: 4,
                    },
                  }}
                >
                  {bodyList.map((item) => (
                    <ListItem key={item}>{item}</ListItem>
                  ))}
                </OrderedList>
                <Textarea
                  placeholder={notePlaceholder}
                  value={note}
                  onChange={handleNoteChange}
                />
                {attachmentSection}
              </>
            ) : (
              <Text color="whiteAlpha.600" fontSize="lg">
                <Text as="span" color="white" fontWeight="500">
                  Sent!&nbsp;
                </Text>
                {sentMessage}
              </Text>
            )}
          </VStack>
        </ModalBody>
        <ModalFooter>
          {!isSubmitted ? (
            <Button
              isDisabled={submitButtonDisabled}
              isLoading={isLoading || buyerClientsLoading}
              w="100%"
              onClick={() => {
                void handleSubmit(note);
              }}
            >
              {submitButtonText}
            </Button>
          ) : (
            <Button
              isLoading={buyerClientsLoading}
              w="100%"
              onClick={() => {
                void (onFinish ? onFinish() : handleClick());
              }}
            >
              {finishButtonText}
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
