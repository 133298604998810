import {
  ListingComparableSetFragment,
  ListingFragment,
  MlsListingFiltersInput,
  MlsListingFragment,
} from '@client/graphql/__generated__/types';
import { bbox, buffer, distance, point } from '@turf/turf';
import dayjs from 'dayjs';
import {
  BATHROOM_RANGE_OPTIONS,
  BEDROOM_RANGE_OPTIONS,
  PRICE_RANGE_OPTIONS,
} from '../../MapSearch/Filters/constants';

export const getDateDiff = ({
  listingDate,
  minCompDate,
  unit = 'day',
}: {
  listingDate: dayjs.Dayjs;
  minCompDate: dayjs.Dayjs;
  unit?: 'day' | 'month';
}) => {
  const diff = listingDate.diff(minCompDate, unit);
  if (diff === 0 && unit === 'month') {
    return listingDate.diff(minCompDate, 'day');
  }

  return diff;
};

export const getDistanceInMiles = (
  firstMlsListing?: MlsListingFragment,
  secondMlsListing?: MlsListingFragment
) => {
  if (!firstMlsListing?.geo?.location || !secondMlsListing?.geo?.location) {
    return undefined;
  }

  return distance(
    point([
      firstMlsListing.geo?.location?.lon,
      firstMlsListing.geo?.location?.lat,
    ]),
    point([
      secondMlsListing.geo?.location?.lon,
      secondMlsListing.geo?.location?.lat,
    ]),
    { units: 'miles' }
  );
};

export const getMaxCompDistance = (
  comparableSet?: ListingComparableSetFragment,
  listing?: ListingFragment
) => {
  if (!listing?.mlsListing?.geo?.location || !comparableSet) {
    return null;
  }

  return comparableSet?.listingComparables.reduce((max, comparable) => {
    const distanceInMiles =
      listing?.mlsListing?.geo?.location && comparable.mlsListing?.geo?.location
        ? distance(
            point([
              listing?.mlsListing?.geo?.location?.lon,
              listing?.mlsListing?.geo?.location?.lat,
            ]),
            point([
              comparable.mlsListing?.geo?.location?.lon,
              comparable.mlsListing?.geo?.location?.lat,
            ]),
            { units: 'miles' }
          )
        : 0;

    return Math.max(max, distanceInMiles);
  }, 0);
};

export const getInitialCompsBBox = (
  comparableSet?: ListingComparableSetFragment,
  listing?: ListingFragment,
  radiusPadding = 1
) => {
  if (!listing?.mlsListing?.geo?.location || !comparableSet) {
    return null;
  }

  const maxDistance = getMaxCompDistance(comparableSet, listing);

  const listingPoint = point([
    listing?.mlsListing?.geo?.location?.lon,
    listing?.mlsListing?.geo?.location?.lat,
  ]);

  const buffered = buffer(listingPoint, (maxDistance || 0) + radiusPadding, {
    units: 'miles',
  });

  return buffered ? bbox(buffered) : null;
};

export const getIntitialFilterValues = (
  listing?: ListingFragment
): MlsListingFiltersInput => {
  if (!listing) {
    return {};
  }
  const listPrice = listing.mlsListing?.listPrice;
  const bedrooms = listing.mlsListing?.property?.bedrooms;
  const fullBaths = listing.mlsListing?.property?.bathsFull;
  const halfBaths = listing.mlsListing?.property?.bathsHalf;

  const findClosestMinOption = (
    value: number | undefined,
    options: { label: string; value: number }[]
  ) => {
    return value !== undefined
      ? options.reduce((closest, option) => {
          if (option.value <= value) {
            return closest !== undefined
              ? Math.max(closest, option.value)
              : option.value;
          }

          return closest;
        }, undefined)
      : undefined;
  };

  const findClosestMaxOption = (
    value: number | undefined,
    options: { label: string; value: number }[]
  ) => {
    return value !== undefined
      ? options.reduce((closest, option) => {
          if (option.value >= value) {
            return closest !== undefined
              ? Math.min(closest, option.value)
              : option.value;
          }

          return closest;
        }, undefined)
      : undefined;
  };

  const priceRangeMin = findClosestMinOption(
    listPrice !== undefined ? listPrice * 0.8 : undefined,
    PRICE_RANGE_OPTIONS
  );
  const priceRangeMax = findClosestMaxOption(
    listPrice !== undefined ? listPrice * 1.2 : undefined,
    PRICE_RANGE_OPTIONS
  );

  const bedRangeMin = findClosestMinOption(
    bedrooms !== undefined ? bedrooms - 1 : undefined,
    BEDROOM_RANGE_OPTIONS
  );
  const bedRangeMax = findClosestMaxOption(
    bedrooms !== undefined ? bedrooms + 1 : undefined,
    BEDROOM_RANGE_OPTIONS
  );

  const fullBathRangeMin = findClosestMinOption(
    fullBaths !== undefined ? fullBaths - 1 : undefined,
    BATHROOM_RANGE_OPTIONS
  );
  const fullBathRangeMax = findClosestMaxOption(
    fullBaths !== undefined ? fullBaths + 1 : undefined,
    BATHROOM_RANGE_OPTIONS
  );

  const halfBathRangeMin = findClosestMinOption(
    halfBaths !== undefined ? halfBaths - 1 : undefined,
    BATHROOM_RANGE_OPTIONS
  );
  const halfBathRangeMax = findClosestMaxOption(
    halfBaths !== undefined ? halfBaths + 1 : undefined,
    BATHROOM_RANGE_OPTIONS
  );

  const closedDateMin = dayjs().subtract(6, 'month').toDate();

  return {
    closedDateRange: {
      startDate: closedDateMin,
      endDate: undefined,
    },
    ...(listPrice !== undefined
      ? {
          priceRange: {
            min: priceRangeMin,
            max: priceRangeMax,
          },
        }
      : {}),
    ...(bedrooms !== undefined
      ? {
          bedRange: {
            min: bedRangeMin,
            max: bedRangeMax,
          },
        }
      : {}),
    ...(fullBaths !== undefined
      ? {
          fullBathRange: {
            min: fullBathRangeMin,
            max: fullBathRangeMax,
          },
        }
      : {}),
    ...(halfBaths !== undefined
      ? {
          halfBathRange: {
            min: halfBathRangeMin,
            max: halfBathRangeMax,
          },
        }
      : {}),
  };
};
