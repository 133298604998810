import { Center, FormControl, FormLabel, Text, VStack } from '@chakra-ui/react';
import { useOfferAutofillVariablesSchemaQuery } from '@client/graphql/__generated__/main-operations';
import { MlsListingObject, UsState } from '@client/graphql/__generated__/types';
import { FC, useCallback } from 'react';
import { serializeFromAutofillSchema } from '~/apps/consumer/utils/autofill-schema';
import { AutofillSchema } from '~/services/document/components/FormulaEditor/autocomplete/types';
import { MLSListingRow } from '../../Listings/MLSListingRow';
import { FindPropertyPopoverList } from '../../MakeOffer/FindPropertyPopoverList';
import { DocumentSectionProps } from '../DocumentSection';

export const DocumentSectionPropertiesSearch: FC<DocumentSectionProps> = ({
  documentSection,
  onChange,
  fieldValues,
}) => {
  const { data } = useOfferAutofillVariablesSchemaQuery({
    variables: { usState: documentSection?.document?.usState as UsState },
    fetchPolicy: 'cache-first',
    skip: !documentSection?.document?.usState,
  });
  const targetFields = documentSection.documentSectionFields;
  const selectedMlsListings = (fieldValues.selectedMlsListings ||
    []) as MlsListingObject[];

  const updateTargetFields = useCallback(
    (updated: MlsListingObject[]) => {
      const propertyAddresses = updated.map(
        (listing) =>
          `${listing.address.full} ${listing.address.city} ${listing.address.state}`
      );
      const updatedFieldValues = {
        selectedMlsListings: updated,
      };

      targetFields?.forEach((field) => {
        Object.assign(updatedFieldValues, {
          [field.field.mappingKey]: propertyAddresses.join(', '),
        });
      });

      onChange(updatedFieldValues);
    },
    [onChange, targetFields]
  );

  return (
    <FormControl id={documentSection.id} width="100%">
      <FormLabel variant="normal">{documentSection.title}</FormLabel>

      <FindPropertyPopoverList
        size="sm"
        onSelect={(listing) => {
          const updated = [
            ...selectedMlsListings,
            serializeFromAutofillSchema(
              data?.offerAutofillVariablesSchema
                .mlsListing as unknown as AutofillSchema,
              listing
            ),
          ];
          updateTargetFields(updated);
        }}
      />

      <VStack justifyContent="flexStart" mt={4} spacing={0} width="100%">
        {selectedMlsListings.map((listing) => (
          <MLSListingRow
            key={listing.id}
            listing={listing}
            onRemove={() => {
              updateTargetFields(
                selectedMlsListings.filter((l) => l.id !== listing.id)
              );
            }}
          />
        ))}

        {selectedMlsListings.length === 0 && (
          <Center>
            <Text color="whiteAlpha.500">No properties selected</Text>
          </Center>
        )}
      </VStack>
    </FormControl>
  );
};
