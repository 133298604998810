import {
  ListingDocumentTypeEnum,
  ListingDocumentUploadObject,
} from '@client/graphql/__generated__/types';

export const filterAndGroupListingDocs = (
  listingDocumentUploads: ListingDocumentUploadObject[]
) => {
  const sortDocs = (
    a: ListingDocumentUploadObject,
    b: ListingDocumentUploadObject
  ) => {
    if (a.listingDocumentType?.id && !b.listingDocumentType?.id) {
      return -1;
    }
    if (b.listingDocumentType?.id && !a.listingDocumentType?.id) {
      return 1;
    }

    return 0;
  };

  const disclosures = listingDocumentUploads
    .filter(
      (doc) =>
        doc.listingDocumentType?.type === ListingDocumentTypeEnum.DISCLOSURE ||
        doc.customDocumentType === ListingDocumentTypeEnum.DISCLOSURE ||
        // Include agent negotiation docs in disclosures so its not so blatant
        doc.listingDocumentType?.type ===
          ListingDocumentTypeEnum.AGENT_NEGOTIATION ||
        doc.customDocumentType === ListingDocumentTypeEnum.AGENT_NEGOTIATION
    )
    .sort(sortDocs);

  const additionalDocuments = listingDocumentUploads
    .filter(
      (doc) =>
        doc.listingDocumentType?.type === ListingDocumentTypeEnum.OTHER ||
        doc.customDocumentType === ListingDocumentTypeEnum.OTHER
    )
    .sort(sortDocs);

  const agentNegotiationDocuments = listingDocumentUploads
    .filter(
      (doc) =>
        doc.listingDocumentType?.type ===
          ListingDocumentTypeEnum.AGENT_NEGOTIATION ||
        doc.customDocumentType === ListingDocumentTypeEnum.AGENT_NEGOTIATION
    )
    .sort(sortDocs);

  return {
    disclosures,
    additionalDocuments,
    agentNegotiationDocuments,
  };
};
