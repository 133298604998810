import { Box } from '@chakra-ui/react';
import {
  ActivityFragment,
  ActivityType,
} from '@client/graphql/__generated__/types';
import { AnnouncementActivity } from './AnnouncementActivity';
import { ComparablesUpdateActivity } from './ComparablesUpdateActivity';
import { DocumentUpdateActivity } from './DocumentUpdateActivity';
import { ListingDocumentActionActivity } from './ListingDocumentActionActivity';
import { ListingUpdateSystemActivity } from './ListingUpdateSystemActivity';
import { OfferSubmittedActivity } from './OfferSubmittedActivity';
import { PreferenceUpdateActivity } from './PreferenceUpdateActivity';
import { StorefrontUserSubscribedActivity } from './StorefrontUserSubscribedActivity';
import { StorefrontUserUnsubscribedActivity } from './StorefrontUserUnsubscribedActivity';

interface ActivityRendererBaseProps {
  listingActivity: ActivityFragment;
}

const ACTIVITY_RENDERERS = {
  [ActivityType.ANNOUNCEMENT]: {
    Component: (props: ActivityRendererBaseProps) => (
      <AnnouncementActivity {...props} />
    ),
  },
  [ActivityType.PREFERENCE_UPDATE]: {
    Component: (props: ActivityRendererBaseProps) => (
      <PreferenceUpdateActivity {...props} />
    ),
  },
  [ActivityType.SYSTEM_UPDATE]: {
    Component: (props: ActivityRendererBaseProps) => (
      <ListingUpdateSystemActivity {...props} />
    ),
  },
  [ActivityType.COMPARABLES_UPDATE]: {
    Component: (props: ActivityRendererBaseProps) => (
      <ComparablesUpdateActivity {...props} />
    ),
  },
  [ActivityType.DOCUMENT_UPDATE]: {
    Component: (props: ActivityRendererBaseProps) => (
      <DocumentUpdateActivity {...props} />
    ),
  },
  [ActivityType.OFFER_SUBMITTED]: {
    Component: (props: ActivityRendererBaseProps) => (
      <OfferSubmittedActivity {...props} />
    ),
  },
  [ActivityType.STOREFRONT_USER_SUBSCRIBED]: {
    Component: (props: ActivityRendererBaseProps) => (
      <StorefrontUserSubscribedActivity {...props} />
    ),
  },
  [ActivityType.STOREFRONT_USER_UNSUBSCRIBED]: {
    Component: (props: ActivityRendererBaseProps) => (
      <StorefrontUserUnsubscribedActivity {...props} />
    ),
  },
  [ActivityType.LISTING_DOCUMENT_DOWNLOADED]: {
    Component: (props: ActivityRendererBaseProps) => (
      <ListingDocumentActionActivity {...props} />
    ),
  },
  [ActivityType.LISTING_DOCUMENT_VIEWED]: {
    Component: (props: ActivityRendererBaseProps) => (
      <ListingDocumentActionActivity {...props} />
    ),
  },
};

export const ActivitiesList = ({
  activities,
}: {
  activities?: ActivityFragment[];
}) => {
  if (!activities?.length) {
    return null;
  }

  return (
    <>
      {activities?.map((listingActivity) => {
        const Component = ACTIVITY_RENDERERS[listingActivity.type].Component;

        if (!Component) {
          return null;
        }

        return (
          <Box key={listingActivity.id} fontSize={{ base: 'sm', md: 'md' }}>
            <Component listingActivity={listingActivity} />
          </Box>
        );
      })}
    </>
  );
};
