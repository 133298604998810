import { Box, BoxProps, Flex, Text, TextProps } from '@chakra-ui/react';
import { ReactNode } from 'react';

interface ITextMarkerProps {
  text: ReactNode;
  bg?: BoxProps['bg'];
  textColor?: TextProps['color'];
  fontWeight?: TextProps['fontWeight'];
  zIndex?: BoxProps['zIndex'];
}

export const TextMarker = ({
  text,
  bg = 'white',
  textColor = 'indigo.800',
  fontWeight = 'semibold',
  zIndex = 10,
}: ITextMarkerProps) => {
  return (
    <Box bg={bg} borderRadius="6px" px={2} py={1} zIndex={zIndex}>
      <Flex alignItems="center" gap={1}>
        <Text color={textColor} fontWeight={fontWeight}>
          {text}
        </Text>
      </Flex>
    </Box>
  );
};
