import { Button, Link, Tag, useToast } from '@chakra-ui/react';
import { useListingforMlsListingLazyQuery } from '@client/graphql/__generated__/main-operations';
import {
  ListingForSearchMlsListingFragment,
  MlsListingFragment,
  PublicListingFragment,
} from '@client/graphql/__generated__/types';
import { ComponentProps } from 'react';
import { openInNewTab } from '~/apps/consumer/utils/browser.utils';
import { EmbeddedConfig } from '~/apps/embedded/embedded-config';
import { PremiumTag } from '../../PremiumTag/PremiumTag';
import { BaseListingCard } from './BaseListingCard';

interface PublicListingCardProps
  extends Omit<ComponentProps<typeof BaseListingCard>, 'listing'> {
  listing?: ListingForSearchMlsListingFragment;
  mlsListing?: MlsListingFragment;
  isEmbedded?: boolean;
  showSubscribers?: boolean;
  fullWidth?: boolean;
  utmParams?: { [key: string]: string };
}

export const PublicListingCard = ({
  listing,
  mlsListing,
  isEmbedded = false,
  showSubscribers = true,
  fullWidth = false,
  utmParams,
  ...otherProps
}: PublicListingCardProps) => {
  const [listingForMlsListing, { loading }] =
    useListingforMlsListingLazyQuery();
  const toast = useToast();
  let baseStorefrontRoute: string | undefined = undefined;

  if (listing?.property?.slug) {
    baseStorefrontRoute = isEmbedded
      ? `${EmbeddedConfig.baseUrl}/storefront/${listing?.property?.slug}`
      : `/storefront/${listing?.property?.slug}`;
  }

  const storefrontRoute = utmParams
    ? `${baseStorefrontRoute}?${new URLSearchParams(utmParams).toString()}`
    : baseStorefrontRoute;

  const fetchPropertySlugAndNavigate = async () => {
    try {
      const { data } = await listingForMlsListing({
        variables: {
          input: {
            id: mlsListing?.id as string,
          },
        },
      });

      if (data?.syncListingFromMLS?.property?.slug) {
        const slug = data.syncListingFromMLS.property.slug;
        const baseRoute = isEmbedded
          ? `${EmbeddedConfig.baseUrl}/storefront/${slug}`
          : `/storefront/${slug}`;

        const route = utmParams
          ? `${baseRoute}?${new URLSearchParams(utmParams).toString()}`
          : baseRoute;
        openInNewTab(route);
      } else {
        toast({
          title: 'Could not find property',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: 'Could not find property',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const onCardClick = () => {
    if (storefrontRoute) {
      openInNewTab(storefrontRoute);
    } else if (!storefrontRoute && mlsListing) {
      void fetchPropertySlugAndNavigate();
    }
  };

  const button = (
    <Button
      _hover={{
        textDecoration: 'none',
        bgColor: 'primaryButton.300', // Somehow setting textDecoration to none removes the hover bg color, so manually setting it
      }}
      as={Link}
      borderRadius="sm"
      fontSize="sm"
      href={storefrontRoute}
      isExternal={true}
      isLoading={loading}
      mt={2}
      size="xs"
      variant="solid"
      onClick={(e) => {
        if (!storefrontRoute && mlsListing) {
          void fetchPropertySlugAndNavigate();
        }
        e.stopPropagation();
      }}
    >
      Start an offer
    </Button>
  );

  // display the mls status if only mls listing is provided
  const offersCountDisplay =
    listing?.offersCountDisplay || mlsListing?.mls?.statusText;

  return (
    <BaseListingCard
      button={button}
      fullWidth={fullWidth}
      listing={{ mlsListing, ...(listing || {}) } as PublicListingFragment}
      otherTags={
        offersCountDisplay ? (
          <Tag variant="blueGradient">{offersCountDisplay}</Tag>
        ) : undefined
      }
      showSubscribers={showSubscribers}
      topTags={listing?.isPremium ? <PremiumTag /> : undefined}
      onCardClick={onCardClick}
      {...otherProps}
    />
  );
};
