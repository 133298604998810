import {
  Button,
  Center,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Spacer,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useCallback, useRef, useState } from 'react';
import AvatarEditor from 'react-avatar-editor';
import { useDropzone } from 'react-dropzone';

interface IProfilePictureUploadModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (editedProfilePicture: HTMLCanvasElement) => void;
}

export const ProfilePictureUploadModal = ({
  isOpen,
  onClose,
  onSave,
}: IProfilePictureUploadModalProps) => {
  const editor = useRef<AvatarEditor>(null);
  const onDrop = useCallback(
    (acceptedFiles: File[]) => setImage(acceptedFiles[0]),
    []
  );

  const { getRootProps, getInputProps, isDragAccept } = useDropzone({
    accept: {
      'image/jpeg': [],
      'image/png': [],
    },
    onDrop,
  });
  const [image, setImage] = useState<File>();
  const [scale, setScale] = useState(2);

  const title = !image ? 'Upload Profile Picture' : 'Edit Profile Picture';

  const cancelEdit = () => {
    setImage(undefined);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={cancelEdit}>
      <ModalOverlay />
      <ModalContent bgColor="bg.main">
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {!image ? (
            <VStack
              alignItems="center"
              bgColor={isDragAccept ? 'bg.hover' : 'bg.main'}
              border="1px solid"
              borderColor="gray.300"
              borderStyle="dashed"
              height="100px"
              justifyContent="center"
              mb={6}
              {...getRootProps()}
            >
              <input {...getInputProps()} />
              <Text fontSize="md">Drag and drop picture here</Text>
            </VStack>
          ) : (
            <Center flexDir="column" gap={4}>
              <AvatarEditor
                ref={editor}
                border={120}
                borderRadius={150}
                color={[255, 255, 255, 0.6]}
                height={150}
                image={image} // RGBA
                rotate={0}
                scale={scale}
                width={150}
              />
              <Slider
                aria-label="slider-ex-1"
                colorScheme="primary"
                defaultValue={2}
                max={10}
                min={1}
                step={0.1}
                onChange={(value) => setScale(value)}
              >
                <SliderTrack>
                  <SliderFilledTrack />
                </SliderTrack>
                <SliderThumb />
              </Slider>
            </Center>
          )}
        </ModalBody>
        <ModalFooter>
          <Button size="sm" variant="ghost" onClick={cancelEdit}>
            Cancel
          </Button>
          <Spacer />
          <Button
            size="sm"
            onClick={() => {
              if (editor.current) {
                const canvas =
                  editor?.current?.getImageScaledToCanvas() as HTMLCanvasElement; // eslint-disable-line
                onSave(canvas);
              }
            }}
          >
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
