import { FieldType } from '@client/graphql/__generated__/types';
import { CheckboxIcon } from '../common/Icons/CheckboxIcon';
import { DateIcon } from '../common/Icons/DateIcon';
import { ImageIcon } from '../common/Icons/ImageIcon';
import { InitialIcon } from '../common/Icons/InitialIcon';
import { NumberIcon } from '../common/Icons/NumberIcon';
import { RadioIcon } from '../common/Icons/RadioIcon';
import { SelectIcon } from '../common/Icons/SelectIcon';
import { SignatureIcon } from '../common/Icons/SignatureIcon';
import { SignedDateIcon } from '../common/Icons/SignedDateIcon';
import { TextIcon } from '../common/Icons/TextIcon';
import { TextareaIcon } from '../common/Icons/TextareaIcon';

export const FIELD_ICONS: Record<FieldType, JSX.Element> = {
  [FieldType.TEXT]: <TextIcon />,
  [FieldType.DATE]: <DateIcon />,
  [FieldType.NUMBER]: <NumberIcon />,
  [FieldType.TEXTAREA]: <TextareaIcon />,
  [FieldType.RADIO]: <RadioIcon />,
  [FieldType.CHECKBOX]: <CheckboxIcon />,
  [FieldType.SELECT]: <SelectIcon />,
  [FieldType.IMAGE]: <ImageIcon />,
  [FieldType.INITIAL]: <InitialIcon />,
  [FieldType.SIGNATURE]: <SignatureIcon />,
  [FieldType.SIGNED_DATE]: <SignedDateIcon />,
};

export const FIELD_ICON_ELEMENTS = {
  [FieldType.TEXT]: TextIcon,
  [FieldType.DATE]: DateIcon,
  [FieldType.NUMBER]: NumberIcon,
  [FieldType.TEXTAREA]: TextareaIcon,
  [FieldType.RADIO]: RadioIcon,
  [FieldType.CHECKBOX]: CheckboxIcon,
  [FieldType.SELECT]: SelectIcon,
  [FieldType.IMAGE]: ImageIcon,
  [FieldType.INITIAL]: InitialIcon,
  [FieldType.SIGNATURE]: SignatureIcon,
  [FieldType.SIGNED_DATE]: SignedDateIcon,
};
