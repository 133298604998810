import { Button, Checkbox, FormControl, Icon, VStack } from '@chakra-ui/react';
import * as Sentry from '@sentry/react';
import { Field, Form, Formik } from 'formik';
import { PenToolIcon } from 'lucide-react';
import { useCallback, useState } from 'react';
import { TextField } from '~/apps/consumer/components/Formik/TextField';
import { useSignGuestbookQueries } from './hooks/useSignGuestbookQueries';
import { useOpenHouseSignContext } from './OpenHouseSignContext';
import { PhoneNumberFormControlWithValidation } from './PhoneNumberFormControlWithValidation';
import { UnstyledFormLabel } from './UnstyledFormLabel';
import { validateForm } from './validate';

const initialValues: BuyerSignerFormValues = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  workingWithBuyerAgent: false,
};

const basicFormFields = [
  { name: 'firstName', label: 'First name', placeholder: 'John' },
  { name: 'lastName', label: 'Last name', placeholder: 'Doe' },
  { name: 'email', label: 'Email', placeholder: 'john.doe@example.com' },
];

export type BuyerSignerFormValues = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  workingWithBuyerAgent: boolean;
};

export const BuyerSignerFormTab = () => {
  const { openHouseData, redirectToStorefront, setSignUpCompleted } =
    useOpenHouseSignContext();
  const openHouseId = openHouseData?.openHouse.id;

  const { useGetOrCreateConsumerMutation, useSignGuestbookMutation } =
    useSignGuestbookQueries();
  const [getOrCreateConsumer, { loading }] = useGetOrCreateConsumerMutation;
  const [signOpenHouseGuestbook, { loading: signOpenHouseGuestbookLoading }] =
    useSignGuestbookMutation;

  const [formKey, setFormKey] = useState(0);

  const reloadForm = useCallback(() => {
    setFormKey((prevKey) => prevKey + 1);
  }, []);

  const handleSubmit = async (input: BuyerSignerFormValues) => {
    if (!openHouseId) return;

    try {
      const { data: foundUser } = await getOrCreateConsumer({
        variables: {
          input: {
            email: input.email,
            phone: `+1${input.phone}`,
            firstName: input.firstName,
            lastName: input.lastName,
          },
        },
      });
      const userUuid = foundUser?.getOrCreateConsumerByPhoneNumber?.id;

      if (userUuid) {
        await signOpenHouseGuestbook({
          variables: {
            input: {
              openHouseId,
              userId: userUuid,
              sendNotification: !redirectToStorefront,
              metadata: {
                // keep a snapshot of the metadata at the time of signing, for debugging purposes
                form: 'openhouse-buyer',
                data: JSON.stringify({
                  userUuid: userUuid || '',
                  ...input,
                }),
              },
            },
          },
        });
      }
    } catch (error) {
      Sentry.captureException(error as Error, {
        level: 'error',
        extra: {
          component: 'BuyerSignerFormTab',
          function: 'handleSubmit',
          input,
        },
      });
    } finally {
      setSignUpCompleted(true);
      reloadForm();
    }
  };

  return (
    <Formik<BuyerSignerFormValues>
      key={formKey}
      initialValues={initialValues}
      validate={validateForm}
      onSubmit={handleSubmit}
    >
      {(props) => {
        const { setFieldValue, isValid, values } = props;

        return (
          <VStack as={Form} direction="column" gap={6}>
            {basicFormFields.map((field) => (
              <FormControl key={field.name} isRequired>
                <UnstyledFormLabel>{field.label}</UnstyledFormLabel>
                <TextField
                  as={Field}
                  id={field.name}
                  name={field.name}
                  placeholder={field.placeholder}
                />
              </FormControl>
            ))}
            <PhoneNumberFormControlWithValidation {...props} />
            <FormControl>
              <Checkbox
                id="workingWithBuyerAgent"
                isChecked={values.workingWithBuyerAgent || false}
                name="workingWithBuyerAgent"
                onChange={(e) => {
                  void setFieldValue('workingWithBuyerAgent', e.target.checked);
                }}
              >
                I'm working with a buyer's agent
              </Checkbox>
            </FormControl>
            <Button
              alignItems="center"
              isDisabled={!isValid}
              isLoading={loading || signOpenHouseGuestbookLoading}
              justifyContent="center"
              leftIcon={<Icon as={PenToolIcon} />}
              type="submit"
              w="100%"
            >
              Sign
            </Button>
          </VStack>
        );
      }}
    </Formik>
  );
};
