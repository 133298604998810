import { UserObject } from '@client/graphql/__generated__/types';
import { useStytch } from '@stytch/react';
import { useSearchParams } from 'react-router-dom';
import { useCustomTourContext } from '~/apps/consumer/contexts/CustomTourContext/CustomTourContext';

export const useStytchLogin = () => {
  const [searchParams] = useSearchParams();
  const stytchClient = useStytch();
  const { viewedTours } = useCustomTourContext();

  /**
   * Send OTP and log in or create a stytch user with the specified phone number
   * @param phoneNumber - The phone number to log in or create a user with
   * @returns The method ID
   * [Ref] https://stytch.com/docs/api/log-in-or-create-user-by-sms
   */
  const getMethodIdAndLogIn = async (phoneNumber: string) => {
    return stytchClient.otps.sms.loginOrCreate(phoneNumber);
  };

  /**
   * Authenticates the OTP and returns the user object
   * !!MARK - we'll update session in backend and log user in
   * @param code - The OTP code
   * @param methodId - The method ID
   * @returns The user object
   */
  const authenticateOtpAndLogIn = async (
    code: string,
    methodId: string
  ): Promise<UserObject> => {
    return fetch('/auth/authenticate-otp', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        code,
        methodId,
        tourNames: viewedTours,
        invitationCode: searchParams?.get('invitationCode'),
      }),
    }).then(async (response) => {
      if (!response.ok) {
        const errorData = (await response.json()) as {
          message: string;
        };
        throw new Error(
          errorData?.message || `HTTP error: status: ${response.status}`
        );
      }

      return response.json() as Promise<UserObject>;
    });
  };

  return {
    getMethodIdAndLogIn,
    authenticateOtpAndLogIn,
  };
};
