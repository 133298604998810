import { useCurrentUserQuery } from '@client/graphql/__generated__/main-operations';
import * as Sentry from '@sentry/react';
import { useEffect } from 'react';

export const useCurrentUser = () => {
  const { data, loading, refetch } = useCurrentUserQuery();

  useEffect(() => {
    if (data?.currentUser?.id) {
      Sentry.setUser({
        id: data.currentUser.id,
        email: data.currentUser.email,
        username: data.currentUser.phoneNumber,
        name: data.currentUser.fullName,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.currentUser?.id]);

  return {
    data,
    loading,
    refetch,
  };
};
