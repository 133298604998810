import { chakra, Flex } from '@chakra-ui/react';

export const ComparablesContainer = chakra(Flex, {
  baseStyle: {
    backgroundColor: 'card.bg',
    border: '1px solid',
    borderRadius: '12px',
    borderColor: 'border.divider',
    flexWrap: 'nowrap',
    isolation: 'isolate',
    overflowX: 'auto',
    overscrollBehaviorX: 'contain',
  },
});
