import { Center, Spinner } from '@chakra-ui/react';
import { useFindMlsAgentsByPhoneNumberLazyQuery } from '@client/graphql/__generated__/main-operations';
import {
  FindMlsAgentFragment,
  UserObject,
} from '@client/graphql/__generated__/types';
import { useEffect, useState } from 'react';
import { useRegisteredRoles } from '~/apps/consumer/hooks/useRegisteredRoles';
import { AutofilledAgent } from '~/services/main/components/AgentSignUpFlow/AutofilledAgent';
import { useAppContext } from '../../contexts/AppContext';
import { AgentSignupForm } from './AgentSignupForm';

interface AgentSignUpFlowProps {
  signupFormInstructions?: string;
  onSignup: (user: UserObject) => Promise<void> | void;
  stytchUser?: UserObject | null;
}

export const AgentSignupFlow = ({
  signupFormInstructions,
  onSignup,
  stytchUser,
}: AgentSignUpFlowProps) => {
  const { isAuthenticated, user } = useAppContext();
  const { isRegisteredAgent } = useRegisteredRoles();
  const [findAgentsByPhoneNumber] = useFindMlsAgentsByPhoneNumberLazyQuery();
  const [mlsAgentsInfo, setMlsAgentsInfo] = useState<FindMlsAgentFragment[]>();
  const [showAgentSignup, setShowAgentSignup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    void (async () => {
      if ((isAuthenticated || !!stytchUser) && !isRegisteredAgent) {
        setIsLoading(true);

        const phoneNumber = isAuthenticated
          ? user?.phoneNumber
          : stytchUser?.phoneNumber ?? '';

        if (phoneNumber) {
          const { data } = await findAgentsByPhoneNumber({
            variables: {
              input: {
                phoneNumber,
              },
            },
          });
          if (data?.findMLSAgentsByPhoneNumber?.length) {
            setMlsAgentsInfo(data.findMLSAgentsByPhoneNumber);
          } else {
            setShowAgentSignup(true);
          }
        } else {
          setShowAgentSignup(true);
        }

        setIsLoading(false);
      }
    })();
  }, [
    isRegisteredAgent,
    user,
    isAuthenticated,
    stytchUser,
    findAgentsByPhoneNumber,
  ]);

  if (isLoading) {
    return (
      <Center width="100%">
        <Spinner />{' '}
      </Center>
    );
  }

  return mlsAgentsInfo && mlsAgentsInfo.length > 0 && !showAgentSignup ? (
    <AutofilledAgent
      mlsAgentsInfo={mlsAgentsInfo}
      setShowAgentSignup={setShowAgentSignup}
      onConfirm={onSignup}
    />
  ) : (
    <AgentSignupForm
      signupFormInstructions={signupFormInstructions}
      onSignup={onSignup}
    />
  );
};
