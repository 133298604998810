import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Text,
  VStack,
} from '@chakra-ui/react';
import {
  useDocumentSectionListForOfferFlowQuery,
  useDocumentVersionListForOfferFlowQuery,
} from '@client/graphql/__generated__/document-operations';
import { DocumentSectionCategory } from '@client/graphql/__generated__/types';
import { Select } from 'chakra-react-select';
import debounce from 'lodash/debounce';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { DocumentSection } from '~/apps/consumer/components/OfferFlow/DocumentSection';
import { useRegisteredRoles } from '~/apps/consumer/hooks/useRegisteredRoles';
import { CenterSpinner } from '~/common/components/CenterSpinner';
import { ConfirmationPopover } from '~/common/components/ConfirmationPopover/ConfirmationPopover';
import { useIsMobile } from '~/common/hooks/useIsMobile';
import { useLocalStorageState } from '~/common/hooks/useLocalStorageState';
import { useDocumentSectionsByPage } from '~/services/document/components/DocumentSections/hooks/useDocumentSectionsByPage';
import { DocumentSection as DocumentSectionType } from '~/services/document/components/DocumentSections/types';
import { useFormFieldsMap } from '~/services/document/components/FormFields/hooks/useFormFieldsMap';
import { useDocumentVersionFormFields } from '~/services/document/hooks/useDocumentVersionFormFields';
import { OfferFlowContainer } from './OfferFlowContainer';
import { useOfferFlowContext } from './OfferFlowContext';

export const FillDocsPage: FC = () => {
  const {
    activeDocument,
    fieldValues,
    updateFieldValues,
    activeDocumentVersion,
    updateContractPreviewState,
    visiblePageNumber,
    setActiveMappingKey,
    activePreviewDocumentVersion,
    mergedAutofillValues,
    latestOffer,
    showContractPreview,
    additionalDocs,
    otherDisclosureContractDocs,
    contractPreviewDocIndex,
    queriesLoading,
    isLoading,
    setContractPreviewDocIndex,
  } = useOfferFlowContext();
  const { isRegisteredAgent } = useRegisteredRoles();

  const isMobile = useIsMobile();
  const [showTooltip, setShowTooltip] = useState(false);

  const { localStorageState, updateLocalStorageState, saveLocalStorageState } =
    useLocalStorageState<{ hasSeenTooltip: boolean }>(
      `fill-docs-tooltip-${latestOffer?.id ?? ''}`
    );

  const { data, loading: documentSectionListLoading } =
    useDocumentSectionListForOfferFlowQuery({
      variables: {
        filters: {
          documentVersionId: activePreviewDocumentVersion?.id as string,
          documentSectionCategory: DocumentSectionCategory.OTHER,
        },
      },
      skip: !activePreviewDocumentVersion?.id,
    });

  const { data: documentVersionList } = useDocumentVersionListForOfferFlowQuery(
    {
      variables: {
        isAgentVerified: isRegisteredAgent,
        skipSecondaryVersions: true,
        documentVersionFields: true,
        filters: {
          id: activePreviewDocumentVersion?.id,
        },
      },
      fetchPolicy: 'cache-first',
      skip: !activePreviewDocumentVersion?.id,
    }
  );
  const documentVersion = isRegisteredAgent
    ? documentVersionList?.documentVersionList?.results?.[0]
    : documentVersionList?.publicDocumentVersionList?.results?.[0];

  const formFields = useDocumentVersionFormFields(
    documentVersion?.documentVersionFields || []
  );
  const formFieldsMap = useFormFieldsMap(formFields);

  const documentSections = useDocumentSectionsByPage({
    formFieldsMap,
    documentSections: data?.publicDocumentSectionList
      ?.results as DocumentSectionType[],
  });

  useEffect(() => {
    if (!activeDocumentVersion?.id) return;

    setTimeout(() => {
      updateContractPreviewState('show');
    }, 500);
  }, [updateContractPreviewState, activeDocumentVersion?.id]);
  useEffect(() => {
    setPageNumber(visiblePageNumber || 1);
  }, [visiblePageNumber]);

  useEffect(() => {
    if (!isMobile) {
      return;
    }
    if (showContractPreview) {
      const pageContainer = document.querySelector(
        `div[data-page-number="${pageNumber}"]`
      );
      if (pageContainer) {
        pageContainer.scrollIntoView();
      }
    }
  }, [showContractPreview, isMobile]); // eslint-disable-line react-hooks/exhaustive-deps
  const [pageNumber, setPageNumber] = useState(visiblePageNumber || 1);

  const pageNumbers = documentSections.map(([pages]) => pages);

  const otherDocsDataSource = useMemo(
    () =>
      otherDisclosureContractDocs?.map((doc) => ({
        value: doc.id,
        label:
          doc?.documentVersion?.document?.name ||
          doc?.externalDocumentName ||
          doc?.file?.fileName,
      })),
    [otherDisclosureContractDocs]
  );

  const contractDocOptions = [
    {
      value: activeDocument?.id,
      label: activeDocument?.name,
    },
    ...additionalDocs.map((doc) => ({
      value: doc.id,
      label: doc.document.name,
    })),
    ...otherDocsDataSource,
  ];

  const debouncedSetShowTooltip = useMemo(
    () =>
      debounce((showTooltip: boolean) => {
        setShowTooltip(showTooltip);
      }, 100),
    [setShowTooltip]
  );
  const saveLocalStorageRef = useRef(saveLocalStorageState);
  const activeAccordionIndex = pageNumbers.findIndex((values) =>
    values.includes(pageNumber)
  );

  useEffect(() => {
    if (latestOffer?.id) {
      debouncedSetShowTooltip(!localStorageState.hasSeenTooltip);
    }
  }, [
    localStorageState.hasSeenTooltip,
    latestOffer?.id,
    debouncedSetShowTooltip,
  ]);

  useEffect(() => {
    saveLocalStorageRef.current = saveLocalStorageState;
  }, [saveLocalStorageState]);

  if (
    isLoading ||
    queriesLoading ||
    (!data?.publicDocumentSectionList && documentSectionListLoading)
  ) {
    return <CenterSpinner />;
  }

  return (
    <OfferFlowContainer
      key={activePreviewDocumentVersion?.id || '0'}
      onContinueRoutePath="./review"
    >
      {(!isMobile || !showContractPreview) && (
        <ConfirmationPopover
          anchor={
            <Box mb={4} width="100%">
              <Select
                menuPortalTarget={document.body}
                options={contractDocOptions}
                placeholder="Select  document type"
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  container: (base) => ({ ...base, width: '100%' }),
                }}
                value={{
                  label:
                    contractDocOptions[contractPreviewDocIndex || 0]?.label,
                  value:
                    contractDocOptions[contractPreviewDocIndex || 0]?.value,
                }}
                onChange={(option) => {
                  if (option) {
                    const docIndex = contractDocOptions.findIndex(
                      (doc) => doc.value === option.value
                    );
                    setContractPreviewDocIndex(docIndex);
                  }
                }}
              />
            </Box>
          }
          handleClick={() => {
            setShowTooltip(false);
            updateLocalStorageState({ hasSeenTooltip: true });
            setTimeout(() => {
              saveLocalStorageRef.current();
            }, 1);
          }}
          isOpen={showTooltip}
          text="Select which document you’d like to fill out. Be sure to review them all!"
        />
      )}
      {documentSections?.length ? (
        <Accordion index={activeAccordionIndex}>
          {documentSections.map(([pages, sections]) => {
            return !pages.includes(0) ? (
              <AccordionItem key={pages.join('-')}>
                <AccordionButton
                  onClick={() => {
                    setPageNumber(pages[0]);
                    const pageContainer = document.querySelector(
                      `div[data-page-number="${pages[0]}"]`
                    );
                    if (pageContainer) {
                      pageContainer.scrollIntoView();
                    }
                  }}
                >
                  Page {pages.join(' - ')}
                </AccordionButton>
                <AccordionPanel
                  alignItems="stretch"
                  as={VStack}
                  flexGrow={1}
                  justifyContent="stretch"
                  px={0}
                  spacing={4}
                >
                  {pages.includes(pageNumber)
                    ? sections.map((section) => (
                        <DocumentSection
                          key={section.id}
                          documentSection={section}
                          fieldValues={fieldValues}
                          formFieldsMap={formFieldsMap}
                          mergedAutofillValues={mergedAutofillValues}
                          setActiveMappingKey={setActiveMappingKey}
                          validations={latestOffer?.contract?.validations}
                          onChange={updateFieldValues}
                        />
                      ))
                    : null}
                </AccordionPanel>
              </AccordionItem>
            ) : null;
          })}
        </Accordion>
      ) : (
        <Flex
          alignItems="center"
          background="whiteAlpha.50"
          borderRadius="4px"
          justifyContent="center"
          p={20}
        >
          <Text color="whiteAlpha.500">
            Please fill out the document by clicking on the blue text boxes
            directly in the document, or add text/check boxes as you need.
          </Text>
        </Flex>
      )}
    </OfferFlowContainer>
  );
};
