import { Flex, Text, VStack } from '@chakra-ui/react';
import { usePublicUserWithLicensesQuery } from '@client/graphql/__generated__/main-operations';
import { useEffect, useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router';
import { DismissableInfo } from '~/common/components/DismissableInfo/DismissableInfo';
import { useIsMobile } from '~/common/hooks/useIsMobile';
import { useAppContext } from '~/services/main/contexts/AppContext';
import { CopyPasteButton } from '../../components/CopyPasteButton';
import { SimpleHeader } from '../../components/Layouts/SimpleHeader';
import { MainLayout } from '../MainLayout';
import { AgentCardForPublicPage } from './AgentCardForPublicPage';
import { PublicUserListings } from './PublicUserListings';
import { hasShownProfileHelperInfo, setShowProfileHelperInfo } from './utils';

export const AgentProfilePage = () => {
  const { userName } = useParams<{ userName: string }>();
  const isMobile = useIsMobile();
  const { isAuthenticated, user: currentUser } = useAppContext();
  const [showInfo, setShowInfo] = useState(
    !hasShownProfileHelperInfo() && isAuthenticated
  );
  const navigate = useNavigate();

  const { data, error } = usePublicUserWithLicensesQuery({
    variables: { input: { userName } },
    skip: !userName,
  });

  const user = data?.getPublicUserWithLicenses;
  const isCurrentUser = currentUser?.id === user?.id;

  useEffect(() => {
    if (user?.fullName) {
      document.title = `${user?.fullName} - Profile | Indigo`;
    }
  }, [user?.fullName]);

  if (!userName || error) return <Navigate to="/not-found" />;

  const handleInfoClose = () => {
    setShowProfileHelperInfo();
    setShowInfo(false);
  };

  const handleEdit = () => {
    if (isCurrentUser) {
      navigate('/account');
    }
  };

  const pageContent = (
    <VStack
      alignItems="flex-start"
      flexDirection="column"
      maxWidth={isMobile ? '500px' : '1000px'}
      minHeight="100vh"
      minWidth={isMobile ? '300px' : 'auto'}
      px={{ base: 5, md: 6 }}
      py={isAuthenticated ? 8 : 0}
      spacing={8}
      width="100%"
    >
      {!isAuthenticated && <SimpleHeader />}
      {isCurrentUser && showInfo && (
        <DismissableInfo
          alignItems="center"
          justifyContent="space-between"
          w="full"
          onClose={handleInfoClose}
        >
          <Flex direction="row" flexWrap="wrap" gap={1}>
            <Text>
              Link to this from the MLS or any public website to easily collect
              offers on all of your listings!
            </Text>
            <CopyPasteButton
              buttonContentCopied="Copied!"
              buttonContentCopy="Copy link"
              color="link.skyBlue"
              copyText={window.location.href}
              minWidth="0px"
              size="md"
              textDecoration="underline"
              variant="link"
            />
          </Flex>
        </DismissableInfo>
      )}

      <AgentCardForPublicPage
        isAuthenticated={isAuthenticated}
        user={user}
        onEdit={isCurrentUser ? handleEdit : undefined}
      />
      <PublicUserListings user={user} />
    </VStack>
  );

  return isAuthenticated ? (
    <MainLayout>
      <Flex justifyContent="center" w="full">
        {pageContent}
      </Flex>
    </MainLayout>
  ) : (
    pageContent
  );
};
