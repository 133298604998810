import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import 'mapbox-gl/dist/mapbox-gl.css';

import { Flex } from '@chakra-ui/react';
import {
  ListingComparableSetFragment,
  ListingFragment,
} from '@client/graphql/__generated__/types';
import { LngLatBounds } from 'mapbox-gl';
import { useMemo, useRef } from 'react';
import { Map, MapRef, NavigationControl, ScaleControl } from 'react-map-gl';
import { getListingPrice } from '~/apps/consumer/utils/listing.utils';
import { useDisableMapControlProps } from '~/common/hooks/useDisableMapControlProps';
import { DEFAULT_POSITION } from '../../MapSearch/constants';
import { ExploreMapMarker } from '../../MapSearch/ExploreMap/ExploreMapMarker';
import { CompsMiniMapMarker } from './CompsMiniMapMarker';
import { getInitialCompsBBox } from './utils';

export const CompsMap = ({
  mainListing,
  comparableSet,
  isMini = false,
}: {
  mainListing: ListingFragment;
  comparableSet?: ListingComparableSetFragment;
  isMini?: boolean;
}) => {
  const mapRef = useRef<MapRef>(null);
  const disableMapControlProps = useDisableMapControlProps();
  const initialBBox = getInitialCompsBBox(comparableSet, mainListing, 1);
  const initialBounds = initialBBox
    ? new LngLatBounds([
        initialBBox[0],
        initialBBox[1],
        initialBBox[2],
        initialBBox[3],
      ])
    : undefined;

  const markers = useMemo(() => {
    if (isMini) {
      const mainMarker = mainListing?.mlsListing?.geo?.location ? (
        <CompsMiniMapMarker
          key={`marker-${mainListing.id}`}
          isMainListing={true}
          lat={mainListing.mlsListing.geo?.location.lat}
          lng={mainListing.mlsListing.geo?.location.lon}
        />
      ) : null;

      const comparableMarkers = comparableSet?.listingComparables.map(
        ({ mlsListing }) => {
          if (
            !mlsListing.geo?.location?.lat ||
            !mlsListing.geo?.location?.lon
          ) {
            return null;
          }

          return (
            <CompsMiniMapMarker
              key={`marker-${mlsListing.id}`}
              isMainListing={false}
              lat={mlsListing.geo?.location.lat}
              lng={mlsListing.geo?.location.lon}
            />
          );
        }
      );

      return [...(comparableMarkers ?? []), mainMarker];
    }

    const mainMarker = mainListing?.mlsListing?.geo?.location ? (
      <ExploreMapMarker
        key={`marker-${mainListing.id}`}
        bgOverride="white"
        colorOverride="black"
        isPremium={true}
        lat={mainListing.mlsListing.geo?.location.lat}
        lng={mainListing.mlsListing.geo?.location.lon}
        price={getListingPrice(mainListing.mlsListing)}
        zoom={mapRef.current?.getZoom() || DEFAULT_POSITION.zoom}
      />
    ) : null;

    const comparableMarkers = comparableSet?.listingComparables.map(
      ({ mlsListing }) => {
        if (!mlsListing.geo?.location?.lat || !mlsListing.geo?.location?.lon) {
          return null;
        }

        const price = getListingPrice(mlsListing);

        return (
          <ExploreMapMarker
            key={`marker-${mlsListing.id}`}
            isPremium={true}
            lat={mlsListing.geo.location.lat}
            lng={mlsListing.geo.location.lon}
            price={price}
            zoom={mapRef.current?.getZoom() || DEFAULT_POSITION.zoom}
          />
        );
      }
    );

    return [...(comparableMarkers ?? []), mainMarker];
  }, [comparableSet, mainListing, mapRef, isMini]);

  return (
    <Flex
      direction={{ base: 'column-reverse', xl: 'column' }}
      flexBasis={0}
      flexGrow={1}
      height="100%"
    >
      <Map
        ref={mapRef}
        {...disableMapControlProps}
        dragPan={!isMini}
        initialViewState={{
          bounds: initialBounds,
          longitude: mainListing?.mlsListing?.geo?.location?.lon,
          latitude: mainListing?.mlsListing?.geo?.location?.lat,
          zoom: DEFAULT_POSITION.zoom,
        }}
        mapStyle="mapbox://styles/mapbox/navigation-guidance-night-v4"
        mapboxAccessToken={window.ENVS.mapboxApiKey}
        minZoom={3}
        style={{
          borderRadius: '6px',
        }}
        testMode={window.ENVS.environment === 'e2e'}
      >
        {!isMini && (
          <>
            <NavigationControl />
            <ScaleControl />
          </>
        )}
        {markers}
      </Map>
    </Flex>
  );
};
