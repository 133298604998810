import { useAppContext } from '~/services/main/contexts/AppContext';
import {
  getIsRegisteredAgent,
  getIsRegisteredConsumer,
} from '../utils/roles.utils';

export interface RegisteredRoles {
  isRegisteredAgent: boolean;
  isRegisteredConsumer?: boolean;
}

export const useRegisteredRoles = (): RegisteredRoles => {
  const { user } = useAppContext();
  const isRegisteredAgent = getIsRegisteredAgent(user);

  const isRegisteredConsumer =
    !isRegisteredAgent && getIsRegisteredConsumer(user);

  return {
    isRegisteredAgent,
    isRegisteredConsumer,
  };
};
