import { chakra, Text } from '@chakra-ui/react';

export const TourHeading = chakra(Text, {
  baseStyle: {
    fontSize: { base: '48px', md: '60px' },
    fontWeight: 500,
    background: 'blueGradient',
    backgroundClip: 'text',
    fontFamily: 'Cardinal Photo',
    lineHeight: '66px',
  },
});

export const TourSubtitle = chakra(Text, {
  baseStyle: {
    fontSize: { base: 'xl', md: '2xl' },
    fontWeight: 400,
    textAlign: 'center',
  },
});

export const TourModalPageTitle = chakra(Text, {
  baseStyle: {
    fontSize: '50px',
    fontWeight: 600,
    textAlign: 'center',
    background: 'blueGradient',
    backgroundClip: 'text',
    lineHeight: '1.1',
  },
});

export const TourModalPageSubtitle = chakra(Text, {
  baseStyle: {
    fontSize: 'lg',
    fontWeight: 400,
    textAlign: 'center',
  },
});
