import { AddIcon } from '@chakra-ui/icons';
import {
  Avatar,
  AvatarProps,
  Box,
  IconButton,
  useDisclosure,
} from '@chakra-ui/react';
import { UserObject } from '@client/graphql/__generated__/types';
import { useState } from 'react';
import { useAppContext } from '~/services/main/contexts/AppContext';
import { ProfilePictureUploadModal } from './ProfilePictureUploadModal';

interface IProfilePictureProps {
  id?: string;
  isEdit?: boolean;
  onProfilePictureChange?: (picture: File, pictureUrl: string) => void;
  size?: AvatarProps['size'];
  onClick?: () => void;
}

export const ProfilePicture = ({
  id,
  isEdit = true,
  size = 'md',
  onProfilePictureChange,
  onClick,
}: IProfilePictureProps) => {
  const { user } = useAppContext();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [profilePicture, setProfilePicture] = useState<string>();

  const defaultPictureUrl = user?.profilePicture?.url;
  const name = user?.fullName;

  return (
    <>
      <Box
        _hover={{
          cursor: isEdit || onClick ? 'pointer' : 'default',
        }}
        id={id}
        position="relative"
        sx={{
          ':hover > span': {
            opacity: 0.8,
            transition: 'opacity 0.3s ease',
          },
          ':hover > button': {
            opacity: 0.7,
            transition: 'opacity 0.3s ease',
          },
        }}
        onClick={() => {
          if (isEdit) onOpen();
          if (onClick) onClick();
        }}
      >
        <Avatar
          name={name}
          size={isEdit ? 'xl' : size}
          src={profilePicture || defaultPictureUrl}
        />
        {isEdit && (
          <IconButton
            isRound
            _hover={{
              opacity: 0.7,
              transition: 'opacity 0.3s ease',
            }}
            aria-label="Add Profile Picture"
            h="32px"
            icon={<AddIcon />}
            opacity="1.0"
            position="absolute"
            right="0px"
            size="sm"
            top="64px"
            w="24px"
            onClick={() => {}}
          />
        )}
      </Box>
      {isEdit && (
        <ProfilePictureUploadModal
          isOpen={isOpen}
          onClose={onClose}
          onSave={(canvas: HTMLCanvasElement) => {
            const newPictureUrl = canvas.toDataURL();
            setProfilePicture(newPictureUrl);
            canvas.toBlob((blob) => {
              const editedPictureFile = new File(
                [blob as Blob],
                `${(user as UserObject).id}-profilePicture.png`
              );
              onProfilePictureChange?.(editedPictureFile, newPictureUrl);
              onClose();
            });
          }}
        />
      )}
    </>
  );
};
