import { Center, Spinner, VStack } from '@chakra-ui/react';
import { OfferObject } from '@client/graphql/__generated__/types';
import { FC } from 'react';
import { DownloadOfferDocumentsButton } from '~/apps/consumer/components/Offers/DownloadOfferDocumentsButton';
import { OfferDetails } from '~/apps/consumer/components/Offers/OfferDetails/OfferDetails';
import { OfferFlowContainer } from '../../Storefront/OfferFlow/OfferFlowContainer';
import { useOfferFlowContext } from '../../Storefront/OfferFlow/OfferFlowContext';

export const ListingOfferReviewPage: FC = () => {
  const { listing, latestOffer, latestOfferLoading } = useOfferFlowContext();
  const isOfferSubmitted = !!latestOffer?.submittedAt;

  if (latestOfferLoading) {
    return (
      <Center mt={6}>
        <Spinner />
      </Center>
    );
  }

  const offerId = (latestOffer as OfferObject).id;

  const customContinueButton = isOfferSubmitted ? (
    <DownloadOfferDocumentsButton
      buyersAgentName={latestOffer.buyersAgent?.user.fullName}
      contractId={latestOffer.contract?.id}
      propertySlug={listing.property.slug}
    />
  ) : undefined;

  return (
    <>
      <OfferFlowContainer
        // TODO: can we remove the OfferFlowContainer wrapper?
        customContinueButton={customContinueButton}
        showContinueButton={!!customContinueButton}
      >
        <VStack alignItems="stretch" flexGrow={1} pt={5} spacing={4}>
          <OfferDetails listing={listing} offerId={offerId} />
        </VStack>
      </OfferFlowContainer>
    </>
  );
};
