import { Flex, HStack } from '@chakra-ui/react';
import { useIsMobile } from '~/common/hooks/useIsMobile';
import { useAppContext } from '~/services/main/contexts/AppContext';
import { SimpleHeader } from '../../components/Layouts/SimpleHeader';
import { MapSearch } from '../../components/MapSearch/MapSearch';
import { MainLayout } from '../MainLayout';

export const MapSearchPageContent = () => {
  const { isAuthenticated } = useAppContext();

  const screenHeight = '100dvh';

  return (
    <>
      <HStack
        height={screenHeight}
        maxHeight={screenHeight}
        spacing={0}
        width="100%"
      >
        <MapSearch showUnauthenticatedHeader={!isAuthenticated} />
      </HStack>
    </>
  );
};

export const MapSearchPage = () => {
  const { isAuthenticated } = useAppContext();
  const isMobile = useIsMobile(true);

  let page = <MapSearchPageContent />;

  if (isAuthenticated) {
    page = (
      <MainLayout tabletIsMobile={true} transparentMobileHeader={true}>
        {page}
      </MainLayout>
    );
  } else if (isMobile) {
    page = (
      <>
        <Flex
          backdropFilter="blur(2px)"
          background="linear-gradient(180deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.25) 100%)"
          position="absolute"
          px={4}
          top={0}
          width="100%"
          zIndex="5"
        >
          <SimpleHeader />
        </Flex>

        {page}
      </>
    );
  }

  return page;
};
