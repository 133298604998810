import { Icon, IconProps } from '@chakra-ui/react';

export const SelectIcon = (props: IconProps) => (
  <Icon
    color="primary.500"
    fill="none"
    height="15px"
    viewBox="0 0 24 15"
    width="24px"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      height="13"
      stroke="currentColor"
      strokeWidth="2"
      width="22"
      x="1"
      y="1"
    />
    <path
      d="M6.32558 5.17H4.21558V4H9.79558V5.17H7.68558V11H6.32558V5.17Z"
      fill="currentColor"
    />
    <g clipPath="url(#clip0_61_19319)">
      <path
        d="M19.8125 6.82776L17.1325 9.50751C17.1151 9.52493 17.0945 9.53874 17.0717 9.54817C17.049 9.5576 17.0246 9.56245 17 9.56245C16.9754 9.56245 16.951 9.5576 16.9283 9.54817C16.9055 9.53874 16.8849 9.52493 16.8675 9.50751L14.1875 6.82776"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_61_19319">
        <rect fill="white" height="6" transform="translate(14 5)" width="6" />
      </clipPath>
    </defs>
  </Icon>
);
