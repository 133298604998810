import { StorefrontSetupStep } from '@client/graphql/__generated__/types';
import { STEP_TO_ROUTE_MAP } from '../pages/ListingSetup/StorefrontSetupWizard/utils';

export const getStorefrontSetupWizardRoute = (
  listingId: string,
  continueStep?: StorefrontSetupStep
) => {
  const routePrefix = `/storefront-setup/${listingId}`;

  return continueStep
    ? `${routePrefix}/${STEP_TO_ROUTE_MAP[continueStep]}`
    : routePrefix;
};

export const getListingRoute = (listingId: string, route?: string) => {
  const routePrefix = `/listings/${listingId}`;

  return route ? `${routePrefix}/${route}` : routePrefix;
};

/**
 * Get URL for offer details modal/page for listing agent view in listing admin.
 * @param listingId
 * @param offerId
 * @param searchParams
 * @returns
 */
export function offerDetailsPath(
  listingId: string,
  offerId: string,
  searchParams: string
): string {
  return `/listings/${listingId}/offers/${offerId}${searchParams}`;
}

export function listingOffersPath(listingId: string): string {
  return `/listings/${listingId}/offers`;
}

export function listingOpenHousesPath(
  listingId: string,
  create?: boolean
): string {
  return `/listings/${listingId}/open-houses${create ? '?create=true' : ''}`;
}

export function openHousePublicSignupPath(openHouseId: string): string {
  return `/open-house/${openHouseId}`;
}

export function listingGuestbookPath(listingId: string): string {
  return `/listings/${listingId}/guestbook`;
}

export function listingsPath(): string {
  return '/listings';
}

export function offerDetailsPathForBuyer(
  partyId: string,
  offerId: string
): string {
  return `/buyers/${partyId}/purchase-offers/${offerId}/review`;
}

export function getAgentProfilePage(userName: string): string {
  return `/agent/${userName}`;
}
