import { Icon, IconProps } from '@chakra-ui/react';
import { memo } from 'react';

export const DotIcon = memo((props: IconProps) => {
  return (
    <Icon
      fill="none"
      height="7px"
      viewBox="0 0 6 7"
      width="6px"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="3" cy="3.5" fill="currentColor" r="3" />
    </Icon>
  );
});
