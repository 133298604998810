import { Icon, IconProps } from '@chakra-ui/react';

export const TextIcon = (props: IconProps) => (
  <Icon
    color="primary.500"
    fill="none"
    height="23"
    viewBox="0 0 23 23"
    width="23"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <line
      stroke="currentColor"
      strokeLinecap="round"
      strokeWidth="2"
      x1="10"
      x2="14"
      y1="1.8335"
      y2="1.8335"
    />
    <line
      stroke="currentColor"
      strokeLinecap="round"
      strokeWidth="2"
      x1="10"
      x2="14"
      y1="22"
      y2="22"
    />
    <line
      stroke="currentColor"
      strokeLinecap="round"
      strokeWidth="2"
      x1="12"
      x2="12"
      y1="2"
      y2="21.0833"
    />
    <mask
      height="18"
      id="mask0_1001_3461"
      maskUnits="userSpaceOnUse"
      style={{
        maskType: 'alpha',
      }}
      width="23"
      x="0"
      y="1"
    >
      <path
        d="M0 19.0002V8.50015V4.00015L9.1884 4.00014V1.80957L15 1.92172V4.00013L23 4.00012V19.0002H15V4.00013L9.1884 4.00014V19.0002H0Z"
        fill="black"
      />
    </mask>
    <g mask="url(#mask0_1001_3461)">
      <rect
        height="13"
        stroke="currentColor"
        strokeWidth="2"
        width="21"
        x="1"
        y="5"
      />
    </g>
    <path
      d="M5.32566 9.17H3.21566V8H8.79566V9.17H6.68566V15H5.32566V9.17Z"
      fill="currentColor"
    />
  </Icon>
);
