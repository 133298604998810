import { ArrowBackIcon } from '@chakra-ui/icons';
import {
  Button,
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
} from '@chakra-ui/react';
import { useMobileHeaderContext } from '~/apps/consumer/contexts/MobileHeaderContext';
import { NeedHelp } from '../../Layouts/NeedHelp';

interface MobileNavDrawerProps {
  onClickBack: () => void;
  backButtonText: string;
  Sidebar: React.ComponentType<{ onClickSidebarItem: () => void }>;
}

export const MobileNavDrawer = ({
  onClickBack,
  backButtonText,
  Sidebar,
}: MobileNavDrawerProps) => {
  const { isDrawerOpen, onDrawerClose } = useMobileHeaderContext();

  return (
    <Drawer isOpen={isDrawerOpen} placement="left" onClose={onDrawerClose}>
      <DrawerOverlay />
      <DrawerContent overflowY={{ base: 'auto', md: 'initial' }}>
        <DrawerHeader
          alignItems="center"
          borderBottom="1px solid"
          borderBottomColor="border.divider"
        >
          <Button
            color="chakra-body-text"
            leftIcon={<ArrowBackIcon />}
            variant="link"
            onClick={() => {
              onDrawerClose();
              onClickBack();
            }}
          >
            {backButtonText}
          </Button>
          <DrawerCloseButton mt={{ base: 2 }} />
        </DrawerHeader>
        <Sidebar onClickSidebarItem={() => onDrawerClose()} />
        <DrawerFooter p={3}>
          <NeedHelp />
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};
