import { Icon, IconProps } from '@chakra-ui/react';
import { memo } from 'react';

export const BedIcon = memo((props: IconProps) => {
  return (
    <Icon
      fill="none"
      height="20"
      viewBox="0 0 21 20"
      width="21"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_1194_4559)">
        <path
          d="M0.931152 0.740234V19.2586H5.25082V16.6629H16.3039V19.2586H20.6233V16.6629V15.4724V10.3785C20.6233 8.99569 19.4984 7.87078 18.1157 7.87078H9.06475V6.29085C9.06475 5.38243 8.32558 4.6435 7.41715 4.6435H5.25082V0.740234H0.931152ZM4.06034 18.0682H2.12163V1.93071H4.06034V4.64347V9.06078V18.0682ZM19.4328 18.0682H17.4943V16.6629H19.4328V18.0682ZM19.4328 10.3785V15.4724H5.45472V9.06126H18.1156C18.842 9.06126 19.4328 9.65207 19.4328 10.3785ZM7.41715 5.83395C7.6692 5.83395 7.87427 6.03881 7.87427 6.29083V7.87031H5.25082V5.83395H7.41715Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_1194_4559">
          <rect
            fill="white"
            height="18.5714"
            transform="translate(0.777344 0.713867)"
            width="20"
          />
        </clipPath>
      </defs>
    </Icon>
  );
});
