import { FC, memo, PropsWithChildren, useEffect } from 'react';
import { useDocumentMapperContext } from './context';

export const DocumentMapperRightPane: FC<PropsWithChildren> = memo(
  function DocumentMapperRightPane({ children }) {
    const { setRightPaneChildren } = useDocumentMapperContext();

    useEffect(() => {
      setRightPaneChildren(children);
    }, [setRightPaneChildren, children]);

    return null;
  }
);

export const DocumentMapperLeftPane: FC<PropsWithChildren> = memo(
  function DocumentMapperLeftPane({ children }) {
    const { setLeftPaneChildren } = useDocumentMapperContext();

    useEffect(() => {
      setLeftPaneChildren(children);
    }, [setLeftPaneChildren, children]);

    return null;
  }
);
