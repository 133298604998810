import { Box, Text, useToast } from '@chakra-ui/react';
import { useSubmitOfferForBuyersAgentMutation } from '@client/graphql/__generated__/main-operations';
import { FC, useState } from 'react';
import { SubmitOfferModal } from '../../SubmitOfferModal/SubmitOfferModal';
import { SubmitOfferToListingAgentModalProps } from './types';

export const SubmitOfferToListingAgentModal: FC<
  SubmitOfferToListingAgentModalProps
> = ({ isOpen, onClose, offer, onFinish, finishButtonText }) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [submitOfferForBuyersAgent, { loading: submittingOffer }] =
    useSubmitOfferForBuyersAgentMutation();
  const toast = useToast();

  const handleSubmit = async (note: string) => {
    if (!offer) return;
    try {
      await submitOfferForBuyersAgent({
        variables: {
          input: {
            noteToListingAgent: note,
            id: offer?.id,
          },
        },
      });
      setIsSubmitted(true);
    } catch (error) {
      toast({
        title: 'Error submitting offer',
        description: 'An unexpected error occurred submiting your offer.',
        status: 'error',
        isClosable: true,
      });
    }
  };

  const bodyHeader = (
    <Box fontSize="lg">
      <Text color="white" fontWeight="500">
        Almost there!
      </Text>
      <Text color="whiteAlpha.600">
        You're ready to submit your offer, that's great!
      </Text>
    </Box>
  );

  const bodyList = [
    `We'll send the offer package to the listing agent`,
    `You'll be notified when they open it, and when the seller signs`,
    `Once signed, you and your buyer can celebrate closing!`,
  ];

  return (
    <SubmitOfferModal
      bodyHeader={bodyHeader}
      bodyList={bodyList}
      finishButtonText={finishButtonText}
      handleSubmit={handleSubmit}
      isLoading={submittingOffer}
      isOpen={isOpen}
      isSubmitted={isSubmitted}
      modalTitle="Prepare offer for listing agent"
      notePlaceholder="add a note for the listing agent"
      offer={offer}
      sentMessage="We'll notify you once the listing agent opens and views your offer."
      submitButtonText="Send to listing agent"
      onClose={onClose}
      onFinish={onFinish}
    />
  );
};
