import { Flex } from '@chakra-ui/react';
import { PublicListingFragment } from '@client/graphql/__generated__/types';
import { StorefrontOverviewContextType } from '~/apps/consumer/pages/Storefront/StorefrontContext';
import { StorefrontCard } from '../StorefrontCard';
import { ComparablePriceChart } from './ComparablePriceChart';

interface ComparablesCardProps {
  listing: PublicListingFragment;
  storefrontOverviewContext: StorefrontOverviewContextType;
  onLinkClick?: () => void;
}
export const ComparablesCard = ({
  listing,
  storefrontOverviewContext,
  onLinkClick,
}: ComparablesCardProps) => {
  const listingComparableSet = storefrontOverviewContext.listingComparableSet;
  if (!listingComparableSet?.listingComparables?.length) {
    return null;
  }

  return (
    <StorefrontCard
      linkText="View all"
      title="Listing Agent Comps"
      onLinkClick={onLinkClick}
    >
      <Flex alignItems="center" direction="column" gap={2} h="100%">
        <ComparablePriceChart
          listing={listing}
          listingComparableSet={listingComparableSet}
          width={260}
        />
      </Flex>
    </StorefrontCard>
  );
};
