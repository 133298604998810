import { useDisclosure } from '@chakra-ui/react';
import { useGenerateListingPackagePdfMutation } from '@client/graphql/__generated__/main-operations';
import {
  FileObject,
  ListingFragment,
} from '@client/graphql/__generated__/types';
import { useEffect, useState } from 'react';
import { getStorefrontUrlWithAnalytics } from '~/apps/consumer/utils/storefront.utils';

export const AGENT_REMARKS_BLURB_PREFIX =
  'Review seller preferences, download disclosures & securely submit all offers via Indigo at: ';
export const AGENT_REMARKS_BLURB_SUFIX = '';

export const useSharePage = (listing: ListingFragment) => {
  const [listingPackage, setListingPackage] = useState<
    FileObject | undefined
  >();

  const [generateListingPackagePdf, { loading }] =
    useGenerateListingPackagePdfMutation({
      variables: {
        input: {
          id: listing.id,
        },
      },
    });

  useEffect(() => {
    if (!listing) {
      return;
    }
    if (listing?.storefrontSetup?.listingPackage?.url) {
      setListingPackage(listing.storefrontSetup.listingPackage);
    } else {
      void (async () => {
        const { data } = await generateListingPackagePdf();
        setListingPackage(data?.generateListingPackagePdf);
      })();
    }
  }, [listing, generateListingPackagePdf]);

  const [shareMenuIsOpen, setShareMenuIsOpen] = useState(false);
  const [downloadMenuIsOpen, setDownloadMenuIsOpen] = useState(false);
  const {
    isOpen: isShareExpanded,
    onClose: onShareCollapse,
    onOpen: onShareExpand,
  } = useDisclosure();

  const {
    isOpen: isQrCodeExpanded,
    onClose: onQrCodeCollapse,
    onOpen: onQrCodeExpand,
  } = useDisclosure();

  const {
    isOpen: isPdfViewerOpen,
    onClose: onPdfViewerClose,
    onOpen: onPdfViewerOpen,
  } = useDisclosure();

  const agentRemarksStorefrontLink = getStorefrontUrlWithAnalytics(
    listing.property.slug,
    {
      utm_source: 'mls',
      utm_content: 'agent-remarks',
    }
  );
  const agentRemarksBlurbString = `${AGENT_REMARKS_BLURB_PREFIX}${agentRemarksStorefrontLink}${AGENT_REMARKS_BLURB_SUFIX}`;

  const webUrlLink = getStorefrontUrlWithAnalytics(listing.property.slug, {
    utm_source: 'mls',
    utm_content: 'web-url',
  });

  const broadcastLink = getStorefrontUrlWithAnalytics(listing.property.slug, {
    utm_content: 'broadcast-link',
  });

  const qrCodeLink = getStorefrontUrlWithAnalytics(listing.property.slug, {
    utm_source: 'qr',
  });

  const downloadQRCode = (type: 'jpg' | 'png') => {
    const canvas = document.querySelector('canvas');
    const link = document.createElement('a');
    link.download = `${listing.property.slug}-storefront.${type}`;
    link.href = canvas?.toDataURL() || '';
    link.click();
    link.remove();
  };

  // NOTE: Facebook will not display share dialog for localhost URL because they can't resolve the URL.
  const encodedFacebookStorefrontLink = encodeURIComponent(
    getStorefrontUrlWithAnalytics(listing.property.slug, {
      utm_source: 'facebook',
    })
  );
  const facebookShareLink = `https://www.facebook.com/sharer/sharer.php?u=${encodedFacebookStorefrontLink}`;
  const instagramShareLink = 'https://www.instagram.com/';
  const encodedTwitterStorefrontLink = encodeURIComponent(
    getStorefrontUrlWithAnalytics(listing.property.slug, {
      utm_source: 'x-twitter',
    })
  );
  const xShareLink = `https://twitter.com/intent/tweet?text=${encodedTwitterStorefrontLink}`;

  return {
    listingPackage,
    loading,
    shareMenuIsOpen,
    downloadMenuIsOpen,
    isShareExpanded,
    onShareCollapse,
    onShareExpand,
    isQrCodeExpanded,
    onQrCodeCollapse,
    onQrCodeExpand,
    isPdfViewerOpen,
    onPdfViewerClose,
    onPdfViewerOpen,
    agentRemarksStorefrontLink,
    agentRemarksBlurbString,
    webUrlLink,
    broadcastLink,
    qrCodeLink,
    downloadQRCode,
    facebookShareLink,
    instagramShareLink,
    xShareLink,
    setShareMenuIsOpen,
    setDownloadMenuIsOpen,
  };
};
