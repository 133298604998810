import { Box, Portal } from '@chakra-ui/react';
import {
  OfferObject,
  PublicListingFragment,
} from '@client/graphql/__generated__/types';
import { useNavigate, useOutletContext, useParams } from 'react-router';
import { CenterSpinner } from '~/common/components/CenterSpinner';
import { useIsMobile } from '~/common/hooks/useIsMobile';
import { STOREFRONT_SIDEBAR_WIDTHS } from '~/common/theme/sizes';
import { OfferFlowOutlet } from '../Storefront/OfferFlow/OfferFlowOutlet';

export const BuyerOfferDetailPage = () => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const { offerId } = useParams();
  const { purchaseOffersMap } = useOutletContext<{
    purchaseOffersMap: Record<string, Partial<OfferObject>>;
  }>();
  const offer = purchaseOffersMap?.[offerId as string];

  if (!offer?.listing) {
    <Box bottom={0} left={0} position="absolute" right={0} top={0}>
      <CenterSpinner />;
    </Box>;
  }

  return (
    <Portal>
      <Box
        backdropFilter="blur(5px)"
        bgColor="rgba(0,0,0,0.1)"
        bottom={0}
        left={0}
        position="fixed"
        right={0}
        top={0}
        zIndex={4}
        onClick={() => navigate('../')}
      />
      <Box
        alignItems="flex-start"
        bgColor={isMobile ? 'transparent' : 'bg.mainDark'}
        borderLeft="1px solid"
        borderLeftColor="card.border"
        bottom={0}
        flexShrink={0}
        height={{ base: 'auto', md: '100vh' }}
        overflowY="scroll"
        position={isMobile ? 'sticky' : 'absolute'}
        right={0}
        top={0}
        width={STOREFRONT_SIDEBAR_WIDTHS}
        zIndex={5}
      >
        <OfferFlowOutlet
          headerTitle="Your offer"
          // TODO: should stop depending on offer.listing being a PublicListingFragment here.
          listing={offer?.listing as unknown as PublicListingFragment}
          maxWidth="90%"
          showStartOverButton={false}
        />
      </Box>
    </Portal>
  );
};
