import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { MlsListingObject } from '@client/graphql/__generated__/types';
import { useComparablesContext } from '~/apps/consumer/pages/ListingSetup/Comparables/ComparablesContext';
import { AddComparablePopoverList } from './AddComparablePopoverList';

interface AddComparableModalProps {
  onAdd: (listing: MlsListingObject) => void;
  mlsListing: MlsListingObject;
}

export const AddComparableModal = ({
  onAdd,
  mlsListing,
}: AddComparableModalProps) => {
  const { isModalOpen, onCloseModal } = useComparablesContext();

  return (
    <Modal
      isCentered
      isOpen={isModalOpen as boolean}
      onClose={onCloseModal as () => void}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add a comp</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <AddComparablePopoverList mlsListing={mlsListing} onAdd={onAdd} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
