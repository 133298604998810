import { usePartyAndBuyerInfoQuery } from '@client/graphql/__generated__/main-operations';
import { CircleDollarSign, Settings } from 'lucide-react';
import { useParams } from 'react-router';
import { CenterSpinner } from '~/common/components/CenterSpinner';
import { useProdFeatureFlagEnabled } from '~/common/hooks/useProdFeatureFlagEnabled';
import { DocIcon, HomeHeartIcon } from '~/common/icons';
import { ComingSoon } from '../../components/ComingSoon';
import {
  PageDetailLayoutSidebar,
  PageDetailSidebarType,
} from '../../components/PageDetailLayout/PageDetailLayoutSidebar';
import { BuyerPartyPageSidebarHeader } from './BuyerPartyPageSidebarHeader';

interface BuyerPartyPageSidebarProps {
  onClickSidebarItem?: () => void;
}

const iconSize = {
  width: '24px',
  height: '24px',
};

export const BuyerPartyPageSidebar = ({
  onClickSidebarItem,
}: BuyerPartyPageSidebarProps) => {
  const { buyerPartyId } = useParams<string>();

  const { data, loading } = usePartyAndBuyerInfoQuery({
    variables: {
      input: { id: buyerPartyId as string },
    },
    skip: !buyerPartyId,
  });
  const isBuyerAgencyAgreementEnabled = useProdFeatureFlagEnabled(
    'buyer-agency-agreement'
  );

  if (loading || !data?.partyAndBuyerInfo) {
    return <CenterSpinner />;
  }

  const party = data.partyAndBuyerInfo;

  const registeredAgentSidebar: PageDetailSidebarType = [
    {
      icon: <CircleDollarSign {...iconSize} />,
      label: 'Purchase offers',
      rightLabel: party.purchaseOffers?.length,
      to: 'purchase-offers',
    },
    isBuyerAgencyAgreementEnabled
      ? {
          icon: <DocIcon {...iconSize} />,
          label: 'Buyer Agreements',
          to: 'agency-agreements',
        }
      : undefined,
    {
      icon: <Settings {...iconSize} />,
      label: 'Buyer info & docs',
      to: 'buyer-info',
    },
    {
      icon: <HomeHeartIcon {...iconSize} />,
      label: 'Saved homes',
      isDisabled: true,
      rightLabel: <ComingSoon />,
      to: 'purchase-offers', // TODO: update to correct path once we enable this page
    },
  ].filter(Boolean) as PageDetailSidebarType;

  return (
    <PageDetailLayoutSidebar
      SidebarHeader={BuyerPartyPageSidebarHeader}
      sidebarItems={registeredAgentSidebar}
      onClickSidebarItem={onClickSidebarItem}
    />
  );
};
