import { Box, BoxProps, Flex } from '@chakra-ui/react';
import { FC, MutableRefObject, useEffect, useMemo, useRef } from 'react';
import { pdfjs } from 'react-pdf';
import { useDraggable } from 'react-use-draggable-scroll';
import { PDFScrollableDocument } from './PDFScrollableDocument';
import { PDFScrollableContext } from './context';
import {
  useIntersectionObserver,
  useKeyActiveCursor,
  useKeyboardManager,
  usePdfSize,
  useZoom,
} from './hooks';
import {
  DocumentsThumbnails,
  PDFFile,
  PageMetadata,
  VisiblePage,
} from './types';

import { useState } from 'react';
import './style.css';

// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

export interface PDFScrollableProps {
  disableFormFields?: boolean;
  pdfFiles: PDFFile[];
  maxWidth: number;
  pageChildren?: FC<{ page: PageMetadata }>;
  documentsThumbnailsRef?: MutableRefObject<DocumentsThumbnails | undefined>;
  isDraggable?: boolean;
  children?: React.ReactNode;
  defaultZoom?: number;
  thumbnailPages?: number[];
  scale?: number;
  onZoom?: (zoom: number) => void;
  onVisiblePageChange?: (visiblePage: VisiblePage) => void;
  onPagesMetadataChange?: (pagesMetadata: PageMetadata[]) => void;
  onDocumentsThumbnailsChange?: (
    documentsThumbnails: DocumentsThumbnails
  ) => void;
  pdfContainerProps?: BoxProps;
}

export const PDFScrollable: FC<PDFScrollableProps> = ({
  disableFormFields,
  pdfFiles,
  maxWidth,
  pageChildren,
  isDraggable,
  defaultZoom,
  thumbnailPages,
  scale,
  onVisiblePageChange,
  onPagesMetadataChange,
  onDocumentsThumbnailsChange,
  onZoom,
  children,
  pdfContainerProps,
}) => {
  const documentsThumbnailsRef = useRef<DocumentsThumbnails>({});
  const pdfContainerRef = useRef() as MutableRefObject<HTMLDivElement>;
  const scrollableRef = useRef() as MutableRefObject<HTMLDivElement>;
  const zoomContainerRef = useRef() as MutableRefObject<HTMLDivElement>;
  const [scrollableElem, setScrollableElem] = useState<HTMLDivElement>();
  const draggable = useDraggable(scrollableRef, {
    applyRubberBandEffect: true,
    isMounted: !!scrollableRef.current,
  });
  const intersectionObserver = useIntersectionObserver({
    scrollableRef,
    onVisiblePageChange,
  });
  const keyboardManager = useKeyboardManager();
  const draggableEvents =
    isDraggable || keyboardManager.space ? draggable.events : {};

  const { zoom } = useZoom({
    keyboardManager,
    scrollableRef,
    zoomContainerRef,
    defaultZoom,
    onZoom,
  });
  const keyActiveCursor = useKeyActiveCursor(keyboardManager);
  const { width } = usePdfSize({
    maxWidth,
    scrollableRef,
    pdfContainerRef,
  });
  const pdfFilesMemo = useMemo(() => {
    return pdfFiles;
  }, [pdfFiles]);

  useEffect(() => {
    setScrollableElem(scrollableRef.current);
  }, []);

  useEffect(() => {
    pdfFiles.forEach((pdfFile, index) => {
      if (!documentsThumbnailsRef.current) {
        return;
      }

      const documentThumbnail = documentsThumbnailsRef.current[index];

      if (
        !documentThumbnail ||
        (documentThumbnail && documentThumbnail.url !== pdfFile.url)
      ) {
        documentsThumbnailsRef.current[index] = {
          documentIndex: index,
          name: pdfFile.name,
          url: pdfFile.url,
          thumbnails: {},
        };
      }
    });
  }, [documentsThumbnailsRef, pdfFiles]);

  return (
    <PDFScrollableContext.Provider
      value={{
        disableFormFields,
        visiblePages: intersectionObserver.visiblePages,
        visibleDocumentIndex: intersectionObserver.visibleDocumentIndex,
        visiblePageNumber: intersectionObserver.visiblePageNumber,
        observer: intersectionObserver.observer,
        scrollableRef: scrollableRef.current,
        keyboardManager,
        thumbnailPages,
        documentsThumbnailsRef: documentsThumbnailsRef,
        setDocumentsThumbnails: onDocumentsThumbnailsChange,
        zoom,
      }}
    >
      <Flex height="100%" justifyContent="center" width="100%">
        <Flex
          ref={scrollableRef}
          className="thin-scrollbar pdf-scrollable"
          height="100%"
          justifyContent="center"
          overflowX="scroll"
          overflowY="scroll"
          overscrollBehaviorX="contain"
          overscrollBehaviorY="contain"
          width="100%"
          {...draggableEvents}
        >
          <Box
            ref={pdfContainerRef}
            data-testid="pdf-container"
            height="max-content"
            position="relative"
            style={{
              cursor: keyActiveCursor,
              maxWidth: maxWidth * zoom,
            }}
            width="100%"
            zIndex={0}
            {...pdfContainerProps}
          >
            <Box
              ref={zoomContainerRef}
              data-testid="pdf-zoom-container"
              maxWidth={maxWidth}
              transform="scale(1) translate(0px 0px)"
              transformOrigin="0px 0px"
            >
              {scrollableElem &&
                intersectionObserver.observer &&
                pdfFilesMemo.map((pdfFile, i) => (
                  <PDFScrollableDocument
                    key={pdfFile.url}
                    documentIndex={i}
                    documentsThumbnailsRef={documentsThumbnailsRef}
                    pageChildren={pageChildren}
                    pdfFile={pdfFile}
                    scale={scale || 2}
                    scrollableRef={scrollableElem}
                    width={width}
                    zoom={zoom}
                    onPagesMetadataChange={onPagesMetadataChange}
                  />
                ))}

              {children}
            </Box>
          </Box>
        </Flex>
      </Flex>
    </PDFScrollableContext.Provider>
  );
};
