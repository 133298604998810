import { Button, HStack, ModalProps, useDisclosure } from '@chakra-ui/react';
import { capitalize } from 'lodash';
import { FC } from 'react';
import { Modal } from './Modal';

interface ConfirmModalProps
  extends Omit<ModalProps, 'children' | 'isOpen' | 'onOpen' | 'onClose'> {
  title: string;
  description?: string | React.ReactNode;
  type?: 'confirm' | 'delete' | 'save';
  children: (onOpen: () => void) => React.ReactNode;
  onConfirm: (onClose: () => void) => void;
  onCancel?: () => void;
}

export const ConfirmModal: FC<ConfirmModalProps> = ({
  description,
  type = 'confirm',
  onConfirm,
  onCancel,
  children,
  ...props
}) => {
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <>
      {children(onOpen)}

      <Modal
        {...props}
        footer={() => (
          <HStack alignItems="stretch">
            <Button
              colorScheme="gray"
              size="sm"
              variant="outline"
              onClick={() => {
                onClose();
                onCancel && onCancel();
              }}
            >
              Cancel
            </Button>
            <Button
              colorScheme={type === 'delete' ? 'red' : 'primary'}
              size="sm"
              onClick={() => onConfirm(onClose)}
            >
              {capitalize(type)}
            </Button>
          </HStack>
        )}
        isOpen={isOpen}
        onClose={onClose}
      >
        {description}
      </Modal>
    </>
  );
};
