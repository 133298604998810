import {
  Button,
  Card,
  CardBody,
  Flex,
  IconButton,
  Image,
  Link,
  Spinner,
  Text,
} from '@chakra-ui/react';
import {
  FileObject,
  ListingFragment,
  MlsListingAddressObject,
} from '@client/graphql/__generated__/types';
import { ZoomInIcon } from 'lucide-react';
import { ReactNode } from 'react';
import blurredListingPackagePdf from '~/apps/consumer/images/blurredListingPackageImage.png';
import { getStreetAddressFromMlsListing } from '../../utils/listing.utils';
import { BasicPdfViewModal } from '../BasicPdfViewerModal/BasicPdfViewerModal';
import { CopyPasteButton } from '../CopyPasteButton';

interface ShareStepsProps {
  listing: ListingFragment;
  isPdfViewerOpen: boolean;
  onPdfViewerOpen: () => void;
  onPdfViewerClose: () => void;
  agentRemarksStorefrontLink: string;
  agentRemarksBlurbString: string;
  loading: boolean;
  listingPackage?: FileObject;
  webUrlLink: string;
}

export const ShareSteps = ({
  listing,
  listingPackage,
  isPdfViewerOpen,
  onPdfViewerOpen,
  onPdfViewerClose,
  agentRemarksStorefrontLink,
  agentRemarksBlurbString,
  loading,
  webUrlLink,
}: ShareStepsProps) => {
  const downloadListingPackageButton = (
    <Button
      download
      isExternal
      as={Link}
      fontWeight="semibold"
      href={listingPackage?.url}
      size="xs"
    >
      Download
    </Button>
  );

  return (
    <Flex direction="column" gap={{ base: 4, md: 10 }} width="100%">
      <ShareStep
        cardBody={
          <Flex alignItems="center" gap={2} justifyContent="space-between">
            <Flex direction="column" minWidth="0">
              <Text color="whiteAlpha.700" fontSize="18px">
                Review seller preferences, download disclosures & securely
                submit all offers via Indigo at:
              </Text>
              <Text
                color="sky.300"
                overflow="hidden"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
              >
                {agentRemarksStorefrontLink}
              </Text>
            </Flex>
            <Flex>
              <CopyPasteButton
                buttonContentCopy="Copy"
                copyText={agentRemarksBlurbString}
                size="xs"
              />
            </Flex>
          </Flex>
        }
        stepNumber={1}
        title="Add to MLS Agent Remarks"
      />

      <ShareStep
        cardBody={
          <Flex alignItems="center" gap={2} justifyContent="space-between">
            <Text
              color="sky.300"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
            >
              {webUrlLink}
            </Text>
            <Flex>
              <CopyPasteButton
                buttonContentCopy="Copy"
                copyText={webUrlLink}
                size="xs"
              />
            </Flex>
          </Flex>
        }
        stepNumber={2}
        title={`Add to Web URL field on MLS (in "Agent/Office" section at the bottom right)`}
      />

      <ShareStep
        cardBody={
          loading ? (
            <Flex alignItems="center" gap={2}>
              <Text>Generating PDF...</Text>
              <Spinner />
            </Flex>
          ) : (
            <Flex alignItems="center" gap={2} justifyContent="space-between">
              <Flex alignItems="center" gap={2}>
                <Image
                  cursor="pointer"
                  src={blurredListingPackagePdf}
                  onClick={() => onPdfViewerOpen()}
                />
                <IconButton
                  aria-label="Preview Indigo storefront PDF"
                  color="white"
                  icon={<ZoomInIcon />}
                  left="33px" // half the width of the image
                  position="absolute"
                  variant="link"
                  onClick={() => onPdfViewerOpen()}
                />
                <Flex direction="column">
                  <Text fontSize="16px">
                    Indigo Storefront for{' '}
                    {getStreetAddressFromMlsListing(
                      listing?.mlsListing?.address as MlsListingAddressObject
                    )}
                  </Text>
                  <Text color="whiteAlpha.700">PDF</Text>
                </Flex>
              </Flex>
              {downloadListingPackageButton}
            </Flex>
          )
        }
        stepNumber={3}
        title="Remove disclosures from the MLS and replace with Indigo storefront PDF"
      />
      <BasicPdfViewModal
        header={
          <Flex
            alignItems="center"
            gap={2}
            justifyContent="space-between"
            mr={8}
          >
            <Text fontSize="16px" fontWeight={400} textTransform="none">
              Indigo Storefront
            </Text>
            {downloadListingPackageButton}
          </Flex>
        }
        isOpen={isPdfViewerOpen}
        pdfFiles={[
          {
            url: listingPackage?.url as string,
            name: listingPackage?.fileName,
          },
        ]}
        onClose={onPdfViewerClose}
      />
    </Flex>
  );
};

interface ShareStepProps {
  stepNumber: number;
  title: string;
  cardBody: ReactNode;
}

export const ShareStep = ({ stepNumber, title, cardBody }: ShareStepProps) => {
  return (
    <Flex direction={{ base: 'column', md: 'row' }} gap={{ base: 2, md: 6 }}>
      <Text
        bg="purpleGradient"
        bgClip="text"
        flexShrink={0}
        fontSize="48px"
        fontWeight="500"
      >
        {stepNumber}.
      </Text>
      <Flex direction="column" flex={1} gap={2} minWidth={0}>
        <Text fontSize="18px" fontWeight="500">
          {title}
        </Text>
        <Card>
          <CardBody>{cardBody}</CardBody>
        </Card>
      </Flex>
    </Flex>
  );
};
