import { Box, Flex, Text, VStack } from '@chakra-ui/react';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router';
import { NavLink } from 'react-router-dom';
import { IndigoLogo } from '~/common/icons/IndigoLogo';
import { IndigoLogoSmall } from '~/common/icons/IndigoLogoSmall';
import { useAppContext } from '~/services/main/contexts/AppContext';
import { NavItems } from '../Navigation/NavItems';
import { NAV_BUTTON_HELPER_ID } from '../Navigation/NavItemsPopover';
import {
  SIDEBAR_WIDTH_COLLAPSED,
  SIDEBAR_WIDTH_EXPANDED,
} from '../PageDetailLayout/PageDetailLayoutSidebar';
import { ProfilePicture } from '../User/ProfilePicture';
import { NeedHelp } from './NeedHelp';

export const DesktopSideBar: FC<{ tabletIsMobile?: boolean }> = ({
  tabletIsMobile = false,
}) => {
  const { user } = useAppContext();
  const [isExpanded, setIsExpanded] = useState(false);

  const navigate = useNavigate();

  const width = isExpanded ? SIDEBAR_WIDTH_EXPANDED : SIDEBAR_WIDTH_COLLAPSED;

  return (
    <Box
      display={
        tabletIsMobile
          ? { base: 'none', lg: 'block' }
          : { base: 'none', md: 'block' }
      }
      minWidth={SIDEBAR_WIDTH_COLLAPSED}
      zIndex={3}
      onMouseEnter={(e) => {
        // only expand if hovering on the Sidebar
        // don't expand if hovering over one of the helper tooltips
        if ((e.target as HTMLElement).dataset.id !== NAV_BUTTON_HELPER_ID) {
          setIsExpanded(true);
        }
      }}
      onMouseLeave={() => setIsExpanded(false)}
    >
      <VStack
        alignItems="flex-start"
        bgColor="bg.mainDark"
        borderRight="1px solid"
        borderRightColor="border.divider"
        height="100vh"
        justifyContent="space-between"
        left={0}
        overflow="hidden"
        position="fixed"
        pt={3}
        spacing={4}
        top={0}
        transition="0.3s width ease-in-out"
        whiteSpace="nowrap"
        width={width}
      >
        <Flex direction="column" gap={3} width="100%">
          {/**  the margin is needed to align the two logos. */}
          <NavLink to="/">
            <Box pb={!isExpanded ? 1 : 0} pl={5} pt={!isExpanded ? '1px' : 0}>
              {
                isExpanded ? (
                  <IndigoLogo />
                ) : (
                  <IndigoLogoSmall />
                ) /**  the margin is needed to align the two logos. */
              }
            </Box>
          </NavLink>

          <NavItems isExpanded={isExpanded} setIsExpanded={setIsExpanded} />
        </Flex>

        <Flex
          direction="column"
          gap={2}
          pb={3}
          pl={3}
          pr={isExpanded ? 3 : 0}
          width="100%"
        >
          <NeedHelp visibility={isExpanded ? 'visible' : 'hidden'} />
          <Flex
            alignItems="center"
            gap={2.5}
            overflow="hidden"
            py={3}
            textOverflow="clip"
            whiteSpace="nowrap"
          >
            <ProfilePicture
              id="account-button"
              isEdit={false}
              size="sm"
              onClick={() => navigate('/account')}
            />
            {isExpanded && (
              <Text color="white" fontWeight="medium">
                {user?.fullName}
              </Text>
            )}
          </Flex>
        </Flex>
      </VStack>
    </Box>
  );
};
