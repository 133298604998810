import { Box, BoxProps } from '@chakra-ui/react';
import { FC } from 'react';

export const CONTAINER_PADDINGS = {
  px: { base: 4, md: 6, lg: 8 },
  py: { base: 4, md: 6, lg: 8 },
};

export const Container: FC<BoxProps> = (props) => {
  return <Box flexGrow={1} {...CONTAINER_PADDINGS} {...props} />;
};
