import { useUpdateOfferForBuyersAgentMutation } from '@client/graphql/__generated__/main-operations';
import {
  ContractObject,
  DocumentVersionObject,
  ListingFragment,
  PartyFragment,
  PublicDocumentVersionObject,
  PublicListingFragment,
} from '@client/graphql/__generated__/types';
import { isEqual } from 'lodash';
import isNil from 'lodash/isNil';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { matchPath, useLocation, useNavigate } from 'react-router';
import { useRegisteredRoles } from '~/apps/consumer/hooks/useRegisteredRoles';
import { useFormFieldsMap } from '~/services/document/components/FormFields/hooks/useFormFieldsMap';
import { ValuesType } from '~/services/document/components/FormFields/types';
import { useDocumentVersionFormFields } from '~/services/document/hooks/useDocumentVersionFormFields';
import { useMergeState } from '~/services/document/hooks/useMergeState';
import { useOfferContractDocuments } from './useOfferContractDocuments';
import { useOfferContractValues } from './useOfferContractValues';
import { useOfferFlowQueries } from './useOfferFlowQueries';
import { useSignatureFormFields } from './useSignatureFormFields';

interface UseOfferFlowOptions {
  listing: ListingFragment | PublicListingFragment;
  waitToLoadQueries?: boolean;
}

interface ContractPreviewState {
  showContractPreview: boolean;
  contractPreviewDocIndex?: number;
}

export interface BuyerInfoInput {
  buyerName?: string;
  buyerEmail?: string;
  entityName?: string;
  entityTitle?: string;
  coBuyerName?: string;
  coBuyerEmail?: string;
}

export const useOfferFlow = ({
  listing,
  waitToLoadQueries = false,
}: UseOfferFlowOptions) => {
  // Active document is the document that is currently being filled out
  // for a case with us states with multiple property purchase contract
  const location = useLocation();
  const isListingAgentViewingOffer = !!matchPath(
    {
      path: 'listings/:listingId',
      end: false,
      caseSensitive: false,
    },
    location.pathname
  );
  const isBuyersAgentViewingOffer = !!matchPath(
    {
      path: 'buyers/:buyerPartyId',
      end: false,
      caseSensitive: false,
    },
    location.pathname
  );

  const defaultShowPreview =
    location.pathname.includes('uploaded') ||
    location.pathname.includes('review') ||
    isListingAgentViewingOffer;

  const [showLoading, setShowLoading] = useState(false);
  const [shouldSaveAsync, setShouldSaveAsync] = useState(false);
  const [activeDocumentId, setActiveDocumentId] = useState<string>();
  const [activePreviewDocumentVersion, setActivePreviewDocumentVersion] =
    useState<DocumentVersionObject | PublicDocumentVersionObject>();
  const [activeMappingKey, setActiveMappingKey] = useState<string>();
  const [visiblePageNumber, setVisiblePageNumber] = useState<number>();
  const [showVerifyUserModal, setShowVerifyUserModal] = useState(false);
  const [
    showSendToBuyerForSignatureModal,
    setShowSendToBuyerForSignatureModal,
  ] = useState(false);

  const [contractPreviewState, setContractPreviewState] =
    useState<ContractPreviewState>({
      showContractPreview: defaultShowPreview,
    });
  const [updateOfferForBuyersAgent] = useUpdateOfferForBuyersAgentMutation();
  const [selectedParty, setSelectedParty] = useState<PartyFragment | undefined>(
    undefined
  );

  const [buyerInfo, setBuyerInfo] = useState<BuyerInfoInput>();
  const updateBuyerInfo = useMergeState(setBuyerInfo);

  const navigate = useNavigate();

  const { isRegisteredAgent } = useRegisteredRoles();
  const {
    documents,
    activeDocument,
    activeDocumentVersion,
    fetchAutofillValues,
    autofillValues,
    queriesLoading,
    autofillValuesLoading,
    latestOffer,
    latestOfferLoading,
    refetchLatestOffer,
    disclosureDocumentTypes,
    agentNegotiationDocumentTypes,
    offerFlowSections,
    offerFlowSectionsDataLoading,
    offerAutofillVariableValues,
    refetchOfferAutofillVariableValues,
    contractValidationsQuery,
    offerKeyFields,
    offerKeyFieldsLoading,
  } = useOfferFlowQueries({
    listing,
    activeDocumentId,
    isAgentVerified: !!isRegisteredAgent,
    isListingAgentViewingOffer,
    isBuyersAgentViewingOffer,
    waitToLoadQueries,
  });
  const contract = latestOffer?.contract as ContractObject | undefined;

  const formFields = useDocumentVersionFormFields(
    activeDocumentVersion?.documentVersionFields || []
  );

  const updateContractPreviewState = useCallback(
    (type: 'show' | 'hide' | 'toggle', tabIndex?: number) => {
      setContractPreviewState((prev) => {
        let showContractPreview = !prev.showContractPreview;

        if (type === 'show') {
          showContractPreview = true;
        } else if (type === 'hide') {
          showContractPreview = false;
        }

        if (
          typeof prev.contractPreviewDocIndex !== 'number' ||
          typeof tabIndex === 'number'
        ) {
          return {
            showContractPreview,
            contractPreviewDocIndex: tabIndex || 0,
          };
        }

        return {
          ...prev,
          showContractPreview,
        };
      });
    },
    []
  );

  const setContractPreviewDocIndex = useCallback((index: number) => {
    setContractPreviewState((prev) => {
      return {
        ...prev,
        contractPreviewDocIndex: index,
      };
    });
  }, []);

  const refreshBuyerInfo = useCallback(async () => {
    const { data } = await refetchOfferAutofillVariableValues();
    const newAutofillVariableValues = data?.offerAutofillVariableValues?.[0];
    if (newAutofillVariableValues?.buyer?.name) {
      setBuyerInfo({
        buyerName: newAutofillVariableValues.buyer?.name,
        buyerEmail: newAutofillVariableValues.buyer?.email,
        entityName: newAutofillVariableValues.buyer?.entityName,
        entityTitle: newAutofillVariableValues.buyer?.entityTitle,
        coBuyerName: newAutofillVariableValues.coBuyer?.name,
        coBuyerEmail: newAutofillVariableValues.coBuyer?.email,
      });
    }
  }, [refetchOfferAutofillVariableValues, setBuyerInfo]);

  const formFieldsMap = useFormFieldsMap(formFields);

  const onFieldValuesUpdate = useCallback(
    (fieldValues: ValuesType) => {
      if (fieldValues) {
        setShowLoading(true);

        void fetchAutofillValues({
          fieldValues,
        })
          .then(({ data }) => {
            if (offerAutofillVariableValues && contract) {
              const readOnlyAutofillValues =
                data?.offerAutofillValues?.readOnlyAutofillValues;
              const autofillValues = data?.offerAutofillValues?.autofillValues;

              return contractValidationsQuery({
                variables: {
                  autofillVariableValues:
                    offerAutofillVariableValues as unknown as ValuesType,
                  fieldValues: {
                    ...autofillValues,
                    ...fieldValues,
                    ...readOnlyAutofillValues,
                  },
                  contractId: contract?.id,
                },
              });
            }
          })
          .finally(() => {
            setShowLoading(false);
          });
      }
    },
    [
      contract,
      contractValidationsQuery,
      fetchAutofillValues,
      offerAutofillVariableValues,
    ]
  );

  // Default field values to assign keys for Key Terms fields so it doesn't
  // autofill with values when user does not check the prefill seller preferences
  const defaultFieldValues = useMemo(() => {
    const output: ValuesType = {};

    offerFlowSections?.KEY_TERMS?.children?.forEach((child) => {
      child.documentSectionFields?.forEach((dsf) => {
        output[dsf.field.mappingKey] = '';
      });
    });

    offerFlowSections?.KEY_TERMS?.documentSectionFields?.forEach((dsf) => {
      output[dsf.field.mappingKey] = '';
    });

    return output;
  }, [offerFlowSections?.KEY_TERMS]);

  const { fieldValues, updateFieldValues, save, isLoading } =
    useOfferContractValues({
      offerFlowSections,
      listing,
      contract,
      autofillValues,
      documentVersionFields: activeDocumentVersion?.documentVersionFields || [],
      defaultFieldValues,
      onFieldValuesUpdate,
    });

  const {
    updateContractDocuments,
    contractDocsWithFiles,
    otherDisclosureContractDocs,
    disclosureContractDocs,
    agentNegotiationContractDocs,
    additionalDocs,
    allDocumentVersions,
  } = useOfferContractDocuments({
    documentVersion: activeDocumentVersion,
    fieldValues: fieldValues || {},
    contract,
    disclosureDocumentTypes,
    agentNegotiationDocumentTypes,
  });

  const {
    signatureFormFields,
    setSignatureFormFields,
    signatureFormFieldInputs,
  } = useSignatureFormFields();

  // Method to prefill seller preferences
  const prefillSellerPreferences = useCallback(async () => {
    if (activeDocumentVersion && fieldValues) {
      const { data } = await fetchAutofillValues({
        fieldValues: fieldValues,
      });
      if (data?.offerAutofillValues) {
        updateFieldValues({
          ...data?.offerAutofillValues.autofillValues,
          ...data?.offerAutofillValues.readOnlyAutofillValues,
        });
      }
    }
  }, [
    activeDocumentVersion,
    fetchAutofillValues,
    fieldValues,
    updateFieldValues,
  ]);

  const mergedAutofillValues = useMemo(() => {
    return {
      ...autofillValues.autofillValues,
      ...autofillValues.readOnlyAutofillValues,
    };
  }, [autofillValues]);

  const saveCallback = useCallback(
    (redirectTo?: string, skipUpdateContractDocuments?: boolean) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const updates: (Promise<any> | undefined)[] = [save()];
      const sanitizedContractFieldValues = Object.keys(fieldValues).reduce(
        (acc, key) => {
          acc[key] = latestOffer?.contract?.fieldValues?.[key];

          return acc;
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        {} as Record<string, any>
      );

      if (!isEqual(sanitizedContractFieldValues, fieldValues)) {
        updates.push(
          updateOfferForBuyersAgent({
            variables: {
              input: {
                id: latestOffer?.id as string,
                fieldValues,
              },
            },
          })
        );
      }

      if (!skipUpdateContractDocuments) {
        updates.push(updateContractDocuments());
      }

      return Promise.all(updates).then(() => {
        if (redirectTo) {
          navigate(redirectTo);
        }
      });
    },
    [
      save,
      latestOffer?.contract?.fieldValues,
      latestOffer?.id,
      fieldValues,
      updateOfferForBuyersAgent,
      updateContractDocuments,
      navigate,
    ]
  );

  useEffect(() => {
    if (shouldSaveAsync) {
      void saveCallback();
      setShouldSaveAsync(false);
    }
  }, [shouldSaveAsync, saveCallback]);

  useEffect(() => {
    if (documents?.length === 1 && documents[0]?.id && !activeDocumentId) {
      setActiveDocumentId(documents[0]?.id);
    }
  }, [documents, activeDocumentId]);

  useEffect(() => {
    if (contractPreviewState.contractPreviewDocIndex) {
      const documentVersion =
        allDocumentVersions[contractPreviewState.contractPreviewDocIndex]; // will be undefined for "other" addenda
      const existingContractDoc = contractDocsWithFiles.find(
        (contractDoc) =>
          contractDoc.documentVersion?.document &&
          documentVersion?.document &&
          contractDoc.documentVersion?.document?.id ===
            documentVersion?.document?.id
      );

      // don't set active docVersion if the addenda is uploaded
      existingContractDoc
        ? setActivePreviewDocumentVersion(undefined)
        : setActivePreviewDocumentVersion(documentVersion);
    } else {
      setActivePreviewDocumentVersion(
        activeDocumentVersion as DocumentVersionObject
      );
    }
  }, [
    activeDocumentVersion,
    allDocumentVersions,
    contractPreviewState.contractPreviewDocIndex,
    contractDocsWithFiles,
  ]);

  // Buyer info update
  useEffect(() => {
    if (
      isNil(buyerInfo?.buyerName) && // only sets the initial values when buyerInfo is empty
      offerAutofillVariableValues?.buyer?.name
    ) {
      setBuyerInfo({
        buyerName: offerAutofillVariableValues.buyer?.name,
        buyerEmail: offerAutofillVariableValues.buyer?.email,
        entityName: offerAutofillVariableValues.buyer?.entityName,
        entityTitle: offerAutofillVariableValues.buyer?.entityTitle,
        coBuyerName: offerAutofillVariableValues.coBuyer?.name,
        coBuyerEmail: offerAutofillVariableValues.coBuyer?.email,
      });
    }
  }, [buyerInfo?.buyerName, offerAutofillVariableValues]);

  return {
    activeDocument,
    setActiveDocumentId,
    documents,
    activeDocumentVersion,
    fieldValues: {
      ...autofillValues.autofillValues,
      ...fieldValues,
      ...autofillValues.readOnlyAutofillValues,
    },
    updateFieldValues,
    save: saveCallback,
    prefillSellerPreferences,
    autofillValues,
    fetchAutofillValues,
    mergedAutofillValues,
    isLoading,
    queriesLoading,
    showLoading,
    autofillValuesLoading,
    formFields,
    formFieldsMap,
    latestOffer,
    latestOfferLoading,
    visiblePageNumber,
    setVisiblePageNumber,
    activeMappingKey,
    setActiveMappingKey,
    activePreviewDocumentVersion,
    refetchLatestOffer,
    refreshBuyerInfo,
    showVerifyUserModal,
    setShowVerifyUserModal,
    updateContractDocuments,
    contractDocsWithFiles,
    otherDisclosureContractDocs,
    disclosureContractDocs,
    agentNegotiationContractDocs,
    additionalDocs,
    allDocumentVersions,
    disclosureDocumentTypes,
    agentNegotiationDocumentTypes,
    buyerInfo,
    updateBuyerInfo,
    offerAutofillVariableValues,
    offerFlowSections,
    offerFlowSectionsDataLoading,
    contractPreviewDocIndex: contractPreviewState.contractPreviewDocIndex,
    showContractPreview: contractPreviewState.showContractPreview,
    setContractPreviewDocIndex,
    updateContractPreviewState,
    signatureFormFields,
    setSignatureFormFields,
    signatureFormFieldInputs,
    showSendToBuyerForSignatureModal,
    setShowSendToBuyerForSignatureModal,
    offerKeyFields,
    offerKeyFieldsLoading,
    selectedParty,
    setSelectedParty,
    isListingAgentViewingOffer,
    isBuyersAgentViewingOffer,
    shouldSaveAsync,
    setShouldSaveAsync,
  };
};
