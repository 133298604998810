import { Flex, Text } from '@chakra-ui/react';
import {
  ActivityFragment,
  PublicUserObject,
} from '@client/graphql/__generated__/types';
import dayjs from 'dayjs';
import { UserAvatar } from '../User/UserAvatar';
import { DATE_MOBILE_OFFSET } from './activity.constants';

export const StorefrontUserUnsubscribedActivity = ({
  listingActivity,
}: {
  listingActivity: ActivityFragment;
}) => {
  return (
    <Flex key={listingActivity.id} flexDirection="column" mb={4}>
      <Flex
        direction={{ base: 'column', md: 'row' }}
        justifyContent="space-between"
        mb={4}
        width="100%"
      >
        <Flex alignItems="center">
          <UserAvatar
            mr={3}
            size="sm"
            user={listingActivity.user as PublicUserObject}
          />
          <Flex alignItems="center">
            <Text color="gray.500">
              <Text as="span" color="white" fontWeight="medium">
                {listingActivity?.user?.fullName}
              </Text>{' '}
              unsubscribed
            </Text>
          </Flex>
        </Flex>

        <Flex ml={{ base: DATE_MOBILE_OFFSET, md: 0 }}>
          <Text color="gray.500">
            {dayjs(listingActivity.createdAt as Date).fromNow()}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};
