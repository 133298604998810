import { makeUniqueId } from '@apollo/client/utilities';
import { FieldType } from '@client/graphql/__generated__/types';
import { DragEndEvent, DragStartEvent } from '@dnd-kit/core';
import { useCallback } from 'react';
import { FormField, ValuesType } from '../../FormFields/types';
import { FormFieldDefaults } from '../config';

export const defaultWidth = 0.15;
export const defaultHeight = 0.02;
export const checkboxRadioWidth = 0.02;

interface UseDragAndDropCallbacksProps {
  onFormFieldChange: (formField: FormField) => void;
  setActiveDraggingItemData: React.Dispatch<
    React.SetStateAction<{
      fieldType: FieldType;
      label?: string;
    } | null>
  >;
  shouldSetDefaultMappingKey?: boolean;
  shouldSetCheckboxValues?: boolean;
  updateValues?: (values: ValuesType) => void;
}

export const useDragAndDropCallbacks = ({
  onFormFieldChange,
  setActiveDraggingItemData,
  shouldSetDefaultMappingKey = false,
  shouldSetCheckboxValues = false,
  updateValues,
}: UseDragAndDropCallbacksProps) => {
  const onDrop = useCallback(
    (e: DragEndEvent) => {
      const { over, active } = e;
      if (!over) {
        return;
      }

      const fieldButtons = document.querySelectorAll(
        '[id*=add-form-field-button]'
      );

      let isHoveringFieldButton = false;

      if (fieldButtons.length > 0) {
        fieldButtons.forEach((button) => {
          if (button.matches(':hover')) {
            isHoveringFieldButton = true;
          }
        });
      }

      const droppableRect = over?.rect;
      const draggableRect = active?.rect?.current?.translated as ClientRect;
      const widthPx = defaultWidth * droppableRect.width;
      const heightPx = defaultHeight * droppableRect.height;
      const x =
        (draggableRect.left - droppableRect.left + widthPx / 2) /
        droppableRect.width;
      const y =
        (draggableRect.top - droppableRect.top + heightPx / 2) /
        droppableRect.height;

      const fieldType = active?.data?.current?.fieldType as FieldType;
      const pageNumber = over?.data?.current?.pageNumber as number;
      const documentIndex = over?.data?.current?.documentIndex as number;

      const isCheckboxOrRadio = [FieldType.CHECKBOX, FieldType.RADIO].includes(
        fieldType
      );

      const newId = makeUniqueId('new-field');
      const formField = {
        id: newId,
        isNew: true,
        fieldType,
        pageNumber: pageNumber,
        documentIndex: documentIndex,
        width: isCheckboxOrRadio ? checkboxRadioWidth : defaultWidth,
        height: defaultHeight,
        x,
        y: isHoveringFieldButton ? y - 0.04 : y,
        label: '',
        mappingKey: shouldSetDefaultMappingKey ? newId : '',
        ...FormFieldDefaults,
      };

      onFormFieldChange(formField);

      if (shouldSetCheckboxValues && updateValues && isCheckboxOrRadio) {
        updateValues({
          [newId]: true,
        });
      }

      window.requestAnimationFrame(() => {
        const newFieldElem = document.querySelector<HTMLDivElement>(
          `.moveable-form-field[data-formfieldid="${newId}"]`
        );
        if (newFieldElem) {
          setTimeout(() => {
            newFieldElem.click();
          }, 100);
        }
      });
    },
    [
      onFormFieldChange,
      shouldSetDefaultMappingKey,
      shouldSetCheckboxValues,
      updateValues,
    ]
  );

  const onDragStart = useCallback(
    (e: DragStartEvent) => {
      const { active } = e;
      setActiveDraggingItemData({
        fieldType: active?.data?.current?.fieldType as FieldType,
        label: active?.data?.current?.label as string | undefined,
      });
      e;
    },
    [setActiveDraggingItemData]
  );

  const onDragEnd = useCallback(
    (e: DragEndEvent) => {
      onDrop(e);
      setActiveDraggingItemData(null);
    },
    [onDrop, setActiveDraggingItemData]
  );

  return { onDragStart, onDragEnd, onDrop };
};
