import {
  Flex,
  forwardRef,
  Grid,
  GridItem,
  GridProps,
  Tag,
  TagCloseButton,
  TagLabel,
  VStack,
} from '@chakra-ui/react';
import { VirtuosoGrid } from 'react-virtuoso';
import { useIsMobile } from '~/common/hooks/useIsMobile';
import { PublicListingCard } from '../../Listings/ListingCard/PublicListingCard';
import { MlsListingWithListing, useExploreContext } from '../ExploreContext';
import { FilterTag } from '../Filters/FilterTag';
import { MappedFilterKeys } from '../Filters/constants';

interface IListingGridProps {
  totalCount: number;
  listings: MlsListingWithListing[];
  utmParams?: { [key: string]: string };
}

export const ListingGrid = ({
  // totalCount,
  listings,
  utmParams,
}: IListingGridProps) => {
  const isMobile = useIsMobile(true);

  const { filters, hasFilters, clearFilters } = useExploreContext();

  return (
    <VStack
      flexGrow={1}
      height="100%"
      mt={{ base: '180px', lg: 2 }}
      pt={4}
      px={0}
      spacing={3}
      width="100%"
    >
      {/* //TODO: Uncomment this when we fix the listing deletion in opensearch
      <Flex
        alignItems="center"
        justifyContent="space-between"
        px={4}
        width="100%"
      >
        <Heading fontWeight="semibold" size="headline">
          {totalCount} homes
        </Heading>
      </Flex> */}

      {hasFilters && !isMobile && (
        <Flex alignItems="center" flexWrap="wrap" gap={2} px={4} width="100%">
          <Tag backgroundColor="red.700">
            <TagLabel>Clear all</TagLabel>
            <TagCloseButton onClick={() => clearFilters()} />
          </Tag>
          {Object.keys(filters).map((key) => {
            if (key === 'premiumOnly' || key === 'bbox') {
              return null;
            }

            return <FilterTag key={key} filterKey={key as MappedFilterKeys} />;
          })}
        </Flex>
      )}

      <VirtuosoGrid
        colSpan={isMobile ? 1 : 2}
        components={{
          List: CustomGrid,
          Item: GridItem,
        }}
        data={listings}
        itemContent={(index, listing) => (
          <PublicListingCard
            key={listing.mlsListing.id}
            fullWidth={isMobile}
            listing={listing.listing}
            mlsListing={listing.mlsListing}
            showSubscribers={true}
            utmParams={utmParams}
          />
        )}
        style={{ flexGrow: 1, display: 'flex', width: '100%' }}
      />
    </VStack>
  );
};

const CustomGrid = forwardRef(({ children, ...props }: GridProps, ref) => {
  return (
    <Grid
      px={4}
      {...props}
      ref={ref}
      gap={4}
      templateColumns={{
        base: 'repeat(1, 1fr)',
        md: 'repeat(2, 1fr)',
        lg: 'repeat(1, 1fr)',
        xl: 'repeat(2, 1fr)',
      }}
    >
      {children}
    </Grid>
  );
});
