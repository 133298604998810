import { KeyTermUnit } from '@client/graphql/__generated__/types';
import isNil from 'lodash/isNil';
import { formatToCurrency } from '~/services/document/utils/number';

export const formatRangeValue = (value: number, unit: KeyTermUnit) => {
  if (unit === KeyTermUnit.USD) {
    return formatRangeCurrencyValue(value);
  } else if (unit === KeyTermUnit.DAYS) {
    return `${value} days`;
  } else {
    return value;
  }
};

export const formatRangeCurrencyValue = (value?: number) => {
  if (isNil(value)) return;

  const notation = value > 10000 ? 'compact' : 'standard';

  // Properly format millions values with 2 decimal places
  // 1,950,000 should be 1.95M but 19,500,000 should be 19.5M
  const millionsValue = value / 1000000;
  const decimals =
    value >= 1000000
      ? millionsValue < 10
        ? 2
        : millionsValue < 100
          ? 1
          : 0
      : 0;

  return formatToCurrency(value, decimals, notation);
};

export const formatDistance = (distanceMiles: number) => {
  return distanceMiles > 30
    ? '> 30 miles'
    : `${distanceMiles.toFixed(1)} miles`;
};
