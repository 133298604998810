import { Button, ButtonProps } from '@chakra-ui/react';
import { FC } from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';

export const SecondaryNavButton: FC<ButtonProps & NavLinkProps> = ({
  children,
  onClick,
  _hover,
  ...props
}) => {
  return (
    <Button
      _activeLink={{
        backgroundColor: 'bg.activeSecondaryNav',
      }}
      _hover={{
        backgroundColor: 'bg.hover',
        ...(_hover || {}),
      }}
      as={NavLink}
      color="link.secondaryNav"
      display="flex"
      flexDir="row"
      fontSize={{ base: 'sm', md: 'md' }}
      height="auto"
      iconSpacing={2}
      justifyContent="flex-start"
      px={4}
      py={2}
      transition="color 0.3s"
      variant="unstyled"
      width="100%"
      onClick={onClick}
      {...props}
    >
      {children}
    </Button>
  );
};
