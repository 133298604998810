import { Portal } from '@chakra-ui/react';
import { LabelFieldObject } from '@client/graphql/__generated__/types';
import { FC, memo, useEffect, useMemo, useRef } from 'react';
import { flushSync } from 'react-dom';
import ReactMoveable, { OnClick } from 'react-moveable';
import Selecto from 'react-selecto';
import { MoveableTarget } from '../DocumentMapper/types';
import { useMoveableEvents, useSelectoEvents } from './eventHooks';
import {
  onDrag,
  onDragGroup,
  onMoveKeyDown,
  onResize,
  onResizeGroup,
} from './events';
import './style.css';

export interface PositionAndSize {
  id: string;
  x?: number;
  y?: number;
  width?: number;
  height?: number;
  labelField?: LabelFieldObject;
}

export interface MoveableProps {
  zoom: number;
  boundWidth: number;
  boundHeight: number;
  moveableTargets?: MoveableTarget[];
  dragContainer: string | HTMLElement | null | undefined;
  selectableTargets: string[];
  isSelectoActive: boolean;
  onSelectoRef: (selecto: Selecto) => void;
  onChange?: (changed: PositionAndSize[]) => void;
  onMoveableTargetClick?: (event: OnClick) => void;
  onSelect: (
    elements: (HTMLElement | SVGElement)[],
    isDragSelect: boolean
  ) => void;
  onSelectEnd?: (
    elements: (HTMLElement | SVGElement)[],
    isDragSelect: boolean
  ) => void;
}

export const Moveable: FC<MoveableProps> = memo(function Moveable({
  zoom,
  boundWidth,
  boundHeight,
  moveableTargets,
  dragContainer,
  selectableTargets,
  isSelectoActive,
  onSelectoRef,
  onChange,
  onMoveableTargetClick,
  onSelect,
  onSelectEnd,
}) {
  const selectoRef = useRef<Selecto>();
  const ref = useRef<ReactMoveable>(null);
  const hasMoveableTargets = !!moveableTargets?.length;

  useEffect(() => {
    const onKeyDown = (event: KeyboardEvent) => {
      if (ref.current) {
        onMoveKeyDown({ event, moveable: ref.current.moveable });
      }
    };

    if (hasMoveableTargets) {
      window.addEventListener('keydown', onKeyDown);
    } else {
      selectoRef.current?.setSelectedTargets([]);
    }

    return () => window.removeEventListener('keydown', onKeyDown);
  }, [hasMoveableTargets, ref, selectoRef]);

  const moveableEvents = useMoveableEvents({
    selectoRef,
    moveableTargets,
    boundHeight,
    boundWidth,
    onChange,
  });

  const selectoEvents = useSelectoEvents({
    moveableTargets,
    moveableRef: ref,
    onSelect,
    onSelectEnd,
  });

  const moveableElements = useMemo(() => {
    return moveableTargets?.map(({ formField, type }) =>
      document.querySelector<HTMLElement>(
        `div[data-formfieldid="${formField.id}"][data-type="${
          type || 'form-field'
        }"]`
      )
    ) as HTMLElement[];
  }, [moveableTargets]);

  const elementsForGuidelines = useMemo(() => {
    return Array.from(document.querySelectorAll('.moveable-form-field')).filter(
      (elem) => {
        return !moveableElements.includes(elem as HTMLElement);
      }
    );
  }, [moveableElements]);

  return (
    <>
      <ReactMoveable
        ref={ref}
        bounds={{
          left: 0,
          top: 0,
          right: boundWidth,
          bottom: boundHeight,
        }}
        dragArea={true}
        draggable={true}
        edge={true}
        elementGuidelines={elementsForGuidelines}
        elementSnapDirections={{
          top: true,
          left: true,
          bottom: true,
          right: true,
          center: true,
          middle: true,
        }}
        flushSync={flushSync}
        isDisplayInnerSnapDigit={false}
        isDisplaySnapDigit={true}
        keepRatio={false}
        maxSnapElementGapDistance={80}
        maxSnapElementGuidelineDistance={100}
        resizable={true}
        rootContainer={document.body}
        snapDirections={{
          top: true,
          left: true,
          bottom: true,
          right: true,
          center: true,
          middle: true,
        }}
        snapGap={false}
        snapThreshold={1}
        snappable={true}
        targets={moveableElements}
        zoom={zoom === 1 ? 1 : 1.5 / zoom}
        onClick={onMoveableTargetClick}
        onClickGroup={moveableEvents.onClickGroup}
        onDrag={onDrag}
        onDragGroup={onDragGroup}
        onRenderEnd={moveableEvents.onRenderEnd}
        onRenderGroupEnd={moveableEvents.onRenderGroupEnd}
        onResize={onResize}
        onResizeGroup={onResizeGroup}
      />
      <Portal appendToParentPortal>
        {isSelectoActive && dragContainer && (
          <Selecto
            ref={(selecto) => {
              if (selecto) {
                selectoRef.current = selecto;
                onSelectoRef(selecto);
              }
            }}
            boundContainer={dragContainer}
            dragContainer={dragContainer}
            hitRate="0%"
            ratio={0}
            selectByClick={true}
            selectFromInside={true}
            selectableTargets={selectableTargets}
            toggleContinueSelect={['shift']}
            onDragStart={selectoEvents.onDragStart}
            onSelect={selectoEvents.onSelect}
            onSelectEnd={selectoEvents.onSelectEnd}
          />
        )}
      </Portal>
    </>
  );
});
