import {
  Container,
  Divider,
  Flex,
  Heading,
  Link,
  Skeleton,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useStorefrontActivitiesQuery } from '@client/graphql/__generated__/main-operations';
import { Bell, Forward, Megaphone, MessageCircleHeart } from 'lucide-react';
import React from 'react';
import { Navigate, NavLink } from 'react-router-dom';
import { ActivitiesList } from '~/apps/consumer/components/Activity/ActivitiesList';
import { EmptyState } from '~/apps/consumer/components/EmptyState';
import { getStorefrontSetupWizardRoute } from '~/apps/consumer/utils/routes.utils';
import { CenterSpinner } from '~/common/components/CenterSpinner';
import { ActionCard } from '../../../components/Dashboard/ActionCard';
import { useListingSetupContext } from '../ListingSetupContext';
import {
  StorefrontDashboardProvider,
  useStorefrontDashboardContext,
} from '../StorefrontDashboardContext';
import { AnalyticsOverviewCard } from './AnalyticsOverviewCard';
import { MLSConfigureModalTrigger } from './MLSConfigureModalTrigger';
import { OffersOverviewCard } from './OffersOverviewCard';
import { ShareStorefrontModalTrigger } from './ShareStorefrontModalTrigger';

const DashboardPageInner: React.FC = () => {
  const { listing, isTrackableListingPackageEnabled } =
    useListingSetupContext();
  const { loading: isDashboardLoading } = useStorefrontDashboardContext();

  const { data, loading: isActivitiesLoading } = useStorefrontActivitiesQuery({
    variables: {
      input: {
        listingId: listing?.id || '',
      },
    },
    skip: !listing?.id || !isTrackableListingPackageEnabled,
  });

  if (!isTrackableListingPackageEnabled) {
    return <Navigate replace to={`/listings/${listing?.id}`} />;
  }

  const hasActivity = data?.storefrontActivities?.length;

  const overviewComponents = [OffersOverviewCard, AnalyticsOverviewCard];

  return (
    <Container maxW="container.xl" pt={12} px={8}>
      <VStack alignItems="flex-start" gap={8} width="100%">
        <CardLayout>
          <ActionCard
            as={NavLink}
            description="Seller preferences, comps, and docs"
            header="Customize your Storefront"
            icon={<MessageCircleHeart />}
            to={getStorefrontSetupWizardRoute(listing.id)}
          />
          <MLSConfigureModalTrigger flex="1" listing={listing}>
            <ActionCard
              description="Drive engagement on your Storefront"
              header="Add to MLS"
              icon={<Megaphone />}
            />
          </MLSConfigureModalTrigger>
          <ShareStorefrontModalTrigger flex="1" listing={listing}>
            <ActionCard
              description="Text, email, Facebook & more"
              header="Share your Storefront"
              icon={<Forward />}
            />
          </ShareStorefrontModalTrigger>
        </CardLayout>
        <CardLayout>
          {overviewComponents.map((OverviewComponent) => (
            <Skeleton
              key={OverviewComponent.name}
              flex="1"
              isLoaded={!isDashboardLoading}
            >
              <StorefrontDashboardProvider listingId={listing.id}>
                <OverviewComponent />
              </StorefrontDashboardProvider>
            </Skeleton>
          ))}
        </CardLayout>
        <VStack alignItems="flex-start" gap={4} width="100%">
          <Heading fontSize="2xl" fontWeight="medium">
            Activity
          </Heading>
          <Divider borderWidth="1px" />
          <Flex flexDirection="column" width="100%">
            {isActivitiesLoading ? (
              <CenterSpinner size="xl" />
            ) : hasActivity ? (
              <ActivitiesList activities={data?.storefrontActivities} />
            ) : (
              <EmptyState
                headerText="No activity on this storefront yet"
                icon={Bell}
                subText={
                  <Text>
                    <ShareStorefrontModalTrigger as="span" listing={listing}>
                      <Link color="link.skyBlue">Share your storefront</Link>
                    </ShareStorefrontModalTrigger>{' '}
                    to start receiving activity and engage with potential buyers
                  </Text>
                }
              />
            )}
          </Flex>
        </VStack>
      </VStack>
    </Container>
  );
};

const CardLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <Flex
      alignSelf="center"
      flexDir={{ base: 'column', lg: 'row' }}
      gap={4}
      justifyContent="space-between"
      overflow="auto"
      width="100%"
    >
      {children}
    </Flex>
  );
};

export const DashboardPage: React.FC = () => {
  const { listing } = useListingSetupContext();

  return (
    <StorefrontDashboardProvider listingId={listing.id}>
      <DashboardPageInner />
    </StorefrontDashboardProvider>
  );
};
