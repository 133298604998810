import { Card, CardBody, CardHeader, Divider, Flex } from '@chakra-ui/react';
import { ComparablesContext } from '~/apps/consumer/pages/ListingSetup/Comparables/ComparablesContext';
import { ListingDisclaimer } from '~/apps/consumer/pages/Storefront/ListingDisclaimer';
import { useStorefrontContext } from '~/apps/consumer/pages/Storefront/StorefrontContext';
import { ComparablesColumns } from './ComparablesColumns';

export const ComparablesDetails = () => {
  const { listing, listingComparableSet } = useStorefrontContext();

  const agentRemarks =
    listingComparableSet?.agentNotes || listing?.mlsListing?.remarks;

  return (
    <Flex direction="column" gap={4}>
      <Card>
        <CardHeader>Agent Remarks</CardHeader>
        <CardBody>{agentRemarks}</CardBody>
      </Card>
      <ComparablesContext.Provider
        value={{
          comparables: listingComparableSet?.listingComparables || [],
          mainMlsListing: listing?.mlsListing,
        }}
      >
        <ComparablesColumns listing={listing} />
      </ComparablesContext.Provider>
      <Divider my={8} />
      <ListingDisclaimer
        extraText="Properties displayed may be listed or sold by various participants in the MLS."
        listing={listing}
      />
    </Flex>
  );
};
